import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search-initiative',
  templateUrl: './search-initiative.component.html',
  styleUrls: ['./search-initiative.component.scss']
})
export class SearchInitiativeComponent implements OnInit {
  tabActive = 1;
  searchForm: FormGroup;
  submitted: boolean;
  initiatives: any[] = [];
  searchKeys: any[] = [];
  keywordsErr: boolean = false;
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  initiativeNextPage: any;
  locations: any;
  loading: boolean = false;
  currentUser: any;
  totalInitiatives: any;
  
  constructor(private searchService: SearchService, public formBuilder: FormBuilder, private router: Router) { 
    this.searchForm = this.formBuilder.group({
      search_key: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      location: new FormControl('', Validators.maxLength(200))
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.searchInitiatives('');
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  search(isValid: boolean) {
    this.submitted = true;
    if (this.searchForm.get('search_key').value || this.searchForm.get('location').value) {
      let search_key = this.searchForm.get('search_key').value;
      let location_key = this.searchForm.get('location').value;
      this.locations = location_key;
      if (search_key !== "" && search_key !== null && search_key.trim().length > 0){
        if (this.searchKeys.length < 3) {
          this.keywordsErr = false;
          this.searchKeys.push(this.searchForm.get('search_key').value);
        } else {
          this.keywordsErr = true;
          this.focusOutErrMsg();
        }
        this.searchForm.reset();
      }
      this.submitted = false;
      this.initiateSearch(location_key);
    }
  }

  initiateSearch(loc_key){
    if (loc_key){
      this.searchForm.controls['location'].setValue(this.locations)
    }
    else{
      this.searchForm.reset();
    }
    let params = this.setParams(loc_key); 
    this.searchInitiatives(params)
  }

  searchInitiatives(params: any){
    this.searchService.searchInitiatives(params)
    .subscribe(response => {
      this.initiatives = response.response.results;
      this.initiativeNextPage = response.response.next;
      this.totalInitiatives = response.response.total_count;
    });
  }

  setParams(loc_key: string) {
    let params = "?search=" + this.searchKeys.join("+");
    if (loc_key) {
      params += "&location=" + loc_key;
    }
    return params;
  }

  focusOutFunction() {
    setTimeout(() => {
      this.hidePlaceResults = true;
      this.searchPlaces = [];
    }, 1000);
  }
  
  focusOutErrMsg() {
    setTimeout(() => {
      this.keywordsErr = false;
    }, 6000);
  }

  delKey(key: string) {
    if (key) {
      let index = this.searchKeys.findIndex(obj => obj == key);
      this.searchKeys.splice(index, 1);
      if (this.searchKeys.length == 0) {
        this.locations = "";
      }
    } else {
      this.searchKeys = [];
      this.locations = "";
    }
    this.initiateSearch(this.locations);
  }

  onScrollDown() {
    if (this.initiativeNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams(this.locations);
      params = params + '&page=' + this.initiativeNextPage;
      this.searchService.searchInitiatives(params)
        .subscribe(data => {
          this.initiatives = this.initiatives.concat(data.response.results);
          this.initiativeNextPage = data.response.next;
          this.loading = false;
        })
    }
  }

  redirectToInitiative(initiative: any){
    if (this.currentUser && initiative.user === this.currentUser.id){
      this.router.navigate(['edit-initiatives/' + initiative.id + '/'])
    }else{
      this.router.navigate(['initiatives/' + initiative.id + '/'])
    }
  }

}
