import { Component, OnInit, TemplateRef, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommentsService } from '../../services/comments.service';
import { RepliesComponent } from '../replies/replies.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @ViewChild(RepliesComponent) replyChild:RepliesComponent;
  @Input() editFeedId: any;
  @Input() editFeedPostType: any;
  @Output() commentAdded = new EventEmitter<any>();
  @Output() commentDeleted = new EventEmitter<any>();
  modalRef: BsModalRef;
  user: any = {};
  comments = [];
  replies = [];
  commentForm: FormGroup;
  replyForm: FormGroup;
  public submitted: boolean = false;
  replySubmitted: boolean = false;
  teams: any[];
  taggedUsers = [];
  cleanData: any;
  feedId: any;
  postType: any;
  commentsNextPage: any = 1;
  totalCommentsCount: number = 0;
  editCommentId: any;
  commentReplyId: any = null;
  replyNextPage: any = 1;
  totalReplyCount: number = 0;
  childNotifier : Subject<any> = new Subject<any>();

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, public commentService: CommentsService) {
    this.commentForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required, this.noWhitespaceValidator])
    });
    this.replyForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required, this.noWhitespaceValidator])
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.commentForm.reset();
    this.feedId = this.editFeedId;
    this.postType = this.editFeedPostType;
    setTimeout(() => {
      this.getNetwork();
      this.getComments();
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  openReply(id: any, replyModal: TemplateRef<any>) {
    this.editCommentId = id;
    this.replySubmitted = false;
    this.replyForm.reset();
    this.modalRef = this.modalService.show(replyModal, Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal comment-modal' }));
  }

  replyAdded(event: any){
    if (event) {
      let index = this.comments.findIndex(obj => obj.id === this.commentReplyId);
      this.comments[index].reply_count = this.comments[index].reply_count + 1;
    }
  }

  getNetwork(){
    this.commentService.getUserNetwork()
    .subscribe(response => {
      if (response.status == 200) {
        this.teams = response.response;
      } else {
        this.teams = [];
      }
    }, error => {
      this.teams = [];
    });
  }

  getComments(){
    if (this.commentsNextPage) {
      let params = '?page=' + this.commentsNextPage;
      this.commentService.getComments(this.feedId, this.postType, params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.commentsNextPage == 1 || this.commentsNextPage === null) {
            this.comments = response.response.results;
          } else {
            this.comments = this.comments.concat(response.response.results);
          }
          this.commentsNextPage = response.response.next;
          this.totalCommentsCount = response.response.total_count;
          this.commentAdded.emit(this.totalCommentsCount);
        } else {
          this.comments = [];
          this.commentsNextPage = 1;
          this.totalCommentsCount = 0;
          this.commentAdded.emit(this.totalCommentsCount);
        }
      }, error => {
        this.commentAdded.emit(this.totalCommentsCount);
      });
    }
  }

  createComment(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.formatData(this.commentForm.get('text').value);
      let model = {
        'text': this.cleanData,
        'post_type': this.postType
      }
      this.commentService.addComment(this.feedId, model)
      .subscribe(response => {
        this.commentForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.submitted = false;
        if (response.status == 200) {
          this.commentsNextPage = 1;
          this.getComments();
        } else {
          this.commentForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
        }
      }, error => {
        this.commentForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.submitted = false;
        this.commentForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
      });
    }
  }

  createReply(isValid: boolean){
    this.replySubmitted = true;
    if (isValid) {
      this.formatData(this.replyForm.get('text').value);
      let model = {
        'text': this.cleanData,
        'post_type': this.postType,
        'timeline_id': this.feedId
      }
      this.commentService.addReply(this.editCommentId, model)
      .subscribe(response => {
        this.replyForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.replySubmitted = false;
        if (response.status == 200) {
          let index = this.comments.findIndex(obj => obj.id === this.editCommentId);
          this.comments[index].reply_count = this.comments[index].reply_count + 1;
          this.enableReplies(this.editCommentId);
          this.resetReplies(response.response);
          this.modalRef.hide();
        } else {
          this.replyForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
        }
      }, error => {
        this.replyForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.replySubmitted = false;
        this.replyForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
      });
    }
  }

  chngFormat = (item): any => {
    this.format(item);
    return '@'+item.name+' ';
  }

  format = (item) => {
    let dataLink = ' <a href="/profile/' + item.id + '/">' + item.name + '</a>&nbsp;'
    let data = {'name': item.name, 'id': item.id, 'data': dataLink};
    this.taggedUsers.push(data);
  }

  formatData(data: any){
    this.cleanData = data;
    for (let tag of this.taggedUsers) {
      let to_replace = '@'+tag['name'];
      this.cleanData = this.cleanData.replace(to_replace, tag['data']);
    }
  }

  deleteComment(){
    this.commentService.deleteComment(this.editCommentId, this.postType)
    .subscribe(response => {
      this.commentDeleted.emit(true);
      this.commentsNextPage = 1;
      this.getComments();
      this.modalRef.hide();
    });
  }

  openDeleteModal(id: any, template: TemplateRef<any>) {
    this.editCommentId = id;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal delete-modal-popup' })
    );
  }

  getReplies(id: any){
    if (this.replyNextPage) {
      let params = '?page=' + this.replyNextPage;
      this.commentService.getReplies(id, this.postType, params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.replyNextPage == 1 || this.replyNextPage === null) {
            this.replies = response.response.results;
          } else {
            this.replies = this.replies.concat(response.response.results);
          }
          this.replyNextPage = response.response.next;
          this.totalReplyCount = response.response.total_count;
        }
      });
    }
  }

  enableReplies(id: any){
    this.commentReplyId = id;
  }

  resetReplies(data: any) {
    setTimeout(() => {
      this.childNotifier.next(data);
    }, 500);
  }
}
