<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="SignUpInnerWrap WrapWhiteShadow">
                        <h1 class="hdg">Questions or Comments?</h1>
                        <app-contact-us-form [displayColClass]="false"></app-contact-us-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
