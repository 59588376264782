import { Component, OnInit, TemplateRef, ElementRef, ViewChild, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @ViewChild("timelineInp") timelineInp: ElementRef;
  @Input() page_id: any;
  @Input() pagesDetails: any;
  modalRef: BsModalRef;
  linkModalRef: BsModalRef;
  public Editor = ClassicEditor;
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  timelineUpdForm: FormGroup;
  radioActive = 1;
  timelines: any = [];
  timelineDocs: any = [];
  editTimelineObj: any = {};
  user: any = {};
  timelineNextPage: any = 1;
  totalTimelines: any = 0;
  positions: any = [];
  events: any = [];
  totalPositions: any = 0;
  totalEvents: any = 0;
  positionNextPage: any = 1;
  eventNextPage: any = 1;
  public submitted: boolean = false;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage = "";
  coverImageUploadSizeMessage = "";
  intUsersNextPage: any = 1;
  interestedUsers:any = [];
  totalUsersCount: any = 0;
  linkAdded: boolean = false;
  postSelected = 1;
  descriptionLimit: number = 97;

  constructor(public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private profileService: ProfileService,
    private modalService: BsModalService, private datePipe: DatePipe, private pageService: PageService, private router: Router) {

    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      date: new FormControl(''),
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelineUpdForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(2250)])
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getAllTimelines();
  }

  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true
    } else {
      return false
    }
  }

  addTimelineModal(template: TemplateRef<any>) {
    this.linkAdded = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.postSelected = 1;
    this.timelineDocs = [];
    this.timelineForm.reset();
    this.timelinelinkForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  addTimelineLinkModal(addTimelineLink: TemplateRef<any>) {
    this.linkAdded = false;
    this.linkModalRef = this.modalService.show(addTimelineLink,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  delTimelineModal(delTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(delTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editTimelineModal(editTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.timelineUpdForm.reset();
    this.editTimelineObj = timeline;
    this.timelineUpdForm.controls['description'].setValue(timeline.description);
    this.modalRef = this.modalService.show(editTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  openModalOthers(template: TemplateRef<any>, timeline: any, post_type: any = 1) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editTimelineObj = timeline;
    this.editTimelineObj['post_type'] = this.radioActive;
    this.getInterestedUsers();
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editTimelineObj.id, this.editTimelineObj.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  markInterested(timeline: any, post_type: any){
    if (timeline.interested_data.interested) {
      this.pageService.unMarkInterested(timeline.id, post_type)
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => obj.id === timeline.id);
      this.timelines[index].interested_data.interested = false;
    } else {
      this.pageService.markInterested(timeline.id, post_type, {})
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => obj.id === timeline.id);
      this.timelines[index].interested_data.interested = true;
    }
  }

  changeType(){
    if (this.postSelected === 3) {
      this.timelineForm.get('date').setValidators([Validators.required]);
      this.timelineForm.controls["date"].updateValueAndValidity();
    } else {
      this.timelineForm.controls["date"].setErrors(null);
      this.timelineForm.get('date').clearValidators();
      this.timelineForm.controls["date"].updateValueAndValidity();
    }
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'page': this.page_id,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'date': this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd")
      }
      if (this.postSelected == 1) {
        this.addPageTimeline(model);
      } else if (this.postSelected == 2) {
        this.addTimelinePosition(model);
      } else if (this.postSelected == 3) {
        this.addTimelineEvent(model);
      }
    }
  }

  addPageTimeline(model: any){
    this.profileService.addPageTimeline(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  uploadTimelineDoc(event) {
    let fileSizeVal = 5000;
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0) {
      let fileCount = this.timelineDocs.length + event.target.files.length;
      if (fileCount > 5) {
        this.coverImageUploadError = true;
        this.coverImageUploadMessage = "Maximum 5 photos allowed.";
        event.target.value = null;
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        let file_name = event.target.files[i].name;
        const fileExtension = file_name.split('.').pop();
        if (file_name.length > 30) {
          file_name = "cu-media." + fileExtension;
        }
        const fileSize = Math.round(file.size / 1024);
        if (!allowedExtensions.includes(fileExtension)) {
          this.coverImageUploadError = true
          this.coverImageUploadMessage = "Only image files allowed.";
        } else {
          this.coverImageUploadError = false;
          this.coverImageUploadMessage = "";
        }
        if (fileSize > fileSizeVal) {
          this.coverImageUploadSizeError = true
          this.coverImageUploadSizeMessage = "Image size should be less than 5MB.";
        } else {
          this.coverImageUploadSizeError = false;
          this.coverImageUploadSizeMessage = "";
        }
        if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e): void => {
            const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
            )[1];
            if (this.timelineDocs.length < 5){
              let timelineDoc = { 'previewImg': reader.result, 'document': base64String, 'document_name': file_name };
              this.timelineDocs.unshift(timelineDoc);
            }
            event.target.value = null;
          };
        } else {
          event.target.value = null;
          setTimeout(() => { this.coverImageUploadError = false; this.coverImageUploadSizeError = false; }, 5000);
          break;
        }
      }
    }
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.editTimelineObj['post_type'] = this.radioActive;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'custom-modal mobile-position modal-dialog modal-dialog-centered new-modal-ui newfeeddetail' })
    );
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.linkModalRef.hide();
      this.linkAdded = true;
      setTimeout(() => {this.linkAdded = false;}, 4000);
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  addTimelinePosition(model: any){
    this.profileService.addPagePosition(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  addTimelineEvent(model: any){
    this.profileService.addClubTeamEvents(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  handlePostResponse(response: any){
    if (response.status === 200) {
      this.timelineDocs = [];
      this.modalRef.hide();
      this.timelineForm.reset();
      this.timelinelinkForm.reset();
      this.submitted = false;
      if (this.radioActive == this.postSelected) {
        this.radioActive = this.postSelected;
      } else {
        this.radioActive = 1;
      }
      this.timelineNextPage=1;
      this.getTimelines();
    } else {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  updateTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {'description': this.timelineUpdForm.get('description').value}
      if (this.radioActive == 1) {
        this.updatePageTimeline(model);
      } else if (this.radioActive == 2) {
        this.updatePosition(model);
      } else if (this.radioActive == 3) {
        this.updateEvent(model);
      }
    }
  }

  updatePageTimeline(model: any) {
    this.profileService.updatePageTimeline(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  protected handleupdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      let index = this.timelines.findIndex(obj => obj.id === response.response.id);
      this.timelines[index].description = response.response.description;
      this.timelineUpdForm.reset();
      this.editTimelineObj = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  updatePosition(model: any) {
    this.profileService.updatePagePosition(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updateEvent(model: any) {
    this.profileService.updateClubTeamEvent(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  delTimelineElem(){
    if (this.radioActive == 1) {
      this.deleteTimeline();
    } else if (this.radioActive == 2) {
      this.deletePosition();
    } else if (this.radioActive == 3) {
      this.deleteEvent();
    }
  }

  deleteTimeline(){
    this.profileService.deletePageTimeline(this.editTimelineObj.id)
    .subscribe(response => {
      this.handleDelResponse();
    })
  }

  deletePosition(){
    this.profileService.deletePagePosition(this.editTimelineObj.id)
    .subscribe(response => {
      this.handleDelResponse();
    })
  }

  deleteEvent(){
    this.profileService.deleteClubTeamEvent(this.editTimelineObj.id)
    .subscribe(response => {
      this.handleDelResponse();
    })
  }

  handleDelResponse(){
    let index = this.timelines.findIndex(obj => obj.id === this.editTimelineObj.id);
    this.timelines.splice(index, 1);
    this.editTimelineObj = {};
  }

  getTimelines(){
    if (this.radioActive == 1) {
      this.getAllTimelines();
    } else if (this.radioActive == 2) {
      this.getPositions();
    } else {
      this.getEvents();
    }
  }

  getAllTimelines(){
    let params = '?page=' + this.timelineNextPage;
    this.profileService.pageTimeline(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  getPositions(){
    let params = '?page=' + this.timelineNextPage;
    this.profileService.pagePosition(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  getEvents(){
    let params = '?page=' + this.timelineNextPage;
    this.profileService.clubTeamEvents(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  loadMoreTimelines(){
    let params = '?page=' + this.timelineNextPage;
    this.profileService.pageTimeline(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      }
    });
  }

  loadMorePositions(){
    let params = '?page=' + this.positionNextPage;
    this.profileService.pagePosition(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.positionNextPage == 1) {
          this.positions = response.response.results;
        } else {
          this.positions = this.positions.concat(response.response.results);
        }
        this.positionNextPage = response.response.next;
        this.totalPositions = response.response.total_count;
      }
    });
  }

  loadMoreEvents(){
    let params = '?page=' + this.eventNextPage;
    this.profileService.clubTeamEvents(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.eventNextPage == 1) {
          this.events = response.response.results;
        } else {
          this.events = this.events.concat(response.response.results);
        }
        this.eventNextPage = response.response.next;
        this.totalEvents = response.response.total_count;
      }
    });
  }

  directToProfile(creator_info: any){
    if (creator_info.user_id === this.user.id) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['profile/' + creator_info.user_id + '/']);
    }
  }

}
