import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TeamService } from '../../services/team.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-page-add-team',
  templateUrl: './page-add-team.component.html',
  styleUrls: ['./page-add-team.component.scss']
})
export class PageAddTeamComponent implements OnInit {
  @Input() member: any;
  updateModalRef: BsModalRef;
  teamError: boolean = false;
  iniTeamError: boolean = false;
  addExpTeam: any[];
  addToActTeam: any[];
  addToSchInvTeam: any[];
  addToEduTeam: any[];
  addToInitTeam: any[];
  matchedExp: any[];
  currentTeamExp: any;
  currentTeamEdu: any;
  UserID: any = {};

  constructor(private teamService: TeamService, private modalService: BsModalService, private profileService: ProfileService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getNetworks();
    });
  }

  addProfileTeam(addProfileTeamModal: TemplateRef<any>) {
    this.updateModalRef = this.modalService.show(addProfileTeamModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getNetworks(){
    this.UserID = null;
    const user_id = this.member.student_info.id;
    if (user_id) {
      this.UserID = this.member.student_info.id;
      this.teamService.getNetworks(user_id)
      .subscribe(response => {
        this.addExpTeam = response.response.experiences;
        this.addToActTeam = response.response.act_experiences;
        this.addToSchInvTeam = response.response.sch_experiences;
        this.addToEduTeam = response.response.edu_experiences;
        this.addToInitTeam = response.response.initiatives;
      });
    }
  }

    
  teamExpBusiness(exp: any, addProfileTeamModal: TemplateRef<any>){
    this.matchedExp = [];
    this.currentTeamExp = exp;
    this.teamError = false;
    if (exp.page){
      let exp_type;
      if (exp.experience_type){
        exp_type = "work";
      } else {
        exp_type = "extracurricular";
      }
      this.profileService.getTaggedExpByPage(exp.page, exp.id, exp_type)
        .subscribe(response => {
          if (response.status === 112){
            if (this.currentTeamExp.experience_type && (this.currentTeamExp.experience_type == 1 || this.currentTeamExp.experience_type == 2)){
                let addWorkIndex = this.addExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
                this.addExpTeam[addWorkIndex]['add_to_team'] = 1;
              } else {
                let addActIndex = this.addToActTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
                this.addToActTeam[addActIndex]['add_to_team'] = 1;
              }
            } else if (response.status === 200){
            if (response.response.length > 0){
              this.matchedExp = response.response;
              if (this.matchedExp.length == 1){
                this.addToTeamFnc(this.matchedExp[0]);
              }
              else {
                this.addProfileTeam(addProfileTeamModal);
              }
            }
          }
        })
    }
  }

  teamExpClubTeam(exp: any, addProfileTeamModal: TemplateRef<any>){
    this.currentTeamExp = exp;
    this.matchedExp = [];
    this.teamError = false;
    if (exp.page){
      this.profileService.getTaggedClubTeam(exp.page, exp.id, "school")
      .subscribe(response => {
        if (response.status === 112){
          let clIndex = this.addToSchInvTeam.findIndex(obj => obj.id === this.currentTeamExp.id)
          this.addToSchInvTeam[clIndex]['add_to_team'] = 1;
        } else if (response.status === 200){
          if (response.response.length > 0){
            this.matchedExp = response.response;
            if (this.matchedExp.length == 1){
              this.addToTeamFnc(this.matchedExp[0]);
            }
            else {
              this.addProfileTeam(addProfileTeamModal);
            }
          }
        }
      });
    }
  }

  addToTeamFnc(exp: any) {
    let model = {};
    if (this.currentTeamExp.school_name){
      model = {'tagged_by_sch': exp.id, 'tagged_to_sch': this.currentTeamExp.id}
    } else {
      if (this.currentTeamExp.experience_type) {
        model = {'tagged_to_exp': this.currentTeamExp.id}
      } else {
        model = {'tagged_to_act': this.currentTeamExp.id}
      }
      if (!exp.is_activity){
        model["tagged_by_exp"] = exp.id
      } else {
        model["tagged_by_act"] = exp.id
      }
    }
    model['to_user'] = this.UserID;
    this.teamService.addTeam(model)
    .subscribe(response => {
      this.handleAddTeamResp(response, exp);
    }, error => {
      this.teamError = true;
    });
  }

  private handleAddTeamResp(response: any, exp: any){
    if (response.status === 111){
      this.teamError = true;
    } else if (response.status === 200) {
      let index = this.matchedExp.findIndex(obj => obj.id === exp.id)
      this.matchedExp[index]['team_id'] = response.response.id;
      if (this.currentTeamExp.school_name){
        let clIndex = this.addToSchInvTeam.findIndex(obj => obj.id === this.currentTeamExp.id)
        this.addToSchInvTeam[clIndex]['add_to_team'] = 1;
      } else {
        if (this.currentTeamExp.experience_type && (this.currentTeamExp.experience_type == 1 || this.currentTeamExp.experience_type == 2)){
          let addWorkIndex = this.addExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
          this.addExpTeam[addWorkIndex]['add_to_team'] = 1;
        } else {
          let addActIndex = this.addToActTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
          this.addToActTeam[addActIndex]['add_to_team'] = 1;
        }
      }
    }
  }

  teamEdu(edu: any, addEduTeamModal: TemplateRef<any>){
    this.currentTeamEdu = edu;
    this.matchedExp = [];
    this.teamError = false;
    if (edu.school){
      this.profileService.getTaggedEdu(edu.school.id, edu.id)
      .subscribe(response => {
        if (response.status === 112){
          let eduIndex = this.addToEduTeam.findIndex(obj => obj.id === this.currentTeamEdu.id)
          this.addToEduTeam[eduIndex]['add_to_team'] = 1;
        } else if (response.status === 200){
          if (response.response.length > 0){
            this.matchedExp = response.response;
            if (this.matchedExp.length == 1){
              this.addToTeamEdu(this.matchedExp[0]);
            } else {
              this.addProfileTeam(addEduTeamModal);
            }
          }
        }
      });
    }
  }

  addToTeamEdu(exp: any) {
    let model = {'tagged_by_edu': exp.id, 'tagged_to_edu': this.currentTeamEdu.id, 'to_user': this.UserID}
    this.teamService.addEduTeam(model)
    .subscribe(response => {
      this.handleEduAddTeamResp(response, exp);
    }, error => {
      this.teamError = true;
    });
  }

  private handleEduAddTeamResp(response: any, exp: any){
    if (response.status === 111){
      this.teamError = true;
    } else if (response.status === 200) {
      let index = this.matchedExp.findIndex(obj => obj.id === exp.id);
      this.matchedExp[index]['team_id'] = response.response.id;
      let eduIndex = this.addToEduTeam.findIndex(obj => obj.id === this.currentTeamEdu.id);
      this.addToEduTeam[eduIndex]['add_to_team'] = 1;
    }
  }

  addToIniTeam(initiative: any){
    this.iniTeamError = false;
    let model = {};
    if (initiative.page_info.is_owner) {
      model = {'tagged_owner': initiative.page_info.instance_id}
    } else {
      model = {'tagged_to_ini': initiative.id}
    }
    model['to_user'] = this.UserID;
    this.teamService.addIniTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let addIndex = this.addToInitTeam.findIndex(obj => obj.page_info.instance_id === initiative.page_info.instance_id);
        this.addToInitTeam[addIndex]['add_to_team'] = 1;
      } else {
        this.iniTeamError = true;
        this.errorFocusOut();
      }
    }, error => {
      this.iniTeamError = true;
      this.errorFocusOut();
    });
  }

  errorFocusOut() {
    setTimeout(() => {
      this.iniTeamError = false;
    }, 2000);
  }

}
