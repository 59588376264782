import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {
  public isValid: boolean = false;
  public isExpired: boolean = false;
  public submitted: boolean = false;
  public isResponse: boolean = false;
  public isParent: boolean = false;
  public parentApproved: boolean = true;

  constructor(private router: Router, private authService: AuthenticationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['key']) {
      this.isParent = true;
      if (this.route.snapshot.queryParams['approve'] === "false"){
        this.parentApproved = false;
      }
    }
    let url = this.router.url;
    this.authService.verifyAccount(url)
    .subscribe(response => {
      this.isResponse = true;
      if (response.status === 111) {
          this.isExpired = true;
      } else if (response.status === 200) {
        if (response.response['validity'] == "valid_token") {
            this.isValid = true;
        } else if (response.response['validity'] == 'expired-token') {
            this.isExpired = true;
        } else {
           this.isExpired = true;
        }
      }
    }, error => {
        this.isResponse = true;
        this.isExpired = true;
    });
  }

  verifyEmail(){
    this.submitted = true;
    let url = this.router.url;
    this.authService.verifyAccount(url)
    .subscribe(response => {
      if (response.status === 111) {
          this.isExpired = true;
      } else if (response.status === 200) {
        if (response.response['validity'] == "valid_token") {
            this.isValid = true;
        } else if (response.response['validity'] == 'expired-token') {
            this.isExpired = true;
        } else {
           this.isExpired = true;
        }
      }
    },
      error => {
        this.isExpired = true;
      });

  }
}
