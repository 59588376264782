import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AuthenticationService } from '../../services/authentication.service';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { TeamService } from 'src/app/services/team.service';
import { collegeType } from '../../app.data';
import { InitiativeService } from 'src/app/services/initiative.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class StudentProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  tabActive = 2;
  messageError: string;
  blockUserChecked: boolean = false;
  reportUserChecked: boolean = false;
  profileImage: string;
  verifiers = [];
  links = [];
  profileImage_name: string;
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  UserID: any = {};
  user: any = {};
  loggedInUser: any = {};
  blockReportForm: FormGroup;
  profile: any = {};
  editSkill: any = {};
  isUserBlocked: any;
  editInterest: any = {};
  editBook: any = {};
  editBulletin: any = {};
  editActivity: any = {};
  skills = [];
  interests = [];
  books = [];
  bulletins = [];
  descriptionForm: FormGroup;
  profileHeader: string = "";
  profileHeader_name: string = "";
  public submitted: boolean = false;
  public hasData: boolean = false;
  public hasExpData: boolean = false;
  public infoDiv1 = true;
  reportUser: boolean = false;
  viewMode: boolean = false;
  public learnAbout = true;
  public goalsBucket = true;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  skillLinkArray: any[];
  skillDocArray: any[];
  favouriteList: []
  goalsBucketList: [];
  learnMoreList = [];
  MamList: [];
  public isFirst = false;
  public isSecond = false;
  public canAdd = false;
  expTeam: [];
  eduTeam: [];
  iniTeam: [];
  eduList: any[];
  educationForm: FormGroup;
  schoolEdForm: FormGroup;
  collegeEdForm: FormGroup;
  insText: string = "School";
  isCollegeSelected: boolean = false;
  editSchoolInv: any = {};
  editAward: any = {};
  plchldrState: string = "PR";
  plchldrPostal: string = "Postal";
  editExp: any = {};
  expForm: FormGroup;
  usrWorkExperience = [];
  usrVolunteerWorkExperience = [];
  schoolInvolvements = [];
  UserDetail: any = {};
  expModalTitle: any;
  activities = [];
  awards = [];
  public editable: boolean = false;
  public collegeTypes: any[] = collegeType;
  myInitiatives: any;
  matchedExp: any[];
  usrWorkExperienceTotal: any = 0;
  usrVolunteerExperienceTotal: any = 0;
  schoolInvolvementsTotal: any = 0;
  activitiesTotal: any = 0;
  currentTeamExp: any;
  currentTeamEdu: any;
  teamError: boolean = false;
  iniTeamError: boolean = false;
  eduEditObj: string = null;
  teamMemberNext: any = 1;
  totalTeamMember: any = 0;
  teamMember: any = [];
  iniObj: any = {};
  addWorkExpTeam: any[];
  addVolExpTeam: any[];
  addToActTeam: any[];
  addToSchInvTeam: any[];
  addToEduTeam: any[];
  addToInitTeam: any[];
  copyEmailBool: boolean = false;

  constructor(private authService: AuthenticationService, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder,
    private profileService: ProfileService, private teamService: TeamService, private initiativeService: InitiativeService) {
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', Validators.maxLength(1000)),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    this.blockReportForm = this.formBuilder.group({
      block_user: new FormControl(),
      report_user: new FormControl(),
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
    this.educationForm = this.formBuilder.group({
      is_college: new FormControl('', [Validators.required,]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    });
    this.schoolEdForm = this.formBuilder.group({
      interests: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });
    this.collegeEdForm = this.formBuilder.group({
      college_degree: new FormControl(''),
      program: new FormControl(''),
      minors: new FormControl(''),
      majors: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    var url = document.URL.split('/');
    this.UserID = url[url.length - 1];
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.loggedInUser = currentUser;
    if (this.loggedInUser.user_role !== 2) {
      this.router.navigate(['**']);
    }
    if (!this.loggedInUser.approval_status && this.loggedInUser.id != this.UserID ) {
      this.router.navigate(['/dashboard']);
    }
    this.profileService.OtherUserProfile(this.UserID)
    .subscribe(response => {
      if (response.message == "not-found"){
        this.router.navigate(['**']);
      } else {
        this.user = response.response;
        this.profileHeader = this.user.header_image;
        this.profileHeader_name = this.user.header_image;
        this.profileImage = this.user.display_picture;
        this.profileImage_name = this.user.display_picture;
      }
    }, error => {
      this.router.navigate(['**']);
    });
    if (this.loggedInUser.id == this.UserID){
      this.viewMode = true;
    } else {
      this.getBanner();
    }
    this.profileService.getDescription(this.UserID)
    .subscribe(response => {
      if (response.response['id'] !== undefined) {
        this.hasData = true;
        this.profile = response.response;
      }
    });
    this.CheckUserBlockStatus();
    this.getVerifiers();
    this.profileService.getSkills(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.skills = response.response;
      }
    });
    this.profileService.getFavourite(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0){
        this.hasData = true;
        this.favouriteList = response.response;
      }
    });
    this.profileService.getGoalsBucket(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.goalsBucketList = response.response;
      }
    });
    this.profileService.getMoreAboutMe(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0){
        this.hasData = true;
        this.MamList = response.response;
      }
    });
    this.profileService.getLearnMore(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.learnMoreList = response.response;
      }
    });
    this.profileService.getInterests(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.interests = response.response;
      }
    });
    this.profileService.getBooks(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.books = response.response;
      }
    });
    this.profileService.getBulletin(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
        this.bulletins = response.response;
      }
    });
    this.getEducationlist();
    this.getAwards();
    if (this.loggedInUser.age_group !== 1) {
      this.getActivities();
      this.getWorkExp();
      this.getVolunExp();
    }
    this.initiativeService.getOtherInitiatives(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasExpData = true;
        this.myInitiatives = response.response;
        this.addToInitTeam = this.myInitiatives.filter(obj => obj.add_to_team === 5);
        if (this.addToInitTeam.length > 0){
          this.canAdd = true;
        }
      } else {
        this.myInitiatives = [];
      }
    });
    this.getSchInvList();
    this.getLinks();
  }

  openSetting(settingModal: TemplateRef<any>) {
    this.reportUser = false;
    this.blockUserChecked = false;
    this.reportUserChecked = false;
    this.blockReportForm.reset();
    this.modalRef = this.modalService.show(settingModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideConfig2 = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideConfigExp = {
    "slidesToShow": 2,
    "slidesToScroll": 1,  
    "infinite": false,
    "autoplay":true,
    "arrow": true,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideBooks = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  favouritesSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  mamSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  sliderInitiative = {
    "slidesToShow": 4,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  getEducationlist(){
    this.profileService.otherStudentlistEducation(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasExpData = true;
        this.eduList = response.response;
        this.addToEduTeam = this.eduList.filter(obj => obj.add_to_team === 5);
        if (this.addToEduTeam.length > 0){
          this.canAdd = true;
        }
      } else {
        this.eduList = [];
      }
    });
  }

  getAwards(){
    this.profileService.getAwards(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasExpData = true;
        this.awards = response.response;
      }
    });
  }

  getWorkExp(){
    this.profileService.getOtherUserWorkExp("1", this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasExpData = true;
        this.usrWorkExperience = response.response;
        this.usrWorkExperienceTotal = response.response.length;
        if (this.loggedInUser.age_group !== 1) {
          this.addWorkExpTeam = this.usrWorkExperience.filter(obj => obj.add_to_team === 5);
          if (this.addWorkExpTeam.length > 0) {
            this.canAdd = true;
          }
        }
      } else {
        this.usrWorkExperience = [];
      }
    });
  }

  getVolunExp(){
    this.profileService.getOtherUserWorkExp("2", this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasExpData = true;
          this.usrVolunteerWorkExperience = response.response;
          this.usrVolunteerExperienceTotal = response.response.length;
          if (this.loggedInUser.age_group !== 1) {
            this.addVolExpTeam = this.usrVolunteerWorkExperience.filter(obj => obj.add_to_team === 5);
            if (this.addVolExpTeam.length > 0){
              this.canAdd = true;
            }
          }
        } else {
          this.usrVolunteerWorkExperience = [];
        }
      });
  }

  getSchInvList(){
    this.profileService.getOtherUserSchoolInvolvement(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasExpData = true;
        this.schoolInvolvements = response.response;
        this.schoolInvolvementsTotal = response.response.length;
        this.addToSchInvTeam = this.schoolInvolvements.filter(obj => obj.add_to_team === 5);
        if (this.addToSchInvTeam.length > 0){
          this.canAdd = true;
        }
      } else {
        this.schoolInvolvements = [];
      }
    });
  }

  getLinks(){
    this.profileService.getSocialLinks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.links = response.response;
        } else {
          this.links = [];
        }
      });
  }

  sendMessage(){
    this.profileService.createConversation({"user2": this.UserID})
    .subscribe(response => {
      if(response.status == 200) {
        this.router.navigate(['message-chat']);
      } else if (response.status < 200) {
        this.messageError = response.message;
      } else {
        this.messageError = "Something went wrong. Please try again later.";
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  openMsgModal(msgModal: TemplateRef<any>) {
    this.sendMessage();
    this.modalRef = this.modalService.show(msgModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal custom-profile-modal' })
    );
  }

  openNewContactusModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position contact-modal' })
    );
  }

  openVerifiersModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  bannerModal(firstModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(firstModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  openNetworkModal(networkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(networkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal  new-modal-ui custom-profile-modal' })
    );
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  reportReason() {
    this.reportUser = !this.reportUser;
    this.reportUserChecked = !this.reportUserChecked;
  }
  blockUser() {
    this.blockUserChecked = !this.blockUserChecked;
  }

  updateSkillModal(skillpopup: TemplateRef<any>, skill: any) {
    this.editSkill = skill;
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.skillLinkArray = skill.user_link;
    this.skillDocArray = skill.user_document;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateInterestModal(skillpopup: TemplateRef<any>, interest: any) {
    this.editInterest = interest;
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.skillLinkArray = interest.user_link;
    this.skillDocArray = interest.user_document;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updatebookModal(skillpopup: TemplateRef<any>, book: any) {
    this.editBook = book;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    this.modalRef = this.modalService.show(bulletinDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal new-modal-ui custom-profile-modal' })
    );
  }

  booksListing(booksListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(booksListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getSkills() {
    this.profileService.getSkills(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        } else {
          this.skills = [];
        }
      });
  }

  getInterests() {
    this.profileService.getInterests(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.interests = response.response;
        } else {
          this.interests = [];
        }
      });
  }

  getBooks() {
    this.profileService.getBooks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        } else {
          this.books = [];
        }
      });
  }

  getBulletin() {
    this.profileService.getBulletin(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
        }
      });
  }

  blockUnblockUser(isValid: boolean, id: any) {
    if (this.blockUserChecked) {
      if (this.isUserBlocked) {
        var block_user = false
      } else {
        var block_user = true
      }
      var data = { 'blocked_user': id, 'is_blocked': block_user }
      this.authService.BlockUserPost(data)
        .subscribe(response => {});
    }
    if (this.reportUserChecked) {
      var description;
      if (!this.blockReportForm.valid) {
        this.submitted = true;
        return;
      }
      if (this.blockReportForm.value['report_description']) {
        description = this.blockReportForm.value['report_description']
      } else {
        description = '';
      }
      var report_data = {
        'reported_user': id, 'reason': this.blockReportForm.value['report_reason'],
        'description': description
      }
      this.authService.ReportUser(report_data).subscribe(respones => {});
    }
    this.modalRef.hide();
    window.location.reload();
  }

  CheckUserBlockStatus() {
    this.profileService.CheckUserBlockStatus(this.UserID)
      .subscribe(response => {
        this.isUserBlocked = response.response.is_user_blocked;
      });
  }

  getVerifiers() {
    this.profileService.listOtherProfileVerifiers(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      });
  }

  getBanner(){
    this.teamService.getNetworkBanner(this.UserID)
    .subscribe(response => {
      if (response.status === 200) {
        this.isFirst = response.response.is_first;
        this.isSecond = response.response.is_second;
        this.expTeam = response.response.exp_team;
        this.eduTeam = response.response.edu_team;
        this.iniTeam = response.response.ini_team;
      } else {
        this.isFirst = false;
        this.isSecond = false;
        this.expTeam = [];
        this.eduTeam = [];
        this.iniTeam = [];
      }
    });
  }

  showImg(headerImages: TemplateRef<any>) {
    if (this.profileHeader){
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
      );
    }
  }

  teamModal(eduObj: any, teamModal: TemplateRef<any>) {
    this.eduEditObj = eduObj.id;
    this.teamMemberNext = 1;
    this.totalTeamMember = 0;
    this.teamMember = [];
    this.getProfileTeamMembers('');
    this.modalRef = this.modalService.show(teamModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  iniTeamModal(initiative: any, teamModal: TemplateRef<any>) {
    this.iniObj = initiative;
    this.teamMemberNext = 1;
    this.totalTeamMember = 0;
    this.teamMember = [];
    if (initiative.page_info.is_owner) {
      this.getIniTeamMembers('');
    } else {
      this.getUserIniTeamMembers('');
    }
    this.modalRef = this.modalService.show(teamModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  updateActivityModal(editActivityTemplate: TemplateRef<any>, activity: any) {
    this.editActivity = activity;
    this.updateModalRef = this.modalService.show(editActivityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getActivities() {
    this.profileService.getOtherUserActivities(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasExpData = true;
          this.activities = response.response;
          this.activitiesTotal = response.response.length;
          if (this.loggedInUser.age_group !== 1) {
            this.addToActTeam = this.activities.filter(obj => obj.add_to_team === 5);
            if (this.addToActTeam.length > 0){
              this.canAdd = true;
            }
          }
        } else {
          this.activities = [];
        }
      });
  }

  eduformUpdate(response) {
    this.plchldrState = "PR";
    this.plchldrPostal = "Postal";
    this.insText = "School"
    this.isCollegeSelected = false;
    this.educationForm.controls['country'].setValue('Canada');
    this.educationForm.controls['is_college'].setValue("false");
    this.educationForm.controls['school_name'].setValue(response.school.school_name);
    this.educationForm.controls['state'].setValue(response.school.state);
    this.educationForm.controls['country'].setValue(response.school.country);
    let start_date;
    let end_date;
    if (this.editable) {
      start_date = new Date(response.start_date);
      end_date = new Date(response.end_date)
    } else {
      start_date = response.start_date;
      end_date = response.end_date
    }
    if (response.school.is_college) {
      this.educationForm.controls['is_college'].setValue("true");
      this.insText = "College/University";
      this.isCollegeSelected = true;
      this.collegeEdForm.controls['start_date'].setValue(parseInt(start_date));
      this.collegeEdForm.controls['end_date'].setValue(parseInt(end_date));
      this.collegeEdForm.controls['program'].setValue(response.program);
      this.collegeEdForm.controls['majors'].setValue(response.majors.toString());
      this.collegeEdForm.controls['minors'].setValue(response.minors.toString());
      this.collegeEdForm.controls['college_degree'].setValue(response.degree);
    } else {
      this.educationForm.controls['is_college'].setValue("false");
      this.insText = "School";
      this.isCollegeSelected = false;
      this.schoolEdForm.controls['start_date'].setValue(parseInt(start_date));
      this.schoolEdForm.controls['end_date'].setValue(parseInt(end_date));
      if (response.interests) {
        this.schoolEdForm.controls['interests'].setValue(response.interests.toString());
      } else {
        this.schoolEdForm.controls['interests'].setValue(response.interests);
      }
    }

    if (response.school.country == 'Canada') {
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
    } else {
      this.plchldrState = "State";
      this.plchldrPostal = "Zip Code";
    }

  }

  updateEducationPopup(staticEdPopup: TemplateRef<any>, ed_id: string) {
    this.eduformUpdate(ed_id);
    this.modalRef = this.modalService.show(staticEdPopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.editExp = exp;
    if (exp.experience_type == 1) {
      this.expModalTitle = 'Work';
    } else {
      this.expModalTitle = 'Volunteer';
    }
    this.updateModalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  viewModalAwards(viewAwardsTemplate: TemplateRef<any>, award: any) {
    this.editAward = award;
    this.modalRef = this.modalService.show(viewAwardsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
      );
  }

  updateSchoolInvolvementModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.editSchoolInv = exp;
    this.updateModalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  workExpListing(skillListModal: TemplateRef<any>) {
    this.expModalTitle = 'Work';
    this.modalRef = this.modalService.show(skillListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addProfileTeam(addProfileTeamModal: TemplateRef<any>) {
    this.expModalTitle = 'Work';
    this.updateModalRef = this.modalService.show(addProfileTeamModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addProfileEduTeam(addEduTeamModal: TemplateRef<any>) {
    this.updateModalRef = this.modalService.show(addEduTeamModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  volunteerListing(volunteerListModal: TemplateRef<any>) {
    this.expModalTitle = 'Volunteer';
    this.modalRef = this.modalService.show(volunteerListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  extraCurricularListing(extraCurricularListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(extraCurricularListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  schoolInvolvementListing(schoolInvolvementListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolInvolvementListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  redirectPage(experience: any){
    if (experience.page && !experience.related_data.is_deleted) {
      let url = '/page/' + experience.page;
      if (experience.related_data.is_related || experience.related_data.is_unclaimed) {
        url = '/page/' + experience.page + '/profile-school';
      }
      this.router.navigate([url]);
    }
  }

  redirectToPage(experience: any){
    if (!experience.related_data.is_deleted) {
      if (experience.page) {
        let url = '/page/' + experience.page;
        if (experience.related_data.is_related || experience.related_data.is_unclaimed) {
          url = '/page/' + experience.page + '/profile-business';
        }
        this.router.navigate([url]);
      } else if (experience.school) {
        let url = '/school/' + experience.school;
        this.router.navigate([url]);
      }
    }
  }

  jumpToInitiative(currentInitiative: any){
    let instance = currentInitiative.page_info;
    let isDeleted = instance.is_deleted
    let initiativeId = instance.instance_id;
    if (!isDeleted){
      this.router.navigate(['initiatives/' + initiativeId + '/'])
    }
  }

  teamExpBusiness(exp: any, addProfileTeamModal: TemplateRef<any>){
    this.matchedExp = [];
    this.currentTeamExp = exp;
    this.teamError = false;
    if (exp.page){
      let exp_type;
      if (exp.experience_type){
        exp_type = "work";
      } else {
        exp_type = "extracurricular";
      }
      this.profileService.getTaggedExpByPage(exp.page, exp.id, exp_type)
        .subscribe(response => {
          if (response.status === 112){
            if (this.currentTeamExp.experience_type && this.currentTeamExp.experience_type == 1){
                let index = this.usrWorkExperience.findIndex(obj => obj.id === this.currentTeamExp.id);
                this.usrWorkExperience[index]['add_to_team'] = 1;
                if (this.canAdd){
                  let addWorkIndex = this.addWorkExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
                  this.addWorkExpTeam[addWorkIndex]['add_to_team'] = 1;
                }
              } else if (this.currentTeamExp.experience_type && this.currentTeamExp.experience_type == 2) {
                let index = this.usrVolunteerWorkExperience.findIndex(obj => obj.id === this.currentTeamExp.id)
                this.usrVolunteerWorkExperience[index]['add_to_team'] = 1;
                if (this.canAdd){
                  let addVolIndex = this.addVolExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
                  this.addVolExpTeam[addVolIndex]['add_to_team'] = 1;
                }
              } else {
                let index = this.activities.findIndex(obj => obj.id === this.currentTeamExp.id);
                this.activities[index]['add_to_team'] = 1;
                if (this.canAdd){
                  let addActIndex = this.addToActTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
                  this.addToActTeam[addActIndex]['add_to_team'] = 1;
                }
              }
            } else if (response.status === 200){
            if (response.response.length > 0){
              this.matchedExp = response.response;
              if (this.matchedExp.length == 1){
                this.addToTeamFnc(this.matchedExp[0]);
              }
              else {
                this.addProfileTeam(addProfileTeamModal);
              }
            }
          }
        })
    }
  }

  teamExpClubTeam(exp: any, addProfileTeamModal: TemplateRef<any>){
    this.currentTeamExp = exp;
    this.matchedExp = [];
    this.teamError = false;
    if (exp.page){
      this.profileService.getTaggedClubTeam(exp.page, exp.id, "school")
        .subscribe(response => {
          if (response.status === 112){
            let index = this.schoolInvolvements.findIndex(obj => obj.id === this.currentTeamExp.id)
            this.schoolInvolvements[index]['add_to_team'] = 1;
            if (this.canAdd){
              let clIndex = this.addToSchInvTeam.findIndex(obj => obj.id === this.currentTeamExp.id)
              this.addToSchInvTeam[clIndex]['add_to_team'] = 1;
            }
          } else if (response.status === 200){
            if (response.response.length > 0){
              this.matchedExp = response.response;
              if (this.matchedExp.length == 1){
                this.addToTeamFnc(this.matchedExp[0]);
              }
              else {
                this.addProfileTeam(addProfileTeamModal);
              }
            }
          }
        })
    }
  }

  addToTeamFnc(exp: any) {
    let model = {};
    if (this.currentTeamExp.school_name){
      model = {'tagged_by_sch': exp.id, 'tagged_to_sch': this.currentTeamExp.id}
    } else {
      if (this.currentTeamExp.experience_type) {
        model = {'tagged_to_exp': this.currentTeamExp.id}
      }
      else {
        model = {'tagged_to_act': this.currentTeamExp.id}
      }
      if (!exp.is_activity){
        model["tagged_by_exp"] = exp.id
      } else {
        model["tagged_by_act"] = exp.id
      }
    }
    model['to_user'] = this.UserID;
    this.teamService.addTeam(model)
      .subscribe(response => {
        this.handleAddTeamResp(response, exp);
      }, error => {
        this.teamError = true;
      })
  }

  private handleAddTeamResp(response: any, exp: any){
    if (response.status === 111){
      this.teamError = true;
    } else if (response.status === 200) {
      let index = this.matchedExp.findIndex(obj => obj.id === exp.id)
      this.matchedExp[index]['team_id'] = response.response.id;
      if (this.currentTeamExp.school_name){
        index = this.schoolInvolvements.findIndex(obj => obj.id === this.currentTeamExp.id)
        this.schoolInvolvements[index]['add_to_team'] = 1;
        if (this.canAdd){
          let clIndex = this.addToSchInvTeam.findIndex(obj => obj.id === this.currentTeamExp.id)
          this.addToSchInvTeam[clIndex]['add_to_team'] = 1;
        }
      } else {
        if (this.currentTeamExp.experience_type && this.currentTeamExp.experience_type == 1){
          index = this.usrWorkExperience.findIndex(obj => obj.id === this.currentTeamExp.id);
          this.usrWorkExperience[index]['add_to_team'] = 1;
          if (this.canAdd){
            let addWorkIndex = this.addWorkExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
            this.addWorkExpTeam[addWorkIndex]['add_to_team'] = 1;
          }
        } else if (this.currentTeamExp.experience_type && this.currentTeamExp.experience_type == 2) {
          index = this.usrVolunteerWorkExperience.findIndex(obj => obj.id === this.currentTeamExp.id)
          this.usrVolunteerWorkExperience[index]['add_to_team'] = 1;
          if (this.canAdd){
            let addVolIndex = this.addVolExpTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
            this.addVolExpTeam[addVolIndex]['add_to_team'] = 1;
          }
        } else {
          index = this.activities.findIndex(obj => obj.id === this.currentTeamExp.id);
          this.activities[index]['add_to_team'] = 1;
          if (this.canAdd){
            let addActIndex = this.addToActTeam.findIndex(obj => obj.id === this.currentTeamExp.id);
            this.addToActTeam[addActIndex]['add_to_team'] = 1;
          }
        }
      }
    }
  }

  getActivitiesRecent () {
    this.profileService.getOtherUserActivitiesRecent(this.UserID)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.hasData = true;
          this.activities = response.response.results;
          this.activitiesTotal = response.response.total_count;
        } else {
          this.activities = [];
        }
      });
  }

  getProfileTeamMembers(params: any){
    this.profileService.getProfileEduMembers(this.eduEditObj, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  loadMoreTeamMemberProfile() {
    if (this.teamMemberNext != null) {
      let params = '?page=' + this.teamMemberNext;
      this.getProfileTeamMembers(params);
    }
  }


  getIniTeamMembers(params: any){
    let iniId = this.iniObj.page_info.instance_id;
    this.profileService.getIniTeamMembers(iniId, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  getUserIniTeamMembers(params: any){
    this.profileService.getUserIniTeamMembers(this.iniObj.id, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  loadMoreIniTeamMember() {
    if (this.teamMemberNext != null) {
      let params = '?page=' + this.teamMemberNext;
      if (this.iniObj.page_info.is_owner) {
        this.getIniTeamMembers(params);
      } else {
        this.getUserIniTeamMembers(params);
      }
    }
  }

  teamEdu(edu: any, addEduTeamModal: TemplateRef<any>){
    this.currentTeamEdu = edu;
    this.matchedExp = [];
    this.teamError = false;
    if (edu.school){
      this.profileService.getTaggedEdu(edu.school.id, edu.id)
        .subscribe(response => {
          if (response.status === 112){
            let index = this.eduList.findIndex(obj => obj.id === this.currentTeamEdu.id)
            this.eduList[index]['add_to_team'] = 1;
            if (this.canAdd){
              let eduIndex = this.addToEduTeam.findIndex(obj => obj.id === this.currentTeamEdu.id)
              this.addToEduTeam[eduIndex]['add_to_team'] = 1;
            }
          } else if (response.status === 200){
            if (response.response.length > 0){
              this.matchedExp = response.response;
              if (this.matchedExp.length == 1){
                this.addToTeamEdu(this.matchedExp[0]);
              } else {
                this.addProfileEduTeam(addEduTeamModal);
              }
            }
          }
        });
    }
  }

  addToTeamEdu(exp: any) {
    let model = {'tagged_by_edu': exp.id, 'tagged_to_edu': this.currentTeamEdu.id, 'to_user': this.UserID}
    this.teamService.addEduTeam(model)
      .subscribe(response => {
        this.handleEduAddTeamResp(response, exp);
      }, error => {
        this.teamError = true;
      });
  }

  private handleEduAddTeamResp(response: any, exp: any){
    if (response.status === 111){
      this.teamError = true;
    } else if (response.status === 200) {
      let index = this.matchedExp.findIndex(obj => obj.id === exp.id);
      this.matchedExp[index]['team_id'] = response.response.id;
      index = this.eduList.findIndex(obj => obj.id === this.currentTeamEdu.id);
      this.eduList[index]['add_to_team'] = 1;
      if (this.canAdd){
        let eduIndex = this.addToEduTeam.findIndex(obj => obj.id === this.currentTeamEdu.id);
        this.addToEduTeam[eduIndex]['add_to_team'] = 1;
      }
    }
  }

  addToIniTeam(initiative: any){
    this.iniTeamError = false;
    let model = {};
    if (initiative.page_info.is_owner) {
      model = {'tagged_owner': initiative.page_info.instance_id}
    } else {
      model = {'tagged_to_ini': initiative.id}
    }
    model['to_user'] = this.UserID;
    this.teamService.addIniTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.myInitiatives.findIndex(obj => obj.page_info.instance_id === initiative.page_info.instance_id);
        this.myInitiatives[index]['add_to_team'] = 1;
        if (this.canAdd){
          let addIndex = this.addToInitTeam.findIndex(obj => obj.page_info.instance_id === initiative.page_info.instance_id);
          this.addToInitTeam[addIndex]['add_to_team'] = 1;
        }
      } else {
        this.iniTeamError = true;
        this.errorFocusOut();
      }
    }, error => {
      this.iniTeamError = true;
      this.errorFocusOut();
    })
  }

  errorFocusOut() {
    setTimeout(() => {
      this.iniTeamError = false;
    }, 2000);
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyEmail(){
    let index = this.links.findIndex(obj => obj.social_type == 6);
    let msg = this.links[index].link;
    this.setToCopy(msg);
    this.copyEmailBool = true;
    setTimeout(() => {this.copyEmailBool = false  ;}, 3000);
  }

}
