<div class="comment-box">
    <h3>Comments ({{totalCommentsCount}})</h3>
    <form [formGroup]="commentForm" novalidate>
    <div class="input-box">
      <textarea type="text" placeholder="Write your comment" formControlName="text"
        [mentionConfig]="{items:teams, labelKey:'name', mentionSelect:chngFormat}"
        [mentionListTemplate]="mentionListTemplate"></textarea>
        <div class="error-message"
        *ngIf="commentForm.get('text').hasError('required') && (commentForm.get('text').dirty || commentForm.get('text').touched || submitted)">
        This field is required.
      </div>
      <div class="error-message" *ngIf="commentForm.get('text').hasError('whitespace') && !commentForm.get('text').hasError('required') && submitted">
        Please enter valid input
      </div>
      <button type="button" (click)="createComment(commentForm.valid)">Post</button>
    </div>
  </form>
    <!--Comment List Main comment list-->
    <ul class="comment-list">
      <li *ngFor="let comment of comments">
        <div class="img-box">
          <img src="{{comment.creator_info.display_picture? comment.creator_info.display_picture : 'assets/images/p-img.png'}}" alt="profile" />
        </div>
        <div class="content-box">
          <div class="w-100 name-comments">
              <a href="profile/{{comment.creator_info.user_id}}/" class="person-name">{{comment.creator_info.name}}</a>
              <p innerHtml="{{comment.text}}"></p>
          </div>
          <div class="date-box">
            <span>{{comment.created_date | date: 'MMMM d, y'}}</span>
            <button *ngIf="user.id == comment.creator_info.user_id" type="button" class="reply-btn" (click)="openDeleteModal(comment.id,delTimelineTemplate)">
              Delete
            </button>
            <button *ngIf="user.id !== comment.creator_info.user_id" type="button" class="reply-btn" (click)="openReply(comment.id, replyModal)">
              Reply
            </button>
            <button class="reply-btn ml-3" *ngIf="comment.reply_count > 0 && commentReplyId != comment.id" type="button" (click)="enableReplies(comment.id)">View Replies ({{comment.reply_count}})</button>
            <app-replies *ngIf="commentReplyId == comment.id" [notifier]="childNotifier" (replyAdded)="replyAdded($event)" [commentId]="comment.id" [editFeedId]="editFeedId" [editFeedPostType]="editFeedPostType"></app-replies>
          </div>
        </div>
      </li>
      <!--Comment List Main comment list items-->
    </ul>
    <!--Comment List Main comment list items-->
    <!--Load More-->
    <button *ngIf="totalCommentsCount > 10 && totalCommentsCount > comments.length" 
      type="button" class="load-more" (click)="getComments()">Load More</button>
    <!--Load More-->
  </div>

  <ng-template #replyModal>
    <div class="modal-body new-body">
      <div class="create-box timeline">
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
          <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg" alt="arrow" />
        </button>
      </div>
  
      <div class="comment-box reply-box">
        <form [formGroup]="replyForm" novalidate>
          <div class="input-box mt-5">
            <textarea type="text" placeholder="Write your reply" formControlName="text"
              [mentionConfig]="{items:teams, labelKey:'name', mentionSelect:chngFormat}"
              [mentionListTemplate]="mentionListTemplate"></textarea>
              <div class="error-message"
              *ngIf="replyForm.get('text').hasError('required') && (replyForm.get('text').dirty || replyForm.get('text').touched || replySubmitted)">
              This field is required.
            </div>
            <div class="error-message" *ngIf="replyForm.get('text').hasError('whitespace') && !replyForm.get('text').hasError('required') && replySubmitted">
              Please enter valid input
            </div>
            <button type="button" (click)="createReply(replyForm.valid)">Post</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  
  <ng-template #mentionListTemplate let-item="item">
    <img class="mr-2" src="{{item.profile_pic? item.profile_pic : 'assets/images/p-img.png'}}" alt="profile-image" width="25" height="25">
    <span>{{item.name}}</span>
  </ng-template>

  <ng-template #delTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4 class="w-100 text-center">Are you sure you want to delete this comment ?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="deleteComment()">
            Confirm
        </button>
    </div>
</ng-template>
