<div *ngIf="usrWorkExperience.length > 0" class="content-profile-white-box">
<div class="titleDiv d-flex align-items-center">
  <h3 class="with-list-icon">Volunteer
  </h3>
  <a href="javascript:void()" class="float-right" (click)="expModal(expTemplate)">
    <img class="d-block" src="assets/images/plus-teal.svg" alt="icon">
  </a>
</div>

<div class="detailContent px-0">
  <div class="single-image-wrap">
    <div class="media border-row cursor-pointer" *ngFor="let experience of usrWorkExperience | slice:0:3"
      (click)="updateExpModal(editExpTemplate, experience)">
      <div class="exp-verified">
        <div class="exp-verified-Inner">
          <img alt="image" (click)="redirectPage(experience);$event.stopPropagation()"
            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
            alt="Education Cover" class="thumb-img">
        </div>
        <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">

        </div>
      </div>
      <div class="media-body ml-3">
        <h3 class="top-name">{{experience.position}}</h3>
        <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
        <h4 *ngIf="!experience.organization_title">Self-employed</h4>
        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
          <span>{{experience.start_date | date: 'MMM y'}}</span> <span> - </span>
          <ng-template
            [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
            [ngIfElse]="currentTemp">
            <span>{{experience.end_date | date: 'MMM y'}}</span>
          </ng-template>
        </h5>
        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
          <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
        </h5>
        <p class="m-0">
          <strong>
            <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
            <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
            <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
            <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
            <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
          </strong>
        </p>
        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
        <div class="addRefRow">
          <div class="addRefRowInner">
              <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                  <p class="my-0 people-number" (click)="$event.stopPropagation();teamMemberView(experience)">
                      <img src="assets/images/verifiedUserActive.svg" alt="icon">
                      <span>{{ experience.related_data.exp_count }}</span>
                    </p>
              </div>
                <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                    <img alt="image" src="assets/images/verifyProfile.svg">
                  </p>
            <a href="javascript:void(0);" *ngIf="experience.page"
              (click)="$event.stopPropagation(); addTeamMember(experience);">
              <img src="assets/images/verifiedUserActive.svg" alt="icon">
              <span>Add team members</span>
            </a>
            <a href="javascript:void(0);"
              *ngIf="!experience.verified_by && experience.verification_status == 1 && user.age_group > 1 && (experience.page || experience.school)"
              (click)="addReferenceListing(addReferenceModal, experience); $event.stopPropagation()">
              <img src="assets/images/verifyProfile.svg" alt="icon">
              <span>Add reference</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="viewMoreWrapper" *ngIf="usrWorkExperienceTotal > 3">
    <span class="viewMore" (click)="volunteerListing(volunteerListModal)">
      View All ({{usrWorkExperienceTotal}})
    </span>
  </div>
</div>
</div>
<ng-template #editExpTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Volunteer Experience</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-box">
      <p class="d-flex align-items-center mb-2" *ngIf="editExp.approver_name && editExp.verified_date">
        <span><img alt="" src="assets/images/verifyProfile.svg" class="tick-verified-img"></span>Verified
      </p>
      <form [formGroup]="expUpdForm" novalidate class="formHide">
        <div class="experience-div media w-100">
          <div class="media-body ml-md-3">
            <div class="row form-group">
              <div class="col-6">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <label>Business</label>
                  </div>
                  <div class="col-md-12">
                    <input type="text" class="form-control custom-input-readonly" placeholder="Enter Business Name"
                      readonly formControlName="title" (focusout)="focusOutFunction()">
                    <div class="error-message"
                      *ngIf="expUpdForm.get('title').hasError('required') && (expUpdForm.get('title').dirty || expUpdForm.get('title').touched || submitted)">
                      This field is required.
                    </div>
                    <div class="error-message"
                      *ngIf="expUpdForm.get('title').hasError('maxlength') && (expUpdForm.get('title').dirty || expUpdForm.get('title').touched)">
                      Max 60 characters allowed.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <label>Position</label>
                  </div>
                  <div class="col-md-12">
                    <input type="text" class="form-control custom-input-readonly" placeholder="Enter Position" readonly
                      formControlName="position">
                    <div class="error-message"
                      *ngIf="expUpdForm.get('position').hasError('required') && (expUpdForm.get('position').dirty || expUpdForm.get('position').touched || submitted)">
                      This field is required.
                    </div>
                    <div class="error-message"
                      *ngIf="expUpdForm.get('position').hasError('maxlength') && (expUpdForm.get('position').dirty || expUpdForm.get('position').touched)">
                      Max 50 characters allowed.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="editExp.verified_by">
              <div class="col-6">
                <label>
                  Professional Reference
                  <input type="text" class="form-control custom-input-readonly" placeholder="Professional Reference"
                    readonly value="{{editExp.verified_by}}">
                </label>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label>Dates</label>
              </div>
              <div class="col-md-6 col-6">
                <label>
                  <span *ngIf="editExp.start_date != editExp.end_date" style="color: #335175;" class="mr-2">Start</span>
                  <span *ngIf="editExp.start_date == editExp.end_date" style="color: #335175;" class="mr-2">Date</span>
                  <input type="text" class="form-control custom-input-readonly" readonly value="{{editExp.start_date | date: 'MMMM d, y'}}">
                  <div class="error-message"
                    *ngIf="expUpdForm.get('start_date').hasError('required') && (expUpdForm.get('start_date').dirty || expUpdForm.get('start_date').touched || submitted)">
                    This field is required.
                  </div>
                </label>
              </div>
              <div class="col-md-6 col-6" *ngIf="editExp.start_date != editExp.end_date">
                <label>End
                  <input type="text" class="form-control custom-input-readonly" *ngIf="!canEditEndDate()" readonly
                    value="{{editExp.end_date | date: 'MMMM d, y'}}">
                  <input type="text" class="form-control" bsDatepicker *ngIf="canEditEndDate()" [maxDate]="today"
                    [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true }" formControlName="end_date"
                    style="background-color: white;" readonly>
                  <div class="error-message"
                    *ngIf="expUpdForm.errors?.invalidEndDate && (expUpdForm.touched || expUpdForm.dirty)">
                    Start date should not be greater than end date
                  </div>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label>Total Hours</label>
              </div>
              <div class="col-md-12">
                <input [readonly]="editExp.page || editExp.school" type="number"
                  class="form-control custom-input-readonly" placeholder="Type Here" formControlName="total_hours">
                <div class="error-message"
                  *ngIf="expUpdForm.get('total_hours').hasError('required') && (expUpdForm.get('total_hours').dirty || expUpdForm.get('total_hours').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="expUpdForm.get('total_hours').hasError('min') && (expUpdForm.get('total_hours').dirty || expUpdForm.get('total_hours').touched)">
                  Please enter valid value for number of hours.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label>Top Skills Built</label>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-4">
                    <input type="text" class="form-control  " formControlName="skill_1">
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_1').hasError('required') && (expUpdForm.get('skill_1').dirty || expUpdForm.get('skill_1').touched || submitted)">
                      This field is required.
                    </div>
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_1').hasError('maxlength') && (expUpdForm.get('skill_1').dirty || expUpdForm.get('skill_1').touched)">
                      Max 30 characters allowed.
                    </div>
                  </div>
                  <div class="col-4">
                    <input type="text" class="form-control  " formControlName="skill_2">
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_2').hasError('required') && (expUpdForm.get('skill_2').dirty || expUpdForm.get('skill_2').touched || submitted)">
                      This field is required.
                    </div>
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_2').hasError('maxlength') && (expUpdForm.get('skill_2').dirty || expUpdForm.get('skill_2').touched)">
                      Max 30 characters allowed.
                    </div>
                  </div>
                  <div class="col-4">
                    <input type="text" class="form-control  " formControlName="skill_3">
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_3').hasError('required') && (expUpdForm.get('skill_3').dirty || expUpdForm.get('skill_3').touched || submitted)">
                      This field is required.
                    </div>
                    <div class="error-message"
                      *ngIf="expUpdForm.get('skill_3').hasError('maxlength') && (expUpdForm.get('skill_3').dirty || expUpdForm.get('skill_3').touched)">
                      Max 30 characters allowed.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label>Main Role</label>
              </div>
              <div class="col-md-12">
                <textarea class="form-control" formControlName="main_role"> </textarea>
                <div class="error-message"
                  *ngIf="expUpdForm.get('main_role').hasError('required') && (expUpdForm.get('main_role').dirty || expUpdForm.get('main_role').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="expUpdForm.get('main_role').hasError('maxlength') && (expUpdForm.get('main_role').dirty || expUpdForm.get('main_role').touched)">
                  Max 1125 characters allowed.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label>Summary of Experience</label>
              </div>
              <div class="col-md-12">
                <textarea class="form-control" formControlName="personal_details"> </textarea>
                <div class="error-message"
                  *ngIf="expUpdForm.get('personal_details').hasError('required') && (expUpdForm.get('personal_details').dirty || expUpdForm.get('personal_details').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="expUpdForm.get('personal_details').hasError('maxlength') && (expUpdForm.get('personal_details').dirty || expUpdForm.get('personal_details').touched)">
                  Max 2250 characters allowed.
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-footer cancel-btn" (click)="delExperience()">
      Delete
    </button>
    <button class="btn btn-footer" (click)="updateExperience(expUpdForm.valid)">
      Update
    </button>
  </div>
</ng-template>
<ng-template #currentTemp>
  <span>Present</span>
</ng-template>
<ng-template #volunteerListModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Volunteer Experience</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
      <div class="single-image-wrap">
        <div class="media border-row cursor-pointer" *ngFor="let experience of usrWorkExperience"
          (click)="updateExpModal(editExpTemplate, experience)">
          <div class="exp-verified">
            <div class="exp-verified-Inner">
              <img alt="image" (click)="redirectPage(experience);modalRef.hide();$event.stopPropagation()"
                src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                alt="Education Cover" class="thumb-img">
            </div>
          </div>
          <div class="media-body ml-3">
            <h3 class="top-name">{{experience.position}}</h3>
            <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
            <h4 *ngIf="!experience.organization_title">Self-employed</h4>
            <h5 class="start-end" *ngIf="experience.start_date != experience.end_date"><strong>Start</strong>
              <span class="ml-1 mr-3">{{experience.start_date | date: 'MMM y'}}</span> <strong class="mr-1">End</strong>
              <ng-template
                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                [ngIfElse]="currentTemp">
                <span>{{experience.end_date | date: 'MMM y'}}</span>
              </ng-template>
            </h5>
            <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
              <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
            </h5>

            <p class="m-0">
              <strong>
                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
              </strong>
            </p>
            <p class="m-0 text-ellipse">{{experience.main_role}}</p>
            <div class="addRefRow">
              <div class="addRefRowInner">
                  <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                      <p class="my-0 people-number" (click)="$event.stopPropagation();teamMemberView(experience)">
                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        <span>{{ experience.related_data.exp_count }}</span>
                      </p>
                    </div>
                    <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                        <img alt="image" src="assets/images/verifyProfile.svg">
                      </p>
                <a href="javascript:void(0);" *ngIf="experience.page"
                  (click)="$event.stopPropagation(); addTeamMember(experience);">
                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                  <span>Add team members</span>
                </a>
                <a href="javascript:void(0);"
                  *ngIf="!experience.verified_by && experience.verification_status == 1 && user.age_group > 1 && (experience.page || experience.school)"
                  (click)="addReferenceListing(addReferenceModal, experience); $event.stopPropagation()">
                  <img src="assets/images/verifyProfile.svg" alt="icon">
                  <span>Add reference</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #expTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Volunteer Experience</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body work-body">
    <div class="modal-box">
      <form *ngIf="showTag" [formGroup]="pageTagForm" novalidate>
          <app-tag-search (setPage)="getSelectedPage($event)" [notifier]="childNotifier" [expType]="1"></app-tag-search>
        <div class="tag-steps pt-4 border-line">
          <div class="row">
            <div class="col-md-12">
              <h2 class="tag-name mb-3">Page ID</h2>
            </div>
            <div class="col-md-6">
              <div class="form-group search_key-relative page-id">
                  <div class="form-group mb-0 mr-3">
                      <input type="text" formControlName="page_id" class="form-control" placeholder="Paste here" (keydown)="reTagPage()" />
                      <div class="error-message"
                        *ngIf="(pageTagForm.errors?.invalidPageId || pageTagForm.get('page_id').hasError('whitespace')) && (pageTagForm.get('page_id').dirty || pageTagForm.get('page_id').touched)">
                        Please enter valid data.
                      </div>
                      <div class="error-message" *ngIf="pageTagForm.get('page_id').hasError('aftersubmit')">
                        {{pageTagForm.controls.page_id.errors.aftersubmit}}
                      </div>
                    </div>
                    <button class="btn btn-colored mb-3" (click)="tagPage(pageTagForm.valid)">Tag</button>
              </div>
              <div class="tag-details mb-3 trtrt" *ngIf="(selectedOrg | json) !== '{}' && !tagSearch">
                <div class="media align-items-center">
                  <img alt="tag Icon"
                    src="{{selectedOrg.display_pic? selectedOrg.display_pic : 'assets/images/default.svg'}}"
                    class="thumb-img img-60">
                  <div class="media-body ml-3">
                    <h4 class="m-0">{{selectedOrg.page_title}}</h4>
                    <p class="m-0 font-14">{{selectedOrg.page_city}}, {{selectedOrg.page_state}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </form>
      <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
        <div class="form-group row">
          <div class="col-md-12">
            <label>Position</label>
          </div>
          <div class="col-md-12">
            <input type="text" class="form-control" placeholder="Type Here" formControlName="position">
            <div class="error-message"
              *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
              This field is required.
            </div>
            <div class="error-message"
              *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
              Max 50 characters allowed.
            </div>
            <div class="error-message"
              *ngIf="searchForm.get('position').hasError('whitespace') && (!searchForm.get('position').hasError('required')) && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
              Please enter valid data.
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label>One-Time Event</label>
          </div>
          <div class="col-md-4">
            <!-- <div class="custom-control custom-radio">
              <input type="radio" value="1" id="duration" class="custom-control-input" (change)="oneDayEvnt($event)"
                [checked]="isOneDayEvnt" name="checkDuration">
              <label for="duration" class="custom-control-label custom-label mb-0">Yes</label>
            </div> -->
            <div class="teal-radio-box">
                <input type="radio" value="1" id="duration" class="custom-control-input" (change)="oneDayEvnt($event)"
                [checked]="isOneDayEvnt" name="checkDuration" />
                <label for="duration">Yes</label>
        </div>
          </div>
          <div class="col-md-4">
            <!-- <div class="custom-control custom-radio">
              <input type="radio" value="0" id="duration1" class="custom-control-input" (change)="oneDayEvnt($event)"
                [checked]="!isOneDayEvnt" name="checkDuration">
              <label for="duration1" class="custom-control-label custom-label mb-0">No</label>
            </div> -->
            <div class="teal-radio-box">
                <input type="radio" value="0" id="duration1" class="custom-control-input" (change)="oneDayEvnt($event)"
                [checked]="!isOneDayEvnt" name="checkDuration" />
                <label for="duration1">No</label>
        </div>
          </div>
        </div>

        <div class="form-group row customRow ">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <label for="" class="custom-label">
                  <span *ngIf="!isOneDayEvnt" class="d-block text">Start
                    Date</span>
                  <span *ngIf="isOneDayEvnt" class="d-block text">Date</span>
                  <div class="relative first">
                      <input type="text" class="form-control modal-control modal-field mt-2"
                      placeholder="{{evntPlaceholder}}" bsDatepicker [maxDate]="today"
                      [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true }" formControlName="start_date"
                      style="background-color: white;" readonly>
                    <a href="javascript:void(0);" (click)="resetStartDate()"><img src="assets/images/plusrotate.svg"
                        class="img-cross"></a>
                  </div>
                  <div class="error-message"
                    *ngIf="searchForm.get('start_date').hasError('required') && (searchForm.get('start_date').dirty || searchForm.get('start_date').touched || partialSubmitted)">
                    This field is required.
                  </div>
                </label>
              </div>
              <div class="col-md-4" *ngIf="!isOneDayEvnt">
                <div class="d-flex justify-content-between flex-column custom-second">
                  <!-- <div class="custom-control custom-radio">
                    <input type="radio" value="1" id="end" class="custom-control-input" name="dateset"
                      (change)="endDate($event)" [checked]="isEndDate">
                    <label for="end" class="custom-control-label custom-label mb-0">End Date</label>
                  </div> -->
                  <div class="teal-radio-box">
                      <input type="radio" value="1" id="end" class="custom-control-input" name="dateset"
                      (change)="endDate($event)" [checked]="isEndDate" />
                      <label for="end">End Date</label>
              </div>
                </div>
                <label for=""  *ngIf="isEndDate">
                  <div class="relative">
                      <input type="text" class="form-control modal-control modal-field" placeholder="End Date" bsDatepicker
                      [maxDate]="today" [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true }"
                      formControlName="end_date" style="background-color: white;" readonly>
                    <a href="javascript:void(0);" (click)="resetEndDate()"><img src="assets/images/plusrotate.svg"
                        class="img-cross"></a>
                  </div>
                  <div class="error-message"
                    *ngIf="isEndDate && searchForm.get('end_date').hasError('required') && (searchForm.get('end_date').dirty || searchForm.get('end_date').touched || partialSubmitted)">
                    This field is required.
                  </div>
                  <div class="error-message"
                    *ngIf="searchForm.errors?.invalidEndDate && (searchForm.touched || searchForm.dirty)">
                    Start date should not be greater than end date
                  </div>
                </label>
              </div>
              <div class="col-md-4">
                  <!-- <div class="custom-control custom-radio ml-3">
                      <input type="radio" value="2" id="current" class="custom-control-input" name="dateset"
                        (change)="endDate($event)" [checked]="!isEndDate">
                      <label for="current" class="custom-control-label custom-label mb-0">Present
                        Date</label>
                    </div> -->
                    <div class="teal-radio-box">
                        <input type="radio" value="2" id="current" class="custom-control-input" name="dateset"
                        (change)="endDate($event)" [checked]="!isEndDate" />
                        <label for="current">Present Date</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-6">
            <label>Total Hours</label>
            <input type="number" class="form-control" placeholder="Type Here" formControlName="total_hours">
            <div class="error-message"
              *ngIf="searchForm.get('total_hours').hasError('required') && (searchForm.get('total_hours').dirty || searchForm.get('total_hours').touched || partialSubmitted)">
              This field is required.
            </div>
            <div class="error-message"
              *ngIf="searchForm.get('total_hours').hasError('min') && (searchForm.get('total_hours').dirty || searchForm.get('total_hours').touched)">
              Please enter valid value for number of hours.
            </div>
          </div>
        </div>
        <div><br></div>
      </form>
      <form *ngIf="pageformShow && !orgId && canCreatePage" [formGroup]="pageForm" novalidate>
        <h3>
          Create an unclaimed page for the Organization
        </h3>
        <div class="row form-group">
          <div class="col-md-6">
            <label>
              Type of Organization
            </label>
            <select class="form-control custom-select" placeholder="Organization Type"
              formControlName="organization_type" (change)="changeOrgType($event)">
              <option selected value=2>Organization</option>
              <option value=3>School</option>
              <option value=5>Self-employed</option>
            </select>
            <div class="error-message"
              *ngIf="pageForm.get('organization_type').hasError('required') && (pageForm.get('organization_type').dirty || pageForm.get('organization_type').touched || partialSubmitted)">
              This field is required.
            </div>
          </div>
          <div class="row m-0" *ngIf="!isSelfEmployed">
            <div class="col-md-12">
              <label>Title of Organization</label>
              <input type="text" placeholder="Title of Organization" class="form-control"
                formControlName="organization_title">
              <div class="error-message"
                *ngIf="pageForm.get('organization_title').hasError('required') && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched || partialSubmitted)">
                This field is required.
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('organization_title').hasError('whitespace') && (!pageForm.get('organization_title').hasError('required')) && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched || partialSubmitted)">
                Please enter valid data
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('organization_title').hasError('maxlength') && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched)">
                Max 125 characters allowed.
              </div>
            </div>
            <div class="col-md-6">
              <label>Country</label>
              <select class="form-control custom-select" placeholder="Country" formControlName="country"
                (change)="changeCountry($event)">
                <option selected>Canada</option>
                <option>USA</option>
              </select>
              <div class="error-message"
                *ngIf="pageForm.get('country').hasError('required') && (pageForm.get('country').dirty || pageForm.get('country').touched || partialSubmitted)">
                This field is required.
              </div>
            </div>
            <div class="col-md-12" (focusout)="addressFocusOut()">
              <label>Address</label>
              <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet" (change)="changeAddress($event)"
                (setAddress)="getAddress($event)" (setHideResult)="setResultHide($event)"></app-azure-maps>
              <div *ngIf="!hidePlaceResults" class="col-md-12">
                <div class="option-div">
                  <div class="listing scrollbar" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                    <svg _ngcontent-dsr-c176="" width="130px" height="130px" viewBox="0 0 130 130" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title _ngcontent-dsr-c176="">Artboard</title>
                      <desc _ngcontent-dsr-c176="">Created with Sketch.</desc>
                      <g _ngcontent-dsr-c176="" id="Artboard" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <rect _ngcontent-dsr-c176="" id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130"
                          rx="12"></rect>
                        <g _ngcontent-dsr-c176="" id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF"
                          fill-rule="nonzero">
                          <path _ngcontent-dsr-c176=""
                            d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                            id="Shape"></path>
                        </g>
                      </g>
                    </svg>
                    <div class="detail">
                      <h6>{{place.address.freeformAddress}}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" [hidden]="true" placeholder="Full Address" class="form-control"
                formControlName="address">
              <div class="error-message"
                *ngIf="pageForm.get('address').hasError('required') && (showAddressError || partialSubmitted)">
                This field is required.
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('address').hasError('whitespace') && (!pageForm.get('address').hasError('required')) && (pageForm.get('address').dirty || pageForm.get('address').touched || partialSubmitted)">
                Please enter valid data
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('address').hasError('maxlength') && (pageForm.get('address').dirty || pageForm.get('address').touched)">
                Max 200 characters allowed.
              </div>
              <div class="error-message" *ngIf="pageForm.get('address').hasError('aftersubmit')">
                Uh Oh! This address is already in use. Please try entering a new address, or check if the page you are
                looking for already exists. Thank you!
              </div>
            </div>
            <div class="col-md-6" (focusout)="cityFocusOut()">
              <label>City</label>
              <app-azure-maps *ngIf="!updateAddress" (change)="changeCity($event)" [updateAddress]="updateCity"
                (setAddress)="getAddress($event)" (setHideResult)="setCityResultHide($event)"
                [placeHolder]="cityPlaceHolder">
              </app-azure-maps>
              <div *ngIf="!hidePlaceResultsCity" class="col-md-12">
                <div class="option-div">
                  <div class="listing scrollbar" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                    <svg _ngcontent-dsr-c176="" width="130px" height="130px" viewBox="0 0 130 130" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title _ngcontent-dsr-c176="">Artboard</title>
                      <desc _ngcontent-dsr-c176="">Created with Sketch.</desc>
                      <g _ngcontent-dsr-c176="" id="Artboard" stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <rect _ngcontent-dsr-c176="" id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130"
                          rx="12"></rect>
                        <g _ngcontent-dsr-c176="" id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF"
                          fill-rule="nonzero">
                          <path _ngcontent-dsr-c176=""
                            d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                            id="Shape"></path>
                        </g>
                      </g>
                    </svg>
                    <div class="detail">
                      <h6>{{place.address.freeformAddress}}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" [hidden]="!updateAddress" placeholder="City" class="form-control"
                formControlName="city">
              <div class="error-message"
                *ngIf="pageForm.get('city').hasError('required') && (showAddressError || partialSubmitted)">
                This field is required.
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('city').hasError('whitespace') && (!pageForm.get('city').hasError('required')) && (pageForm.get('city').dirty || pageForm.get('city').touched || partialSubmitted)">
                Please enter valid data
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('city').hasError('maxlength') && (pageForm.get('city').dirty || pageForm.get('city').touched)">
                Max 20 characters allowed.
              </div>
            </div>
            <div class="col-md-6">
              <label>{{plchldrState}}</label>
              <input type="text" placeholder="{{plchldrState}}" class="form-control" formControlName="state">
              <div class="error-message"
                *ngIf="pageForm.get('state').hasError('required') && (pageForm.get('state').dirty || pageForm.get('state').touched || partialSubmitted)">
                This field is required.
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('state').hasError('whitespace') && (!pageForm.get('state').hasError('required')) && (pageForm.get('state').dirty || pageForm.get('state').touched || partialSubmitted)">
                Please enter valid data
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('state').hasError('maxlength') && (pageForm.get('state').dirty || pageForm.get('state').touched)">
                Max 50 characters allowed.
              </div>
            </div>
            <div class="col-md-6">
              <label>{{plchldrPostal}}</label>
              <input type="text" placeholder="{{plchldrPostal}}" class="form-control" formControlName="zip_code">
              <div class="error-message"
                *ngIf="pageForm.get('zip_code').hasError('required') && (pageForm.get('zip_code').dirty || pageForm.get('zip_code').touched || partialSubmitted)">
                This field is required.
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('zip_code').hasError('whitespace') && (!pageForm.get('zip_code').hasError('required')) && (pageForm.get('zip_code').dirty || pageForm.get('zip_code').touched || partialSubmitted)">
                Please enter valid data
              </div>
              <div class="error-message"
                *ngIf="pageForm.get('zip_code').hasError('maxlength') || pageForm.get('zip_code').hasError('minlength')">
                <span *ngIf="plchldrPostal==='Postal'">Please enter your postal code in a valid format (Ex.
                  A1A1A1).</span>
                <span *ngIf="plchldrPostal==='ZIP Code'">Please enter your zip code in a valid format (Ex.
                  A1AA1).</span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form *ngIf="verifierFormShow" novalidate>
        <h3 class="mb-0">Review Details</h3>
        <div class="innerBox mb-4">
          <div class="row">
            <div class="col-6">
              <h3>Position</h3>
            </div>
            <div class="col-6">
              <p>
                {{this.searchForm.get('position').value}}
              </p>
            </div>
            <div class="col-6">
              <h3>Organization</h3>
            </div>
            <div class="col-6">
              <p *ngIf="!isSelfEmployed">{{this.searchForm.get('title').value}}</p>
              <p *ngIf="isSelfEmployed">Self-employed</p>
            </div>
            <div class="col-6">
              <h3 *ngIf="!isOneDayEvnt">Start Date</h3>
              <h3 *ngIf="isOneDayEvnt">Date</h3>
            </div>
            <div class="col-6">
              <p>
                {{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}
              </p>
            </div>
            <div class="col-6" *ngIf="!isOneDayEvnt">
              <h3>End Date</h3>
            </div>
            <div class="col-6" *ngIf="!isOneDayEvnt">
              <p *ngIf="!this.searchForm.get('end_date').value">Present</p>
              <p>{{this.searchForm.get('end_date').value | date: 'yyyy-MM-dd'}}</p>
            </div>

            <div class="col-6">
              <h3>Total Hours</h3>
            </div>
            <div class="col-6">
              <p>{{this.searchForm.get('total_hours').value }}</p>
            </div>

          </div>
        </div>
      </form>
      <div *ngIf="previewShow" class="content-section p-2">
        <div class="profile-div">
          <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
            <div class="box-div inner-exp border-0 m-0 border-radius-0">
              <div class="experience-div media p-0 bg-transparent border-radius-0">
                <p class="contentText forExpStep4">Your experience has been added successfully!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-box" *ngIf="teamMember.length > 0">
          <div class="othersExpOuterPopup px-md-5">
            <p class="othersExp">Now add others that you have shared this experience with to your <strong>Team</strong>
              <br />Pssst... <span>the more team members you add, the more <strong>verified</strong> your experience
                will be!</span></p>
            <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try
              again later.</div>
            <div class="scroll-activity scrollbar pr-2 overflowXHide">
              <ul class="teamListWrap no-list">
                <li *ngFor="let member of teamMemberSliced">
                  <div class="teamMemberEach">
                    <div class="topMember">
                      <img alt="icon" class="bannerImg"
                        src="{{member.student_info.header_image ? member.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                    </div>
                    <div class="profileMember">
                      <img alt="icon" class="profileImg"
                        src="{{member.student_info.display_pic ? member.student_info.display_pic : 'assets/images/p-img.png'}}" />
                    </div>
                    <div class="btmMember">
                      <h1 class="name">{{ member.student_info.student_name }}</h1>
                      <h5 class="subName">{{member.position}}</h5>
                      <p class="yearHistory">
                        {{ member.start_date }}<span *ngIf="member.end_date"> - {{member.end_date}}</span>
                        <span *ngIf="!member.end_date"> - Present</span>
                      </p>
                      <div class="memberActions">
                        <button class="btn common-btn btnMessage btnProfile" *ngIf="!member.team_id"
                          (click)="addToTeam(member)">Add to team</button>
                        <button class="btn common-btn btnMessage btnProfile" *ngIf="member.team_id"
                          (click)="cancelTeam(member)">Cancel</button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="teamMember.length > page_size && teamMember.length > teamMemberSliced.length"
              class="text-center pt-4 timeLineLoad">
              <a href="javascript:void(0);" (click)="teamLoadMore()">Load More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="showTag || !formShow" class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
      Cancel
    </button>
    <button class="btn btn-footer" *ngIf="showTag" (click)="aftrTag(pageTagForm.valid)">
      Next
    </button>
    <button *ngIf="!formShow" class="btn btn-footer" (click)="showForm(searchForm.valid)">
      Next
    </button>
    <button class="btn btn-footer cancel-btn" *ngIf="pageformShow && !orgId && canCreatePage" (click)="showForm(searchForm.valid)">
      Return
    </button>
    <button *ngIf="pageformShow && !orgId && canCreatePage" class="btn btn-footer"
      (click)="showPageForm(pageForm.valid)">
      Next
    </button>
    <button class="btn btn-footer" *ngIf="verifierFormShow && (orgId || !canCreatePage)"
      (click)="showForm(searchForm.valid)">
      Return
    </button>
    <button class="btn btn-footer" *ngIf="verifierFormShow && !orgId && canCreatePage"
      (click)="showPageForm(pageForm.valid,true)">
      Return
    </button>
    <button class="btn btn-footer" *ngIf="verifierFormShow" (click)="addExperience()">
      Save
    </button>
    <button class="btn btn-footer" *ngIf="previewShow && teamMember.length > 0" (click)="modalRef.hide()">
      Skip
    </button>
    <button class="btn btn-footer" *ngIf="previewShow" (click)="modalRef.hide()">
      Done
    </button>
  </div>
</ng-template>
<ng-template #addReferenceModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Reference</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-box">
      <form [formGroup]="verifierForm" novalidate *ngIf="!previewShow">
        <p><strong>Note:</strong> You must add your reference if they are not on the dropdown list</p>
        <div class="form-group row">
          <div class="col-md-6">
            <label>Professional Reference First Name</label>
            <input type="text" class="form-control text-capitalize" formControlName="first_name"
              (keydown)="firstnameSearch()" (focusout)="focusOutFunction()">
            <div class="error-message"
              *ngIf="verifierForm.get('first_name').hasError('required') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched || submitted)">
              This field is required.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('first_name').hasError('maxlength') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched)">
              Max 50 characters allowed.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('first_name').hasError('whitespace') && (!verifierForm.get('first_name').hasError('required')) && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched || submitted)">
              Please enter valid data.
            </div>
          </div>
          <div class="col-md-6">
            <label>Professional Reference Last Name</label>
            <input type="text" class="form-control text-capitalize" formControlName="last_name"
              (keydown)="lastnameSearch()" (focusout)="focusOutFunction()">
            <div class="error-message"
              *ngIf="verifierForm.get('last_name').hasError('required') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched || submitted)">
              This field is required.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('last_name').hasError('maxlength') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched)">
              Max 50 characters allowed.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('last_name').hasError('whitespace') && (!verifierForm.get('last_name').hasError('required')) && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched || submitted)">
              Please enter valid data.
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <div class="listingBox mt-0"
              *ngIf="verifiers.length > 0 && this.verifierForm.get('first_name').value && canSrchFirstname">
              <ul class="pl-0 mb-0" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                <li *ngFor="let verifier of verifiers | firstNameSearch: this.verifierForm.get('first_name').value"
                  (click)="selectVerifier(verifier)">
                  <p>{{verifier.first_name}} {{verifier.last_name}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="listingBox mt-0"
              *ngIf="verifiers.length > 0 && this.verifierForm.get('last_name').value && canSrchLastname">
              <ul class="pl-0 mb-0" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                <li *ngFor="let verifier of verifiers | lastNameSearch: this.verifierForm.get('last_name').value"
                  (click)="selectVerifier(verifier)">
                  <p>{{verifier.first_name}} {{verifier.last_name}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-6">
            <label>Professional Reference Email</label>
            <input [readonly]="verifierId" type="text" class="form-control" formControlName="email"
              (focusout)="focusOutEmail()">
            <div class="error-message"
              *ngIf="verifierForm.get('email').hasError('required') && (verifierForm.get('email').dirty || verifierForm.get('email').touched || submitted)">
              This field is required.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('email').hasError('maxlength') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
              Max 100 characters allowed.
            </div>
            <div class="error-message"
              *ngIf="verifierForm.get('email').hasError('pattern') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
              Please enter your email in a valid format.
            </div>
            <div class="error-message" *ngIf="verifierForm.get('email').hasError('aftersubmit')">
              {{verifierForm.controls.email.errors.aftersubmit}}
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="previewShow" class="content-section p-2">
        <div class="profile-div">
          <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
            <div class="box-div inner-exp border-0 m-0 border-radius-0">
              <div class="experience-div media p-0 bg-transparent border-radius-0">
                <p class="contentText">Your experience has been sent successfully!
                  We will inform you once your reference has reviewed it.
                  The last step is to add some details to your experience via your resume!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-footer cancel-btn" (click)="updateModalRef.hide()">
      <span *ngIf="!previewShow">Cancel</span><span *ngIf="previewShow">Done</span>
    </button>
    <button class="btn btn-footer" *ngIf="!previewShow" (click)="addReference(verifierForm.valid)">
      Send
    </button>
  </div>

</ng-template>
