<section class="profile-head resourceDiv">
    <div class="container-fuild">
        <div class="row">
            <div class="col-xl-3 col-md-4 d-md-block d-none sidebarContent fullPage">
                <div class="footer-nav">
                    <div class="footerbar">
                        <h4>About</h4>
                        <ul class="pl-0">
                            <li class=""><a [routerLink]="['/about-us']">About
                                    Us</a></li>
                            <li><a [routerLink]="['/guidelines']">Community
                                    Guidelines</a></li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Policies</h4>
                        <ul class="pl-0">
                            <li class=""><a [routerLink]="['/terms-of-use']">Terms of
                                    Use</a></li>
                            <li><a [routerLink]="['/privacy-policy']">Privacy
                                    Policy</a></li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Resources</h4>
                        <ul class="pl-0">
                            <li class="active"><a routerlinkactive="active" [routerLink]="['/faq']">FAQ</a></li>
                            <li class=""><a [routerLink]="['/mental-health']">Mental Health</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Why Sign Up?</h4>
                        <ul class="pl-0">
                            <li class=""><a [routerLink]="['/why-signup-students']">Students</a></li>
                            <li class=""><a [routerLink]="['/why-signup-others']">Others</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xl-9 p-0">
                <div class="top-sticky">
                    <div class="profile-sec media">
                        <div class="media-body ml-md-3">
                            <h1>FAQs</h1>
                        </div>
                    </div> 
                </div>
                <div class="content-section fullPage pt-4">
                    <div class="profile-div"> 
                        <div class="profile-inner full-inner">
                            <h3>
                                FAQ
                            </h3>
                            <div class="box-div faqCol" *ngIf="faqList?.length > 0">
                                <div class="inner-div faq-div" *ngFor="let faq of faqList">
                                    <h4>Q. {{faq.question}}</h4>
                                    <p>{{faq.answer}}</p>
                                </div>
                            </div>
                            <div class="box-div">
                                <div class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                    <p>Please enter FAQ </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>