<div class="customSearchWrap">
    <div class="searchFieldWrap">
        <div class="container">
            <form [formGroup]="searchForm" novalidate>
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                            <input type="text" placeholder="Search" class="form-control searchField" formControlName="search_key">
                            <div class="error-message"
                                *ngIf="searchForm.get('search_key').hasError('maxlength') && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                Max 50 characters allowed.
                            </div>
                            <div class="error-message" *ngIf="keywordsErr">
                                Max 3 search keywords allowed.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="form-group pl-0 pl-lg-4">
                            <div class="d-flex align-items-center " (focusout)="focusOutFunction()">
                                <app-azure-maps (change)="changeAddress($event)" [updateAddress]="updateAddress"
                                    (setAddress)="getAddress($event)" [placeHolder]="placeHolder"
                                    (setHideResult)="setResultHide($event)">
                                </app-azure-maps>
                            </div>
                            <div class="error-message" *ngIf="!showLocation && !updateAddress && submitted">
                                Please select location or enable location from your privacy settings
                            </div>
                            <div *ngIf="!hidePlaceResults && searchPlaces.length > 0" class="col-md-12">
                                <div class="option-div">
                                    <div class="listing" *ngFor="let place of searchPlaces"
                                        (click)="selectPlace(place)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                            viewBox="0 0 15 20">
                                            <path fill="none" stroke="#505759" stroke-width="1.4"
                                                d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                        </svg>
                                        <div class="detail">
                                            <h6>{{place.address.freeformAddress}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="form-group pl-0 pl-lg-4">
                            <button type="submit" class="common-btn" (click)="search(searchForm.valid)">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="searchKeys.length > 0" class="d-flex align-items-center flex-wrap">
                <ul class="d-flex no-list chipsListing">
                    <li *ngFor="let searchKey of searchKeys">
                        <a href="javascript:void();">{{searchKey}} </a>
                        <span class="float-right cursor-pointer" (click)="delKey(searchKey)">x</span>
                    </li>
                </ul>
                <button class="common-btn ghost mt-1" (click)="delKey('')">Clear Filter</button>
            </div>
        </div>
    </div>

    <div class="searchTabsWrap">
        <div class="container">
            <ul class="borderTabs no-list">
                <li class="cursor-pointer" [ngClass]="{'selected-tab': tabActive == 3}" (click)="onTabChange('school')">
                    <span>schools</span>
                </li>
                <li class="cursor-pointer" [ngClass]="{'selected-tab': tabActive == 1}" (click)="onTabChange('business')">
                    <span>pages</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="searchResultsWrap">
        <div *ngIf="tabActive == 1">
            <div class="container">
                <div class="d-md-flex searchScroll w-100">
                    <div *ngIf="businessProfiles.length > 0" class="searchResultOuter w-100">
                        
                        <div class="eachResult cursor-pointer" *ngFor="let businessProfile of businessProfiles" 
                            (click)="redirectBusiness(businessProfile)">
                            <div class="topImages">
                                <img alt="banner-image" class="bannerImage" src="{{businessProfile.header_image ? businessProfile.header_image : './assets/images/new-header-img.png'}}" />
                                <img alt="profile-image" class="profileImage" src="{{businessProfile.display_pic? businessProfile.display_pic : 'assets/images/default.svg'}}" />
                            </div>
    
                            <div class="btmDetails">
                                <h1 class="searchName">{{businessProfile.business_info.title}}</h1>
                                <div class="searchTags" *ngFor="let keyword of businessProfile.keywords | slice:0:1">
                                    <span class="btn btn-dark-badge" *ngIf="keyword.profile_keyword_1">{{ keyword.profile_keyword_1 }}</span>
                                    <span class="btn btn-dark-badge" *ngIf="keyword.profile_keyword_2">{{ keyword.profile_keyword_2 }}</span>
                                    <span class="btn btn-dark-badge" *ngIf="keyword.profile_keyword_3">{{ keyword.profile_keyword_3 }}</span>
                                </div>
                                <div class="searchLocation">
                                    <p class="m-0">
                                        <img alt="icon" class="locationIcon" src="/assets/images/location-icon.png" />
                                        <span>
                                            <span *ngIf="businessProfile.business_info.address">{{businessProfile.business_info.address}}, </span>{{businessProfile.business_info.city}}<span *ngIf="businessProfile.business_info.zip_code">, {{businessProfile.business_info.zip_code}}</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="businessProfiles.length == 0" class="noResultFound">
                        <h5 class="m-0">No Result(s) Found</h5>
                    </div>
                </div>
                <div *ngIf="totalbusinessProfiles > 21 && totalbusinessProfiles > businessProfiles.length"
                    class="next-listing text-center mt-4 pb-4 timeLineLoad">
                    <a href="javascript:void(0);" (click)="onBusinessScrollDown()">Load More</a>
                </div>
            </div>
        </div>

        <div *ngIf="tabActive == 3">
            <div class="container">
                <div class="d-md-flex searchScroll w-100">
                    <div *ngIf="schoolProfiles.length > 0" class="searchResultOuter w-100">
                        <div class="eachResult cursor-pointer" *ngFor="let schoolProfile of schoolProfiles"
                            [routerLink]="['/school/' + schoolProfile.id]">
                            <div class="topImages">
                                <img alt="banner-image" class="bannerImage" src="{{schoolProfile.header_image ? schoolProfile.header_image : './assets/images/new-header-img.png'}}" />
                                <img alt="profile-image" class="profileImage" src="{{schoolProfile.display_picture? schoolProfile.display_picture : 'assets/images/default.svg'}}" />
                            </div>
    
                            <div class="btmDetails">
                                <h1 class="searchName">{{schoolProfile.school_name}}</h1>
                                <div class="searchLocation">
                                    <p class="m-0">
                                        <img alt="icon" class="locationIcon" src="/assets/images/location-icon.png" />
                                        <span>
                                            <span *ngIf="schoolProfile.address">{{schoolProfile.address}}, </span>{{schoolProfile.details.city}}<span *ngIf="schoolProfile.details.zip_code">, {{schoolProfile.details.zip_code}}</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="schoolProfiles.length == 0" class="noResultFound">
                        <h5 class="m-0">No Result(s) Found</h5>
                    </div>
                </div>
                <div *ngIf="totalschoolProfiles > 21 && totalschoolProfiles > schoolProfiles.length"
                    class="next-listing text-center mt-4 pb-4 timeLineLoad">
                    <a href="javascript:void(0);" (click)="onSchoolScrollDown()">Load More</a>
                </div>
            </div>
        </div>

    </div>
</div>