<div class="profileWhiteWrap pb-3 pageSettingWhiteWrap">
<div class="content-section p-0">
    <div class="profile-div">
        <div class="profile-inner full-inner ">
            <div class="forBlueHdg">
                <h3>General</h3>
            </div>
            <div class="box-div customBox d-flex justify-content-between">
                <div class="inner-div customFlex">
                    <h2>Page details</h2>
                    <div class="inner-box mr-4">
                        <h4>{{pagesDetails?.page_info?.title}}</h4>
                        <p *ngIf="pagesDetails.business">
                            <span *ngIf="pagesDetails?.business?.address">{{pagesDetails?.business?.address}}, </span>{{pagesDetails?.business?.city}}, {{pagesDetails?.business?.state}}<span *ngIf="pagesDetails?.business?.zip_code">, {{pagesDetails?.business?.zip_code}}</span>
                        </p>
                        <p *ngIf="pagesDetails.club_team && pagesDetails.club_team.school">
                            {{pagesDetails?.club_team?.school.school_name}}</p>
                    </div>
                    <button class="common-button" *ngIf="pagesDetails.business"
                        (click)="emailModal(ChangeAddress)">Change
                        Address</button>
                </div>
                <div class="inner-div customFlex">
                    <h2>Page Owner details</h2>
                    <div class="inner-box">
                        <h4>{{pagesDetails?.page_info?.owner_name}}</h4>
                        <p>{{pagesDetails?.page_info?.owner_email}}</p>
                    </div> 
                </div>
                <div class="d-md-flex justify-content-start" *ngIf="pagesDetails.business">
                    <div class="inner-div mb-0 mr-md-3"
                        *ngIf="pagesDetails.business?.business_number && pagesDetails.business">
                        <h2 *ngIf="pagesDetails.business.organization_type ===2">business Number
                        </h2>
                        <h2 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number</h2>
                        <div class="inner-box">
                            <h4 *ngIf="pagesDetails.business.organization_type ===2">business
                                Number</h4>
                            <h4 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number
                            </h4>
                            <p>
                                ({{ pagesDetails.business.business_number | slice:0:3 }}){{ pagesDetails.business.business_number | slice:3:6 }}-{{ pagesDetails.business.business_number | slice:6 }}
                            </p>
                        </div>
                        <button class="common-button"
                            (click)="emailModal(BusinessNumber)">Change Number</button>
                    </div>
                    <div class="inner-div mb-0 mr-md-3">
                        <div class="inner-div"
                            *ngIf="!pagesDetails.business?.business_number && pagesDetails.business">
                            <h2 *ngIf="pagesDetails.business.organization_type ===2">business
                                Number</h2>
                            <h2 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number
                            </h2>
                            <button class="common-button"
                                (click)="emailModal(BusinessNumber)">Add Number</button>
                        </div>
                    </div>
                    <div class="inner-div position-relative"
                        *ngIf="pagesDetails?.professional_email && pagesDetails.business">
                        <h2>Contact Email
                            <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                                class="cursor-pointer ml-2"></h2> 
                            <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                <div class="info-div">
                                    <p>Please use a professional email for this section, as this email will be placed at the top of your Page for users to contact you</p>
                                </div>
                            </div>
                        <div class="inner-box">
                            <h4>Contact Email</h4>
                            <p>{{pagesDetails.professional_email}}</p>
                        </div>
                        <button class="common-button" (click)="emailModal(ContactEmail)">Change
                            Contact Email</button>
                    </div>
                    <div class="inner-div position-relative"
                        *ngIf="!pagesDetails?.professional_email && pagesDetails.business">
                        <h2>Contact Email<img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv1 = !infoDiv1"
                            class="cursor-pointer ml-2"></h2> 
                        <div class="info-overlay1" [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                            <div class="info-div">
                                <p>Please use a professional email for this section, as this email will be placed at the top of your Page for users to contact you</p>
                            </div>
                        </div>
                        <button class="common-button" (click)="emailModal(ContactEmail)">Add
                            Contact Email</button>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="inner-div mb-0"
                        *ngIf="pagesDetails?.professional_email && pagesDetails.club_team">
                        <h2>Professional Email</h2>
                        <div class="inner-box">
                            <h4>Professional Email</h4>
                            <p>{{pagesDetails?.professional_email}}</p>
                        </div>
                        <button class="common-button"
                            (click)="emailModal(ProfessionalEmail)">Change
                            Professional Email</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-inner full-inner" *ngIf="isOwner && businessVerified != '3' ">
            <div class="forBlueHdg">
                <h3>Administrator</h3>
            </div>
            <div class="box-div">
                <h4 class="infoText">You can add the email of anybody who works within your
                    organization as an Administrator. They will be able to edit the page and
                    verify all student’s experiences. If the user does not yet have an account,
                    they will be sent an Access Code. If they do, they will be able to join
                    through their current Account. You will be informed on the status of the
                    added Administrators through Notifications.”
                </h4>
                <div class="inner-div">
                    <button class="common-button" (click)="adminModal(addAdmin)">Add</button>
                </div>
                <div class="inner-div" *ngIf="adminInvites.length > 0">
                    <h2>Manage</h2>
                    <p *ngIf="showSubmitMsg" class="alert"
                        [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">
                        {{submitMsg}}</p>
                    <div class="d-flex flex-wrap">
                        <div class="ownerDiv" *ngFor="let invite of adminInvites">
                            <div class="inner-box">
                                <h4>{{invite.first_name}} {{invite.last_name}}</h4>
                                <p class="emailWrap">{{invite.email}}</p>
                                <p *ngIf="invite.status == 1" class="text-uppercase">Pending |
                                    <span *ngIf="invite.invite_type == 1">Owner
                                        Request</span><span
                                        *ngIf="invite.invite_type == 2">Admin Request</span></p>
                                <p *ngIf="invite.status == 2">Accepted</p>
                            </div>
                            <button class="common-button mr-3" *ngIf="invite.status == 1"
                                (click)="adminActionsModal(cnclRequest, invite)">Cancel <span
                                    *ngIf="invite.invite_type == 1">Transfer
                                    Ownership</span></button>
                            <button class="common-button mr-3" *ngIf="invite.status == 2"
                                (click)="adminActionsModal(delAdmin, invite)">Remove</button>
                            <button
                                *ngIf="invite.status == 2 && invite.page_info.user_age_group == 3"
                                class="common-button ghost"
                                (click)="adminActionsModal(transferModal, invite)">Transfer
                                Ownership</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile-inner full-inner">
            <div class="forBlueHdg">
                <h3>Verifiers</h3>
            </div>
            <div class="box-div">
                <div class="inner-div"> 
                    <button class="common-button" (click)="addVerifiers(addVerifier)">Add</button>
                </div>
                <div class="inner-div mb-2" *ngIf="verifiers.length > 0">
                    <h2>Manage</h2>
                    <p *ngIf="showVerifierMsg" class="alert"
                        [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">
                        {{verifierMsg}}</p>
                    <div class="d-flex flex-wrap">
                        <div class="ownerDiv" *ngFor="let verifier of verifiers">
                            <div class="inner-box mr-md-3">
                                <h4 class="nameFont">{{verifier.first_name}} {{verifier.last_name}}</h4>
                                <p>{{verifier.email}}</p>
                            </div>
                            <div class="d-flex align-items-center mb-md-0 mb-3">
                                <button class="common-button m-0" (click)="delVerifierModal(delVerifier, verifier)">Delete</button>
                                <button *ngIf="isOwner" class="common-button ghost ml-2 mb-0" (click)="makeAdmin(verifier)">Make Admin</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner-div">
                    <h4 class="infoText"><strong>Note:</strong> Verifiers will not have access to edit the page – they will only be able to verify student's experiences through their email inbox if a student sends it to them</h4>
                </div>
            </div>
        </div>
        <div class="profile-inner full-inner" *ngIf="isOwner && businessVerified != '3' ">
            <div class="forBlueHdg">
                <h3>Delete Page</h3>
            </div>
            <div class="box-div deleteCol">
                <h5>
                    You Understand that Deleting a Page while you are an Owner will Delete the
                    Page for all Administrators, and all of the Page Details will be Deleted on
                    the System. YOUR ACCOUNT WILL STILL REMAIN ACTIVE.
                </h5>
                <button class="common-button mt-3" (click)="deleteModal(deletePage)">
                    Delete Page Indefinitely
                </button>
            </div>
        </div>
        <div class="profile-inner full-inner" *ngIf="!isOwner">
            <div class="forBlueHdg">
                <h3>Leave Page</h3>
            </div>
            <div class="box-div deleteCol">
                <h5>
                    Click below to leave this page
                </h5>
                <button class="common-button mt-3" (click)="deleteModal(leavePage)">
                    Leave Page Indefinitely
                </button>
            </div>
        </div>
    </div>
</div>
</div>

<div class="profileWhiteWrap pb-3 pt-0 d-none">
    <div class="content-section pt-md-4 pt-3">
        <div class="profile-div">
            <div class="profile-inner full-inner ">
                <h3>General</h3>
                <div class="box-div customBox d-flex justify-content-between">
                    <div class="inner-div customFlex">
                        <h2>Page details</h2>
                        <div class="inner-box mr-4">
                            <h4>{{pagesDetails?.page_info?.title}}</h4>
                            <p *ngIf="pagesDetails.business">
                                {{pagesDetails?.business?.address}}, {{pagesDetails?.business?.city}}, {{pagesDetails?.business?.state}}, {{pagesDetails?.business?.zip_code}}
                            </p>
                            <p *ngIf="pagesDetails.club_team && pagesDetails.club_team.school">
                                {{pagesDetails?.club_team?.school.school_name}}</p>
                        </div>
                        <button class="common-button" *ngIf="pagesDetails.business"
                            (click)="emailModal(ChangeAddress)">Change
                            Address</button>
                    </div>
                    <div class="inner-div customFlex">
                        <h2>Page Owner details</h2>
                        <div class="inner-box">
                            <h4>{{pagesDetails?.page_info?.owner_name}}</h4>
                            <p>{{pagesDetails?.page_info?.owner_email}}</p>
                        </div> 
                    </div>
                    <div class="d-md-flex justify-content-start" *ngIf="pagesDetails.business">
                        <div class="inner-div mb-0 mr-md-3"
                            *ngIf="pagesDetails.business?.business_number && pagesDetails.business">
                            <h2 *ngIf="pagesDetails.business.organization_type ===2">business Number
                            </h2>
                            <h2 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number</h2>
                            <div class="inner-box">
                                <h4 *ngIf="pagesDetails.business.organization_type ===2">business
                                    Number</h4>
                                <h4 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number
                                </h4>
                                <p>
                                    ({{ pagesDetails.business.business_number | slice:0:3 }}){{ pagesDetails.business.business_number | slice:3:6 }}-{{ pagesDetails.business.business_number | slice:6 }}
                                </p>
                            </div>
                            <button class="common-button"
                                (click)="emailModal(BusinessNumber)">Change Number</button>
                        </div>
                        <div class="inner-div mb-0 mr-md-3">
                            <div class="inner-div"
                                *ngIf="!pagesDetails.business?.business_number && pagesDetails.business">
                                <h2 *ngIf="pagesDetails.business.organization_type ===2">business
                                    Number</h2>
                                <h2 *ngIf="pagesDetails.business.organization_type ===1">Non-Profit Number
                                </h2>
                                <button class="common-button"
                                    (click)="emailModal(BusinessNumber)">Add Number</button>
                            </div>
                        </div>
                        <div class="inner-div position-relative"
                            *ngIf="pagesDetails?.professional_email && pagesDetails.business">
                            <h2>Contact Email
                                <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                                    class="cursor-pointer ml-2"></h2> 
                                <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                    <div class="info-div">
                                        <p>Please use a professional email for this section, as this email will be placed at the top of your Page for users to contact you</p>
                                    </div>
                                </div>
                            <div class="inner-box">
                                <h4>Contact Email</h4>
                                <p>{{pagesDetails.professional_email}}</p>
                            </div>
                            <button class="common-button" (click)="emailModal(ContactEmail)">Change
                                Contact Email</button>
                        </div>
                        <div class="inner-div position-relative"
                            *ngIf="!pagesDetails?.professional_email && pagesDetails.business">
                            <h2>Contact Email<img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv1 = !infoDiv1"
                                class="cursor-pointer ml-2"></h2> 
                            <div class="info-overlay1" [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                                <div class="info-div">
                                    <p>Please use a professional email for this section, as this email will be placed at the top of your Page for users to contact you</p>
                                </div>
                            </div>
                            <button class="common-button" (click)="emailModal(ContactEmail)">Add
                                Contact Email</button>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="inner-div mb-0"
                            *ngIf="pagesDetails?.professional_email && pagesDetails.club_team">
                            <h2>Professional Email</h2>
                            <div class="inner-box">
                                <h4>Professional Email</h4>
                                <p>{{pagesDetails?.professional_email}}</p>
                            </div>
                            <button class="common-button"
                                (click)="emailModal(ProfessionalEmail)">Change
                                Professional Email</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner" *ngIf="isOwner && businessVerified != '3' ">
                <h3>Administrator</h3>
                <div class="box-div">
                    <h4 class="infoText">You can add the email of anybody who works within your
                        organization as an Administrator. They will be able to edit the page and
                        verify all student’s experiences. If the user does not yet have an account,
                        they will be sent an Access Code. If they do, they will be able to join
                        through their current Account. You will be informed on the status of the
                        added Administrators through Notifications.”
                    </h4>
                    <div class="inner-div">
                        <button class="common-button" (click)="adminModal(addAdmin)">Add</button>
                    </div>
                    <div class="inner-div" *ngIf="adminInvites.length > 0">
                        <h2>Manage</h2>
                        <p *ngIf="showSubmitMsg" class="alert"
                            [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">
                            {{submitMsg}}</p>
                        <div class="d-flex flex-wrap">
                            <div class="ownerDiv" *ngFor="let invite of adminInvites">
                                <div class="inner-box">
                                    <h4>{{invite.first_name}} {{invite.last_name}}</h4>
                                    <p>{{invite.email}}</p>
                                    <p *ngIf="invite.status == 1" class="text-uppercase">Pending |
                                        <span *ngIf="invite.invite_type == 1">Owner
                                            Request</span><span
                                            *ngIf="invite.invite_type == 2">Admin Request</span></p>
                                    <p *ngIf="invite.status == 2">Accepted</p>
                                </div>
                                <button class="common-button mr-3" *ngIf="invite.status == 1"
                                    (click)="adminActionsModal(cnclRequest, invite)">Cancel <span
                                        *ngIf="invite.invite_type == 1">Transfer
                                        Ownership</span></button>
                                <button class="common-button mr-3" *ngIf="invite.status == 2"
                                    (click)="adminActionsModal(delAdmin, invite)">Remove</button>
                                <button
                                    *ngIf="invite.status == 2 && invite.page_info.user_age_group == 3"
                                    class="common-button ghost"
                                    (click)="adminActionsModal(transferModal, invite)">Transfer
                                    Ownership</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner">
                <h3>Verifiers</h3>
                <div class="box-div">
                    <div class="inner-div"> 
                        <button class="common-button" (click)="addVerifiers(addVerifier)">Add</button>
                    </div>
                    <div class="inner-div mb-2" *ngIf="verifiers.length > 0">
                        <h2>Manage</h2>
                        <p *ngIf="showVerifierMsg" class="alert"
                            [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">
                            {{verifierMsg}}</p>
                        <div class="d-flex flex-wrap">
                            <div class="ownerDiv" *ngFor="let verifier of verifiers">
                                <div class="inner-box mr-md-3">
                                    <h4 class="nameFont">{{verifier.first_name}} {{verifier.last_name}}</h4>
                                    <p>{{verifier.email}}</p>
                                </div>
                                <div class="d-flex align-items-center mb-md-0 mb-3">
                                    <button class="common-button m-0" (click)="delVerifierModal(delVerifier, verifier)">Delete</button>
                                    <button *ngIf="isOwner" class="common-button ghost ml-2 mb-0" (click)="makeAdmin(verifier)">Make Admin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-div">
                        <h4 class="infoText"><strong>Note:</strong> Verifiers will not have access to edit the page – they will only be able to verify student's experiences through their email inbox if a student sends it to them</h4>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner" *ngIf="isOwner && businessVerified != '3' ">
                <h3>Delete Page</h3>
                <div class="box-div deleteCol">
                    <h5>
                        You Understand that Deleting a Page while you are an Owner will Delete the
                        Page for all Administrators, and all of the Page Details will be Deleted on
                        the System. YOUR ACCOUNT WILL STILL REMAIN ACTIVE.
                    </h5>
                    <button class="common-button mt-3" (click)="deleteModal(deletePage)">
                        Delete Page Indefinitely
                    </button>
                </div>
            </div>
            <div class="profile-inner full-inner" *ngIf="!isOwner">
                <h3>Leave Page</h3>
                <div class="box-div deleteCol">
                    <h5>
                        Click below to leave this page
                    </h5>
                    <button class="common-button mt-3" (click)="deleteModal(leavePage)">
                        Leave Page Indefinitely
                    </button>
                </div>
            </div>
        </div>
    </div>
    </div>

<ng-template #changeEmail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Change Email</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form>
                <div class="form-group">
                    <label>Enter New Email</label>
                    <input type="email" class="form-control" placeholder="New Email">
                </div>
                <div class="form-group">
                    <label>Repeat New Email</label>
                    <input type="email" class="form-control" placeholder="Repeat New Email">
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer">
            Done
        </button>
    </div>
</ng-template>

<ng-template #transferModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Transfer Ownership</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box" *ngIf="pendingOwnerReq == 0">
            <h4>You are about to Transfer Ownership to the Following Admin:</h4>
            <div class="inner-box">
                <h4>{{inviteObj.first_name}} {{inviteObj.last_name}}</h4>
                <p>{{inviteObj.email}}</p>
            </div>
            <p>Once you transfer Ownership, you will be considered an Administrator. You will not be able to manage
                Administrators or Delete the page indefinitely.</p>
        </div>
        <div class="modal-box" *ngIf="pendingOwnerReq > 0">
            <h4>To Transfer Ownership please cancel the previous request.</h4>
        </div>
    </div>
    <div class="modal-footer" *ngIf="pendingOwnerReq == 0">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="ownerInvite()">
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #deletePage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Deleting Page</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>You are about to delete the following Page: </h4>
            <div class="content-section pl-md-3 pr-md-3 pt-2 pb-2">
                <div class="profile-div">
                    <div class="profile-inner mb-3 mt-2 full-inner">
                        <div class="box-div inner-exp"
                            [ngClass]="{'business-border': pagesDetails.page_type == 1,'volunteer-border': pagesDetails.page_type == 2,'school-border': [3,4].indexOf(pagesDetails.page_type) > -1}">
                            <div class="experience-div media"
                                [ngClass]="{'business': pagesDetails.page_type == 1,'volunteer': pagesDetails.page_type == 2,'school': [3,4].indexOf(pagesDetails.page_type) > -1}">
                                <img *ngIf="pagesDetails.display_pic" src="{{pagesDetails.display_pic}}">
                                <img *ngIf="!pagesDetails.display_pic" src="assets/images/p-img.png">
                                <div class="media-body ml-2">
                                    <h4>{{pagesDetails?.page_info?.title}} <span>{{pagesDetails?.page_info?.state}},
                                            {{pagesDetails?.page_info?.country}}</span></h4>
                                    <button *ngIf="pagesDetails.business" class="common-btn ghost"
                                        (click)="OpenPage(page_id, 'business'); modalRef.hide()">Go to Page</button>
                                    <button *ngIf="!pagesDetails.business" class="common-btn ghost"
                                        (click)="OpenPage(page_id, 'club_team'); modalRef.hide()">Go to Page</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>This page will no longer be accessible by Admin or
                Yourself. Click the Button below to Delete this Page.</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="DeletePage(); modalRef.hide()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
    </div>
</ng-template>

<ng-template #leavePage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Leaving Page</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>You are about to leave the following Page: </h4>
            <div class="content-section pl-md-3 pr-md-3 pt-2 pb-2">
                <div class="profile-div">
                    <div class="profile-inner mb-3 mt-2 full-inner">
                        <div class="box-div inner-exp"
                            [ngClass]="{'business-border': pagesDetails.page_type == 1,'volunteer-border': pagesDetails.page_type == 2,'school-border': [3,4].indexOf(pagesDetails.page_type) > -1}">
                            <div class="experience-div media"
                                [ngClass]="{'business': pagesDetails.page_type == 1,'volunteer': pagesDetails.page_type == 2,'school': [3,4].indexOf(pagesDetails.page_type) > -1}">
                                <img *ngIf="pagesDetails.display_pic" src="{{pagesDetails.display_pic}}">
                                <img *ngIf="!pagesDetails.display_pic" src="assets/images/p-img.png">
                                <div class="media-body ml-2">
                                    <h4>{{pagesDetails?.page_info?.title}} <span>{{pagesDetails?.page_info?.state}},
                                            {{pagesDetails?.page_info?.country}}</span></h4>
                                    <button *ngIf="pagesDetails.business" class="common-btn ghost"
                                        (click)="OpenPage(page_id, 'business'); modalRef.hide()">Go to Page</button>
                                    <button *ngIf="!pagesDetails.business" class="common-btn ghost"
                                        (click)="OpenPage(page_id, 'club_team'); modalRef.hide()">Go to Page</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="LeavePage(); modalRef.hide()">
            Leave
        </button>
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
    </div>
</ng-template>

<ng-template #addAdmin>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Admin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="addAdminForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>First Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter First Name"
                            formControlName="first_name">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('first_name').hasError('required') && (addAdminForm.get('first_name').dirty || addAdminForm.get('first_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('first_name').hasError('maxlength') && (addAdminForm.get('first_name').dirty || addAdminForm.get('first_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('first_name').hasError('aftersubmit')">
                            {{addAdminForm.controls.first_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Last Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter Last Name"
                            formControlName="last_name">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('last_name').hasError('required') && (addAdminForm.get('last_name').dirty || addAdminForm.get('last_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('last_name').hasError('maxlength') && (addAdminForm.get('last_name').dirty || addAdminForm.get('last_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('last_name').hasError('aftersubmit')">
                            {{addAdminForm.controls.last_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('required') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('maxlength') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('pattern') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('email').hasError('aftersubmit')">
                            {{addAdminForm.controls.email.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="createAdmin(addAdminForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #BusinessNumber>
    <div class="modal-header">
        <h4 class="modal-title pull-left"><span *ngIf="pagesDetails.business.organization_type === 1">Non-Profit</span>
            <span *ngIf="pagesDetails.business.organization_type === 2">Business</span> Number</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="newBusineesNoForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Enter New <span style="color: #335175;" *ngIf="pagesDetails.business.organization_type === 1">Non-Profit</span>
                            <span style="color: #335175;" *ngIf="pagesDetails.business.organization_type === 2">Business</span> Number</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" formControlName="businessnumber"
                            placeholder="Number">
                        <div class="error-message"
                            *ngIf="newBusineesNoForm.get('businessnumber').hasError('required') && (newBusineesNoForm.get('businessnumber').dirty || newBusineesNoForm.get('businessnumber').touched )">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newBusineesNoForm.get('businessnumber').hasError('minlength') && (newBusineesNoForm.get('businessnumber').dirty || newBusineesNoForm.get('businessnumber').touched)">
                            <span *ngIf="pagesDetails.business.organization_type === 1">Non-Profit</span>
                            <span *ngIf="pagesDetails.business.organization_type === 2">Business</span>number needs to be at least 10 digits. 
                        </div>
                        <div class="error-message"
                            *ngIf="newBusineesNoForm.get('businessnumber').hasError('maxlength') && (newBusineesNoForm.get('businessnumber').dirty || newBusineesNoForm.get('businessnumber').touched)">
                            Max 15 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="newBusineesNoForm.get('businessnumber').hasError('aftersubmit')">
                            {{newBusineesNoForm.controls.businessnumber.errors.aftersubmit}}
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="addNewBusinessNumber(newBusineesNoForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #ContactEmail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contact Email</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="newContactEmailForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Enter New Contact Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" formControlName="contact_email"
                            placeholder="Contact Email">
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('required') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched )">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('maxlength') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('pattern') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('aftersubmit')">
                            {{newContactEmailForm.controls.contact_email.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="addNewContactEmail(newContactEmailForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #ProfessionalEmail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Professional Email</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="newContactEmailForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Enter New Professional Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" formControlName="contact_email"
                            placeholder="Professional Email">
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('required') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched )">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('maxlength') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('pattern') && (newContactEmailForm.get('contact_email').dirty || newContactEmailForm.get('contact_email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message"
                            *ngIf="newContactEmailForm.get('contact_email').hasError('aftersubmit')">
                            {{newContactEmailForm.controls.contact_email.errors.aftersubmit}}
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="addNewContactEmail(newContactEmailForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #ChangeAddress>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Address</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="AddressForm" novalidate>
                <div class="form-group gutters-5 row">
                    <div class="col-md-12">
                        <label>Country</label>
                        <select class="form-control custom-select" placeholder="Country" formControlName="country"
                            (change)="changeCountry($event)">
                            <option selected>Canada</option>
                            <option>USA</option>
                        </select>
                        <div class="error-message"
                            *ngIf="AddressForm.get('country').hasError('required') && (AddressForm.get('country').dirty || AddressForm.get('country').touched || partialSubmitted)">
                            This field is required.
                        </div>
                    </div>
                </div>
                <div class="form-group gutters-5 row">
                    <div class="col-md-12">
                        <label>Full Address</label>
                        <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet"
                            (change)="changeAddress($event)" (setAddress)="getAddress($event)"
                            (setHideResult)="setResultHide($event)"></app-azure-maps>
                        <div *ngIf="!hidePlaceResults" class="col-md-12">
                            <div class="option-div">
                                <div class="listing" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                                    <svg class="location-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                        viewBox="0 0 15 20">
                                        <path fill="none" stroke="#505759" stroke-width="1.4"
                                            d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                    </svg>
                                    <div class="detail">
                                        <h6>{{place.address.freeformAddress}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="text" [hidden]="true" placeholder="Full Address" class="form-control"
                            formControlName="address">
                        <div class="error-message"
                            *ngIf="AddressForm.get('address').hasError('maxlength') && (AddressForm.get('address').dirty || AddressForm.get('address').touched)">
                            Max 200 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row gutters-5" (focusout)="cityFocusOut()">
                    <div class="col-md-6">
                        <app-azure-maps *ngIf="!updateAddress" (change)="changeCity($event)" [updateAddress]="updateCity"
                            (setAddress)="getAddress($event)" (setHideResult)="setCityResultHide($event)" [placeHolder]="cityPlaceHolder">
                        </app-azure-maps>
                        <div *ngIf="!hidePlaceResultsCity" class="col-md-12">
                            <div class="dropdownList" *ngIf="!updateAddress">
                                <div class="listing" *ngFor="let place of searchPlaces"
                                    (click)="selectPlace(place)">
                                    <svg class="location-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                        viewBox="0 0 15 20">
                                        <path fill="none" stroke="#505759" stroke-width="1.4"
                                            d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                    </svg>
                                    <div class="detail">
                                        <h6>{{place.address.freeformAddress}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="text" [hidden]="!updateAddress" placeholder="City" class="form-control" formControlName="city">
                        <div class="error-message"
                            *ngIf="AddressForm.get('city').hasError('required') && (showAddressError || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="AddressForm.get('city').hasError('maxlength') && (AddressForm.get('city').dirty || AddressForm.get('city').touched)">
                            Max 20 characters allowed.
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <input type="text" placeholder="{{plchldrPostal}}" class="form-control"
                            formControlName="zip_code">
                        <div class="error-message"
                            *ngIf="AddressForm.get('zip_code').hasError('required') && (AddressForm.get('zip_code').dirty || AddressForm.get('zip_code').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="AddressForm.get('zip_code').hasError('maxlength') && (AddressForm.get('zip_code').dirty || AddressForm.get('zip_code').touched)">
                            Max 20 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row gutters-5">
                    <div class="col-md-12">
                        <input type="text" placeholder="{{plchldrState}}" class="form-control" formControlName="state">
                        <div class="error-message"
                            *ngIf="AddressForm.get('state').hasError('required') && (AddressForm.get('state').dirty || AddressForm.get('state').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="AddressForm.get('state').hasError('maxlength') && (AddressForm.get('state').dirty || AddressForm.get('state').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="NewAddress(AddressForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #delAdmin>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Admin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>You are about to delete Admin:</h4>
            <div class="inner-box">
                <h4>{{inviteObj.first_name}} {{inviteObj.last_name}}</h4>
                <p>{{inviteObj.email}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="deleteAdmin()">
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #cnclRequest>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cancel Request</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>You are about to cancel request for:</h4>
            <div class="inner-box">
                <h4>{{inviteObj.first_name}} {{inviteObj.last_name}}</h4>
                <p>{{inviteObj.email}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="cancelRequest()">
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #addVerifier>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Verifier</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="addAdminForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>First Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter First Name"
                            formControlName="first_name">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('first_name').hasError('required') && (addAdminForm.get('first_name').dirty || addAdminForm.get('first_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('first_name').hasError('maxlength') && (addAdminForm.get('first_name').dirty || addAdminForm.get('first_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('first_name').hasError('aftersubmit')">
                            {{addAdminForm.controls.first_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Last Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter Last Name"
                            formControlName="last_name">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('last_name').hasError('required') && (addAdminForm.get('last_name').dirty || addAdminForm.get('last_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('last_name').hasError('maxlength') && (addAdminForm.get('last_name').dirty || addAdminForm.get('last_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('last_name').hasError('aftersubmit')">
                            {{addAdminForm.controls.last_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('required') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('maxlength') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="addAdminForm.get('email').hasError('pattern') && (addAdminForm.get('email').dirty || addAdminForm.get('email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message" *ngIf="addAdminForm.get('email').hasError('aftersubmit')">
                            {{addAdminForm.controls.email.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="createVerifier(addAdminForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #delVerifier>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Verifier</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>You are about to delete Verifier:</h4>
            <div class="inner-box">
                <h4>{{delVerifierObj.first_name}} {{delVerifierObj.last_name}}</h4>
                <p>{{delVerifierObj.email}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="deleteVerifier()">
            Confirm
        </button>
    </div>
</ng-template>
