<div class="tag-steps">
    <div class="row">
        <div class="col-md-12">
            <h2 class="tag-name mb-3">Page Search</h2>
        </div>
    </div>
    <form [formGroup]="tagSearchForm" novalidate>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Keyword" formControlName="search_key" />
                    <div class="error-message"
                        *ngIf="tagSearchForm.get('search_key').hasError('required') && (tagSearchForm.get('search_key').dirty || tagSearchForm.get('search_key').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="tagSearchForm.get('search_key').hasError('maxlength') && (tagSearchForm.get('search_key').dirty || tagSearchForm.get('search_key').touched)">
                        Max 50 characters allowed.
                    </div>
                    <div class="error-message"
                        *ngIf="tagSearchForm.get('search_key').hasError('minlength') && (tagSearchForm.get('search_key').dirty || tagSearchForm.get('search_key').touched)">
                        Minimum 3 characters required.
                    </div>
                    <div class="error-message"
                        *ngIf="tagSearchForm.get('search_key').hasError('whitespace') && (!tagSearchForm.get('search_key').hasError('required')) && (tagSearchForm.get('search_key').dirty || tagSearchForm.get('search_key').touched || submitted)">
                        Please enter valid data.
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group search_key-relative">
                    <div class="d-flex" (focusout)="focusOutFunction()">
                        <app-azure-maps class="w-100" (change)="changeAddress($event)" [updateAddress]="updateAddress"
                            (setAddress)="getAddress($event)" (setHideResult)="setResultHide($event)">
                        </app-azure-maps>
                    </div>
                    <div *ngIf="!hidePlaceResults && searchPlaces.length > 0" class="col-md-12 p-0">
                        <div class="option-div">
                            <div class="listing" *ngFor="let place of searchPlaces"
                                (click)="selectPlace(place)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                    viewBox="0 0 15 20">
                                    <path fill="none" stroke="#505759" stroke-width="1.4"
                                        d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                </svg>
                                <div class="detail">
                                    <h6>{{place.address.freeformAddress}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-colored mb-3" type="submit" (click)="search(tagSearchForm.valid)">Search</button>
                </div>
            </div>

        </div>
    </form>
    <div class="row" *ngIf="!hideResults">
        <div class="col-12 expSearchListWrap">
            <div class="expSearchListWrapInner">
                <h4 class="clickNot">Can't find the page you're looking for? Click Next.</h4>
                <ul class="no-list expSearchList scroll-activity scrollbar" *ngIf="searchResults.length > 0">
                    <li *ngFor="let searchResult of searchResults">
                        <div class="leftWrap">
                            <div class="forImg">
                                <img alt="profile-image" class="thumb" src="{{searchResult.page_info.display_pic? searchResult.page_info.display_pic : 'assets/images/default.svg'}}" />
                            </div>
                            <div class="forhdg">
                                <h3>{{searchResult.page_info.page_title}}</h3>
                                <p>{{searchResult.page_info.address}}</p>
                            </div>
                        </div>
                        <div class="rightWrap">
                            <button class="btn btn-colored" (click)="selectPage(searchResult)">
                                <ng-template
                                    [ngIf]="selectedResult && selectedResult.id == searchResult.id && selectedResult.is_school == searchResult.is_school"
                                    [ngIfElse]="tagTemp">
                                    <span>Selected</span>
                                </ng-template>
                            </button>
                        </div>
                    </li>
                </ul>
                <div *ngIf="searchResults.length == 0" class="text-center py-4">No Result(s) Found</div>
            </div>
        </div>
    </div>
</div>
<ng-template #tagTemp>
    <span>Tag</span>
</ng-template>
