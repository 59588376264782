import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, SelectControlValueAccessor } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { findIndex } from 'rxjs/operators';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})

export class ProfileHeaderComponent implements OnInit {
  modalRef: BsModalRef;
  user: any = {};
  editLink: any = {};
  verifiers = [];
  links = [];
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  uploadForm: FormGroup;
  linkForm: FormGroup;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  profileImage: string;
  profileHeader: string = "";
  reportUser:boolean = true;
  public isMinor: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public isHeaderToDel: boolean = false;
  public delError: boolean = false;
  delErrorMessage = "Unable to delete the image. Please try again later.";
  getImgCount = 0;
  newmodalRef: BsModalRef;
  account_verification_status: string;
  days_left_to_verify: string;
  mediaType: any;

  constructor(private modalService: BsModalService, private profileService: ProfileService, private router: Router, public formBuilder: FormBuilder,
    private authService: AuthenticationService, private customValidator: CustomvalidationService) {
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required])
    });
    this.linkForm = this.formBuilder.group({
      email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      tw_link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      tt_link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      insta_link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      fb_link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      yt_link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
  }

  ngOnInit(): void {
    this.getImgCount = 0;
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.age_group && this.user.age_group == 1) {
      this.isMinor = true;
    } else {
      this.isMinor = false;
    }
    if (this.user.display_picture){
      this.profileImage = this.user.display_picture;
    }
    if (this.user.header_image){
      this.profileHeader = this.user.header_image;
    }
    this.authService.AccountVerificationStatus()
    .subscribe(response => {
      this.account_verification_status = response.response.account_verified
      this.days_left_to_verify = response.response.days_left
    });
    this.getVerifiers();
    this.getLinks();
  }

  getImageUrl() {
    this.profileHeader = 'assets/images/new-header-img.png';
    this.profileImage = "assets/images/p-img.png";
    if (this.getImgCount === 0){
      this.profileService.getUserImage(this.user.id)
      .subscribe(response => {
        if (response.status === 200) {
          if (response.response.display_picture){
            this.profileImage = response.response.display_picture;
            this.user.display_picture = response.response.display_picture;
          }
          if (response.response.header_image){
            this.profileHeader = response.response.header_image;
            this.user.header_image = response.response.header_image;
          }
          localStorage.setItem('user', JSON.stringify(this.user));
        }
      })
    }
    this.getImgCount = this.getImgCount + 1;
  }

  smLinkValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let link_data = control.get('link').value;
    if (link_data && link_data.length > 0 && this.mediaType) {
      let match = false;
      link_data = link_data.toLowerCase();
      if (this.mediaType == 4 && (link_data.indexOf("youtube") !== -1 || link_data.indexOf("youtu.be") !== -1)) {
        match = true;
      } else if (this.mediaType == 1 && (link_data.indexOf("instagram") !== -1 || link_data.indexOf("instagr") !== -1)) {
        match = true;
      } else if (this.mediaType == 2 && link_data.indexOf("tiktok") !== -1) {
        match = true;
      } else if (this.mediaType == 3 && link_data.indexOf("facebook") !== -1) {
        match = true;
      } else if (this.mediaType == 5 && link_data.indexOf("twitter") !== -1) {
        match = true;
      }
      if (match) {
        return null;
      } else {
        return { 'invalidUrl': true };
      }
    }
    return null;
  }

  getVerifiers() {
    this.profileService.listVerifiers()
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      })
  }

  getLinks(){
    this.profileService.getSocialLinks(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.links = response.response;
        } else {
          this.links = [];
        }
      });
  }

  config = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered custom-modal new-modal-ui',
  };

  openModal(template: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      this.config  
      // Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  openVerifiersModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  setLinks(social_type: any){
    let set_val = null;
    let insta_index = this.links.findIndex(obj => obj.social_type == social_type);
    if (insta_index !== -1) {
      let link = this.links[insta_index];
      set_val = link.link;
    }
    return set_val;
  }
  openSocialModal(socialTemplate: TemplateRef<any>) {
    this.linkForm.reset();
    this.submitted = false;
    this.linkForm.get('insta_link').setValue(this.setLinks(1));
    this.linkForm.get('tt_link').setValue(this.setLinks(2));
    this.linkForm.get('fb_link').setValue(this.setLinks(3));
    this.linkForm.get('tw_link').setValue(this.setLinks(5));
    this.linkForm.get('yt_link').setValue(this.setLinks(4));
    this.linkForm.get('email').setValue(this.setLinks(6));
    this.modalRef = this.modalService.show(socialTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position contact-modal' })
    );
  }

  openSetting(settingModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(settingModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }
  showImg(headerImages: TemplateRef<any>) {
    if (this.profileHeader){
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  addHeaderImage(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value,
        'header_img': true,
        'display_pic': false,
      }
      this.profileService.ProfileImage(model, this.user.id)
        .subscribe(response => {
          this.handleRes(response);
        }, error => {
          this.handleError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleRes(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.user.header_image = response.response.header_image;
      localStorage.setItem('user', JSON.stringify(this.user));
      if (this.user.header_image){
        this.profileHeader = this.user.header_image;
      }
    }
  }

  protected handleError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  closeDiv(){
    this.account_verification_status = '2'
  }

  addImage(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value,
        'display_pic': true,
        'header_img': false,
      }
      this.profileService.ProfileImage(model, this.user.id)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.user.display_picture = response.response.display_picture;
      localStorage.setItem('user', JSON.stringify(this.user));
      if (this.user.display_picture){
        this.profileImage = this.user.display_picture;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delImageModal(delImage: TemplateRef<any>, header_image: boolean = false) {
    this.delError = false;
    if (header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.newmodalRef = this.modalService.show(delImage,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  deleteImage(){
    this.profileService.delProfileImage({'header_img': this.isHeaderToDel})
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.newmodalRef.hide();
        if (this.isHeaderToDel) {
          this.profileHeader = null;
          this.user.header_image = null;
        } else {
          this.profileImage = null;
          this.user.display_picture = null;
        }
        localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
  }

  updateLink(link: string){
    if (link == null || link.length == 0) {
      return null;
    }
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink;
    }
  }

  addSocialLink(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let val_arr = [{'link': this.updateLink(this.linkForm.get('insta_link').value), 'social_type': 1},
                  {'link': this.updateLink(this.linkForm.get('tt_link').value), 'social_type': 2},
                  {'link': this.updateLink(this.linkForm.get('fb_link').value), 'social_type': 3},
                  {'link': this.updateLink(this.linkForm.get('tw_link').value), 'social_type': 5},
                  {'link': this.updateLink(this.linkForm.get('yt_link').value), 'social_type': 4},
                  {'link': this.linkForm.get('email').value, 'social_type': 6}]
      let model = {'payload': val_arr}
      this.profileService.addSocialLink(model)
        .subscribe(response => {
          if (response.status === 200) {
            this.links = response.response;
            this.modalRef.hide();
            this.submitted = false;
            this.linkForm.reset();
          } else {
            let data = response.message;
            let message = "Something went wrong please try again later.";
            const fields = Object.keys(data || {});
            if (fields.indexOf('link') !== -1) {
              message = data['link'][0];
            }
            this.linkForm.get('tw_link').setErrors({aftersubmit: message});
          }
        }, error => {
          this.linkForm.get('tw_link').setErrors({aftersubmit: "Something went wrong please try again later."});
        });
    }
  }

}
