import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss']
})
export class TagSearchComponent implements OnInit {
  @Output() setPage: EventEmitter<any> = new EventEmitter();
  @Input() notifier: Subject<boolean>;
  @Input() expType: any = 2;
  tagSearchForm: FormGroup;
  searchPlaces: any[] = [];
  searchResults: any[] = [];
  selectedResult: any;
  latitude: string;
  longitude: string;
  hideResults: boolean = true;
  hidePlaceResults: boolean = true;
  showAddressError: boolean = false;
  submitted: boolean = false;
  updateAddress: string = '';

  constructor(public formBuilder: FormBuilder, private profileService: ProfileService) {
    this.tagSearchForm = this.formBuilder.group({
      search_key: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), this.noWhitespaceValidator])
    });
  }

  ngOnInit(): void {
    this.notifier.subscribe(data => {
      this.resetSearch();
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  focusOutFunction() {
    setTimeout(() => {
      this.hidePlaceResults = true;
      this.searchPlaces = [];
    }, 500);
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.showAddressError = true;
      this.latitude = null;
      this.longitude= null;
      this.updateAddress = "";
    } else {
      this.showAddressError = false;
      this.latitude = null;
      this.longitude= null;
    }
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    this.updateAddress = place.address.freeformAddress;
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

  search(isValid: boolean) {
    this.submitted = true;
    this.searchResults = [];
    if (isValid){
      this.hideResults = false;
      let params = "?search=" + this.tagSearchForm.get('search_key').value;
      if (this.latitude && this.longitude) {
        params += "&lat=" + this.latitude + "&lng=" + this.longitude;
      }
      this.profileService.tagSearch(this.expType, params)
      .subscribe(data => {
        this.searchResults = data.response;
      });
    } else {
      this.hideResults = true;
    }
  }

  selectPage(searchResult: any){
    this.selectedResult = searchResult;
    this.setPage.emit(this.selectedResult.page_info);
  }

  resetSearch(){
    this.updateAddress = "";
    this.tagSearchForm.reset();
    this.searchResults = [];
    this.selectedResult = null;
    this.submitted = false;
    this.hideResults = true;
    this.latitude = null;
    this.longitude= null;
  }

}
