import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserProfile } from '../shared/userProfile';
import { UserBook } from '../shared/userBook';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { SlickCarouselComponent } from "ngx-slick-carousel";

import { PublicResumeService } from '../services/public-resume.service';

@Component({
  selector: 'app-public-resume',
  templateUrl: './public-resume.component.html',
  styleUrls: ['./public-resume.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class PublicResumeComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  tabActive = 2;
  pageImage: string = null;
  profileImage: string;
  verifiers = [];
  links = [];
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  UserDetail: any = {};
  UserID: any = {};
  user: any = {};
  profile: any = {};
  editSkill: any = {};
  expModalTitle: any;
  editInterest: any = {};
  editBook: any = {};
  editBulletin: any = {};
  editActivity: any = {};
  skills = [];
  interests = [];
  books = [];
  bulletins = [];
  descriptionForm: FormGroup;
  profileHeader: string = "";
  public infoDiv1 = true;
  activeSlideIndex = 0;
  today = new Date();
  public learnAbout = true;
  public goalsBucket = true;

  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  userBook: UserBook = {
    description: '',
    title: '',
    user: '',
    id: '',
    author: '',
    rating: undefined
  }
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  skillLinkArray: any[];
  skillDocArray: any[];
  favouriteList: []
  goalsBucketList: [];
  learnMoreList = [];
  MamList: [];
  currentUserData: any;
  copyEmailBool: boolean = false;

  constructor(private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: PublicResumeService) {
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', Validators.maxLength(1000)),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    var url = document.URL.split('/')
    this.UserID = url[url.length - 1]
  }

  ngOnInit(): void {
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.profileService.getUserProfile(this.UserID)
    .subscribe(response => {
      if (response.message == "not-found"){
        this.router.navigate(['**']);
      } else {
        this.currentUserData = response.response;
        this.user = this.currentUserData.userprofile;
        this.profileHeader = this.user.header_image;
        this.profileImage = this.user.display_picture;
        if (this.currentUserData.user_description['id'] !== undefined) {
          this.profile = this.currentUserData.user_description;
        }
        this.goalsBucketList = this.currentUserData.goalsandbuckets;
        this.learnMoreList = this.currentUserData.learnmore;
      }
    }, error => {
      this.router.navigate(['**']);
    })
   
    this.getVerifier();
    
    this.profileService.getUserSkills(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        }
      })
    this.profileService.getUserFavourite(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0){
        this.favouriteList = response.response;
      }
    })
    this.profileService.getUserInterests(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.interests = response.response;
      }
    })
    this.profileService.getUserBooks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        }
      })
    this.profileService.getUserBulletin(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.bulletins = response.response;
      }
    })
    this.profileService.getUserMam(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0){
        this.MamList = response.response;
      }
    });
    this.getLinks();
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  
  slideConfig2 = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideBooks = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  favouritesSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  mamSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  openModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  
  openVerifiersModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  updateSkillModal(skillpopup: TemplateRef<any>, skill: any) {
    this.editSkill = skill;
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.skillLinkArray = skill.user_link;
    this.skillDocArray = skill.user_document;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateInterestModal(skillpopup: TemplateRef<any>, interest: any) {
    this.editInterest = interest;
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.skillLinkArray = interest.user_link;
    this.skillDocArray = interest.user_document;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  
  updatebookModal(skillpopup: TemplateRef<any>, book: any) {
    this.editBook = book;
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    this.modalRef = this.modalService.show(bulletinDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal new-modal-ui custom-profile-modal' })
    );
  }

  booksListing(booksListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(booksListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  openNewContactusModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position contact-modal' })
    );
  }

  getVerifier() {
    this.profileService.getVerifiers(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      })
  }

  getLinks(){
    this.profileService.getSocialLinks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.links = response.response;
        } else {
          this.links = [];
        }
      });
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyEmail(){
    let index = this.links.findIndex(obj => obj.social_type == 6);
    let msg = this.links[index].link;
    this.setToCopy(msg);
    this.copyEmailBool = true;
    setTimeout(() => {this.copyEmailBool = false  ;}, 3000);
  }

}
