<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="studentSignUpInner">
                    <div class="SignUpInnerWrap WrapWhiteShadow mb-0">
                        <h1 class="hdg">Run an Organization?</h1>
                        <p class="subHdg">Create A New Page! Select One:</p>

                        <div class="form-group m-0 text-center">
                            <div class="mb-3">
                                <a class="btn btnSignUp" [routerLink]="['/createbusinesspage']">Organization</a>
                            </div>
                            <div>
                                <a class="btn btnSignUp" [routerLink]="['/create-clubteam-page']">School Club or Team</a>
                            </div>
                        </div>
                        <p class="subHdg mb-0 mt-5" *ngIf="!isDisabled">Or</p>
                        <p class="subHdg mb-4" *ngIf="!isDisabled">Create Your Resume!</p>
                        <div class="form-group m-0 text-center" *ngIf="!isDisabled">
                            <a href="javascript:void();" *ngIf="!isDisabled" (click)="openDisclaimer(disclaimer)"
                                class="btn btnSignUp">Resume</a>
                            <a href="javascript:void();" *ngIf="isDisabled" (click)="openDisclaimer(disabledModal)"
                                class="btn btnSignUp">Resume</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #disclaimer>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Disclaimer</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box disclaimer-box">
            <p><strong>Important: </strong>Once you create a Resume, you will not be able to delete it without
                deleting your whole account. They are linked. You will automatically have your own searchable identity.
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <a class="common-btn" [routerLink]="['/user-profile']" (click)="modalRef.hide()">Accept</a>
        <button class="common-btn" (click)="modalRef.hide()">Decline</button>
    </div>
</ng-template>

<ng-template #disabledModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Unqiue Profile </h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>Resume Already Exist</h3>
                </div>
            </div>
        </div>
    </div>
</ng-template>