import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../shared/user';
import { FormControl, FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { LoginService } from '../services/login.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  enteredEmail: string;
  resendEmailMessage: string = '';
  mainSidebar: boolean = false;
  firstStep: boolean = true;
  secondStep: boolean = false;
  firstStepForm: FormGroup;
  maxNum: number = new Date().getFullYear();
  today = new Date();
  public submitted: boolean = false;
  public invalidDate: boolean = true;
  public isMinor: boolean = false;
  public successOverlay: boolean = false;
  public dob: string;
  public infoDiv = true;
  public age: number;
  public partialSubmitted: boolean = false;
  submitErrorMsg: string = null;
  public submitError: boolean = false;

  user: User = {
    first_name: '',
    last_name: '',
    email: '',
    user_role : 2,
    password : '',
    is_terms_accepted : false,
    parent_email : undefined,
    date_of_birth: '',
    student_referral: undefined
  };

  inviteForm: FormGroup;
  dateFilled: boolean = false;
  publicToken = null;

  constructor(private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private datePipe: DatePipe, private loginService: LoginService) {
    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    }
    this.publicToken = this.loginService.publicToken ? this.loginService.publicToken : this.loadPublicToken();
    this.firstStepForm = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastname: new FormControl('', [Validators.required, Validators.minLength(2),Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      confirm_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      date_of_birth: new FormControl('', Validators.required),
      parent_email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15),this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required),
    }, {
      validators: [this.checkEmail.bind(this), this.checkPassword.bind(this)]
    });

    this.inviteForm = this.formBuilder.group({
      invite_code: new FormControl('', Validators.required)
    });
   } 


  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {

  }

  loadPublicToken() {
    this.loginService.getPublicToken()
      .pipe(first())
      .subscribe(
          data => {
        },
          error => {
        });
    }

  checkDate() {
    if (this.firstStepForm.get('date_of_birth').value !== "" && this.firstStepForm.get('date_of_birth').value !== null) {
      let dob = this.firstStepForm.get('date_of_birth').value;
      let currentYear = new Date().getFullYear();
      const newDate = currentYear + "/1/1";
      const prevDate = (currentYear - 2) + "/12/31";
      const ckDate = currentYear + "/4/21";
      let compareDate = new Date(newDate);
      let comparePrevDate = new Date(prevDate);
      let checkDate = new Date(ckDate);
      let minorDob = new Date(dob.setFullYear(dob.getFullYear() + 13));
      if (comparePrevDate < minorDob && minorDob < compareDate) {
        if (new Date() < checkDate) {
          this.isMinor = true;
          this.firstStepForm.controls['parent_email'].setValidators([Validators.required,Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]);
        } else {
          this.isMinor = false;
        }
      } else if (compareDate < minorDob || compareDate.getTime() === minorDob.getTime()) {
          this.isMinor = true;
          this.firstStepForm.controls['parent_email'].setValidators([Validators.required,Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]);
      } else {
        this.isMinor = false;
        this.firstStepForm.get('parent_email').clearValidators();
        this.firstStepForm.get('parent_email').updateValueAndValidity();
        this.firstStepForm.get('parent_email').setValue(null);
      }
      dob = new Date(dob.setFullYear(dob.getFullYear() - 13));
      this.dob = this.datePipe.transform(dob,"yyyy-MM-dd");
      this.dateFilled = true;
    } else {
      this.dateFilled = false;
    }
  }

  
  disableOverlay(){
    this.router.navigate(["/signin"]);
    this.successOverlay = false;
    this.enteredEmail = null;
    this.submitted = false;
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  checkEmail(formGroup: FormGroup) {
    const { value: email } = formGroup.get('email');
    const { value: confirmEmail } = formGroup.get('confirm_email');
    return email === confirmEmail ? null : { emailNotMatch: true };
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.user.is_terms_accepted = true;
    } else {
      this.user.is_terms_accepted = false;
    }
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (this.user.is_terms_accepted && isValid && this.dateFilled) {
      this.user.first_name = this.firstStepForm.get('firstname').value;
      this.user.last_name = this.firstStepForm.get('lastname').value;
      this.user.email = this.firstStepForm.get('email').value;
      this.user.password = this.firstStepForm.get('password').value;
      if (this.firstStepForm.get('parent_email').value !== "" && this.firstStepForm.get('parent_email').value !== null) {
        this.user.parent_email = this.firstStepForm.get('parent_email').value;
      } else {
        this.user.parent_email = undefined;
      }
      if (this.user.parent_email != undefined && this.user.email.toLowerCase() === this.user.parent_email.toLowerCase()){
        this.firstStepForm.get('parent_email').setErrors({ aftersubmit: 'The user email and parent email can not be the same. Please enter different email ids.' });
      }
      else {
        this.user.date_of_birth = this.dob;
        this.user.is_terms_accepted = true;
        this.authService.userSignup(this.user)
        .subscribe(response => {
          this.handleResponse(response);
        },
          error => {
            this.handleSubmitError(error.error);
          });
      }
    }

  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.successOverlay = true;
      this.enteredEmail = this.firstStepForm.controls['email'].value;
        this.firstStep = true;
        this.secondStep = false;
        this.firstStepForm.reset();
        this.submitted = false;
        this.partialSubmitted = false;
    } else {
      this.firstStep = true;
      this.secondStep = false;
      this.firstStepForm.reset();
      this.submitted = false;
      this.partialSubmitted = false;
      if (response.status === 112) {
        this.submitErrorMsg = response.message;
      } else {
        this.submitErrorMsg = "Something went wrong. Please try again later";
      }
      this.submitError = true;
      this.hideMessage();
    }
  }

  hideMessage() {
    setTimeout(() => {
      this.submitError = false;
    }, 6000);
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    let firstFormErr = false;
    fields.forEach(field => {
      if (this.firstStepForm.contains(field)) {
        this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
        firstFormErr = true;
      }
    });
    if (firstFormErr) {
      this.firstStep = true;
      this.secondStep = false;
    } else {
      this.firstStep = false;
      this.secondStep = true;
    }
  }

  resendVerification(){
    let data = {
      'email': this.enteredEmail
    }
    this.authService.resendVerify(data)
      .subscribe(response => {
        this.handleReverify(response, true);
      },
        error => {
          this.handleReverify(error.error, false);
        });
  }

  protected handleReverify(response: any, is_success:boolean) {
    if (response.status == 111){
      is_success = false;
    }
    if (is_success){
      this.resendEmailMessage = 'Please check your inbox for verification email'
    } else {
      this.resendEmailMessage = 'There was an error in sending verification email. This can happen in case of invalid email or when your account has been verified already'
    }
  }

  checkCode(isValid: boolean){
    if (isValid) {
      const invite_code = this.inviteForm.get('invite_code').value;
      this.authService.verifyInviteCode(invite_code)
      .subscribe(response => {
        if (response.status === 111) {
          this.inviteForm.get('invite_code').setErrors({ aftersubmit: 'Sorry, the used invitation code is incorrect, please try again with the correct code.' });
        } else if (response.status === 200) {
            this.inviteForm.reset();
            this.submitted = false;
            localStorage.setItem('invited-user', JSON.stringify(response.response))
            this.router.navigate(['/invitation-signup']);
        }
      },
        error => {
          this.inviteForm.get('invite_code').setErrors({ aftersubmit: 'Sorry, the used invitation code is incorrect, please try again with the correct code.' });
        });
    }
  }
}
