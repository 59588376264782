<div class="side-links">
    <ul class="d-flex d-md-none">
        <li class="active">
            <a [routerLink]="['/signup']">
                Sign up
            </a>
        </li>
        <li class="bg-login">
            <a [routerLink]="['/signin']">
                Log In
            </a>
        </li>
    </ul>
</div>