<div class="initiativeTeamBlock">
    <div class="initiativeTeamSlider pt-0 pb-0">
        <div class="d-flex align-items-center mb-4 withRadBlueName">
            <h3 class="blueHdg">pending</h3>
        </div>
        <p *ngIf="showSuccessMsg" class="alert alert-success">Verification request sent successfully.</p>
        <p *ngIf="showErrorMsg" class="alert alert-danger">{{ errorMsg }}</p>
    </div>

    <div class="initiativeTeamSlider mb-4 mb-md-5">
        <div class="teamListScroll scroll-activity scrollbar">
            
            <ul class="teamListWrap no-list">
                <li *ngFor="let pendingExp of pendingExpSliced">
                    <div class="teamMemberEach">
                        <div class="topMember cursor-pointer" (click)="redirectPage(pendingExp);$event.stopPropagation()">
                            <img alt="icon" class="bannerImg" src="{{ pendingExp.more_info?.header_img ? pendingExp.more_info?.header_img : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="profileMember cursor-pointer" (click)="redirectPage(pendingExp);$event.stopPropagation()">
                            <img alt="icon" class="profileImg" src="{{ pendingExp.more_info?.display_pic ? pendingExp.more_info?.display_pic : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="btmMember">
                            <h1 class="name">{{ pendingExp.more_info.verifiers_name }}</h1>
                            <p class="teamEmail">{{ pendingExp.more_info.verifiers_email }}</p>
                            <h3 class="subName">{{ pendingExp.position }}</h3>
                            <p class="dateHistory mb-0">{{ pendingExp.start_date | date: 'MMM y'}} - <span *ngIf="pendingExp.end_date">{{ pendingExp.end_date | date: 'MMM y'}}</span>
                                <span *ngIf="!pendingExp.end_date">Present</span>
                            </p>
                            <p class="hours" *ngIf="pendingExp?.total_hours">{{ pendingExp.total_hours }} Hours</p>
                            <div class="memberActions">
                                <button class="btn common-btn btnMessage" (click)="resendExpVerification(pendingExp)">resend</button>
                                <button class="btn common-btn btnProfile" (click)="deleteExperience(pendingExp)">delete</button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="timeLineLoad pt-3" *ngIf="pendingLoadMoreBool">
            <a href="javascript:void(0);" (click)="pendingLoadMore()">Load More</a>
        </div>
    </div>

    <div class="initiativeTeamSlider pt-0 pb-0">
        <div class="d-flex align-items-center mb-4 withRadBlueName">
            <h3 class="blueHdg">Approved</h3>
        </div>
    </div>

    <div class="initiativeTeamSlider mb-4 mb-md-5">
        <div class="teamListScroll scroll-activity scrollbar">
            <ul class="teamListWrap no-list">
                <li *ngFor="let approvedExp of approvedExpSliced">
                    <div class="teamMemberEach">
                        <div class="topMember cursor-pointer" (click)="redirectPage(approvedExp);$event.stopPropagation()">
                            <img alt="icon" class="bannerImg" src="{{ approvedExp.more_info?.header_img ? approvedExp.more_info?.header_img : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="profileMember cursor-pointer" (click)="redirectPage(approvedExp);$event.stopPropagation()">
                            <img alt="icon" class="profileImg" src="{{ approvedExp.more_info?.display_pic ? approvedExp.more_info?.display_pic : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="btmMember">
                            <h1 class="name">{{ approvedExp.more_info.verifiers_name }}</h1>
                            <p class="teamEmail">{{ approvedExp.more_info.verifiers_email }}</p>
                            <h3 class="subName">{{ approvedExp.position }}</h3>
                            <p class="dateHistory mb-0">{{ approvedExp.start_date | date: 'MMM y'}} - <span *ngIf="approvedExp.end_date">{{ approvedExp.end_date | date: 'MMM y'}}</span>
                                <span *ngIf="!approvedExp.end_date">Present</span>
                            </p>
                            <p class="hours" *ngIf="approvedExp?.total_hours">{{ approvedExp.total_hours }} Hours</p>
                            <p class="verifedBy">
                                <img src="assets/images/verifyProfile.svg" />
                                <span>Verified by {{ approvedExp.more_info.verifiers_name }} on {{ approvedExp?.verified_date | date:'longDate' }}</span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="timeLineLoad pt-3" *ngIf="approvedLoadMoreBool">
            <a href="javascript:void(0);" (click)="approvedLoadMore()">Load More</a>
        </div>
    </div>

    <div class="initiativeTeamSlider pt-0 pb-0">
        <div class="d-flex align-items-center mb-4 withRadBlueName">
            <h3 class="blueHdg">DECLINED</h3>
        </div>
    </div>

    <div class="initiativeTeamSlider mb-3">
        <div class="teamListScroll scroll-activity scrollbar">
            <ul class="teamListWrap no-list">
                <li *ngFor="let declinedExp of declinedExpSliced">
                    <div class="teamMemberEach">
                        <div class="topMember cursor-pointer" (click)="redirectPage(declinedExp);$event.stopPropagation()">
                            <img alt="icon" class="bannerImg" src="{{ declinedExp.more_info?.header_img ? declinedExp.more_info?.header_img : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="profileMember cursor-pointer" (click)="redirectPage(declinedExp);$event.stopPropagation()">
                            <img alt="icon" class="profileImg" src="{{ declinedExp.more_info?.display_pic ? declinedExp.more_info?.display_pic : './assets/images/new-header-img.png'}}" />
                        </div>
                        <div class="btmMember">
                            <h1 class="name">{{ declinedExp.more_info.verifiers_name }}</h1>
                            <p class="teamEmail">{{ declinedExp.more_info.verifiers_email }}</p>
                            <h3 class="subName">{{ declinedExp.position }}</h3>
                            <p class="dateHistory mb-0">{{ declinedExp.start_date | date: 'MMM y'}} - <span *ngIf="declinedExp.end_date">{{ declinedExp.end_date | date: 'MMM y'}}</span>
                                <span *ngIf="!declinedExp.end_date">Present</span>
                            </p>
                            <p class="hours" *ngIf="declinedExp?.total_hours">{{ declinedExp.total_hours }} Hours</p>
                            <p class="verifedBy declinedBy">
                                <span>Declined <span *ngIf="declinedExp?.verified_date">on {{ declinedExp?.verified_date | date:'longDate' }}</span></span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="timeLineLoad pt-3" *ngIf="declinedLoadMoreBool">
            <a href="javascript:void(0);" (click)="declinedLoadMore()">Load More</a>
        </div>
    </div>
</div>