import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseTeamEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private baseTeamUrl: string = BaseTeamEndPoint.apiEndpoint;
  constructor(private http: BaseService) { }

  sendInvite(data: any) {
    return this.http.post(this.baseTeamUrl + 'invite/', data);
  }

  addTeam(data: any) {
    return this.http.post(this.baseTeamUrl + 'add/', data);
  }

  deleteTeamMember(id: string) {
    return this.http.delete(this.baseTeamUrl + 'update-member/' + id + '/');
  }

  getPendingRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-pending-request-exp/' + params);
  }

  getApprovedRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-approved-request/' + params);
  }

  acceptDeclineRequest(id: string, params: any, data: any) {
    return this.http.put(this.baseTeamUrl + 'update-member/' + id + '/' + params, data);
  }

  getUnreadReq() {
    return this.http.get(this.baseTeamUrl + 'get-pending-request-count' + '/');
  }

  markRequests() {
    return this.http.get(this.baseTeamUrl + 'mark-request-read/');
  }

  addEduTeam(data: any) {
    return this.http.post(this.baseTeamUrl + 'add-education-team/', data);
  }

  getApprovedEduRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-approved-request/edu/' + params);
  }

  getPendingEduRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-pending-request/edu/' + params);
  }

  acceptDeclineEduRequest(id: any, params: any, data: any) {
    return this.http.put(this.baseTeamUrl + 'update-education-member/' + id + '/' + params, data);
  }

  deleteEduTeamMember(id: string) {
    return this.http.delete(this.baseTeamUrl + 'update-education-member/' + id + '/');
  }

  addIniTeam(data: any) {
    return this.http.post(this.baseTeamUrl + 'add-initiative-team/', data);
  }

  getApprovedIniRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-approved-request/initiative/' + params);
  }

  getPendingIniRequest(params: any) {
    return this.http.get(this.baseTeamUrl + 'get-pending-request/initiative/' + params);
  }

  acceptDeclineIniRequest(id: any, params: any, data: any) {
    return this.http.put(this.baseTeamUrl + 'update-initiative-member/' + id + '/' + params, data);
  }

  deleteIniTeamMember(id: string) {
    return this.http.delete(this.baseTeamUrl + 'update-initiative-member/' + id + '/');
  }

  getTeamCount() {
    return this.http.get(this.baseTeamUrl + 'get-team-connection/count/');
  }

  getNetworkBanner(id: any) {
    return this.http.get(this.baseTeamUrl + 'get-connection-banner/' + id + '/');
  }

  getNetworks(user_id: any) {
    return this.http.get(this.baseTeamUrl + 'get-networks/' + user_id + '/');
  }

  getUserTeams(user_id: any) {
    return this.http.get(this.baseTeamUrl + 'get-user-teams/' + user_id + '/');
  }

}
