<section class="profile-head newPageShow newProfile">
   
<div class="tab-content-box">
        <div class="fullBanner">
                <div class="forImage">
                    <img *ngIf="profileHeader" src="{{profileHeader}}" class="bannerImg cursor-pointer" alt="header-img">
                    <img *ngIf="!profileHeader" src="assets/images/new-header-img.png" class="bannerImg cursor-pointer"
                    alt="header-img">
                </div>

                <div class="forDetail">
                    <div class="container custom-container max-890 mb-40">
                        <div class="row">
                            <div class="col-12 center-wrap">
                                <div class="withProfile">
                                    <div class="forEdit mr-auto">
                                        <form novalidate="" class="formImg ng-untouched ng-pristine ng-valid">
                                            <img *ngIf="profileImage" src="{{profileImage}}" class="profileImg"
                                            alt="header-img">
                                            <img *ngIf="!profileImage" src="assets/images/p-img.png" class="profileImg"
                                            alt="header-img">
                                        </form>
                                    </div>
                                    <div class="btn-box">
                                        <button type="button" class="contact-info-btn" (click)="openNewContactusModal(contactListingtemplate)">
                                            <img src="/assets/images/contact-info-btn.svg" alt="btn" />
                                        </button>
                                    </div>
                                </div>
                                <div class="profileList">
                                    <h3>{{user.first_name}} {{user.last_name}}</h3>
                                    <p (click)="openVerifiersModal(verification)"
                                        *ngIf="verifiers.length >= 3 || user.approval_status"
                                        class="d-inline-flex align-items-center cursor-pointer">
                                        <span>
                                            <img alt="image" src="assets/images/verifyProfile.svg" alt="">
                                        </span> Verified
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
           
            <div class="tabWrap">
                <div class="container custom-container max-890">
                    <div class="row">
                        <div class="col-12">
                            <ul class="tabBtns no-list">
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 2
                                  }" (click)="tabActive=2"> Experiences </li>
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 1
                                  }" (click)="tabActive=1"> canvas </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
</div>
   
    <div class="custom-tab-dark custom-tab-teal">

        <div *ngIf="tabActive == 1">
            <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3 pt-0">
                <div class="topLinks">
                    <div class="content-section">
                        <div class="content-profile-white-box" *ngIf="profile.description">
                                <div class="titleDiv d-flex align-items-center" *ngIf="profile.description">
                                        <h3>
                                            Biography
                                        </h3>
                                    </div>
                
                                    <div class="detailContent bioDesc" *ngIf="profile.description" [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                                        <p (click)="openModal(editDescriptionTemplate)"
                                            [innerHtml]="profile.description | slice:0:1000"></p>
                                        <a *ngIf="(profile.description + '').length > 1000" href="javascript:void();"
                                            (click)="infoDiv1 = !infoDiv1" class="d-block text-center">
                                            <img src="assets/images/new-arrow.svg" alt="icon">
                                        </a>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="MamList && MamList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="MamList && MamList.length > 0">
                                        <h3>
                                            more about me
                                        </h3>
                                    </div>
                
                                    <div class="detailContent" *ngIf="MamList && MamList.length > 0">
                                        <ngx-slick-carousel class="sliderContent carousel mt-4" #slickModal="slick-carousel"
                                            [config]="mamSlider">
                                            <div class="mam-slider slide" ngxSlickItem *ngFor="let mam of MamList">
                                                <div class="mam-wrap cursor-pointer">
                                                    <div class="mx-auto">
                                                        <img src="{{mam.thumbnail ? mam.thumbnail :'assets/images/default.svg'}}"
                                                            class="icon">
                                                    </div>
                                                    <div class="dtl">
                                                        <div class="custom-tooltip copy-tooltip new">
                                                                <h3 class="text-capitalize">{{ mam.title }}</h3>
                                                                <div class="tooltip-data text-capitalize">{{ mam.title }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="user.user_role == '2' && skills?.length > 0">
                                <div class="titleDiv d-flex align-items-center"
                                *ngIf="user.user_role == '2' && skills?.length > 0">
                                <h3 class="with-list-icon">
                                    Skills
                                </h3>
                            </div>
                            <div class="detailContent" *ngIf="user.user_role == '2' && skills?.length > 0">
                                <ul class="no-list awardsList withSkills" *ngIf="skills?.length > 0">
                                    <li *ngFor="let skill of skills">
                                        <div class="awardsDataLeft" (click)="updateSkillModal(editSkillpopup, skill)">
                                            <p class="name m-0 mr-2 cursor-pointer">{{ skill.title }}</p>
                                            <div class="forDotsImages">
                                                <span *ngIf="skill?.user_link && skill?.user_link.length > 0">
                                                    <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                                    </span>
                                                    <span *ngIf="skill?.user_document && skill?.user_document.length > 0">
                                                        <img alt="image" src="assets/images/awardsFileIcon.svg">
                                                    </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                        <h3>
                                            Goals & bucket list
                                        </h3>
                                    </div>
                                    <div class="detailContent mb-2" *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                        <div [ngClass]="{ '': goalsBucket, showDetailContent: !goalsBucket}">
                                            <div class="truncatedText">
                                                <ul class="dots-list m-0 truncatedText">
                                                    <li *ngFor="let goal of goalsBucketList | slice:0:6">{{ goal.title }}</li>
                                                </ul>
                                            </div>
                                            <div class="fullText">
                                                <ul class="dots-list m-0">
                                                    <li *ngFor="let goal of goalsBucketList">{{ goal.title }}</li>
                                                </ul>
                                            </div>
                                            <a href="javascript:void();" *ngIf="goalsBucketList && goalsBucketList.length > 6"
                                                (click)="goalsBucket = !goalsBucket" class="d-block text-center showMore">
                                                <img src="assets/images/new-arrow.svg" alt="icon">
                                            </a>
                                        </div>
                                    </div>
                        </div>

                        <div class="content-profile-white-box" *ngIf="user.user_role == '2' && interests?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="user.user_role == '2' && interests?.length > 0">
                                        <h3 class="with-list-icon">
                                            Interests
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="user.user_role == '2' && interests?.length > 0">
                                        <ul class="no-list awardsList withSkills" *ngIf="interests?.length > 0">
                                            <li *ngFor="let interest of interests">
                                                <div class="awardsDataLeft" (click)="updateInterestModal(editInterestpopup, interest)">
                                                    <p class="name m-0 mr-2 cursor-pointer">{{ interest.title }}</p>
                                                    <div class="forDotsImages">
                                                        <span *ngIf="interest.user_link && interest?.user_link.length > 0">
                                                            <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                                            </span>
                                                            <span *ngIf="interest?.user_document && interest?.user_document.length > 0">
                                                                <img alt="image" src="assets/images/awardsFileIcon.svg">
                                                            </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="bulletins?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="bulletins?.length > 0">
                                        <h3>
                                            Bulletin board
                                        </h3>
                                    </div>
                
                                    <div class="detailContent pl-3 pr-3" *ngIf="bulletins?.length > 0">
                                        <div class="w-100">
                                            <ngx-slick-carousel class="box-div library-div carousel bulletinBoardSlides" #slickModal="slick-carousel"
                                                [config]="slideConfig">
                                                <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                                                    (click)="updatebulletinModal(bulletinDetail, bulletin)">
                                                    <div *ngIf="bulletin.document" class="img-div">
                                                        <img *ngIf="bulletin.thumbnail" src="{{ bulletin.thumbnail }}">
                                                        <img *ngIf="!bulletin.thumbnail && isImage(bulletin.document_name)"
                                                            src="{{bulletin.document}}">
                                                        <img *ngIf="!isImage(bulletin.document_name) && !bulletin.thumbnail"
                                                            src="assets/images/pdf-icon.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document && bulletin.thumbnail" class="img-div">
                                                        <img src="{{ bulletin.thumbnail }}">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document && !bulletin.thumbnail" class="img-div">
                                                        <img src="assets/images/default.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="books?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="books?.length > 0">
                                        <h3 class="with-list-icon">
                                            books
                                        </h3>
                                    </div>
                
                                    <div class="detailContent" *ngIf="books?.length > 0">
                                        <div class="topRecent" *ngIf="books?.length > 0">
                                            <div class="media" (click)="updatebookModal(booktemplate, books[0])">
                                                <img class="mainTopImg bookTopImage"
                                                    src="{{books[0]?.thumbnail ? books[0].thumbnail : 'assets/images/default.svg'}}"
                                                    alt="Book Cover">
                                                <div class="media-body ml-3">
                                                    <h4>
                                                        {{ books[0]?.title }}
                                                    </h4>
                                                    <p>{{ books[0].author }}</p>
                                                    <div>
                                                        <ul *ngIf="books[0].rating"
                                                            class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                            <ngb-rating [(rate)]="books[0].rating" [readonly]="true" [max]="5">
                                                                <ng-template let-fill="fill" let-index="index">
                                                                    <span class="star" [class.filled]="fill === 100"
                                                                        [class.bad]="index < 3"></span>
                                                                </ng-template>
                                                            </ngb-rating>
                                                        </ul>
                                                    </div>
                                                    <p class="text-ellipse">
                                                        {{ books[0].description }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ngx-slick-carousel *ngIf="books?.length > 1" class="sliderContent carousel mt-4"
                                            #slickModal="slick-carousel" [config]="slideBooks">
                                            <div *ngFor="let book of books | slice:1" class=" slide" ngxSlickItem>
                                                <div class="media" (click)="updatebookModal(booktemplate, book)">
                                                    <img src="{{book.thumbnail ? book.thumbnail : 'assets/images/default.svg'}}"
                                                        alt="Book Cover" class="booksThumb">
                                                    <div class="media-body ml-3">
                                                        <h4>
                                                            {{ book.title }}
                                                        </h4>
                                                        <p>{{ book.author }}</p>
                                                        <div>
                                                            <ul *ngIf="book.rating"
                                                                class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                                <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                                                    <ng-template let-fill="fill" let-index="index">
                                                                        <span class="star" [class.filled]="fill === 100"
                                                                            [class.bad]="index < 3"></span>
                                                                    </ng-template>
                                                                </ngb-rating>
                                                            </ul>
                                                        </div>
                                                        <p class="text-ellipse">{{ book.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
            
                                        <div class="viewMoreWrapper" *ngIf="books?.length > 4">
                                            <span class="viewMore" (click)="booksListing(booksListModal)">
                                                View All ({{books?.length}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="favouriteList && favouriteList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="favouriteList && favouriteList.length > 0">
                                        <h3>
                                            favourites
                                        </h3>
                                    </div>
                
                                    <div class="detailContent" *ngIf="favouriteList && favouriteList.length > 0">
                                        <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                            [config]="favouritesSlider">
                                            <div class="fav-slider slide" ngxSlickItem *ngFor="let fav of favouriteList">
                                                <div class="fav-wrap">
                                                    <div class="img-outer">
                                                        <img src="{{fav.thumbnail ? fav.thumbnail : 'assets/images/default.svg'}}"
                                                            class="icon mainTopImg mx-auto">
                                                    </div>
                                                    <div class="dtl">
                                                            <div class="custom-tooltip copy-tooltip new">
                                                                    <h3>{{ fav.title }}</h3>
                                                                    <div class="tooltip-data text-capitalize">{{ fav.title }}</div>
                                                            </div>
                                                        <p>{{ fav.topic }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="learnMoreList && learnMoreList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="learnMoreList && learnMoreList.length > 0">
                                        <h3>
                                            i want to learn about
                                        </h3>
                                    </div>
                
                                    <div class="detailContent mb-2" *ngIf="learnMoreList && learnMoreList.length > 0">
                                        <div [ngClass]="{ '': learnAbout, showDetailContent: !learnAbout}">
                                            <div class="truncatedText">
                                                <ul class="dots-list m-0">
                                                    <li *ngFor="let learning of learnMoreList | slice:0:6">
                                                        {{ learning.title }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="fullText">
                                                <ul class="dots-list m-0">
                                                    <li *ngFor="let learning of learnMoreList">
                                                        {{ learning.title }}
                                                    </li>
                                                </ul>
                                            </div>
                
                                            <a href="javascript:void();" *ngIf="learnMoreList && learnMoreList.length > 6"
                                                (click)="learnAbout = !learnAbout" class="d-block text-center showMore">
                                                <img src="assets/images/new-arrow.svg" alt="icon">
                                            </a>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="tabActive == 2">
            <app-public-experience></app-public-experience>
        </div>
        
    </div>
</section>

<ng-template #verification>
    <div class="modal-header">
        <h4 class="modal-title pull-left">profile verifiers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="verifiers.length >= 3">
                <div class="verify-div" *ngFor="let verifier of verifiers">
                    <p>Verified by
                        <span>********@{{verifier.email.split('@')[1]}}</span> on
                        <span>{{verifier.verified_date | date: 'yyyy-MM-dd'}}</span>
                    </p>
                </div>
            </div>
            <div class="col-md-12" *ngIf="verifiers.length < 3 && user.approval_status">
                <div>
                    <p>Verified by the ConnectUs.today Team
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <p [innerHtml]="profile.description | slice:0:1000" class="bio-text-para"></p>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editSkillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <p class="pl-3">{{ editSkill.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="editSkill.past_description">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this skill? What introduced you to it?</label>
                        <p class="pl-3">{{ editSkill.past_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editSkill.present_description">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this skill?</label>
                        <p class="pl-3">{{ editSkill.present_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editSkill.future_description">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this skill? How do you intend to use and build this in
                            future? </label>
                        <p class="pl-3">{{ editSkill.future_description }}</p>
                    </div>
                    <ul class="view-skill-list">
                        <li *ngFor="let skillLink of skillLinkArray">
                            <a href="{{skillLink.link}}" target="_blank">
                                <img alt="image"
                                    src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}">
                                <h5>{{ skillLink.title }}</h5>
                            </a>
                        </li>
                    </ul>
                    <div class="form-group" *ngIf="skillDocArray.length > 0">
                        <h3>
                            Images/Files
                        </h3>
                        <ul class="view-files-list">
                            <li *ngFor="let skillDoc of skillDocArray">
                                <img alt="image" *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}">
                                <img alt="image" *ngIf="!isImage(skillDoc.document_name)"
                                    src="assets/images/pdf-icon.svg">
                                <h5>{{ skillDoc.title }}</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #currentTemp>
    <span>-</span>
</ng-template>

<ng-template #favtemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Favourites</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">
            <img class="fav-image" src="assets/images/default-ui.png" alt="Skill Cover">
            <div class="media-body ml-3">
                <h4>Psychology</h4>
                <p class="m-0">Subject</p>
                <p class="m-0">I believe in the importance of learning through experiences...I believe in the importance
                    of learning through experiences...I believe in the importance of learning through experiences.</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #booktemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">
            <img class="fav-image for-preview-image" *ngIf="editBook.thumbnail" src="{{ editBook.thumbnail }}"
                alt="Book Cover">
            <img class="fav-image for-preview-image" *ngIf="!editBook.thumbnail" src="assets/images/default.svg"
                alt="Book Cover">
            <div class="media-body ml-3">
                <h4>{{ editBook.title }}</h4>
                <p class="m-0">{{ editBook.author }}</p>
                <div>
                    <ul *ngIf="editBook.rating" class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                        <ngb-rating [(rate)]="editBook.rating" [readonly]="true" [max]="5">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </ul>

                </div>
                <p class="m-0">{{ editBook.description }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">bulletin board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">

            <div *ngIf="editBulletin.document" class="img-div">
                <a href="{{editBulletin.document}}" target="_blank">
                    <img *ngIf="isImage(editBulletin.document_name)" class="fav-image" src="{{editBulletin.document}}">
                    <img *ngIf="!isImage(editBulletin.document_name)" class="fav-image"
                        src="assets/images/pdf-icon.svg">
                </a>
            </div>
            <div *ngIf="!editBulletin.document" class="img-div">
                <img class="fav-image" src="assets/images/default.svg">
            </div>

            <div class="media-body ml-3">
                <h4>{{ editBulletin.title }}</h4>
                <p class="m-0" *ngIf="editBulletin.link"><a href="{{ editBulletin.link }}">{{ editBulletin.link }}</a>
                </p>
                <p class="m-0" *ngIf="editBulletin.description">{{ editBulletin.description }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #editInterestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interest</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <p class="pl-3">{{ editInterest.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="editInterest.past_description">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this Interest? What introduced you to it?</label>
                        <p class="pl-3">{{ editInterest.past_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editInterest.present_description">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this Interest?</label>
                        <p class="pl-3">{{ editInterest.present_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editInterest.future_description">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this Interest? How do you intend to use and build this in
                            future? </label>
                        <p class="pl-3">{{ editInterest.future_description }}</p>
                    </div>
                    <ul class="view-skill-list">
                        <li *ngFor="let skillLink of skillLinkArray">
                            <a href="{{skillLink.link}}" target="_blank">
                                <img alt="image"
                                    src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}">
                                <h5>{{ skillLink.title }}</h5>
                            </a>
                        </li>
                    </ul>
                    <div class="form-group" *ngIf="skillDocArray.length > 0">
                        <h3>
                            Images/Files
                        </h3>
                        <ul class="view-files-list">
                            <li *ngFor="let skillDoc of skillDocArray">
                                <img alt="image" *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}">
                                <img alt="image" *ngIf="!isImage(skillDoc.document_name)"
                                    src="assets/images/pdf-icon.svg">
                                <h5>{{ skillDoc.title }}</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #booksListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">books Listing</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-listing-wrap">
            <div class="media border-row" *ngFor="let book of books"
                (click)="updatebookModal(booktemplate, book)">
                <img src="{{book.thumbnail ? book.thumbnail :'assets/images/default.svg'}}" alt="Books Cover"
                    class="booksCover mainTopImg">
                <div class="media-body ml-3">
                    <h4>{{ book.title }}</h4>
                    <p>{{ book.author }}</p>
                    <div>
                        <ul *ngIf="book.rating" class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                            <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                <ng-template let-fill="fill" let-index="index">
                                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                                </ng-template>
                            </ngb-rating>
                        </ul>
                    </div>
                    <p class="text-ellipse">{{ book.description }}</p>
                </div>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #contactListingtemplate>
    <div class="modal-body new-body">
        <div class="timeLineBlock">
            <div class="create-box">
                <h4 class="modal-title pull-left">Contact Info</h4>
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img alt="image" src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <ul class="contact-list">
                    <li *ngFor="let link of links">
                        <a href="javascript:void(0);" class="email" (click)="copyEmail()" *ngIf="link.social_type == 6 && link.link">
                            <img src="/assets/images/email-teal-icon.svg" alt="Email" />
                            {{link.link}}
                            <div class="custom-tooltip">
                                <div class="tooltip-data" *ngIf="copyEmailBool">Email ID Copied</div>
                            </div>
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 2 && link.link">
                            <img src="/assets/images/tiktok-teal-icon.svg" alt="Tiktok" />
                            Tiktok
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 1 && link.link">
                            <img src="/assets/images/instagram-teal-icon.svg" alt="Instagram" />
                            Instagram
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 3 && link.link">
                            <img src="/assets/images/facebook-teal-icon.svg" alt="Facebook" />
                            Facebook
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 5 && link.link">
                            <img src="/assets/images/twitter-teal-icon.svg" alt="Twitter" />
                            Twitter
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 4 && link.link">
                            <img src="/assets/images/youTubeIcon-teal.svg" alt="Twitter" />
                            Youtube
                        </a>
                    </li>
                    
                </ul>
            </div>
            <div class="timeFrameBtns btn-box">
                <div class="rightBtns">
                    <button class="btn btn-colored " (click)="modalRef.hide()">Done</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
