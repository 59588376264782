import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-experience-status',
  templateUrl: './experience-status.component.html',
  styleUrls: ['./experience-status.component.scss']
})
export class ExperienceStatusComponent implements OnInit {
  verificationsObj: any;
  pendingLoadMoreBool = false;
  approvedLoadMoreBool = false;
  declinedLoadMoreBool = false;
  pendingExpSliced = [];
  approvedExpSliced = [];
  declinedExpSliced = [];
  sliceCount = 6;
  showSuccessMsg: boolean = false;
  showErrorMsg: boolean = false;
  errorMsg: string;

  constructor(private profileService: ProfileService, private router: Router) {
   }

  ngOnInit(): void {
    this.profileService.getVerifications()
      .subscribe(response => {
        this.verificationsObj = response.response;
        if (this.verificationsObj.pending.length > this.sliceCount){
          this.pendingExpSliced = this.verificationsObj.pending.slice(0, this.sliceCount);
          this.pendingLoadMoreBool = true;
        }
        else {
          this.pendingExpSliced = this.verificationsObj.pending;
        }
        if (this.verificationsObj.approved.length > this.sliceCount){
          this.approvedExpSliced = this.verificationsObj.approved.slice(0, this.sliceCount);
          this.approvedLoadMoreBool = true;
        }
        else {
          this.approvedExpSliced = this.verificationsObj.approved;
        }
        if (this.verificationsObj.declined.length > this.sliceCount){
          this.declinedExpSliced = this.verificationsObj.declined.slice(0, this.sliceCount);
          this.declinedLoadMoreBool = true;
        }
        else {
          this.declinedExpSliced = this.verificationsObj.declined;
        }
      })
  }

  pendingLoadMore(){
    let totalPendingExp = this.verificationsObj.pending.length;
    let slicedPendingExp = this.pendingExpSliced.length;
    if (slicedPendingExp < totalPendingExp){
      this.pendingExpSliced = this.verificationsObj.pending.slice(0, slicedPendingExp+this.sliceCount)
    }
    if (totalPendingExp > this.pendingExpSliced.length){
      this.pendingLoadMoreBool = true;
    } else {
      this.pendingLoadMoreBool = false;
    }
  }

  approvedLoadMore(){
    let totalApprovedExp = this.verificationsObj.approved.length;
    let slicedApprovedExp = this.approvedExpSliced.length;
    if (slicedApprovedExp < totalApprovedExp){
      this.approvedExpSliced = this.verificationsObj.approved.slice(0, slicedApprovedExp+this.sliceCount)
    }
    if (totalApprovedExp < this.approvedExpSliced.length){
      this.approvedLoadMoreBool = true;
    } else {
      this.approvedLoadMoreBool = false;
    }
  }

  declinedLoadMore(){
    let totalDeclinedExp = this.verificationsObj.declined.length;
    let slicedDeclinedExp = this.declinedExpSliced.length;
    if (slicedDeclinedExp < totalDeclinedExp){
      this.declinedExpSliced = this.verificationsObj.declined.slice(0, slicedDeclinedExp+this.sliceCount)
    }
    if (totalDeclinedExp < this.declinedExpSliced.length){
      this.declinedLoadMoreBool = true;
    } else {
      this.declinedLoadMoreBool = false;
    }
  }

  refreshPendingVerification(id: any){
    let index = this.verificationsObj.pending.findIndex(obj => obj.id === id);
    this.verificationsObj.pending.splice(index, 1);
    if (this.verificationsObj.pending.length > this.sliceCount){
      this.pendingExpSliced = this.verificationsObj.pending.slice(0, this.sliceCount);
      this.pendingLoadMoreBool = true;
    }
    else {
      this.pendingExpSliced = this.verificationsObj.pending;
    }
  }

  alreadyResponded(){
    this.showErrorMsg = true;
    this.errorMsg = "The requested user has already responded to your request. Please refresh";
    this.fadeOutErrorMsg();
  }

  deleteExperience(exp: any){
    let exp_type = exp.exp_type;
    let id = exp.id;
    if (exp_type === "work"){
      this.profileService.deleteWorkVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.refreshPendingVerification(id);
          }
        })
    } else if (exp_type === "activity") {
      this.profileService.deleteActivityVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.refreshPendingVerification(id);
          }
        })
    } else if (exp_type === "school"){
      this.profileService.deleteClubTeamVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.refreshPendingVerification(id);
          }
        })
    }
  }

  resendExpVerification(exp: any){
    this.showSuccessMsg = false;
    this.showErrorMsg = false;
    let exp_type = exp.exp_type;
    let id = exp.id;
    if (exp_type === "work"){
      this.profileService.resendWorkVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.showSuccessMsg = true;
            this.fadeOutMsg();
          }
        }, error => {
          this.showErrorMsg = true;
          this.errorMsg = "Something went wrong. Please try again later.";
          this.fadeOutErrorMsg();
        })
    } else if (exp_type === "activity") {
      this.profileService.resendActivityVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.showSuccessMsg = true;
            this.fadeOutMsg();
          }
        }, error => {
          this.showErrorMsg = true;
          this.errorMsg = "Something went wrong. Please try again later.";
          this.fadeOutErrorMsg();
        })
    } else if (exp_type === "school"){
      this.profileService.resendClubTeamVerification(id)
        .subscribe((response: any) => {
          if (response && response.status === 111){
            this.alreadyResponded();
          } else {
            this.showSuccessMsg = true;
            this.fadeOutMsg();
          }
        }, error => {
          this.showErrorMsg = true;
          this.errorMsg = "Something went wrong. Please try again later.";
          this.fadeOutErrorMsg();
        })
    }
  }

  fadeOutMsg() {
    setTimeout( () => {
      this.showSuccessMsg = false;
    }, 2000);
   }

   fadeOutErrorMsg() {
    setTimeout( () => {
      this.showErrorMsg = false;
    }, 2000);
   }

   redirectPage(experience: any){
    if (!experience.more_info.is_deleted) {
      if (experience.exp_type  === "work"){
        if (experience.page_id) {
          let url = '/page/' + experience.page_id;
          if (experience.more_info.is_related || experience.more_info.is_unclaimed) {
            url = '/page/' + experience.page_id + '/profile-business';
          }
          this.router.navigate([url]);
        } else if (experience.school) {
          let url = '/school/' + experience.school;
          this.router.navigate([url]);
        }
      } else if (experience.exp_type  === "activity"){
        if (experience.page_id) {
          let url = '/page/' + experience.page_id;
          if (experience.more_info.is_related || experience.more_info.is_unclaimed) {
            url = '/page/' + experience.page_id + '/profile-business';
          }
          this.router.navigate([url]);
        }
      } else {
        if (experience.page_id) {
          let url = '/page/' + experience.page_id;
          if (experience.more_info.is_related || experience.more_info.is_unclaimed) {
            url = '/page/' + experience.page_id + '/profile-school';
          }
          this.router.navigate([url]);
        }
      }
    }
  }

}
