import { Component, OnInit, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ProfileService } from '../services/profile.service';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, AbstractControl  } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { PageService } from '../services/page.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { InitiativeService } from '../services/initiative.service';
import { EventService } from '../services/event.service';
import { TeamService } from '../services/team.service';

@Component({
  selector: 'app-profile-school',
  templateUrl: './profile-school.component.html',
  styleUrls: ['./profile-school.component.scss']
})
export class ProfileSchoolComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("searchInp") searchInp: ElementRef;
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  public Editor = ClassicEditor;
  tabActive = 1;
  user: any = {};
  profile: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  public isUnclaimed: boolean = false;
  uploadForm: FormGroup;
  descriptionForm: FormGroup;
  fileUploadError = false;
  public infoDiv1 = true;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  maxNum: number = new Date().getFullYear();
  page_id: any;
  is_Page = true;
  pagesDetails: any= {};
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage = "";
  coverImageUploadSizeMessage = "";
  linkArray: any = [];
  linkForm: FormGroup;
  previewThumbnailName: any;
  previewThumbnail: any;
  aboutUs: any;
  teamMembers: any = [];
  messageError: any;
  managementForm: FormGroup;
  managementList: any = [];
  totalManagements: any = 0;
  nextManagement: any;
  weekEvents: any = [];
  searchQuery: string = "";
  invitationForm: FormGroup;
  invitationSuccess: boolean = false;
  copyMessageBool: boolean = false;
  teamMemberNextPage: any = 1;
  totalTeamMembers: any = 0;
  selectedUser: any;

  constructor(private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private teamService: TeamService, 
    private customValidator: CustomvalidationService, private profileService: ProfileService, private authService: AuthenticationService,
    private route: ActivatedRoute, private pageService: PageService, private initiativeService: InitiativeService, private eventService: EventService) {

      this.uploadForm = this.formBuilder.group({
        document: [''],
        document_name: [''],
        thumbnail: [''],
        thumbnail_name: ['']
      });
      this.descriptionForm = this.formBuilder.group({
        about_us: new FormControl('', [Validators.required, Validators.maxLength(1000)])
      });
      this.linkForm = this.formBuilder.group({
        title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        link: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
      });
      this.managementForm = this.formBuilder.group({
        firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      });
      this.invitationForm = this.formBuilder.group({
        invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
      });
    }

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    if (this.route.snapshot.queryParams['tab'] === 'setting') {
      this.tabActive = 5;
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
          if (this.pagesDetails.owner){
            this.isUnclaimed = false;
          } else {
            this.isUnclaimed = true;
          }
          if ([1, 2].indexOf(this.pagesDetails?.page_type) > -1) {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    this.pageService.getPageDescription(this.page_id)
    .subscribe(response => {
      if(response.response['id'] !== undefined){
        this.profile = response.response;
        this.aboutUs = response.response.about_us;
      }
    });
    this.getLink();
    // this.getWeeklyEvents();
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive":[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  teamsConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": true,
    "arrow": true,
    "autoplay": false,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  changeTab(event: any){
    if (event && event.tabActive) {
      this.tabActive = event.tabActive;
    }
  }

  // getWeeklyEvents(){
  //   this.eventService.getWeekEvent(this.page_id)
  //   .subscribe(response => {
  //     this.weekEvents = response.response;
  //   });
  // }

  leadersModal(leaderTemplate: TemplateRef<any>) {
    this.submitted = false;
    this.managementForm.reset();
    this.modalRef = this.modalService.show(leaderTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  getTeamItems(){
    this.resetSearch();
    if (this.totalManagements == 0) {
      this.getManagement();
    }
  }

  getMembers(){
    let params = '?';
    if (this.teamMemberNextPage > 1){
      params = params + '&page=' + this.teamMemberNextPage;
    }
    if (this.searchQuery && this.searchQuery.length > 2) {
      params = params + '&search=' + this.searchQuery;
    }
    this.profileService.UserSchoolInvolvementMembers(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.teamMemberNextPage == 1 || this.teamMemberNextPage === null) {
          this.teamMembers = response.response.results;
        } else {
          this.teamMembers = this.teamMembers.concat(response.response.results);
        }
        this.teamMemberNextPage = response.response.next;
        this.totalTeamMembers = response.response.total_count;
      } else {
        this.teamMembers = [];
        this.teamMemberNextPage = 1;
        this.totalTeamMembers = 0;
      }
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  coverImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 3000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 3MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnail = reader.result;
          this.previewThumbnailName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
        };
      }
    }
  }

  onFileSelect(event, doc_type: any) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg","jpeg","png", "JPG","JPEG","PNG", "pdf","doc","docx"];
      if (doc_type == "image"){
        allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
      }
      if(!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        if (doc_type == "image"){
          this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
        } else {
          this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
        }
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if(fileSize > 30000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image/Document size should be less than 30MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if(!this.fileUploadError && !this.fileUploadSizeError){
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
        };
      }
    }
  }

  linkModal(linkTemplate: TemplateRef<any>) {
    this.linkForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(linkTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  saveDescription(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      let model = {'about_us': this.descriptionForm.get('about_us').value,
                  'page': this.page_id}
      if(this.profile && this.profile.id) {
        this.pageService.updatePageDescription(model, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
            this.handleDescSubmitError(error.error);
        })
      } else {
        this.pageService.addPageDescription(model)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
            this.handleDescSubmitError(error.error);
        })
      }
    }
  }

  deleteDescription(){
    if(this.profile && this.profile.id) {
      let model = {'about_us': "",
                  'page': this.page_id}
        this.pageService.updatePageDescription(model, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
            this.handleDescSubmitError(error.error);
        })
    }
  }

  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.profile = response.response;
        this.aboutUs = response.response.about_us;
        this.descriptionForm.reset();
        this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  openModal(member: any, template: TemplateRef<any>) {
    this.selectedUser = member;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addProfileTeam(addProfileTeamModal: TemplateRef<any>) {
    this.updateModalRef = this.modalService.show(addProfileTeamModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getLink(){
    this.pageService.getLinks(this.page_id)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  delLink(id:any){
    var id = id;
    this.initiativeService.deleteLink(id)
    .subscribe(response => {
      let index = this.linkArray.findIndex(obj => obj.id === id)
      this.linkArray.splice(index, 1)
    })
  }

  blankLinkImageUpload(element){
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  addLink(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    let link = this.linkForm.get('link').value;
    if (link) {
      link = this.updateLink(link);
    }
    if (isValid){
      let model = {
        'page': this.page_id,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addLink(model)
        .subscribe(response => {
          this.handleAddLinkResponse(response);
        }, error =>{
          this.handleAddLinkSubmitError(error.error)
        })
    }
  }

  protected handleAddLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleAddLinkSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.linkArray.push(response.response);
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleAddLinkSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.linkForm.contains(field)) {
        this.linkForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addManagement(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'page': this.page_id,
        'first_name': this.managementForm.get('firstName').value,
        'last_name': this.managementForm.get('lastName').value,
        'position': this.managementForm.get('position').value
      }
      this.pageService.addManagementVolunteer(model)
        .subscribe(response => {
          this.handleAddManagementResponse(response);
        }, error =>{
          this.handleAddManagementSubmitError(error.error)
        })
    }
  }

  protected handleAddManagementResponse(response: any) {
    if (response.status === 111) {
      this.handleAddManagementSubmitError(response.message);
    } else if (response.status === 200) {
      this.managementList.push(response.response);
      this.modalRef.hide();
      this.managementForm.reset();
      this.submitted = false;
    }
  }

  protected handleAddManagementSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.managementForm.contains(field)) {
        this.managementForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  getManagement(){
    let param = '';
    if (this.nextManagement && this.nextManagement != null){
      param = '?page=' + this.nextManagement;
    }
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = response.response.results;
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }

  deleteVol(id: any){
    this.pageService.deleteManagement(id)
    .subscribe(response => {
      let index = this.managementList.findIndex(obj => obj.id === id)
      this.managementList.splice(index, 1);
      this.totalManagements = this.totalManagements - 1;
    })
  }

  laodMoreManagements(){
    let  param = '?page=' + this.nextManagement;
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = this.managementList.concat(response.response.results);
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }

  aboutUsModal(missionTemplate: TemplateRef<any>) {
    this.submitted = false;
    this.descriptionForm.reset();
    this.descriptionForm.controls['about_us'].setValue(this.aboutUs);
    this.modalRef = this.modalService.show(missionTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.modalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal new-modal-ui' })
    );
  }

  searchStudent(event: any){
    let name = event.target.value;
    if (name.length === 0) {
      this.resetSearch();
    } else if (name.length > 2) {
      this.searchQuery = name;
      this.teamMemberNextPage = 1;
      this.getMembers();
    } else {
      this.searchQuery = null;
    }
  }

  resetSearch(){
    if (this.searchInp){
      this.searchInp.nativeElement.value = "";
    }
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getMembers();
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'page': this.page_id
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyMessage(){
    let msg = "Join my network at " + this.pagesDetails.page_info.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.setToCopy(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  // isEvent(event: any){
  //   if (event) {
  //     this.getWeeklyEvents();
  //   }
  // }
  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };

  directToProfile(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      this.router.navigate(['profile/' + member.student_info.id + '/']);
    }
  }

  checkClick(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      return true;
    }
    return false;
  }

}
 