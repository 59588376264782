<div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3">
    <h2>Your QR Code and Link</h2>
    <p>This is your digital resume's QR code and link. You can add these to any of your PDF resumes for employers to see your verified ConnectUs resume with references.</p>
    <h3>QR Code</h3>
    <div class="qr-box">
        <div class="img-box">
            <qrcode #qrCodeSrc [qrdata]="qrCodeData" [width]="90" [errorCorrectionLevel]="'M'" [allowEmptyString]="true">
            </qrcode>
        </div>
        <button type="button" (click)="saveAsImage(qrCodeSrc)" class="download-btn">Download</button>
    </div>
    <div class="input-box">
        <input type="text" [value]="qrCodeData" class="form-control" #linkInput readonly/>
        <div class="custom-tooltip copy-tooltip">
            <button type="button" class="download-btn" (click)="copyLink(linkInput)">Copy</button>
            <div class="tooltip-data" *ngIf="copyMessageBool">Link Copied</div>
        </div>
    </div>
</div>

<section class="resumePage">
    <div class="resumeWhiteWrap">
        <div class="contactInfoResume">
            <div class="forImage">
                <h2 class="name">{{user.first_name}} {{user.last_name}}</h2>
                <div class="phoneEmail">
                    <a href="javascript:void()"><strong>C:</strong>
                        <span> ({{resumeSummary.phone_number | slice:0:3}}) {{resumeSummary.phone_number | slice:3:6}}-{{resumeSummary.phone_number | slice:6}}</span>
                    </a>
                    <a href="javascript:void()"><strong>E:</strong> {{resumeSummary.email}}</a>
                </div>
                <div class="editContact">
                    <button class="btn buttonWithEdit" (click)="contactInfoList(contactInfoModal)">
                        <span class="for-edit-icon"><img src="assets/images/pdfEdit.png" alt="icon"></span>
                        <span class="for-text">contact information</span>
                    </button>
                </div>
                <div class="summary">
                    <p class="des">{{resumeSummary.summary_statement}}</p>
                    <button class="btn buttonWithEdit" (click)="summaryList(summaryModal)">
                        <span class="for-edit-icon"><img src="assets/images/pdfEdit.png" alt="icon"></span>
                        <span class="for-text">summary statement</span>
                    </button>
                </div>
                <div class="forPDFDownload">
                    <a href="javascript:void()" class="downloadBtn" (click)="downloadPDF()">
                        <img class="downloadIcon" src="assets/images/downloadPDF.png" alt="icon">
                    </a>
                </div>
            </div>
        </div>

        <div class="fullResumeOuter">
            <div class="leftWrap">
                <div class="eachBlock">
                    <div class="hdg">
                        <button class="btn buttonWithEdit">
                            <span class="for-text">education</span>
                        </button>
                    </div>
                    <div class="educationData" *ngIf="eduList.length > 0">
                        <ul class="no-list">
                            <li *ngFor="let eduObj of eduList">
                                <h3 class="name">{{eduObj.school.school_name}}</h3>
                                <h4 class="major" *ngIf="eduObj.school.is_college && eduObj.majors">{{eduObj.majors.join(', ')}}</h4>
                                <p class="years">
                                    <span>{{eduObj.start_date | date:'yyyy' }}-{{eduObj.end_date | date:'yyyy' }}</span>
                                </p>
                                <div class="aboutPhotoCheck">
                                    <div class="custom-radio-blue">
                                        <input type="checkbox" (change)="updateEdu(eduObj)"
                                            [checked]="resumeSummary.selected_education.indexOf(eduObj.id) > -1">
                                        <label for="Include" class="m-0">Include</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="eachBlock" *ngIf="user.age_group > 1">
                    <div class="hdg">
                        <button class="btn buttonWithEdit withIcon" (click)="expList(expModal)">
                            <span class="for-edit-icon"><img src="assets/images/pdfEdit.png" alt="icon"></span>
                            <span class="for-text">experiences</span>
                        </button>
                    </div>

                    <div class="expeianceList">
                        <ul class="no-list single-image-wrap">
                            <li class="border-row" *ngFor="let resumeExperience of resumeExperiences" >
                                <div class="media-body">
                                    <h3 class="top-name">{{resumeExperience.position}}</h3>
                                    <h4>{{resumeExperience.title}}</h4>
                                    <h5 class="start-end" *ngIf="resumeExperience.start_date != resumeExperience.end_date"><strong>Start</strong><span
                                            class="ml-1 mr-3">{{resumeExperience.start_date|date:"MMMM y"}}</span><strong
                                            class="mr-1">End</strong><span *ngIf="resumeExperience.end_date">{{resumeExperience.end_date|date:"MMMM y"}}</span><span *ngIf="!resumeExperience.end_date">Present</span>
                                    </h5>
                                    <h5 class="start-end" *ngIf="resumeExperience.start_date == resumeExperience.end_date"><strong>Date</strong><span
                                        class="ml-1 mr-3">{{resumeExperience.start_date|date:"MMMM y"}}</span>
                                    </h5>
                                    <p class="m-0">
                                        <strong>
                                            <span *ngIf="resumeExperience.skill_1">{{resumeExperience.skill_1}}</span>
                                            <span *ngIf="resumeExperience.skill_2 && resumeExperience.skill_1">, </span>
                                            <span *ngIf="resumeExperience.skill_2">{{resumeExperience.skill_2}}</span>
                                            <span *ngIf="resumeExperience.skill_3 && (resumeExperience.skill_2 || resumeExperience.skill_1)">, </span>
                                            <span *ngIf="resumeExperience.skill_3">{{resumeExperience.skill_3}}</span>
                                        </strong>
                                    </p>
                                    <p class="m-0 text-ellipse">{{resumeExperience.main_role}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

                <div class="eachBlock">
                    <div class="hdg">
                        <button class="btn buttonWithEdit withIcon" (click)="refList(refModal)">
                            <span class="for-edit-icon"><img src="assets/images/pdfEdit.png" alt="icon"></span>
                            <span class="for-text">references</span>
                        </button>
                    </div>

                    <div class="referenceList">
                        <ul class="no-list">
                            <li *ngFor="let resumeReference of resumeReferences">
                                <div class="leftRefWrap">
                                    <h2 class="name">{{resumeReference.first_name}} {{resumeReference.last_name}}</h2>
                                    <h3 class="designation">{{resumeReference.position}}</h3>
                                </div>
                                <div class="rightRefWrap">
                                    <div class="forMail">
                                        <a href="javascript:void()">{{resumeReference.email}}</a>
                                    </div>
                                    <div class="forMail">
                                        <a href="javascript:void()">{{resumeReference.phone_number}}</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

                <div class="fullResume">
                    <h3>view full resume</h3>
                    <div class="fullResumeInner">
                        <div class="forQR">
                            <qrcode [qrdata]="qrCodeData" [width]="88" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
                        </div>
                        <div class="forShare">
                            <a href="{{qrCodeData}}"  target="_blank" class="ml-2">
                                <img style="opacity: 0.3;" class="icon" alt="icon" src="assets/images/resumeShare.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rightWrap">
                <div class="eachBlock">
                    <div class="hdg">
                        <button class="btn buttonWithEdit withIcon" (click)="skillList(skillModal)">
                            <span class="for-edit-icon"><img src="assets/images/pdfEdit.png" alt="icon"></span>
                            <span class="for-text">skills and interests</span>
                        </button>
                    </div>

                    <div class="blockList">
                        <ul class="no-list">
                            <li *ngFor="let resumeSkillinterest of resumeSkillinterests">{{resumeSkillinterest.title}}</li>
                        </ul>
                    </div>
                </div>

                <div class="eachBlock">
                    <div class="hdg">
                        <button class="btn buttonWithEdit">
                            <span class="for-text">Full resume</span>
                        </button>
                    </div>

                    <div class="blockList">
                        <ul class="no-list">
                            <li *ngIf="user.age_group > 1">{{usrWorkExperience.length}} Work</li>
                            <li *ngIf="user.age_group > 1">{{usrVolunteerExperience.length}} Volunteer</li>
                            <li *ngIf="user.age_group > 1">{{activities.length}} Extracurricular</li>
                            <li>{{schoolInvolvements.length}} School Involvement</li>
                            <li>{{skills.length}} Skills</li>
                            <li>{{interests.length}} Interests</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #summaryModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Summary statement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="summaryForm" novalidate>
            <div class="form-group mb-0">
                <textarea class="form-control" placeholder="Summary Statement" formControlName="summary_statement"></textarea>
                <div class="error-message"
                    *ngIf="summaryForm.get('summary_statement').hasError('required') && (summaryForm.get('summary_statement').dirty || summaryForm.get('summary_statement').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="summaryForm.get('summary_statement').hasError('maxlength') && (summaryForm.get('summary_statement').dirty || summaryForm.get('summary_statement').touched)">
                    Max 250 characters allowed.
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="updateSummary(summaryForm.valid)">
            save
        </button>
    </div>
</ng-template>

<ng-template #contactInfoModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">contact information</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="contactForm" novalidate>
            <div class="form-group">
                <input type="text" placeholder="Phone Number" class="form-control" formControlName="phone_number" />
                <div class="error-message"
                    *ngIf="contactForm.get('phone_number').hasError('required') && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="contactForm.get('phone_number').hasError('pattern') && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)">
                    Please enter numbers only.
                </div>
                <div class="error-message"
                    *ngIf="contactForm.get('phone_number').hasError('minlength') && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)">
                    Phone number needs to be atleast 10 digits.
                </div>
                <div class="error-message"
                    *ngIf="contactForm.get('phone_number').hasError('maxlength') && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)">
                    Max 15 characters allowed.
                </div>
            </div>
            <div class="form-group mb-0">
                <input type="text" placeholder="Email" class="form-control" formControlName="email" />
                <div class="error-message"
                    *ngIf="contactForm.get('email').hasError('required') && (contactForm.get('email').dirty || contactForm.get('email').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="contactForm.get('email').hasError('maxlength') && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                    Max 100 characters allowed.
                </div>
                <div class="error-message"
                    *ngIf="contactForm.get('email').hasError('pattern') && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                    Please enter your email in a valid format.
                </div>
            </div> 
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="updateContactDetails(contactForm.valid)">
            save
        </button>
    </div>
</ng-template>

<ng-template #expModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">experiences</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body pb-0">
        <h3 class="resumeHdgExpModal">
            You may select up to <strong>5 experiences</strong> to showcase on your PDF Resume.
        </h3>

        <div class="resumeExpListing scroll-activity scrollbar">
            <div class="eachPart">
                <h3 class="hdg">Work</h3>
                <ul class="no-list">
                    <li *ngFor="let usrWorkExp of usrWorkExperience">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectExperience('work', usrWorkExp.id)" [checked]="usrWorkExp.resume_selected" />
                            <label class="m-0">
                                <span class="forImage">
                                    <img alt="icon" src="{{usrWorkExp.page_display_pic? usrWorkExp.page_display_pic: 'assets/images/default.svg'}}" />
                                </span>
                                <span class="forNames">
                                    <h3 class="topName">{{usrWorkExp.position}}</h3>
                                    <p class="des">{{usrWorkExp.title}}</p>
                                </span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="eachPart">
                <h3 class="hdg">Volunteer</h3>
                <ul class="no-list">
                    <li *ngFor="let usrVolunteerExp of usrVolunteerExperience">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectExperience('work', usrVolunteerExp.id)" [checked]="usrVolunteerExp.resume_selected"  />
                            <label class="m-0">
                                <span class="forImage">
                                    <img alt="icon" src="{{usrVolunteerExp.page_display_pic? usrVolunteerExp.page_display_pic: 'assets/images/default.svg'}}" />
                                </span>
                                <span class="forNames">
                                    <h3 class="topName">{{usrVolunteerExp.position}}</h3>
                                    <p class="des">{{usrVolunteerExp.title}}</p>
                                </span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="eachPart">
                <h3 class="hdg">Extracurricular</h3>
                <ul class="no-list">
                    <li *ngFor="let activity of activities">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectExperience('activity', activity.id)" [checked]="activity.resume_selected" />
                            <label class="m-0">
                                <span class="forImage">
                                    <img alt="icon" src="{{activity.page_display_pic? activity.page_display_pic: 'assets/images/default.svg'}}" />
                                </span>
                                <span class="forNames">
                                    <h3 class="topName">{{activity.position}}</h3>
                                    <p class="des">{{activity.title}}</p>
                                </span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="eachPart">
                <h3 class="hdg">School Involvement</h3>
                <ul class="no-list">
                    <li *ngFor="let schoolInvolvement of schoolInvolvements">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectExperience('school', schoolInvolvement.id)" [checked]="schoolInvolvement.resume_selected" />
                            <label class="m-0">
                                <span class="forImage">
                                    <img alt="icon" src="{{schoolInvolvement.page_display_pic? schoolInvolvement.page_display_pic: 'assets/images/default.svg'}}" />
                                </span>
                                <span class="forNames">
                                    <h3 class="topName">{{schoolInvolvement.position}}</h3>
                                    <p class="des">{{schoolInvolvement.title}}</p>
                                </span>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal-body p-0">
        <div class="error-message text-center" *ngIf="selectedExperiences.length > 5">
            You can select maximum 5 Experiences.
        </div>
        <div class="error-message text-center" *ngIf="submitError">
            Something went wrong. Please try again later.
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addExpResume()">
            save
        </button>
    </div>
</ng-template>

<ng-template #skillModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">skills and interests</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <h3 class="resumeHdgExpModal">
            You may select up to <strong>10 skills or interests</strong> to showcase on your PDF Resume.
        </h3>

        <div class="skillsExpListing">
            <div class="eachPart">
                <h3 class="hdg">Skills</h3>
                <ul class="no-list">
                    <li *ngFor="let skill of skills">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectSkillInt('skill', skill.id)" [checked]="skill.resume_selected" />
                            <label for="1" class="m-0">
                                {{skill.title}}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="eachPart">
                <h3 class="hdg">Interests</h3>
                <ul class="no-list">
                    <li *ngFor="let interest of interests">
                        <div class="custom-radio-blue">
                            <input type="checkbox" (change)="selectSkillInt('interest', interest.id)" [checked]="interest.resume_selected" />
                            <label for="1" class="m-0">
                                {{interest.title}}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-body p-0">
        <div class="error-message text-center" *ngIf="selectedSkillinterests.length > 10">
            You can select maximum 10 Skills and Interests.
        </div>
        <div class="error-message text-center" *ngIf="submitError">
            Something went wrong. Please try again later.
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addSkillIntResume()">
            save
        </button>
    </div>
</ng-template>

<ng-template #refModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">references</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="scroll-activity scrollbar no-overflow-x">
            <h3 class="resumeHdgExpModal">
                You may select (or add) up to <strong>3 references</strong> to showcase on your PDF Resume.
            </h3>

            <div class="skillsExpListing">
                <div class="eachPart partFull">
                    <h3 class="hdg">Add New</h3>
                    <div class="row">
                        <div class="col-md-8">
                            <form [formGroup]="referenceForm" novalidate>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="First Name" class="form-control text-capitalize" formControlName="firstName" />
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('firstName').hasError('required') && (referenceForm.get('firstName').dirty || referenceForm.get('firstName').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('firstName').hasError('maxlength') && (referenceForm.get('firstName').dirty || referenceForm.get('firstName').touched)">
                                                Max 100 characters allowed.
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Last Name" class="form-control text-capitalize" formControlName="lastName" />
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('lastName').hasError('required') && (referenceForm.get('lastName').dirty || referenceForm.get('lastName').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('lastName').hasError('maxlength') && (referenceForm.get('lastName').dirty || referenceForm.get('lastName').touched)">
                                                Max 100 characters allowed.
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="text" placeholder="Position (ex. Manager at McDonald's, Soccer Coach, etc.)" 
                                                class="form-control" formControlName="position" />
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('position').hasError('required') && (referenceForm.get('position').dirty || referenceForm.get('position').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('position').hasError('maxlength') && (referenceForm.get('position').dirty || referenceForm.get('position').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Email" class="form-control" formControlName="email" />
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('email').hasError('required') && (referenceForm.get('email').dirty || referenceForm.get('email').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('email').hasError('maxlength') && (referenceForm.get('email').dirty || referenceForm.get('email').touched)">
                                                Max 100 characters allowed.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('email').hasError('pattern') && (referenceForm.get('email').dirty || referenceForm.get('email').touched)">
                                                Please enter your email in a valid format.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('email').hasError('aftersubmit')">
                                                {{referenceForm.controls.email.errors.aftersubmit}}
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" placeholder="Phone Number" class="form-control" formControlName="phone_number" />
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('phone_number').hasError('required') && (referenceForm.get('phone_number').dirty || referenceForm.get('phone_number').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('phone_number').hasError('pattern') && (referenceForm.get('phone_number').dirty || referenceForm.get('phone_number').touched)">
                                                Please enter numbers only.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('phone_number').hasError('minlength') && (referenceForm.get('phone_number').dirty || referenceForm.get('phone_number').touched)">
                                                Phone number needs to be atleast 10 digits.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('phone_number').hasError('maxlength') && (referenceForm.get('phone_number').dirty || referenceForm.get('phone_number').touched)">
                                                Max 15 characters allowed.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="referenceForm.get('phone_number').hasError('aftersubmit')">
                                                {{referenceForm.controls.phone_number.errors.aftersubmit}}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </form>
                            <div class="mb-3">
                                <button class="btn common-btn" (click)="addReference(referenceForm.valid)">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="skillsExpListing" *ngIf="references.length > 0">
                <div class="eachPart partFull">
                    <h3 class="hdg">Select</h3>
                    <ul class="no-list withDel list2PartsWithPad">
                        <li *ngFor="let reference of references">
                            <div class="custom-radio-blue">
                                <input type="checkbox" (change)="selectRef(reference)" [checked]="reference.resume_selected" />
                                <label class="m-0">
                                {{reference.first_name}} {{reference.last_name}} 
                                </label>
                            </div>
                            <div class="deleteRef">
                                <a href="javascript:void()" class="del-icon" (click)="delResumeRef(reference)">
                                    <img alt="icon" src="assets/images/newDelete.svg">
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body p-0">
        <div class="error-message text-center" *ngIf="selectedReferences.length > 3">
            You can select maximum 3 References.
        </div>
        <div class="error-message text-center" *ngIf="submitError">
            Something went wrong. Please try again later.
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addResumeReference()">
            save
        </button>
    </div>
</ng-template>
