export function setExternalUserId(unique_id, email, canSet) {
    const isApp = navigator.userAgent.toLowerCase().indexOf('canvas') > -1;
    console.log("-----setExternalUserId", unique_id, email, canSet, isApp);
    try {
        if (canSet) {
            nativeFunctions.onesignalSetExternalUserId(unique_id);
            nativeFunctions.onesignalSendTag("email", email);
        } else {
            nativeFunctions.onesignalRemoveExternalUserId(unique_id);
            nativeFunctions.onesignalDeleteTag("email");
        }
    } catch (ex) {
        console.log(ex.message);
    }
}
