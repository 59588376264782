import { Component, OnInit, TemplateRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-awards-credentials',
  templateUrl: './awards-credentials.component.html',
  styleUrls: ['./awards-credentials.component.scss']
})
export class AwardsCredentialsComponent implements OnInit {
  @Output() hasData = new EventEmitter<any>();
  @ViewChild('awardsTemplate') awardsTemplate: TemplateRef<any>;
  modalRef: BsModalRef;
  awardsForm: FormGroup;
  uploadForm: FormGroup;
  user: any = {};
  awards = [];
  editAwards: any = {};
  maxNum: number = new Date().getFullYear();
  public submitted: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = false;
  fileUploadSizeMessage = "";

  error_messages = {
    'year': [
      { type: 'required', message: 'This field is required.' },
      { type: 'min', message: 'Please enter valid Year.' },
      { type: 'max', message: 'Max ' + this.maxNum + ' is allowed.' }
    ]
  }

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private customValidator: CustomvalidationService,
    private profileService: ProfileService) {
    this.awardsForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75), this.noWhitespaceValidator]),
      year: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      skills_gained: new FormControl('', [this.arrayElementLengthValidator])
    });
    this.uploadForm = this.formBuilder.group({
      document: [''],
      document_name: ['']
    });
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.getAwards();
  }

  arrayElementLengthValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let array_str = control.value;
    let isValid = true;
    if (array_str == null || array_str == ''){
      isValid = true;
    } else {
      let arr = array_str.split(',');
      for (var product of arr) {
        if (product.length > 100){
          isValid = false;
          break;
        }
      }
    }
    return !isValid ? { 'arrElemValid': true } : null;
  };

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getAwards(){
    this.profileService.getAwards(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.awards = response.response;
        this.hasData.emit(true);
      }
    });
  }

  addSection(){
    this.openModalAwards(this.awardsTemplate);
  }

  openModalAwards(awardsTemplate: TemplateRef<any>) {
    this.submitted = false;
    this.awardsForm.reset();
    this.uploadForm.reset();
    this.fileUploadError = false;
    this.fileUploadMessage = "";
    this.fileUploadSizeError = false;
    this.fileUploadSizeMessage = "";
    this.modalRef = this.modalService.show(awardsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
      );
  }

  editModalAwards(editAwardsTemplate: TemplateRef<any>, award: any) {
    this.editAwards = award;
    this.submitted = false;
    this.uploadForm.reset();
    this.fileUploadError = false;
    this.fileUploadMessage = "";
    this.fileUploadSizeError = false;
    this.fileUploadSizeMessage = "";
    let year = null;
    let skills_gained = null;
    if (award.year) {
      year = new Date(award.year);
    }
    if (award.skills_gained) {
      skills_gained = award.skills_gained.toString();
    }
    this.awardsForm.controls['title'].setValue(award.title);
    this.awardsForm.controls['year'].setValue(year.getFullYear());
    this.awardsForm.controls['link'].setValue(award.link);
    this.awardsForm.controls['skills_gained'].setValue(skills_gained);
    this.modalRef = this.modalService.show(editAwardsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  onFileSelect(event) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf", "doc", "docx"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true;
        this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 30000) {
        this.fileUploadSizeError = true;
        this.fileUploadSizeMessage = "Document size should be less than 30MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
        };
      }
    }
  }

  addAwards(isValid: boolean){
    this.submitted = true;
    if (isValid && !this.fileUploadError && !this.fileUploadSizeError) {
      let skills_gained = null;
      let year = this.awardsForm.controls['year'].value + "-01-01";
      if (this.awardsForm.controls['skills_gained'].value) {
        skills_gained = this.awardsForm.controls['skills_gained'].value.split(',');
      }
      let link = this.awardsForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'title': this.awardsForm.get('title').value,
        'year': year,
        'link': link,
        'skills_gained': skills_gained,
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.createAwards(model)
        .subscribe(response => {
          this.handleCreateAwards(response);
        }, error => {
          this.handleAwardsError(error.error);
        })
    }
  }

  protected handleCreateAwards(response: any){
    if (response.status === 111) {
      this.handleAwardsError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.awards.push(response.response);
      this.hasData.emit(true);
      this.awards.sort(this.custom_sort);
      this.awardsForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
    }
  }

  custom_sort(a: any, b: any) {
    return new Date(b.year).getTime() - new Date(a.year).getTime();
  }

  protected handleAwardsError(data: any){
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.awardsForm.contains(field)) {
        this.awardsForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  removeAwardDoc(id: any) {
    let model = {
      'document': null,
      'document_name': null,
      'year': this.editAwards['year'],
      'title': this.editAwards['title'],
      'skills_gained': this.editAwards['skills_gained'],
      'link': this.editAwards['link']
    }
    this.profileService.updateAwards(model, this.editAwards.id)
      .subscribe(response => {
        this.editAwards["document"] = null;
        this.editAwards["document_name"] = null;
      })
  }

  delAward() {
    this.profileService.deleteAward(this.editAwards.id)
      .subscribe(response => {
        let index = this.awards.findIndex(obj => obj.id === this.editAwards.id);
        this.awards.splice(index, 1);
        if (this.awards.length == 0) {
          this.hasData.emit(false);
        }
        this.modalRef.hide();
        this.editAwards = {};
        this.awardsForm.reset();
      })
  }

  updateAward(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editAwards.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let skills_gained = null;
      let year = this.awardsForm.controls['year'].value + "-01-01";
      if (this.awardsForm.controls['skills_gained'].value) {
        skills_gained = this.awardsForm.controls['skills_gained'].value.split(',');
      }
      let link = this.awardsForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'title': this.awardsForm.get('title').value,
        'year': year,
        'link': link,
        'skills_gained': skills_gained,
        'document_name': doc_name,
        'document': this.uploadForm.get('document').value
      }
      this.profileService.updateAwards(model, this.editAwards.id)
        .subscribe(response => {
          this.handleAwardsUpdResponse(response);
        }, error => {
          this.handleAwardsError(error.error);
        })
    }
  }

  protected handleAwardsUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleAwardsError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      let index = this.awards.findIndex(obj => obj.id === this.editAwards.id);
      this.awards[index] = response.response;
      this.awardsForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
    }
  }

}
