import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl, FormArray } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ProfileService } from '../../services/profile.service';
import { IEducationExisting, ISchool, IEducationNew } from '../../shared/education';
import { canadaStates, usStates, collegeType, schoolType } from '../../app.data';
import { TeamService } from 'src/app/services/team.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  modalRef: BsModalRef;
  delModalRef: BsModalRef;
  user: any = {};
  disableSubmit: boolean = false;
  educationForm: FormGroup;
  newSchoolForm: FormGroup;
  schoolEdForm: FormGroup;
  collegeEdForm: FormGroup;
  currentEdForm: number = 1;
  nextClicked: boolean = false;
  doneClicked: boolean = false;
  viewDate: Date = new Date();
  showPrev: boolean = false;
  showNext: boolean = true;
  showDone: boolean = false;
  teamError: boolean = false;
  showTeam: boolean = false;
  schools: any[] = [];
  schoolId: string;
  public caStates: any[] = canadaStates;
  public usStates: any[] = usStates;
  public schoolTypes: any[] = schoolType;
  public collegeTypes: any[] = collegeType;
  public showCaStates: boolean = true;
  plchldrState: string = "PR";
  plchldrPostal: string = "Postal";
  insText: string = "School"
  isCollegeSelected: boolean = false;
  hideResults: boolean = true;
  edFormSubmitted: boolean = false;
  eduList: any[] = [];
  eduEditObj: any = null;
  addNewEdu: boolean = true;
  districtValidators: any[] = [Validators.maxLength(50)];
  showDistrict: boolean = true;
  public showAddressError: boolean = false;
  updateAddress: string = '';
  searchPlaces: any[] = [];
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  countrySet: string = 'CA';
  today = new Date();
  schoolMembers: any[] = [];
  schoolMemberSliced: any[] = [];
  slicedItem: any = 6;
  searchQuery: string = "";
  teamMemberNext: any = 1;
  totalTeamMember: any = 0;
  teamMember: any = [];
  teamLoadMoreBool: boolean = false;
  editTeamMember: any;
  invitationForm: FormGroup;
  newModalRef: BsModalRef;
  submitted: boolean;
  invitationSuccess: boolean = false;
  copyMessageBool: boolean = false;
  title: string = null;

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, private authService: AuthenticationService,
    private router: Router, private teamService: TeamService, private customValidator: CustomvalidationService, private datePipe: DatePipe) {

    this.educationForm = this.formBuilder.group({
      is_college: new FormControl('', [Validators.required,]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
    }, { validators: this.startEndValidator });
    this.newSchoolForm = this.formBuilder.group({
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      district: new FormControl('', this.districtValidators),
      school_type: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
    this.schoolEdForm = this.formBuilder.group({
      interests: new FormControl('', [this.arrayElementLengthValidator]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
    }, { validators: this.startEndValidator });
    this.collegeEdForm = this.formBuilder.group({
      college_degree: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      program: new FormControl('', Validators.maxLength(150)),
      minors: new FormControl('', [this.arrayColgElementLengthValidator]),
      majors: new FormControl('', [this.arrayColgElementLengthValidator]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
    }, { validators: this.startEndValidator });
    this.invitationForm = this.formBuilder.group({
      invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
    });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }

  startEndValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    return start_date && end_date && start_date > end_date ? {
       'startEndValid': true } : null;
  };

  arrayElementLengthValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let array_str = control.value;
    let isValid = true;
    if (array_str == null || array_str == ''){
      isValid = true;
    } else {
      let arr = array_str.split(',');
      for (var product of arr) {
        if (product.length > 100){
          isValid = false;
          break;
        }
      }
    }
    return !isValid ? { 'arrElemValid': true } : null;
  };

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  arrayColgElementLengthValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let array_str = control.value;
    let isValid = true;
    if (array_str == null || array_str == ''){
      isValid = true;
    } else {
      let arr = array_str.split(',');
      for (var product of arr) {
        if (product.length > 50){
          isValid = false;
          break;
        }
      }
    }
    return !isValid ? { 'arrElemValid': true } : null;
  };

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.showCaStates = true;
    this.getEducationList();
  }

  sliderEdu = {
    "slidesToShow": 2,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrows": true,
    "dots": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ]
  };

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  eduformInit(){
    this.disableSubmit = false;
    this.educationForm.reset();
    this.newSchoolForm.reset();
    this.collegeEdForm.reset();
    this.schoolEdForm.reset();
    this.currentEdForm = 1;
    this.doneClicked = false;
    this.nextClicked = false;
    this.plchldrState = "PR";
    this.plchldrPostal = "Postal";
    this.insText = "School"
    this.isCollegeSelected = false;
    this.setNextPrevDone(true, false, false);
    this.educationForm.controls['country'].setValue('Canada');
    this.educationForm.controls['is_college'].setValue("false");
    this.districtValidators = [Validators.maxLength(50)];
    this.showDistrict = true;
    this.newSchoolForm.get('school_type').setValue(8);
    this.newSchoolForm.get('district').clearValidators();
    this.newSchoolForm.get('district').setValidators(this.districtValidators);
  }

  ngOnDestroy() {
    if (this.user.first_education) {
      document.body.classList.remove("onboardeducation");
      this.user.first_education = false;
      localStorage.setItem('user', JSON.stringify(this.user));
    }
  }

  eduModal(educationModal: TemplateRef<any>) {
    if (this.user.first_education) {
      document.body.classList.remove("onboardeducation");
      this.user.first_education = false;
      localStorage.setItem('user', JSON.stringify(this.user));
    }
    this.updateAddress = '';
    this.addNewEdu = true;
    this.eduEditObj = null;
    this.showCaStates = true;
    this.schoolMembers = [];
    this.schoolMemberSliced = [];
    this.eduEditObj = null;
    this.teamLoadMoreBool = false;
    this.showTeam = false;
    this.eduformInit();
    this.modalRef = this.modalService.show(educationModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  teamModal(eduObj: any, teamModal: TemplateRef<any>) {
    this.eduEditObj = eduObj.id;
    this.teamMemberNext = 1;
    this.getProfileTeamMembers('');
    this.modalRef = this.modalService.show(teamModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  modalAddTeam(templateAddTeam: TemplateRef<any>, education: any) {
    this.schoolMembers = [];
    this.schoolMemberSliced = [];
    this.eduEditObj = null;
    this.schoolId = null;
    this.title = null;
    this.teamLoadMoreBool = false;
    if (education.id) {
      this.eduEditObj = education.id;
      this.schoolId = education.school.id;
      this.title = education.school.school_name;
      this.getSchoolMembers(this.eduEditObj);
    }
    this.modalRef = this.modalService.show(templateAddTeam , 
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  getCurrentEdForm(form_nm: number=1, is_college:boolean=false){
    if (form_nm === 1 || form_nm === 2){
      return (form_nm === this.currentEdForm);
    } else {
      return false;
    }
  }

  setNextPrevDone(next:boolean, prev:boolean, done: boolean){
    this.showNext = next;
    this.showPrev = prev;
    this.showDone = done;
  }

  getNextEd(){
    this.nextClicked = true;
    if (this.currentEdForm === 1 && this.educationForm.valid){
      if (this.schoolId == null){
        this.currentEdForm = 2;
        this.setNextPrevDone(false, true, true);
      } else {
        this.doneClicked = false;
        this.addEducation();
      }
      this.nextClicked = false;
    }
  }

  getPrevEd(){
    if (this.currentEdForm === 2){
      this.currentEdForm = 1;
      this.setNextPrevDone(true, false, false);
    }
  }

  getDoneClicked(){
    if (this.newSchoolForm.valid){
      this.doneClicked = false;
      this.addEducation();
    } else {
      this.doneClicked = true;
    }
  }

  changeCountry(e){
    this.educationForm.controls['state'].setValue(null);
    this.educationForm.controls['school_name'].setValue('');
    this.schoolId = null;
    this.schools = [];
    if (e.target.value == "USA"){
      this.showCaStates = false;
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
    } else {
      this.showCaStates = true;
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  changeSchoolCollege(e){
    this.newSchoolForm.controls['school_type'].setValue(8);
    this.educationForm.controls['school_name'].setValue('');
    this.schoolEdForm.controls['interests'].setValue('');
    this.collegeEdForm.controls['majors'].setValue('');
    this.collegeEdForm.controls['minors'].setValue('');
    this.collegeEdForm.controls['college_degree'].setValue('');
    this.schoolId = null;
    this.schools = [];
    if (e.target.value === "true") {
      this.insText = 'College/University'; 
      this.isCollegeSelected = true;
      this.districtValidators = [Validators.maxLength(50)];
      this.newSchoolForm.get('school_type').clearValidators();
      this.newSchoolForm.updateValueAndValidity();
      this.newSchoolForm.get('school_type').setValue(null);
      this.showDistrict = false;
    } else {
      this.insText = 'School'
      this.isCollegeSelected = false;
      this.newSchoolForm.get('school_type').setValidators([Validators.required, Validators.maxLength(30)]);
      this.newSchoolForm.get('school_type').updateValueAndValidity();
      this.districtValidators = [Validators.required, Validators.maxLength(50)];
      this.showDistrict = true;
    }
    this.newSchoolForm.get('district').clearValidators();
    this.newSchoolForm.get('district').setValidators(this.districtValidators);
  }

  changeState(e){
    this.educationForm.controls['school_name'].setValue('');
    this.schoolId = null;
    this.schools = [];
  }

  searchSchool(e){
    let key = e.target.value;
    this.schoolId = null;
    if (key !== null && key.length > 0) {
      this.schools = [];
      let state = this.educationForm.get('state').value;
      if (state == null) {
        state = "";
      }
      let country = this.educationForm.get('country').value;
      let is_college = this.educationForm.get('is_college').value;
      let params = key.toLowerCase() + '&country=' + country.toLowerCase();
      params += '&state=' + state.toLowerCase() + '&is_college=' + is_college;
      this.authService.searchSchool(params)
      .subscribe(response => {
        if (response.status == 200){
          this.schools = response.response;
          if (this.schools.length > 0) {
            this.hideResults = false;
          } else {
            this.hideResults = true;
          }
        }
      });
    } else {
      this.hideResults = true;
      this.schools = [];
    }
  }

  selectSchool(school) {
    this.hideResults = true;
    this.educationForm.controls['school_name'].setValue(school.school_name);
    this.schoolId = school.id;
    this.schools = [];
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.schools = [];
    }, 1000);
  }

  getDateString(_date: Date) {
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

  setEdInterfaceValue(iEducation){
    if (this.isCollegeSelected){
      iEducation.degree = this.collegeEdForm.controls['college_degree'].value;
      iEducation.program = this.collegeEdForm.controls['program'].value;
      if (this.collegeEdForm.controls['majors'].value) {
        iEducation.majors = this.collegeEdForm.controls['majors'].value.split(',');
      } else {
        iEducation.majors = [];
      }
      if (this.collegeEdForm.controls['minors'].value) {
        iEducation.minors = this.collegeEdForm.controls['minors'].value.split(',');
      } else {
        iEducation.minors = [];
      }
      iEducation.start_date = this.datePipe.transform(this.collegeEdForm.get('start_date').value,"yyyy-MM-dd");
      iEducation.end_date = this.datePipe.transform(this.collegeEdForm.get('end_date').value,"yyyy-MM-dd");
    } else {
      if (this.schoolEdForm.controls['interests'].value) {
        iEducation.interests = this.schoolEdForm.controls['interests'].value.split(',');
      } else {
        iEducation.interests = [];
      }
      iEducation.start_date = this.datePipe.transform(this.schoolEdForm.get('start_date').value,"yyyy-MM-dd");
      iEducation.end_date = this.datePipe.transform(this.schoolEdForm.get('end_date').value,"yyyy-MM-dd");
    }
    return iEducation;
  }

  addEducation(is_update:boolean = false) {
    this.edFormSubmitted = true;
    this.disableSubmit = true;
    if (this.schoolId) {
      let iEducation: IEducationExisting = {
        'school': parseInt(this.schoolId),
        'start_date': this.getDateString(this.viewDate),
        'end_date': this.getDateString(this.viewDate)
      };
      if (is_update){
        iEducation = this.setEdInterfaceValue(iEducation);
        this.profileService.updateExistingEducation(iEducation, this.eduEditObj)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
      } else {
        iEducation.start_date = this.datePipe.transform(this.educationForm.get('start_date').value,"yyyy-MM-dd");
        iEducation.end_date = this.datePipe.transform(this.educationForm.get('end_date').value,"yyyy-MM-dd");
        this.profileService.addExistingEducation(iEducation)
        .subscribe(response => {
          this.handleAddEduResponse(response);
        }, error => {
          this.handleEduSubmitError(error.error);
        });
      }
    } else {
      let iSchool: ISchool = {
        'school_name': this.educationForm.controls['school_name'].value,
        'address': this.newSchoolForm.controls['address'].value,
        'city': this.newSchoolForm.controls['city'].value,
        'country': this.educationForm.controls['country'].value,
        'state': this.educationForm.controls['state'].value,
        'school_category': this.newSchoolForm.controls['school_type'].value,
        'district': this.newSchoolForm.controls['district'].value,
        'zip_code': this.newSchoolForm.controls['zip_code'].value,
        'is_college': this.isCollegeSelected,
        'latitude': this.latitude,
        'longitude': this.longitude
      }
      let iEducation: IEducationNew = {
        'school': iSchool,
        'start_date': this.getDateString(this.viewDate),
        'end_date': this.getDateString(this.viewDate)
      }
      if (is_update){
        iEducation = this.setEdInterfaceValue(iEducation);
        this.profileService.updateNewEducation(iEducation, this.eduEditObj)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
      } else{
        iEducation.start_date = this.datePipe.transform(this.educationForm.get('start_date').value,"yyyy-MM-dd");
        iEducation.end_date = this.datePipe.transform(this.educationForm.get('end_date').value,"yyyy-MM-dd");
        this.profileService.addNewEducation(iEducation)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
        }
    }
  }

  protected handleAddEduResponse(response: any) {
    if (response.status === 111) {
      this.handleEduSubmitError(response.message);
    } else if (response.status === 200) {
        this.setNextPrevDone(false, false, false);
        this.eduEditObj = response.response.id;
        this.profileService.getSchoolMembers(this.eduEditObj)
        .subscribe(response => {
          if(response.status == 200){
            if (response.response.educations.length > 0) {
              this.showTeam = true;
              this.schoolMembers = response.response.educations;
              this.schoolMemberSliced = this.schoolMembers.slice(0, this.slicedItem);
              if (this.schoolMembers.length > this.schoolMemberSliced.length){
                this.teamLoadMoreBool = true;
              }
            } else {
              this.resetAddModal();
            }
          } else {
            this.resetAddModal();
          }
        }, error => {
          this.resetAddModal();
        });
    }
  }

  resetAddModal(){
    this.modalRef.hide();
    this.getEducationList();
    this.showTeam = false;
    this.disableSubmit = false;
    this.eduEditObj = null;
    this.edFormSubmitted = false;
  }

  protected handleEduResponse(response: any) {
    if (response.status === 111) {
      this.handleEduSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        let index = this.eduList.findIndex(obj => obj.id === response.response.id);
        if (response.response.school.is_college) {
          this.eduList[index].majors = response.response.majors;
          this.eduList[index].minors = response.response.minors;
        } else {
          this.eduList[index].interests = response.response.interests;
        }
        this.eduList[index].start_date = response.response.start_date;
        this.eduList[index].end_date = response.response.end_date;
        this.eduList[index].team_members = response.response.team_members;
        this.eduList[index].add_to_team = response.response.add_to_team;
        this.eduEditObj = null;
        this.edFormSubmitted = false;
        this.disableSubmit = false;
    }
  }

  protected handleEduSubmitError(data: any) {
    this.edFormSubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      const formList: FormGroup[] = [this.educationForm,this.newSchoolForm,  this.schoolEdForm, this.collegeEdForm]
      formList.forEach((formGroup: FormGroup) => {
        if (formGroup.contains(field)) {
          formGroup.setErrors({aftersubmit: true});
        }
      });
    });
  }

  getEducationList(){
    this.profileService.listEducation()
    .subscribe(response => {
      if(response.response.length > 0){
          this.eduList = response.response;
        } else {
          this.eduList = [];
        }
      });
  }

  updateEducationPopup(edPopup: TemplateRef<any>, ed_id: string) {
    let showModal;
    showModal = edPopup;
    this.profileService.RetreiveEducation(ed_id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            let educationObj = response.response;
            this.eduformUpdate(educationObj);
            this.eduEditObj = educationObj.id;
            this.addNewEdu = false;
            this.modalRef = this.modalService.show(
              showModal, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'}));
          } else {
            this.getEducationList();
          }
        });
  }

  eduformUpdate(response){
    this.eduformInit();
    this.educationForm.controls['school_name'].setValue(response.school.school_name);
    this.educationForm.controls['state'].setValue(response.school.state);
    this.schoolId = response.school.id;
    this.educationForm.controls['country'].setValue(response.school.country);
    let start_date = new Date(response.start_date);
    let end_date = new Date(response.end_date);
    if (response.school.is_college){
      this.educationForm.controls['is_college'].setValue("true");
      this.insText = "College/University";
      this.isCollegeSelected = true;
      this.collegeEdForm.controls['start_date'].setValue(start_date);
      this.collegeEdForm.controls['end_date'].setValue(end_date);
      this.collegeEdForm.controls['program'].setValue(response.program);
      if (response.majors) {
        this.collegeEdForm.controls['majors'].setValue(response.majors.toString());
      } else {
        this.collegeEdForm.controls['majors'].setValue(response.majors);
      }
      if (response.minors) {
        this.collegeEdForm.controls['minors'].setValue(response.minors.toString());
      } else {
        this.collegeEdForm.controls['minors'].setValue(response.minors);
      }
      this.collegeEdForm.controls['college_degree'].setValue(response.degree);
    } else {
      this.educationForm.controls['is_college'].setValue("false");
      this.insText = "School";
      this.isCollegeSelected = false;
      this.schoolEdForm.controls['start_date'].setValue(start_date);
      this.schoolEdForm.controls['end_date'].setValue(end_date);
      if (response.interests) {
        this.schoolEdForm.controls['interests'].setValue(response.interests.toString());
      } else {
        this.schoolEdForm.controls['interests'].setValue(response.interests);
      }
    }
    if (response.school.country == 'Canada'){
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
    } else {
      this.plchldrState = "State";
      this.plchldrPostal = "Zip Code";
    }
  }

  getUpdateClicked(isDelete:boolean=false){
    if (isDelete){
      this.deleteEducation();
      this.doneClicked = false;
      return;
    }
    if (this.isCollegeSelected && this.collegeEdForm.valid){
      this.doneClicked = false;
      this.addEducation(true);
    } else if (!this.isCollegeSelected && this.schoolEdForm.valid){
      this.doneClicked = false;
      this.addEducation(true);
    } else {
      this.doneClicked = true;
    }
  }

  deleteEducation() {
    let model = {
      'is_deleted': true
    }
    this.profileService.deleteEducation(model, this.eduEditObj)
      .subscribe(response => {
        this.modalRef.hide();
        let index = this.eduList.findIndex(obj => obj.id == this.eduEditObj);
        this.eduList.splice(index, 1);
        this.eduEditObj = null;
      });
  }

  closeModal(){
    this.modalRef.hide();
    this.eduEditObj = null;
  }

  setResultHide(e){ 
    this.hidePlaceResults = e;
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.newSchoolForm.controls['address'].setValue(null);
      this.showAddressError = true;
    } else {
      this.newSchoolForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let zip_code = '';
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    this.newSchoolForm.controls['address'].setValue(address);
    this.newSchoolForm.controls['city'].setValue(place.address.municipality);
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.educationForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

  getSchoolMembers(eduId: any){
    this.profileService.getSchoolMembers(eduId)
    .subscribe(response => {
      if(response.status == 200){
          this.schoolMembers = response.response.educations;
          this.schoolMemberSliced = this.schoolMembers.slice(0, this.slicedItem);
          if (this.schoolMembers.length > this.schoolMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  loadMoreMembers(id){
    let totalMember = this.schoolMembers.length;
    let slicedMember = this.schoolMemberSliced.length;
    if (slicedMember < totalMember){
      this.schoolMemberSliced = this.schoolMembers.slice(0, slicedMember+this.slicedItem)
    }
    if (totalMember > this.schoolMemberSliced.length){
      this.teamLoadMoreBool = true;
    } else {
      this.teamLoadMoreBool = false;
    }
    setTimeout(() => {
      let el = document.getElementById(id);
      el.scrollIntoView({behavior: 'smooth'});
    }, 500);
  }

  routeToProfile(member_id: any) {
    if (member_id){
      this.modalRef.hide();
      this.router.navigate(["/profile/" + member_id + "/"]);
    }
  }

  addToTeam(member: any){
    this.teamError = false;
    let model = {'tagged_by_edu': this.eduEditObj, 'tagged_to_edu': member.id, 'to_user': member.student_info.id}
    this.teamService.addEduTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.schoolMemberSliced.findIndex(obj => obj.id === member.id);
        this.schoolMemberSliced[index]['team_status'] = 1;
      } else {
        this.teamError = true;
      }
    }, error => {
      this.teamError = true;
    })
  }

  searchStudent(e: any){
    if (e.target.value && e.target.value.length > 0){
      this.searchQuery = e.target.value;
      this.schoolMemberSliced = this.schoolMembers.filter(item => item.student_info.student_name.search(new RegExp(this.searchQuery, 'i')) > -1);
      this.teamLoadMoreBool = false;
    } else if (this.searchQuery.length > 0) {
      this.schoolMemberSliced = this.schoolMembers;
    }
  }

  getProfileTeamMembers(params: any){
    this.profileService.getProfileEduMembers(this.eduEditObj, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  loadMoreTeamMemberProfile() {
    if (this.teamMemberNext != null) {
      let params = '?page=' + this.teamMemberNext;
      this.getProfileTeamMembers(params);
    }
  }

  delTeamMemberModal(delTeamTemplate: TemplateRef<any>, member: any) {
    this.editTeamMember = member;
    this.delModalRef = this.modalService.show(delTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTeamMember(){
    if (this.editTeamMember && this.editTeamMember.id) {
      this.teamService.deleteEduTeamMember(this.editTeamMember.id)
      .subscribe(response => {
        let index = this.teamMember.findIndex(obj => obj.id === this.editTeamMember.id);
        this.teamMember.splice(index, 1);
        this.totalTeamMember = this.totalTeamMember - 1;
        let eduIndex = this.eduList.findIndex(obj => obj.id === this.eduEditObj);
        this.eduList[eduIndex].team_members = this.totalTeamMember;
      })
    }
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.newModalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'school': this.schoolId
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  copyMessage(){
    let msg = "Join my network at " + this.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.setToCopy(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
