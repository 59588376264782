import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { UserProfile } from '../../shared/userProfile';
import { UserSkill } from '../../shared/userSkill';
import { UserBook } from '../../shared/userBook';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AuthenticationService } from '../../services/authentication.service';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000000 } }
  ]
})
export class CanvasComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  tabActive = 2;
  user: any = {};
  profile: any = {};
  editSkill: any = {};
  editInterest: any = {};
  editBook: any = {};
  editBulletin: any = {};
  skills = [];
  interests = [];
  books = [];
  bulletins = [];
  public editor = ClassicEditor;
  descriptionForm: FormGroup;
  addSkillForm: FormGroup;
  skillForm: FormGroup;
  linkForm: FormGroup;
  documentForm: FormGroup;
  interestForm: FormGroup;
  bookForm: FormGroup;
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  public interestFrmsubmitted: boolean = false;
  public bookFrmsubmitted: boolean = false;
  public disableSubmit: boolean = false;
  public eventListing = true;
  activeSlideIndex = 0;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  today = new Date();
  timeline_start: any;
  timeline_end: any;
  month_instances:any;
  current_year : any;
  running_year = new Date().getFullYear();
  prevYearCheck:boolean = true;
  nextYearCheck:boolean = true;
  timelineDetails : any;
  timelineColClass: any;
  timelineMonth:any;
  public infoDiv1 = true;
  currentInterestOrSkill : any;
  goalsBucketList = [];
  favouriteList = [];
  public learnAbout = true;
  public goalsBucket = true;
  
  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  userSkill: UserSkill = {
    description: '',
    title: '',
    user: '',
    id: '',
    link: undefined
  }

  userBook: UserBook = {
    description: '',
    title: '',
    user: '',
    id: '',
    author: '',
    rating: undefined
  }
  staticBulletins = [];
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage = "";
  coverImageUploadSizeMessage = "";
  skillLinkArray = [];
  skillDocArray = [];
  linkFrmSubmitted: boolean;
  previewThumbnail: any = false;
  previewThumbnailName: any;
  previewThumbnailLink: any = false;
  previewThumbnailLinkName: any;
  addMoreFileBoolean: boolean;
  addMoreFileError: boolean = false;
  addMoreFileErrorMessage: string;
  goalBucketForm: FormGroup;
  favouritesForm: FormGroup;
  currentGoalNBucket: any;
  goalBucketUpdate: boolean = false;
  currentFav: any;
  updateFavBool: boolean;
  learnMoreList= [];
  currentLearnAbout: any;
  mamList: any = [];
  mamForm: FormGroup;
  start_date: any;
  end_date: any;
  isEndDate: boolean = false;

  constructor(private authService: AuthenticationService, private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: ProfileService,
    private customValidator: CustomvalidationService) {
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    this.addSkillForm = this.formBuilder.group({
      skills: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
    }, { validators: this.skillsValidator });
    this.skillForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      description: new FormControl('', [Validators.maxLength(1500)]),
      past_description: new FormControl('', [Validators.maxLength(1500)]),
      present_description: new FormControl('', [Validators.maxLength(1500)]),
      future_description: new FormControl('', [Validators.maxLength(1500)])
    });
    this.linkForm = this.formBuilder.group({
      skill: new FormControl('', []),
      title: new FormControl('', [Validators.maxLength(30)]),
      link: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.documentForm = this.formBuilder.group({
      skill: new FormControl(''),
      title: new FormControl('', [Validators.maxLength(30)])
    });
    this.interestForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      description: new FormControl('', [Validators.maxLength(1500)]),
      past_description: new FormControl('', [Validators.maxLength(1500)]),
      present_description: new FormControl('', [Validators.maxLength(1500)]),
      future_description: new FormControl('', [Validators.maxLength(1500)])
    });
    this.bookForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(90), this.noWhitespaceValidator]),
      author: new FormControl('', [Validators.required, Validators.maxLength(90), this.noWhitespaceValidator]),
      description: new FormControl('', [Validators.required, Validators.maxLength(4500), this.noWhitespaceValidator]),
      rating: new FormControl('')
    });
    this.uploadForm = this.formBuilder.group({
      document: [''],
      document_name: [''],
      thumbnail: [''],
      thumbnail_name: ['']
    });
    this.bulletinForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]),
      description: new FormControl('', [Validators.maxLength(2250)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.goalBucketForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator])
    })
    this.favouritesForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      topic: new FormControl('', [Validators.maxLength(75)]),
    })
    this.mamForm = this.formBuilder.group({
      'title': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'role': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'location': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'start_date': new FormControl(''),
      'end_date': new FormControl('')
    }, { validators: this.dateValidator })
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.profileService.getStaticBulletin()
    .subscribe(response => {
      if (response.response.length > 0){
        this.staticBulletins = response.response;
      }
    });
    this.profileService.getDescription(this.user.id)
    .subscribe(response => {
      if (response.response['id'] !== undefined) {
        this.profile = response.response;
      }
    });
    this.getGoalsAndBuckets();
    this.getFavourites();
    this.getLearnMore();
    this.getMaM();
    this.profileService.getSkills(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.skills = response.response;
      }
    });
    this.profileService.getInterests(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.interests = response.response;
      }
    });
    this.getBulletin();
    this.profileService.getBooks(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.books = response.response;
      }
    });
    this.authService.getTimeline(this.running_year)
    .subscribe(response => {
      this.timeline_start = response.response.start_year;
      this.timeline_end = response.response.end_year;
      this.month_instances = response.response.month_dict;
      this.current_year = this.running_year
      if (this.timeline_start  < this.current_year){
        this.prevYearCheck = false;
      }else{
        this.prevYearCheck = true;
      }
      if (this.timeline_end  > this.current_year){
        this.nextYearCheck = false;
      }else{
        this.nextYearCheck = true;
      }
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0, 0, 0, 0);
      end_date = new Date(end_date).setHours(0, 0, 0, 0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    } else if (!start_date && end_date){
      return { 'startDateNA' : true}
    }
    return null;
  }

  skillsValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let skills_data = control.get('skills').value;
    if (skills_data && skills_data.length > 0) {
      let skills = skills_data.split(',');
      if (skills.length > 10) {
        return { 'maxCount': true };
      }
    }
    return null;
  }

  getGoalsAndBuckets(){
    this.profileService.getGoalsBucket(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.goalsBucketList = response.response;
      }
    })
  }

  getLearnMore(){
    this.profileService.getLearnMore(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.learnMoreList = response.response;
      }
    })
  }

  getFavourites(){
    this.profileService.getFavourite(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0){
        this.favouriteList = response.response;
      }
    })
  }

  getMaM(){
    this.profileService.getMoreAboutMe(this.user.id)
    .subscribe(response => {
      if (response.response.length > 0){
        this.mamList = response.response;
      }
    })
  }

  getBulletin() {
    this.profileService.getBulletin(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
        }
      });
  }

  getSkills() {
    this.profileService.getSkills(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        } else {
          this.skills = [];
        }
      });
  }

  getInterests() {
    this.profileService.getInterests(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.interests = response.response;
        } else {
          this.interests = [];
        }
      });
  }

  getBooks() {
    this.profileService.getBooks(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        } else {
          this.books = [];
        }
      });
  }


  endDate(e){
    const key = e.target.value;
    if(key == "1"){
      this.isEndDate = true;
      this.mamForm.get('end_date').setValidators([Validators.required]);
      this.mamForm.get('end_date').updateValueAndValidity();
    }else{
      this.isEndDate = false;
      this.mamForm.get('end_date').clearValidators();
      this.mamForm.get('end_date').updateValueAndValidity();
      this.mamForm.controls['end_date'].setValue(null);
    }
  }

  resetEndDate(){
    this.mamForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.mamForm.controls['start_date'].setValue(null);
  }

  addMam(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let start_date = this.datePipe.transform(this.mamForm.get('start_date').value,"yyyy-MM-dd");
      let end_Date = this.datePipe.transform(this.mamForm.get('end_date').value,"yyyy-MM-dd");
      let model = {
        'user': this.user.id,
        'title': this.mamForm.get('title').value,
        'role': this.mamForm.get('role').value,
        'location': this.mamForm.get('location').value,
        'start_date': start_date,
        'end_date': end_Date,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.profileService.createMoreAboutMe(model)
        .subscribe(response => {
          this.handleCreateMam(response);
        }, error => {
          this.handleMamError(error.error);
        })
    }
  }

  protected handleCreateMam(response: any){
    if (response.status === 111) {
      this.handleFavouriteError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.mamList.push(response.response)
      this.mamForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
    }
  }

  protected handleMamError(data: any){
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.mamForm.contains(field)) {
        this.mamForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  removeMamImage(element){
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideConfig2 = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  booksSlider = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  favouritesSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  mamSlider = {
    "slidesToShow": 5,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [ 
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0 || this.staticBulletins.length > 0) {
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    }
  }

  openModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  openWorkModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  skillInterestModal(skillpopup: TemplateRef<any>) {
    this.addSkillForm.reset();
    this.currentInterestOrSkill = "";
    this.submitted = false;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateSkillModal(skillpopup: TemplateRef<any>, skill: any) {
    this.editSkill = skill;
    this.linkFrmSubmitted = false;
    this.currentInterestOrSkill = skill.id;
    this.linkForm.reset();
    this.uploadForm.reset();
    this.previewThumbnailName = "";
    this.addMoreFileBoolean = false;
    this.addMoreFileErrorMessage = ""
    this.addMoreFileError = false;
    this.previewThumbnail = false;
    this.previewThumbnailLinkName = "";
    this.previewThumbnailLink = false;
    this.documentForm.reset();
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.linkFrmSubmitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.skillLinkArray = skill.user_link;
    this.skillDocArray = skill.user_document;
    this.skillForm.controls['title'].setValue(skill.title);
    this.skillForm.controls['past_description'].setValue(skill.past_description);
    this.skillForm.controls['present_description'].setValue(skill.present_description);
    this.skillForm.controls['future_description'].setValue(skill.future_description);
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  bulletinModal(skillpopup: TemplateRef<any>) {
    this.bulletinForm.reset();
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.submitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateInterestModal(skillpopup: TemplateRef<any>, interest: any) {
    this.editInterest = interest;
    this.currentInterestOrSkill = interest.id;
    this.skillLinkArray = [];
    this.skillDocArray = [];
    this.linkFrmSubmitted = false;
    this.linkForm.reset();
    this.uploadForm.reset();
    this.documentForm.reset();
    this.addMoreFileBoolean = false;
    this.addMoreFileErrorMessage = ""
    this.addMoreFileError = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.previewThumbnailLinkName = "";
    this.previewThumbnailLink = false;
    this.linkFrmSubmitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.skillLinkArray = interest.user_link;
    this.skillDocArray = interest.user_document;
    this.interestForm.controls['title'].setValue(interest.title);
    this.interestForm.controls['past_description'].setValue(interest.past_description);
    this.interestForm.controls['present_description'].setValue(interest.present_description);
    this.interestForm.controls['future_description'].setValue(interest.future_description);
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  bookModal(template: TemplateRef<any>) {
    this.bookForm.reset();
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.bookFrmsubmitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getRating(e) {
    this.bookForm.controls['rating'].setValue(e);
  }

  updatebookModal(skillpopup: TemplateRef<any>, book: any) {
    this.editBook = book;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.coverImageUploadSizeError = false;
    this.bookForm.controls['description'].setValue(book.description);
    this.bookForm.controls['title'].setValue(book.title);
    this.bookForm.controls['author'].setValue(book.author);
    this.updateModalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  booksListing(booksListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(booksListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  goalsListing(goalsModal: TemplateRef<any>) {
    this.goalBucketUpdate = false;
    this.goalBucketForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(goalsModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateGoals(goalBucket: any, goalsModal: TemplateRef<any>) {
    this.currentGoalNBucket = goalBucket;
    this.goalBucketUpdate = true;
    this.goalBucketForm.reset();
    this.goalBucketForm.controls['title'].setValue(goalBucket.title);
    this.submitted = false;
    this.modalRef = this.modalService.show(goalsModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  learnAboutListing(learnAboutModal: TemplateRef<any>) {
    this.goalBucketUpdate = false;
    this.goalBucketForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(learnAboutModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateLearnAbout(learnAbout: any, goalsModal: TemplateRef<any>) {
    this.currentLearnAbout = learnAbout;
    this.goalBucketUpdate = true;
    this.goalBucketForm.reset();
    this.goalBucketForm.controls['title'].setValue(learnAbout.title);
    this.submitted = false;
    this.modalRef = this.modalService.show(goalsModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  favouritesListing(favouritesModal: TemplateRef<any>) {
    this.updateFavBool = false;
    this.favouritesForm.reset();
    this.uploadForm.reset();
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.previewThumbnail = false;
    this.previewThumbnailName = ""
    this.modalRef = this.modalService.show(favouritesModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateFav(fav: any, favouritesModal: TemplateRef<any>) {
    this.updateFavBool = true;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.currentFav = fav;
    this.previewThumbnail = fav.thumbnail;
    this.favouritesForm.controls['title'].setValue(fav.title);
    this.favouritesForm.controls['topic'].setValue(fav.topic);
    this.modalRef = this.modalService.show(favouritesModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  mamListing(mamModal: TemplateRef<any>) {
    this.mamForm.reset();
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.modalRef = this.modalService.show(mamModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }


  updatebulletinModal(editbulletinModal: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.bulletinForm.controls['description'].setValue(bulletin.description);
    this.bulletinForm.controls['title'].setValue(bulletin.title);
    this.bulletinForm.controls['link'].setValue(bulletin.link);
    this.modalRef = this.modalService.show(editbulletinModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }


  addFavourite(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'user': this.user.id,
        'title': this.favouritesForm.get('title').value,
        'topic': this.favouritesForm.get('topic').value,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.profileService.addFavourite(model)
        .subscribe(response => {
          this.handleAddFavouriteResp(response);
        }, error => {
          this.handleFavouriteError(error.error);
        })
    }
  }

  updateFavourite(isValid: boolean){
    this.submitted = true;
    if (isValid){
      const prev_doc_name = this.uploadForm.get('thumbnail_name').value;
      let doc_name = this.currentFav.thumbnail_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let model = {
        'user': this.user.id,
        'title': this.favouritesForm.get('title').value,
        'topic': this.favouritesForm.get('topic').value,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': doc_name
      }
      this.profileService.upFavourite(model, this.currentFav.id)
        .subscribe(response => {
          this.handleAddFavouriteResp(response);
        }, error => {
          this.handleFavouriteError(error.error);
        })
    }
  }

  delFavourite() {
    this.profileService.deleteFavourite(this.currentFav.id)
      .subscribe(response => {
        let index = this.favouriteList.findIndex(obj => obj.id === this.currentFav.id)
        this.favouriteList.splice(index, 1)
        this.modalRef.hide();
        this.currentFav = {};
        this.favouritesForm.reset();
      })
  }

  protected handleAddFavouriteResp(response: any){
    if (response.status === 111) {
      this.handleFavouriteError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      if (this.updateFavBool){
        this.getFavourites();
      }
      else{
        this.favouriteList.push(response.response)
      }
      this.favouritesForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
    }
  }

  protected handleFavouriteError(data: any){
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.favouritesForm.contains(field)) {
        this.favouritesForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      this.userProfile.description = this.descriptionForm.get('description').value;
      this.userProfile.user = this.user.id;
      this.profileService.addDescription(this.userProfile)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  updateDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      this.userProfile.description = this.descriptionForm.get('description').value;
      this.userProfile.user = this.user.id;
      this.profileService.updateDescription(this.userProfile, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  deleteBio(id: any){
    this.profileService.deleteDescription(id)
    .subscribe(response => {
      this.profile = {};
      this.modalRef.hide();
      this.descriptionForm.reset();
    })
  }


  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.profile = response.response;
      this.descriptionForm.reset();
      this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  coverImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 7000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 7MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnail = reader.result;
          this.previewThumbnailName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
        };
      }
    }
  }

  blankfavImageUpload(update: boolean = true, element){
    if (update){
      this.currentFav.thumbnail_name = '';
    }
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  linkImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 3000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 3MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnailLink = reader.result;
          this.previewThumbnailLinkName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
        };
      }
    }
  }

  onFileSelect(event) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf", "doc", "docx"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 30000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 30MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
          this.addMoreFileBoolean = true;
          this.addMoreFileErrorMessage = "";
          this.addMoreFileError = false;
        };
      } else {
        this.uploadForm.reset();
      }
    } else {
      this.uploadForm.reset();
    }
  }

  delLink(id:any) {
    var id = id;
    this.profileService.deleteLink(id)
      .subscribe(response => {
        let index = this.skills.findIndex(obj => obj.id === id)
        this.skills.splice(index, 1)
        this.skillLinkArray = this.skillLinkArray.filter(item => item.id !== id);
      })
  }

  delLinkInterest(id:any) {
    var id = id;
    this.profileService.deleteLink(id)
      .subscribe(response => {
        let index = this.interests.findIndex(obj => obj.id === id)
        this.interests.splice(index, 1)
        this.skillLinkArray = this.skillLinkArray.filter(item => item.id !== id);
      })
  }

  delDoc(id:any){
    var id = id;
    this.profileService.deleteDoc(id)
    .subscribe(response => {
      let index = this.skillDocArray.findIndex(item => item.id == id);
      this.skillDocArray.splice(index, 1);
    })
  }

  delDocInterest(id:any){
    var id = id;
    this.profileService.deleteDoc(id)
    .subscribe(response => {
      let index = this.skillDocArray.findIndex(item => item.id == id);
      this.skillDocArray.splice(index, 1);
    })
  }

  addMoreSkillLink(isValid: boolean){
    this.linkFrmSubmitted = true;
    if (isValid){
      this.linkFrmSubmitted =false;
      let link = this.linkForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'skill': this.currentInterestOrSkill,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.profileService.addLink(model)
        .subscribe(response => {
          this.handleLinkResponse(response);
          this.skillLinkArray.push(response.response);
          this.linkForm.reset();
          this.linkFrmSubmitted = false;
        }, error => {
          this.handleSubmitError(error.error);
        })
    }
  }

  addMoreSkillDoc(isValid: boolean, element){
    if (isValid && this.uploadForm.valid && this.uploadForm.get('document').value){
      let model = {
        'skill': this.currentInterestOrSkill,
        'title': this.documentForm.get('title').value,
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.addDoc(model)
        .subscribe(response => {
          this.handleLinkResponse(response);
          this.skillDocArray.push(response.response);
          this.documentForm.reset();
          element.value = '';
        }, error => {
          this.handleSubmitError(error.error);
        })
    }
    else{
      this.addMoreFileErrorMessage = "Add any image/document file."
      this.addMoreFileError = true;
      this.uploadForm.reset();
    }
  }

  addMoreInterestLinkSubmit(isValid: boolean){
    this.linkFrmSubmitted = true;
    if (isValid){
      this.linkFrmSubmitted =false;
      let link = this.linkForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'interest': this.currentInterestOrSkill,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.profileService.addLink(model)
        .subscribe(response => {
          this.handleInterestLinkResponse(response);
          this.skillLinkArray.push(response.response);
          this.linkForm.reset();
          this.linkFrmSubmitted = false;
        }, error => {
          this.handleInterestSubmitError(error.error);
        })
    }
  }

  addMoreInterestDocSubmit(isValid: boolean, element){
    if (isValid && this.uploadForm.valid && this.uploadForm.get('document').value){
      let model = {
        'interest': this.currentInterestOrSkill,
        'title': this.documentForm.get('title').value,
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.addDoc(model)
        .subscribe(response => {
          this.handleInterestDocResponse(response);
          this.skillDocArray.push(response.response);
          this.documentForm.reset();
          element.value = '';
        }, error => {
          this.handleInterestSubmitError(error.error);
        })
    }
    else{
      this.addMoreFileErrorMessage = "Add any image/document file."
      this.addMoreFileError = true;
      this.uploadForm.reset();
    }
  }

  addSkill(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'skills': this.addSkillForm.get('skills').value}
      this.profileService.addSkill(model)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.addSkillForm.get('skills').setErrors({aftersubmit: "Something went wrong please try again later."});
        })
    }
  }

  updateSkill(isValid: boolean) {
    // this.submitted = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.skillForm.get('title').value,
        'past_description': this.skillForm.get('past_description').value,
        'present_description': this.skillForm.get('present_description').value,
        'future_description': this.skillForm.get('future_description').value
      }
      this.profileService.updateSkill(model, this.editSkill.id)
        .subscribe(response => {
          this.handleSkillResponse(response);
        }, error => {
          this.handleSkillSubmitError(error.error);
        })
    }
  }

  delSkill() {
    this.profileService.deleteSkill(this.editSkill.id)
      .subscribe(response => {
        let index = this.skills.findIndex(obj => obj.id === this.editSkill.id)
        this.skills.splice(index, 1)
        this.updateModalRef.hide();
        this.editSkill = {};
        this.skillForm.reset();
      })
  }

  protected handleResponse(response: any) {
    if (response.status === 112) {
      this.addSkillForm.get('skills').setErrors({aftersubmit: response.message});
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.getSkills();
      this.addSkillForm.reset();
      this.submitted = false;
    } else {
      this.addSkillForm.get('skills').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  protected handleLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.linkForm.reset();
      this.addMoreFileBoolean = false;
      this.getSkills();
      this.documentForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.addMoreFileErrorMessage = ""
      this.addMoreFileError = false;
      this.disableSubmit = false;
      this.previewThumbnailLinkName = "";
      this.previewThumbnailLink = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.skillForm.contains(field)) {
        this.skillForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  protected handleSkillResponse(response: any) {
    if (response.status === 111) {
      this.handleSkillSubmitError(response.message);
    } else if (response.status === 200) {
      this.getSkills();
      this.updateModalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.linkForm.reset();
      this.documentForm.reset();
      this.skillForm.reset();
    }
  }

  protected handleSkillSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.skillForm.contains(field)) {
        this.skillForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addGoalsAndBucket(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.goalBucketForm.get('title').value
      }
      this.profileService.addGoalBucket(model)
      .subscribe(response => {
        this.handleGoalBucketResponse(response);
      }, error => {
        this.handleGoalBucketError(error.error);
      })
    }  
  }

  updateGoalsAndBucket(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.goalBucketForm.get('title').value
      }
      this.profileService.updateGoalBucket(model, this.currentGoalNBucket.id)
      .subscribe(response => {
        this.handleGoalBucketResponse(response);
      }, error => {
        this.handleGoalBucketError(error.error);
      })
    }  
  }

  delGoalsAndBucket() {
    this.profileService.deleteGoalBucket(this.currentGoalNBucket.id)
      .subscribe(response => {
        let index = this.goalsBucketList.findIndex(obj => obj.id === this.currentGoalNBucket.id)
        this.goalsBucketList.splice(index, 1)
        this.modalRef.hide();
        this.currentGoalNBucket = {};
        this.goalBucketForm.reset();
      })
  }

  protected handleGoalBucketResponse(response: any) {
    if (response.status === 111) {
      this.handleGoalBucketError(response.message);
    } else if (response.status === 200) {
      this.goalBucketForm.reset();
      this.modalRef.hide();
      if (this.goalBucketUpdate){
        this.getGoalsAndBuckets()
      }
      else{
        this.goalsBucketList.push(response.response)
      }
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleGoalBucketError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.goalBucketForm.contains(field)) {
        this.goalBucketForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addLearnMoreAbout(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.goalBucketForm.get('title').value
      }
      this.profileService.addLearnMore(model)
      .subscribe(response => {
        this.handleLearnMoreResponse(response);
      }, error => {
        this.handleLearnMoreError(error.error);
      })
    }  
  }

  updateLearnMoreAbout(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.goalBucketForm.get('title').value
      }
      this.profileService.updateLearnMore(model, this.currentLearnAbout.id)
      .subscribe(response => {
        this.handleLearnMoreResponse(response);
      }, error => {
        this.handleLearnMoreError(error.error);
      })
    }  
  }
  
  delLearnMoreAbout() {
    this.profileService.deleteLearnMore(this.currentLearnAbout.id)
      .subscribe(response => {
        let index = this.learnMoreList.findIndex(obj => obj.id === this.currentLearnAbout.id)
        this.learnMoreList.splice(index, 1)
        this.modalRef.hide();
        this.currentLearnAbout = {};
        this.goalBucketForm.reset();
      })
  }

  protected handleLearnMoreResponse(response: any) {
    if (response.status === 111) {
      this.handleLearnMoreError(response.message);
    } else if (response.status === 200) {
      this.goalBucketForm.reset();
      this.modalRef.hide();
      if (this.goalBucketUpdate){
        this.getLearnMore();
      }
      else{
        this.learnMoreList.push(response.response)
      }
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleLearnMoreError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.goalBucketForm.contains(field)) {
        this.goalBucketForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addInterest(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'interests': this.addSkillForm.get('skills').value}
      this.profileService.addInterest(model)
        .subscribe(response => {
          this.handleInterestResponse(response);
        }, error => {
          this.addSkillForm.get('skills').setErrors({aftersubmit: "Something went wrong please try again later."});
        })
    }
  }

  updateInterest(isValid: boolean) {
    this.interestFrmsubmitted = true;
    if (isValid) {
      let model = {
        'user': this.user.id,
        'title': this.interestForm.get('title').value,
        'past_description': this.interestForm.get('past_description').value,
        'present_description': this.interestForm.get('present_description').value,
        'future_description': this.interestForm.get('future_description').value
      }
      this.profileService.updateInterest(model, this.editInterest.id)
        .subscribe(response => {
          this.handleInterestUpdResponse(response);
        }, error => {
          this.handleInterestSubmitError(error.error);
        })
    }
  }

  delInterest() {
    this.profileService.deleteInterest(this.editInterest.id)
      .subscribe(response => {
        let index = this.interests.findIndex(obj => obj.id === this.editInterest.id)
        this.interests.splice(index, 1)
        this.updateModalRef.hide();
        this.editInterest = {};
        this.interestForm.reset();
      })
  }

  protected handleInterestResponse(response: any) {
    if (response.status === 112) {
      this.addSkillForm.get('skills').setErrors({aftersubmit: response.message});
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.getInterests();
      this.addSkillForm.reset();
      this.submitted = false;
    } else {
      this.addSkillForm.get('skills').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  protected handleInterestLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.linkForm.reset();
      this.addMoreFileBoolean = false;
      this.getInterests();
      this.documentForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.addMoreFileErrorMessage = ""
      this.addMoreFileError = false;
      this.disableSubmit = false;
      this.previewThumbnailLinkName = "";
      this.previewThumbnailLink = false;
    }
  }

  protected handleInterestDocResponse(response: any) {
    if (response.status === 111) {
      this.handleInterestSubmitError(response.message);
    } else if (response.status === 200) {
      // this.modalRef.hide();
      this.interests.push(response.response);
      this.uploadForm.reset();
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.getInterests();
      this.linkForm.reset();
      this.documentForm.reset();
      this.interestFrmsubmitted = false;
      this.disableSubmit = false;
      this.addMoreFileBoolean = false;
      this.addMoreFileErrorMessage = ""
      this.addMoreFileError = false;
    }
  }

  protected handleInterestUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleInterestSubmitError(response.message);
    } else if (response.status === 200) {
      this.getInterests();
      this.updateModalRef.hide();
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.linkForm.reset();
      this.documentForm.reset();
      this.uploadForm.reset();
      this.interestFrmsubmitted = false;
      this.interestForm.reset();
    }
  }

  protected handleInterestSubmitError(data: any) {
    this.interestFrmsubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.interestForm.contains(field)) {
        this.interestForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  removeBookCover(id: any) {
    let model = {
      'thumbnail': null,
      'user': this.user.id,
      'title': this.editBook['title'],
      'description': this.editBook['description'],
      'rating': this.editBook['rating'],
      'author': this.editBook['author'],
      'thumbnail_name': null,
      'id': this.editBook['id'],
    }
    this.profileService.updateBook(model, this.editBook.id)
      .subscribe(response => {
        this.editBook["thumbnail"] = null;
        this.editBook["thumbnail_name"] = null;
      })
  }
  
  addBook(isValid: boolean) {
    this.bookFrmsubmitted = true;
    if (isValid) {
      let rating = undefined;
      if (this.bookForm.get('rating').value !== "" || this.bookForm.get('rating').value !== null) {
        rating = this.bookForm.get('rating').value;
      }
      let model = {
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'user': this.user.id,
        'title': this.bookForm.get('title').value,
        'description': this.bookForm.get('description').value,
        'rating': rating,
        'author': this.bookForm.get('author').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value,
        'id': ""
      }
      
      this.profileService.addBook(model)
        .subscribe(response => {
          this.handleBookResponse(response);
        }, error => {
          this.handleBookSubmitError(error.error);
        })
    }
  }

  updateBook(isValid: boolean) {
    this.bookFrmsubmitted = true;

    if (isValid) {
      const prev_doc_name = this.uploadForm.get('thumbnail_name').value;
      let doc_name = this.editBook.thumbnail_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let rating = undefined;
      if (this.bookForm.get('rating').value) {
        rating = this.bookForm.get('rating').value;
      } else {
        rating = this.editBook.rating;
      }
      let model = {
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'user': this.user.id,
        'title': this.bookForm.get('title').value,
        'description': this.bookForm.get('description').value,
        'rating': rating,
        'author': this.bookForm.get('author').value,
        'thumbnail_name': doc_name,
        "id": ""
      }
      this.profileService.updateBook(model, this.editBook.id)
        .subscribe(response => {
          this.handleBookUpdResponse(response);
        }, error => {
          this.handleBookSubmitError(error.error);
        })
    }
  }

  delBook() {
    this.profileService.deleteBook(this.editBook.id)
      .subscribe(response => {
        let index = this.books.findIndex(obj => obj.id === this.editBook.id)
        this.books.splice(index, 1)
        this.updateModalRef.hide();
        this.editBook = {};
        this.bookForm.reset();
      })
  }

  protected handleBookResponse(response: any) {
    if (response.status === 111) {
      this.handleBookSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.books.push(response.response);
      this.getBooks();
      this.bookForm.reset();
      this.uploadForm.reset();
      this.bookFrmsubmitted = false;
    }
  }

  protected handleBookUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBookSubmitError(response.message);
    } else if (response.status === 200) {
      this.getBooks();
      this.updateModalRef.hide();
      this.bookForm.reset();
      this.uploadForm.reset();
      this.bookFrmsubmitted = false;
    }
  }

  protected handleBookSubmitError(data: any) {
    this.bookFrmsubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bookForm.contains(field)) {
        this.bookForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  addBulletin(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': this.uploadForm.get('document_name').value,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.profileService.addBulletin(model)
        .subscribe(response => {
          this.handleBulletinResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateBulletin(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editBulletin.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      const prev_thumbnail_name = this.uploadForm.get('thumbnail_name').value;
      let thumbnail_name = this.editBulletin.thumbnail_name;
      if (prev_thumbnail_name && prev_thumbnail_name.length > 0) {
        thumbnail_name = prev_thumbnail_name;
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': doc_name,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': thumbnail_name
      }
      this.profileService.updateBulletin(model, this.editBulletin.id)
        .subscribe(response => {
          this.handleBulletinUpdResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    }
  }

  delBulletin() {
    this.profileService.deleteBulletin(this.editBulletin.id)
      .subscribe(response => {
        let index = this.bulletins.findIndex(obj => obj.id === this.editBulletin.id)
        this.bulletins.splice(index, 1)
        this.modalRef.hide();
        this.editBulletin = {};
        this.bulletinForm.reset();
      })
  }

  removeBulletinDoc(id: any) {
    const prev_thumbnail_name = this.uploadForm.get('thumbnail_name').value;
      let thumbnail_name = this.editBulletin.thumbnail_name;
      if (prev_thumbnail_name && prev_thumbnail_name.length > 0) {
        thumbnail_name = prev_thumbnail_name;
      }
    let model = {
      'document': null,
      'user': this.user.id,
      'title': this.editBulletin['title'],
      'description': this.editBulletin['description'],
      'link': this.editBulletin['link'],
      'document_name': null,
      'thumbnail': this.uploadForm.get('thumbnail').value,
      'thumbnail_name': thumbnail_name
    }
    this.profileService.updateBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.editBulletin["document"] = null;
        this.editBulletin["document_name"] = null;
      })
  }

  removeBulletinCover(id: any) {
    const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editBulletin.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }

    let model = {
      'document': this.uploadForm.get('document').value,
      'user': this.user.id,
      'title': this.editBulletin['title'],
      'description': this.editBulletin['description'],
      'link': this.editBulletin['link'],
      'document_name': doc_name,
      'thumbnail': null,
      'thumbnail_name': null
    }
    this.profileService.updateBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.editBulletin["thumbnail"] = null;
        this.editBulletin["thumbnail_name"] = null;
      })
  }

  protected handleBulletinResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.getBulletin();
      this.updBulletin();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false
    }
  }

  protected handleBulletinUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.getBulletin();
      this.modalRef.hide();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
    }
  }

  protected handleBulletinSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bulletinForm.contains(field)) {
        this.bulletinForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  handleTermsResponse(response: any) {
    if (response.status === 200) {
        this.user.age_group_updated = response.response.age_group_updated;
        this.user.age_group = response.response.age_group;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
    }
  }


  prevYear(){
    Array.from(document.getElementsByClassName("eventListing")).forEach(function(item) {
      if (item.classList.contains('show')){
        item.classList.remove('show')
      }
   });
    this.timelineDetails = []
    this.eventListing = this.eventListing
    if (this.timeline_start < this.current_year){
      this.current_year = this.current_year - 1;
      this.authService.getTimeline(this.current_year)
      .subscribe(response => {
        this.timeline_start = response.response.start_year;
        this.timeline_end = response.response.end_year;
        this.month_instances = response.response.month_dict;
        if (this.timeline_start < this.current_year){
          this.prevYearCheck = false;
        } else {
          this.prevYearCheck = true;
        }
        if (this.running_year > this.current_year){
          this.nextYearCheck = false;
        } else {
          this.nextYearCheck = true;
        }
      });
    }
  }

  nextYear(){
    Array.from(document.getElementsByClassName("eventListing")).forEach(function(item) {
      if (item.classList.contains('show')){
        item.classList.remove('show')
      }
   });
    this.timelineDetails = []
    this.eventListing = this.eventListing
    this.timelineMonth = ""
    if (this.running_year > this.current_year){
      this.current_year = this.current_year + 1;
      this.authService.getTimeline(this.current_year)
      .subscribe(response => {
        this.timeline_start = response.response.start_year;
        this.timeline_end = response.response.end_year;
        this.month_instances = response.response.month_dict;
        if (this.timeline_start < this.current_year){
          this.prevYearCheck = false;
        }else{
          this.prevYearCheck = true;
        }
        if (this.running_year > this.current_year){
          this.nextYearCheck = false;
        }else{
          this.nextYearCheck = true;
        }
      });
    }
  }

  getTimelineDetails(month){
    var months = [ "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December" ];
    this.timelineMonth = months[month-1];
    this.eventListing = !this.eventListing;
    for (const [key, value] of Object.entries(this.month_instances)) {
      if (key == month){
        this.timelineDetails = value;
      }
    }
    Array.from(document.getElementsByClassName("dotSpan")).forEach(function(item) {
      if (item.classList.contains('active')){
        item.classList.remove('active');
      }
    });
    let element = document.getElementsByClassName(month + this.current_year);
    if (!this.eventListing){
      if (element[0].classList.contains('active')){
        element[0].classList.remove('active');
      } else {
        element[0].classList.add('active');
      }
    }
  }

  getClass(value,key){
    let returnClass=''
    if (value.length == 0){
      this.timelineColClass = "hideEle"
    }
    else if (value.length > 1){
      this.timelineColClass = 'school';
    }else if ( value[0].instance_name == 'UserSchoolInvolvement')
    {
      this.timelineColClass = 'school';
    }else if(value[0].instance_name == 'UserVolExperience'){
      this.timelineColClass = 'volunteer';
    }else if(value[0].instance_name == 'UserWorkExperience'){
      this.timelineColClass = 'business';
    }else{
      this.timelineColClass = 'student';
    }

    returnClass = this.timelineColClass + " " + key + this.current_year
    return returnClass
  }

  jumpToMaMDetails(currentMaM: any){
    let mamId = currentMaM.id;
    this.router.navigate(['edit-mam/' + mamId + '/'])
  }

  deleteMam(id: any){
    this.profileService.delMam(id)
      .subscribe(response => {
        let index = this.mamList.findIndex(obj => obj.id === id)
        this.mamList.splice(index, 1)
      })
  }

  descOrder = (a, b) => {
    if(a.key < b.key) return a.key;
  }

  showAdd(){
    if (this.mamList.length > 0 && this.books.length > 0 && this.bulletins.length > 0 && this.interests.length > 0 && 
        this.favouriteList.length > 0 && this.learnMoreList.length > 0) {
      return false;
    }
    return true;
  }

}
