import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentComponent } from './student/student.component';
import { CreateBusinessPageComponent } from './create-business-page/create-business-page.component';
import { CreateClubteamPageComponent } from './create-clubteam-page/create-clubteam-page.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileBusinessComponent } from './profile-business/profile-business.component';
import { ProfileSchoolComponent } from './profile-school/profile-school.component';
import { SearchProfilesComponent } from './search/search-profiles/search-profiles.component';
import { StudentDashboardComponent } from './profile/student-dashboard/student-dashboard.component';
import { NotificationsComponent } from './profile/notifications/notifications.component';
import { SearchPageComponent } from './search/search-page/search-page.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ParentVerificationComponent } from './parent-verification/parent-verification.component';
import { AuthGuard } from './shared/_guards/auth.guard';
import { ProfileSettingComponent } from './profile/profile-setting/profile-setting.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ResourcesComponent } from './resources/resources.component';
import { BusinessPageComponent } from './business-page/business-page.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InvitationSignupComponent } from './signup/invitation-signup/invitation-signup.component';
import { BusinessListingComponent } from './business-listing/business-listing.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { ProfileVerificationComponent } from './profile-verification/profile-verification.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { BusinessProfileComponent } from './search/business-profile/business-profile.component';
import { StudentProfileComponent } from './search/student-profile/student-profile.component';
import { PageNotFoundComponent } from './error-pages/not-found/not-found.component';
import { StudentSearchComponent } from './search/student-search/student-search.component';
import { SchoolBucketComponent } from './school-bucket/school-bucket.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';
import { TermsPolicyComponent } from './terms-policy/terms-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SearchHelpComponent } from './search/search-help/search-help.component';
import { VerifyExperienceComponent } from './verify-experience/verify-experience.component';
import { StudentsComponent  } from './why-signup/students/students.component';
import { OthersComponent  } from './why-signup/others/others.component';
import { ClaimPageComponent } from './claim-page/claim-page.component';
import { EditInitiativesComponent } from './profile/edit-initiatives/edit-initiatives.component';
import { ViewInitiativeComponent } from './search/view-initiative/view-initiative.component';
import { MoreAboutMeComponent } from './profile/more-about-me/more-about-me.component';
import { SearchInitiativeComponent } from './search/search-initiative/search-initiative.component';
import { EditMamComponent } from './profile/edit-mam/edit-mam.component';
import { ResumeComponent } from './profile/resume/resume.component';
import { PublicResumeComponent } from './public-resume/public-resume.component';
import { PublicExperienceComponent } from './public-experience/public-experience.component';
import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { ExperienceTeamComponent } from './profile/experience-team/experience-team.component';
import { MessageChatComponent } from './profile/message-chat/message-chat.component';
import { NeedHelpComponent } from './need-help/need-help.component';
import { PostDetailComponent } from './post-detail/post-detail.component';

const routes: Routes = [
  { path: '', component: LandingComponent},
  { path: 'signup', component: StudentComponent },
  { path: 'invitation-signup', component: InvitationSignupComponent},
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/profile-business', component: ProfileBusinessComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/profile-school', component: ProfileSchoolComponent, canActivate: [AuthGuard]},
  { path: 'dashboard', component: StudentDashboardComponent, canActivate: [AuthGuard]},
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},
  { path: 'profile/teams', component: ExperienceTeamComponent, canActivate: [AuthGuard]},
  { path: 'message-chat', component: MessageChatComponent, canActivate: [AuthGuard]},
  { path: 'verification', component: AccountVerificationComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'parent-verification', component:ParentVerificationComponent},
  { path: 'profile/setting', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'profile/experiences', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'setting', component: ProfileSettingComponent, canActivate: [AuthGuard]},
  { path: 'help', component: NeedHelpComponent},
  { path: 'page/:page_id/contact', component:SearchHelpComponent, canActivate: [AuthGuard]},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'faq', component:ResourcesComponent},
  { path: 'create-page', component: BusinessPageComponent, canActivate: [AuthGuard]},
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard]},
  { path: 'page-listing', component: BusinessListingComponent, canActivate: [AuthGuard]},
  { path: 'mental-health', component:MentalHealthComponent},
  { path: 'createbusinesspage', component: CreateBusinessPageComponent, canActivate: [AuthGuard]},
  { path: 'create-clubteam-page', component: CreateClubteamPageComponent, canActivate: [AuthGuard]},
  { path: 'profile-verification', component: ProfileVerificationComponent},
  { path: 'help-page', component: HelpPageComponent, canActivate: [AuthGuard]},
  { path: 'school/:id', component: SchoolBucketComponent, canActivate: [AuthGuard]},
  { path: 'profile/:user_id', component: StudentProfileComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id', component: BusinessProfileComponent, canActivate: [AuthGuard]},
  { path: 'search-page', component: SearchPageComponent, canActivate: [AuthGuard]},
  { path: 'search-pages', component: SearchProfilesComponent, canActivate: [AuthGuard]},
  { path: 'student-search', component: StudentSearchComponent, canActivate: [AuthGuard]},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'guidelines', component: CommunityGuidelinesComponent},
  { path: 'terms-of-use', component: TermsPolicyComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'verify-experience', component: VerifyExperienceComponent},
  { path: 'why-signup-students', component: StudentsComponent},
  { path: 'why-signup-others', component: OthersComponent},
  { path: 'verify-claim', component: ClaimPageComponent},
  { path: 'edit-initiatives/:page_id', component: EditInitiativesComponent, canActivate: [AuthGuard]},
  { path: 'initiatives/:page_id', component: ViewInitiativeComponent},
  { path: 'edit-mam/:page_id', component: EditMamComponent, canActivate: [AuthGuard]},
  { path: 'mam/:page_id', component: MoreAboutMeComponent, canActivate: [AuthGuard]},
  { path: 'search-initiative', component: SearchInitiativeComponent},
  { path: 'resume', component: ResumeComponent, canActivate: [AuthGuard]},
  { path: 'post-detail/:post_id/:post_type', component: PostDetailComponent, canActivate: [AuthGuard]},
  { path: 'public-resume/:user_id', component: PublicResumeComponent},
  { path: 'public-experience/:user_id', component: PublicExperienceComponent},
  { path: 'signin', component: SigninComponent},
  { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
