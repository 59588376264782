<div class="custom-tab-dark custom-tab-teal">
    <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3">
        <div class="blog-listing">
            <div class="feeds-box-listing my-feeds pt-0">
                <div class="box" *ngFor="let feed of feeds" [ngClass]="{'no-image': feed?.documents?.length == 0 }">
                    <span class="event"
                        *ngIf="feed.post_info.post_type === 1 || feed.post_info.post_type === 8 || feed.post_info.post_type === 4">Update</span>
                    <span class="event"
                        *ngIf="feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10">Event</span>
                    <span class="event"
                        *ngIf="feed.post_info.post_type === 2 || feed.post_info.post_type === 5">Position</span>
                    <span class="event interested" *ngIf="feed.post_info.post_type === 6">
                        <img src="assets/images/blub.svg" alt="blub" />Idea
                    </span>
                    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                        [config]="communityConfig">
                        <div class="communitySlide slide" ngxSlickItem *ngFor="let document of feed.documents">
                            <div class="communitySlideInner">
                                <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                                <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg"
                                    alt="pdf-image" />
                            </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="content-box">
                        <div class="img-box">
                            <img class="cursor-pointer" (click)="directToPage(feed)"
                                src="{{feed.display_picture? feed.display_picture: 'assets/images/default.svg'}}"
                                alt="small-img" />
                        </div>
                        <div class="content">
                            <h3 class="cursor-pointer" (click)="feedDetailModal(feedDetail, feed)">{{feed.title}}</h3>
                            <p>{{feed.page_title}}</p>
                            <div class="date-box"
                            *ngIf="[3,5,6,7,8,10].includes(feed.post_info.post_type) && (feed.post_info.event_date != null)">
                                <div class="month">
                                    <span>{{feed.post_info.event_date | date:'MMM'}}</span>
                                </div>
                                <div class="date">
                                    <span>{{feed.post_info.event_date | date:'d'}}</span>
                                </div>
                            </div>
                            <div class="date-box"
                            *ngIf="[1,2,4].includes(feed.post_info.post_type) && (feed.post_info.date != null)">
                            <div class="month">
                              <span>{{feed.post_info.date | date:'MMM'}}</span>
                            </div>
                            <div class="date">
                              <span>{{feed.post_info.date | date:'d'}}</span>
                            </div>
                          </div>
                            <p class="para cursor-pointer" (click)="feedDetailModal(feedDetail, feed)"
                                innerHtml="{{feed.description | slice:0:100}}{{feed.description.length > descriptionLimit ? '...' : ''}}"></p>
                        </div>
                        <div class="save-content">
                            <div class="left-part">
                                <button type="button" class="interest-btn" (click)="markInterested(feed)"
                                    [ngClass]="{'remove': feed.interested_data?.interested}">
                                    <img class="remove-img" src="/assets/images/interested-star-filled.svg"
                                        alt="star-filled" />
                                    <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                    <span class="remove-text">remove</span>
                                    <span>Interested</span>
                                </button>
                                <button type="button" class="other-btn" *ngIf="feed.interested_data.count > 0"
                                    (click)="openModalOthers(templateOthers, feed)">
                                    {{feed.interested_data.count}} Others
                                </button>
                            </div>
                            <div class="right-part">
                                <div class="btn-group" dropdown>
                                    <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                                        aria-controls="dropdown-basic">
                                        <img src="assets/images/dot-teal.svg" alt="more icon" />
                                    </button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box"
                                        role="menu" aria-labelledby="button-basic">
                                        <li role="menuitem" >
                                            <div class="flag-data-box cursor-pointer" (click)="editFeedModal(editFeedTemplate, feed)">
                                                <img class="edit-icon" src="/assets/images/editIcon.svg" alt="edit" />
                                                <span>Edit</span>
                                            </div>
                                        </li>
                                        <li role="menuitem" >
                                            <div class="flag-data-box cursor-pointer" (click)="delFeedModal(delFeedTemplate, feed)">
                                                <img class="edit-icon" src="/assets/images/deleteIcon.svg" alt="delete" />
                                                <span>Delete</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="totalFeedsCount > 10 && totalFeedsCount > feeds.length"
                class="next-listing text-center mt-4 pb-4 timeLineLoad d-lg-block d-none">
                <a href="javascript:void(0);" (click)="getFeeds()">Load More</a>
            </div>
        </div>
    </div>
</div>

<ng-template #feedDetail>
    <div class="modal-body new-body">
        <div class="create-box timeline">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
                <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg"
                    alt="arrow" />
            </button>
        </div>
        <div class="timeLinesEach custom-design scrollbar">
            <div class="feedDetailSlider" [ngClass]="{'no-image': editFeedObj?.documents?.length == 0 }">
                <span class="event"
                    *ngIf="editFeedObj.post_info.post_type === 3 || editFeedObj.post_info.post_type === 7 || editFeedObj.post_info.post_type === 10">Event</span>
                <span class="event"
                    *ngIf="editFeedObj.post_info.post_type === 2 || editFeedObj.post_info.post_type === 5">Position</span>
                <span class="event"
                    *ngIf="editFeedObj.post_info.post_type === 1 || editFeedObj.post_info.post_type === 8 || editFeedObj.post_info.post_type === 4">Update</span>
                <span class="event interested" *ngIf="editFeedObj.post_info.post_type === 6">
                    <img src="assets/images/blub.svg" alt="blub" />Idea
                </span>
                <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
                    <div class="slide" ngxSlickItem *ngFor="let document of editFeedObj.documents">
                        <div class="img-box">
                            <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                            <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg"
                                alt="pdf-image" />
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
            <div class="feedDetailContent">
                <div class="img-box"
                    [ngClass]="{'mt-4 mt-lg-0 mt-md-0': editFeedObj?.documents?.length == 0 && [2,3,5,6,7].indexOf(editFeedObj.post_info.post_type) < 0}">
                    <img class="cursor-pointer" (click)="directToPage(editFeedObj);closeModal()"
                        src="{{editFeedObj.display_picture? editFeedObj.display_picture : 'assets/images/default.svg'}}"
                        alt="image" />
                </div>
                <h3>{{editFeedObj.title}}</h3>
                <p>{{editFeedObj.page_title}}</p>
                <div class="date-box"
                *ngIf="[3,5,6,7,8,10].includes(editFeedObj.post_info.post_type) && (editFeedObj.post_info.event_date != null)">
                    <div class="month">
                        <span>{{editFeedObj.post_info.event_date | date:'MMM'}}</span>
                    </div>
                    <div class="date">
                        <span>{{editFeedObj.post_info.event_date | date:'d'}}</span>
                    </div>
                </div>
                <div class="date-box"
                *ngIf="[1,2,4].includes(editFeedObj.post_info.post_type) && (editFeedObj.post_info.date != null)">
                <div class="month">
                  <span>{{editFeedObj.post_info.date | date:'MMM'}}</span>
                </div>
                <div class="date">
                  <span>{{editFeedObj.post_info.date | date:'d'}}</span>
                </div>
              </div>
                <div class="save-content">
                    <div class="left-part">
                        <button type="button" class="interest-btn"
                            [ngClass]="{'remove': editFeedObj.interested_data.interested}"
                            (click)="markInterested(editFeedObj)">
                            <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                            <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                            <span class="remove-text">remove</span>
                            <span>Interested</span>
                        </button>
                        <button type="button" class="other-btn" *ngIf="editFeedObj.interested_data.count > 0"
                            (click)="openModalOthers(templateOthers, editFeedObj)">
                            {{editFeedObj.interested_data.count}} Others
                        </button>
                    </div>
                </div>
                <div class="details">
                    <h4>Details</h4>
                    <p innerHtml="{{editFeedObj.description}}"></p>
                    <p class="metaLink" *ngIf="!checkExtraData(editFeedObj)">
                        <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
                    </p>
                    <div class="singleTimelineDetail" *ngIf="checkExtraData(editFeedObj)">
                        <div class="imageBox">
                            <img alt="timeline" class="timeLineImg"
                                src="{{editFeedObj.metadata_image_link? editFeedObj.metadata_image_link : 'assets/images/default.svg'}}" />
                        </div>
                        <div class="dtlOuter">
                            <h1>{{editFeedObj.metadata_title}}</h1>
                            <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
                            <p>{{editFeedObj.metadata_description}}</p>
                        </div>
                    </div>
                    <div class="posted-by">
                        <h4>Posted by</h4>
                        <div class="name-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                            <img src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}"
                                alt="image" />
                            <p>{{editFeedObj.post_info.creator_name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateOthers>
    <div class="modal-body new-body common-body">
        <div class="create-box">
            <h4 class="modal-title pull-left">Interested</h4>
            <button type="button" class="close-btn" aria-label="Close" (click)="otherModalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <ul class="follow-list scroll-activity scrollbar">
            <li *ngFor="let usr of interestedUsers">
                <div class="info-content">
                    <img alt="profile-image" class="cursor-pointer" (click)="closeModal()"
                        [routerLink]="['/profile', usr.id]"
                        src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
                    <div class="text">
                        <p class="bold cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
                            [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}
                        </p>
                        <p>{{usr.education_info.school_name}}</p>
                        <p class="small" *ngIf="usr.education_info.school">
                            {{usr.education_info.start_date | date: 'yyyy'}} -
                            {{usr.education_info.end_date | date: 'yyyy'}}</p>
                    </div>
                </div>
            </li>
        </ul>
        <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
            <a href="javascript:void(0);" (click)="getInterestedUsers()">Load More</a>
        </div>
    </div>
</ng-template>

<ng-template #editFeedTemplate>
    <div class="modal-body new-body">
        <div class="modal-box">
            <div class="create-box timeline">
                    <h4 class="modal-title pull-left">Update Post</h4>
                    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                        <img alt="image" src="assets/images/cross-modal.svg">
                    </button>
            </div>
            <form [formGroup]="timelineUpdForm" novalidate>
                <ckeditor [editor]="Editor" formControlName="description"
                    [config]="{ toolbar: [ 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('required') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('maxlength') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched)">
                    Max 2250 characters allowed.
                </div>
                <div class="error-message" *ngIf="timelineUpdForm.get('description').hasError('aftersubmit')">
                    {{timelineUpdForm.controls.description.errors.aftersubmit}}
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer new-btns">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="updatePost(timelineUpdForm.valid)">update</button>
    </div>
</ng-template>

<ng-template #delFeedTemplate>
    <div class="modal-body new-body">
        <div class="modal-box">
            <div class="create-box timeline">
                    <h4 class="modal-title pull-left">Delete Post</h4>
                    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                        <img  alt="image" src="assets/images/cross-modal.svg">
                    </button>
            </div>
            <h4>Are you sure you want to delete this Post?</h4>
        </div>
    </div>
    <div class="modal-footer new-btns">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="deletePost();this.modalRef.hide();">
            Confirm
        </button>
    </div>
</ng-template>
