import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseAccountEndPoint, BaseProfileEndPoint } from '../app.config';
import { UserProfile } from '../shared/userProfile';
import { UserBook } from '../shared/userBook';
import { IEducationNew, IEducationExisting } from '../shared/education';
import { IJobPosting, IVolunteerPosting } from '../shared/jobPosting';
import { IContactUs } from '../shared/contactUs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private baseAccountsUrl: string = BaseAccountEndPoint.apiEndpoint;
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;
  private baseChatUrl: string = environment.apiURL + 'api/chats/';

  constructor(private http: BaseService) { }

  addDescription(userProfile: UserProfile) {
    return this.http.post(this.baseAccountsUrl + 'add-description/', userProfile);
  }

  getDescription(id: string) {
    return this.http.get(this.baseAccountsUrl + 'userprofile/' + id + '/');
  }

  updateDescription(userProfile: UserProfile, id: string) {
    return this.http.put(this.baseAccountsUrl + 'profile/update/' + id + '/', userProfile);
  }

  deleteDescription(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'profile/update/' + id + '/');
  }

  addSkill(userSkill: any) {
    return this.http.post(this.baseAccountsUrl + 'add-skill/', userSkill);
  }

  addLink(skillLink: any) {
    return this.http.post(this.baseAccountsUrl + 'add-link/', skillLink);
  }

  addDoc(skillDoc: any) {
    return this.http.post(this.baseAccountsUrl + 'add-doc/', skillDoc);
  }

  getSkills(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-skills/' + id + '/');
  }

  updateSkill(userSkill: any, id: string) {
    return this.http.put(this.baseAccountsUrl + 'skill/update/' + id + '/', userSkill);
  }

  updateLink(userLink: any, id: string) {
    return this.http.put(this.baseAccountsUrl + 'link/update/' + id + '/', userLink);
  }

  deleteSkill(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'skill/update/' + id + '/');
  }

  deleteLink(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'link/update/' + id + '/');
  }

  deleteDoc(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'document/update/' + id + '/');
  }

  addInterest(userSkill: any) {
    return this.http.post(this.baseAccountsUrl + 'add-interest/', userSkill);
  }

  getInterests(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-interests/' + id + '/');
  }

  updateInterest(userSkill: any, id: string) {
    return this.http.put(this.baseAccountsUrl + 'interest/update/' + id + '/', userSkill);
  }

  deleteInterest(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'interest/update/' + id + '/');
  }

  addBook(userBook: UserBook) {
    return this.http.post(this.baseAccountsUrl + 'add-book/', userBook);
  }

  getBooks(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-books/' + id + '/');
  }

  updateBook(userBook: UserBook, id: string) {
    return this.http.put(this.baseAccountsUrl + 'book/update/' + id + '/', userBook);
  }

  deleteBook(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'book/update/' + id + '/');
  }

  addBulletin(userBulletin: any) {
    return this.http.post(this.baseProfileUrl + 'add-bulletin/', userBulletin);
  }

  getBulletin(id: string) {
    return this.http.get(this.baseProfileUrl + 'user-bulletin/' + id + '/');
  }

  updateBulletin(userBulletin: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'bulletin/update/' + id + '/', userBulletin);
  }

  deleteBulletin(id: string) {
    return this.http.delete(this.baseProfileUrl + 'bulletin/update/' + id + '/');
  }

  addUserActivity(userActivity: any) {
    return this.http.post(this.baseProfileUrl + 'add-activity/', userActivity);
  }

  getUserActivities(id: string) {
    return this.http.get(this.baseProfileUrl + 'activities/' + id + '/');
  }

  getUserActivitiesRecent(id: string) {
    return this.http.get(this.baseProfileUrl + 'activities-recent/' + id + '/');
  }

  getOtherUserActivities(id: string) {
    return this.http.get(this.baseProfileUrl + 'other-activities/' + id + '/');
  }

  getOtherUserActivitiesRecent(id: string) {
    return this.http.get(this.baseProfileUrl + 'other-activities-recent/' + id + '/');
  }

  updateUserActivity(userActivity: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'activity/update/' + id + '/', userActivity);
  }

  deleteUserActivity(id: string) {
    return this.http.delete(this.baseProfileUrl + 'activity/update/' + id + '/');
  }

  addNewEducation(eduObj: IEducationNew) {
      return this.http.post(this.baseProfileUrl + 'student-education/create-new/', eduObj);
  }

  addExistingEducation(eduObj: IEducationExisting) {
    return this.http.post(this.baseProfileUrl + 'student-education/create-existing/', eduObj);
  }

  listEducation() {
    return this.http.get(this.baseProfileUrl + 'student-education/list/');
  }

  otherStudentlistEducation(pk) {
    return this.http.get(this.baseProfileUrl + 'other-student-education/list/' + pk + '/');
  }

  deleteEducation(eduObj: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/delete/' +  id + '/', eduObj);
  }

  updateExistingEducation(eduObj: IEducationExisting, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/update-existing/' +  id + '/', eduObj);
  }

  updateNewEducation(eduObj: IEducationNew, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/update-new/' +  id + '/', eduObj);
  }

  RetreiveEducation(id: string) {
    return this.http.get(this.baseProfileUrl + 'student-education/' +  id + '/');
  }

  searchOrg(type: string, params: any) {
    return this.http.get(this.baseAccountsUrl + 'organizations/' + type + '/?search=' + params);
  }

  searchOrgPage(id: string, type: string) {
    return this.http.get(this.baseAccountsUrl + 'organization-pages/' + id + '/' + type + '/');
  }

  addUserWorkExp(UserWorkExp: any) {
    return this.http.post(this.baseProfileUrl + 'add-work-experience/', UserWorkExp);
  }

  getUserWorkExp(type: string) {
    return this.http.get(this.baseProfileUrl + 'work-experience/' + type + '/');
  }

  getUserWorkExpRecent(type: string) {
    return this.http.get(this.baseProfileUrl + 'work-experience-recent/' + type + '/');
  }

  getOtherUserWorkExp(type: string, user_id: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-work-experience/' + type + '/' + user_id + '/');
  }

  getOtherUserWorkExpRecent(type: string, user_id: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-work-experience-recent/' + type + '/' + user_id + '/');
  }

  updateUserWorkExp(UserWorkExp: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'work-experience/update/' + id + '/', UserWorkExp);
  }

  deleteUserWorkExp(id: string) {
    return this.http.delete(this.baseProfileUrl + 'work-experience/update/' + id + '/');
  }

  listJobs(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'job-postings/' + id + '/' + params);
  }

  updateJob(jobObj: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'job-posting/' +  id + '/', jobObj);
  }

  addJob(jobObj: any) {
    return this.http.post(this.baseProfileUrl + 'job-posting/', jobObj);
  }

  getRecentJob(id: string) {
    return this.http.get(this.baseProfileUrl + 'job-postings/recent/' +  id + '/');
  }

  RetreiveJob(id: string) {
    return this.http.get(this.baseProfileUrl + 'job-posting/' +  id + '/');
  }

  deleteJob(id: string) {
    return this.http.delete(this.baseProfileUrl + 'job-posting/' + id + '/');
  }

  listRestricted() {
    return this.http.get(this.baseProfileUrl + 'list-restricted/');
  }

  listFAQs() {
    return this.http.get(this.baseProfileUrl + 'list-faqs/');
  }

  listVolunteerJobs(id: string, params: any) {
    return this.http.get(this.baseProfileUrl + 'volunteer-postings/' +  id + '/' + params);
  }

  updateVolunteerJob(jobObj: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'volunteer-posting/' +  id + '/', jobObj);
  }

  addVolunteerJob(jobObj: any) {
    return this.http.post(this.baseProfileUrl + 'volunteer-posting/', jobObj);
  }

  RetreiveVolunteerJob(id: string) {
    return this.http.get(this.baseProfileUrl + 'volunteer-posting/' +  id + '/');
  }

  getRecentVolunteer(id: string) {
    return this.http.get(this.baseProfileUrl + 'volunteer-postings/recent/' +  id + '/');
  }

  deleteVolunteerJob(id: string) {
    return this.http.delete(this.baseProfileUrl + 'volunteer-posting/' + id + '/');
  }

  addSchoolInvolvement(UserSchoolInv: any) {
    return this.http.post(this.baseProfileUrl + 'add-school-involvement/', UserSchoolInv);
  }

  getSchoolInvolvement() {
    return this.http.get(this.baseProfileUrl + 'school-involvement/list/');
  }

  getSchoolInvolvementRecent() {
    return this.http.get(this.baseProfileUrl + 'school-involvement-recent/list/');
  }

  getOtherUserSchoolInvolvement(pk: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-school-involvement/list/' + pk + '/');
  }

  getOtherUserSchoolInvolvementRecent(pk: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-school-involvement-recent/list/' + pk + '/');
  }

  updateSchoolInvolvement(UserSchoolInv: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'school-involvement/update/' + id + '/', UserSchoolInv);
  }

  deleteSchoolInvolvement(id: string) {
    return this.http.delete(this.baseProfileUrl + 'school-involvement/update/' + id + '/');
  }

  contactUsPost(contactObj: IContactUs) {
    return this.http.post(this.baseAccountsUrl + 'contact-us/', contactObj);
  }

  searchClubTeam(params: any) {
    return this.http.get(this.baseAccountsUrl + 'search-club-team/?search=' + params);
  }

  searchClubTeamPage(params: any) {
    return this.http.get(this.baseAccountsUrl + 'school-pages/?search=' + params);
  }

  changePassword(obj: any) {
    return this.http.put(this.baseAccountsUrl + 'change-password/', obj);
  }

  verifyPassword(password: string) {
    return this.http.post(this.baseAccountsUrl + 'verify-password/', {password: password});
  }

  changeEmailReq(email: string) {
    return this.http.post(this.baseAccountsUrl + 'change-email/', {new_email: email});
  }

  verifyOTP(otp: string, id: string) {
    return this.http.put(this.baseAccountsUrl + 'verify-otp/' + id + '/', {otp: otp});
  }

  createVerifier(obj: any) {
    return this.http.post(this.baseAccountsUrl + 'add-verifier/', obj);
  }

  listVerifiers() {
    return this.http.get(this.baseAccountsUrl + 'verifiers/');
  }

  listOtherProfileVerifiers(user_id) {
    return this.http.get(this.baseAccountsUrl + 'verifiers/user/' + user_id + '/');
  }

  deleteVerifier(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'delete-verifier/' + id + '/', obj);
  }

  resendVerification(id: any) {
    return this.http.get(this.baseAccountsUrl + 'resend-verification/' + id + '/');
  }

  ProfileImage(data:any , id: any) {
    return this.http.put(this.baseAccountsUrl + 'user-profile/' + id + '/', data);
  }

  BusinessVerificationStatus(id: any) {
    return this.http.get(this.baseAccountsUrl + 'business-verification-status/' + id + '/');
  }

  ResendProEmail(id: any) {
    return this.http.get(this.baseAccountsUrl + 'resend-proemail/' + id + '/');
  }

  OtherUserProfile(id:any){
    return this.http.get(this.baseAccountsUrl + 'other-user-profile/' + id + '/');
  }
  CheckUserBlockStatus(id:any){
    return this.http.get(this.baseAccountsUrl + 'check-user-block-status/' + id + '/');
  }

  UserSchoolInvolvementMembers(page_id:any, params: any){
    return this.http.get(this.baseProfileUrl + 'get-schoolinvolvement-members/' + page_id + '/' + params);
  }

  AcceptTerms(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'approve-terms-policies/' + id + '/', data);
  }

  getUserNotifCount(params: string = "") {
    return this.http.get(this.baseProfileUrl + 'get-notif-count/' + params);
  }

  markNotifications() {
    return this.http.get(this.baseProfileUrl + 'mark-notification/');
  }

  getConversations() {
    return this.http.get(this.baseChatUrl + 'conversations/');
  }

  createConversation(obj: any) {
    return this.http.post(this.baseChatUrl + 'create-conversation/', obj);
  }

  getRecentConversation() {
    return this.http.get(this.baseChatUrl + 'get-recent-conversation/');
  }

  getConversation(id: any) {
    return this.http.get(this.baseChatUrl + 'get-conversation/' + id + '/');
  }

  sendMessage(obj: any) {
    return this.http.post(this.baseChatUrl + 'send-message/', obj);
  }

  deleteConversation(id: any) {
    return this.http.put(this.baseChatUrl + 'delete-conversation/' + id + '/', {});
  }

  deleteMessages(id: any, obj: any) {
    return this.http.put(this.baseChatUrl + 'delete-messages/' + id + '/', obj);
  }

  getReferralCount() {
    return this.http.get(this.baseAccountsUrl + 'get-referral-count/');
  }

  delPageImage(data:any , id: any) {
    return this.http.put(this.baseAccountsUrl + 'page-image/' + id + '/', data);
  }

  delProfileImage(data:any) {
    return this.http.put(this.baseAccountsUrl + 'profile-image/', data);
  }

  getPageVerifiers(page_id: string) {
    return this.http.get(this.baseProfileUrl + 'page-verifiers/' + page_id + '/');
  }

  getUserSchools() {
    return this.http.get(this.baseProfileUrl + 'list-user-schools/');
  }

  getStaticBulletin() {
    return this.http.get(this.baseAccountsUrl + 'get-static-bulletin/');
  }

  getSchoolVerifiers(page_id: string) {
    return this.http.get(this.baseProfileUrl + 'school-verifiers/' + page_id + '/');
  }

  checkPageAddress(obj: any) {
    return this.http.post(this.baseProfileUrl + 'check-address/', obj);
  }

  getEditHistory(page_id: string, params: string) {
    return this.http.get(this.baseProfileUrl + 'edit-history/' + page_id + '/' + params);
  }

  getGoalsBucket(user_id: string){
    return this.http.get(this.baseProfileUrl + 'get-goals/' + user_id + '/');
  }

  addGoalBucket(data: any){
    return this.http.post(this.baseProfileUrl + 'create-goal/', data)
  }

  updateGoalBucket(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-goal/' + id + '/', data)
  }

  deleteGoalBucket(id: string){
    return this.http.delete(this.baseProfileUrl + 'update-goal/' + id + '/')
  }

  addFavourite(data:any){
    return this.http.post(this.baseProfileUrl + 'create-favourite/', data)
  }

  upFavourite(data:any, id:string){
    return this.http.put(this.baseProfileUrl + 'update-favourite/' + id + '/', data)
  }

  deleteFavourite(id: string){
    return this.http.delete(this.baseProfileUrl + 'update-favourite/' + id + '/')
  }

  getFavourite(id: string){
    return this.http.get(this.baseProfileUrl + 'favourite/' + id + '/')
  }

  getLearnMore(user_id: string){
    return this.http.get(this.baseProfileUrl + 'get-learn-about/' + user_id + '/');
  }

  addLearnMore(data: any){
    return this.http.post(this.baseProfileUrl + 'create-learn-about/', data)
  }

  updateLearnMore(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-learn-about/' + id + '/', data)
  }

  deleteLearnMore(id: string){
    return this.http.delete(this.baseProfileUrl + 'update-learn-about/' + id + '/')
  }

  getMoreAboutMe(id:string){
    return this.http.get(this.baseProfileUrl + 'get-mam/' + id + '/')
  }

  createMoreAboutMe(data: any){
    return this.http.post(this.baseProfileUrl + 'create-mam/', data)
  }

  getMoreAboutMeDetail(id: string){
    return this.http.get(this.baseProfileUrl + 'get-mam-details/' + id + '/');
  }

  saveMamDescription(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-mam/description/' + id + '/', data)
  }

  updateMamdetail(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-mam/' +  id + '/', data)
  }

  updateMamHeader(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-mam/header-image/' + id + '/', data)
  }

  removeMamHeader(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'header-image-mam/' + id + '/', data)
  }

  getUserImage(id: string){
    return this.http.get(this.baseAccountsUrl + 'get-user-images/' + id + '/');
  }

  addPageTimeline(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-timeline/', data);
  }

  recentPageTimeline(id: string){
    return this.http.get(this.baseProfileUrl + 'timeline/recent/' + id + '/');
  }

  pageTimeline(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'timeline/' + id + '/' + params);
  }

  updatePageTimeline(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-timeline/' + id + '/', data);
  }

  deletePageTimeline(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-timeline/' + id + '/');
  }

  pageAllRecentTimeline(id: string){
    return this.http.get(this.baseProfileUrl + 'list-timelines/recent/' + id + '/');
  }

  pageAllTimeline(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'list-timelines/' + id + '/' + params);
  }

  getResumeSummary(){
    return this.http.get(this.baseProfileUrl + 'get-summary/');
  }

  updateResumeSummary(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-summary/' + id + '/', data);
  }

  addResumeExp(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-resume-experience/', data);
  }

  getResumeExperiences(){
    return this.http.get(this.baseProfileUrl + 'get-resume-experiences/');
  }

  getResumeSkillInterests(){
    return this.http.get(this.baseProfileUrl + 'get-resume-skillinterest/');
  }

  addResumeSkillInterest(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-resume-skillinterest/', data);
  }

  addPagePosition(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-page-position/', data);
  }

  pagePosition(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'get-position/' + id + '/' + params);
  }

  updatePagePosition(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-page-position/' + id + '/', data);
  }

  deletePagePosition(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-page-position/' + id + '/');
  }

  pageAllSchoolTimeline(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'list-school-timelines/' + id + '/' + params);
  }

  getAllExperiences() {
    return this.http.get(this.baseProfileUrl + 'list-all-experiences/');
  }

  getAllSkillinterests() {
    return this.http.get(this.baseProfileUrl + 'list-all-skillinterest/');
  }

  addResumeReference(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-resume-reference/', data);
  }

  deleteResumeReference(id: string) {
    return this.http.delete(this.baseProfileUrl + 'delete-resume-reference/' + id + '/');
  }

  getAllResumeReference() {
    return this.http.get(this.baseProfileUrl + 'get-resume-reference/');
  }

  selectResumeReference(data: any) {
    return this.http.post(this.baseProfileUrl + 'select-resume-reference/', data);
  }

  downloadResumePDF() {
    return this.http.get(this.baseProfileUrl + 'pdf-resume-download/');
  }

  delMam(id: any){
    return this.http.delete(this.baseProfileUrl + 'delete-mam/' + id + '/');
  }

  getVerifications(){
    return this.http.get(this.baseProfileUrl + 'user-experiences/');
  }

  deleteWorkVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'delete-work-verification/' + id + '/', {});
  }

  resendWorkVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'resend-work-verification/' + id + '/', {});
  }

  deleteActivityVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'delete-activity-verification/' + id + '/', {});
  }

  resendActivityVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'resend-activity-verification/' + id + '/', {});
  }

  deleteClubTeamVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'delete-clubteam-verification/' + id + '/', {});
  }

  resendClubTeamVerification(id: any){
    return this.http.put(this.baseProfileUrl + 'resend-clubteam-verification/' + id + '/', {});
  }

  addExpReference(data: any){
    return this.http.post(this.baseProfileUrl + 'add-experience-reference/', data)
  }

  addActivityReference(data: any){
    return this.http.post(this.baseProfileUrl + 'add-activity-reference/', data)
  }

  addSchoolInvReference(data: any){
    return this.http.post(this.baseProfileUrl + 'add-school-inv-reference/', data)
  }

  getTaggedPages(){
    return this.http.get(this.baseProfileUrl + 'get-tagged-pages/');
  }

  getWeeklyDetails(params: any)
  {
    return this.http.get(this.baseProfileUrl + 'weekly-events-feeds/' + params);
  }

  getTaggedExpByPage(page_id: any, id: any, exp_type: any){
    return this.http.get(this.baseProfileUrl + 'get-tagged-exp/' + exp_type + '/' + page_id + '/' + id + '/');
  }

  getTaggedClubTeam(page_id: any, id: any, exp_type: any){
    return this.http.get(this.baseProfileUrl + 'get-tagged-clubteam/' + exp_type + '/' + page_id + '/' + id + '/');
  }

  getProfileTeam(expType: string, expId: string){
    return this.http.get(this.baseProfileUrl + 'team-for-profile/' + expType + '/' + expId + '/');
  }

  getProfileMembers(expType: string, expId: string, params: string){
    return this.http.get(this.baseProfileUrl + 'team-member-profile/' + expType + '/' + expId + '/' + params);
  }

  createAwards(data: any){
    return this.http.post(this.baseProfileUrl + 'add-awards-credentials/', data);
  }

  updateAwards(data: any, id: any){
    return this.http.put(this.baseProfileUrl + 'update-awards-credentials/' + id + '/', data);
  }

  deleteAward(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-awards-credentials/' + id + '/');
  }

  getAwards(userId: string){
    return this.http.get(this.baseProfileUrl + 'get-awards-credentials/' + userId + '/');
  }

  getSchoolMembers(id: string) {
    return this.http.get(this.baseProfileUrl + 'team-for-profile/edu/' + id + '/');
  }

  getProfileEduMembers(eduId: string, params: string){
    return this.http.get(this.baseProfileUrl + 'team-approved-member/edu/' + eduId + '/' + params);
  }

  getTaggedEdu(sch_id: any, id: any){
    return this.http.get(this.baseProfileUrl + 'get-tagged-edu/' + sch_id + '/' + id + '/');
  }

  getInitiativeMembers(id: string) {
    return this.http.get(this.baseProfileUrl + 'get-tagged/initiative/' + id + '/');
  }

  getUserInitiativeMembers(id: string) {
    return this.http.get(this.baseProfileUrl + 'get-tagged/userinitiative/' + id + '/');
  }

  getIniTeamMembers(iniId: string, params: string){
    return this.http.get(this.baseProfileUrl + 'get-approved-member/initiative/' + iniId + '/' + params);
  }

  getUserIniTeamMembers(iniId: string, params: string){
    return this.http.get(this.baseProfileUrl + 'get-approved-member/taggedinitiative/' + iniId + '/' + params);
  }

  tagSearch(expType: any, params: any) {
    return this.http.get(this.baseProfileUrl + 'tag-search/' + expType + '/' + params);
  }

  getSocialLinks(id: any){
    return this.http.get(this.baseAccountsUrl + 'get-social-link/' + id + '/');
  }

  addSocialLink(data: any){
    return this.http.post(this.baseAccountsUrl + 'add-social-link/', data);
  }

  addClubTeamEvents(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-clubteam-event/', data);
  }

  clubTeamEvents(id: string, params: any){
    return this.http.get(this.baseProfileUrl + 'get-clubteam-event-list/' + id + '/' + params);
  }

  updateClubTeamEvent(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-clubteam-event/' + id + '/', data);
  }

  deleteClubTeamEvent(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-clubteam-event/' + id + '/');
  }

  getFeeds(params: any){
    return this.http.get(this.baseProfileUrl + 'feeds/' + params);
  }

  getSchFeeds(params: any){
    return this.http.get(this.baseProfileUrl + 'education-school-feeds/' + params);
  }

  reportFeed(data: any){
    return this.http.post(this.baseProfileUrl + 'report-feed/', data);
  }

  getFollowedPages(params: any){
    return this.http.get(this.baseProfileUrl + 'get-follow-all-pages/' + params);
  }

  getMyPages(params: any){
    return this.http.get(this.baseAccountsUrl + 'all-page-listing/' + params);
  }

  getNewPagesNotif(params: any){
    return this.http.get(this.baseProfileUrl + 'education-school-notif/' + params);
  }

  getMyFeeds(params: any){
    return this.http.get(this.baseProfileUrl + 'my-feeds/' + params);
  }

  getShareUrl(id: any, type: any){
    return this.http.get(this.baseProfileUrl + 'share-post-url/' + id + '/' + type + '/');
  }

  getPostDetails(id: any, type: any){
    return this.http.get(this.baseProfileUrl + 'share-post-view/' + id + '/' + type + '/');
  }


}
