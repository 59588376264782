<div class="fullBanner">
    <div class="forImage">
        <img alt="banner" class="bannerImg"
            src="{{header_image? header_image : 'assets/images/new-header-img.png'}}">
            
            <div class="return-wrap">
                    <button class="return-btn" (click)="returnToProfile();">                
                        <img alt="image" src="assets/images/return-btn-teal.svg" class="returnImg">
                    </button>
            </div>
    </div>
    <div class="forDetail">
        <div class="container custom-container max-890 mb-40">
            <div class="row">
                <!-- <div class="d-none d-md-block col-md-4 sdg-wrap">

                </div> -->
                <div class="col-12 center-wrap">
                    <div class="withProfile">
                        <div class="forEdit mr-auto">
                            <form>
                                <img alt="profile Image" class="profileImg"
                                    src="{{display_image? display_image : 'assets/images/new-header-img.png'}}">
                            </form>
                        </div>

                    </div>

                    <div class="profileList">
                        <h3>{{ title }}</h3>
                        <h4>{{ location }}</h4>
                        <p *ngIf="start_date">{{ start_date | date: 'MMMM y' }} -
                            <span *ngIf="end_date" class="normal-data">{{ end_date | date : 'MMMM y' }}</span>
                            <span *ngIf="!end_date" class="normal-data">Present</span>
                        </p>
                    </div>
                </div>
                <!-- <div class="d-none d-md-block col-md-4 support-wrap">
                </div> -->
            </div>
            <p class="initiativeSummary mt-1">{{ summary }}</p>
        </div>

        <!-- <div class="search-wrap">
            <div class="container custom-container max-890">
                <div class="search-inner">
                    <div class="left-zone">
                        <button class="btn btn-light-colored mr-2 mr-md-4" (click)="returnToProfile();">Return</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="custom-tab-dark">
    <div class="impactBlock backWhite">
        <div class="eachBlock">
            <div class="topHdg">
                <h3 class="hdgName">
                    Links
                </h3>
            </div>
            <div class="blockDes blockDesLinks">
                <ngx-slick-carousel class="sliderContent custom-slick-arrow carousel mt-4" #slickModal="slick-carousel"
                    [config]="linksConfig">
                    <div class="image-with-del slide" *ngFor="let link of linkArray" ngxSlickItem>
                        <a href="{{ link.link }}" target="_blank">
                            <div class="mx-auto">
                                <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}"
                                    class="icon img-70 mx-auto">
                            </div>
                        </a>
                        <div class="dtl">
                            <h3>{{ link.title }}</h3>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>

        <div class="eachBlock">
            <div class="topHdg">
                <h3 class="hdgName">
                    Description
                </h3>
            </div>
            <div class="blockDes blockMission" [ngClass]="{ '': mamDescription, showDetailContent: !mamDescription}">
                <p class="truncatedText" innerHtml="{{ description | slice:0:400 }}"></p>
                <p class="fullText" innerHtml="{{ description }}"></p>
                <a *ngIf="(description + '').length > 400" href="javascript:void();" (click)="mamDescription = !mamDescription"
                    class="d-block text-center showMore">
                    <img src="assets/images/new-arrow.svg" alt="icon">
                </a>
            </div>
        </div>

    </div>

    <h3 class="timellineHdg">Timeline</h3>
        <div class="timeLineBlock">
            <div class="allTimeLinesOuter">
                <div class="allTimeLines scroll-activity scrollbar" *ngIf="timelines.length > 0">
                    <div *ngFor="let timeline of timelines" class="timeLinesEach">
                        <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                            <h3 class="cursor-pointer">{{timeline.title}}</h3>
                            <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                        </div>
                        <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                        <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                            <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                        </p>
                        <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                            <div class="imageBox">
                                <img alt="timeline" class="timeLineImg" 
                                src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                            </div>
                            <div class="dtlOuter">
                                <h1>{{timeline.metadata_title}}</h1>
                                <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                            </div>
                        </div>
                        <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                            <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                <a href="{{document.document}}" target="_blank">
                                    <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                    <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                </a>
                            </div>
                        </div>
                        <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                            <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                            <span class="postedUserName">{{timeline.creator_info.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="timeLineLoad" *ngIf="timelineNextPage == 1 && totalTimelines > 3 && totalTimelines > timelines.length">
                    <a href="javascript:void(0);" (click)="loadMore()">Load More</a>
                </div>
                <div class="timeLineLoad" *ngIf="timelineNextPage > 1 && totalTimelines > 10 && totalTimelines > timelines.length">
                    <a href="javascript:void(0);" (click)="loadMore()">Load More</a>
                </div>

            </div>
        </div>
</div>
<ng-template #timelineDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Timeline detail</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="timeLinesEach">
            <div class="timelineTopHdr">
                <h3>{{editTimelineObj.title}}</h3>
                <p>Posted on {{editTimelineObj.created_date | date:'MMMM d, y'}}</p>
            </div>
            <p class="timelineDes" innerHtml="{{editTimelineObj.description}}"></p>
            <p class="metaLink" *ngIf="!checkExtraData(editTimelineObj)">
                <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
            </p>
            <div class="singleTimelineDetail" *ngIf="checkExtraData(editTimelineObj)">
                <div class="imageBox">
                    <img alt="timeline" class="timeLineImg" 
                    src="{{editTimelineObj.metadata_image_link? editTimelineObj.metadata_image_link : 'assets/images/default.svg'}}" />
                </div>
                <div class="dtlOuter">
                    <h1>{{editTimelineObj.metadata_title}}</h1>
                    <p>{{editTimelineObj.metadata_description}}</p>
                    <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
                </div>
            </div>
            <div class="allTimeImages" *ngIf="editTimelineObj.timeline_documents.length > 0">
                <div class="imageOuter" *ngFor="let document of editTimelineObj.timeline_documents">
                    <a href="{{document.document}}" target="_blank">
                        <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                        <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
    </div>
</ng-template>
