import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseInitiativeEndPoint } from '../app.config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService {
  private baseInitiativeUrl: string = BaseInitiativeEndPoint.apiEndpoint;
  constructor(private http: BaseService) { }

  addInitiative(initiative: any) {
    return this.http.post(this.baseInitiativeUrl + 'add/', initiative);
  }

  getSdks() {
    return this.http.get(this.baseInitiativeUrl + 'get-sdks/');
  }

  getInitiatives(id: string) {
    return this.http.get(this.baseInitiativeUrl + 'get/' + id + '/');
  }

  saveMissionStatement(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'mission-statement/' + id + '/', data);
  }

  saveWhatWeImpact(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'what-we-impact/' + id + '/', data);
  }

  saveGoalAndPlan(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update/goalplans/' + id + '/', data);
  }

  updateInitiative(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update-initiative/' + id + '/', data);
  }

  addSupporter(data: any) {
    return this.http.post(this.baseInitiativeUrl + 'add-who-support-us/', data);
  }

  getSupporter(id: string){
    return this.http.get(this.baseInitiativeUrl + 'get-supporters/' + id + '/');
  }

  updateSupporter(data: any, id: string) {
    return this.http.put(this.baseInitiativeUrl + 'update/who-support-us/' + id + '/', data);
  }

  addMeasurables(data: any) {
    return this.http.post(this.baseInitiativeUrl + 'add/mesurable/', data);
  }

  getMeasurable(id: string){
    return this.http.get(this.baseInitiativeUrl + 'get-measurables/' + id + '/');
  }

  updateMeasurable(data: any, id: string) {
    return this.http.put(this.baseInitiativeUrl + 'update/mesurable/' + id + '/', data);
  }

  editInitiativedetails(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update/detail/initiative/' + id + '/', data);
  }

  deleteInitiative(id: string){
    return this.http.delete(this.baseInitiativeUrl + 'update/detail/initiative/' + id + '/');
  }

  deleteSupporter(id: string) {
    return this.http.delete(this.baseInitiativeUrl + 'update/who-support-us/' + id + '/');
  }

  deleteMeasurable(id: string) {
    return this.http.delete(this.baseInitiativeUrl + 'update/mesurable/' + id + '/');
  }

  addLink(data: any){
    return this.http.post(this.baseInitiativeUrl + 'add/link/', data);
  }

  getLinks(id: string){
    return this.http.get(this.baseInitiativeUrl + 'get-links/' + id + '/');
  }

  getLinksMam(id: string){
    return this.http.get(this.baseInitiativeUrl + 'get-links-mam/' + id + '/');
  }

  deleteLink(id: string) {
    return this.http.delete(this.baseInitiativeUrl + 'update/link/' + id + '/');
  }

  addSDG(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update/sdg/' + id + '/', data);
  }

  getUserInitiatives(){
    return this.http.get(this.baseInitiativeUrl + 'list-initiative/');
  }

  getOtherInitiatives(id: string){
    return this.http.get(this.baseInitiativeUrl + 'list-initiatives/' + id + '/');
  }

  removeHeaderImage(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'header-image/' + id + '/', data);
  }

  reportInitiative(reported_initiative:any){
    return this.http.post(this.baseInitiativeUrl + 'report-initiative/', reported_initiative);
  }

  searchInitiative(id: string){
    return this.http.get(this.baseInitiativeUrl + 'search-initiative/' + id + '/');
  }

  tagInitiative(data: any){
    return this.http.post(this.baseInitiativeUrl + 'add-position/', data);
  }

  removeTagInitiative(id: string){
    return this.http.delete(this.baseInitiativeUrl + 'remove-tag/' + id + '/');
  }

  addTimeline(data: any) {
    return this.http.post(this.baseInitiativeUrl + 'add-timeline/', data);
  }

  recentMamTimeline(id: string){
    return this.http.get(this.baseInitiativeUrl + 'mam/timeline/recent/' + id + '/');
  }

  mamTimeline(id: string, params: any){
    return this.http.get(this.baseInitiativeUrl + 'mam/timeline/' + id + '/' + params);
  }

  updateTimeline(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update-timeline/' + id + '/', data);
  }

  deleteTimeline(id: string) {
    return this.http.delete(this.baseInitiativeUrl + 'update-timeline/' + id + '/');
  }
  
  getTeam(id: string, params: any){
    return this.http.get(this.baseInitiativeUrl + 'get-user/' + id + '/' + params);
  }

  addVolunteers(data: any){
    return this.http.post(this.baseInitiativeUrl + 'add-volunteer/', data)
  }

  getVolunteers(id: string){
    return this.http.get(this.baseInitiativeUrl + 'get-volunteer/' + id + '/')
  }

  deleteVolunteer(id: string){
    return this.http.delete(this.baseInitiativeUrl + 'delete-volunteer/' + id + '/');
  }

  getIniVolunteer(id: string, params: any){
    return this.http.get(this.baseInitiativeUrl + 'list-volunteer-posting/' + id + '/' + params);
  }

  addIniVolunteer(data: any){
    return this.http.post(this.baseInitiativeUrl + 'add-volunteer-posting/', data)
  }

  updateIniVolunteer(data: any, id: string){
    return this.http.put(this.baseInitiativeUrl + 'update-volunteer-posting/' + id + '/', data);
  }

  deleteIniVolunteer(id: string){
    return this.http.delete(this.baseInitiativeUrl + 'update-volunteer-posting/' + id + '/');
  }

  getAllIniTimeline(id: string, params: any){
    return this.http.get(this.baseInitiativeUrl + 'all-timeline/' + id + '/' + params);
  }

  tagInitiativeSchool(id: string, data: any,){
    return this.http.put(this.baseInitiativeUrl + 'tag-school/' + id + '/', data);
  }

}
