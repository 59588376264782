import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InitiativeService } from '../../services/initiative.service';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-edit-mam',
  templateUrl: './edit-mam.component.html',
  styleUrls: ['./edit-mam.component.scss']
})
export class EditMamComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("timelineInp") timelineInp: ElementRef;
  public mamDescription = true;
  modalRef: BsModalRef;
  public Editor = ClassicEditor;
  mamId: string;
  user: any;
  location: any;
  title: any;
  start_date: any;
  end_date: any;
  display_image: any;
  description: any;
  descriptionForm: FormGroup;
  submitted: boolean;
  disableSubmit: boolean;
  editDetailForm: FormGroup;
  isEndDate: boolean = false;
  uploadForm: FormGroup;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage: string;
  coverImageUploadSizeMessage: string;
  previewThumbnail: any;
  previewThumbnailName: any;
  role: any;
  header_image: any;
  linkForm: FormGroup;
  linkArray = [];
  delError: boolean;
  isHeaderToDel: boolean;
  imageChangedEvent: any;
  croppedImage: any;
  fileUploadError: any;
  fileUploadSizeError: any;
  profileImgSubmit: any;
  fileUploadSizeMessage: string;
  fileUploadMessage: string;
  display_image_delete: boolean;
  summary: any;
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  timelineUpdForm: FormGroup;
  timelines: any = [];
  timelineDocs: any = [];
  editTimelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;

  constructor(private datePipe: DatePipe, private modalService: BsModalService, private initiativeService: InitiativeService,
    private profileeService: ProfileService, private customValidator: CustomvalidationService, private router: Router, public formBuilder: FormBuilder) {
    var url = document.URL.split('/');
    this.mamId = url[url.length - 1];
    this.descriptionForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    });
    this.editDetailForm = this.formBuilder.group({
      'title': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'role': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'location': new FormControl('', [Validators.required, Validators.maxLength(75)]),
      'start_date': new FormControl(''),
      'end_date': new FormControl(''),
      'summary': new FormControl('', [Validators.maxLength(200)]),
    }, { validators: this.dateValidator });
    this.linkForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      link: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required]),
      thumbnail: [''],
      thumbnail_name: ['']
    });
    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelineUpdForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)])
    });
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }
    this.profileeService.getMoreAboutMeDetail(this.mamId)
    .subscribe(response => {
      let res = response.response;
      if (res.user !== this.user.id){
        this.router.navigate(['/dashboard']);
      }
      this.location = res.location;
      this.title = res.title;
      this.role = res.role;
      this.start_date = res.start_date;
      this.end_date = res.end_date;
      this.display_image = res.thumbnail;
      this.header_image = res.header_image;
      this.description = res.description;
      this.summary = res.summary;
      this.getLink();
    });
    this.initiativeService.recentMamTimeline(this.mamId)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        this.timelines = response.response.results;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
      }
    });
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0, 0, 0, 0);
      end_date = new Date(end_date).setHours(0, 0, 0, 0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }
    else if (!start_date && end_date){
      return { 'startDateNA' : true}
    }
    return null;
  }

  endDate(e){
    const key = e.target.value;
    if(key == "1"){
      this.isEndDate = true;
    }else{
      this.isEndDate = false;
      this.editDetailForm.get('end_date').clearValidators();
      this.editDetailForm.get('end_date').updateValueAndValidity();
      this.editDetailForm.controls['end_date'].setValue(null);
    }
  }

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  linkModal(linkTemplate: TemplateRef<any>) {
    this.linkForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(linkTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  blankLinkImageUpload(element){
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  editTimelineModal(editTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.timelineUpdForm.reset();
    this.editTimelineObj = timeline;
    this.timelineUpdForm.controls['description'].setValue(timeline.description);
    this.modalRef = this.modalService.show(editTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTimelineModal(delTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(delTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getLink(){
    this.initiativeService.getLinksMam(this.mamId)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  addLink(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    let link = this.linkForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
    if (isValid){
      let model = {
        'mam': this.mamId,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addLink(model)
        .subscribe(response => {
          this.handleAddLinkResponse(response);
        }, error =>{
          this.handleAddLinkSubmitError(error.error)
        })
    }
  }

  protected handleAddLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleAddLinkSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.linkArray.push(response.response);
      this.linkForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleAddLinkSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.linkForm.contains(field)) {
        this.linkForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  desModal(desTemplate: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.descriptionForm.controls['description'].setValue(this.description);
    this.modalRef = this.modalService.show(desTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  deleteBannerModal(deleteBannerTemplate: TemplateRef<any>) {
    this.delError = false;
    if (this.header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.modalRef = this.modalService.show(deleteBannerTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addTimelineLinkModal(addTimelineLink: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addTimelineLink,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  removeHeader(){
    let model = {"header_img": "true"}
    this.profileeService.removeMamHeader(model, this.mamId)
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.modalRef.hide();
        if (this.isHeaderToDel) {
          this.header_image = null;
        } else {
          this.header_image = null;
        }
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
   }

  editImagetModal(editImageTemplate: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.profileImgSubmit = false;
    this.modalRef = this.modalService.show(editImageTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  addImage(isValid: boolean) {
    this.profileImgSubmit = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'header_image': this.uploadForm.get('document').value,
        'header_image_name': this.uploadForm.get('document_name').value
      }
      this.profileeService.updateMamHeader(model, this.mamId)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.profileImgSubmit = false;
      this.disableSubmit = false;
      this.header_image = response.response.header_image;
      if (this.header_image){
        this.header_image = this.header_image;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  config = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal',
  };

  updateMAM(updateMAMTemplate: TemplateRef<any>) {
    this.editDetailForm.reset();
    this.uploadForm.reset();
    this.display_image_delete = false;
    this.editDetailForm.controls['title'].setValue(this.title);
    this.editDetailForm.controls['role'].setValue(this.role);
    this.editDetailForm.controls['location'].setValue(this.location);
    this.editDetailForm.controls['start_date'].setValue(this.start_date);
    this.editDetailForm.controls['summary'].setValue(this.summary);
    if (this.start_date && this.end_date == null){
      this.isEndDate = false;
    }
    else{
      this.isEndDate = true;
      this.editDetailForm.controls['end_date'].setValue(this.end_date);
    }
    this.previewThumbnail = this.display_image;
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(updateMAMTemplate,
      this.config
      // Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  saveDescription(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      let model = {
        'id': this.mamId,
        'description': this.descriptionForm.get('description').value
      }
      this.profileeService.saveMamDescription(model, this.mamId)
        .subscribe(response => {
          this.handleMaMDescResponse(response);
        }, error =>{
          this.handleMaMDescSubmitError(error.error)
        })
    }
  }

  deleteDescription(){
    let model = {
      'id': this.mamId,
      'description': ""
    }
    this.profileeService.saveMamDescription(model, this.mamId)
    .subscribe(response => {
      this.handleMaMDescResponse(response);
    }, error =>{
      this.handleMaMDescSubmitError(error.error)
    })
  }

  protected handleMaMDescResponse(response: any) {
    if (response.status === 111) {
      this.handleMaMDescSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.description = response.response.description;
      this.submitted = false;
    }
  }

  protected handleMaMDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  resetEndDate(){
    this.editDetailForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.editDetailForm.controls['start_date'].setValue(null);
  }

  updateDetail(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      const prev_thumbnail_name = this.uploadForm.get('thumbnail_name').value;
      let thumbnail_name = this.display_image;
      if (prev_thumbnail_name && prev_thumbnail_name.length > 0) {
        thumbnail_name = prev_thumbnail_name;
      }
      if (this.display_image_delete){
        thumbnail_name = "";
      }
      let start_date = this.datePipe.transform(this.editDetailForm.get('start_date').value,"yyyy-MM-dd");
      let end_Date = this.datePipe.transform(this.editDetailForm.get('end_date').value,"yyyy-MM-dd");
      let model = {
        'title': this.editDetailForm.get('title').value,
        'role': this.editDetailForm.get('role').value,
        'location': this.editDetailForm.get('location').value,
        'summary': this.editDetailForm.get('summary').value,
        'start_date': start_date,
        'end_date': end_Date,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': thumbnail_name
      }
      this.profileeService.updateMamdetail(model, this.mamId)
      .subscribe(response => {
        this.handleEditDetailResponse(response);
      }, error => {
        this.handleEditDetailSubmitError(error.error)
      })
    }
  }

  protected handleEditDetailResponse(response: any) {
    if (response.status === 111) {
      this.handleEditDetailSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.title = response.response.title;
      this.location = response.response.location;
      this.role = response.response.role;
      this.start_date = response.response.start_date;
      this.end_date = response.response.end_date;
      this.display_image = response.response.thumbnail;
      this.summary = response.response.summary;
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleEditDetailSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.editDetailForm.contains(field)) {
        this.editDetailForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  returnToProfile(){
    this.router.navigate(['/profile']);
  }

  blankProfileImage(element){
    element.value = '';
    this.uploadForm.reset();
    this.display_image_delete = true;
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  coverImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 2000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 2MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnail = reader.result;
          this.previewThumbnailName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
          this.display_image_delete = false;
        };
      }
    }
  }

  delLink(id:any){
    var id = id;
    this.initiativeService.deleteLink(id)
    .subscribe(response => {
      let index = this.linkArray.findIndex(obj => obj.id === id)
      this.linkArray.splice(index, 1)
    })
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'mam': this.mamId,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'date': null
      }
      this.initiativeService.addTimeline(model)
        .subscribe(response => {
          this.timelineDocs = [];
          if (response.status === 112) {
            this.timelineUpdForm.get('description').setErrors({aftersubmit: response.message});
          } else if (response.status === 200) {
            this.timelines.unshift(response.response);
            this.timelineForm.reset();
            this.timelinelinkForm.reset();
            this.submitted = false;
          } else {
            this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
          }
        }, error => {
          this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
          this.timelineDocs = [];
        })
    }
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.modalRef.hide();
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  updateTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {
        'description': this.timelineUpdForm.get('description').value
      }
      this.initiativeService.updateTimeline(model, this.editTimelineObj.id)
        .subscribe(response => {
          this.handleupdResponse(response);
        }, error => {
          this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
        })
    }
  }

  protected handleupdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      let index = this.timelines.findIndex(obj => obj.id === response.response.id);
      this.timelines[index].description = response.response.description;
      this.timelineUpdForm.reset();
      this.editTimelineObj = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  deleteTimeline(){
    this.initiativeService.deleteTimeline(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.timelines.findIndex(obj => obj.id === this.editTimelineObj.id);
      this.timelines.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  loadMore(){
    let params = '?page=' + this.timelineNextPage;
    this.initiativeService.mamTimeline(this.mamId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      }
    });
  }

  uploadTimelineDoc(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true;
        this.coverImageUploadMessage = "Only jpg, png and pdf files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.coverImageUploadSizeError = true;
        this.coverImageUploadSizeMessage = "Image/Document size should be less than 5MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          let timelineDoc = {'previewImg': reader.result, 'document': base64String, 'document_name': file_name};
          this.timelineDocs.push(timelineDoc);
          if (this.timelineDocs.length < 5) {
            this.timelineInp.nativeElement.value = "";
          }
        };
      }
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

}
