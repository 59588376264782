<section class="profile-head newPageShow newProfile">
<div class="tab-content-box">
    <app-profile-header></app-profile-header>
    <div class="tabWrap">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12">
                    <ul class="tabBtns no-list profile-tabs">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                          }" (click)="tabActive=2" *ngIf="user.user_role == '2'"> Experiences </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                          }" (click)="tabActive=1" *ngIf="user.user_role == '2'"> canvas </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 3
                          }" (click)="tabActive=3"> Resume </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 9
                          }" (click)="tabActive=9"> My Posts </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div class="">
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 1">
            <app-canvas></app-canvas>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 2">
            <app-experiences></app-experiences>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 3">
            <app-resume></app-resume>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 4">
            <app-profile-setting></app-profile-setting>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 5">
            <app-need-help></app-need-help>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 8">
            <app-experience-status></app-experience-status>
        </div>
        <div class="custom-tab-dark custom-tab-teal" *ngIf="tabActive == 9">
            <app-my-feeds></app-my-feeds>
        </div>
    </div>
</section>

<div class="overlay-edit">
</div>
<div class="onBoard-overlay noticeOverlay" *ngIf="user.age_group_updated">
    <div class="onBoard-div">
        <div class="noticeDiv" *ngIf="stepOne">
            <h3>CONGRATS, CONNECTUS.TODAY GRAD!</h3>
            <p>
                Every year, on April 21, we take all of the students who will be turning 14 within the year and graduate
                them to a Full-Access User (a.k.a. users who have full access to all features within our Platform). We
                have noticed that you are turning 14 years old this year, so you, too, will be able to use the full
                site!

            </p>
            <p>
                Before using ConnectUs.today as a Full-Access User, we must ask that you Read and Accept the Terms of
                Use and Privacy Policy again with a Parent/Guardian, so that you understand how your Terms and Privacy
                as a user is changing.
            </p>
            <div class="text-center">
                <button class="common-btn" (click)="showTwo();">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepTwo">
            <h3>TERMS OF USE / PRIVACY POLICY CLICKWRAP</h3>
            <p>
                Please Read and Accept the following Terms of Use and Privacy Policy with a Parent/Guardian, so that you
                understand how your Terms and Privacy as a user is changing. If you do not Accept, you will only have
                access to Account Settings where you may either Delete your Account or Read through the Terms and
                Privacy Policy to Accept.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="showThree();">Logout</button>
                <button class="common-btn" (click)="submitTermsAcceptForm()">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepThree">
            <h3>YOU ARE ABOUT TO LOG OUT!</h3>
            <h3 class="text-left">PLEASE NOTE</h3>
            <p>
                If you Log Out, you understand that you will only have Access to your Account Settings to either Review
                and Accept the Terms of Use and Privacy Policy, or to Delete your Account.</p>
            <h3 class="text-left">
                YOU WILL NOT HAVE ACCESS TO THE REST OF YOUR ACCOUNT UNTIL YOU ACCEPT THE TERMS OF USE AND PRIVACY
                POLICY.
            </h3>
            <p>
                Click Log Out to continue, or click the Back button to return to the previous screen.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="goBack()">Go Back</button>
                <button class="common-btn" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>

<div class="profile-modal-step1">
    <a href="javascript:void(0);" class="modal-logo">
        <img src="/assets/images/modal-logo.svg" alt="logo" />
    </a>
    <p class="bold">Welcome to ConnectUs, {{user.first_name}}!</p>
    <p class="text-center">Whatever you're here for, we're genuinely excited to be a part of your next steps.</p>
    <p class="text-center">Your experiences are what will help you learn your interests, build skills, grow your passions.</p>
    <p class="text-center">Remember: everyone starts somewhere. Nobody knows exactly where they'll end up. So... start today!</p>
    <button type="button" class="next-btn" (click)="step2Event()">Next</button>
</div>

<div class="profile-modal-step1 step2">
    <a href="javascript:void(0);" class="modal-logo">
        <img src="/assets/images/modal-logo.svg" alt="logo" />
    </a>
    <p class="bold">We'll walk you through 3 steps to start finding, creating, and showcasing:</p>
    <p><span>STEP1</span> Add your school to your profile</p>
    <p><span>STEP2</span> Add one school club or team to your profile (if you have one!)</p>
    <p><span>STEP3</span> Check out the 'Your School' feed to see everything going on, or to post!</p>
    <button type="button" class="next-btn" (click)="step3Event()">Next</button>
</div>
<div class="profile-modal-step1 step3">
    <a href="javascript:void(0);" class="modal-logo">
        <img src="/assets/images/modal-logo.svg" alt="logo" />
    </a>
    <p class="bold">Here's an example of a post. This is what you'll find:</p>
    <img src="/assets/images/OnboardImage.png" class="banner" alt="img" />
    <button type="button" class="next-btn" (click)="step4Event()">Next</button>
</div>
