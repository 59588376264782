<section class="profile-head newPageShow newProfile">

<div class="tab-content-box">
        <div class="fullBanner">
                <div class="forImage">
                    <img src="{{profileHeader ? profileHeader : 'assets/images/new-header-img.png'}}"
                        (click)="showImg(headerImages);" class="bannerImg" [ngClass]="{'cursor-pointer': profileHeader}"
                        alt="header-img">
                </div>
        
                <div class="forDetail">
                    <div class="container custom-container max-890">
                        <div class="row">
                            <div class="col-12 center-wrap">
                                <div class="withProfile">
                                    <div class="forEdit mr-auto">
                                        <form novalidate="" class="formImg ng-untouched ng-pristine ng-valid">
                                            <img *ngIf="profileImage_name" src="{{profileImage}}" class="profileImg"
                                                alt="header-img">
                                            <img *ngIf="!profileImage_name" src="assets/images/p-img.png" class="profileImg"
                                                alt="header-img">
                                        </form>
                                    </div>
                                    <div class="btn-box">
                                        <button *ngIf="links.length > 0" type="button" class="contact-info-btn border-0 bg-transparent" (click)="openNewContactusModal(contactListingtemplate)">
                                            <img src="/assets/images/contact-info-btn.svg" alt="btn" />
                                        </button>
                                        <div class="btn-group" dropdown *ngIf="!viewMode">
                                            <button id="button-basic" dropdownToggle type="button" class="three-dots-btn" aria-controls="dropdown-basic">
                                                <img src="/assets/images/three-dots-btn.svg" alt="dots" />
                                            </button>
                                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box school-bucket-dropdown ViewDropdown"
                                                role="menu" aria-labelledby="button-basic">
                                                <li role="menuitem">
                                                    <div class="flag-data-box" (click)="openSetting(settingModal)">
                                                        <img class="cursor-pointer edit-icon" src="/assets/images/settings-teal.svg" alt="flag" />
                                                        <span class="cursor-pointer">Settings</span>
                                                    </div>
                                                </li>
                                                <li role="menuitem"
                                                    *ngIf="user.user_role == '2' && user.age_group > 1 && loggedInUser.user_role == '2' && loggedInUser.age_group > 1">
                                                    <div class="flag-data-box" *ngIf="isUserBlocked" (click)="openMsgModal(noMessage)">
                                                        <img class="cursor-pointer edit-icon" src="/assets/images/messageIcon-teal.svg" alt="flag" />
                                                        <span class="cursor-pointer">Message</span>
                                                    </div>
                                                    <div class="flag-data-box" *ngIf="!isUserBlocked" (click)="sendMessage()">
                                                        <img class="cursor-pointer edit-icon" src="/assets/images/messageIcon-teal.svg" alt="flag" />
                                                        <span class="cursor-pointer">Message</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="profileList">
                                    <h3 class="withDotText">
                                        {{user.first_name}} {{user.last_name}}
                                        <span class="forDotName" *ngIf="!viewMode">
                                            <span *ngIf="isFirst" class="forDot"></span>
                                            <span *ngIf="isSecond" class="forDot"></span>
                                            <span *ngIf="isFirst" (click)="bannerModal(firstModal)" class="forName cursor-pointer">1st</span>
                                            <span *ngIf="isSecond" (click)="bannerModal(secondModal)" class="forName ml-2 cursor-pointer">2nd</span>
                                        </span>
                                    </h3>
                                    <div class="withAddNet">
                                        <p (click)="openVerifiersModal(verification)"
                                            *ngIf="verifiers.length >= 3 || user.approval_status"
                                            class="d-inline-flex align-items-center cursor-pointer">
                                            <span>
                                                <img alt="image" src="assets/images/verifyProfile.svg" alt="">
                                            </span> Verified
                                        </p>
        
                                        <p class="addNetWrap" *ngIf="!viewMode && canAdd">
                                            <img src="assets/images/verifiedUserActive.svg" alt="" />
                                            <span (click)="openNetworkModal(networkModal)" class="cursor-pointer">Add to Network</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="tabWrap">
                <div class="container custom-container max-890">
                    <div class="row">
                        <div class="col-12 col-md-9">
                            <ul class="tabBtns no-list">
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 2
                                  }" (click)="tabActive=2"> Experiences </li>
                                  <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 1
                                  }" (click)="tabActive=1"> canvas </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
</div>
    <div class="custom-tab-dark custom-tab-teal">
        <div *ngIf="tabActive == 1">
            <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3 pt-0">
                <div class="topLinks">
                    <div class="content-section" *ngIf="hasData">
                        <div class="content-profile-white-box" *ngIf="profile.description">
                                <div class="titleDiv d-flex align-items-center" *ngIf="profile.description">
                                        <h3>
                                            Biography
                                        </h3>
                                    </div>
                                    <div class="detailContent bioDesc pl-0" *ngIf="profile.description"
                                        [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                                        <p (click)="openModal(editDescriptionTemplate)"
                                            [innerHtml]="profile.description | slice:0:1000"></p>
                                        <a *ngIf="(profile.description + '').length > 1000" href="javascript:void();"
                                            (click)="infoDiv1 = !infoDiv1" class="d-block text-center mt-2">
                                            <img src="assets/images/new-arrow.svg" alt="icon">
                                        </a>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="MamList && MamList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="MamList && MamList.length > 0">
                                        <h3>
                                            more about me
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="MamList && MamList.length > 0">
                                        <ngx-slick-carousel class="sliderContent carousel mt-4" #slickModal="slick-carousel"
                                            [config]="mamSlider">
                                            <div class="mam-slider slide" ngxSlickItem *ngFor="let mam of MamList">
                                                <div class="mam-wrap cursor-pointer" routerLink="/mam/{{mam.id}}/">
                                                    <div class="mx-auto">
                                                        <img src="{{mam.thumbnail ? mam.thumbnail :'assets/images/default.svg'}}"
                                                            class="icon">
                                                    </div>
                                                            <div class="custom-tooltip copy-tooltip new">
                                                                    <h3 class="text-capitalize">{{ mam.title }}</h3>
                                                                    <div class="tooltip-data text-capitalize">{{ mam.title }}</div>
                                                            </div>       
                                                    </div>
                                                </div>
                                        </ngx-slick-carousel>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="user.user_role == '2' && skills?.length > 0">
                                <div class="titleDiv d-flex align-items-center"
                                *ngIf="user.user_role == '2' && skills?.length > 0">
                                <h3 class="with-list-icon">
                                    Skills
                                </h3>
                            </div>
                            <div class="detailContent" *ngIf="user.user_role == '2' && skills?.length > 0">
                                <ul class="no-list awardsList withSkills" *ngIf="skills?.length > 0">
                                    <li *ngFor="let skill of skills">
                                        <div class="awardsDataLeft" (click)="updateSkillModal(editSkillpopup, skill)">
                                            <p class="name m-0 mr-2 cursor-pointer">{{ skill.title }}</p>
                                            <div class="forDotsImages">
                                                <span *ngIf="skill?.user_link && skill?.user_link.length > 0">
                                                    <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                                    </span>
                                                    <span *ngIf="skill?.user_document && skill?.user_document.length > 0">
                                                        <img alt="image" src="assets/images/awardsFileIcon.svg">
                                                    </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                <div class="titleDiv d-flex align-items-center"
                                *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                <h3>
                                    Goals & bucket list
                                </h3>
                            </div>
                            <div class="detailContent mb-2" *ngIf="goalsBucketList && goalsBucketList.length > 0">
                                <div [ngClass]="{ '': goalsBucket, showDetailContent: !goalsBucket}">
                                    <div class="truncatedText">
                                        <ul class="dots-list m-0 truncatedText">
                                            <li *ngFor="let goal of goalsBucketList | slice:0:6">{{ goal.title }}</li>
                                        </ul>
                                    </div>
                                    <div class="fullText">
                                        <ul class="dots-list m-0">
                                            <li *ngFor="let goal of goalsBucketList">{{ goal.title }}</li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void();" *ngIf="goalsBucketList && goalsBucketList.length > 6"
                                        (click)="goalsBucket = !goalsBucket" class="d-block text-center showMore">
                                        <img src="assets/images/new-arrow.svg" alt="icon">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="user.user_role == '2' && interests?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="user.user_role == '2' && interests?.length > 0">
                                        <h3 class="with-list-icon">
                                            Interests
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="user.user_role == '2' && interests?.length > 0">
                                        <ul class="no-list awardsList withSkills" *ngIf="interests?.length > 0">
                                            <li *ngFor="let interest of interests">
                                                <div class="awardsDataLeft" (click)="updateInterestModal(editInterestpopup, interest)">
                                                    <p class="name m-0 mr-2 cursor-pointer">{{ interest.title }}</p>
                                                    <div class="forDotsImages">
                                                        <span *ngIf="interest.user_link && interest?.user_link.length > 0">
                                                            <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                                            </span>
                                                            <span *ngIf="interest?.user_document && interest?.user_document.length > 0">
                                                                <img alt="image" src="assets/images/awardsFileIcon.svg">
                                                            </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="bulletins?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="bulletins?.length > 0">
                                        <h3>
                                            Bulletin board
                                        </h3>
                                    </div>
            
                                    <div class="detailContent pl-3 pr-3" *ngIf="bulletins?.length > 0">
                                        <div class="w-100">
                                            <ngx-slick-carousel class="box-div library-div carousel bulletinBoardSlides"
                                                #slickModal="slick-carousel" [config]="slideConfig">
                                                <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                                                    (click)="updatebulletinModal(bulletinDetail, bulletin)">
                                                    <div *ngIf="bulletin.document" class="img-div">
                                                        <img *ngIf="bulletin.thumbnail" src="{{ bulletin.thumbnail }}">
                                                        <img *ngIf="!bulletin.thumbnail && isImage(bulletin.document_name)"
                                                            src="{{bulletin.document}}">
                                                        <img *ngIf="!isImage(bulletin.document_name) && !bulletin.thumbnail"
                                                            src="assets/images/pdf-icon.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document && bulletin.thumbnail" class="img-div">
                                                        <img src="{{ bulletin.thumbnail }}">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document && !bulletin.thumbnail" class="img-div">
                                                        <img src="assets/images/default.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="books?.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="books?.length > 0">
                                        <h3 class="with-list-icon">
                                            books
                                        </h3>
                                    </div>
            
                                    <div class="detailContent" *ngIf="books?.length > 0">
                                        <div class="topRecent" *ngIf="books?.length > 0">
                                            <div class="media" (click)="updatebookModal(booktemplate, books[0])">
                                                <img class="mainTopImg bookTopImage"
                                                    src="{{books[0]?.thumbnail ? books[0].thumbnail : 'assets/images/default.svg'}}"
                                                    alt="Book Cover">
                                                <div class="media-body ml-3">
                                                    <h4>
                                                        {{ books[0]?.title }}
                                                    </h4>
                                                    <p>{{ books[0].author }}</p>
                                                    <div>
                                                        <ul *ngIf="books[0].rating"
                                                            class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                            <ngb-rating [(rate)]="books[0].rating" [readonly]="true" [max]="5">
                                                                <ng-template let-fill="fill" let-index="index">
                                                                    <span class="star" [class.filled]="fill === 100"
                                                                        [class.bad]="index < 3"></span>
                                                                </ng-template>
                                                            </ngb-rating>
                                                        </ul>
                                                    </div>
                                                    <p class="text-ellipse">
                                                        {{ books[0].description }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ngx-slick-carousel *ngIf="books?.length > 1" class="sliderContent carousel mt-4"
                                            #slickModal="slick-carousel" [config]="slideBooks">
                                            <div *ngFor="let book of books | slice:1" class=" slide" ngxSlickItem>
                                                <div class="media" (click)="updatebookModal(booktemplate, book)">
                                                    <img src="{{book.thumbnail ? book.thumbnail : 'assets/images/default.svg'}}"
                                                        alt="Book Cover" class="booksThumb">
                                                    <div class="media-body ml-3">
                                                        <h4>
                                                            {{ book.title }}
                                                        </h4>
                                                        <p>{{ book.author }}</p>
                                                        <div>
                                                            <ul *ngIf="book.rating"
                                                                class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                                <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                                                    <ng-template let-fill="fill" let-index="index">
                                                                        <span class="star" [class.filled]="fill === 100"
                                                                            [class.bad]="index < 3"></span>
                                                                    </ng-template>
                                                                </ngb-rating>
                                                            </ul>
                                                        </div>
                                                        <p class="text-ellipse">{{ book.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
            
                                        <div class="viewMoreWrapper" *ngIf="books?.length > 4">
                                            <span class="viewMore" (click)="booksListing(booksListModal)">
                                                View All ({{books?.length}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="favouriteList && favouriteList.length > 0">
                                <div class="titleDiv d-flex align-items-center"
                                *ngIf="favouriteList && favouriteList.length > 0">
                                <h3>
                                    favourites
                                </h3>
                            </div>
    
                            <div class="detailContent" *ngIf="favouriteList && favouriteList.length > 0">
                                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                    [config]="favouritesSlider">
                                    <div class="fav-slider slide" ngxSlickItem *ngFor="let fav of favouriteList">
                                        <div class="fav-wrap">
                                            <div class="img-outer">
                                                <img src="{{fav.thumbnail ? fav.thumbnail : 'assets/images/default.svg'}}"
                                                    class="icon mainTopImg mx-auto">
                                            </div>
                                            <div class="dtl">
                                                    <div class="custom-tooltip copy-tooltip new">
                                                            <h3>{{ fav.title }}</h3>
                                                            <div class="tooltip-data text-capitalize">{{ fav.title }}</div>
                                                    </div>
                                                <p>{{ fav.topic }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ngx-slick-carousel>
                            </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="learnMoreList && learnMoreList.length > 0">
                                <div class="titleDiv d-flex align-items-center"
                                *ngIf="learnMoreList && learnMoreList.length > 0">
                                <h3>
                                    i want to learn about
                                </h3>
                            </div>
    
                            <div class="detailContent mb-2" *ngIf="learnMoreList && learnMoreList.length > 0">
                                <div [ngClass]="{ '': learnAbout, showDetailContent: !learnAbout}">
                                    <div class="truncatedText">
                                        <ul class="dots-list m-0">
                                            <li *ngFor="let learning of learnMoreList | slice:0:6">
                                                {{ learning.title }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="fullText">
                                        <ul class="dots-list m-0">
                                            <li *ngFor="let learning of learnMoreList">
                                                {{ learning.title }}
                                            </li>
                                        </ul>
                                    </div>
    
                                    <a href="javascript:void();" *ngIf="learnMoreList && learnMoreList.length > 6"
                                        (click)="learnAbout = !learnAbout" class="d-block text-center showMore">
                                        <img src="assets/images/new-arrow.svg" alt="icon">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="timelineNoResult" *ngIf="!hasData">
                        <h5 class="m-0">No data exists.</h5>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="tabActive == 2">
            <div class="profileWhiteWrap  profileWrapSpace blueHdgRad pb-3">
                <div class="topLinks">
                    <div class="content-section" *ngIf="hasExpData">
                        <div class="content-profile-white-box" *ngIf="eduList && eduList.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="eduList && eduList.length > 0">
                                        <h3 class="with-list-icon">
                                            Education
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="eduList && eduList.length > 0">
                                        <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                            [config]="slideConfigExp">
                                            <div class=" slide" ngxSlickItem *ngFor="let eduObj of eduList">
                                                <div class="media">
                                                    <div>
                                                        <a routerLink="/school/{{ eduObj.school.id }}">
                                                            <img alt="image"
                                                                src="{{eduObj.school.display_picture? eduObj.school.display_picture : 'assets/images/default.svg'}}"
                                                                alt="Education Cover" class="thumb-img">
                                                        </a>

                                                    </div>
                                                    <div class="media-body ml-2">
                                                        <h4 (click)="updateEducationPopup(staticEduModal, eduObj)">{{eduObj.school.school_name}}</h4>
                                                        <p *ngIf="eduObj.majors">{{ eduObj.majors.join(', ') }}</p>
                                                        <p class="font14">{{eduObj.end_date | date:'yyyy' }}</p>
                                                        <p *ngIf="eduObj.interests">{{eduObj.interests.join(', ')}}</p>
                                                        <div class="add-detial-content flex-column">
                                                                <div class="addRefRow">
                                                                        <div class="addRefRowInner">
                                                                                <div class="expVerifyTeam mb-2" *ngIf="eduObj.team_members > 0">
                                                                                        <p class="my-0 people-number cursor-pointer" (click)="teamModal(eduObj, teamMemberTemplate)">
                                                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                            <span>{{eduObj.team_members}}</span>
                                                                                        </p>
                                                                                    </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="addRefRow" *ngIf="eduObj.add_to_team && !viewMode">
                                                                        <div class="addRefRowInner" *ngIf="eduObj.add_to_team == 5">
                                                                            <a href="javascript:void(0);" (click)="teamEdu(eduObj, addEduTeamModal);$event.stopPropagation()">
                                                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                <span>Add team members</span>
                                                                            </a>
                                                                        </div>
                                                                        <div class="addRefRowInner" *ngIf="eduObj.add_to_team == 1">
                                                                            <a href="javascript:void(0);">
                                                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                <span>Pending</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="myInitiatives && myInitiatives.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="myInitiatives && myInitiatives.length > 0">
                                        <h3 class="with-list-icon">
                                            initiative
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="myInitiatives && myInitiatives.length > 0">
                                        <div *ngIf="iniTeamError" class="error-message err-font mb-4 mt-0 text-center pb-2">Something went wrong. Please try again later.</div>
                                        <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                            [config]="sliderInitiative">
                                            <div class="slide" ngxSlickItem *ngFor="let initiative of myInitiatives">
                                                <div class="initiative-wrap pr-2">
                                                        <div class="img-60-circle mx-auto" (click)="jumpToInitiative(initiative)">
                                                        <img class="icon" src="{{initiative.display_pic ? initiative.display_pic : 'assets/images/default.svg'}}" />
                                                    </div>
                                                    <div class="dtl">
                                                        <h3 (click)="jumpToInitiative(initiative)">{{ initiative.page_info.title }}</h3>
                                                        <div class="expVerifyTeam" *ngIf="initiative.team_members > 0">
                                                            <p class="mb-2 mx-auto cursor-pointer people-number" (click)="iniTeamModal(initiative, iniTeamMemberTemplate)">
                                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                <span>{{initiative.team_members}}</span>
                                                            </p>
                                                        </div>
                                                        <div class="initiativeAddWrap" *ngIf="initiative.add_to_team && !viewMode">
                                                            <div class="addRefRow m-0">
                                                                <div class="addRefRowInner" *ngIf="initiative.add_to_team == 5">
                                                                    <a href="javascript:void(0);" (click)="addToIniTeam(initiative)">
                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                        <span>Add</span>
                                                                    </a>
                                                                </div>
                                                                <div class="addRefRowInner" *ngIf="initiative.add_to_team == 1">
                                                                    <a href="javascript:void(0);">
                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                        <span>Pending</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="usrWorkExperience && usrWorkExperience.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="usrWorkExperience && usrWorkExperience.length > 0">
                                        <h3 class="with-list-icon">
                                            Work
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="usrWorkExperience && usrWorkExperience.length > 0">
                                        <div class="single-image-wrap">
                                            <div class="media border-row cursor-pointer"
                                                *ngFor="let experience of usrWorkExperience | slice:0:3"
                                                (click)="updateExpModal(editExpTemplate, experience)">
                                                <div class="exp-verified">
                                                    <div class="exp-verified-Inner">
                                                        <img alt="image" class="thumb-img" (click)="redirectToPage(experience);$event.stopPropagation()"
                                                        src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                                    </div>
                                                    <!-- <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                        <p class="m-0">
                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                            <span>{{ experience.team_members.length }}</span>
                                                        </p>
                                                    </div> -->
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h3 class="top-name"><span>{{experience.position}}</span></h3>
                                                    <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
                                                    <h4 *ngIf="!experience.organization_title">Self-employed</h4>
                                                    <h5 *ngIf="experience.start_date != experience.end_date" class="start-end">
                                                        <span>{{experience.start_date | date: 'MMM y'}}</span>
                                                        <span> - </span>
                                                        <ng-template
                                                            [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                                            [ngIfElse]="currentTemp">
                                                            <span>{{experience.end_date | date: 'MMM y'}}</span>
                                                        </ng-template>
                                                    </h5>
                                                    <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                                        <strong>Date</strong>
                                                        <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                                                    </h5>
                                                    <p class="m-0"><strong>
                                                            <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                                            <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                                            <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                                            <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                                            <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                                        </strong></p>
                                                    <p class="m-0 text-ellipse">{{ experience.main_role }}</p>
                                                    <div class="add-detial-content">
                                                            <div class="addRefRow">
                                                                    <div class="addRefRowInner">
                                                                            <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                                                    <p class="my-0 people-number">
                                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                        <span>{{ experience.team_members.length }}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <p class="verifiedTextImg"
                                                                                *ngIf="experience.approver_name && experience.verified_date">
                                                                                <img alt="image" src="assets/images/verifyProfile.svg">
                                                                                </p>
                                                                    </div>
                                                            </div>
                                                            <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                                                <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                                                    <a href="javascript:void(0);" (click)="teamExpBusiness(experience, addProfileTeamModal);$event.stopPropagation()">
                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                        <span>Add team members</span>
                                                                    </a>
                                                                </div>
                                                                <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                                                    <img src="assets/images/verifiedUserActive.svg" class="student-icon" alt="icon">
                                                                    <span>Pending</span>
                                                                </div>
                                                            </div> 
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="viewMoreWrapper" *ngIf="usrWorkExperienceTotal > 3">
                                            <span class="viewMore" (click)="workExpListing(workExpListModal)">
                                               View All ({{usrWorkExperienceTotal}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="usrVolunteerWorkExperience && usrVolunteerWorkExperience.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="usrVolunteerWorkExperience && usrVolunteerWorkExperience.length > 0">
                                        <h3 class="with-list-icon">
                                            Volunteer
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="usrVolunteerWorkExperience && usrVolunteerWorkExperience.length > 0">
                                        <div class="single-image-wrap">
                                            <div class="media border-row cursor-pointer"
                                                *ngFor="let experience of usrVolunteerWorkExperience | slice:0:3"
                                                (click)="updateExpModal(editExpTemplate, experience)">
                                                <div class="exp-verified">
                                                    <div class="exp-verified-Inner">
                                                        <img alt="image" class="thumb-img" (click)="redirectToPage(experience);$event.stopPropagation()"
                                                        src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                                    </div>

                                                </div>
                                                <div class="media-body ml-3">
                                                    <h3 class="top-name">{{experience.position}}</h3>
                                                    <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
                                                    <h4 *ngIf="!experience.organization_title">Self-employed</h4>
                                                    <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                                                        <span>{{experience.start_date | date: 'MMM y'}}</span>
                                                        <span> - </span>
                                                        <ng-template
                                                            [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                                            [ngIfElse]="currentTemp">
                                                            <span>{{experience.end_date | date: 'MMM y'}}</span>
                                                        </ng-template>
                                                    </h5>
                                                    <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                                        <strong>Date</strong>
                                                        <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                                                    </h5>
                                                    <p class="m-0">
                                                        <strong>
                                                            <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                                            <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                                            <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                                            <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                                            <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                                        </strong>
                                                    </p>
                                                    <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                                                    <div class="add-detial-content">
                                                            <div class="addRefRow">
                                                                    <div class="addRefRowInner">
                                                                            <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                                                    <p class="my-0 people-number">
                                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                        <span>{{ experience.team_members.length }}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <p class="verifiedTextImg"
                                                                                *ngIf="experience.approver_name && experience.verified_date">
                                                                                <img alt="image" src="assets/images/verifyProfile.svg">
                                                                                </p>
                                                                    </div>
                                                                </div>
                                                                <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                                                    <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                                                        <a href="javascript:void(0);" (click)="teamExpBusiness(experience, addProfileTeamModal);$event.stopPropagation()">
                                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                            <span>Add team members</span>
                                                                        </a>
                                                                    </div>
                                                                    <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                                                        <img src="assets/images/verifiedUserActive.svg" class="student-icon" alt="icon">
                                                                        <span>Pending</span>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="viewMoreWrapper" *ngIf="usrVolunteerExperienceTotal > 3">
                                            <span class="viewMore" (click)="volunteerListing(volunteerListModal)">
                                                View All ({{usrVolunteerExperienceTotal}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="activities && activities.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="activities && activities.length > 0">
                                        <h3 class="with-list-icon">
                                            Extracurricular
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="activities && activities.length > 0">
                                        <div class="single-image-wrap">
                                            <div class="media border-row cursor-pointer"
                                                *ngFor="let activity of activities | slice:0:3"
                                                (click)="updateActivityModal(editActivityTemplate, activity)">
                                                <div class="exp-verified">
                                                    <div class="exp-verified-Inner">
                                                        <img alt="image" class="thumb-img" (click)="redirectToPage(activity);$event.stopPropagation()"
                                                        src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}">
                                                    </div>

                                                </div>
                                                <div class="media-body ml-3">
                                                    <h3 class="top-name">{{activity.position}}</h3>
                                                    <h4 *ngIf="activity.organization_title">{{activity.organization_title}}</h4>
                                                    <h4 *ngIf="!activity.organization_title">Self-employed</h4>
                                                    <h5 class="start-end" *ngIf="activity.start_date != activity.end_date">
                                                        <span>{{activity.start_date | date: 'MMM y'}}</span>
                                                        <span> - </span>
                                                        <ng-template
                                                            [ngIf]="activity.end_date && !(activity.verification_status == 1 && activity.approver_name)"
                                                            [ngIfElse]="currentTemp">
                                                            <span>{{activity.end_date | date: 'MMM y'}}</span>
                                                        </ng-template>
                                                    </h5>
                                                    <h5 class="start-end" *ngIf="activity.start_date == activity.end_date">
                                                        <strong>Date</strong>
                                                        <span class="ml-1">{{activity.start_date | date: 'MMMM d, y'}}</span>
                                                    </h5>
                                                    <p class="m-0">
                                                        <strong>
                                                            <span *ngIf="activity.skill_1">{{activity.skill_1}}</span>
                                                            <span *ngIf="activity.skill_2 && activity.skill_1">, </span>
                                                            <span *ngIf="activity.skill_2">{{activity.skill_2}}</span>
                                                            <span *ngIf="activity.skill_3  && (activity.skill_2 || activity.skill_1)">, </span>
                                                            <span *ngIf="activity.skill_3">{{activity.skill_3}}</span>
                                                        </strong>
                                                    </p>
                                                    <p class="m-0 text-ellipse">{{activity.main_role}}</p>
                                                    <div class="add-detial-content">
                                                            <div class="addRefRow">
                                                                    <div class="addRefRowInner">
                                                                            <div class="expVerifyTeam" *ngIf="activity.page && activity.team_members.length > 0">
                                                                                    <p class="my-0 people-number">
                                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                        <span>{{ activity.team_members.length }}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <p class="verifiedTextImg"
                                                                                *ngIf="activity.approver_name && activity.verified_date">
                                                                                <img alt="image" src="assets/images/verifyProfile.svg">
                                                                                </p>
                                                                    </div>
                                                                </div>
                                                                <div class="addRefRow" *ngIf="activity.add_to_team && !viewMode">
                                                                    <div class="addRefRowInner" *ngIf="activity.add_to_team == 5">
                                                                        <a href="javascript:void(0);" (click)="teamExpBusiness(activity, addProfileTeamModal);$event.stopPropagation()">
                                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                            <span>Add team members</span>
                                                                        </a>
                                                                    </div>
                                                                    <div class="addRefRowInner" *ngIf="activity.add_to_team == 1">
                                                                        <img src="assets/images/verifiedUserActive.svg" class="student-icon" alt="icon">
                                                                        <span>Pending</span>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="viewMoreWrapper" *ngIf="activitiesTotal > 3">
                                            <span class="viewMore" (click)="extraCurricularListing(extraCurricularListModal)">
                                               View All ({{activitiesTotal}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="schoolInvolvements && schoolInvolvements.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="schoolInvolvements && schoolInvolvements.length > 0">
                                        <h3 class="with-list-icon">
                                            School Involvement
                                        </h3>
                                    </div>
                                    <div class="detailContent mb-0" *ngIf="schoolInvolvements && schoolInvolvements.length > 0">
                                        <div class="single-image-wrap">
                                            <div class="media border-row cursor-pointer"
                                                *ngFor="let experience of schoolInvolvements | slice:0:3"
                                                (click)="updateSchoolInvolvementModal(schoolInvolvementTemplate, experience)">
                                                <div class="exp-verified">
                                                    <div class="exp-verified-Inner">
                                                        <img alt="image" class="thumb-img" (click)="redirectPage(experience);$event.stopPropagation()"
                                                        src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                                    </div>
                                                </div>
                                                <div class="media-body ml-3">
                                                    <h3 class="top-name">{{experience.position}}</h3>
                                                    <h4>{{experience.title}}</h4>
                                                    <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                                                        <span>{{experience.start_date | date: 'MMM y'}}</span>
                                                        <span> - </span>
                                                        <ng-template
                                                            [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                                            [ngIfElse]="currentTemp">
                                                            <span>{{experience.end_date | date: 'MMM y'}}</span>
                                                        </ng-template>
                                                    </h5>
                                                    <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                                        <strong>Date</strong>
                                                        <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                                                    </h5>
                                                    <p class="m-0">
                                                        <strong>
                                                            <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                                            <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                                            <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                                            <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                                            <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                                        </strong>
                                                    </p>
                                                    <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                                                    <div class="add-detial-content">
                                                            <div class="addRefRow">
                                                                    <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                                            <p class="my-0 people-number">
                                                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                                <span>{{ experience.team_members.length }}</span>
                                                                            </p>
                                                                        </div>
                                                                        <p class="verifiedTextImg"
                                                                        *ngIf="experience.approver_name && experience.verified_date">
                                                                        <img alt="image" src="assets/images/verifyProfile.svg">
                                                                        </p>
                                                            </div>
                                                            <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                                                <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                                                    <a href="javascript:void(0);" (click)="teamExpClubTeam(experience, addProfileTeamModal);$event.stopPropagation()">
                                                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                                        <span>Add team members</span>
                                                                    </a>
                                                                </div>
                                                                <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                                                    <img src="assets/images/verifiedUserActive.svg" class="student-icon" alt="icon">
                                                                    <span>Pending</span>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="viewMoreWrapper" *ngIf="schoolInvolvementsTotal > 3">
                                            <span class="viewMore" (click)="schoolInvolvementListing(schoolInvolvementListModal)">
                                               View All ({{schoolInvolvementsTotal}})
                                            </span>
                                        </div>
                                    </div>
                        </div>
                        <div class="content-profile-white-box" *ngIf="awards && awards.length > 0">
                                <div class="titleDiv d-flex align-items-center" *ngIf="awards && awards.length > 0">
                                        <h3 class="with-list-icon">Awards and Credentials
                                        </h3>
                                    </div>
                                    <div class="detailContent" *ngIf="awards && awards.length > 0">
                                        <ul class="no-list awardsList">
                                            <li *ngFor="let award of awards">
                                                <div class="awardsDataLeft">
                                                    <p class="name m-0 mr-2 cursor-pointer" (click)="viewModalAwards(viewAwardsTemplate, award)">{{award.title}}</p>
                                                </div>
                                                <div class="awardsDataRight">
                                                        <div class="forDotsImages">
                                                                <span *ngIf="award.link">
                                                                    <img alt="image" class="" src="assets/images/awardsLinkIcon.svg">
                                                                </span>
                                                                <span *ngIf="award.document">
                                                                    <img alt="image" class="" src="assets/images/awardsFileIcon.svg">
                                                                </span>
                                                            </div>
                                                    <p class="ml-0 my-0">{{award.year | date:'yyyy'}}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                        </div>
                    </div>
                    <div class="timelineNoResult" *ngIf="!hasExpData">
                        <h5 class="m-0">No data exists.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #verification>
    <div class="modal-header">
        <h4 class="modal-title pull-left">profile verifiers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="verifiers.length >= 3">
                <div class="verify-div" *ngFor="let verifier of verifiers">
                    <p>Verified by
                        <span>********@{{verifier.email.split('@')[1]}}</span> on
                        <span>{{verifier.verified_date | date: 'yyyy-MM-dd'}}</span>
                    </p>
                </div>
            </div>
            <div class="col-md-12" *ngIf="verifiers.length < 3 && user.approval_status">
                <div>
                    <p>Verified by the ConnectUs.today Team
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form [formGroup]="descriptionForm" novalidate>
                <p [innerHtml]="profile.description" class="bio-text-para"></p>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #noMessage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Send Message</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>{{messageError}}</p>
        </div>
    </div>
</ng-template>
<ng-template #settingModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Settings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="blockReportForm">
                <div class="d-flex align-items-center">
                    <div class="form-group mr-5">
                        <div class="custom-control custom-checkbox new-check" *ngIf="isUserBlocked">
                            <input type="checkbox" class="custom-control-input" id="block" formControlName="block_user"
                                (click)="blockUser();">
                            <label class="custom-control-label" for="block">Unblock User</label>
                        </div>
                        <div class="custom-control custom-checkbox new-check" *ngIf="!isUserBlocked">
                            <input type="checkbox" class="custom-control-input" id="block" formControlName="block_user"
                                (click)="blockUser();">
                            <label class="custom-control-label" for="block">Block User</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox new-check">
                            <input type="checkbox" class="custom-control-input" id="report"
                                formControlName="report_user" (click)="reportReason();">
                            <label class="custom-control-label" for="report">Report User</label>
                        </div>
                    </div>
                </div>
                <div class="reportShow" *ngIf="reportUser">
                    <div class="form-group">
                        <label>Reason</label>
                        <select class="form-control custom-select" formControlName="report_reason">
                            <option value=1>Hate Speech</option>
                            <option value=2>Bullying and harrassment of yourself or others</option>
                            <option value=3>Direct threats</option>
                            <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)
                            </option>
                            <option value=5>Other</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="report_description"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="blockUnblockUser(blockReportForm.valid, user.id)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editSkillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <p class="pl-3">{{ editSkill.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="editSkill.past_description">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this skill? What introduced you to it?</label>
                        <p class="pl-3">{{ editSkill.past_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editSkill.present_description">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this skill?</label>
                        <p class="pl-3">{{ editSkill.present_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editSkill.future_description">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this skill? How do you intend to use and build this in
                            future? </label>
                        <p class="pl-3">{{ editSkill.future_description }}</p>
                    </div>
                    <ul class="view-skill-list">
                        <li *ngFor="let skillLink of skillLinkArray">
                            <a href="{{skillLink.link}}" target="_blank">
                                <img alt="image"
                                    src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}">
                                <h5>{{ skillLink.title }}</h5>
                            </a>
                        </li>
                    </ul>
                    <div class="form-group" *ngIf="skillDocArray.length > 0">
                        <h3>
                            Images/Files
                        </h3>
                        <ul class="view-files-list">
                            <li *ngFor="let skillDoc of skillDocArray">
                                <img alt="image" *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}">
                                <img alt="image" *ngIf="!isImage(skillDoc.document_name)"
                                    src="assets/images/pdf-icon.svg">
                                <h5>{{ skillDoc.title }}</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #booktemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">
            <img class="fav-image for-preview-image" *ngIf="editBook.thumbnail" src="{{ editBook.thumbnail }}"
                alt="Book Cover">
            <img class="fav-image for-preview-image" *ngIf="!editBook.thumbnail" src="assets/images/default.svg"
                alt="Book Cover">
            <div class="media-body ml-3">
                <h4>{{ editBook.title }}</h4>
                <p class="m-0">{{ editBook.author }}</p>
                <div>
                    <ul *ngIf="editBook.rating" class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                        <ngb-rating [(rate)]="editBook.rating" [readonly]="true" [max]="5">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </ul>
                </div>
                <p class="m-0">{{ editBook.description }}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">bulletin board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">

            <div *ngIf="editBulletin.document" class="img-div">
                <a href="{{editBulletin.document}}" target="_blank">
                    <img *ngIf="isImage(editBulletin.document_name)" class="fav-image" src="{{editBulletin.document}}">
                    <img *ngIf="!isImage(editBulletin.document_name)" class="fav-image"
                        src="assets/images/pdf-icon.svg">
                </a>
            </div>
            <div *ngIf="!editBulletin.document" class="img-div">
                <img class="fav-image" src="assets/images/default.svg">
            </div>

            <div class="media-body ml-3">
                <h4>{{ editBulletin.title }}</h4>
                <p class="m-0" *ngIf="editBulletin.link"><a href="{{ editBulletin.link }}">{{ editBulletin.link }}</a>
                </p>
                <p class="m-0" *ngIf="editBulletin.description">{{ editBulletin.description }}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #editInterestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interest</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <p class="pl-3">{{ editInterest.title }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="editInterest.past_description">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this Interest? What introduced you to it?</label>
                        <p class="pl-3">{{ editInterest.past_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editInterest.present_description">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this Interest?</label>
                        <p class="pl-3">{{ editInterest.present_description }}</p>
                    </div>
                    <div class="form-group" *ngIf="editInterest.future_description">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this Interest? How do you intend to use and build this in
                            future? </label>
                        <p class="pl-3">{{ editInterest.future_description }}</p>
                    </div>
                    <ul class="view-skill-list">
                        <li *ngFor="let skillLink of skillLinkArray">
                            <a href="{{skillLink.link}}" target="_blank">
                                <img alt="image"
                                    src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}">
                                <h5>{{ skillLink.title }}</h5>
                            </a>
                        </li>
                    </ul>
                    <div class="form-group" *ngIf="skillDocArray.length > 0">
                        <h3>
                            Images/Files
                        </h3>
                        <ul class="view-files-list">
                            <li *ngFor="let skillDoc of skillDocArray">
                                <img alt="image" *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}">
                                <img alt="image" *ngIf="!isImage(skillDoc.document_name)"
                                    src="assets/images/pdf-icon.svg">
                                <h5>{{ skillDoc.title }}</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #booksListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">books Listing</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-listing-wrap">
            <div class="media  border-row" *ngFor="let book of books"
                (click)="updatebookModal(booktemplate, book)">
                <img src="{{book.thumbnail ? book.thumbnail :'assets/images/default.svg'}}" alt="Books Cover"
                    class="booksCover mainTopImg">
                <div class="media-body ml-3">
                    <h4>{{ book.title }}</h4>
                    <p>{{ book.author }}</p>
                    <div>
                        <ul *ngIf="book.rating" class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                            <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                <ng-template let-fill="fill" let-index="index">
                                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                                </ng-template>
                            </ngb-rating>
                        </ul>
                    </div>
                    <p class="text-ellipse">{{ book.description }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerImages>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Header Image</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="headerOuter">
                    <img class="headerImg w-100"
                        src="{{profileHeader? profileHeader: 'assets/images/new-header-img.png'}}">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #firstModal>
    <div class="modal-body connectionModal">
        <div class="row">
            <div class="col-md-12">
                <div class="connectionHdg">
                    <h2>Your Connection
                        <span class="forDotName">
                            <span class="forDot"></span>
                            <span class="forName">1st</span>
                        </span>
                    </h2>
                </div>
                <div class="connectionFirstList scroll-activity scrollbar">
                    <ul class="no-list">
                        <li *ngFor="let team of expTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.team_detail.display_pic? team.team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{team.team_detail.title}} 
                                    <span class="orgCount" *ngIf="team.team_detail.org_count && team.team_detail.org_count > 1">
                                        x{{team.team_detail.org_count}}
                                    </span>
                                </h3>
                            </div>
                        </li>
                        <li *ngFor="let team of eduTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.team_detail.display_pic? team.team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{team.team_detail.title}} 
                                    <span class="orgCount" *ngIf="team.team_detail.org_count && team.team_detail.org_count > 1">
                                        x{{team.team_detail.org_count}}
                                    </span>
                                </h3>
                            </div>
                        </li>
                        <li *ngFor="let team of iniTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.team_detail.display_pic? team.team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{team.team_detail.title}} 
                                    <span class="orgCount" *ngIf="team.team_detail.org_count && team.team_detail.org_count > 1">
                                        x{{team.team_detail.org_count}}
                                    </span>
                                </h3>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #secondModal>
    <div class="modal-body connectionModal">
        <div class="row">
            <div class="col-md-12">
                <div class="connectionHdg">
                    <h2>Your Connection
                        <span class="forDotName">
                            <span class="forDot"></span>
                            <span class="forName">2nd</span>
                        </span>
                    </h2>
                </div>
                <div class="connectionSecondList scroll-activity scrollbar">
                    <ul class="no-list">
                        <li *ngFor="let team of expTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.exp_team_detail.display_pic? team.exp_team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{ team.exp_team_detail.name }}
                                    <span class="forDotName">
                                        <span class="forDot"></span>
                                        <span class="forName">1st</span>
                                    </span>
                                </h3>
                                <p>Connected with {{user.first_name}} through
                                    <span *ngIf="team.exp_team_detail.type == 1">Work at</span>
                                    <span *ngIf="team.exp_team_detail.type == 2">Volunteering at</span>
                                    <span *ngIf="team.exp_team_detail.type == 3">Extracurricular at</span>
                                    <span *ngIf="team.exp_team_detail.type == 4">School Involvement in</span>
                                    {{team.exp_team_detail.organization_title}}</p>
                            </div>
                        </li>

                        <li *ngFor="let team of eduTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.edu_team_detail.display_pic? team.edu_team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{ team.edu_team_detail.name }}
                                    <span class="forDotName">
                                        <span class="forDot"></span>
                                        <span class="forName">1st</span>
                                    </span>
                                </h3>
                                <p>Connected with {{user.first_name}} through Education at {{team.edu_team_detail.organization_title}}</p>
                            </div>
                        </li>

                        <li *ngFor="let team of iniTeam">
                            <div class="leftWrap">
                                <img class="connImg" src="{{team.ini_team_detail.display_pic? team.ini_team_detail.display_pic: 'assets/images/new-header-img.png'}}">
                            </div>
                            <div class="rightWrap">
                                <h3>
                                    {{ team.ini_team_detail.name }}
                                    <span class="forDotName">
                                        <span class="forDot"></span>
                                        <span class="forName">1st</span>
                                    </span>
                                </h3>
                                <p>Connected with {{user.first_name}} through an Initiative called {{team.ini_team_detail.organization_title}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #networkModal>
        <div  class="modal-header">
          <h4  class="modal-title pull-left">Add to network</h4>
          <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
            <img  alt="image"  src="assets/images/cross-modal.svg"></button>
        </div>
        <div class="modal-body connectionModal">
        <div class="row">
            <div class="col-md-12">
                <div class="connectionNetworkList scroll-activity scrollbar">
                    <ul class="no-list pr-2">
                        <li *ngFor="let experience of addWorkExpTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{experience.position}}</h2>
                                    <h4>{{experience.organization_title}}</h4>
                                    <p>{{experience.start_date}}
                                        <ng-template [ngIf]="experience.end_date" [ngIfElse]="currentTemp">
                                            <span>- {{experience.end_date}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="experience.add_to_team == 5"
                                    (click)="teamExpBusiness(experience, addProfileTeamModal)">Add</button>
                                <p *ngIf="experience.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="experience.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                        <li *ngFor="let experience of addVolExpTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{experience.position}}</h2>
                                    <h4>{{experience.organization_title}}</h4>
                                    <p>{{experience.start_date}}
                                        <ng-template [ngIf]="experience.end_date" [ngIfElse]="currentTemp">
                                            <span>- {{experience.end_date}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="experience.add_to_team == 5"
                                    (click)="teamExpBusiness(experience, addProfileTeamModal)">Add</button>
                                <p *ngIf="experience.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="experience.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                        <li *ngFor="let activity of addToActTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{activity.position}}</h2>
                                    <h4>{{activity.organization_title}}</h4>
                                    <p>{{activity.start_date}}
                                        <ng-template [ngIf]="activity.end_date" [ngIfElse]="currentTemp">
                                            <span>- {{activity.end_date}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="activity.add_to_team == 5"
                                    (click)="teamExpBusiness(activity, addProfileTeamModal)">Add</button>
                                <p *ngIf="activity.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="activity.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                        <li *ngFor="let experience of addToSchInvTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{experience.position}}</h2>
                                    <h4>{{experience.title}}</h4>
                                    <p>{{experience.start_date}}
                                        <ng-template [ngIf]="experience.end_date" [ngIfElse]="currentTemp">
                                            <span>- {{experience.end_date}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="experience.add_to_team == 5"
                                (click)="teamExpClubTeam(experience, addProfileTeamModal)">Add</button>
                                <p *ngIf="experience.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="experience.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                        <li *ngFor="let eduObj of addToEduTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{eduObj.school.display_picture? eduObj.school.display_picture : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{eduObj.school.school_name}}</h2>
                                    <h4 *ngIf="eduObj.majors">{{ eduObj.majors.join(', ') }}</h4>
                                    <h4 *ngIf="eduObj.interests">{{ eduObj.interests.join(', ') }}</h4>
                                    <p>{{eduObj.end_date | date:'yyyy' }}</p>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="eduObj.add_to_team == 5"
                                    (click)="teamEdu(eduObj, addEduTeamModal)">Add</button>
                                <p *ngIf="eduObj.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="eduObj.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                        <li *ngFor="let initiative of addToInitTeam">
                            <div class="leftWrap">
                                <div class="forImage">
                                    <img class="connImg"
                                    src="{{initiative.display_pic ? initiative.display_pic : 'assets/images/default.svg'}}">
                                </div>
                                <div class="forDes">
                                    <h2>{{ initiative.page_info.title }}</h2>
                                </div>
                            </div>
                            <div class="rightWrap">
                                <button class="btnAdd" *ngIf="initiative.add_to_team == 5"
                                    (click)="addToIniTeam(initiative)">Add</button>
                                <p *ngIf="initiative.add_to_team == 1">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                                </p>
                                <p *ngIf="initiative.add_to_team == 2">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #staticEduModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">

            <form [formGroup]="educationForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h2 class="stand-by-hdg">{{insText}}</h2>
                    </div>
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input readonly type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Country</label>
                        <input readonly type="text" class="form-control" formControlName="country"
                            placeholder="Country">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-md-12">
                        <!-- <div class="custom-control custom-radio" [hidden]="isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" class="custom-control-input">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div> -->
                        <div class="teal-radio-box">
                                <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" />
                                <label for="customCheck2">School</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <!-- <div class="custom-control custom-radio" [hidden]="!isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div> -->
                        <div class="teal-radio-box" [hidden]="!isCollegeSelected">
                                <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" />
                                <label for="customCheck3">College/University</label>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="isCollegeSelected" [formGroup]="collegeEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12"
                        *ngIf="collegeEdForm.controls.college_degree && collegeEdForm.controls.college_degree.value">
                        <label>Degree of College/University
                        </label>
                    </div>
                    <div class="col-md-12"
                        *ngIf="collegeEdForm.controls.college_degree && collegeEdForm.controls.college_degree.value">
                        <select formControlName="college_degree" class="custom-select" disabled>
                            <option *ngFor="let ins_type of collegeTypes" value={{ins_type.id}}>
                                {{ins_type.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row"
                    *ngIf="collegeEdForm.controls.program">
                    <div class="col-md-12">
                        <label>Program
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="program" placeholder="Program" class="form-control">
                    </div>
                </div>
                <div class="form-group row"
                    *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                    <div class="col-md-12">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="majors" placeholder="Majors" class="form-control">
                    </div>
                </div>
                <div class="form-group row" *ngIf="collegeEdForm.controls.minors.value">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="minors" placeholder="Minors" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>

                </div>
            </form>
            <form *ngIf="!isCollegeSelected" [formGroup]="schoolEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <label>Interests and Favourite Classes

                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <input readonly type="text" formControlName="interests" placeholder="Interests"
                            class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{expModalTitle}} Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate [ngClass]="{ 'formHide': editable, formShow: !editable }">
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img
                                src="{{editExp.page_display_pic? editExp.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editExp.position }}</h3>
                                <h4 *ngIf="editExp.organization_title">{{editExp.organization_title}}</h4>
                                <h4 *ngIf="!editExp.organization_title">Self-employed</h4>
                                <h5 class="start-end" *ngIf="editExp.start_date != editExp.end_date">
                                    <span>{{editExp.start_date | date: 'MMM y'}}</span>
                                    <span> - </span>
                                    <span *ngIf="editExp.end_date" >{{ editExp.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editExp.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editExp.start_date == editExp.end_date"><strong>Date</strong>
                                    <span class="ml-1">{{editExp.start_date | date: 'MMMM d, y'}}</span>
                                </h5>
                                <p *ngIf="editExp.approver_name && editExp.verified_date" class="verifiedText d-block">
                                    Verified by {{editExp.approver_name}} on
                                    {{editExp.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.skill_1 || editExp.skill_2 || editExp.skill_3">
                                <h2>Top Skills Built</h2>
                                <p class="medium-para">
                                    <span *ngIf="editExp.skill_1">{{editExp.skill_1}}</span>
                                    <span *ngIf="editExp.skill_2 && editExp.skill_1">, </span> 
                                    <span *ngIf="editExp.skill_2">{{editExp.skill_2}}</span>
                                    <span *ngIf="editExp.skill_3 && (editExp.skill_2 || editExp.skill_1)">, </span> 
                                    <span *ngIf="editExp.skill_3">{{editExp.skill_3}}</span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editExp.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editExp.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="expTeamPopupWrap px-4" *ngIf="editExp.page && editExp.team_members && editExp.team_members.length > 0">
                <h3 class="expTeamPopupName pt-3 text-center">Verified Team Members</h3>
                <ul class="teamListWrap no-list">
                    <li *ngFor="let teamMem of editExp.team_members">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{ teamMem.exp_team_detail.header_image ? teamMem.exp_team_detail.header_image : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{ teamMem.exp_team_detail.display_pic ? teamMem.exp_team_detail.display_pic : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <h1 class="name font18">{{ teamMem.exp_team_detail.name }}</h1>
                                <h3 class="subName font16">{{ teamMem.exp_team_detail.position }}</h3>
                                <p class="dateHistory mb-0">{{ teamMem.exp_team_detail.start_date | date: 'MMM y' }} - <span *ngIf="teamMem.exp_team_detail.end_date">{{ teamMem.exp_team_detail.end_date | date: 'MMM y' }}</span><span *ngIf="!teamMem.exp_team_detail.end_date">Present</span>
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #editActivityTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img alt="image"
                                src="{{editActivity.page_display_pic? editActivity.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editActivity.position }}</h3>
                                <h4 *ngIf="editActivity.organization_title">{{editActivity.organization_title}}</h4>
                                <h4 *ngIf="!editActivity.organization_title">Self-employed</h4>
                                <h5 class="start-end" *ngIf="editActivity.start_date != editActivity.end_date">
                                    <span>{{ editActivity.start_date | date: 'MMM y'}}</span>
                                    <span> - </span>
                                    <span *ngIf="editActivity.end_date" >{{ editActivity.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editActivity.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editActivity.start_date == editActivity.end_date"><strong>Date</strong>
                                    <span class="ml-1">{{editActivity.start_date | date: 'MMMM d, y'}}</span>
                                </h5>

                                <p *ngIf="editActivity.approver_name && editActivity.verified_date"
                                    class="verifiedText d-block">
                                    Verified by {{editActivity.approver_name}} on
                                    {{editActivity.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts"
                                *ngIf="editActivity.skill_1 || editActivity.skill_2 || editActivity.skill_3">
                                <h2>Top Skills Built</h2>
                                <p>
                                    <span *ngIf="editActivity.skill_1">{{editActivity.skill_1}}</span>
                                    <span *ngIf="editActivity.skill_2 && editActivity.skill_1">, </span> 
                                    <span *ngIf="editActivity.skill_2">{{editActivity.skill_2}}</span>
                                    <span *ngIf="editActivity.skill_3 && (editActivity.skill_2 || editActivity.skill_1)">, </span> 
                                    <span *ngIf="editActivity.skill_3">{{editActivity.skill_3}}</span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editActivity.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editActivity.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editActivity.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editActivity.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="expTeamPopupWrap px-4" *ngIf="editActivity.page && editActivity.team_members && editActivity.team_members.length > 0">
                <h3 class="expTeamPopupName pt-3 text-center">Verified Team Members</h3>
                <ul class="teamListWrap no-list">
                    <li *ngFor="let teamMem of editActivity.team_members">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{ teamMem.exp_team_detail.header_image ? teamMem.exp_team_detail.header_image : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{ teamMem.exp_team_detail.display_pic ? teamMem.exp_team_detail.display_pic : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <h1 class="name font18">{{ teamMem.exp_team_detail.name }}</h1>
                                <h3 class="subName font16">{{ teamMem.exp_team_detail.position }}</h3>
                                <p class="dateHistory mb-0">{{ teamMem.exp_team_detail.start_date }} - <span *ngIf="teamMem.exp_team_detail.end_date">{{ teamMem.exp_team_detail.end_date }}</span><span *ngIf="!teamMem.exp_team_detail.end_date">Present</span>
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #schoolInvolvementTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate [ngClass]="{ 'formHide': editable, formShow: !editable }">
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img
                                src="{{editSchoolInv.page_display_pic? editSchoolInv.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editSchoolInv.position }}</h3>
                                <h4>{{ editSchoolInv.title }}</h4>
                                <h5 class="start-end" *ngIf="editSchoolInv.start_date != editSchoolInv.end_date">
                                    <span>{{editSchoolInv.start_date | date: 'MMM y'}}</span>
                                    <span> - </span>
                                    <span *ngIf="editSchoolInv.end_date" >{{ editSchoolInv.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editSchoolInv.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editSchoolInv.start_date == editSchoolInv.end_date"><strong>Date</strong>
                                    <span class="ml-1">{{editSchoolInv.start_date | date: 'MMMM d, y'}}</span>
                                </h5>

                                <p *ngIf="editSchoolInv.approver_name && editSchoolInv.verified_date"
                                    class="verifiedText d-block">
                                    Verified by {{editSchoolInv.approver_name}} on
                                    {{editSchoolInv.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts"
                                *ngIf="editSchoolInv.skill_1 || editSchoolInv.skill_2 || editSchoolInv.skill_3">
                                <h2>Top Skills Built</h2>
                                <p>
                                    <span *ngIf="editSchoolInv.skill_1">{{editSchoolInv.skill_1}}</span>
                                    <span *ngIf="editSchoolInv.skill_2 && editSchoolInv.skill_1">, </span> 
                                    <span *ngIf="editSchoolInv.skill_2">{{editSchoolInv.skill_2}}</span>
                                    <span *ngIf="editSchoolInv.skill_3 && (editSchoolInv.skill_2 || editSchoolInv.skill_1)">, </span> 
                                    <span *ngIf="editSchoolInv.skill_3">
                                        {{editSchoolInv.skill_3}}
                                    </span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editSchoolInv.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editSchoolInv.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editSchoolInv.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editSchoolInv.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="expTeamPopupWrap px-4" *ngIf="editSchoolInv.page && editSchoolInv.team_members && editSchoolInv.team_members.length > 0">
                <h3 class="expTeamPopupName pt-3 text-center">Verified Team Members</h3>
                <ul class="teamListWrap no-list forWorkExpFont">
                    <li *ngFor="let teamMem of editSchoolInv.team_members">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{ teamMem.exp_team_detail.header_image ? teamMem.exp_team_detail.header_image : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{ teamMem.exp_team_detail.display_pic ? teamMem.exp_team_detail.display_pic : './assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <h1 class="name font18">{{ teamMem.exp_team_detail.name }}</h1>
                                <h3 class="subName font16">{{ teamMem.exp_team_detail.position }}</h3>
                                <p class="dateHistory mb-0">{{ teamMem.exp_team_detail.start_date }} - <span *ngIf="teamMem.exp_team_detail.end_date">{{ teamMem.exp_team_detail.end_date }}</span><span *ngIf="!teamMem.exp_team_detail.end_date">Present</span>
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #currentTemp>
    <span>Present</span>
</ng-template>
<ng-template #workExpListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar overflowXHide exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of usrWorkExperience"
                    (click)="updateExpModal(editExpTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" class="thumb-img" (click)="redirectToPage(experience);modalRef.hide();$event.stopPropagation()"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
                        <h4 *ngIf="!experience.organization_title">Self-employed</h4>
                        <h5 *ngIf="experience.start_date != experience.end_date" class="start-end">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                        </h5>
                        <p class="m-0"><strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong></p>
                        <p class="m-0 text-ellipse">
                            {{ experience.main_role }}
                        </p>
                        <div class="add-detial-content">
                                <div class="addRefRow">
                                        <div class="addRefRowInner">
                                                <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                        <p class="my-0 people-number">
                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                            <span>{{ experience.team_members.length }}</span>
                                                        </p>
                                                    </div>
                                                    <p class="verifiedTextImg"
                                                    *ngIf="experience.approver_name && experience.verified_date">
                                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                                    </p>
                                        </div>
                                    </div>
                                    <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                            <a href="javascript:void(0);" (click)="teamExpBusiness(experience, addProfileTeamModal);$event.stopPropagation()">
                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                <span>Add team members</span>
                                            </a>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>Pending</span>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 2">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #volunteerListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Volunteer Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of usrVolunteerWorkExperience"
                    (click)="updateExpModal(editExpTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" class="thumb-img" (click)="redirectToPage(experience);modalRef.hide();$event.stopPropagation()"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4 *ngIf="experience.organization_title">{{experience.organization_title}}</h4>
                        <h4 *ngIf="!experience.organization_title">Self-employed</h4>
                        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                        </h5>

                        <p class="m-0">
                            <strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                        <div class="add-detial-content">
                                <div class="addRefRow">
                                        <div class="addRefRowInner">
                                                <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                        <p class="my-0 people-number">
                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                            <span>{{ experience.team_members.length }}</span>
                                                        </p>
                                                    </div>
                                                    <p class="verifiedTextImg"
                                                    *ngIf="experience.approver_name && experience.verified_date">
                                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                                    </p>
                                        </div>
                                    </div>
                                    <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                            <a href="javascript:void(0);" (click)="teamExpBusiness(experience, addProfileTeamModal);$event.stopPropagation()">
                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                <span>Add team members</span>
                                            </a>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>Pending</span>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 2">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #extraCurricularListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let activity of activities"
                    (click)="updateActivityModal(editActivityTemplate, activity)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" class="thumb-img" (click)="redirectToPage(activity);modalRef.hide();$event.stopPropagation()"
                            src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{activity.position}}</h3>
                        <h4 *ngIf="activity.organization_title">{{activity.organization_title}}</h4>
                        <h4 *ngIf="!activity.organization_title">Self-employed</h4>
                        <h5 class="start-end" *ngIf="activity.start_date != activity.end_date">
                            <span>{{activity.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="activity.end_date && !(activity.verification_status == 1 && activity.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{activity.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="activity.start_date == activity.end_date"><strong>Date</strong>
                            <span class="ml-1">{{activity.start_date | date: 'MMMM d, y'}}</span>
                        </h5>
                        <p class="m-0">
                            <strong>
                                <span *ngIf="activity.skill_1">{{activity.skill_1}}</span>
                                <span *ngIf="activity.skill_2 && activity.skill_1">, </span>
                                <span *ngIf="activity.skill_2">{{activity.skill_2}}</span>
                                <span *ngIf="activity.skill_3 && (activity.skill_2 || activity.skill_1)">, </span>
                                <span *ngIf="activity.skill_3">{{activity.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{activity.main_role}}</p>
                        <div class="add-detial-content">
                                <div class="addRefRow">
                                        <div class="addRefRowInner">
                                                <div class="expVerifyTeam" *ngIf="activity.page && activity.team_members.length > 0">
                                                        <p class="my-0 people-number">
                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                            <span>{{ activity.team_members.length }}</span>
                                                        </p>
                                                    </div>
                                                    <p class="verifiedTextImg"
                                                    *ngIf="activity.approver_name && activity.verified_date">
                                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                                    </p>
                                        </div>
                                    </div>
                                    <div class="addRefRow" *ngIf="activity.add_to_team && !viewMode">
                                        <div class="addRefRowInner" *ngIf="activity.add_to_team == 5">
                                            <a href="javascript:void(0);" (click)="teamExpBusiness(activity, addProfileTeamModal);$event.stopPropagation()">
                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                <span>Add team members</span>
                                            </a>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="activity.add_to_team == 1">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>Pending</span>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="activity.add_to_team == 2">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #schoolInvolvementListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of schoolInvolvements"
                    (click)="updateSchoolInvolvementModal(schoolInvolvementTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" class="thumb-img" (click)="redirectPage(experience);modalRef.hide();$event.stopPropagation()"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4>{{experience.title}}</h4>
                        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMMM d, y'}}</span>
                        </h5>

                        <p class="m-0">
                            <strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                        <div class="add-detial-content">
                                <div class="addRefRow">
                                        <div class="addRefRowInner">
                                                <div class="expVerifyTeam" *ngIf="experience.page && experience.team_members.length > 0">
                                                        <p class="my-0 people-number">
                                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                            <span>{{ experience.team_members.length }}</span>
                                                        </p>
                                                    </div>
                                                    <p class="verifiedTextImg"
                                                    *ngIf="experience.approver_name && experience.verified_date">
                                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                                    </p>
                                        </div>
                                        
                                    </div>
                                    <div class="addRefRow" *ngIf="experience.add_to_team && !viewMode">
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 5">
                                            <a href="javascript:void(0);" (click)="teamExpClubTeam(experience, addProfileTeamModal);$event.stopPropagation()">
                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                <span>Add team members</span>
                                            </a>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 1">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>Pending</span>
                                        </div>
                                        <div class="addRefRowInner" *ngIf="experience.add_to_team == 2">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #addProfileTeamModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team Members</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="othersExpOuterPopup px-md-5">
            <p class="othersExp2">You held multiple roles. When were you teammates?</p>
            <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
            <div class="scroll-activity scrollbar overflowXHide">
                <ul class="teamListWrap no-list">
                    <li *ngFor="let eachExp of matchedExp">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{eachExp.student_info.header_image ? eachExp.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{eachExp.student_info.display_pic ? eachExp.student_info.display_pic : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <h3 class="subName">{{ eachExp.position }}</h3>
                                <p class="yearHistory">
                                    {{ eachExp.start_date }}<span *ngIf="eachExp.end_date"> {{ eachExp.end_date }}</span><span *ngIf="!eachExp.end_date"> Present</span>
                                </p>
                                <div class="memberActions">
                                    <button class="btn common-btn btnMessage" *ngIf="!eachExp.team_id" (click)="addToTeamFnc(eachExp)">Add to team</button>
                                    <button class="btn common-btn btnMessage" *ngIf="eachExp.team_id">Pending</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updateModalRef.hide()">
            Done
        </button>
    </div>
</ng-template>
<ng-template #viewAwardsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Awards and Credentials</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box pb-2">
            <div class="media">
                <div class="imgSec" *ngIf="editAward.document_name">
                    <a *ngIf="!isImage(editAward.document_name)" href="{{editAward.document}}" target="_blank">
                        <img alt="image" class="for-preview-image" src="assets/images/pdf-icon.svg">
                    </a>
                    <a *ngIf="isImage(editAward.document_name)" href="{{editAward.document}}" target="_blank">
                        <img alt="image" class="for-preview-image" src="{{editAward.document}}">
                    </a>
                </div>
                <div class="imgSec" *ngIf="!editAward.document_name">
                    <img alt="image" class="for-preview-image" src="assets/images/default.svg">
                </div>
                <div class="media-body ml-3">
                    <div class="awardsData">
                        <h3 class="m-0">{{editAward.title}}</h3>
                        <p class="mb-2">{{editAward.year | date: 'yyyy'}}</p>
                        <a *ngIf="editAward.link" href="{{editAward.link}}" target="blank">{{editAward.link}}</a>
                        <div class="awardsBadge" *ngIf="editAward.skills_gained && editAward.skills_gained.length > 0">
                            <span class="btn-dark-badge mr-2" *ngFor="let skill_gained of editAward.skills_gained">{{skill_gained}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #teamMemberTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Your Team <span *ngIf="teamMember.length > 0">({{ totalTeamMember }})</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div class="initiativeTeamSlider mb-md-2 px-2">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMember.length > 0">
                        <ul class="teamMemberList no-list">
                            <li *ngFor="let member of teamMember">
                                <div class="myTeamEach">
                                    <div class="leftTeam">
                                        <img alt="icon" class="profileImg" src="{{member.edu_team_detail.display_pic ? member.edu_team_detail.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="rightTeam">
                                        <div class="forDetails">
                                            <h1 class="name m-0">{{ member.edu_team_detail.name }}</h1>
                                            <p class="yearHistoryMember m-0">
                                                {{ member.edu_team_detail.start_date | date:'yyyy' }} - {{member.edu_team_detail.end_date | date:'yyyy' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="totalTeamMember > 6 && totalTeamMember > teamMember.length">
                        <a href="javascript:void(0);" (click)="loadMoreTeamMemberProfile()">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #addEduTeamModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team Members</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="othersExpOuterPopup px-md-5">
            <p class="othersExp2">When were you teammates?</p>
            <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
            <div class="scroll-activity scrollbar overflowXHide">
                <ul class="teamListWrap no-list">
                    <li *ngFor="let eachExp of matchedExp">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{eachExp.student_info.header_image ? eachExp.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{eachExp.student_info.display_pic ? eachExp.student_info.display_pic : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <p class="yearHistory">
                                    {{ eachExp.start_date | date:'yyyy'}} - {{ eachExp.end_date | date:'yyyy' }}
                                </p>
                                <div class="memberActions">
                                    <button class="btn common-btn btnMessage" *ngIf="!eachExp.team_id" (click)="addToTeamEdu(eachExp)">Add to team</button>
                                    <button class="btn common-btn btnMessage" *ngIf="eachExp.team_id">Pending</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updateModalRef.hide()">
            Done
        </button>
    </div>
</ng-template>
<ng-template #iniTeamMemberTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Your Team <span *ngIf="teamMember.length > 0">({{ totalTeamMember }})</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div class="initiativeTeamSlider mb-md-2 px-2">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMember.length > 0">
                        <ul class="teamMemberList no-list">
                            <li *ngFor="let member of teamMember">
                                <div class="myTeamEach">
                                    <div class="leftTeam">
                                        <img alt="icon" class="profileImg" src="{{member.ini_team_detail.display_pic ? member.ini_team_detail.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="rightTeam">
                                        <div class="forDetails">
                                            <h1 class="name m-0">{{ member.ini_team_detail.name }}</h1>
                                            <p class="yearHistoryMember m-0">{{ member.ini_team_detail.position }}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="totalTeamMember > 6 && totalTeamMember > teamMember.length">
                        <a href="javascript:void(0);" (click)="loadMoreIniTeamMember()">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #contactListingtemplate>
    <div class="modal-body new-body">
        <div class="timeLineBlock">
            <div class="create-box">
                <h4 class="modal-title pull-left">Contact Info</h4>
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img alt="image" src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <ul class="contact-list">
                    <li *ngFor="let link of links">
                        <a href="javascript:void(0);" class="email" (click)="copyEmail()" *ngIf="link.social_type == 6 && link.link">
                            <img src="/assets/images/email-teal-icon.svg" alt="Email" />
                            {{link.link}}
                            <div class="custom-tooltip">
                                <div class="tooltip-data" *ngIf="copyEmailBool">Email ID Copied</div>
                            </div>
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 2 && link.link">
                            <img src="/assets/images/tiktok-teal-icon.svg" alt="Tiktok" />
                            Tiktok
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 1 && link.link">
                            <img src="/assets/images/instagram-teal-icon.svg" alt="Instagram" />
                            Instagram
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 3 && link.link">
                            <img src="/assets/images/facebook-teal-icon.svg" alt="Facebook" />
                            Facebook
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 5 && link.link">
                            <img src="/assets/images/twitter-teal-icon.svg" alt="Twitter" />
                            Twitter
                        </a>
                        <a href="{{ link.link }}" target="_blank" *ngIf="link.social_type == 4 && link.link">
                            <img src="/assets/images/youTubeIcon-teal.svg" alt="Twitter" />
                            Youtube
                        </a>
                    </li>
                    
                </ul>
            </div>
            <div class="timeFrameBtns btn-box">
                <div class="rightBtns">
                    <button class="btn btn-colored " (click)="modalRef.hide()">Done</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
