<div class="footer-inner" *ngIf="loggedIn"></div>

<footer>
    <div class="container-fuild">
        <div class="row">
            <div class="col-md-3 col-lg-2 logo-div pl-md-5">
                <img class="ml-md-5" style="width: 80px;" src="/assets/images/logo.svg" alt="logo" />
            </div>
            <div class="col-xl-3 col-lg-4 col-md-5">
                <div class="footer-nav d-flex flex-wrap">
                    <div class="footerbar">
                        <h4>Policies</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                            </li>
                            <li>
                                <a [routerLink]="['/terms-of-use']">Terms of Use</a>
                            </li>
                            <li>
                                <a [routerLink]="['/guidelines']">Community Guidelines</a>
                            </li>
                        </ul>
                    </div>

                    <div class="footerbar">
                        <h4>Want to learn more?</h4>
                        <ul class="pl-0">
                            <li>
                                <a class="footerAboutUsBtn" href="https://www.aboutcu.today/" target="_blank">About
                                    Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>