import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { TeamService } from '../services/team.service';
import { PageService } from '../services/page.service';
import { ProfileService } from '../services/profile.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { InitiativeService } from '../services/initiative.service';

@Component({
  selector: 'app-business-listing',
  templateUrl: './business-listing.component.html',
  styleUrls: ['./business-listing.component.scss']
})
export class BusinessListingComponent implements OnInit {
  modalRef: BsModalRef;
  intModalRef: BsModalRef;
  otherModalRef: BsModalRef;
  settingShow: boolean = true;
  pageslist: any = []
  timelineDocs: any = [];
  params: string = "";
  loggedIn: boolean = false;
  user: any = {};
  url: string = "";
  headerImage: string = "";
  headerImage_name: string = "";
  displayImage: string = "";
  displayImage_name: string = "";
  totalPageCount: number = 0;
  teamCount: number = 0;
  totalIntFeedsCount: number = 0;
  totalUsersCount: number = 0;
  totalFollowedCount: number = 0;
  intFeedsNextPage: any = 1;
  intUsersNextPage: any = 1;
  followedNextPage: any = 1;
  myPageNextPage: any = 1;
  followedPages: any = [];
  interestedFeeds:any = [];
  interestedUsers:any = [];
  editFeedObj: any = {};
  followBtnErr: boolean = false;
  getImgCount = 0;
  initiativeForm: FormGroup;
  uploadForm: FormGroup;
  blockReportForm: FormGroup;
  submitted: boolean;
  disableSubmit: boolean;
  previewThumbnailName: any = "";
  previewThumbnail: any = false;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage: string;
  coverImageUploadSizeMessage: string;
  school_id = 0;
  schools = [];

  constructor(private router: Router, private teamService: TeamService, public formBuilder: FormBuilder,
    private pageService: PageService, private modalService: BsModalService, private profileService: ProfileService,
    private initiativeService: InitiativeService) {
      this.initiativeForm = this.formBuilder.group({
        title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        state: new FormControl('', [Validators.required, Validators.maxLength(50)])
      });
      this.uploadForm = this.formBuilder.group({
        thumbnail: [''],
        thumbnail_name: ['']
      });
      this.blockReportForm = this.formBuilder.group({
        report_reason: new FormControl('', [Validators.required]),
        report_description: new FormControl('', [Validators.maxLength(1000)])
      });
    }

  ngOnInit(): void {
    this.getImgCount = 0;
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.headerImage = this.user.header_image;
      this.headerImage_name = this.user.header_image;
      this.displayImage = this.user.display_picture;
      this.displayImage_name = this.user.display_picture;
      this.getFollowedPages();
      this.getAllPages();
      this.getInterestedFeeds();
      if (this.user.user_role == '2') {
        this.getTeamCount();
      }
    }

  }

  getTeamCount(){
    this.teamService.getTeamCount()
    .subscribe(response => {
      if (response.status === 200){
        this.teamCount = response.response.team_count;
      }
    });
  }

  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal' }));
  }

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  getInterestedFeeds(){
    let params = '?page=' + this.intFeedsNextPage;
    this.pageService.interestedFeeds(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intFeedsNextPage === 1) {
            this.interestedFeeds = response.response.results;
          } else {
            this.interestedFeeds = this.interestedFeeds.concat(response.response.results);
          }
          this.totalIntFeedsCount = response.response.total_count;
          this.intFeedsNextPage = response.response.next;
        } else {
          this.interestedFeeds = [];
          this.totalIntFeedsCount = 0;
          this.intFeedsNextPage = 1;
        }
      }, error => {
          this.interestedFeeds = [];
          this.totalIntFeedsCount = 0;
          this.intFeedsNextPage = 1;
      });
  }

  getAllPages(){
    if (this.myPageNextPage) {
      let params = '?page=' + this.myPageNextPage;
      this.profileService.getMyPages(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.myPageNextPage == 1) {
            this.pageslist = response.response.results;
          } else {
            this.pageslist = this.pageslist.concat(response.response.results);
          }
          this.totalPageCount = response.response.total_count;
          this.myPageNextPage = response.response.next;
        } else {
          this.pageslist = [];
          this.totalPageCount = 0;
          this.myPageNextPage = 1;
        }
      });
    }
  }

  openModalFollow(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall custom-profile-modal' }));
  }

  getFollowedPages(){
    if (this.followedNextPage) {
      let params = '?page=' + this.followedNextPage;
      this.profileService.getFollowedPages(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.followedNextPage == 1) {
            this.followedPages = response.response.results;
          } else {
            this.followedPages = this.followedPages.concat(response.response.results);
          }
          this.totalFollowedCount = response.response.total_count;
          this.followedNextPage = response.response.next;
        } else {
          this.followedPages = [];
          this.totalFollowedCount = 0;
          this.followedNextPage = 1;
        }
      });
    }
  }

  OpenPage(page: any) {
    if ([1,2].indexOf(page.page_info.page_type) > -1) {
      this.router.navigate(['page/' + page.id + '/profile-business']);
    } else if (page.page_info.page_type === 5) {
      this.router.navigate(['edit-initiatives/' + page.id + '/'])
    } else {
      this.router.navigate(['page/' + page.id + '/profile-school']);
    }
  }

  OpenPageDashboard(page: any) {
    if ([1,2].indexOf(page.page_info.page_type) > -1) {
      this.router.navigate(['page/' + page.id + '/profile-business']);
    } else if (page.page_info.page_type === 5) {
      this.router.navigate(['edit-initiatives/' + page.id + '/']);
    } else {
      this.router.navigate(['page/' + page.id + '/profile-school']);
    }
  }

  directToProfile(feed: any){
    if (feed.post_info.creator_id === this.user.id) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['profile/' + feed.post_info.creator_id + '/']);
    }
  }

  openInterestedFeedModal(template: TemplateRef<any>) {
    if (this.totalIntFeedsCount > 0) {
      this.intModalRef = this.modalService.show(
        template,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui interested-popup' })
      );
    }
  }

  closeModal(){
    if (this.otherModalRef) {
      this.otherModalRef.hide();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.intModalRef) {
      this.intModalRef.hide();
    }
  }

  markInterested(feed: any){
    if (feed.interested_data.interested) {
      this.pageService.unMarkInterested(feed.id, feed.post_info.post_type)
      .subscribe(response => {
        this.intFeedsNextPage = 1;
        this.getInterestedFeeds();
      });
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = false;
      }
    } else {
      this.pageService.markInterested(feed.id, feed.post_info.post_type, {})
      .subscribe(response => {
        this.intFeedsNextPage = 1;
        this.getInterestedFeeds();
      });
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = true;
      }
    }
  }

  feedDetailModal(feedDetail: TemplateRef<any>, feed: any) {
    this.editFeedObj = feed;
    this.modalRef = this.modalService.show(feedDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position newfeeddetail' })
    );
  }

  OpenPageSettings(pagee: any) {
    let url = 'page/' + pagee.id;
    if ([1,2].indexOf(pagee.page_info.page_type) > -1) {
      url = url + '/profile-business?tab=setting';
      this.router.navigateByUrl(url);
    } else {
      url = url + '/profile-school?tab=setting';
      this.router.navigateByUrl(url);
    }
  }

  openModalOthers(template: TemplateRef<any>, feed: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editFeedObj = feed;
    this.getInterestedUsers();
    this.otherModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  createInitiative(createInitiativeTemplate: TemplateRef<any>) {
    this.school_id = 0;
    this.getUserSchools();
    this.initiativeForm.reset();
    this.uploadForm.reset();
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(createInitiativeTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  getUserSchools(){
    this.schools = [];
      this.profileService.getUserSchools()
      .subscribe(response => {
        if (response.response.length > 0) {
          this.schools = response.response;
        } else {
          this.schools = [];
        }
      }, error => {
        this.schools = [];
      })
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editFeedObj.id, this.editFeedObj.post_info.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true;
    } else {
      return false;
    }
  }

  checkExtraData(feed: any){
    if (feed.link && feed.metadata_title && (feed.metadata_description || feed.metadata_image_link)){
      return true;
    } else if (feed.link && !feed.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  directToPage(feed: any) {
    if (feed.post_info.ini_id) {
      this.router.navigate(['initiatives/' + feed.post_info.ini_id + '/']);
    } else if (feed.post_info.page_id) {
      this.router.navigate(['page/' + feed.post_info.page_id + '/']);
    }
  }

  unFollowPageIni(instance: any){
    if (instance.page_info.page_ini_id) {
      if (instance.page_info.page_type === 5) {
        this.unFollowInitiative(instance);
      } else {
        this.unFollowPage(instance);
      }
    }
  }

  unFollowInitiative(instance: any){
    this.followBtnErr = false;
    this.pageService.unFollowInitiative(instance.page_info.page_ini_id, {})
    .subscribe(response => {
      let index = this.followedPages.findIndex(obj => obj.id === instance.id);
      this.followedPages.splice(index, 1);
      this.totalFollowedCount = this.totalFollowedCount - 1;
    }, error => {
      this.followBtnErr = true;
      this.fadeOutFollowErrMsg();
    });
  }

  unFollowPage(instance: any){
    this.followBtnErr = false;
    this.pageService.unFollowPage(instance.page_info.page_ini_id, {})
    .subscribe(response => {
      let index = this.followedPages.findIndex(obj => obj.id === instance.id);
      this.followedPages.splice(index, 1);
      this.totalFollowedCount = this.totalFollowedCount - 1;
    }, error => {
      this.followBtnErr = true;
      this.fadeOutFollowErrMsg();
    });
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 3000);
  }

  directToPageIni(instance: any){
    if (instance.page_info.page_type === 5) {
      if (instance.page_info.owner_id === this.user.id) {
        this.router.navigate(['edit-initiatives/' + instance.page_info.page_ini_id + '/']);
      } else {
        this.router.navigate(['initiatives/' + instance.page_info.page_ini_id + '/']);
      }
    } else {
      if (instance.page_info.owner_id === this.user.id) {
        this.router.navigate(['page/' + instance.page_info.page_ini_id + '/profile-school']);
      } else {
        this.router.navigate(['page/' + instance.page_info.page_ini_id + '/']);
      }
    }
  }

  getImageUrl() {
    this.displayImage = "assets/images/p-img.png";
    if (this.getImgCount === 0){
      this.profileService.getUserImage(this.user.id)
      .subscribe(response => {
        if (response.status === 200) {
          if (response.response.display_picture){
            this.displayImage = response.response.display_picture;
            this.user.display_picture = response.response.display_picture;
          }
          if (response.response.header_image){
            this.user.header_image = response.response.header_image;
          }
          localStorage.setItem('user', JSON.stringify(this.user));
        }
      })
    }
    this.getImgCount = this.getImgCount + 1;
  }

  addInitiative(isValid: boolean, initiativeSuccessTemplate: TemplateRef<any>){
    this.submitted = true;
    this.disableSubmit = true;
    let school = this.school_id;
    if (this.school_id === 0){
      school = null;
    }
    if (isValid){
      let model = {
        'school': school,
        'user': this.user.id,
        'title': this.initiativeForm.get('title').value,
        'city': this.initiativeForm.get('city').value,
        'state': this.initiativeForm.get('state').value,
        'display_picture': this.uploadForm.get('thumbnail').value,
        'display_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addInitiative(model)
        .subscribe(response => {
          this.handleInitiativeResponse(response, initiativeSuccessTemplate);
        }, error => {
          this.handleSubmitError(error.error)
        })
    }
  }

  coverImageUpload(event, isPost: boolean = false){
    let fileSizeVal = 2000;
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (isPost){
      fileSizeVal = 5000;
      allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf"];
    }
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
        if (isPost){
          this.coverImageUploadMessage = "Only jpg, png and pdf files allowed.";
        }
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > fileSizeVal) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 2MB.";
        if (isPost){
          this.coverImageUploadSizeMessage = "Image/Document size should be less than 5MB.";
        }
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          if (isPost) {
            let timelineDoc = {'previewImg': reader.result, 'document': base64String, 'document_name': file_name};
            this.timelineDocs.push(timelineDoc);
          } else {
            this.previewThumbnail = reader.result;
            this.previewThumbnailName = file_name;
            this.uploadForm.get('thumbnail').setValue(base64String);
            this.uploadForm.get('thumbnail_name').setValue(file_name);
          }
        };
      }
    }
  }

  protected handleInitiativeResponse(response: any, initiativeSuccessTemplate: TemplateRef<any>) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.modalRef = this.modalService.show(initiativeSuccessTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
      this.myPageNextPage = 1;
      this.getAllPages();
      this.totalPageCount = this.totalPageCount + 1;
      this.initiativeForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.initiativeForm.contains(field)) {
        this.initiativeForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

}
