<section class="landingBanner">
    <div class="forLogo">
        <div class="container custom-container">
            <div class="row">
                <div class="col forLogoInner">
                    <div class="leftWrap">
                        <a href="/">
                            <img src="assets/images/FullLogoBlue.png" class="new-logo" alt="logo" />
                        </a>
                    </div>
                    <div class="rightWrap">
                        <div class="forSignUpHeader">
                            <button class="btn signInHdrNew mr-3" [routerLink]="['/signin']">Sign In</button>
                            <button class="btn signUpHdrNew" [routerLink]="['/signup']">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="forBanner">
        <div class="container custom-container">
            <div class="row">
                <div class="col-12">
                    <div class="banner-content">
                            <div class="forImage">
                                    <img src="../../assets/images/mobile-banner.png" class="landingBannerUser" alt="banner" />
                                </div>

                    <div class="forDes">
                            <h1>We understand that there's pressure on you to have your future figured out</h1>
                            <p>The answer is: <mark>experience</mark></p>
                            <button class="btn signUpHdrNew" [routerLink]="['/signup']">Start here</button>
                            <img src="../../assets/images/find-create.svg" alt="" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>



<section class="twoBlocksOuter">

    <section class="twoBlocks">
            <div class="container custom-container">
                <div class="row">
                    <div class="col-md-12">
                       <div class="landing-content-box">
                            <div class="leftWrap">
                                    <img src="../../assets/images/landing-mobile-text.png" class="" alt="image" />
                                    <img class="d-md-none d-block" src="../../assets/images/text-cloud-your-school.svg" alt="text" />
                            </div>
                            <div class="rightWrap">
                                    <h2 class="hdgTop">Find all student experiences on the feed</h2>
                                <p class="des">Add your school and instantly see (or post) all <mark>events, positions and ideas.</mark> Find experiences or students for school clubs, teams, and initiatives.</p>
                                <img src="../../assets/images/text-cloud-your-school.svg" alt="text" />
                               </div>
                       </div>
                    </div>

                </div>
            </div>
    </section>
    <section class="twoBlocks shuffleImage">
            <div class="container custom-container">
                <div class="row">
                    <div class="col-md-12">
                       <div class="landing-content-box">
                            <div class="leftWrap">
                                    <img src="../../assets/images/landing-mobile-text-2.png" class="" alt="image" />
                                    <img class="stars" src="../../assets/images/stars.svg" alt="stars" />
                            </div>
                            <div class="rightWrap">
                                    <h2 class="hdgTop">Create new experiences</h2>
                                <p class="des">Post your ideas to the rest of your school.

                                        Launch a sporting event, start an eco club,

                                        build an initiative... you never know who might want to get involved!</p>
                               </div>
                       </div>
                    </div>

                </div>
            </div>
    </section>

    <section class="thinkLink">
            <div class="container custom-container">
                <div class="row">
                    <div class="col-12">
                        <div class="content-box">
                                <h2 class="hdgTop">Showcase your experiences on a <mark>digital resume</mark></h2>
                                <p class="des">Showcase all of your extracurriculars, skills, education and more!</p>
                        </div>
                        <img src="../../assets/images/laptop-landing.png" alt="laptop" />
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <img src="../../assets/images/building-text.svg" alt="building" />
                    </div>
                </div>
            </div>
        </section>
    <section class="community">
        <div class="container custom-container">
            <div class="row">
                <div class="col-12">
                    <h1 class="hdgTop">Let's show everyone what a community of students is able to achieve.
                    <img src="../../assets/images/smile.svg" alt="smile" />
                    </h1>
                </div>

                <div class="col-12">
                    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                        [config]="communityConfig">
                        <div class="communitySlide slide" ngxSlickItem>
                            <div class="communitySlideInner">
                                <img src="assets/images/landingAboutIcon.svg" class="iconImage" alt="image" />
                                <h1>About Us</h1>
                                <p>Learn about the students building ConnectUs.today, and much more!</p>
                                <a href="https://www.aboutcu.today/" target="_blank" class="btn learnMoreBtn">Learn More</a>
                            </div>
                        </div>

                        <div class="communitySlide slide" ngxSlickItem>
                            <div class="communitySlideInner">
                                <img src="assets/images/landingSearch.svg" class="iconImage" alt="image" />
                                <h1>Search Initiatives</h1>
                                <p>See the impact that students are making in your community</p>
                                <a href="javascript:void();" [routerLink]="['/search-initiative']" class="btn learnMoreBtn">Learn More</a>
                            </div>
                        </div>

                        <div class="communitySlide slide" ngxSlickItem>
                            <div class="communitySlideInner">
                                <img src="assets/images/landingCU4.svg" class="iconImage" alt="image" />
                                <h1>CU 4 Community</h1>
                                <p>Want to build your own initiative? Visit this non-profit's website!</p>
                                <a href="https://www.cuclub.today/"  target="_blank" class="btn learnMoreBtn">Learn More</a>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </section>
</section>
