<div class="row">
    <div class="col-md-12">
        <div class="connectionNetworkList scroll-activity scrollbar">
            <ul class="no-list pr-2">
                <li *ngFor="let approvedReq of expTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                                src="{{ approvedReq.exp_team_detail.display_pic ? approvedReq.exp_team_detail.display_pic :'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{approvedReq.exp_team_detail.position}}</h2>
                            <h4>{{approvedReq.exp_team_detail.organization_title}}</h4>
                            <p>{{approvedReq.exp_team_detail.start_date | date: 'MMM y'}}
                                <ng-template [ngIf]="approvedReq.exp_team_detail.end_date" [ngIfElse]="currentTemp">
                                    <span>- {{approvedReq.exp_team_detail.end_date | date: 'MMM y'}}</span>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btn common-btn btnMessage" (click)="cancelTeam(approvedReq.id)">Remove</button>
                    </div>
                </li>

                <li *ngFor="let approvedReq of eduTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                                src="{{ approvedReq.edu_team_detail.header_image ? approvedReq.edu_team_detail.header_image :'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{approvedReq.edu_team_detail.school_name}}</h2>
                            <p>{{approvedReq.edu_team_detail.start_date | date:'yyyy'}}
                                - {{approvedReq.edu_team_detail.end_date | date:'yyyy'}}
                            </p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btn common-btn btnMessage"
                            (click)="cancelEduTeam(approvedReq.id)">Remove</button>
                    </div>
                </li>

                <li *ngFor="let approvedReq of iniTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                                src="{{ approvedReq.ini_team_detail.header_image ? approvedReq.ini_team_detail.header_image :'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{approvedReq.ini_team_detail.position}}</h2>
                            <h4>{{approvedReq.ini_team_detail.ini_name}}</h4>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btn common-btn btnMessage"
                            (click)="cancelIniTeam(approvedReq.id)">Remove</button>
                    </div>
                </li>

                <li *ngIf="expTeam.length == 0 && eduTeam.length == 0 && iniTeam.length == 0">
                    No More Record(s).
                </li>
            </ul>
        </div>
    </div>
</div>

<ng-template #currentTemp>
    <span>Present</span>
</ng-template>
