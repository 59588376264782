import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-parent-verification',
  templateUrl: './parent-verification.component.html',
  styleUrls: ['./parent-verification.component.scss']
})
export class ParentVerificationComponent implements OnInit {

  changeEmailForm: FormGroup;
  disableSubmit: boolean = false;
  submitted: boolean = false;
  parentEmail: string = '';
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, 
    private authService: AuthenticationService,  private customValidator: CustomvalidationService,) {
    this.changeEmailForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      parent_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  ngOnInit(): void {
  }

  changeParentMail(successModal: TemplateRef<any>){
    if (!this.changeEmailForm.valid){
      this.submitted = true;
      return
    }
    this.submitted = false;
    this.disableSubmit = true;
    let data =  {
        'password': this.changeEmailForm.controls['password'].value,
        'email': this.changeEmailForm.controls['email'].value,
        'parent_email': this.changeEmailForm.controls['parent_email'].value,
    }
    this.authService.changeParentEmail(data)
      .subscribe(response => {
        this.handleFormubmit(response, successModal);
      }, error => {
        this.handleFormError(error.error);
      });
  }

  changeFormInit() {
    this.changeEmailForm.reset();
    this.submitted = false;
  }

  protected handleFormubmit(response: any, successModal: TemplateRef<any>) {
    if (response.status === 111) {
      this.handleFormError(response.message);
    } else if (response.status === 200) {
        this.parentEmail = response.response.parent_email;
        this.changeFormInit();
        this.disableSubmit = false;
        this.modalRef = this.modalService.show(
          successModal, Object.assign({}, { 
            class: 'modal-dialog-centered custom-modal'}));
        
    }
  }

  protected handleFormError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.changeEmailForm.contains(field)) {
        this.changeEmailForm.get(field).setErrors({ aftersubmit: data[field] });
      }
    });
  }

}
