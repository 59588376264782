import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../services/authentication.service';
import { School } from '../shared/school';
import { schoolPage } from '../shared/schoolPage';
import { schoolUserPage } from '../shared/schoolUserPage';
import { User } from '../shared/user';
import { SchoolUser } from '../shared/schoolUser';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { canadaStates, usStates } from '../app.data';

@Component({
  selector: 'app-create-clubteam-page',
  templateUrl: './create-clubteam-page.component.html',
  styleUrls: ['./create-clubteam-page.component.scss']
})
export class CreateClubteamPageComponent implements OnInit {


  enteredEmail: string;
  nextShow: boolean = false;
  currentForm: boolean = true;
  firstStep: boolean = false;
  secondStep: boolean = false;
  firstStepForm: FormGroup;
  searchForm: FormGroup;
  public submitted: boolean = false;
  public successOverlay: boolean = false;
  public searchSubmitted: boolean = false;
  public caStates: any[] = canadaStates;
  public usStates: any[] = usStates;
  public showCaStates: boolean = true;
  public showAddressError: boolean = false;
  public partialSubmitted: boolean = false;
  public hideResults: boolean = true;
  public schools: any[];
  schoolId: string = null;
  plchldrState = "PR";
  plchldrPostal = "Postal";
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  updateAddress: string = '';
  countrySet: string = 'CA';
  public invalidDate: boolean = false;
  maxNum: number = new Date().getFullYear();
  public dob: string;
  public age: number;
  public invalidDateMsg: string;
  professional_email: string = null;
  
  user;

  school: School = {
    title: '',
    school_name: '',
    account_type: 1,
    school_category: 8,
    address: '',
    country: '',
    state: '',
    city: '',
    district: '',
    zip_code: '',
    professional_email: undefined,
    latitude: undefined,
    longitude: undefined
}

schoolUser: SchoolUser = {
  title: '',
  school: '',
  account_type: 1,
  is_representative: true,
  professional_email: undefined
}

schoolPage: schoolPage = {
  club_team : this.school,
  professional_email : this.professional_email
};

schoolUserPage: schoolUserPage = {
  club_team : this.schoolUser,
  professional_email : this.professional_email
};

constructor(private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder,
  private customValidator: CustomvalidationService, private location: Location) {

  if (localStorage.getItem('user')) {
      var user_json = JSON.parse(localStorage.getItem('user'));
      this.user=user_json.id;
      if (user_json.age_group == 1) {
        this.router.navigate(['/dashboard']);
      }
  }
  this.searchForm = this.formBuilder.group({
    title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
    school_name: new FormControl('', [Validators.required, Validators.maxLength(125)]),
    country: new FormControl('', Validators.required),
    account_type: new FormControl('', Validators.required),
    state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    professional_email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),

  });
  this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
  this.firstStepForm = this.formBuilder.group({
    title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
    school_name: new FormControl('', [Validators.required, Validators.maxLength(125)]),
    address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    country: new FormControl('', Validators.required),
    state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    district: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
    zip_code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });
  this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});

} 

slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

ngOnInit(): void {
  this.showCaStates = true;
  this.hideResults = true;
}

searchSchool(e){
  let key = e.target.value;
  this.schoolId = null;
  if (key !== null && key.length > 0) {
    this.schools = [];
    let state = this.searchForm.get('state').value;
    if (state == null) {
      state = "";
    }
    let country = this.searchForm.get('country').value;
    const params = key.toLowerCase() + '&country=' + country.toLowerCase() + '&state=' + state.toLowerCase();
    this.authService.searchSchool(params)
    .subscribe(response => {
      if (response.status == 200){
        this.hideResults = false;
        this.schools = response.response;
      }
    });
  } else {
    this.hideResults = true;
    this.schools = [];
  }
}

focusOutFunction(){
  setTimeout(() => {
    this.hideResults = true;
    this.schools = [];
  }, 1000);
}

selectSchool(school) {
  this.hideResults = true;
  this.searchForm.controls['school_name'].setValue(school.school_name);
  this.firstStepForm.controls['school_name'].setValue(school.school_name);
  this.schoolId = school.id;
  this.schools = [];
}

showNext(isValid: boolean){
  if (isValid && this.schoolId == null) {
    this.nextShow = true;
    this.currentForm = false;
    this.firstStep = false;
    this.secondStep = false;
    this.firstStepForm.controls['title'].setValue(this.searchForm.get('title').value);
    this.firstStepForm.controls['country'].setValue(this.searchForm.get('country').value);
    this.firstStepForm.controls['state'].setValue(this.searchForm.get('state').value);
    this.firstStepForm.controls['school_name'].setValue(this.searchForm.get('school_name').value);
  } else if (isValid && this.schoolId !== null) {
      this.nextShow = false;
      this.currentForm = false;
      this.firstStep = false;
      this.secondStep = true;
  } else {
    this.searchSubmitted = true;
  }
}

showNextForm(){
  this.searchSubmitted = false;
  this.nextShow = false;
  this.currentForm = false;
  this.firstStep = true;
  this.secondStep = false;
}

showLastForm(isValid: boolean){
  if (isValid){
    this.firstStep = false;
    this.secondStep = true;
  } else {
    this.partialSubmitted = true;
  }
}

checkPassword(formGroup: FormGroup) {
  const { value: password } = formGroup.get('password');
  const { value: confirmPassword } = formGroup.get('confirm_password');
  return password === confirmPassword ? null : { passwordNotMatch: true };
}

checkEmail(formGroup: FormGroup) {
  const { value: email } = formGroup.get('email');
  const { value: confirmEmail } = formGroup.get('confirm_email');
  return email === confirmEmail ? null : { emailNotMatch: true };
}

onCheckboxChange(e) {
  if (e.target.checked) {
    this.user.is_terms_accepted = true;
  } else {
    this.user.is_terms_accepted = false;
  }
}

disableOverlay(){
  this.successOverlay = false;
  this.enteredEmail = null;
}

changeAddress(e){
  if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
    this.firstStepForm.controls['address'].setValue(null);
    this.showAddressError = true;
  } else {
    this.firstStepForm.controls['address'].setValue(e.target.value);
    this.showAddressError = false;
  }
}

getAddrComponent(place, componentTemplate) {
  let result;

  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentTemplate[addressType]) {
      result = place.address_components[i][componentTemplate[addressType]];
      return result;
    }
  }

  return;
}

getCity(place) {
  const COMPONENT_TEMPLATE = { locality: 'long_name' },
  city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
  return city;
}

getState(place) {
  const COMPONENT_TEMPLATE = { administrative_area_level_1: 'long_name' },
    state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
  return state;
}

getPostCode(place) {
  const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
    postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
  return postCode;
}

changeCountry(e){
  if (e.target.value == "USA"){
    this.showCaStates = false;
    this.plchldrState = "State";
    this.plchldrPostal = "ZIP Code";
    this.countrySet = 'US';
    this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
  } else {
    this.showCaStates = true;
    this.plchldrState = "PR";
    this.plchldrPostal = "Postal";
    this.countrySet = 'CA';
    this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  }
}

save(isValid: boolean) {
  this.submitted = true;
  if (isValid) {
    if (this.schoolId != null) {
        this.schoolUser.title = this.searchForm.get('title').value;
        this.schoolUser.account_type = this.searchForm.get('account_type').value;
        this.schoolUser.school = this.schoolId;
        this.schoolUser.professional_email = this.searchForm.get('professional_email').value ? this.searchForm.get('professional_email').value : this.professional_email;
        this.schoolUser.is_representative = false;
        this.schoolUserPage.professional_email = this.schoolUser.professional_email;
        this.authService.createSchoolUserPage(this.schoolUserPage)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
            this.handleSubmitError(error.error);
        });
    } else {
      this.school.title = this.searchForm.get('title').value;
      this.school.account_type = this.searchForm.get('account_type').value;
      this.school.school_name = this.searchForm.get('school_name').value;
      this.school.professional_email = this.searchForm.get('professional_email').value ? this.searchForm.get('professional_email').value : this.professional_email;
      this.schoolPage.professional_email = this.school.professional_email;
      this.school.address = this.firstStepForm.get('address').value;
      this.school.country = this.firstStepForm.get('country').value;
      this.school.state = this.firstStepForm.get('state').value;
      this.school.city = this.firstStepForm.get('city').value;
      this.school.zip_code = this.firstStepForm.get('zip_code').value;
      this.school.district = this.firstStepForm.get('district').value;
      this.school.school_category = this.school.school_category
      
      this.authService.createSchoolPage(this.schoolPage)
      .subscribe(response => {
        this.handleResponse(response);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }
  }else {
    this.partialSubmitted = true;
  }
}

protected handleResponse(response: any) {
  if (response.status === 111) {
    this.handleSubmitError(response.message);
  } else if (response.status === 200) {
      this.successOverlay = true;
      this.firstStep = false;
      this.secondStep = false;
      this.nextShow = false;
      this.currentForm = true;
      this.firstStepForm.reset();
      this.searchForm.reset();
      this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.submitted = false;
      this.partialSubmitted = false;
      this.searchSubmitted = false;
      this.schoolId = null;
      this.router.navigate(['/dashboard']);
  } else {
      this.firstStep = false;
      this.secondStep = false;
      this.nextShow = false;
      this.currentForm = true;
      this.firstStepForm.reset();
      this.searchForm.reset();
      this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.submitted = false;
      this.partialSubmitted = false;
      this.searchSubmitted = false;
      this.schoolId = null;
  }
}

protected handleSubmitError(data: any) {
  this.submitted = false;
  this.partialSubmitted = false;
  this.searchSubmitted = false;
  const fields = Object.keys(data || {});
  let firstFormErr = false;
  fields.forEach(field => {
    if (this.firstStepForm.contains(field)) {
      this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
      firstFormErr = true;
    }
  });

  if (firstFormErr) {
    this.firstStep = true;
    this.secondStep = false;
    this.nextShow = false;
    this.currentForm = false;
  } else {
    this.firstStep = false;
    this.secondStep = true;
    this.nextShow = false;
    this.currentForm = false;
  }
}


getAddress(places: any[]) {
  this.searchPlaces = places;
}

setResultHide(e){
  this.hidePlaceResults = e;
}

formClick(){
  this.hidePlaceResults = true;
}

selectPlace(place){
  let lat = place.position.lat;
  let lon = place.position.lon;
  let address = place.address.freeformAddress.split(",")[0];
  this.updateAddress = address;
  this.firstStepForm.controls['address'].setValue(address);
  this.firstStepForm.controls['city'].setValue(place.address.municipality);
  if (lat && lon) {
    this.school.latitude = lat;
    this.school.longitude = lon;
  }
  this.firstStepForm.controls['state'].setValue(place.address.countrySubdivisionName);
  this.hidePlaceResults = true;
  this.searchPlaces = [];
}

validateEmail(){
  let email = this.searchForm.get('professional_email').value;
  if (email && email.length > 0 && this.searchForm.controls['professional_email'].valid) {
    this.authService.validateEmail({"professional_email": email})
    .subscribe(response => {
      if (response.status === 111) {
        this.searchForm.controls['professional_email'].setErrors({'invalid_format': true});
      }
    })
  }
}

backClicked(){
  this.location.back();
}

}
