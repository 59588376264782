import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppConfigService } from './app.config.service';
import { BaseEventEndPoint, azureSearchURL } from '../app.config';
import { IEvent } from '../shared/event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private baseEventURL:string = BaseEventEndPoint.apiEndpoint;
  private azureSearchURLName: string = azureSearchURL.apiEndpoint;

  constructor(private http: BaseService, private configProvider: AppConfigService) { }

  getSearchAzure(query='', countrySet='CA,US'){
    return this.http.get(this.azureSearchURLName + this.configProvider.config.azure_subscription_key + '&query=' + query + "&countrySet=" + countrySet);
  }

  addEvent(EventOb: IEvent) {
    return this.http.post(this.baseEventURL, EventOb);
  }

  listEvents(month:string='', year:string='') {
    return this.http.get(this.baseEventURL + '?month=' + month + '&year=' + year);
  }

  updateEvent(EventOb: IEvent, id: string) {
    return this.http.put(this.baseEventURL + id + '/', EventOb);
  }

  RetreiveEvent(id: string) {
    return this.http.get(this.baseEventURL + id + '/');
  }

  addPageEvent(EventOb: IEvent) {
    return this.http.post(this.baseEventURL + 'page/', EventOb);
  }

  listPageEvents(month:string='', year:string='', id: string) {
    return this.http.get(this.baseEventURL + 'page/' + '?month=' + month + '&year=' + year + '&page=' + id);
  }

  updatePageEvent(EventOb: IEvent, id: string) {
    return this.http.put(this.baseEventURL + 'page/' + id + '/', EventOb);
  }

  RetreivePageEvent(id: string) {
    return this.http.get(this.baseEventURL + 'page/' + id + '/');
  }

  GetOtherUserEventList(page_id: any, month:string='', year:string='') {
    return this.http.get(this.baseEventURL + 'pageuser/' + page_id + '/' + '?month=' + month + '&year=' + year);
  }

  getWeekEvent(page_id: any){
    return this.http.get(this.baseEventURL + 'page-event/' + page_id + '/');
  }

}
