<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="SignInInnerWrap WrapWhiteShadow">
                        <h1 class="hdg mb-2">Forgot Password</h1>
                        <form *ngIf="!submitted" class="" [formGroup]="emailForm" novalidate (ngSubmit)="submit(emailForm.valid)">
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <input type="email" class="form-control signUpField" formControlName="email" placeholder="E-mail">
                                    <div class="error-message" *ngIf="emailForm.get('email').hasError('required') && (emailForm.get('email').dirty || emailForm.get('email').touched)">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-0 text-center pt-2">
                                <button type="submit" class="btn btnSignUp" [disabled]="!emailForm.valid">Submit</button>
                            </div>
                        </form>
                        <span *ngIf="submitted" class="text-center success-msg d-inline-block">If there is an account associated with email entered, you will receive an email with a link to
                            reset your password.</span>
                    </div>
                    <div class="SignUpInnerWrap WrapWhiteShadow m-0">
                        <h1 class="hdg mb-4 pb-2">Back to Home Page</h1>
                        <div class="form-group m-0 text-center">
                            <button type="button" [routerLink]="['/']" class="btn btnSignUp">Click Here</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
