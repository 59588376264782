import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.scss']
})
export class TermsPolicyComponent implements OnInit {
  terms: any = "terms";
  termsContent: any = "";
  
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.authService.staticContent(this.terms)
    .subscribe(response => {
      this.termsContent = response
    });
  }

}
