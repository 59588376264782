import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseAccountEndPoint, BaseProfileEndPoint, AccountVerificationEndPoint, BaseInitiativeEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private baseAccountsUrl: string = BaseAccountEndPoint.apiEndpoint;
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;
  private accountVerificationUrl: string = AccountVerificationEndPoint.apiEndpoint;
  private baseInitiativeUrl: string = BaseInitiativeEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  addAdmin(obj: any) {
    return this.http.post(this.baseAccountsUrl + 'send-invite/', obj);
  }

  getPageAdminInvites(id: string) {
    return this.http.get(this.baseAccountsUrl + 'get-page-invites/' + id + '/');
  }

  getUserInvites() {
    return this.http.get(this.baseAccountsUrl + 'get-user-invites/');
  }

  deletePageAdmin(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'delete-page-admin/' + id + '/', obj);
  }

  cancelPageAdmin(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'cancel-invite/' + id + '/', obj);
  }

  transferPageOwnership(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'change-page-owner/' + id + '/', obj);
  }

  inviteResponse(id: string, params: any, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'invite-response/' + id + '/?approve=' + params, obj);
  }

  addPageDescription(desc: any) {
    return this.http.post(this.baseProfileUrl + 'add-page-description/', desc);
  }

  getPageDescription(id: string) {
    return this.http.get(this.baseProfileUrl + 'page-description/' + id + '/');
  }

  updatePageDescription(desc: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'update-page-description/' + id + '/', desc);
  }

  deletePageDescription(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-page-description/' + id + '/');
  }

  getPageExperiences(page_id: string, id: string) {
    return this.http.get(this.baseProfileUrl + 'page-experiences/' + page_id + '/' + id + '/');
  }

  getSchoolPageExperiences(page_id: string) {
    return this.http.get(this.baseProfileUrl + 'clubteam-page-experiences/' + page_id + '/');
  }

  verifyPageExperiences(obj: any, params: any) {
    return this.http.put(this.baseProfileUrl + 'experience-verification/' + params, obj);
  }

  verifyPageActivity(obj: any, params: any) {
    return this.http.put(this.baseProfileUrl + 'verify-activity/' + params, obj);
  }

  verifyClubTeam(obj: any, params: any) {
    return this.http.put(this.baseProfileUrl + 'clubteam-verification/' + params, obj);
  }

  getPageDetails(page_id: string) {
    return this.http.get(this.baseAccountsUrl + 'page-details/' + page_id + '/');
  }

  getSchoolDetails(school_id: string) {
    return this.http.get(this.baseAccountsUrl + 'school-details/' + school_id + '/');
  }

  updatePageLocationDisplay(id:any, params:any){
    return this.http.put(this.baseAccountsUrl + 'update-display-location/' + id + '/', params)
  }

  updateSchoolImage(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'school-profile/' + id + '/', data);
  }

  deleteSchoolImage(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'school-image/' + id + '/', data);
  }

  addVerifiers(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-verifier/', data);
  }

  getVerifiers(page_id: string, params: string = '') {
    return this.http.get(this.baseProfileUrl + 'verifiers/' + page_id + '/' + params);
  }

  makeVerifierAdmin(data: any, id: any) {
    return this.http.post(this.baseProfileUrl + 'verifier-admin/' + id + '/', data);
  }

  deleteVerifier(id: string, obj: any) {
    return this.http.put(this.baseProfileUrl + 'delete-verifier/' + id + '/', obj);
  }

  getSchoolExperiences(id: string) {
    return this.http.get(this.baseProfileUrl + 'school-experiences/' + id + '/');
  }

  verifyClaim(params: any){
    return this.http.get(this.accountVerificationUrl + params);
  }

  getLinks(id: string){
    return this.http.get(this.baseAccountsUrl + 'get-page-links/' + id + '/');
  }

  teamMembers(page_id: string) {
    return this.http.get(this.baseProfileUrl + 'team-members/' + page_id + '/');
  }

  listAllClubTeam(page_id: string, param: string){
    return this.http.get(this.baseProfileUrl + 'club-team/' + page_id + '/' +param);
  }

  listAllClub(page_id: string, param: string){
    return this.http.get(this.baseProfileUrl + 'club-team/' + page_id + '/1/' + param);
  }

  listAllTeam(page_id: string, param: string){
    return this.http.get(this.baseProfileUrl + 'club-team/' + page_id + '/2/' + param);
  }

  addManagementVolunteer(data: any){
    return this.http.post(this.baseProfileUrl + 'add-management/', data);
  }

  getManagement(page_id: string, param){
    return this.http.get(this.baseProfileUrl + 'get-management/' + page_id + '/' + param);
  }

  deleteManagement(id: string){
    return this.http.delete(this.baseProfileUrl + 'delete-management/' + id + '/');
  }

  checkPageUser(id: string) {
    return this.http.get(this.baseAccountsUrl + 'check-page-user/' + id + '/');
  }

  expteamMembers(exp_id: string) {
    return this.http.get(this.baseProfileUrl + 'team-members/experience/' + exp_id + '/');
  }

  actteamMembers(exp_id: string) {
    return this.http.get(this.baseProfileUrl + 'team-members/activity/' + exp_id + '/');
  }

  schInvteamMembers(exp_id: string) {
    return this.http.get(this.baseProfileUrl + 'team-members/sch-inv/' + exp_id + '/');
  }

  listAllInitiatives(sch_id: string, param: string){
    return this.http.get(this.baseProfileUrl + 'get-initiative/' + sch_id + '/' + param);
  }

  followSchool(id: any, data: any) {
    return this.http.post(this.baseProfileUrl + 'follow-school/' + id + '/', data);
  }

  followInitiative(id: any, data: any) {
    return this.http.post(this.baseProfileUrl + 'follow-initiative/' + id + '/', data);
  }

  followPage(id: any, data: any) {
    return this.http.post(this.baseProfileUrl + 'follow-page/' + id + '/', data);
  }

  unFollowSchool(id: any, data: any) {
    return this.http.put(this.baseProfileUrl + 'unfollow-school/' + id + '/', data);
  }

  unFollowInitiative(id: any, data: any) {
    return this.http.put(this.baseProfileUrl + 'unfollow-initiative/' + id + '/', data);
  }

  unFollowPage(id: any, data: any) {
    return this.http.put(this.baseProfileUrl + 'unfollow-page/' + id + '/', data);
  }

  markInterested(id: any, type: any, data: any) {
    return this.http.put(this.baseProfileUrl + 'mark-interested/' + id + '/' + type + '/', data);
  }

  unMarkInterested(id: any, type: any){
    return this.http.delete(this.baseProfileUrl + 'mark-interested/' + id + '/' + type + '/');
  }

  interestedUsers(id: any, type: any, param: string){
    return this.http.get(this.baseProfileUrl + 'get-interested-users/' + id + '/' + type + '/' + param);
  }

  interestedFeeds(param: string){
    return this.http.get(this.baseProfileUrl + 'interested-feeds/' + param);
  }

  schoolInvPages(){
    return this.http.get(this.baseProfileUrl + 'school-inv-pages/');
  }

  userInitiatives(){
    return this.http.get(this.baseInitiativeUrl + 'get-initiative/');
  }

  addSchoolTimeline(data: any) {
    return this.http.post(this.baseProfileUrl + 'add-school-timeline/', data);
  }

  listSchoolTimeline(school_id: string, param: string){
    return this.http.get(this.baseProfileUrl + 'school-timeline/' + school_id + '/' +param);
  }

  updateSchoolTimeline(data: any, id: string){
    return this.http.put(this.baseProfileUrl + 'update-school-timeline/' + id + '/', data);
  }

  deleteSchoolTimeline(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-school-timeline/' + id + '/');
  }

}
