<div class="customSearchWrap">

    <!-- <div class="searchTabsWrap pt-30">
        <div class="container">
            <ul class="borderTabs no-list">
                <li class="cursor-pointer" [ngClass]="{
                    'selected-tab': tabActive == 1
                }" (click)="tabActive=1">
                    <span>students</span>
                </li>
            </ul>
        </div>
    </div> -->

    <div class="searchResultsWrap">
        <div *ngIf="tabActive == 1">
            <div class="container">
                <div class="searchScroll">
                    <div class="heading-box">
                            <button type="button" class="back-btn" (click)="backClicked()">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.58 69.16">
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill: none;
                                                stroke: #31bfb8;
                                                stroke-linecap: round;
                                                stroke-miterlimit: 10;
                                                stroke-width: 15px;
                                            }
                                        </style>
                                    </defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <polyline class="cls-1" points="37.29 7.5 10.61 34.18 38.08 61.66" />
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        <h2>Student search</h2>
                        <button type="button" class="filter-btn" (click)="settingShow = !settingShow">Search Filters</button>
                    </div>
                    <div class="searchResultOuter resultWithForm">
                        <div class="eachResult mobile-result mb-0" [ngClass]="{ '': settingShow, show: !settingShow}">
                            <div class="profile-sidebar">
                                <div class="sidebarForm">
                                        <button type="button" class="close-btn d-lg-none d-flex" (click)="settingShow = !settingShow"><img alt="image" src="assets/images/cross-modal.svg"></button>
                                    <h3>
                                        Search (maximum of 1 entry per box)
                                    </h3>
                                    <form [formGroup]="searchForm" novalidate>
                                        <div class="form-group">
                                            <input type="text" class="form-control text-capitalize" placeholder="Name" formControlName="name">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('name').hasError('whitespace') && (searchForm.get('name').dirty || searchForm.get('name').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('name').hasError('maxlength') && (searchForm.get('name').dirty || searchForm.get('name').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="School" formControlName="school">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('school').hasError('whitespace') && (searchForm.get('school').dirty || searchForm.get('school').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('school').hasError('maxlength') && (searchForm.get('school').dirty || searchForm.get('school').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Skill or Interest"
                                                formControlName="skill_interest">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('skill_interest').hasError('whitespace') && (searchForm.get('skill_interest').dirty || searchForm.get('skill_interest').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('skill_interest').hasError('maxlength') && (searchForm.get('skill_interest').dirty || searchForm.get('skill_interest').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                placeholder="Business or NPO or Government Org" formControlName="business">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('business').hasError('whitespace') && (searchForm.get('business').dirty || searchForm.get('business').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('business').hasError('maxlength') && (searchForm.get('business').dirty || searchForm.get('business').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="School Club or Team"
                                                formControlName="club_team">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('club_team').hasError('whitespace') && (searchForm.get('club_team').dirty || searchForm.get('club_team').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('club_team').hasError('maxlength') && (searchForm.get('club_team').dirty || searchForm.get('club_team').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Education Program or Major"
                                                formControlName="program_major">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('program_major').hasError('whitespace') && (searchForm.get('program_major').dirty || searchForm.get('program_major').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('program_major').hasError('maxlength') && (searchForm.get('program_major').dirty || searchForm.get('program_major').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Books" formControlName="books">
                                            <div class="error-message"
                                                *ngIf="searchForm.get('books').hasError('whitespace') && (searchForm.get('books').dirty || searchForm.get('books').touched)">
                                                Please enter valid data
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.get('books').hasError('maxlength') && (searchForm.get('books').dirty || searchForm.get('books').touched)">
                                                Max 50 characters allowed.
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-group mb-0 text-center">
                                                <button type="submit" (click)="search(searchForm.valid);settingShow = !settingShow"
                                                    class="common-btn mt-1 d-md-none d-block">Search</button>
                                                <button type="submit" (click)="search(searchForm.valid)"
                                                    class="common-btn mt-1 d-md-flex d-none">Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="eachResultTwo">
                            <div class="row m-0">
                                <h2>
                                    Student Search
                                </h2>
                                <div class="eachResult eachResultHalf" *ngFor="let student of students">
                                    <div class="topImages cursor-pointer" [routerLink]="['/profile', student.id]">
                                        <!-- <img alt="banner-image" class="bannerImage" src="{{student.personal_info.header_image? student.personal_info.header_image + mediaAccessToken: './assets/images/new-header-img.png'}}" /> -->
                                        <img alt="profile-image" class="profileImage" src="{{student.personal_info.display_picture? student.personal_info.display_picture + mediaAccessToken: 'assets/images/p-img.png'}}" />
                                    </div>
            
                                    <div class="btmDetails">
                                        <h1 class="searchName cursor-pointer withDotText" [routerLink]="['/profile', student.id]">{{student.personal_info.name}}
                                            <span class="forDotName" *ngIf="student.personal_info.connection">
                                                <span class="forDot"></span>
                                                <span class="forName cursor-pointer" *ngIf="student.personal_info.connection == 1">1st</span>
                                                <span class="forName cursor-pointer" *ngIf="student.personal_info.connection == 2">2nd</span>
                                            </span>
                                        </h1>
                                       <div class="searchLocation">
                                            <p class="m-0">
                                                <span>{{student.user_schools}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                <div *ngIf="students.length == 0" class="noResultFound">
                        <h5 class="m-0">No Result(s) Found</h5>
                    </div>
                        </div>
                    </div>

                    <!-- <div class="searchResultOuter" *ngIf="students.length > 4">
                        <div class="eachResult" *ngFor="let student of students | slice:4">
                            <div class="topImages cursor-pointer" [routerLink]="['/profile', student.id]">
                                <img alt="banner-image" class="bannerImage" src="{{student.personal_info.header_image? student.personal_info.header_image + mediaAccessToken: './assets/images/new-header-img.png'}}" />
                                <img alt="profile-image" class="profileImage" src="{{student.personal_info.display_picture? student.personal_info.display_picture + mediaAccessToken: 'assets/images/p-img.png'}}" />
                            </div>
    
                            <div class="btmDetails">
                                <h1 class="searchName cursor-pointer withDotText" [routerLink]="['/profile', student.id]">{{student.personal_info.name}}
                                    <span class="forDotName" *ngIf="student.personal_info.connection">
                                        <span class="forDot"></span>
                                        <span class="forName cursor-pointer" *ngIf="student.personal_info.connection == 1">1st</span>
                                        <span class="forName cursor-pointer" *ngIf="student.personal_info.connection == 2">2nd</span>
                                    </span>
                                </h1>
                                <div class="searchLocation">
                                    <p class="m-0">
                                        <span>{{student.user_schools}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div *ngIf="totalStudents > page_size && totalStudents > students.length"
                        class="next-listing mt-md-4 mt-1 pb-4 timeLineLoad load-student">
                        <a href="javascript:void(0);" (click)="onScrollDown()">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>