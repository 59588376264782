<div class="titleDiv d-flex align-items-center">
    <h3 class="with-list-icon">School Involvement
    </h3>
    <a href="javascript:void()" class="float-right" (click)="expModal(expTemplate)">
        <img class="d-block" src="assets/images/plus-teal.svg" alt="icon">
    </a>
</div>

<div class="detailContent mb-0 px-0">
    <div class="single-image-wrap">
        <div class="media border-row cursor-pointer" *ngFor="let experience of schoolInvolvements | slice:0:3"
            (click)="updateExpModal(editExpTemplate, experience)">
            <div class="exp-verified">
                <div class="exp-verified-Inner">
                    <img alt="image" (click)="redirectPage(experience);$event.stopPropagation()"
                        src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                        alt="Education Cover" class="thumb-img">
                </div>
            </div>
            <div class="media-body ml-3">
                <h3 class="top-name">{{experience.position}}</h3>
                <h4>{{experience.title}}</h4>
                <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                    <span>{{experience.start_date | date: 'MMM y'}}</span> <span> - </span>
                    <ng-template
                        [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                        [ngIfElse]="currentTemp">
                        <span>{{experience.end_date | date: 'MMM y'}}</span>
                    </ng-template>
                </h5>
                <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                    <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                </h5>

                <p class="m-0">
                    <strong>
                        <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                        <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                        <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                        <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                        <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                    </strong>
                </p>
                <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                <div class="addRefRow">
                    <div class="addRefRowInner">
                            <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                    <p class="my-0 people-number" (click)="$event.stopPropagation();teamMemberView(experience)">
                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        <span>{{ experience.related_data.exp_count }}</span>
                                    </p>
                                </div>
                                <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                        <img alt="image" src="assets/images/verifyProfile.svg">
                                    </p>
                        <a href="javascript:void(0);" *ngIf="experience.page"
                            (click)="$event.stopPropagation(); addTeamMember(experience);">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                            <span>Add team members</span>
                        </a>
                        <a href="javascript:void(0);"
                            *ngIf="!experience.verified_by && experience.verification_status == 1 && user.age_group > 1 && experience.page"
                            (click)="addReferenceListing(addReferenceModal, experience); $event.stopPropagation()">
                            <img src="assets/images/verifyProfile.svg" alt="icon">
                            <span>Add reference</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="viewMoreWrapper" *ngIf="schoolInvolvementsTotal > 3">
        <span class="viewMore" (click)="extraCurricularListing(extraCurricularListModal)">
            View All ({{schoolInvolvementsTotal}})
        </span>
    </div>
</div>

<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p class="d-flex align-items-center mb-2"
                *ngIf="editSchoolInv.approver_name && editSchoolInv.verified_date">
                <span><img alt="" src="assets/images/verifyProfile.svg" class="tick-verified-img"></span>Verified
            </p>
            <form [formGroup]="schoolInvUpdForm" novalidate class="formHide">
                <div class="experience-div media w-100">
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>School Club or Team</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control custom-input-readonly"
                                            placeholder="Title of Club/Team" readonly value="{{editSchoolInv.title}}">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control custom-input-readonly"
                                            placeholder="Enter Position" readonly value="{{editSchoolInv.position}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label *ngIf="editSchoolInv.city">City</label>
                                <label *ngIf="editSchoolInv.school_name">School</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" *ngIf="editSchoolInv.city" class="form-control custom-input-readonly"
                                    placeholder="City" value="{{editSchoolInv.city}}" readonly>
                                <input type="text" *ngIf="editSchoolInv.school_name"
                                    class="form-control custom-input-readonly" placeholder="School"
                                    value="{{editSchoolInv.school_name}}" readonly>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="editSchoolInv.verified_by">
                            <div class="col-6">
                                <label>
                                    Teacher
                                    <input type="text" class="form-control custom-input-readonly" placeholder="Teacher"
                                        readonly value="{{editSchoolInv.verified_by}}">
                                </label>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>
                                    <span *ngIf="editSchoolInv.start_date != editSchoolInv.end_date"
                                        style="color: #335175;" class="mr-2">Start</span>
                                    <span *ngIf="editSchoolInv.start_date == editSchoolInv.end_date"
                                        style="color: #335175;" class="mr-2">Date</span>
                                    <input type="text" class="form-control custom-input-readonly" readonly
                                        value="{{editSchoolInv.start_date | date: 'MMMM d, y'}}">
                                    <div class="error-message"
                                        *ngIf="schoolInvUpdForm.get('start_date').hasError('required') && (schoolInvUpdForm.get('start_date').dirty || schoolInvUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6" *ngIf="editSchoolInv.start_date != editSchoolInv.end_date">
                                <label>End
                                    <input type="text" class="form-control custom-input-readonly"
                                        *ngIf="!canEditEndDate()" readonly value="{{editSchoolInv.end_date | date: 'MMMM d, y'}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="canEditEndDate()"
                                        [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="schoolInvUpdForm.errors?.invalidEndDate && (schoolInvUpdForm.touched || schoolInvUpdForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Built</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  " formControlName="skill_1">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_1').hasError('required') && (schoolInvUpdForm.get('skill_1').dirty || schoolInvUpdForm.get('skill_1').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_1').hasError('maxlength') && (schoolInvUpdForm.get('skill_1').dirty || schoolInvUpdForm.get('skill_1').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " formControlName="skill_2">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_2').hasError('required') && (schoolInvUpdForm.get('skill_2').dirty || schoolInvUpdForm.get('skill_2').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_2').hasError('maxlength') && (schoolInvUpdForm.get('skill_2').dirty || schoolInvUpdForm.get('skill_2').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " formControlName="skill_3">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_3').hasError('required') && (schoolInvUpdForm.get('skill_3').dirty || schoolInvUpdForm.get('skill_3').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_3').hasError('maxlength') && (schoolInvUpdForm.get('skill_3').dirty || schoolInvUpdForm.get('skill_3').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="main_role"> </textarea>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('main_role').hasError('required') && (schoolInvUpdForm.get('main_role').dirty || schoolInvUpdForm.get('main_role').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('main_role').hasError('maxlength') && (schoolInvUpdForm.get('main_role').dirty || schoolInvUpdForm.get('main_role').touched)">
                                    Max 1125 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Summary of Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="personal_details"> </textarea>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('personal_details').hasError('required') && (schoolInvUpdForm.get('personal_details').dirty || schoolInvUpdForm.get('personal_details').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('personal_details').hasError('maxlength') && (schoolInvUpdForm.get('personal_details').dirty || schoolInvUpdForm.get('personal_details').touched)">
                                    Max 2250 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="canEdit()">
        <button class="btn btn-footer cancel-btn" (click)="delSchoolInv()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateSchoolInv(schoolInvUpdForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #expTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
                <div class="form-group row">
                    <div class="col-12">
                        <label>School</label>
                        <select class="form-control custom-select" formControlName="school" (change)="getPageBySchool($event)">
                        <option value="null" disabled selected>Select School</option>
                        <option *ngFor="let school of schools" value="{{school.id}}">{{school.school_name}}</option>
                        <option value="0">Other</option>
                    </select>
                    <div class="error-message"
                    *ngIf="searchForm.get('school').hasError('required') && (searchForm.get('school').dirty || searchForm.get('school').touched || partialSubmitted)">
                    This field is required.
                </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="otherSchool">
                    <div class="col-12">
                        <label>School Name</label>
                        <input type="text" class="form-control" formControlName="school_name"
                        placeholder="Full school Name" (keydown)="searchSchool($event)"
                        (focusout)="searchfocusOutFunction()">
                    <div class="error-message" *ngIf="searchForm.get('school_name').hasError('aftersubmit')">
                        {{searchForm.controls.school_name.errors.aftersubmit}}
                    </div>
                    <div *ngIf="!hideResults" class="col-md-9">
                        <div class="option-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                            <div *ngFor="let school of searchSchools">
                                <div (click)="selectSchool(school)" class="media p-2">
                                    <img src="{{school.display_picture? school.display_picture : 'assets/images/default.svg'}}">
                                    <div class="media-body ml-2">
                                        <p class="pl-2">{{school.school_name}}, {{school.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <label>Club/Team Title</label>
                        <input type="text" class="form-control" placeholder="Enter Title of Club/Team"
                        formControlName="title" (keydown)="searchOrg($event)" (focusout)="focusOutFunction()">
                    <div class="error-message"
                        *ngIf="searchForm.get('title').hasError('required') && (searchForm.get('title').dirty || searchForm.get('title').touched || partialSubmitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="searchForm.get('title').hasError('maxlength') && (searchForm.get('title').dirty || searchForm.get('title').touched)">
                        Max 60 characters allowed.
                    </div>
                    <div *ngIf="!hideResults" class="col-md-9">
                        <div class="option-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                            <div *ngFor="let clubsAndTeam of searchResults">
                                <div (click)="selectOrg(clubsAndTeam)" class="media p-2">
                                    <img
                                        src="{{clubsAndTeam.display_pic? clubsAndTeam.display_pic : 'assets/images/default.svg'}}">
                                    <div class="media-body ml-2">
                                        <p class="pl-2">{{clubsAndTeam.page_title}},
                                            {{clubsAndTeam.school_name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <label>Position</label>
                        <input type="text" class="form-control" placeholder="Type Here" formControlName="position">
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="row   customRow">
                            <div class="col-md-4">
                                <label for="" class="custom-label">
                                    <span class="text">Start Date</span>
                                    <div class="relative first">
                                            <input type="text" class="form-control modal-control modal-field mt-2"
                                            placeholder="Start Date" bsDatepicker [maxDate]="today"
                                            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                            formControlName="start_date" style="background-color: white;" readonly>
                                        <a href="javascript:void(0);" (click)="resetStartDate()"><img
                                                src="assets/images/plusrotate.svg" class="img-cross"></a>
                                    </div>
                                    <div class="error-message"
                                        *ngIf="searchForm.get('start_date').hasError('required') && (searchForm.get('start_date').dirty || searchForm.get('start_date').touched || partialSubmitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex align-items-center justify-content-between">
                                    <!-- <div class="custom-control custom-radio">
                                        <input type="radio" value="1" id="end" class="custom-control-input"
                                            name="dateset" (change)="endDate($event)" [checked]="isEndDate">
                                        <label for="end" class="custom-control-label custom-label mb-0">End Date</label>
                                    </div> -->
                                    <div class="teal-radio-box">
                                            <input type="radio" value="1" id="end" class="custom-control-input"
                                            name="dateset" (change)="endDate($event)" [checked]="isEndDate" />
                                            <label for="end">End Date</label>
                                        </div>
                                </div>
                                <div class="fullWidth" *ngIf="isEndDate">
                                        <label for="">
                                            <div class="relative">
                                                    <input type="text" class="form-control modal-control modal-field"
                                                    placeholder="End Date" bsDatepicker [maxDate]="today"
                                                    [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                                    formControlName="end_date" style="background-color: white;" readonly>
                                                <a href="javascript:void(0);" (click)="resetEndDate()"><img
                                                        src="assets/images/plusrotate.svg" class="img-cross">
                                                </a>
                                            </div>
                                            <div class="error-message"
                                                *ngIf="isEndDate && searchForm.get('end_date').hasError('required') && (searchForm.get('end_date').dirty || searchForm.get('end_date').touched || partialSubmitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message"
                                                *ngIf="searchForm.errors?.invalidEndDate && (searchForm.touched || searchForm.dirty)">
                                                Start date should not be greater than end date
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                        <!-- <div class="custom-control custom-radio ml-3">
                                                <input type="radio" value="2" id="current" class="custom-control-input"
                                                    name="dateset" (change)="endDate($event)" [checked]="!isEndDate">
                                                <label for="current" class="custom-control-label custom-label mb-0">Present
                                                    Date</label>
                                            </div> -->
                                            <div class="teal-radio-box">
                                                    <input type="radio" value="2" id="current" class="custom-control-input"
                                                    name="dateset" (change)="endDate($event)" [checked]="!isEndDate" />
                                                    <label for="current">Present Date</label>
                                            </div>
                                </div>

                            </div>
                        </div>
                    </div>
            </form>
            <form *ngIf="pageformShow && !schoolId && canCreatePage" [formGroup]="pageForm" novalidate>
                <h3>
                    Create an unclaimed page for the Organization
                </h3>
                <div class="row form-group">
                    <div class="col-md-6">
                        <label>
                            Type of Organization
                        </label>
                        <select class="form-control custom-select" placeholder="Organization Type"
                            formControlName="organization_type">
                            <option selected value=1>Club</option>
                            <option value=2>Team</option>
                        </select>
                        <div class="error-message"
                            *ngIf="pageForm.get('organization_type').hasError('required') && (pageForm.get('organization_type').dirty || pageForm.get('organization_type').touched || partialSubmitted)">
                            This field is required.
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="selectedSchool?.school_name">
                        <label>School</label>
                        <input type="text" placeholder="School" class="form-control"
                            value="{{selectedSchool.school_name}}" readonly>
                    </div>
                    <div class="col-md-12">
                        <label>Title of Organization</label>
                        <input type="text" placeholder="Title of Organization" class="form-control"
                            formControlName="organization_title" readonly>
                        <div class="error-message"
                            *ngIf="pageForm.get('organization_title').hasError('required') && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="pageForm.get('organization_title').hasError('whitespace') && (!pageForm.get('organization_title').hasError('required')) && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched || partialSubmitted)">
                            Please enter valid data
                        </div>
                        <div class="error-message"
                            *ngIf="pageForm.get('organization_title').hasError('maxlength') && (pageForm.get('organization_title').dirty || pageForm.get('organization_title').touched)">
                            Max 125 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="verifierFormShow" novalidate>
                <h3 class="mb-0">Review Details</h3>
                <div class="innerBox mb-4">
                    <div class="row">
                        <div class="col-6">
                            <h3>Position</h3>
                        </div>
                        <div class="col-6">
                            <p>
                                {{this.searchForm.get('position').value}}
                            </p>
                        </div>
                        <div class="col-6">
                            <h3>Club/Team</h3>
                        </div>
                        <div class="col-6">
                            <p>
                                {{this.searchForm.get('title').value}}
                            </p>
                        </div>
                        <div class="col-6">
                            <h3 *ngIf="!exp_end_one_day">Start Date</h3>
                            <h3 *ngIf="exp_end_one_day">Date</h3>
                        </div>
                        <div class="col-6">
                            <p>
                                {{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}
                            </p>
                        </div>
                        <div class="col-6" *ngIf="!exp_end_one_day">
                            <h3>End Date</h3>
                        </div>
                        <div class="col-6" *ngIf="!exp_end_one_day">
                            <p *ngIf="!this.searchForm.get('end_date').value">Present</p>
                            <p>{{this.searchForm.get('end_date').value | date: 'yyyy-MM-dd'}}</p>
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="previewShow" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText forExpStep4">Your experience has been saved successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-box" *ngIf="teamMember.length > 0">
                    <div class="othersExpOuterPopup px-md-5">
                        <p class="othersExp">Now add others that you have shared this experience with to your
                            <strong>Team</strong> <br />Pssst... <span>the more team members you add, the more
                                <strong>verified</strong> your experience will be!</span>
                        </p>
                        <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went
                            wrong. Please try again later.</div>
                        <div class="scroll-activity scrollbar pr-2 overflowXHide">
                            <ul class="teamListWrap no-list">
                                <li *ngFor="let member of teamMemberSliced">
                                    <div class="teamMemberEach">
                                        <div class="topMember">
                                            <img alt="icon" class="bannerImg"
                                                src="{{member.student_info.header_image ? member.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                                        </div>
                                        <div class="profileMember">
                                            <img alt="icon" class="profileImg"
                                                src="{{member.student_info.display_pic ? member.student_info.display_pic : 'assets/images/p-img.png'}}" />
                                        </div>
                                        <div class="btmMember">
                                            <h1 class="name">{{ member.student_info.student_name }}</h1>
                                            <h5 class="subName">{{member.position}}</h5>
                                            <p class="yearHistory">
                                                {{ member.start_date }}<span *ngIf="member.end_date"> -
                                                    {{member.end_date}}</span>
                                                <span *ngIf="!member.end_date"> - Present</span>
                                            </p>
                                            <div class="memberActions">
                                                <button class="btn common-btn btnMessage btnProfile" *ngIf="!member.team_id"
                                                    (click)="addToTeam(member)">Add to team</button>
                                                <button class="btn common-btn btnMessage btnProfile" *ngIf="member.team_id"
                                                    (click)="cancelTeam(member)">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="teamMember.length > page_size && teamMember.length > teamMemberSliced.length"
                            class="text-center pt-4 timeLineLoad">
                            <a href="javascript:void(0);" (click)="teamLoadMore()">Load More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!formShow" class="btn btn-footer" (click)="showForm(searchForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="pageformShow && !schoolId && canCreatePage"
            (click)="showForm(searchForm.valid)">
            Return
        </button>
        <button *ngIf="pageformShow && !schoolId && canCreatePage" class="btn btn-footer"
            (click)="showPageForm(pageForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="verifierFormShow && (schoolId || !canCreatePage)"
            (click)="showForm(searchForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="verifierFormShow && !schoolId && canCreatePage"
            (click)="showPageForm(pageForm.valid,true)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="verifierFormShow" (click)="addSchoolInv()">
            Save
        </button>
        <button class="btn btn-footer" *ngIf="previewShow && teamMember.length > 0" (click)="modalRef.hide()">
            Skip
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="modalRef.hide()">
            Done
        </button>
    </div>
</ng-template>
<ng-template #currentTemp>
    <span>Present</span>
</ng-template>
<ng-template #extraCurricularListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of schoolInvolvements"
                    (click)="updateExpModal(editExpTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" (click)="redirectPage(experience);modalRef.hide();$event.stopPropagation()"
                                src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                                alt="Education Cover" class="thumb-img">
                        </div>
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4>{{experience.title}}</h4>
                        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                            <strong>Start</strong>
                            <span class="ml-1 mr-3">{{experience.start_date | date: 'MMM y'}}</span> <strong class="mr-1">End</strong>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                        </h5>

                        <p class="m-0">
                            <strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">, </span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">, </span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                        <div class="addRefRow">
                            <div class="addRefRowInner">
                                    <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                            <p class="my-0 people-number" (click)="$event.stopPropagation();teamMemberView(experience)">
                                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                                <span>{{ experience.related_data.exp_count }}</span>
                                            </p>
                                        </div>
                                        <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                                <img alt="image" src="assets/images/verifyProfile.svg">
                                            </p>
                                <a href="javascript:void(0);" *ngIf="experience.page"
                                    (click)="$event.stopPropagation(); addTeamMember(experience);">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                    <span>Add team members</span>
                                </a>
                                <a href="javascript:void(0);"
                                    *ngIf="!experience.verified_by && experience.verification_status == 1 && user.age_group > 1 && experience.page"
                                    (click)="addReferenceListing(addReferenceModal, experience); $event.stopPropagation()">
                                    <img src="assets/images/verifyProfile.svg" alt="icon">
                                    <span>Add reference</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #addReferenceModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Reference</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="verifierForm" novalidate *ngIf="!previewShow">
                <p><strong>Note:</strong> You must add your reference if they are not on the dropdown list</p>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Professional Reference First Name</label>
                        <input type="text" class="form-control text-capitalize" autocomplete="off"
                            formControlName="first_name" (keydown)="firstnameSearch()" (focusout)="focusOutFunction()">
                        <div class="error-message"
                            *ngIf="verifierForm.get('first_name').hasError('required') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('first_name').hasError('maxlength') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('first_name').hasError('whitespace') && (!verifierForm.get('first_name').hasError('required')) && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched || submitted)">
                            Please enter valid data.
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>Professional Reference Last Name</label>
                        <input type="text" class="form-control text-capitalize" autocomplete="off"
                            formControlName="last_name" (keydown)="lastnameSearch()" (focusout)="focusOutFunction()">
                        <div class="error-message"
                            *ngIf="verifierForm.get('last_name').hasError('required') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('last_name').hasError('maxlength') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('last_name').hasError('whitespace') && (!verifierForm.get('last_name').hasError('required')) && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched || submitted)">
                            Please enter valid data.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <div class="listingBox mt-0"
                            *ngIf="verifiers.length > 0 && this.verifierForm.get('first_name').value && canSrchFirstname">
                            <ul class="pl-0 mb-0" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                <li *ngFor="let verifier of verifiers | firstNameSearch: this.verifierForm.get('first_name').value"
                                    (click)="selectVerifier(verifier)">
                                    <p>{{verifier.first_name}} {{verifier.last_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="listingBox mt-0"
                            *ngIf="verifiers.length > 0 && this.verifierForm.get('last_name').value && canSrchLastname">
                            <ul class="pl-0 mb-0" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                <li *ngFor="let verifier of verifiers | lastNameSearch: this.verifierForm.get('last_name').value"
                                    (click)="selectVerifier(verifier)">
                                    <p>{{verifier.first_name}} {{verifier.last_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Professional Reference Email</label>
                        <input [readonly]="verifierId" type="text" class="form-control" formControlName="email"
                            (focusout)="focusOutEmail()">
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('required') && (verifierForm.get('email').dirty || verifierForm.get('email').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('maxlength') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('pattern') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message" *ngIf="verifierForm.get('email').hasError('aftersubmit')">
                            {{verifierForm.controls.email.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="previewShow" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your experience has been sent successfully!
                                    We will inform you once your reference has reviewed it.
                                    The last step is to add some details to your experience via your resume!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="updateModalRef.hide()">
            <span *ngIf="!previewShow">Cancel</span><span *ngIf="previewShow">Done</span>
        </button>
        <button class="btn btn-footer" *ngIf="!previewShow" (click)="addReference(verifierForm.valid)">
            Send
        </button>
    </div>

</ng-template>
