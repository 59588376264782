import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserProfile } from '../shared/userProfile';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { IEducationNew } from '../shared/education';
import { collegeType } from '../app.data';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { PublicResumeService } from '../services/public-resume.service';

@Component({
  selector: 'app-public-experience',
  templateUrl: './public-experience.component.html',
  styleUrls: ['./public-experience.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }]
})
export class PublicExperienceComponent implements OnInit {

  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  insText: string = "School";
  editSchoolInv: any = {};
  businessImage: string = null;
  editExp: any = {};
  editAward: any = {};
  pageImage: string = null;
  public editor = ClassicEditor;
  schoolId: string = null;
  profileImage: string;
  awards = [];
  verifiers = [];
  usrWorkExperience = [];
  usrVolunteerWorkExperience = [];
  schoolInvolvements = [];
  currentUserData: any;
  eduList: IEducationNew[] = [];
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  UserDetail: any = {};
  UserID: any = {};
  user: any = {};
  profile: any = {};
  activities = [];
  organizations = [];
  profileHeader: string = "";
  activeSlideIndex = 0;
  today = new Date();
  orgId: string = null;
  public collegeTypes: any[] = collegeType;

  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  skillLinkArray: any[];
  skillDocArray: any[];
  myInitiatives: any;
  educationForm: FormGroup;
  schoolEdForm: FormGroup;
  collegeEdForm: FormGroup;
  editActivity: any;
  isCollegeSelected: boolean = false;
  expModalTitle: string;

  constructor(private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: PublicResumeService) {
    this.educationForm = this.formBuilder.group({
      is_college: new FormControl('', [Validators.required,]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    });
    this.schoolEdForm = this.formBuilder.group({
      interests: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });
    this.collegeEdForm = this.formBuilder.group({
      college_degree: new FormControl(''),
      minors: new FormControl(''),
      majors: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });

    var url = document.URL.split('/')
    this.UserID = url[url.length - 1]
  }

  ngOnInit(): void {
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.profileService.getUserProfile(this.UserID)
    .subscribe(response => {
      if (response.message == "not-found"){
        this.router.navigate(['**']);
      } else {
        this.currentUserData = response.response;
        this.user = this.currentUserData.userprofile;
        this.profileHeader = this.user.header_image;
        this.profileImage = this.user.display_picture;
      }
    }, error => {
      this.router.navigate(['**']);
    })
    this.getVerifier();
    this.getActivities();
    this.getAwards();
    this.profileService.getUserExp(this.UserID)
      .subscribe(response => {
        if (response.response.work_experience.length > 0) {
          this.usrWorkExperience = response.response.work_experience;
        }
        if (response.response.volunteer_exprience.length > 0) {
          this.usrVolunteerWorkExperience = response.response.volunteer_exprience;
        }
      });

    this.profileService.getUserInitiative(this.UserID)
    .subscribe(response => {
      this.myInitiatives = response.response
    })

    this.profileService.getUserSchoolInv(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.schoolInvolvements = response.response;
        } else {
          this.schoolInvolvements = [];
        }
      });

    this.profileService.getUserEducation(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.eduList = response.response;
        } else {
          this.eduList = [];
        }
      });
  }

  getAwards(){
    this.profileService.getUserAwards(this.UserID)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.awards = response.response;
      }
    });
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  
  slideConfig2 = {
    "slidesToShow": 2,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  sliderInitiative = {
    "slidesToShow": 4,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  slideBooks = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  
  openVerifiersModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  updateActivityModal(editActivityTemplate: TemplateRef<any>, activity: any) {
    this.editActivity = activity;
    this.updateModalRef = this.modalService.show(editActivityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  viewModalAwards(viewAwardsTemplate: TemplateRef<any>, award: any) {
    this.editAward = award;
    this.modalRef = this.modalService.show(viewAwardsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
      );
  }

  getActivities() {
    this.profileService.getUserActivities(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.activities = response.response;
        } else {
          this.activities = [];
        }
      });
  }

  getVerifier() {
    this.profileService.getVerifiers(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      })
  }

  eduformUpdate(response) {
    this.insText = "School"
    this.isCollegeSelected = false;
    this.educationForm.controls['country'].setValue('Canada');
    this.educationForm.controls['is_college'].setValue("false");
    this.educationForm.controls['school_name'].setValue(response.school.school_name);
    this.educationForm.controls['state'].setValue(response.school.state);
    this.educationForm.controls['country'].setValue(response.school.country);
    let start_date = response.start_date;
    let end_date = response.end_date;
    if (response.school.is_college) {
      this.educationForm.controls['is_college'].setValue("true");
      this.insText = "College/University";
      this.isCollegeSelected = true;
      this.collegeEdForm.controls['start_date'].setValue(parseInt(start_date));
      this.collegeEdForm.controls['end_date'].setValue(parseInt(end_date));
      this.collegeEdForm.controls['majors'].setValue(response.majors.toString());
      this.collegeEdForm.controls['minors'].setValue(response.minors.toString());
      this.collegeEdForm.controls['college_degree'].setValue(response.degree);
    } else {
      this.educationForm.controls['is_college'].setValue("false");
      this.insText = "School";
      this.isCollegeSelected = false;
      this.schoolEdForm.controls['start_date'].setValue(parseInt(start_date));
      this.schoolEdForm.controls['end_date'].setValue(parseInt(end_date));
      if (response.interests) {
        this.schoolEdForm.controls['interests'].setValue(response.interests.toString());
      } else {
        this.schoolEdForm.controls['interests'].setValue(response.interests);
      }
    }
  }

  updateEducationPopup(staticEdPopup: TemplateRef<any>, ed_id: string) {
    this.eduformUpdate(ed_id);
    this.modalRef = this.modalService.show(staticEdPopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.orgId = null;
    this.businessImage = null;
    this.editExp = exp;
    if (exp.experience_type == 1) {
      this.expModalTitle = 'Work'
    }
    else {
      this.expModalTitle = 'Volunteer'
    }
    this.updateModalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  updateSchoolInvolvementModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.schoolId = null;
    this.pageImage = null;
    this.editSchoolInv = exp;
    this.updateModalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  workExpListing(skillListModal: TemplateRef<any>) {
    this.expModalTitle = 'Work';
    this.modalRef = this.modalService.show(skillListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  volunteerListing(volunteerListModal: TemplateRef<any>) {
    this.expModalTitle = 'Volunteer';
    this.modalRef = this.modalService.show(volunteerListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  extraCurricularListing(extraCurricularListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(extraCurricularListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  schoolInvolvementListing(schoolInvolvementListModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolInvolvementListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }
  
  jumpToInitiative(currentInitiative: any){
    let instance = currentInitiative.page_info;
    let isDeleted = instance.is_deleted
    let initiativeId = instance.instance_id;
    if (!isDeleted){
      this.router.navigate(['initiatives/' + initiativeId + '/']);
    }
  }

}
