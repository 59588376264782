import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutUs: any = "about_us";
  aboutUsContent: any = "";
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.authService.staticContent(this.aboutUs)
    .subscribe(response => {
      this.aboutUsContent = response
    });
  }

}
