import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { CustomvalidationService } from '../services/customvalidation.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  enteredEmail: string;
  parentEmail: string = '';
  resendEmailMessage: string = '';
  loginForm: FormGroup;
  submitted = false;
  reqMessage: string;
  showError = false;
  modalRef: BsModalRef;
  publicToken = null;

  constructor(private modalService: BsModalService, private loginService: LoginService, 
    public formBuilder: FormBuilder, private authService: AuthenticationService,
     private customValidator: CustomvalidationService, private router: Router) {

    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', Validators.required),
    })
   }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.router.navigate(['dashboard']);
    }
    this.publicToken = this.loginService.publicToken ? this.loginService.publicToken : this.loadPublicToken();
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
        .pipe(first())
        .subscribe(
            data => {
            },
            error => {

            });
}

  login(resendModal: TemplateRef<any>, parentModal: TemplateRef<any>) {
    this.submitted = true;
    if (this.loginForm.invalid) {
        return;
    }
    this.loginService.userLogin(this.loginForm.get('email').value, this.loginForm.get('password').value)
        .subscribe(
            response => {
                if (response.status === 111) {
                  this.showError = true;
                  if ("non_field_errors" in response.message) {
                    this.reqMessage = response.message["non_field_errors"][0];
                  } else {
                    this.reqMessage = 'The email and password combination you entered is not correct. Please try again.';
                  }
                } else if(response.status === 112 || response.status===113){
                    let showModal;
                    if (response.status === 113){
                      this.parentEmail = response.message.email;
                      showModal = parentModal;
                    } else {
                      this.parentEmail = '';
                      showModal = resendModal;
                    }
                    this.showError = true;
                    this.enteredEmail = this.loginForm.controls['email'].value;
                    this.modalRef = this.modalService.show(
                      showModal, Object.assign({}, { 
                        class: 'modal-dialog-centered custom-modal new-modal-ui'}));
                } else if (response.status === 200) {
                  let newUser = response.response;
                  newUser['first_education'] = response.response.first_login ? true : false;
                  localStorage.setItem('user', JSON.stringify(newUser));
                  localStorage.removeItem('invited-user');
                  let url = this.router.url;
                  if(url.indexOf('verify-claim') > 0){
                    window.location.reload();
                  } else {
                    window.location.href = response.response.user_role === 2 && response.response.first_login ? '/profile' : '/dashboard';
                  }
                  this.loginForm.reset();
                }
            },
            error => {
                this.showError = true;
                this.reqMessage = 'The email and password combination you entered is not correct. Please try again.';
            }
        )
  }

  resendVerification(to_parent: boolean=false){
    let data = {
      'email': this.enteredEmail
    }
    if (to_parent){
      data['send_to_parent'] = true;
    }
    this.authService.resendVerify(data)
      .subscribe(response => {
        this.handleReverify(response, true);
      },
        error => {
          this.handleReverify(error.error, false);
        });
  }

  protected handleReverify(response: any, is_success:boolean) {
    if (response.status == 111){
      is_success = false;
    }
    if (is_success){
      this.resendEmailMessage = 'The Activation Link has been sent successfully. Please check your inbox to activate your Account.'
    } else {
      this.resendEmailMessage = 'There was an error in sending verification email. This can happen in case of invalid email or when your account has been verified already'
    }
  }

  closeModal(){
    this.modalRef.hide();
    this.resendEmailMessage = '';
  }

}
