import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { ProfileService } from '../../services/profile.service';
import { Location } from '@angular/common';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Output() tabActive = new EventEmitter<any>();
  page_id: string;
  pagesDetail: any = {};
  user: any = {};
  histories = [];
  page_Image: string = "";
  modalRef: BsModalRef;
  uploadForm: FormGroup;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  public isHeaderToDel: boolean = false;
  public delError: boolean = false;
  public isUnclaimed: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  delErrorMessage = "Unable to delete the image. Please try again later.";
  pageHeaderImg: string = "";
  public settingShow = true;
  pageVerified: string;
  pageVerificationDaysLeft: string;
  pageType: string;
  pageName: string
  pageProfessionalEmail: string;
  keywordArray: any = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  blockReportForm: FormGroup;
  totalResults: any;
  nextPage: any;
  loading: boolean = false;

  @Input() page: any = {};
  @Input() profile: any = {};
  plchldrState: string = "Province";
  plchldrPostal: string = "Postal";
  countrySet: string = 'CA';
  updateAddress: string;
  updateCity: string = '';
  AddressForm: FormGroup;
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  hidePlaceResultsCity: boolean = true;
  searchPlaces: any[] = [];
  isReadOnly: boolean = false;
  showAddressError: boolean;
  pagesDetails: any = {};
  descriptionForm: FormGroup;
  cityPlaceHolder = "City"
  newModalRef: BsModalRef;
  detailForm: FormGroup;
  followBtnErr: boolean = false;
  allKeywords: any;
  maxKeywordLimit: boolean = false;
  isOwner: boolean = false;
  encrypt_email: any;
  encrypt_page_id: any;

  constructor(private profileService: ProfileService, private route: ActivatedRoute, private authService: AuthenticationService, private router: Router, private modalService: BsModalService,
    public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private location: Location, private pageService: PageService) {
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required])
    });
    this.detailForm = this.formBuilder.group({
        leader_name: new FormControl('', [Validators.maxLength(150)]),
        contact_email : new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
        description: new FormControl('', Validators.maxLength(200)),
        keywords: new FormControl(''),
    });
    this.blockReportForm = this.formBuilder.group({
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
    this.AddressForm = this.formBuilder.group({
      address: new FormControl('', [Validators.maxLength(200)]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.maxLength(20)]),
      phone_number : new FormControl('', [Validators.minLength(10), Validators.maxLength(15), Validators.pattern(this.customValidator.numberRegex)]),
      contact_email : new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      description: new FormControl('', Validators.maxLength(200))
    });
    this.AddressForm.controls['country'].setValue('Canada', {onlySelf: true});
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    }
    this.page_id = this.route.snapshot.paramMap.get('page_id')
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        this.pagesDetail = response.response;
        this.keywordArray = response.response.keywords;
        this.allKeywords = response.response.keywords;
        if (this.pagesDetail.owner){
          this.isUnclaimed = false;
        } else {
          this.isUnclaimed = true;
        }
        if (this.pagesDetail.display_pic) {
          this.page_Image = this.pagesDetail.display_pic;
        }
        if (this.pagesDetail.header_img) {
          this.pageHeaderImg = this.pagesDetail.header_img;
        }
      });
    
      this.profileService.BusinessVerificationStatus(this.page_id)
      .subscribe(response => {
        this.pageVerified = response.response.business_verified;
        this.pageVerificationDaysLeft = response.response.days_left;
        this.pageType = response.response.page_type;
        this.pageName = response.response.page_info.name;
        this.pageProfessionalEmail = response.response.page_info.email;
      });
      this.checkPageUser();
    
  }

  resendProEmail(){
    this.profileService.ResendProEmail(this.page_id)
    .subscribe(response => {})
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  config = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal',
  };

  openModal(template: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      this.config
    );
  }

  openReportModal(editModal: TemplateRef<any>) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(editModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  checkPageUser(){
    this.pageService.checkPageUser(this.page_id)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.is_related === false) {
          this.router.navigate(['/page/' + this.page_id]);
        }
        this.isOwner = response.response.is_owner;
        this.encrypt_email = response.response.encrypt_email;
        this.encrypt_page_id = response.response.encrypt_page_id;
      } else {
        this.isOwner = false;
      }
    })
  }

  changeTab(tabActive: any){
    let data = {"tabActive": tabActive}
    this.tabActive.emit(data);
  }

  routeToClaim(){
    this.router.navigate(['/verify-claim'], {queryParams: {'id': this.encrypt_page_id, 'verifier': this.encrypt_email}});
  }

  getHistory() {
    let params = "?page=1";
    this.profileService.getEditHistory(this.page_id, params)
      .subscribe(data => {
        this.histories = data.response.results;
        this.nextPage = data.response.next;
        this.totalResults = data.response.total_count;
      });
  }

  openModalEditHistory(template: TemplateRef<any>) {
    this.getHistory();
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  loadMore() {
    if (this.nextPage != null && !this.loading) {
      this.loading = true;
      let params = "?page=" + this.nextPage;
      this.profileService.getEditHistory(this.page_id, params)
        .subscribe(data => {
          this.histories = this.histories.concat(data.response.results);
          this.nextPage = data.response.next;
          this.totalResults = data.response.total_count;
          this.loading = false;
        })
    }
  }

  checkCommaVal(event){
    this.maxKeywordLimit = false;
    var keywordInput = this.detailForm.get('keywords').value;
    var allKeywordsLocal = keywordInput.split(",");
    if (event.key === ","){
       allKeywordsLocal.pop();
      if (allKeywordsLocal.length >= 10){
        this.maxKeywordLimit = true;
        allKeywordsLocal = allKeywordsLocal.slice(0,10);
      }
    } else if (allKeywordsLocal.length > 10){
      this.maxKeywordLimit = true;
      allKeywordsLocal = allKeywordsLocal.slice(0,10);
    }
    this.allKeywords = allKeywordsLocal;
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  addNewDetails(isValid: boolean) {
     this.submitted = true;
    if (isValid){
      let leader_name = this.detailForm.get('leader_name').value;
      let model = Object.assign({}, this.pagesDetail);
      if (leader_name && leader_name.trim().length > 0){
        model.leader_name = leader_name;
      } else {
        model.leader_name = null;
      }
      model.professional_email = this.detailForm.get('contact_email').value;
      model.description = this.detailForm.get('description').value;
      this.keywordArray = this.allKeywords;
      model.keywords = this.keywordArray;
      if (this.pagesDetail.club_team){
        if (this.pagesDetail.club_team.school?.id) {
          model.club_team.school = this.pagesDetail.club_team.school.id;
        } else {
          model.club_team.school = this.pagesDetail.club_team.school;
        }
      }
      this.authService.UpdatePageBusiness(model,this.pagesDetail.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          const fields = Object.keys(response.message || {});
          if (fields.indexOf("new_email") > -1) {
            this.detailForm.get('contact_email').setErrors({ aftersubmit: response.message['new_email'][0] });
          }
        } else if (response.status === 200) {
          this.pagesDetail = response.response;
          this.keywordArray = response.response.keywords;
          this.submitted = false;
          this.detailForm.reset();
          this.modalRef.hide();
        }
      })
    }
  }

   addImage(isValid: boolean, type:string) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      if (type === 'header'){
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': false,
          'header_img': true,
        }
      } else {
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': true,
          'header_img': false,
        }
      }
      this.authService.PageImg(model, this.page_id)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.pagesDetail.display_pic = response.response.display_picture;
      this.pagesDetail.header_img = response.response.header_image;
      if (this.pagesDetail.header_img) {
        this.pageHeaderImg =  this.pagesDetail.header_img;
      }
      if (this.pagesDetail.display_pic) {
        this.page_Image =  this.pagesDetail.display_pic;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delImageModal(delImage: TemplateRef<any>, header_image: boolean = false) {
    this.delError = false;
    if (header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.newModalRef = this.modalService.show(delImage,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  deleteImage(){
    this.profileService.delPageImage({'header_img': this.isHeaderToDel}, this.page_id)
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }
  
  showImg(headerImages: TemplateRef<any>) {
    if (this.pageHeaderImg) {
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
      );
    }
  }

  editKeywordModal(editKeywordTemplate: TemplateRef<any>) {
    this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1)
    this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2)
    this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3)
    this.modalRef = this.modalService.show(editKeywordTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editPageDetails(updatePageDetail: TemplateRef<any>) {
    this.AddressForm.reset();
    this.updateAddress = this.pagesDetail.business.address;
    if (!this.updateAddress) {
      this.updateCity = this.pagesDetail.business.city;
    }
    this.AddressForm.controls['address'].setValue(this.pagesDetail.business.address);
    this.AddressForm.controls['country'].setValue(this.pagesDetail.business.country);
    this.AddressForm.controls['state'].setValue(this.pagesDetail.business.state);
    this.AddressForm.controls['city'].setValue(this.pagesDetail.business.city);
    this.AddressForm.controls['zip_code'].setValue(this.pagesDetail.business.zip_code);
    this.AddressForm.controls['phone_number'].setValue(this.pagesDetail.phone_number);
    this.AddressForm.controls['contact_email'].setValue(this.pagesDetail.professional_email);
    this.AddressForm.controls['description'].setValue(this.pagesDetail.description);
    this.submitted = false;
    this.AddressForm.controls['country'].setValue('Canada', {onlySelf: true});
    this.modalRef = this.modalService.show(updatePageDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editSchoolDetails(updatePageDetail: TemplateRef<any>) {
    this.detailForm.reset();
    this.detailForm.controls['leader_name'].setValue(this.pagesDetail.leader_name);
    this.detailForm.controls['contact_email'].setValue(this.pagesDetail.professional_email);
    this.detailForm.controls['description'].setValue(this.pagesDetail.description);
    this.allKeywords = this.keywordArray;
    if (this.keywordArray && this.keywordArray.length > 0){
      let keywordString: string = this.keywordArray.join(",");
      this.detailForm.controls['keywords'].setValue(keywordString);
    }
    this.submitted = false;
    this.modalRef = this.modalService.show(updatePageDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.newModalRef.hide();
        if (this.isHeaderToDel) {
          this.pageHeaderImg = null;
        } else {
          this.page_Image = null;
        }
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.newModalRef.hide();
      this.delError = false;
    }, 6000);
  }

  reportUser(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reported_page': this.page_id, 'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description']
      }
      this.authService.ReportUser(report_data).subscribe(respones => {});
      this.modalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  changeCountry(e){
    if (e.target.value == "USA"){
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
    } else {
      this.plchldrState = "Province";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let zip_code = '';
    let address = place.address.freeformAddress.split(",")[0];
    if (this.AddressForm.controls['address'].value){
      this.updateAddress = address;
      this.AddressForm.controls['address'].setValue(address);
    }
    this.updateCity = place.address.municipality;
    if (this.updateCity.length > 20){
      this.updateCity = this.updateCity.split(",")[0]
    }
    this.AddressForm.controls['city'].setValue(this.updateCity);
    if (place.address.extendedPostalCode && this.countrySet=='CA'){
      zip_code = place.address.extendedPostalCode;
    } else {
      zip_code = place.address.postalCode;
    }
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    if (zip_code && zip_code.length > 20) {
      zip_code = place.address.postalCode;
    }
    this.AddressForm.controls['zip_code'].setValue(zip_code);
    this.AddressForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
    this.isReadOnly = true;
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.AddressForm.controls['address'].setValue(null);
      this.updateAddress = null;
      if (!this.isUnclaimed) {
        this.showAddressError = true;
      }
      this.isReadOnly = false;
    } else {
      this.updateAddress = e.target.value;
      this.updateCity = null;
      this.AddressForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  NewAddress(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      this.pagesDetail.business.address = this.AddressForm.get('address').value;
      this.pagesDetail.business.country = this.AddressForm.get('country').value;
      this.pagesDetail.business.state = this.AddressForm.get('state').value;
      this.pagesDetail.business.city = this.AddressForm.get('city').value;
      if (this.AddressForm.get('zip_code').value) {
        this.pagesDetail.business.zip_code = this.AddressForm.get('zip_code').value;
      } else {
        this.pagesDetail.business.zip_code = null;
      }
      if (this.AddressForm.get('phone_number').value){
        this.pagesDetail.phone_number = this.AddressForm.get('phone_number').value;
      }
      if (this.AddressForm.get('contact_email').value){
        this.pagesDetail.professional_email = this.AddressForm.get('contact_email').value;
      }
      this.pagesDetail.description = this.AddressForm.get('description').value;
      if (this.latitude && this.longitude) {
        this.pagesDetail.business.longitude = this.longitude;
        this.pagesDetail.business.latitude = this.latitude;
      }
      this.authService.UpdatePageBusiness(this.pagesDetail,this.pagesDetail.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          this.AddressForm.get('address').setErrors({ aftersubmit: response.message['new address'] });
        } else if (response.status === 200) {
          this.pagesDetail = response.response;
          this.modalRef.hide();
          this.submitted = false;
          this.AddressForm.reset();
        } else {
          this.AddressForm.get('address').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.AddressForm.get('address').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
  }

  backClicked(){
    this.location.back();
  }

  updateDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {
        'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
        'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
        'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
        'page': this.page_id
      }
      this.pageService.updatePageDescription(model, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.profile = response.response;
      this.descriptionForm.reset();
      this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {
        'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
        'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
        'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
        'page': this.page_id
      }
      this.pageService.addPageDescription(model)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  OpenPageSettings() {
    let url = 'page/' + this.page_id + '/profile-business?tab=setting';
    window.location.href = url;
  }

  changeCity(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.showAddressError = true;
      this.AddressForm.controls['city'].setValue(null);
      this.updateCity = null;
      this.latitude = null;
      this.longitude= null;
    } else {
      this.updateCity = e.target.value;
      if (this.updateCity.length > 20) {
        this.updateCity = this.updateCity.split(",")[0];
      }
      this.AddressForm.controls['city'].setValue(this.updateCity);
      this.showAddressError = false;
    }
  }
  
  setCityResultHide(e){ 
    this.hidePlaceResultsCity = e;
  }

  cityFocusOut() {
    setTimeout(() => {
      this.hidePlaceResultsCity = true;
      this.searchPlaces = [];
    }, 500);
  }

  followPage(){
    if (this.user.age_group < 3 || this.pagesDetail.club_team.school.is_college) {
      this.followBtnErr = false;
      if (this.pagesDetail.page_info.is_followed) {
        this.pageService.unFollowPage(this.pagesDetail.id, {})
        .subscribe(response => {
          this.pagesDetail.page_info.is_followed = false;
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      } else {
        this.pageService.followPage(this.pagesDetail.id, {})
        .subscribe(response => {
          if (response.status === 200) {
            this.pagesDetail.page_info.is_followed = true;
          } else {
            this.followBtnErr = true;
            this.fadeOutFollowErrMsg();
          }
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      }
    }
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 6000);
  }

}
