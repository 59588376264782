import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss']
})
export class StudentSearchComponent implements OnInit {
  user: any = {};
  verifiers = [];
  searchForm: FormGroup; 
  public settingShow = true;
  mediaAccessToken: any;
  totalStudents: any;
  allStudents: any[] = [];
  students: any[] = [];
  tabActive = 1;
  page_size = 19;

  constructor(private searchService: SearchService, public formBuilder: FormBuilder, private authService: AuthenticationService,
    private profileService: ProfileService, private router: Router, private location: Location) {
      if (localStorage.getItem('user')) {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        this.user = currentUser;
      } else {
        this.router.navigate(['/']);
      }
      this.getVerifiers();
      this.searchForm = this.formBuilder.group({
        name: new FormControl('', [Validators.maxLength(50)]),
        school: new FormControl('', [Validators.maxLength(50)]),
        skill_interest: new FormControl('', [Validators.maxLength(50)]),
        business: new FormControl('', [Validators.maxLength(50)]),
        club_team: new FormControl('', [Validators.maxLength(50)]),
        program_major: new FormControl('', [Validators.maxLength(50)]),
        books: new FormControl('', [Validators.maxLength(50)])
      });
  }

  ngOnInit(): void {
    this.studentSearch();
  }

  getVerifiers(){
    this.profileService.listVerifiers()
    .subscribe(response => {
      if (response.response.length > 0) {
        let verifiers = response.response;
        this.verifiers = verifiers.filter(obj => obj.status === 2);
      }
      if (this.user.age_group !== 1 && this.user.user_role !== 2 && (this.verifiers.length < 3 || !this.user.approval_status)) {
        this.router.navigate(['/']);
      }
    })
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  search(isValid: boolean) {
    if (isValid) {
      this.studentSearch();
    }
    this.settingShow = false;
  }

  setParams() {
    let name = this.searchForm.get('name').value;
    let school = this.searchForm.get('school').value;
    let skill = this.searchForm.get('skill_interest').value;
    let business = this.searchForm.get('business').value;
    let club_team = this.searchForm.get('club_team').value;
    let program_major = this.searchForm.get('program_major').value;
    let book = this.searchForm.get('books').value;
    let params = "?page=1";
    if (name !== null && name !== "") {
      params += "&name=" + name;
    }
    if (school !== null && school !== "") {
      params += "&school=" + school;
    }
    if (skill !== null && skill !== "") {
      params += "&skill=" + skill;
    }
    if (business !== null && business !== "") {
      params += "&business=" + business;
    }
    if (club_team !== null && club_team !== "") {
      params += "&club_team=" + club_team;
    }
    if (program_major !== null && program_major !== "") {
      params += "&program_major=" + program_major;
    }
    if (book !== null && book !== "") {
      params += "&book=" + book;
    }
    return params;
  }

  studentSearch() {
    let params = this.setParams();
    this.searchService.studentSearch(params)
    .subscribe(data => {
      this.allStudents = data.response.students;
      this.students = this.allStudents.slice(0, this.page_size);
      this.mediaAccessToken = data.response.sas_token;
      this.totalStudents = data.response.students.length;
    })
  }

  onScrollDown() {
    let currentLen = this.students.length;
    if (currentLen < this.totalStudents){
      this.students = this.allStudents.slice(0, currentLen + this.page_size);
    }
  }

  backClicked(){
    this.location.back();
  }

}
