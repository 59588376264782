import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseCommentEndPoint, BaseProfileEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private baseCommentUrl: string = BaseCommentEndPoint.apiEndpoint;
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  addComment(id: any, data: any) {
    return this.http.post(this.baseCommentUrl + 'create-comment/' + id + '/', data);
  }

  getUserNetwork() {
    return this.http.get(this.baseProfileUrl + 'my-network-users/');
  }

  getComments(id: any, post_type: any, params: any) {
    return this.http.get(this.baseCommentUrl + 'list-comments/' + id + '/' + post_type + '/' + params);
  }

  deleteComment(id: string, post_type: any) {
    return this.http.delete(this.baseCommentUrl + 'update-comment/' + id + '/' + post_type + '/', {});
  }

  addReply(id: any, data: any) {
    return this.http.post(this.baseCommentUrl + 'create-reply/' + id + '/', data);
  }

  getReplies(id: any, post_type: any, params: any) {
    return this.http.get(this.baseCommentUrl + 'list-replies/' + id + '/' + post_type + '/' + params);
  }

  deleteReply(id: string) {
    return this.http.delete(this.baseCommentUrl + 'update-reply/' + id + '/', {});
  }

}
