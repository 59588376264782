<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="notAccount">
                        <a [routerLink]="['/signup']">Don't Have an Account? Sign Up 
                            <img alt="icon" class="iconArrow" src="/assets/images/blue-right-arrow.svg" />
                        </a>
                    </div>

                    <div class="SignInInnerWrap WrapWhiteShadow">
                        <h1 class="hdg mb-2">Welcome Back!</h1>
                        <form [formGroup]="loginForm" novalidate (ngSubmit)="login(resendModal, parentEmailModal)">
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="email" placeholder="E-mail" formControlName="email" class="form-control signUpField" />
                                    <div class="error-message" *ngIf="loginForm.get('email').hasError('required') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message" *ngIf="loginForm.get('email').hasError('pattern') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="password" placeholder="Password" formControlName="password" class="form-control signUpField" />
                                    <div class="error-message" *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message" *ngIf="submitted && reqMessage">
                                        {{reqMessage}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group m-0 text-center pt-2">
                                <button type="submit" class="btn btnSignUp">Sign In</button>
                            </div>

                            <div class="forgotSignInPass">
                                <a [routerLink]="['/forgot-password']">Forgot my Password</a>
                            </div>
                        </form>
                    </div>

                    <div class="SignUpInnerWrap WrapWhiteShadow m-0">
                        <h1 class="hdg mb-4 pb-2">Back to Home Page</h1>
                        <div class="form-group m-0 text-center">
                            <button type="button" [routerLink]="['/']" class="btn btnSignUp">Click Here</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #resendModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Pending account activation</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>Uh oh! Your account has not been activated. You must verify
                        the email sent to your inbox to activate your Account!</h3>
                    <p>{{resendEmailMessage}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="resendVerification()" class="btn btn-footer">Re-send Link</button>
    </div>
</ng-template>

<ng-template #parentEmailModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Pending parent approval</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>A confirmation email has been sent to your Parent/Guardian at {{parentEmail}}. Please get your Parent’s/Guardian’s
                        approval to access your account.</h3>
                    <p>{{resendEmailMessage}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="resendVerification(true)" class="btn btn-footer">Re-send Email to Parent</button>
        <button (click)="closeModal()" class="btn btn-footer">
            <a class="forParentEmail" [routerLink]="['/parent-verification']">Change Parent Email</a>
        </button>
    </div>
</ng-template>