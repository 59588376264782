<section>
    <div class="custom-tab-dark box-shadow-box pt-0">
            <div class="timeLineBlock pt-0">
                    <div class="allTimeLinesOuter">
                        <button  type="button" class="initiative-btn" (click)="addTimelineModal(schPostTemplate)">
                            <img src="/assets/images/School Post.svg" alt="edit">
                        </button>
                        <div class="isThisPost mb-0">
                            <div class="teal-radio-box">
                                    <input type="radio" [value]="1" id="radioActive1" (click)="timelineNextPage=1;radioActive=1;getTimelines()"
                                      [(ngModel)]="radioActive" class="custom-control-input" name="post" checked />
                                    <label for="radioActive1">Update/Reminders</label>
                            </div>

                            <div class="teal-radio-box">
                                    <input type="radio" [value]="2" id="radioActive2" (click)="timelineNextPage=1;radioActive=2;getTimelines()"
                                      [(ngModel)]="radioActive" class="custom-control-input" name="post" />
                                    <label for="radioActive2">Positions</label>
                                </div>

                                <div class="teal-radio-box mr-0">
                                  <input type="radio" [value]="3" id="radioActive3" (click)="timelineNextPage=1;radioActive=3;getTimelines()"
                                    [(ngModel)]="radioActive" class="custom-control-input" name="post" />
                                  <label for="radioActive3">Events</label>
                              </div>
                        </div>
                        <div class="feeds-box-listing">
                            <div class="impactNoResult" *ngIf="timelines.length == 0">
                                <h5 class="m-0">No data exists.</h5>
                            </div>
                            <div class="box TimeLineBox" *ngFor="let timeline of timelines"
                                [ngClass]="{'no-image': timeline?.timeline_documents?.length == 0 }">
                                <span class="event" *ngIf="radioActive == 3">Event</span>
                                <span class="event" *ngIf="radioActive == 1">Update</span>
                                <span class="event" *ngIf="radioActive == 2">Position</span>
                                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                    [config]="communityConfig">
                                    <div class="communitySlide slide" ngxSlickItem *ngFor="let document of timeline.timeline_documents">
                                        <div class="communitySlideInner">
                                            <img src="{{document.document}}" alt="image" />
                                        </div>
                                    </div>
                                </ngx-slick-carousel>
                                <div class="content-box">
                                    <div class="img-box">
                                        <img src="{{pagesDetails.display_pic? pagesDetails.display_pic:'assets/images/default.svg'}}" alt="small-img" />
                                    </div>
                                    <div class="content">
                                        <h3 class="cursor-pointer" (click)="timelineDetailModal(timelineDetail, timeline)">{{timeline.title}}</h3>
                                        <p>{{pagesDetails?.page_info?.title}}</p>
                                        <div class="date-box" *ngIf="timeline.date != null">
                                            <div class="month">
                                                <span>{{timeline.date | date:'MMM'}}</span>
                                            </div>
                                            <div class="date">
                                                <span>{{timeline.date | date:'d'}}</span>
                                            </div>
                                        </div>
                                        <p class="para cursor-pointer" (click)="timelineDetailModal(timelineDetail, timeline)" innerHtml="{{timeline.description | slice:0:100}}{{timeline.description.length > descriptionLimit ? '...' : ''}}"></p>
                                    </div>
                                    <div class="save-content">
                                        <div class="left-part">
                                            <button type="button" class="interest-btn" (click)="markInterested(timeline, radioActive)"
                                                [ngClass]="{'remove': timeline.interested_data?.interested}">
                                                <img class="remove-img" src="/assets/images/interested-star-filled.svg"
                                                    alt="star-filled" />
                                                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                                <span class="remove-text">remove</span>
                                                <span>Interested</span>
                                            </button>
                                            <button type="button" class="other-btn" *ngIf="timeline.interested_data?.count > 0"
                                            (click)="openModalOthers(templateOthers, timeline)">
                                                {{timeline.interested_data?.count}} Others
                                            </button>
                                        </div>
                                        <div class="right-part">
                                            <div class="btn-group" dropdown>
                                                <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                                                    aria-controls="dropdown-basic">
                                                    <img src="assets/images/dot-teal.svg" alt="more icon" />
                                                </button>
                                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                                                    aria-labelledby="button-basic">
                                                    <li role="menuitem">
                                                        <div class="flag-data-box cursor-pointer" (click)="editTimelineModal(editTimelineTemplate, timeline)">
                                                            <img class="edit-icon" src="/assets/images/editIcon.svg" alt="edit" />
                                                            <span>Edit</span>
                                                        </div>
                                                    </li>
                                                    <li role="menuitem">
                                                        <div class="flag-data-box cursor-pointer" (click)="delTimelineModal(delTimelineTemplate, timeline)">
                                                            <img class="edit-icon" src="/assets/images/deleteIcon.svg" alt="delete" />
                                                            <span>Delete</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad mt-0" *ngIf="totalTimelines > 10 && totalTimelines > timelines.length">
                            <a href="javascript:void(0);" class="mb-4" (click)="getTimelines()">Load More</a>
                        </div>
                    </div>
                </div>
    </div>
</section>

<ng-template #delTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Position</span>
            <span *ngIf="radioActive == 3">Event</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to delete this <span *ngIf="radioActive == 1">timeline?</span>
                <span *ngIf="radioActive == 2">position?</span><span *ngIf="radioActive == 3">event?</span></h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="delTimelineElem(); this.modalRef.hide();">
            Confirm
        </button>
    </div>
</ng-template>
<ng-template #editTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">update
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Position</span>
            <span *ngIf="radioActive == 3">Event</span>
        </h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="timelineUpdForm" novalidate>
                <ckeditor [editor]="Editor" formControlName="description"
                    [config]="{ toolbar: [ 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('required') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('maxlength') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched)">
                    Max 2250 characters allowed.
                </div>
                <div class="error-message" *ngIf="timelineUpdForm.get('description').hasError('aftersubmit')">
                    {{timelineUpdForm.controls.description.errors.aftersubmit}}
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="updateTimeline(timelineUpdForm.valid)">update</button>
    </div>
</ng-template>
<ng-template #timelineDetail>
    <div class="modal-body new-body">
        <div class="create-box timeline">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
                <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg"
                    alt="arrow" />
            </button>
        </div>
        <div class="d-flex detail-comment-box scrollbar">
            <div class="timeLinesEach custom-design mt-0">
                <div class="feedDetailSlider" [ngClass]="{'no-image': editTimelineObj?.timeline_documents?.length == 0 }">
                    <span class="event" *ngIf="editTimelineObj.post_type == 3">Event</span>
                    <span class="event" *ngIf="editTimelineObj.post_type == 2">Position</span>
                    <span class="event" *ngIf="editTimelineObj.post_type == 1">Update</span>
                    <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
                        <div class="slide" ngxSlickItem *ngFor="let document of editTimelineObj.timeline_documents">
                            <div class="img-box">
                                <img src="{{document.document}}" alt="image">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div class="feedDetailContent">
                    <div class="img-box">
                        <img src="{{pagesDetails.display_pic? pagesDetails.display_pic:'assets/images/default.svg'}}"
                            alt="small-img" />
                    </div>
                    <h3>{{editTimelineObj.title}}</h3>
                    <p>{{pagesDetails?.page_info?.title}}</p>
                    <div class="date-box" *ngIf="editTimelineObj.date != null">
                        <div class="month">
                            <span>{{editTimelineObj.date | date:'MMM'}}</span>
                        </div>
                        <div class="date">
                            <span>{{editTimelineObj.date | date:'d'}}</span>
                        </div>
                    </div>
                    <div class="save-content">
                        <div class="left-part">
                            <button type="button" class="interest-btn"
                                (click)="markInterested(editTimelineObj, editTimelineObj.post_type)"
                                [ngClass]="{'remove': editTimelineObj.interested_data?.interested}">
                                <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                <span class="remove-text">remove</span>
                                <span>Interested</span>
                            </button>
                            <button type="button" class="other-btn" *ngIf="editTimelineObj.interested_data?.count > 0"
                                (click)="openModalOthers(templateOthers, editTimelineObj)">
                                {{editTimelineObj.interested_data?.count}} Others
                            </button>
                        </div>
                    </div>
                    <div class="details">
                        <h4>Details</h4>
                        <p innerHtml="{{editTimelineObj.description}}"></p>

                        <p class="metaLink" *ngIf="!checkExtraData(editTimelineObj)">
                            <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
                        </p>
                        <div class="singleTimelineDetail" *ngIf="checkExtraData(editTimelineObj)">
                            <div class="imageBox">
                                <img alt="timeline" class="timeLineImg"
                                    src="{{editTimelineObj.metadata_image_link? editTimelineObj.metadata_image_link : 'assets/images/default.svg'}}" />
                            </div>
                            <div class="dtlOuter">
                                <h1>{{editTimelineObj.metadata_title}}</h1>
                                <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
                                <p>{{editTimelineObj.metadata_description}}</p>
                            </div>
                        </div>

                        <div class="posted-by">
                            <h4>Posted by</h4>
                            <div class="name-box cursor-pointer"
                                (click)="directToProfile(editTimelineObj.creator_info);modalRef.hide()">
                                <img src="{{editTimelineObj.creator_info.display_picture? editTimelineObj.creator_info.display_picture: 'assets/images/default.svg'}}"
                                    alt="image" />
                                <p>{{editTimelineObj.creator_info.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-comments [editFeedPostType]="editTimelineObj.post_type" [editFeedId]="editTimelineObj.id"></app-comments>
        </div>
    </div>
</ng-template>
<ng-template #addTimelineLink>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Link</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="linkModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row m-0">
            <div class="col-md-12">
                <form [formGroup]="timelinelinkForm" novalidate>
                    <input type="text" placeholder="Type Here" class="form-control" formControlName="link">
                    <div class="error-message"
                        *ngIf="timelinelinkForm.get('link').hasError('maxlength') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
                        Max 250 characters allowed.
                    </div>
                    <div class="error-message"
                        *ngIf="timelinelinkForm.get('link').hasError('pattern') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
                        Please enter a valid URL.
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="removeLink();linkModalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="setLink(timelinelinkForm.valid)">save</button>
    </div>
</ng-template>
<ng-template #templateOthers>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interested</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
        </button>
      </div>
    <div class="modal-body">
        <ul class="follow-list scroll-activity scrollbar">
          <li *ngFor="let usr of interestedUsers">
            <div class="info-content">
              <img alt="profile-image" src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
              <div class="text">
                <p class="bold" [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}</p>
                <p>{{usr.education_info.school_name}}</p>
                <p class="small" *ngIf="usr.education_info.school">{{usr.education_info.start_date | date: 'yyyy'}} - {{usr.education_info.end_date | date: 'yyyy'}}</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
          <a href="javascript:void(0);" (click)="getInterestedUsers()">Load More</a>
        </div>
    </div>
</ng-template>
<ng-template #schPostTemplate>
    <div class="modal-body new-body">
        <div class="modal-box">
            <div class="timeLineBlock">
                <div class="create-box">
                    <h4 class="modal-title pull-left">Create a post</h4>
                    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                        <img alt="image" src="assets/images/cross-modal.svg">
                    </button>
                </div>
                <div class="allTimeLinesOuter">
                    <div class="addTimeFrame">
                        <form [formGroup]="timelineForm" novalidate>
                            <div class="isThisPost">
                                <span class="">Is this post a(n):</span>
                                <div class="teal-radio-box">
                                    <input type="radio" value="1" id="postSelected1" (click)="postSelected=1;changeType()"
                                        name="post" checked />
                                    <label for="postSelected1">Update / Reminder</label>
                                </div>
                                <div class="teal-radio-box">
                                    <input type="radio" value="2" id="postSelected2" (click)="postSelected=2;changeType()"
                                        name="post" />
                                    <label for="postSelected2">Position</label>
                                </div>
                                <div class="teal-radio-box">
                                    <input type="radio" value="3" id="postSelected3" (click)="postSelected=3;changeType()"
                                        name="post" />
                                    <label for="postSelected3">Event</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="filesTimeLine custom-file-upload mr-2 mb-2"
                                    *ngIf="timelineDocs.length == 0">
                                    <div class="file-box">
                                        <input type="file" class="uploadInput" multiple=""
                                            (change)="uploadTimelineDoc($event)">
                                        <div class="file-content">
                                            <img src="/assets/images/teal-add-icon.svg" alt="plus-icon" />
                                            <p>Select photos (up to 5)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="forUploadedImages scrollbarHorizontal">
                                    <div class="forUploadedImagesInner mr-3 mb-2"
                                        *ngFor="let timelineDoc of timelineDocs; index as i">
                                        <img alt="icon" class="uploadedPreview" src="{{timelineDoc.previewImg}}" />
                                        <img alt="icon" class="previewDelete cursor-pointer"
                                            src="assets/images/cross-modal.svg" (click)="remTimelineDoc(i)" />
                                    </div>
                                </div>
                                <button type="button" class="select-photos-btn"
                                    *ngIf="timelineDocs.length > 0 && timelineDocs.length < 5">
                                    <input type="file" class="uploadInput" multiple=""
                                        (change)="uploadTimelineDoc($event)">
                                    Select photos
                                </button>
                                <div *ngIf="coverImageUploadError" class="error-message">
                                    {{coverImageUploadMessage}}
                                </div>
                                <div *ngIf="coverImageUploadSizeError" class="error-message">
                                    {{coverImageUploadSizeMessage}}
                                </div>
                                <div *ngIf="timelineDocs.length > 5" class="error-message">
                                    Maximum 5 images allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Title of post"
                                    formControlName="title">
                                <div class="error-message"
                                    *ngIf="timelineForm.get('title').hasError('required') && (timelineForm.get('title').dirty || timelineForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('title').hasError('maxlength') && (timelineForm.get('title').dirty || timelineForm.get('title').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <ckeditor [editor]="Editor" formControlName="description"
                                    [config]="{ toolbar: ['bold', 'italic'], placeholder: 'Details', baseFloatZIndex:100001}">
                                </ckeditor>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('description').hasError('required') && (timelineForm.get('description').dirty || timelineForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('description').hasError('maxlength') && (timelineForm.get('description').dirty || timelineForm.get('description').touched)">
                                    Max 2250 characters allowed.
                                </div>
                            </div>

                            <div class="form-group">
                                <input readonly type="text" class="form-control modal-control modal-field"
                                    placeholder="dd mm yy" bsDatepicker formControlName="date"
                                    [bsConfig]="{adaptivePosition: true}">
                                <div class="error-message"
                                    *ngIf="timelineForm.get('date').hasError('required') && (timelineForm.get('date').dirty || timelineForm.get('date').touched || submitted) && (postSelected == 3)">
                                    This field is required.
                                </div>
                            </div>

                            <div class="timeFrameBtns btn-box">
                                <div class="leftBtns">
                                    <div class="error-message"
                                        *ngIf="timelineForm.get('description').hasError('aftersubmit')">
                                        {{timelineForm.controls.description.errors.aftersubmit}}
                                    </div>
                                    <div class="leftBtnsInner">
                                        <div class="linkTimeLine mr-2 mb-2">
                                            <a href="javascript:void();"
                                                (click)="addTimelineLinkModal(addTimelineLink)"><img alt="icon"
                                                    src="assets/images/linkIcon.svg" /></a>
                                            <div class="linkAdded" *ngIf="linkAdded">Link Added.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="rightBtns">
                                    <button class="btn btn-colored"
                                        (click)="createTimeline(timelineForm.valid)">Post</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
