<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="notAccount">
                        <a [routerLink]="['/signin']">Have an Account? Sign In 
                            <img alt="icon" class="iconArrow" src="assets/images/blue-right-arrow.svg" />
                        </a>
                    </div>
                    <div class="SignUpInnerWrap WrapWhiteShadow">
                        <h1 class="hdg">Sign Up</h1>
                        <div *ngIf="submitError" class="error-message col-md-12 text-center">{{submitErrorMsg}}</div>
                        <p class="subHdg">None of the information you add here will be <br /> made public if you so choose.</p>
                        <form [formGroup]="firstStepForm" novalidate (ngSubmit)="save(firstStepForm.valid)">
                            <div class="form-group row mb-0">
                                <div class="col-md-6 inputRow">
                                    <input type="text" placeholder="First" class="form-control signUpField text-capitalize"
                                        formControlName="firstname">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('firstname').hasError('required') && (partialSubmitted || firstStepForm.get('firstname').dirty || firstStepForm.get('firstname').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('firstname').hasError('maxlength') && (firstStepForm.get('firstname').dirty || firstStepForm.get('firstname').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                </div>
                                <div class="col-md-6 inputRow">
                                    <input type="text" placeholder="Last" class="form-control signUpField text-capitalize"
                                        formControlName="lastname">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('lastname').hasError('required') && (partialSubmitted || firstStepForm.get('lastname').dirty || firstStepForm.get('lastname').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('lastname').hasError('minlength') && (firstStepForm.get('lastname').dirty || firstStepForm.get('lastname').touched)">
                                        Last name has to be greated than 1 character.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('lastname').hasError('maxlength') && (firstStepForm.get('lastname').dirty || firstStepForm.get('lastname').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-6">
                                    <input type="text" class="form-control signUpField" bsDatepicker
                                        [maxDate]="today" placeholder="Birthday" (bsValueChange)="checkDate()"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="date_of_birth" style="background-color: white;" readonly>

                                        <div class="error-message"
                                        *ngIf="firstStepForm.get('date_of_birth').hasError('required') && (firstStepForm.get('date_of_birth').dirty || firstStepForm.get('date_of_birth').touched)">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="email" placeholder="Email" class="form-control signUpField"
                                        formControlName="email">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('required') && (partialSubmitted || firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('maxlength') && (firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('pattern') && (firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('aftersubmit')">
                                        {{firstStepForm.controls.email.errors.aftersubmit}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="email" placeholder="Repeat Email" class="form-control signUpField"
                                        formControlName="confirm_email">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('required') && (partialSubmitted || firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('maxlength') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('pattern') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.hasError('emailNotMatch') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Emails do not match.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow" *ngIf="isMinor">
                                <div class="col-md-12">
                                    <input type="email" class="form-control mb-2 signUpField" placeholder="Parent's Email"
                                        formControlName="parent_email">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('parent_email').hasError('required') && (submitted || firstStepForm.get('parent_email').dirty || firstStepForm.get('parent_email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('parent_email').hasError('maxlength') && (firstStepForm.get('parent_email').dirty || firstStepForm.get('parent_email').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('parent_email').hasError('pattern') && (firstStepForm.get('parent_email').dirty || firstStepForm.get('parent_email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('parent_email').hasError('aftersubmit')">
                                        {{firstStepForm.controls.parent_email.errors.aftersubmit}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="password" class="form-control mb-2 signUpField" placeholder="Password"
                                            formControlName="password">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('password').hasError('required') && (submitted || firstStepForm.get('password').dirty || firstStepForm.get('password').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('password').hasError('maxlength') && (firstStepForm.get('password').dirty || firstStepForm.get('password').touched)">
                                        Max 15 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="!firstStepForm.get('password').hasError('maxlength') && firstStepForm.get('password').hasError('invalidPassword') && (firstStepForm.get('password').dirty || firstStepForm.get('password').touched)">
                                        Your password needs to be at least 8 characters, including a Capital Letter,
                                        Lowercase Letter and a Number.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="password" class="form-control signUpField" placeholder="Repeat Password"
                                        formControlName="confirm_password">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_password').hasError('required') && (submitted || firstStepForm.get('confirm_password').dirty || firstStepForm.get('confirm_password').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_password').hasError('minlength') && (firstStepForm.get('confirm_password').dirty || firstStepForm.get('confirm_password').touched)">
                                        Your password needs to be at least 8 characters, including a Capital Letter,
                                        Lowercase Letter and a Number.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.hasError('passwordNotMatch') && (firstStepForm.get('confirm_password').dirty || firstStepForm.get('confirm_password').touched)">
                                        Passwords do not match.
                                    </div>
                                </div>
                            </div>  
                            <div class="signupRadioOuter">
                                <div class="custom-radio-blue">
                                    <input type="checkbox" class="custom-control-input" id="customCheck"
                                        name="privacy" (change)="onCheckboxChange($event)">
                                        <label class="custom-control-label" for="customCheck">I have read and agree to
                                            the
                                            <a [routerLink]="['/terms-of-use']" target="_blank" rel="noopener noreferrer">Terms of use</a> and
                                            <a [routerLink]="['/privacy-policy']" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                                        </label>
                                        <div class="error-message" *ngIf="!user.is_terms_accepted && submitted">
                                            Please agree to the Terms and Conditions and Privacy Policy.
                                        </div>
                                </div>
                            </div>
                            <div class="form-group m-0 text-center">
                                <button type="submit" class="btn btnSignUp">Sign Up</button>
                            </div>
                        </form>
                        
                    </div>

                    <div class="SignUpInnerWrap WrapWhiteShadow">
                        <h1 class="hdg">Access Code</h1>
                        <p class="subHdg">The Access Code is on the email that sent you here!</p>
                        <form [formGroup]="inviteForm" novalidate (ngSubmit)="checkCode(inviteForm.valid)">
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="text" placeholder="Enter your Access Code Here" class="form-control signUpField" formControlName="invite_code"/>
                                    <div class="error-message"
                                        *ngIf="inviteForm.get('invite_code').hasError('aftersubmit')">
                                        {{inviteForm.controls.invite_code.errors.aftersubmit}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-0 text-center">
                                <button type="submit" class="btn btnSignUp">Next</button>
                            </div>
                        </form>
                    </div>

                    <div class="SignUpInnerWrap WrapWhiteShadow m-0">
                        <h1 class="hdg mb-4 pb-2">Back to Home Page</h1>
                        <div class="form-group m-0 text-center">
                            <button type="button" [routerLink]="['/']" class="btn btnSignUp">Click Here</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="success-overlay" *ngIf="successOverlay">
    <div class="success-div">
        <div *ngIf="isMinor" class="inner-div text-center">
            <p>Verification Email Sent</p>
            <h1>Please Verify Your Email to Access Your Account. Make sure your Parent also verifies your Email Address.
            </h1>
            <h1>{{resendEmailMessage}}</h1>
            <button class="btn btn-common mr-3" (click)="disableOverlay()">Okay!</button>
            <button class="btn btn-common" (click)="resendVerification()">Re-Send Email</button>
        </div>
        <div *ngIf="!isMinor" class="inner-div text-center">
            <p>Verification Email Sent</p>
            <h1>Please Verify Your Email to Access Your Account</h1>
            <h1>{{resendEmailMessage}}</h1>
            <button class="btn btn-common mr-3" (click)="disableOverlay()">Okay!</button>
            <button class="btn btn-common" (click)="resendVerification()">Re-Send Email</button>
        </div>
    </div>
</div>
