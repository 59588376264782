<section class="common-section fullPage">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-md-4 d-md-block d-none sidebarContent fullPage">
                <div class="footer-nav">
                    <div class="footerbar">
                        <h4>About</h4>
                        <ul class="pl-0">
                            <li class="active">
                                <a [routerLink]="['/about-us']">About Us</a>
                            </li>
                            <li>
                                <a [routerLink]="['/guidelines']">Community Guidelines</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Policies</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/terms-of-use']">Terms of Use</a>
                            </li>
                            <li>
                                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Resources</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/faq']" routerLinkActive="active">FAQ</a>
                            </li>
                            <li>
                                <a [routerLink]="['/mental-health']">Mental Health</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Why Sign Up?</h4>
                        <ul class="pl-0">
                            <li class=""><a [routerLink]="['/why-signup-students']">Students</a></li>
                            <li class=""><a [routerLink]="['/why-signup-others']">Others</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-md-8" [innerHtml]="aboutUsContent|safe">
            </div>
        </div>
    </div>
</section>