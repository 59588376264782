import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-page',
  templateUrl: './business-page.component.html',
  styleUrls: ['./business-page.component.scss']
})
export class BusinessPageComponent implements OnInit {
  modalRef: BsModalRef;
  loggedIn : boolean =false;
  user: any = {};
  user_profile_exist: boolean =false;
  submitted: boolean = false;
  public searchSubmitted: boolean = false;
  isDisabled :boolean = false;

  constructor(private modalService: BsModalService, private router: Router) {} 

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      var dob = currentUser.date_of_birth
      var today_ = new Date();
      var birthDate = new Date(dob);
      var agee = today_.getFullYear() - birthDate.getFullYear();
      var m = today_.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today_.getDate() < birthDate.getDate())) {
          agee--;
      }
      if(agee < 13){
        this.router.navigate(['/']);
      }
      if (this.user.user_role === 2){
          this.isDisabled =true
      }
    }
  } 

  openDisclaimer(disclaimer: TemplateRef<any>) {
    this.modalRef = this.modalService.show(disclaimer, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

}
