import { Component, OnInit, TemplateRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {

  editFeedObj: any = {};
  copyMessageBool: boolean = false;
  shareUrlIdCheck: any;
  user: any = {};
  reportModalRef: BsModalRef;
  otherModalRef: BsModalRef;
  modalRef: BsModalRef;
  intModalRef: BsModalRef;
  blockReportForm: FormGroup;
  submitted: boolean = false;
  isInterested: boolean = false;
  intUsersNextPage: any = 1;
  interestedUsers:any = [];
  totalUsersCount: number = 0;
  hideContent: boolean = false;
  showPostDetailError:boolean = false;
  showDeletePostMessage:boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private profileService: ProfileService, public formBuilder: FormBuilder, private modalService: BsModalService,  private pageService: PageService) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.blockReportForm = this.formBuilder.group({
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
  }

  ngOnInit(): void {
    let postId = this.route.snapshot.params['post_id'];
    let postType = this.route.snapshot.params['post_type'];
    this.profileService.getPostDetails(postId, postType).subscribe(response => {
     if(response.status == 200){
        if (response.response.result == null){
          this.hideContent = true;
          this.editFeedObj = {};
        } else if (response.response.result.id == null){
          this.showDeletePostMessage = true;
        } else {
          this.editFeedObj = response.response.result;
          this.hideContent = false;
        }
      }
    }, error => {
       this.showPostDetailError = true;
    });
  }

  copyUrl(data: any){
    this.profileService.getShareUrl(data.id, data.post_info.post_type).subscribe(response => {
      const msg = response.response.share_post_url;
      this.setToCopy(msg);
      this.shareUrlIdCheck = data.id;
      this.copyMessageBool = true;
      setTimeout(() => {this.copyMessageBool = false;}, 3000);
    });
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  checkExtraData(feed: any){
    if (feed.link && feed.metadata_title && (feed.metadata_description || feed.metadata_image_link)){
      return true;
    } else if (feed.link && !feed.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  directToProfile(feed: any){
    if (feed.post_info.creator_id === this.user.id) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['profile/' + feed.post_info.creator_id + '/']);
    }
  }

  closeModal(){
    if (this.otherModalRef) {
      this.otherModalRef.hide();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.intModalRef) {
      this.intModalRef.hide();
    }
  }

  reportModal(reportTemplate: TemplateRef<any>, feed: any) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.editFeedObj = feed;
    this.reportModalRef = this.modalService.show(reportTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' }
    ));
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true;
    } else {
      return false;
    }
  }

  directToPage(feed: any) {
    if (feed.post_info.ini_id) {
      this.router.navigate(['initiatives/' + feed.post_info.ini_id + '/']);
    } else if (feed.post_info.page_id) {
      this.router.navigate(['page/' + feed.post_info.page_id + '/']);
    } else if (feed.post_info.school_id) {
      this.router.navigate(['school/' + feed.post_info.school_id + '/']);
    }
  }

  markInterested(feed: any){
    if (feed.interested_data.interested) {
      this.pageService.unMarkInterested(feed.id, feed.post_info.post_type)
      .subscribe(response => { });
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = false;
      }
    } else {
      this.pageService.markInterested(feed.id, feed.post_info.post_type, {})
      .subscribe(response => { });
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = true;
      }
    }
  }

  reportFeed(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description']
      }
      if (this.editFeedObj.post_info.post_type === 1) {
        report_data['page_general'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 2) {
        report_data['reported_position'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 3) {
        report_data['reported_event'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 4) {
        report_data['initiative_general'] = this.editFeedObj.id;
      }
      else if ([5, 10].includes(this.editFeedObj.post_info.post_type)){
        report_data['reported_volunteer'] = this.editFeedObj.id;
      }
      else if ([6, 7, 8].includes(this.editFeedObj.post_info.post_type)){
        report_data['school_timeline'] = this.editFeedObj.id;
      }
      this.profileService.reportFeed(report_data).subscribe(respones => {});
      this.reportModalRef.hide();
      this.closeModal();
    } else {
      this.submitted = true;
    }
  }

  openModalOthers(template: TemplateRef<any>, feed: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editFeedObj = feed;
    this.getInterestedUsers();
    this.otherModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editFeedObj.id, this.editFeedObj.post_info.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            },
          },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

}

