import { Component, OnInit, TemplateRef, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { PageService } from 'src/app/services/page.service';
import { TeamService } from 'src/app/services/team.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-volunteer-experience',
  templateUrl: './volunteer-experience.component.html',
  styleUrls: ['./volunteer-experience.component.scss']
})
export class VolunteerExperienceComponent implements OnInit {
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  pageTagForm: FormGroup;
  expForm: FormGroup;
  searchForm: FormGroup;
  expUpdForm: FormGroup;
  verifierForm: FormGroup;
  pageForm: FormGroup;
  usrWorkExperience = [];
  verifiers = [];
  editExp: any = {};
  hideResults: boolean = false;
  previewShow: boolean = false;
  formShow: boolean = false;
  pageformShow: boolean = false;
  verifierFormShow: boolean = false;
  isEndDate: boolean = false;
  isOneDayEvnt: boolean = false;
  isSelfEmployed: boolean = false;
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  public showAddressError: boolean = false;
  today = new Date();
  orgId: string = null;
  verifierId: string = null;
  evntPlaceholder: string = "Start Date";
  @Input() user: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  pageImage: string = null;
  canSrchFirstname: boolean = false;
  canSrchLastname: boolean = false;
  selectedOrg: any = {};
  plchldrState = "Province";
  plchldrPostal = "Postal";
  countrySet: string = 'CA';
  hidePlaceResults: boolean = true;
  hidePlaceResultsCity: boolean = true;
  searchPlaces: any[] = [];
  updateAddress: string = '';
  updateCity: string = '';
  latitude: string = null;
  longitude: string = null;
  canCreatePage: boolean = false;
  showTag: boolean = true;
  selectedExp: any = {};
  cityPlaceHolder = "City"
  usrWorkExperienceTotal: any = 0;
  teamMember: any = [];
  teamMemberSliced: any = [];
  page_size = 6;
  teamError: boolean = false;
  @Output() exp = new EventEmitter<any>();
  @Output() hasData = new EventEmitter<any>();
  tagSearch: boolean = false;
  childNotifier : Subject<boolean> = new Subject<boolean>();
  @ViewChild('expTemplate') expTemplate: TemplateRef<any>;

  constructor(private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder,
    private profileService: ProfileService, private customValidator: CustomvalidationService, private pageService: PageService, private teamService: TeamService) {

    this.pageTagForm = this.formBuilder.group({
      page_id: new FormControl('')
    },{ validators: this.idValidator });
    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.maxLength(125)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      total_hours: new FormControl('', [Validators.required, Validators.min(0)])
    }, { validators: this.dateValidator });
    this.expForm = this.formBuilder.group({
      skill_1: new FormControl('', Validators.maxLength(30)),
      skill_2: new FormControl('', Validators.maxLength(30)),
      skill_3: new FormControl('', Validators.maxLength(30)),
      main_role: new FormControl('', Validators.maxLength(1125)),
      personal_details: new FormControl('', Validators.maxLength(2250)),
    });
    this.expUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      total_hours: new FormControl('', [Validators.required, Validators.min(0)]),
      skill_1: new FormControl('', Validators.maxLength(30)),
      skill_2: new FormControl('', Validators.maxLength(30)),
      skill_3: new FormControl('', Validators.maxLength(30)),
      main_role: new FormControl('', Validators.maxLength(1125)),
      personal_details: new FormControl('', Validators.maxLength(2250)),
    }, { validators: this.dateValidator });
    this.verifierForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)])
    });
    this.pageForm = this.formBuilder.group({
      organization_type: new FormControl('', Validators.required),
      organization_title: new FormControl('', [Validators.required, Validators.maxLength(125),this.noWhitespaceValidator]),
      address: new FormControl('', [Validators.maxLength(200)]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      zip_code: new FormControl('', [Validators.minLength(6), Validators.maxLength(6)])
    });

  }

  ngOnInit(): void {
    this.getUserWorkExperienceRecent();
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0,0,0,0);
      end_date = new Date(end_date).setHours(0,0,0,0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }
    return null;
  }

  idValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let inpVal = control.get('page_id').value;
    if (inpVal) {
      let page_type = inpVal.slice(0,3);
      let page_id = parseInt(inpVal.slice(3));
      if (['ORG', 'SCH'].indexOf(page_type) < 0 || isNaN(page_id) || page_id < 0) {
        return {'invalidPageId': true };
      }
      return null;
    }
    return null;
  }

  oneDayEvnt(e) {
    const key = e.target.value;
    this.searchForm.get('end_date').clearValidators();
    this.searchForm.get('end_date').updateValueAndValidity();
    this.searchForm.controls['end_date'].setValue(null);
    if (key == "1") {
      this.isOneDayEvnt = true;
      this.evntPlaceholder = "Date";
    } else {
      this.isOneDayEvnt = false;
      this.evntPlaceholder = "Start Date";
    }
  }

  endDate(e){
    const key = e.target.value;
    if(key == "1"){
      this.isEndDate = true;
      this.searchForm.get('end_date').setValidators([Validators.required]);
      this.searchForm.get('end_date').updateValueAndValidity();
    }else{
      this.isEndDate = false;
      this.searchForm.get('end_date').clearValidators();
      this.searchForm.get('end_date').updateValueAndValidity();
      this.searchForm.controls['end_date'].setValue(null);
    }

  }

  canEditEndDate(){
    if (!this.editExp.end_date) {
      return true;
    } else {
    return false;
    }
  }

  resetEndDate(){
    this.expUpdForm.controls['end_date'].setValue(null);
    this.searchForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.expUpdForm.controls['start_date'].setValue(null);
    this.searchForm.controls['start_date'].setValue(null);
  }

  changeOrgType(e){
    if (e.target.value == 5){
      this.isSelfEmployed = true;
      this.pageForm.controls['address'].setValue(null);
      this.updateAddress = null;
      this.updateCity = null;
    } else {
      this.isSelfEmployed = false;
    }
  }

  changeCountry(e){
    if (e.target.value == "USA"){
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
      this.pageForm.controls['zip_code'].setValidators([Validators.minLength(5), Validators.maxLength(5)])
    } else {
      this.plchldrState = "Province";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
      this.pageForm.controls['zip_code'].setValidators([Validators.minLength(6), Validators.maxLength(6)])
    }
    this.updateAddress = null;
    this.updateCity = null;
    this.pageForm.controls['address'].setValue(null);
    this.pageForm.controls['state'].setValue(null);
    this.pageForm.controls['city'].setValue(null);
    this.pageForm.controls['zip_code'].setValue(null);
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  addressFocusOut() {
    setTimeout(() => {
      this.hidePlaceResults = true;
      this.searchPlaces = [];
    }, 1000);
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.updateAddress = null;
      this.pageForm.controls['address'].setValue(null);
      this.showAddressError = true;
    } else {
      this.updateAddress = e.target.value;
      this.updateCity = null;
      this.pageForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    }
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  getUserWorkExperience(){
    this.profileService.getUserWorkExp("2")
    .subscribe(response => {
      if(response.response.length > 0){
          this.usrWorkExperience = response.response;
          this.usrWorkExperienceTotal = response.response.length;
        } else {
          this.usrWorkExperience = [];
        }
      });
  }

  getUserWorkExperienceRecent(){
    this.profileService.getUserWorkExpRecent("2")
    .subscribe(response => {
      if(response.response.results.length > 0){
          this.usrWorkExperience = response.response.results;
          this.usrWorkExperienceTotal = response.response.total_count;
          this.hasData.emit(true);
        } else {
          this.usrWorkExperience = [];
        }
      });
  }

  aftrTag(isValid: boolean){
    if (isValid) {
      this.showTag = !this.showTag;
      this.formShow = !this.formShow;
      if (Object.keys(this.selectedOrg).length > 0){
        this.searchForm.controls['title'].setValue(this.selectedOrg.page_title);
      }
    }
  }

  config = {
    ignoreBackdropClick: false,
    class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal',
  };

  addSection(){
    this.expModal(this.expTemplate);
  }

  expModal(expTemplate: TemplateRef<any>) {
    this.teamMember = [];
    this.teamMemberSliced = [];
    this.selectedOrg = {};
    this.selectedExp = {};
    this.teamError = false;
    this.orgId = null;
    this.verifierId = null;
    this.latitude = null;
    this.longitude = null;
    this.pageImage = null;
    this.isOneDayEvnt = false;
    this.previewShow = false;
    if (this.user.age_group == 1) {
      this.showTag = false;
      this.formShow = false;
    } else {
      this.showTag = true;
      this.formShow = true;
    }
    this.isEndDate = false;
    this.submitted = false;
    this.partialSubmitted = false;
    this.pageformShow = false;
    this.verifierFormShow = false;
    this.isSelfEmployed = false;
    this.canCreatePage = false;
    this.pageTagForm.reset();
    this.searchForm.reset();
    this.expForm.reset();
    this.pageForm.reset();
    this.hideResults = true;
    this.searchForm.get('end_date').clearValidators();
    this.searchForm.get('end_date').updateValueAndValidity();
    this.searchForm.controls['end_date'].setValue(null);
    this.pageForm.controls['country'].setValue('Canada', {onlySelf: true});
    this.pageForm.controls['organization_type'].setValue(2, {onlySelf: true});
    this.updateAddress = null;
    this.updateCity = null;
    this.modalRef = this.modalService.show(expTemplate,this.config);
  }

  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.orgId = null;
    this.pageImage = null;
    this.editExp = exp;
    if (exp.organization_title) {
      this.expUpdForm.controls['title'].setValue(exp.organization_title);
    } else {
      this.expUpdForm.controls['title'].setValue("Self-employed");
    }
    this.expUpdForm.controls['position'].setValue(exp.position);
    this.expUpdForm.controls['start_date'].setValue(exp.start_date);
    this.expUpdForm.controls['end_date'].setValue(exp.end_date);
    this.expUpdForm.controls['skill_1'].setValue(exp.skill_1);
    this.expUpdForm.controls['skill_2'].setValue(exp.skill_2);
    this.expUpdForm.controls['skill_3'].setValue(exp.skill_3);
    this.expUpdForm.controls['main_role'].setValue(exp.main_role);
    this.expUpdForm.controls['personal_details'].setValue(exp.personal_details);
    this.expUpdForm.controls['total_hours'].setValue(exp.total_hours);
    this.hideResults = true;
    this.updateModalRef = this.modalService.show(editExpTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  showForm(isValid: boolean) {
    this.partialSubmitted = false;
    if (isValid){
      this.formShow = !this.formShow;
      let exp_start_date: any = this.searchForm.controls['start_date'].value;
      let exp_end_date: any = this.searchForm.controls['end_date'].value;
      if (this.user.age_group == 1) {
        this.canCreatePage = false;
      } else {
        this.canCreatePage = true;
      }
      if (this.orgId || !this.canCreatePage) {
        this.verifierFormShow = !this.verifierFormShow;
      } else {
        this.pageformShow = !this.pageformShow;
      }
      if (exp_end_date && exp_start_date.getTime() === exp_end_date.getTime()){
          this.isOneDayEvnt = true;
      }
    } else {
      this.partialSubmitted = true;
    }
  }

  volunteerListing(volunteerListModal: TemplateRef<any>) {
    if (this.usrWorkExperience.length !== this.usrWorkExperienceTotal) {
      this.getUserWorkExperience();
    }
    this.modalRef = this.modalService.show(volunteerListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  showPageForm(isValid: boolean, isReturn: boolean=false) {
    this.partialSubmitted = false;
    if ((isValid && !this.isSelfEmployed) || (!isValid && this.isSelfEmployed)){
      if (isValid) {
        this.searchForm.controls['title'].setValue(this.pageForm.get('organization_title').value);
        if (!isReturn) {
          this.checkAddress();
        }
      }
      if (this.orgId) {
        this.formShow = !this.formShow;
        this.verifierFormShow = !this.verifierFormShow;
      } else if (isReturn || this.isSelfEmployed) {
        this.pageformShow = !this.pageformShow;
        this.verifierFormShow = !this.verifierFormShow;
      }
    } else {
      this.partialSubmitted = true;
    }
  }

  checkAddress(){
    if (this.pageForm.controls['address'].value){
      let model = {'country': this.pageForm.get('country').value,
                  'state': this.pageForm.get('state').value,
                  'address': this.pageForm.get('address').value,
                  'city': this.pageForm.get('city').value,
                  'zip_code': this.pageForm.get('zip_code').value}
      this.profileService.checkPageAddress(model)
      .subscribe(response => {
        if (response.response.is_exists) {
          this.pageForm.get('address').setErrors({aftersubmit:'Uh Oh! This address is already in use. Please try entering a new address, or check if the page you are looking for already exists. Thank you!'});
        } else {
          this.pageformShow = !this.pageformShow;
          this.verifierFormShow = !this.verifierFormShow;
        }
      }, error => {
        this.pageformShow = !this.pageformShow;
        this.verifierFormShow = !this.verifierFormShow;
      })
    } else {
      this.pageformShow = !this.pageformShow;
      this.verifierFormShow = !this.verifierFormShow;
    }
  }

  showPreview(isValid: boolean){
    if (isValid){
      this.addExperience();
    } else {
      this.submitted = true;
    }
  }

  tagPage(isValid: boolean){
    if (isValid) {
      this.getOrgById();
    }
  }

  reTagPage(){
    this.selectedOrg = {};
    this.orgId = null;
  }

  getOrgById(){
    if (this.user.age_group == 1) {
      return;
    }
    let inpVal = this.pageTagForm.get('page_id').value;
    if (inpVal){
      this.tagSearch = false;
      this.selectedOrg = {};
      this.orgId = null;
      this.pageImage = null;
      this.resetTagSearch();
      let page_type = inpVal.slice(0,3);
      let page_id = inpVal.slice(3);
      let nType = "3";
      if (page_type === 'SCH'){
        nType = "1";
      }
      if (page_id !== null && page_id > 0) {
        this.profileService.searchOrgPage(page_id, nType)
        .subscribe(response => {
          if (response){
            this.selectOrg(response.response);
          } else {
            this.pageTagForm.get('page_id').setErrors({ aftersubmit: "Page don't exists." });
          }
        });
      }
    }
  }

  selectOrg(org: any) {
    this.selectedOrg = org;
    this.orgId = org.id;
    this.pageImage = org.display_pic;
  }

  selectVerifier(verifier: any){
    if (verifier.id) {
      this.verifierId = verifier.id;
      this.canSrchFirstname = false;
      this.canSrchLastname = false;
      this.verifierForm.controls['first_name'].setValue(verifier.first_name);
      this.verifierForm.controls['last_name'].setValue(verifier.last_name);
      this.verifierForm.controls['email'].setValue(verifier.email);
    }
  }

  firstnameSearch() {
    this.canSrchFirstname = true;
    this.verifierId = null;
    this.verifierForm.controls['email'].setValue(null);
  }

  lastnameSearch() {
    this.canSrchLastname = true;
    this.verifierId = null;
    this.verifierForm.controls['email'].setValue(null);
  }

  focusOutEmail(){
    let email = this.verifierForm.get('email').value;
    let first_name = this.verifierForm.get('first_name').value;
    let last_name = this.verifierForm.get('last_name').value;
    if (email && first_name && last_name) {
      let index = this.verifiers.findIndex(obj => obj.email.toLowerCase() === email.toLowerCase());
      if (index > -1 && this.verifierId == null) {
        let verif = this.verifiers[index];
        if (verif.first_name.toLowerCase() == first_name.toLowerCase() && verif.last_name.toLowerCase() == last_name.toLowerCase()) {
          this.selectVerifier(this.verifiers[index]);
        } else {
          this.verifierForm.get('email').setErrors({ aftersubmit: 'Professional Reference with this email already exists.' });
        }
      }
    }
  }

  addExperience() {
    let school = null;
    let page = null;
    let page_data = null;
    if (this.selectedOrg && this.selectedOrg.school_name) {
      school = this.orgId;
    } else {
      page = this.orgId;
    }
    if (!this.isSelfEmployed && !this.orgId && this.canCreatePage) {
      page_data = {'organization_type': this.pageForm.get('organization_type').value,
                    'country': this.pageForm.get('country').value,
                    'state': this.pageForm.get('state').value,
                    'address': this.pageForm.get('address').value,
                    'city': this.pageForm.get('city').value,
                    'zip_code': this.pageForm.get('zip_code').value,
                    'latitude': this.latitude,
                    'longitude': this.longitude}
    }
    let start_date = this.datePipe.transform(this.searchForm.get('start_date').value,"yyyy-MM-dd");
    let end_Date = this.datePipe.transform(this.searchForm.get('end_date').value,"yyyy-MM-dd");
    if (this.isOneDayEvnt) {
      end_Date =  this.datePipe.transform(this.searchForm.get('start_date').value,"yyyy-MM-dd");
    }
    let model = {'page': page,
                'school': school,
                'organization_title': this.searchForm.get('title').value,
                'user': this.user.id,
                'position': this.searchForm.get('position').value,
                'start_date': start_date,
                'end_date': end_Date,
                'total_hours': this.searchForm.get('total_hours').value,
                'experience_type': 2,
                'page_data': page_data}
    this.profileService.addUserWorkExp(model)
    .subscribe(response => {
      this.handleExpResponse(response);
    }, error => {
        this.handleExpSubmitError(error.error);
    })
  }

  updateExperience(isValid: boolean){
    this.submitted = true;
    let businessId = this.editExp.page;
    if (this.orgId !== null) {
      businessId = this.orgId;
    }
    if (isValid) {
      let start_date = this.datePipe.transform(this.expUpdForm.get('start_date').value, "yyyy-MM-dd");
      let end_Date =  this.datePipe.transform(this.expUpdForm.get('end_date').value, "yyyy-MM-dd");
      let model = {'organization_title': this.expUpdForm.get('title').value,
                  'user': this.user.id,
                  'position': this.expUpdForm.get('position').value,
                  'start_date': start_date,
                  'end_date': end_Date,
                  'total_hours': this.expUpdForm.get('total_hours').value,
                  'experience_type': 2,
                  'skill_1': this.expUpdForm.get('skill_1').value,
                  'skill_2': this.expUpdForm.get('skill_2').value,
                  'skill_3': this.expUpdForm.get('skill_3').value,
                  'main_role': this.expUpdForm.get('main_role').value,
                  'personal_details': this.expUpdForm.get('personal_details').value}
      this.profileService.updateUserWorkExp(model, this.editExp.id)
      .subscribe(response => {
        this.handleExpUpdResponse(response);
      }, error => {
          this.handleExpSubmitError(error.error);
      })
    }
  }

  delExperience(){
    this.profileService.deleteUserWorkExp(this.editExp.id)
    .subscribe(response => {
      if (this.usrWorkExperienceTotal === this.usrWorkExperience.length){
        let index = this.usrWorkExperience.findIndex(obj => obj.id === this.editExp.id)
        this.usrWorkExperience.splice(index, 1)
        this.usrWorkExperienceTotal = this.usrWorkExperience.length;
        if (this.usrWorkExperience.length == 0) {
          this.hasData.emit(false);
        }
      }
      else {
        this.getUserWorkExperienceRecent();
      }
      this.updateModalRef.hide();
      this.editExp = {};
      this.expUpdForm.reset();
    })
  }

  protected handleExpResponse(response: any) {
    if (response.status === 111) {
      this.handleExpSubmitError(response.message);
    } else if (response.status === 200) {
        this.verifierFormShow = !this.verifierFormShow;
        this.previewShow = !this.previewShow;
        if (response.response.page) {
          this.getTeamMembers(response.response);
        }
        this.usrWorkExperience.push(response.response);
        this.hasData.emit(true);
        this.usrWorkExperience.sort(this.custom_sort);
        this.usrWorkExperienceTotal = this.usrWorkExperienceTotal + 1;
        this.searchForm.reset();
        this.expForm.reset();
        this.partialSubmitted = false;
        this.submitted = false;
        this.selectedOrg = {};
    }
  }

  protected handleExpUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleExpSubmitError(response.message);
    } else if (response.status === 200) {
      let index = this.usrWorkExperience.findIndex(obj => obj.id === this.editExp.id)
      this.usrWorkExperience[index] = response.response;
      this.updateModalRef.hide();
      this.expUpdForm.reset();
      this.submitted = false;
    }
  }

  protected handleExpSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.expUpdForm.contains(field)) {
        this.expUpdForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.canSrchFirstname = false;
      this.canSrchLastname = false;
    }, 1000);
  }

  redirectPage(experience: any){
    if (!experience.related_data.is_deleted) {
      if (experience.page) {
        let url = '/page/' + experience.page;
        if (experience.related_data.is_related || experience.related_data.is_unclaimed) {
          url = '/page/' + experience.page + '/profile-business';
        }
        this.router.navigate([url]);
      } else if (experience.school) {
        let url = '/school/' + experience.school;
        this.router.navigate([url]);
      }
    }
  }

  custom_sort(a: any, b: any) {
    return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let address = place.address.freeformAddress.split(",")[0];
    if (this.pageForm.controls['address'].value){
      this.updateAddress = address;
      this.pageForm.controls['address'].setValue(address);
    }
    this.updateCity = place.address.municipality;
    if (this.updateCity.length > 20) {
      this.updateCity = this.updateCity.split(",")[0];
    }
    this.pageForm.controls['city'].setValue(this.updateCity);
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.pageForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

  addReferenceListing(addReferenceModal: TemplateRef<any>, experience: any) {
    this.previewShow = false;
    this.selectedExp = experience;
    this.getReferences();
    this.verifierForm.reset();
    this.updateModalRef = this.modalService.show(addReferenceModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getReferences(){
    this.verifiers = [];
    if (this.selectedExp.school) {
      this.profileService.getSchoolVerifiers(this.selectedExp.school)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.verifiers = response.response;
        } else {
          this.verifiers = [];
        }
      }, error => {
        this.verifiers = [];
      })
    } else {
      this.profileService.getPageVerifiers(this.selectedExp.page)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.verifiers = response.response;
        } else {
          this.verifiers = [];
        }
      }, error => {
        this.verifiers = [];
      })
    }
  }

  addReference(isValid: boolean){
    if (isValid){
      let model = {'first_name': this.verifierForm.get('first_name').value,
                    'last_name': this.verifierForm.get('last_name').value,
                    'email': this.verifierForm.get('email').value,
                    'exp_id': this.selectedExp.id
                  }
      this.profileService.addExpReference(model)
      .subscribe(response => {
        if (response.status === 200) {
            this.previewShow = !this.previewShow;
            this.verifierForm.reset();
            let index = this.usrWorkExperience.findIndex(obj => obj.id === this.selectedExp.id);
            this.usrWorkExperience[index] = response.response;
            this.submitted = false;
            this.selectedExp = {};
        } else {
          this.verifierForm.get('email').setErrors({aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.verifierForm.get('email').setErrors({aftersubmit: "Something went wrong please try again later."});
      })
    } else {
      this.submitted = true;
    }
  }

  changeCity(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.showAddressError = true;
      this.updateCity = null;
      this.pageForm.controls['city'].setValue(null);
      this.latitude = null;
      this.longitude= null;
    } else {
      this.updateCity = e.target.value;
      if (this.updateCity.length > 20) {
        this.updateCity = this.updateCity.split(",")[0];
      }
      this.pageForm.controls['city'].setValue(this.updateCity);
      this.showAddressError = false;
    }
  }

  setCityResultHide(e){
    this.hidePlaceResultsCity = e;
  }

  cityFocusOut() {
    setTimeout(() => {
      this.hidePlaceResultsCity = true;
      this.searchPlaces = [];
    }, 1000);
  }

  getTeamMembers(experience: any){
    if (this.orgId) {
      this.selectedExp = experience;
      this.pageService.expteamMembers(experience.id)
      .subscribe(response => {
        if(response.status == 200){
            this.teamMember = response.response.experiences;
            this.teamMemberSliced = this.teamMember.slice(0, this.page_size);
          }
      });
    }
  }

  teamLoadMore(){
    let totalMember = this.teamMember.length;
    let slicedMember = this.teamMemberSliced.length;
    if (slicedMember < totalMember){
      this.teamMemberSliced = this.teamMember.slice(0, slicedMember + this.page_size)
    }
  }

  addToTeam(member: any){
    this.teamError = false;
    let model = {'tagged_by_exp': this.selectedExp.id};
    if (member.is_activity) {
      model['tagged_to_act'] = member.id;
    } else {
      model['tagged_to_exp'] = member.id;
    }
    model['to_user'] = member.student_info.id;
    this.teamService.addTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.teamMemberSliced.findIndex(obj => obj.id === member.id);
        this.teamMemberSliced[index]['team_id'] = response.response.id;
      } else {
        this.teamError = true;
      }
    }, error => {
      this.teamError = true;
    })
  }

  cancelTeam(member: any){
    this.teamService.deleteTeamMember(member.team_id)
    .subscribe(response => {
        let index = this.teamMemberSliced.findIndex(obj => obj.id === member.id);
        this.teamMemberSliced[index]['team_id'] = null;
    }, error => {
      this.teamError = true;
    })
  }

  addTeamMember(experience: any){
    if (experience.page && experience.id) {
      let data = {"expId": experience.id, "expType": "3","pageId": experience.page, "title": experience.organization_title}
      this.exp.emit(data);
    }
  }

  teamMemberView(experience: any){
    if (experience.id && experience.page) {
      let data = {"expId": experience.id, "expType": "work", "title": experience.organization_title}
      this.exp.emit(data);
    }
  }

  getSelectedPage(page: any) {
    this.tagSearch = true;
    this.selectedOrg = {};
    this.orgId = null;
    this.pageImage = null;
    this.pageTagForm.reset();
    this.selectOrg(page);
  }

  resetTagSearch() {
    this.childNotifier.next(this.tagSearch);
  }

  resetCount(expId: any, count: any){
    let index = this.usrWorkExperience.findIndex(obj => obj.id === expId);
    this.usrWorkExperience[index].related_data.exp_count = count;
  }

}
