import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { TeamService } from 'src/app/services/team.service';
import { WorkExperienceComponent } from '../work-experience/work-experience.component';
import { VolunteerExperienceComponent } from '../volunteer-experience/volunteer-experience.component';
import { ExtracurricularComponent } from '../extracurricular/extracurricular.component';
import { SchoolInvolvementComponent } from '../school-involvement/school-involvement.component';
import { AwardsCredentialsComponent } from '../awards-credentials/awards-credentials.component';
import { InitiativesComponent } from '../initiatives/initiatives.component';

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit {
  @ViewChild(WorkExperienceComponent) workChild:WorkExperienceComponent;
  @ViewChild(VolunteerExperienceComponent) volChild:VolunteerExperienceComponent;
  @ViewChild(ExtracurricularComponent) actChild:ExtracurricularComponent;
  @ViewChild(SchoolInvolvementComponent) schInvChild:SchoolInvolvementComponent;
  @ViewChild(AwardsCredentialsComponent) awdCredsChild:AwardsCredentialsComponent;
  @ViewChild(InitiativesComponent) iniChild:InitiativesComponent;
  user: any = {};
  expId: string = null;
  expType: string = null;
  title: string = null;
  teamMember: any = [];
  teamMemberSliced: any[];
  teamLoadMoreBool: boolean = false;
  slicedItem: any = 6;
  modalRef: BsModalRef;
  newModalRef: BsModalRef;
  delModalRef: BsModalRef;
  submitted: boolean;
  searchQuery: string = "";
  pageId: any;
  teamError: boolean = false;
  teamMemberNext: any = 1;
  totalTeamMember: any = 0;
  invitationForm: FormGroup;
  invitationSuccess: boolean = false;
  editTeamMember: any;
  forExp: any;
  copyMessageBool: boolean = false;
  hasAwards: boolean = false;
  hasWork: boolean = false;
  hasVolunteer: boolean = false;
  hasIni: boolean = false;
  hasExtra: boolean = false;

  constructor(private router: Router, private profileService: ProfileService, private modalService: BsModalService, public formBuilder: FormBuilder,
    private customValidator: CustomvalidationService, private teamService: TeamService) { 
      this.invitationForm = this.formBuilder.group({
        invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
      });
     }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  getExp(data: any, memberTeamTemplate: TemplateRef<any>, teamMemberTemplate: TemplateRef<any>, forExp: any){
    this.forExp = forExp;
    if (data && data.expId && data.expType) {
      this.teamMember = [];
      this.teamMemberSliced = [];
      this.teamLoadMoreBool = false;
      this.expId = data.expId;
      this.expType = data.expType;
      this.title = data.title;
      if (data.pageId != undefined){
        this.pageId = data.pageId;
        this.getTeamForProfile();
        this.modalRef = this.modalService.show(memberTeamTemplate,
          Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
        );
      } else {
        this.teamMemberNext == 1;
        this.totalTeamMember = 0;
        this.getProfileTeamMembers('');
        this.modalRef = this.modalService.show(teamMemberTemplate,
          Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
        );
      }
      
    }
  }

  getProfileTeamMembers(params: any){
    this.profileService.getProfileMembers(this.expType, this.expId, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  loadMoreTeamMemberProfile() {
    if (this.teamMemberNext != null) {
      let params = '?page=' + this.teamMemberNext;
      this.getProfileTeamMembers(params);
    }
  }

  getTeamForProfile(){
    this.profileService.getProfileTeam(this.expType, this.expId)
    .subscribe(response => {
      if(response.status == 200){
          this.teamMember = response.response.experiences;
          this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
          if (this.teamMember.length > this.teamMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  teamLoadMore(id){
    let totalMember = this.teamMember.length;
    let slicedMember = this.teamMemberSliced.length;
    if (slicedMember < totalMember){
      this.teamMemberSliced = this.teamMember.slice(0, slicedMember+this.slicedItem)
    }
    if (totalMember > this.teamMemberSliced.length){
      this.teamLoadMoreBool = true;
    } else {
      this.teamLoadMoreBool = false;
    }
    setTimeout(() => {
      let el = document.getElementById(id);
      el.scrollIntoView({behavior: 'smooth'});
    }, 500);
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.newModalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'page': this.pageId
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  searchStudent(e: any){
    if (e.target.value && e.target.value.length > 0){
      this.searchQuery = e.target.value
      this.teamMemberSliced = this.teamMember.filter(item => item.student_info.student_name.search(new RegExp(this.searchQuery, 'i')) > -1);
      this.teamLoadMoreBool = false;
    } else if (this.searchQuery.length > 0) {
      this.teamMemberSliced = this.teamMember
    }
  }

  addToTeam(member: any){
    this.teamError = false;
    let model = {};
    if (this.expType === "2"){
      model = {'tagged_by_sch': this.expId, 'tagged_to_sch':  member.id}
    } else if (this.expType === "1") {
      model = {'tagged_by_act': this.expId}
      if (member.is_activity) {
        model['tagged_to_act'] = member.id;
      } else {
        model['tagged_to_exp'] = member.id;
      }
    } else {
      model = {'tagged_by_exp': this.expId}
      if (member.is_activity) {
        model['tagged_to_act'] = member.id;
      } else {
        model['tagged_to_exp'] = member.id;
      }
    }
    model['to_user'] = member.student_info.id;
    this.teamService.addTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.teamMemberSliced.findIndex(obj => obj.id === member.id);
        this.teamMemberSliced[index]['team_status'] = 1;
      } else {
        this.teamError = true;
      }
    }, error => {
      this.teamError = true;
    })
  }

  routeToProfile(member_id: any) {
    if (member_id){
      this.modalRef.hide();
      this.router.navigate(["/profile/" + member_id + "/"]);
    }
  }

  delTeamMemberModal(delTeamTemplate: TemplateRef<any>, member: any) {
    this.editTeamMember = member;
    this.delModalRef = this.modalService.show(delTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTeamMember(){
    if (this.editTeamMember && this.editTeamMember.id) {
      this.teamService.deleteTeamMember(this.editTeamMember.id)
      .subscribe(response => {
        let index = this.teamMember.findIndex(obj => obj.id === this.editTeamMember.id);
        this.teamMember.splice(index, 1);
        this.totalTeamMember = this.totalTeamMember - 1;
        this.updCount();
      })
    }
  }

  updCount(){
    if (this.forExp === 1) {
      this.workChild.resetCount(this.expId, this.totalTeamMember);
    } else if (this.forExp === 2) {
      this.volChild.resetCount(this.expId, this.totalTeamMember);
    } else if (this.forExp === 3) {
      this.actChild.resetCount(this.expId, this.totalTeamMember);
    } else if (this.forExp === 4) {
      this.schInvChild.resetCount(this.expId, this.totalTeamMember);
    }
  }

  copyMessage(){
    let msg = "Join my network at " + this.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.setToCopy(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  checkData(data: any, section: any){
    if (section == 1) {
      this.hasIni = data;
    } else if (section == 2) {
      this.hasWork = data;
    } else if (section == 3) {
      this.hasVolunteer = data;
    } else if (section == 4) {
      this.hasExtra = data;
    } else if (section == 5) {
      this.hasAwards = data;
    }
  }

  addSection(section: any){
    if (section == 1) {
      this.iniChild.addSection();
    } else if (section == 2) {
      this.workChild.addSection();
    } else if (section == 3) {
      this.volChild.addSection();
    } else if (section == 4) {
      this.actChild.addSection();
    } else if (section == 5) {
      this.awdCredsChild.addSection();
    }
  }

  showAdd() {
    if (this.user.age_group == 1 && this.hasIni && this.hasAwards) {
      return false;
    } else if (this.hasIni && this.hasWork && this.hasVolunteer && this.hasExtra && this.hasAwards) {
      return false;
    }
    return true;
  }

}
