<section class="profile-head newPageShow newProfile">
    <app-profile-header *ngIf="user?.user_role == 2"></app-profile-header>
    <div class="tabWrap">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12 col-md-9">
                    <ul class="tabBtns no-list">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                        }" (click)="tabActive=1" > All Notifications <span *ngIf="notifCount > 0" class="count">({{notifCount}})</span>
                        </li>
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                        }" (click)="tabActive=2">Requests <span *ngIf="inviteCount > 0" class="count">({{inviteCount}})</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-tab-dark custom-tab-teal">
        <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3">
            <div *ngIf="tabActive == 1">
                <div class="content-section fullPage p-0 showPadding notificationNewTabs">
                    <div class="profile-div">
                        <div class="profile-inner full-inner">
                            <div class="box-div" *ngFor="let notification of notifications">
                                <div class="notification-row media" [ngClass]="{'new': !notification.is_read}">
                                    <img *ngIf="notification.notify_type !== 6" src="{{notification.page_info.display_pic? notification.page_info.display_pic : 'assets/images/default.svg'}}"
                                        class="imgPic cursor-pointer" (click)="redirectToPage(notification)">
                                    <img *ngIf="notification.notify_type == 6" src="assets/images/logo.svg"
                                        class="imgPic">
                                    <div class="media-body ml-3">
                                        <h3><span>{{notification.message}}</span></h3>
                                        <h4>{{notification.page_info.title}} <span class="pipe">|</span>
                                            <span>{{notification.created_date | date: 'yyyy/MM/dd h:mm a'}}</span></h4>
                                        <p>{{notification.page_info.school_name}}<span
                                                *ngIf="!notification.page_info.school_name">{{notification.page_info.address}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class=" empty-div text-center" *ngIf="notifications?.length == 0">
                                <p>No New Notifications</p>
                            </div>
                        </div>
                    </div>
                    <div class="timeLineLoad pt-3 mb-3" *ngIf="totalNotifications > 20 && totalNotifications > notifications.length">
                        <a href="javascript:void(0);" (click)="getNotifications()">Load More</a>
                    </div>
                </div>
            </div>

            <div *ngIf="tabActive == 2">
                <div class="content-section fullPage p-0 showPadding notificationNewTabs">
                    <div class="profile-div">
                        <div class="profile-inner full-inner">
                            <div class="box-div" *ngFor="let invite of invites">
                                <div class="notification-row new media align-items-center">
                                    <img  alt="image" src="{{invite.page_info.display_pic? invite.page_info.display_pic : 'assets/images/default.svg'}}"
                                        class="imgPic">
                                    <div class="media-body ml-3">
                                        <h3>{{invite.page_info.owner}} has invited you to be <span
                                                *ngIf="invite.invite_type == 1">the Owner</span><span
                                                *ngIf="invite.invite_type == 2">an Administrator</span></h3>
                                        <h4>{{invite.page_info.title}} <span class="pipe">|</span>
                                            <span>{{invite.created_date | date}}</span></h4>
                                        <p>{{invite.page_info.school_name}}<span
                                                *ngIf="!invite.page_info.school_name">{{invite.page_info.address}}</span>
                                        </p>
                                    </div>
                                </div>
                                <button class="common-btn mr-2 mt-3"
                                    (click)="inviteAction(inviteModal, invite, true)">Accept</button>
                                <button class="common-btn mt-3 ghost"
                                    (click)="inviteAction(inviteModal, invite)">Decline</button>
                            </div>
                            <div class="empty-div text-center" *ngIf="invites?.length == 0">
                                <p>No New Requests</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #inviteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">CONGRATULATIONS!</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="inner-box">
                <p *ngIf="showSubmitMsg">{{submitMsg}}</p>
                <p *ngIf="!showSubmitMsg">You are now an <span *ngIf="inviteObj.invite_type == 1">Owner</span><span
                        *ngIf="inviteObj.invite_type == 2">Administrator</span> of this Page. You may verify experiences,
                         edit the Page details, and access the Page settings. This page has been added 
                         and is accessible from your Home page. </p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            OK
        </button>
    </div>
</ng-template>
