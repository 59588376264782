<div class="initiativeTeamBlock">
    <div class="initiativeTeamSlider pt-0 pb-0">
        <div class="d-flex align-items-center mb-4 withRadBlueName">
            <h3 class="blueHdg">pending</h3>
        </div>
    </div>

    <div class="initiativeTeamSlider mb-4 mb-md-5">
        <div class="teamListScroll scroll-activity scrollbar">
            <p *ngIf="showSubmitMsg" class="alert" [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">{{submitMsg}}</p>
            <ul class="teamListWrap no-list">
                <li *ngFor="let experience of pendingExperiences">
                    <div class="teamMemberEach">
                        <div class="topMember">
                            <img alt="icon" class="bannerImg" src="./assets/images/new-header-img.png" />
                        </div>
                        <div class="profileMember">
                            <img alt="icon" class="profileImg" src="{{experience.display_pic ? experience.display_pic : 'assets/images/p-img.png'}}" />
                        </div>
                        <div class="btmMember">
                            <h1 class="name">{{ experience.student_name }}</h1>
                            <h3 class="subName">{{experience.position}}</h3>
                            <p class="dateHistory mb-0">{{experience.start_date | date: 'MMM y'}}
                                <span *ngIf="experience.start_date != experience.end_date"> - <span *ngIf="experience.end_date">{{experience.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!experience.end_date">Present</span>
                                </span>
                            </p>
                            <div class="memberActions">
                                <button class="btn common-btn btnMessage" (click)="expModal(declineTemplate, experience)">decline</button>
                                <button class="btn common-btn btnProfile" (click)="approveReq(experience, true)">approve</button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="timeLineLoad pt-3" *ngIf="pendingLoadMoreBool">
            <a href="javascript:void(0);" (click)="pendingLoadMore()">Load More</a>
        </div>
    </div>

    <div class="initiativeTeamSlider pt-0 pb-0">
        <div class="d-flex align-items-center mb-4 withRadBlueName">
            <h3 class="blueHdg">Approved</h3>
        </div>
    </div>

    <div class="initiativeTeamSlider mb-4 mb-md-5">
        <div class="teamListScroll scroll-activity scrollbar">
            <ul class="teamListWrap no-list">
                <li *ngFor="let experience of approvedExperiences">
                    <div class="teamMemberEach">
                        <div class="topMember">
                            <img alt="icon" class="bannerImg" src="./assets/images/new-header-img.png" />
                        </div>
                        <div class="profileMember">
                            <img alt="icon" class="profileImg" src="{{experience.display_pic ? experience.display_pic : 'assets/images/p-img.png'}}" />
                        </div>
                        <div class="btmMember">
                            <h1 class="name">{{ experience.student_name }}</h1>
                            <h3 class="subName">{{experience.position}}</h3>
                            <p class="dateHistory mb-0">{{experience.start_date | date: 'MMM y'}}
                                <span *ngIf="experience.start_date != experience.end_date"> - <span *ngIf="experience.end_date">{{experience.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!experience.end_date">Present</span>
                                </span>
                            </p>
                            <p class="verifedBy">
                                <img src="assets/images/verifyProfile.svg" />
                                <span>Verified by {{experience.approver_name}} on {{experience.verified_date | date: 'MMMM d, y'}}</span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="timeLineLoad pt-3" *ngIf="approvedLoadMoreBool">
            <a href="javascript:void(0);" (click)="approvedLoadMore()">Load More</a>
        </div>
    </div>
</div>

<ng-template #declineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Decline</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="declineForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reason for Rejection</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="rejection_reason"> </textarea>
                        <div class="error-message"
                            *ngIf="declineForm.get('rejection_reason').hasError('maxlength') && (declineForm.get('rejection_reason').dirty || declineForm.get('rejection_reason').touched)">
                            Max 250 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="declineReq(declineForm.valid)">
            Confirm
        </button>
    </div>
</ng-template>