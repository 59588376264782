import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../shared/user';
import { SchoolUser } from '../../shared/schoolUser';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'app-claim-school-page',
  templateUrl: './claim-school-page.component.html',
  styleUrls: ['./claim-school-page.component.scss']
})
export class ClaimSchoolPageComponent implements OnInit {
  @Input() page: any = {};
  @Input() verifier: any = {};
  @Output() successOverlay: EventEmitter<boolean> = new EventEmitter();
  currentForm: boolean = true;
  secondStep: boolean = false;
  secondStepForm: FormGroup;
  searchForm: FormGroup;
  public submitted: boolean = false;
  public searchSubmitted: boolean = false;
  public infoDiv  = true;
  userId: string = null;
  public invalidDate: boolean = false;
  maxNum: number = new Date().getFullYear();
  public dob: string;
  public age: number;
  public invalidDateMsg: string;
  submitErrorMsg: string = null;
  public submitError: boolean = false;
  currentUser: any = {};

  user: User = {
    first_name: '',
    last_name: '',
    email: '',
    user_role : 3,
    password : '',
    is_terms_accepted : false,
    parent_email : undefined,
    date_of_birth: undefined,
    student_referral: undefined
  };

  schoolUser: SchoolUser = {
    title: '',
    school: '',
    user: this.user,
    account_type: 1,
    professional_email: undefined
  }

  error_messages = {
    'year_of_birth': [
      { type: 'required', message: 'This field is required.' },
      { type: 'min', message: 'Please enter valid Year.' },
      { type: 'max', message: 'Max ' + this.maxNum + ' is allowed.' }
    ]
  }

  constructor(private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService) {
    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      account_type: new FormControl('', Validators.required),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      professional_email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
    });
    this.secondStepForm = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl({value: null, disabled: true}, [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required),
      year_of_birth: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)])
    }, {
      validators: [this.checkPassword.bind(this)]
    });
  } 

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.currentUser = currentUser;
      this.userId = this.currentUser.id;
    }
    let school = this.page.club_team.school;
    this.searchForm.controls['title'].setValue(this.page.club_team.title);
    this.searchForm.controls['state'].setValue(school.state);
    this.searchForm.controls['country'].setValue(school.country);
    this.searchForm.controls['school_name'].setValue(school.school_name);
    this.secondStepForm.controls['email'].setValue(this.verifier.email);
    this.secondStepForm.controls['firstname'].setValue(this.verifier.first_name);
    this.secondStepForm.controls['lastname'].setValue(this.verifier.last_name);
  }

  checkDate() {
    if (this.secondStepForm.get('year_of_birth').value !== "" && this.secondStepForm.get('year_of_birth').value !== null) {
      this.dob = (this.secondStepForm.get('year_of_birth').value).toString() + '-1-1';
      let timeDiff = Math.abs(Date.now() - new Date(this.dob.replace(/-/g, "/")).getTime());
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
      if(new Date(this.dob.replace(/-/g, "/")) > new Date()){
        this.invalidDate = true;
        this.invalidDateMsg = "The year of birth is invalid.";
      } else if(this.age < 20) {
        this.invalidDate = true;
        this.invalidDateMsg = "Sorry, the minimum age to set up a Business is 20 years old.";
      } else {
        this.invalidDate = false;
        this.invalidDateMsg = null;
      }
    }
  }

  validateEmail(){
    let email = this.searchForm.get('professional_email').value;
    if (email && email.length > 0 && this.searchForm.controls['professional_email'].valid) {
      this.authService.validateEmail({"professional_email": email})
      .subscribe(response => {
        if (response.status === 111) {
          this.searchForm.controls['professional_email'].setErrors({'invalid_format': true});
        }
      })
    }
  }

  showNext(isValid: boolean){
    if (isValid) {
        if (this.userId) {
          this.user.is_terms_accepted = true;
          this.save(isValid);
        } else {
          this.secondStep = true;
          this.currentForm = false;
        }
    } else {
      this.searchSubmitted = true;
    }
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.user.is_terms_accepted = true;
    } else {
      this.user.is_terms_accepted = false;
    }
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (isValid && !this.invalidDate && this.user.is_terms_accepted) {
      if (this.userId == null) {
        this.user.first_name = this.secondStepForm.get('firstname').value;
        this.user.last_name = this.secondStepForm.get('lastname').value;
        this.user.email = this.secondStepForm.get('email').value;
        this.user.password = this.secondStepForm.get('password').value;
        this.user.date_of_birth = this.dob;
        this.user.is_terms_accepted = true;
      }
      this.schoolUser.title = this.searchForm.get('title').value;
      this.schoolUser.account_type = this.searchForm.get('account_type').value;
      this.schoolUser.professional_email = this.searchForm.get('professional_email').value;
      this.authService.claimPage(this.schoolUser, this.page.id, 2)
      .subscribe(response => {
        this.handleResponse(response);
      }, error => {
        this.handleSubmitError(error.error);
      });
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.successOverlay.emit(true);
        this.secondStep = false;
        this.currentForm = true;
        this.secondStepForm.reset();
        this.searchForm.reset();
        this.submitted = false;
        this.searchSubmitted = false;
    } else {
        this.secondStep = false;
        this.currentForm = true;
        this.secondStepForm.reset();
        this.searchForm.reset();
        this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.submitted = false;
        this.searchSubmitted = false;
        if (response.status === 112) {
          this.submitErrorMsg = response.message;
        } else {
          this.submitErrorMsg = "Something went wrong. Please try again later";
        }
        this.submitError = true;
        this.hideMessage();
    }
  }

  hideMessage() {
    setTimeout(() => {
      this.submitError = false;
    }, 6000);
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.searchSubmitted = false;
    const fields = Object.keys(data || {});
    const fkFields = Object.keys(data['user'] || {});
    fields.forEach(field => {
      if (this.secondStepForm.contains(field)) {
        this.secondStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
    fkFields.forEach(fkField => {
      if (this.secondStepForm.contains(fkField)) {
        this.secondStepForm.get(fkField).setErrors({ aftersubmit: data['user'][fkField][0] });
      }
    });
    this.secondStep = true;
    this.currentForm = false;
  }

}
