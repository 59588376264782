<section class="experiencesPage">
    <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3 pt-0">
            <div class="btn-group" dropdown *ngIf="showAdd()">
                    <button id="button-basic" dropdownToggle type="button" class="add-section-btn" aria-controls="dropdown-basic">
                        <img src="/assets/images/add-section-btn.svg" alt="btn" />
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu add-section-dropdown" role="menu"
                        aria-labelledby="button-basic">
                        <li role="menuitem" *ngIf="mamList.length == 0">
                            <button type="button" class="add-btn" (click)="mamListing(mamModal)">
                                More About Me
                            </button>
                        </li>
                        <li role="menuitem" *ngIf="interests.length == 0">
                            <button type="button" class="add-btn" (click)="skillInterestModal(interestpopup)">
                                Interests
                            </button>
                        </li>
                        <li role="menuitem" *ngIf="bulletins.length == 0">
                            <button type="button" class="add-btn" (click)="bulletinModal(buletinModal)">
                                Bulletin Board
                            </button>
                        </li>
                        <li role="menuitem" *ngIf="books.length == 0">
                            <button type="button" class="add-btn" (click)="bookModal(addBooks)">
                                Books
                            </button>
                        </li>
                        <li role="menuitem" *ngIf="favouriteList.length == 0">
                            <button type="button" class="add-btn" (click)="favouritesListing(favouritesModal)">
                                Favourites
                            </button>
                        </li>
                        <li role="menuitem" *ngIf="learnMoreList.length == 0">
                            <button type="button" class="add-btn" (click)="learnAboutListing(learnAboutModal)">
                                I Want To Learn About
                            </button>
                        </li>
                    </ul>
                </div>

        <div class="topLinks">
            <div class="content-section">
                <div class="content-profile-white-box">
                        <div class="titleDiv d-flex align-items-center">
                                <h3>
                                    Bio
                                </h3>
                                <a href="javascript:void();" *ngIf="(profile | json) == '{}'"
                                    (click)="openModal(descriptionTemplate)"> <img class="d-block"
                                        src="assets/images/plus-teal.svg" alt="icon"></a>
                            </div>
                            <div class="detailContent bioDesc" [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                                    <p (click)="openModal(editDescriptionTemplate)"
                                        [innerHtml]="profile.description | slice:0:1000"></p>
                                    <a *ngIf="(profile.description + '').length > 1000" href="javascript:void();"
                                        (click)="infoDiv1 = !infoDiv1" class="d-block text-center mt-2">
                                        <img src="assets/images/new-arrow.svg" alt="icon">
                                    </a>
                                </div>
                </div>

<div class="content-profile-white-box" *ngIf="mamList.length > 0">
        <div class="titleDiv d-flex align-items-center">
                <h3>
                    more about me
                </h3>
                <a href="javascript:void();" (click)="mamListing(mamModal)">
                    <img class="d-block" src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>

            <div class="detailContent">
                <ngx-slick-carousel class="sliderContent carousel mt-4" #slickModal="slick-carousel"
                    [config]="mamSlider">
                    <div class="mam-slider slide" ngxSlickItem *ngFor="let mam of mamList">
                        <div class="mam-wrap cursor-pointer">
                            <div class="mx-auto" (click)="jumpToMaMDetails(mam)">
                                <img src="{{mam.thumbnail ? mam.thumbnail :'assets/images/default.svg'}}"
                                    class="icon">
                            </div>
                            <div class="dtl">
                                <div class="custom-tooltip copy-tooltip">
                                        <h3 class="text-capitalize" (click)="jumpToMaMDetails(mam)">{{ mam.title }}
                                        </h3>
                                        <div class="tooltip-data">{{ mam.title }}</div>
                                </div>
                                <a href="javascript:void();" (click)="deleteMam(mam.id)" class="delMam">
                                    <img alt="icon" src="assets/images/newDelete.svg">
                                </a>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
</div>

<div class="content-profile-white-box">
        <div class="titleDiv d-flex align-items-center" *ngIf="user.user_role == '2'">
                <h3 class="with-list-icon">
                    Skills
                </h3>
                <a href="javascript:void();" (click)="skillInterestModal(skillpopup)"> <img class="d-block"
                        src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>
            <div class="detailContent" *ngIf="user.user_role == '2'">
                <ul class="no-list awardsList withSkills" *ngIf="skills?.length > 0">
                    <li *ngFor="let skill of skills">
                        <div class="awardsDataLeft" (click)="updateSkillModal(editSkillpopup, skill)">
                            <p class="name m-0 mr-2 cursor-pointer">{{ skill.title }}</p>
                            <div class="forDotsImages">
                                <span *ngIf="skill?.user_link && skill?.user_link.length > 0">
                                    <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                    </span>
                                    <span *ngIf="skill?.user_document && skill?.user_document.length > 0">
                                        <img alt="image" src="assets/images/awardsFileIcon.svg">
                                    </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
</div>

<div class="content-profile-white-box">
        <div class="titleDiv d-flex align-items-center">
                <h3>
                    Goals & bucket list
                </h3>
                <a href="javascript:void();" (click)="goalsListing(goalsModal)">
                    <img class="d-block" src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>

            <div class="detailContent mb-2">
                <div [ngClass]="{ '': goalsBucket, showDetailContent: !goalsBucket}">
                    <div class="truncatedText">
                        <ul class="dots-list m-0 truncatedText">
                            <li *ngFor="let goal of goalsBucketList | slice:0:6"
                                (click)="updateGoals(goal, goalsModal)">{{ goal.title }}</li>
                        </ul>
                    </div>
                    <div class="fullText">
                        <ul class="dots-list m-0">
                            <li *ngFor="let goal of goalsBucketList"
                                (click)="updateGoals(goal, goalsModal)">{{ goal.title }}</li>
                        </ul>
                    </div>
                    <a href="javascript:void();" *ngIf="goalsBucketList && goalsBucketList.length > 6"
                        (click)="goalsBucket = !goalsBucket" class="d-block text-center showMore">
                        <img src="assets/images/new-arrow.svg" alt="icon">
                    </a>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="interests.length > 0">
        <div class="titleDiv d-flex align-items-center" *ngIf="user.user_role == '2'">
                <h3 class="with-list-icon">
                    Interests
                </h3>
                <a href="javascript:void();" (click)="skillInterestModal(interestpopup)"> <img class="d-block"
                        src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>
            <div class="detailContent" *ngIf="user.user_role == '2'">
                <ul class="no-list awardsList withSkills" *ngIf="interests?.length > 0">
                    <li *ngFor="let interest of interests">
                        <div class="awardsDataLeft" (click)="updateInterestModal(editInterestpopup, interest)">
                            <p class="name m-0 mr-2 cursor-pointer">{{ interest.title }}</p>
                            <div class="forDotsImages">
                                <span *ngIf="interest.user_link && interest?.user_link.length > 0">
                                    <img alt="image" src="assets/images/awardsLinkIcon.svg">
                                    </span>
                                    <span *ngIf="interest?.user_document && interest?.user_document.length > 0">
                                        <img alt="image" src="assets/images/awardsFileIcon.svg">
                                    </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="bulletins.length > 0">
        <div class="titleDiv d-flex align-items-center">
                <h3>
                    Bulletin Board
                </h3>
                <a href="javascript:void();" (click)="bulletinModal(buletinModal)"> <img class="d-block"
                        src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>
            <div class="detailContent pl-3 pr-3">
                <div class="w-100" *ngIf="staticBulletins.length > 0 || bulletins.length > 0">
                    <ngx-slick-carousel class="box-div library-div carousel bulletinBoardSlides"
                        #slickModal="slick-carousel" [config]="slideConfig">
                        <div *ngFor="let staticBulletin of staticBulletins" class="slide img-cont"
                            ngxSlickItem (click)="updatebulletinModal(staticModal, staticBulletin)">
                            <div class="img-div">
                                <img src="assets/images/pdf-icon.svg">
                                <p>{{ staticBulletin.title }}</p>
                            </div>
                        </div>
                        <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                            (click)="updatebulletinModal(editbulletinModal, bulletin)">
                            <div *ngIf="bulletin.document" class="img-div">
                                <img *ngIf="bulletin.thumbnail" src="{{ bulletin.thumbnail }}">
                                <img *ngIf="!bulletin.thumbnail && isImage(bulletin.document_name)"
                                    src="{{bulletin.document}}">
                                <img *ngIf="!isImage(bulletin.document_name) && !bulletin.thumbnail"
                                    src="assets/images/pdf-icon.svg">
                                <p>{{bulletin.title}}</p>
                            </div>
                            <div *ngIf="!bulletin.document && bulletin.thumbnail" class="img-div">
                                <img src="{{ bulletin.thumbnail }}">
                                <p>{{bulletin.title}}</p>
                            </div>
                            <div *ngIf="!bulletin.document && !bulletin.thumbnail" class="img-div">
                                <img src="assets/images/default.svg">
                                <p>{{bulletin.title}}</p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div *ngIf="staticBulletins?.length == 0 && bulletins?.length == 0"
                    class="library-div empty-div box-div text-center h-100 d-flex align-items-center justify-content-center m-0">
                    <p>Add documents and links that show off your accomplishments</p>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="books.length > 0">
        <div class="titleDiv d-flex align-items-center" *ngIf="user.user_role == '2'">
                <h3 class="with-list-icon">
                    Books
                </h3>
                <a href="javascript:void();" (click)="bookModal(addBooks)"> <img class="d-block"
                        src="assets/images/plus-teal.svg" alt="icon">
                </a>
            </div>
            <div class="detailContent" *ngIf="user.user_role == '2'">
                <div class="topRecent" *ngIf="books?.length > 0">
                    <div class="media" (click)="updatebookModal(updateBooks, books[0])">
                        <img src="{{books[0]?.thumbnail ? books[0].thumbnail : 'assets/images/default.svg'}}"
                            alt="Books Cover" class="booksCover mainTopImg bookTopImage">
                        <div class="media-body ml-3">
                            <h4>{{ books[0]?.title }}</h4>
                            <p class="mb-2">{{ books[0]?.author }}</p>
                            <div>
                                <ul *ngIf="books[0].rating"
                                    class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                    <ngb-rating [(rate)]="books[0].rating" [readonly]="true" [max]="5">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100"
                                                [class.bad]="index < 3"></span>
                                        </ng-template>
                                    </ngb-rating>
                                </ul>
                            </div>
                            <p class="text-ellipse">
                                {{ books[0]?.description }}
                            </p>
                        </div>
                    </div>
                </div>
                <ngx-slick-carousel *ngIf="books?.length > 1" class="sliderContent carousel mt-4"
                    #slickModal="slick-carousel" [config]="booksSlider">
                    <div *ngFor="let book of books | slice:1" class=" slide" ngxSlickItem>
                        <div class="media" (click)="updatebookModal(updateBooks, book)">
                            <img src="{{book.thumbnail ? book.thumbnail : 'assets/images/default.svg'}}"
                                alt="Skill Cover" class="booksThumb">
                            <div class="media-body ml-3">
                                <h4>
                                    {{ book.title }}
                                </h4>
                                <p class="mb-2">{{ book.author }}</p>
                                <div>
                                    <ul *ngIf="book.rating"
                                        class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                        <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                            <ng-template let-fill="fill" let-index="index">
                                                <span class="star" [class.filled]="fill === 100"
                                                    [class.bad]="index < 3"></span>
                                            </ng-template>
                                        </ngb-rating>
                                    </ul>
                                </div>
                                <p class="text-ellipse">{{ book.description }}</p>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class="viewMoreWrapper" *ngIf="books?.length > 4">
                    <span class="viewMore" (click)="booksListing(booksListModal)">
                        View All ({{books?.length}})
                    </span>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="favouriteList.length > 0">
        <div class="titleDiv d-flex align-items-center">
                <h3>
                    favourites
                </h3>
                <a href="javascript:void();" (click)="favouritesListing(favouritesModal)">
                    <img class="d-block" src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>

            <div class="detailContent">
                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                    [config]="favouritesSlider">
                    <div class="fav-slider slide" ngxSlickItem *ngFor="let fav of favouriteList"
                        (click)="updateFav(fav, editfavouritesModal)">
                        <div class="fav-wrap">
                            <div class="img-outer">
                                <img src="{{fav.thumbnail ? fav.thumbnail : 'assets/images/default.svg'}}"
                                    class="icon mainTopImg mx-auto">
                            </div>
                            <div class="dtl">
                                <div class="custom-tooltip copy-tooltip">
                                        <h3>{{ fav.title }}</h3>
                                        <div class="tooltip-data">{{ fav.title }}</div>
                                </div>
                                <p>{{ fav.topic }}</p>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="learnMoreList.length > 0">
        <div class="titleDiv d-flex align-items-center">
                <h3>
                    i want to learn about
                </h3>
                <a href="javascript:void();" (click)="learnAboutListing(learnAboutModal)">
                    <img class="d-block" src="assets/images/plus-teal.svg" alt="icon"></a>
            </div>

            <div class="detailContent mb-2">
                <div [ngClass]="{ '': learnAbout, showDetailContent: !learnAbout}">
                    <div class="truncatedText">
                        <ul class="dots-list m-0">
                            <li *ngFor="let learning of learnMoreList | slice:0:6"
                                (click)="updateLearnAbout(learning ,learnAboutModal)">
                                {{ learning.title }}
                            </li>
                        </ul>
                    </div>
                    <div class="fullText">
                        <ul class="dots-list m-0">
                            <li *ngFor="let learning of learnMoreList"
                                (click)="updateLearnAbout(learning ,learnAboutModal)">
                                {{ learning.title }}
                            </li>
                        </ul>
                    </div>

                    <a href="javascript:void();" *ngIf="learnMoreList && learnMoreList.length > 6"
                        (click)="learnAbout = !learnAbout" class="d-block text-center showMore">
                        <img src="assets/images/new-arrow.svg" alt="icon">
                    </a>
                </div>
            </div>
</div>

<div class="content-profile-white-box">
        <div class="titleDiv d-flex align-items-center">
                <h3>Timeline</h3>
            </div>
            <div class="detailContent mb-0">
                <div class="d-flex justify-content-between align-items-center slideDiv px-2">
                    <p class="mb-0 d-flex align-items-center">
                        <button type="button" class="btnSlide prevBtn bg-transparent border-0" id="prevbut"
                            (click)="prevYear()" *ngIf="!prevYearCheck">
                            <svg style="display:block;" xmlns="http://www.w3.org/2000/svg" width="12.181"
                                height="18.881" viewBox="0 0 12.181 18.881">
                                <g transform="rotate(90.000032 6.09056932 9.44040707)">
                                    <path fill="#31BFB8" fill-rule="evenodd"
                                        d="M-2.64273203 5.6360747l1.57912821-1.5791291c.39026275-.39026297 1.0239506-.39026315 1.41421356-4e-7l7.77358505 7.77358068c.39026297.39026274.39026314 1.0239506 4e-7 1.41421356l-1.57912822 1.5791291c-.39026274.39026296-1.0239506.39026314-1.41421356 4e-7l-7.77358504-7.77358068c-.39026297-.39026275-.39026315-1.0239506-4e-7-1.41421357z" />
                                    <path fill="#31BFB8" fill-rule="evenodd"
                                        d="M13.24474246 4.0569456l1.57912822 1.5791291c.39026274.39026296.39026256 1.02395081-4e-7 1.41421356l-7.77358505 7.77358068c-.39026296.39026274-1.02395081.39026256-1.41421356-4e-7l-1.57912821-1.5791291c-.39026275-.39026297-.39026257-1.02395082 4e-7-1.41421356L11.8305289 4.0569452c.39026297-.39026275 1.02395082-.39026257 1.41421356 4e-7z" />
                                </g>
                            </svg>
                        </button><span *ngIf="!prevYearCheck" class="next-timeline-year">{{current_year-1}}</span>
                    </p>
                    <h4 class="current-timeline-year">
                        {{current_year}}
                    </h4>
                    <p class="mb-0 d-flex align-items-center"><span *ngIf="nextYearCheck">Now</span>
                        <span *ngIf="!nextYearCheck" class="next-timeline-year">{{current_year + 1}}</span>
                        <button type="button" class="btnSlide nextBtn bg-transparent border-0" id="nextbut"
                            (click)="nextYear()" *ngIf="!nextYearCheck">
                            <svg style="display:block;" xmlns="http://www.w3.org/2000/svg" width="12.181"
                                height="18.881" viewBox="0 0 12.181 18.881">
                                <g transform="rotate(-90.000032 6.09056932 9.44040707)">
                                    <path fill="#31BFB8" fill-rule="evenodd"
                                        d="M-2.64273203 5.6360747l1.57912821-1.5791291c.39026275-.39026297 1.0239506-.39026315 1.41421356-4e-7l7.77358505 7.77358068c.39026297.39026274.39026314 1.0239506 4e-7 1.41421356l-1.57912822 1.5791291c-.39026274.39026296-1.0239506.39026314-1.41421356 4e-7l-7.77358504-7.77358068c-.39026297-.39026275-.39026315-1.0239506-4e-7-1.41421357z" />
                                    <path fill="#31BFB8" fill-rule="evenodd"
                                        d="M13.24474246 4.0569456l1.57912822 1.5791291c.39026274.39026296.39026256 1.02395081-4e-7 1.41421356l-7.77358505 7.77358068c-.39026296.39026274-1.02395081.39026256-1.41421356-4e-7l-1.57912821-1.5791291c-.39026275-.39026297-.39026257-1.02395082 4e-7-1.41421356L11.8305289 4.0569452c.39026297-.39026275 1.02395082-.39026257 1.41421356 4e-7z" />
                                </g>
                            </svg>
                        </button>
                    </p>
                </div>
                <div class="box-div m-0">
                    <div class="timeLineDiv d-flex align-items-center" id="testinggg">

                        <span *ngFor="let item of month_instances | keyvalue:descOrder"
                            class="d-flex align-items-center justify-content-center dotSpan"
                            [ngClass]="getClass(item.value, item.key)"
                            (click)="getTimelineDetails(item.key) ">{{item.value.length > 1 ?
                            item.value.length : ''}}</span>
                    </div>
                    <div class="eventListing" [ngClass]="{ '': eventListing, show: !eventListing}">
                        <h4 class="mb-2">{{timelineMonth}}</h4>
                        <ul class=" pl-0 mb-0 d-flex flex-wrap no-list timelineListing">
                            <li *ngFor="let details of timelineDetails">
                                <div class="eventDiv">
                                    <h4>
                                        {{details.position}}
                                    </h4>
                                    <p>
                                        <span *ngIf="!details.one_day_event">{{details.start_date ?
                                            'Starting' : 'Ending'}}
                                            work at </span>
                                        <span *ngIf="details.one_day_event">Worked at </span>
                                        <span>{{details.organization_title}}</span> on<span class="d-block">
                                            {{details.start_date ? details.start_date :
                                            details.end_date}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
</div>
                <div class="profile-div">
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form [formGroup]="descriptionForm" novalidate>
                <ckeditor tagName="textarea" [editor]="editor" formControlName="description"
                    [config]="{ link: {addTargetToExternalLinks: true}, toolbar: [ 'link', 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}" class="bio-text-para">
                </ckeditor>
                <div class="error-message"
                    *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                    Max 4000 characters allowed.
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form [formGroup]="descriptionForm" novalidate>
                <ckeditor tagName="textarea" [editor]="editor" formControlName="description"
                    [config]="{ link: {addTargetToExternalLinks: true}, toolbar: [ 'link', 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                    *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                    Max 4000 characters allowed.
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updateDescription(descriptionForm.valid)">
            Update
        </button>
        <button class="btn btn-footer" (click)="deleteBio(profile.id)">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #skillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="addSkillForm" novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="" class="textTransformInitial">Add up to 10 at once. Separate with commas (ex. Skill A, Skill B)</label>
                                <textarea type="text" class="form-control height100" formControlName="skills"></textarea>
                            </div>
                            <div class="error-message"
                                *ngIf="addSkillForm.get('skills').hasError('required') && (addSkillForm.get('skills').dirty || addSkillForm.get('skills').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                            *ngIf="addSkillForm.get('skills').hasError('whitespace') && (!addSkillForm.get('skills').hasError('required')) && (addSkillForm.get('skills').dirty || addSkillForm.get('skills').touched || submitted)">
                                Please enter valid data.
                            </div>
                            <div class="error-message" *ngIf="addSkillForm.errors?.maxCount && (addSkillForm.touched || addSkillForm.dirty)">
                                Max 10 skills allowed.
                            </div>
                            <div class="error-message" *ngIf="addSkillForm.get('skills').hasError('aftersubmit')">
                                {{addSkillForm.controls.skills.errors.aftersubmit}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="addSkill(addSkillForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editSkillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Skill</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="skillForm" novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <input type="text" class="form-control" formControlName="title">
                            </div>
                            <div class="error-message"
                                *ngIf="skillForm.get('title').hasError('required') && (skillForm.get('title').dirty || skillForm.get('title').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="skillForm.get('title').hasError('maxlength') && (skillForm.get('title').dirty || skillForm.get('title').touched)">
                                Max 200 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this skill? What introduced you to it?</label>
                        <textarea type="text" class="form-control" formControlName="past_description"></textarea>
                        <div class="error-message"
                            *ngIf="skillForm.get('past_description').hasError('maxlength') && (skillForm.get('past_description').dirty || skillForm.get('past_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this skill?</label>
                        <textarea type="text" class="form-control" formControlName="present_description"></textarea>
                        <div class="error-message"
                            *ngIf="skillForm.get('present_description').hasError('maxlength') && (skillForm.get('present_description').dirty || skillForm.get('present_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this skill? How do you intend to use and build this in
                            future? </label>
                        <textarea type="text" class="form-control" formControlName="future_description"></textarea>
                        <div class="error-message"
                            *ngIf="skillForm.get('future_description').hasError('maxlength') && (skillForm.get('future_description').dirty || skillForm.get('future_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>

            <div class="divSecond">
                <span class="dividerBr"></span>
                <form [formGroup]="linkForm">
                    <div class="form-group">
                        <h3>Links</h3>
                    </div>
                    <div class="media align-items-center d-md-flex d-block" *ngIf="skillLinkArray.length < 5">
                        <div class="imgSec">
                            <img src="{{previewThumbnailLink? previewThumbnailLink :'assets/images/default.svg'}}"
                                class="mainCover d-block mx-auto img60">
                            <p>
                                {{ previewThumbnailLinkName }}
                            </p>
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Link</label>
                                    <input type="text" class="form-control" formControlName="link">

                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('pattern') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Please enter a valid URL.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="!(linkForm.get('link').hasError('pattern')) && linkForm.get('link').hasError('required') && (linkForm.get('link').dirty || linkForm.get('link').touched || linkFrmSubmitted)">
                                        Please enter a valid URL before submit.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('maxlength') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Max 250 characters allowed.
                                    </div>
                                    <div class="error-message" *ngIf="linkForm.get('link').hasError('aftersubmit')">
                                        Please enter a valid URL.
                                    </div>

                                </div>
                                <div class="form-group col-md-6">
                                    <label>Title</label>
                                    <input type="text" class="form-control" formControlName="title">
                                    <div class="error-message"
                                        *ngIf="linkForm.get('title').hasError('maxlength') && (linkForm.get('title').dirty || linkForm.get('title').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="mb-2">Upload Cover Image</label>
                                    <input type="file" class="fileUpload" placeholder="Type Here"
                                        (change)="linkImageUpload($event)">
                                    <span class="fileText cursor-pointer">Choose File</span>
                                    <div *ngIf="coverImageUploadError" class="error-message">
                                        {{coverImageUploadMessage}}
                                    </div>
                                    <div *ngIf="coverImageUploadSizeError" class="error-message">
                                        {{coverImageUploadSizeMessage}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button class="common-btn" (click)="addMoreSkillLink(linkForm.valid)">Save & Add
                                        More</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngFor="let skillLink of skillLinkArray">
                        <div class="col-4 mb-2">
                            <input type="text" class="form-control custom-readonly" readonly
                                value="{{ skillLink.link }}">
                        </div>

                        <div class="col-4 mb-2">
                            <input type="text" class="form-control custom-readonly" readonly
                                value="{{ skillLink.title }}">
                        </div>

                        <div class="col-4 mb-2">
                            <div class="del-icon-outer">
                                <div class="imgSec mr-3">
                                    <img src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}"
                                        class="mainCover2 d-block img40">
                                </div>
                                <div class="del-icon">
                                    <img class="cursor-pointer" src="assets/images/delete_icon.svg"
                                        (click)="delLink(skillLink.id)">
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <span class="dividerBr"></span>
                <form [formGroup]="documentForm" novalidate>
                    <div class="form-group">
                        <h3>Images/Documents</h3>
                    </div>
                    <div class="media align-items-center d-md-flex d-block" *ngIf="skillDocArray.length < 5">

                        <div class="media-body ml-md-3">
                            <div class="row">

                                <div class="form-group col-md-4">
                                    <label>Title</label>
                                    <input type="text" class="form-control" formControlName="title">
                                    <div class="error-message"
                                        *ngIf="documentForm.get('title').hasError('maxlength') && (documentForm.get('title').dirty || documentForm.get('title').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="mb-2">Upload Image/Document</label>
                                    <input type="file" class="fileUpload" placeholder="Type Here"
                                        (change)="onFileSelect($event)" #skillDocInp>
                                    <span class="fileText cursor-pointer">Choose File</span>
                                    <p class="mt-2 mb-1" *ngIf="uploadForm.controls.document_name.value">
                                        {{uploadForm.controls.document_name.value}}
                                    </p>
                                    <div *ngIf="fileUploadError" class="error-message">
                                        {{fileUploadMessage}}
                                    </div>
                                    <div *ngIf="fileUploadSizeError" class="error-message">
                                        {{fileUploadSizeMessage}}
                                    </div>
                                    <div *ngIf="addMoreFileError" class="error-message">
                                        {{addMoreFileErrorMessage}}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> &nbsp; </label>
                                    <button class="common-btn"
                                        (click)="addMoreSkillDoc(addMoreFileBoolean, skillDocInp)">Save & Add
                                        More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="skillDocArray.length > 0">
                        <div class="row mobile-2">
                            <div class="image-with-del" *ngFor="let skillDoc of skillDocArray">
                                <div class="imgSec">
                                    <img *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}"
                                        class="mainCover d-block mx-auto img60">
                                    <img *ngIf="!isImage(skillDoc.document_name)" src="assets/images/pdf-icon.svg"
                                        class="mainCover d-block mx-auto img60">
                                    <p class="m-0 mt-2">
                                        {{ skillDoc.title }}
                                    </p>
                                    <div class="del-icon">
                                        <img src="./assets/images/delete_icon.svg" (click)="delDoc(skillDoc.id)" class="cursor-pointer"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="delSkill()">
            Delete
        </button>
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="updateSkill(skillForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #interestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Interests</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="addSkillForm" novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="" class="textTransformInitial">Add up to 10 at once. Separate with commas (ex. Interest A, Interest B)</label>
                                <textarea type="text" class="form-control height100" formControlName="skills"></textarea>
                            </div>
                            <div class="error-message"
                                *ngIf="addSkillForm.get('skills').hasError('required') && (addSkillForm.get('skills').dirty || addSkillForm.get('skills').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                            *ngIf="addSkillForm.get('skills').hasError('whitespace') && (!addSkillForm.get('skills').hasError('required')) && (addSkillForm.get('skills').dirty || addSkillForm.get('skills').touched || submitted)">
                                Please enter valid data.
                            </div>
                            <div class="error-message" *ngIf="addSkillForm.errors?.maxCount && (addSkillForm.touched || addSkillForm.dirty)">
                                Max 10 interests allowed.
                            </div>
                            <div class="error-message" *ngIf="addSkillForm.get('skills').hasError('aftersubmit')">
                                {{addSkillForm.controls.skills.errors.aftersubmit}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="addInterest(addSkillForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editInterestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Interest</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="interestForm" novalidate>
                <div class="firstDiv">
                    <div class="media">
                        <div class="media-body">
                            <div class="form-group">
                                <label for="">Title </label>
                                <input type="text" class="form-control" formControlName="title">
                            </div>
                            <div class="error-message"
                                *ngIf="interestForm.get('title').hasError('required') && (interestForm.get('title').dirty || interestForm.get('title').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="interestForm.get('title').hasError('maxlength') && (interestForm.get('title').dirty || interestForm.get('title').touched)">
                                Max 200 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Past
                        </h3>
                        <label for="">How did you build this interest? What introduced you to it?</label>
                        <textarea type="text" class="form-control" formControlName="past_description"></textarea>
                        <div class="error-message"
                            *ngIf="interestForm.get('past_description').hasError('maxlength') && (interestForm.get('past_description').dirty || interestForm.get('past_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Present
                        </h3>
                        <label for="">What experiences are you currently doing to build or use this interest?</label>
                        <textarea type="text" class="form-control" formControlName="present_description"></textarea>
                        <div class="error-message"
                            *ngIf="interestForm.get('present_description').hasError('maxlength') && (interestForm.get('present_description').dirty || interestForm.get('present_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                    <div class="form-group">
                        <h3>
                            Future
                        </h3>
                        <label for="">What are your goals with this interest? How do you intend to use and build this in
                            future? </label>
                        <textarea type="text" class="form-control" formControlName="future_description"></textarea>
                        <div class="error-message"
                            *ngIf="interestForm.get('future_description').hasError('maxlength') && (interestForm.get('future_description').dirty || interestForm.get('future_description').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>

            <div class="divSecond">
                <span class="dividerBr"></span>
                <form [formGroup]="linkForm">
                    <div class="form-group">
                        <h3>Links</h3>
                    </div>
                    <div class="media align-items-center d-md-flex d-block" *ngIf="skillLinkArray.length < 5">
                        <div class="imgSec">
                            <img src="{{previewThumbnailLink? previewThumbnailLink :'assets/images/default.svg'}}"
                                class="mainCover d-block mx-auto img60">
                            <p>
                                {{ previewThumbnailLinkName }}
                            </p>
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Link</label>
                                    <input type="text" class="form-control" formControlName="link">
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('pattern') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Please enter a valid URL.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="!(linkForm.get('link').hasError('pattern')) && linkForm.get('link').hasError('required') && (linkForm.get('link').dirty || linkForm.get('link').touched || linkFrmSubmitted)">
                                        Please enter a valid URL before submit.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('maxlength') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Max 250 characters allowed.
                                    </div>
                                    <div class="error-message" *ngIf="linkForm.get('link').hasError('aftersubmit')">
                                        Please enter a valid URL.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Title</label>
                                    <input type="text" class="form-control" formControlName="title">
                                    <div class="error-message"
                                        *ngIf="linkForm.get('title').hasError('maxlength') && (linkForm.get('title').dirty || linkForm.get('title').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="mb-2">Upload Cover Image</label>
                                    <input type="file" class="fileUpload" placeholder="Type Here"
                                        (change)="linkImageUpload($event)">
                                    <span class="fileText cursor-pointer">Choose File</span>
                                    <div *ngIf="coverImageUploadError" class="error-message">
                                        {{coverImageUploadMessage}}
                                    </div>
                                    <div *ngIf="coverImageUploadSizeError" class="error-message">
                                        {{coverImageUploadSizeMessage}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <button class="common-btn" (click)="addMoreInterestLinkSubmit(linkForm.valid)">Save
                                        & Add More</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngFor="let skillLink of skillLinkArray">
                        <div class="col-4 mb-2">
                            <input type="text" class="form-control custom-readonly" readonly
                                value="{{ skillLink.link }}">
                        </div>

                        <div class="col-4 mb-2">
                            <input type="text" class="form-control custom-readonly" readonly
                                value="{{ skillLink.title }}">
                        </div>

                        <div class="col-4 mb-2">
                            <div class="del-icon-outer">
                                <div class="imgSec mr-3">
                                    <img src="{{skillLink.thumbnail ? skillLink.thumbnail :'assets/images/default.svg'}}"
                                        class="mainCover2 d-block img40">
                                </div>
                                <div class="del-icon">
                                    <img class="cursor-pointer" src="assets/images/delete_icon.svg"
                                        (click)="delLinkInterest(skillLink.id)">
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <span class="dividerBr"></span>
                <form [formGroup]="documentForm" novalidate>
                    <div class="form-group">
                        <h3>Images/Documents</h3>
                    </div>
                    <div class="media align-items-center d-md-flex d-block" *ngIf="skillDocArray.length < 5">
                        <div class="media-body ml-md-3">
                            <div class="row">

                                <div class="form-group col-md-4">
                                    <label>Title</label>
                                    <input type="text" class="form-control" formControlName="title">
                                    <div class="error-message"
                                        *ngIf="documentForm.get('title').hasError('maxlength') && (documentForm.get('title').dirty || documentForm.get('title').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="mb-2">Upload Image/Document</label>
                                    <input type="file" class="fileUpload" placeholder="Type Here"
                                        (change)="onFileSelect($event)" #interestDocInp>
                                    <span class="fileText cursor-pointer">Choose File</span>
                                    <p class="mt-2 mb-1" *ngIf="uploadForm.controls.document_name.value">
                                        {{uploadForm.controls.document_name.value}}
                                    </p>
                                    <div *ngIf="fileUploadError" class="error-message">
                                        {{fileUploadMessage}}
                                    </div>
                                    <div *ngIf="fileUploadSizeError" class="error-message">
                                        {{fileUploadSizeMessage}}
                                    </div>
                                    <div *ngIf="addMoreFileError" class="error-message">
                                        {{addMoreFileErrorMessage}}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> &nbsp; </label>
                                    <button class="common-btn"
                                        (click)="addMoreInterestDocSubmit(addMoreFileBoolean, interestDocInp)">Save &
                                        Add More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="skillDocArray.length > 0">
                        <div class="row mobile-2">
                            <div class="image-with-del" *ngFor="let skillDoc of skillDocArray">
                                <div class="imgSec">
                                    <img *ngIf="isImage(skillDoc.document_name)" src="{{skillDoc.document}}"
                                        class="mainCover d-block mx-auto img60">
                                    <img *ngIf="!isImage(skillDoc.document_name)" src="assets/images/pdf-icon.svg"
                                        class="mainCover d-block mx-auto img60">
                                    <p class="m-0 mt-2">
                                        {{ skillDoc.title }}
                                    </p>
                                    <div class="del-icon">
                                        <img src="./assets/images/delete_icon.svg"
                                            (click)="delDocInterest(skillDoc.id)" class="cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="delInterest()">
            Delete
        </button>

        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="updateInterest(interestForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #addBooks>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bookForm" novalidate>

                <div class="media">
                    <div class="imgSec">
                        <img class="for-preview-image"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                    </div>

                    <div class="media-body ml-3">

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Cover Image</label>
                            </div>
                            <div class="col-md-12 mt-1">
                                <div class="row m-0">
                                    <div class="d-flex align-self-start">
                                        <input type="file" class="fileUpload" placeholder="Type Here"
                                            (change)="coverImageUpload($event)">
                                        <span class="fileText cursor-pointer">Choose File</span>
                                        <div *ngIf="coverImageUploadError" class="error-message">
                                            {{coverImageUploadMessage}}
                                        </div>
                                        <div *ngIf="coverImageUploadSizeError" class="error-message">
                                            {{coverImageUploadSizeMessage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-md-12">
                            <label>Title <span>*</span> </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Title of Book"
                                formControlName="title">
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('required') && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('whitespace') && (!bookForm.get('title').hasError('required')) && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                                Please enter valid data.
                            </div>
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('maxlength') && (bookForm.get('title').dirty || bookForm.get('title').touched)">
                                Max 90 characters allowed.
                            </div>
                        </div>
                    </div>


                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Name of Author <span>*</span></label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" placeholder="Full Name of Author"
                            formControlName="author">
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('required') && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('whitespace') && (!bookForm.get('author').hasError('required')) && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            Please enter valid data.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('maxlength') && (bookForm.get('author').dirty || bookForm.get('author').touched)">
                            Max 90 characters allowed.
                        </div>
                    </div>
                </div>

                <div class="form-group row mb-1">
                    <div class="col-md-12">
                        <label>Rating</label>
                    </div>
                    <div class="col-md-12 custom-rating">
                        <ngb-rating [max]="5" (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reflection <span>*</span> </label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Type Here"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('required') && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('whitespace') && (!bookForm.get('description').hasError('required')) && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            Please enter valid data.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('maxlength') && (bookForm.get('description').dirty || bookForm.get('description').touched)">
                            Max 4500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="addBook(bookForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #updateBooks>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bookForm" novalidate>

                <div class="media">
                    <div class="imgSec">
                        <img *ngIf="editBook.thumbnail && !previewThumbnail" src="{{ editBook.thumbnail }}"
                            class="for-preview-image" alt="image">
                        <img *ngIf="!editBook.thumbnail || previewThumbnail"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}"
                            class="for-preview-image" alt="image">
                        <ul class="d-flex pl-0" *ngIf="editBook.thumbnail && !previewThumbnail">
                            <li><a href="javascript:void();" (click)="removeBookCover(editBook.id)"><img
                                        src="assets/images/newDelete.svg"></a></li>
                        </ul>
                    </div>

                    <div class="media-body ml-3">
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Cover Image</label>
                            </div>
                            <div class="col-md-12 mt-1">
                                <div class="row m-0">
                                    <div class="d-flex align-self-center">
                                        <input type="file" class="fileUpload" placeholder="Type Here"
                                            (change)="coverImageUpload($event)">
                                        <span class="fileText cursor-pointer">Choose File</span>
                                        <div *ngIf="coverImageUploadError" class="error-message">
                                            {{coverImageUploadMessage}}
                                        </div>
                                        <div *ngIf="coverImageUploadSizeError" class="error-message">
                                            {{coverImageUploadSizeMessage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-md-12">
                            <label>Title <span>*</span> </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Title of Book"
                                formControlName="title">
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('required') && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('whitespace') && (!bookForm.get('title').hasError('required')) && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                                Please enter valid data.
                            </div>
                            <div class="error-message"
                                *ngIf="bookForm.get('title').hasError('maxlength') && (bookForm.get('title').dirty || bookForm.get('title').touched)">
                                Max 90 characters allowed.
                            </div>
                        </div>
                    </div>


                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Name of Author <span>*</span> </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" placeholder="Full Name of Author"
                            formControlName="author">
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('required') && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('whitespace') && (!bookForm.get('author').hasError('required')) && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            Please enter valid data.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('maxlength') && (bookForm.get('author').dirty || bookForm.get('author').touched)">
                            Max 90 characters allowed.
                        </div>
                    </div>
                </div>

                <div class="form-group row mb-1 custom-rating">
                    <div class="col-md-12">
                        <label>Rating</label>
                    </div>
                    <div *ngIf="editBook.rating" class="col-md-9">
                        <ngb-rating [max]="5" [(rate)]="editBook.rating" (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                    <div *ngIf="!editBook.rating" class="col-md-9">
                        <ngb-rating [max]="5" (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reflection <span>*</span> </label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Type Here"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('required') && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('whitespace') && (!bookForm.get('description').hasError('required')) && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            Please enter valid data.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('maxlength') && (bookForm.get('description').dirty || bookForm.get('description').touched)">
                            Max 4500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delBook()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBook(bookForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #buletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>

                <div class="media">
                    <div class="imgSec">
                        <img src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}"
                            class="for-preview-image" alt="image">
                    </div>

                    <div class="media-body ml-3">

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Cover Image</label>
                            </div>
                            <div class="col-md-12 mt-1">
                                <input type="file" class="fileUpload" placeholder="Type Here"
                                    (change)="coverImageUpload($event)">
                                <span class="fileText cursor-pointer">Choose File</span>
                                <div *ngIf="coverImageUploadError" class="error-message">
                                    {{coverImageUploadMessage}}
                                </div>
                                <div *ngIf="coverImageUploadSizeError" class="error-message">
                                    {{coverImageUploadSizeMessage}}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-md-12">
                            <label>Title <span>*</span></label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Title" formControlName="title">
                            <div class="error-message"
                                *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                                Max 200 characters allowed.
                            </div>
                            <div class="error-message"
                                *ngIf="bulletinForm.get('title').hasError('whitespace') && (!bulletinForm.get('title').hasError('required')) && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                                Please enter valid data.
                            </div>
                        </div>
                    </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-12 mt-1">
                        <input type="file" class="fileUpload" placeholder="Type Here" (change)="onFileSelect($event)">
                        <span class="fileText cursor-pointer">Choose File</span>
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 2250 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addBulletin(bulletinForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editbulletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>

                <div class="media">
                    <div class="imgSec">
                        <img *ngIf="editBulletin.thumbnail && !previewThumbnail" src="{{ editBulletin.thumbnail }}"
                            class="for-preview-image" alt="image">
                        <img *ngIf="!editBulletin.thumbnail || previewThumbnail"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}"
                            class="for-preview-image" alt="image">
                        <ul class="d-flex pl-0" *ngIf="editBulletin.thumbnail && !previewThumbnail">
                            <li><a href="javascript:void();" (click)="removeBulletinCover(editBulletin.id)"><img
                                        src="assets/images/newDelete.svg"></a></li>
                        </ul>
                    </div>

                    <div class="media-body ml-3">
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Cover Image</label>
                            </div>
                            <div class="col-md-12 mt-1">
                                <input type="file" class="fileUpload" placeholder="Type Here"
                                    (change)="coverImageUpload($event)">
                                <span class="fileText cursor-pointer">Choose File</span>
                                <div *ngIf="coverImageUploadError" class="error-message">
                                    {{coverImageUploadMessage}}
                                </div>
                                <div *ngIf="coverImageUploadSizeError" class="error-message">
                                    {{coverImageUploadSizeMessage}}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-md-12">
                            <label>Title </label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Title" formControlName="title">
                            <div class="error-message"
                                *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                                Max 200 characters allowed.
                            </div>
                        </div>
                    </div>


                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-12 mt-1" *ngIf="!editBulletin.document">
                        <input type="file" class="fileUpload" placeholder="Type Here" (change)="onFileSelect($event)">
                        <span class="fileText cursor-pointer">Choose File</span>
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editBulletin.document" class="col-md-10">
                        <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank"
                            class="link-style">{{editBulletin.document_name}}</a>
                        <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank">
                            <img alt="image" src="{{editBulletin.document}}" class="uploaded-img">
                        </a>
                        <a class="ml-2" href="javascript:void()" (click)="removeBulletinDoc(editBulletin.id)">
                            <img alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 2250 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delBulletin()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBulletin(bulletinForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #staticModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank"
                            class="link-style">{{editBulletin.document_name}}</a>
                        <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank">
                            <img alt="image" src="{{editBulletin.document}}" class="uploaded-img">
                        </a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description" readonly> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #booksListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">books Listing</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-listing-wrap">
            <div class="media  border-row" *ngFor="let book of books"
                (click)="updatebookModal(updateBooks, book)">
                <img src="{{book.thumbnail ? book.thumbnail :'assets/images/default.svg'}}" alt="Books Cover"
                    class="booksCover mainTopImg">
                <div class="media-body ml-3">
                    <h4>{{ book.title }}</h4>
                    <p>{{ book.author }}</p>
                    <div>
                        <ul *ngIf="book.rating" class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                            <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                <ng-template let-fill="fill" let-index="index">
                                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                                </ng-template>
                            </ngb-rating>
                        </ul>
                    </div>
                    <p class="text-ellipse">{{ book.description }}</p>
                </div>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #goalsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="!goalBucketUpdate">goals & bucket List</h4>
        <h4 class="modal-title pull-left" *ngIf="goalBucketUpdate">update goals & bucket List</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="goalBucketForm" novalidate>
            <div class="modal-box">
                <div class="form-group m-0">
                    <label for="">Title</label>
                    <input type="text" class="form-control" formControlName="title">
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('required') && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('whitespace') && (!goalBucketForm.get('title').hasError('required')) && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched || submitted)">
                        Please enter valid data.
                    </div>
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('maxlength') && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched)">
                        Max 100 characters allowed.
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="!goalBucketUpdate">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addGoalsAndBucket(goalBucketForm.valid)">
            save
        </button>
    </div>
    <div class="modal-footer" *ngIf="goalBucketUpdate">
        <button class="btn btn-footer" (click)="delGoalsAndBucket()">
            Delete
        </button>
        <button class="btn btn-footer cancel-btn" (click)="updateGoalsAndBucket(goalBucketForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #learnAboutModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="!goalBucketUpdate">i want to learn about</h4>
        <h4 class="modal-title pull-left" *ngIf="goalBucketUpdate">i want to learn about</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="goalBucketForm" novalidate>
                <div class="form-group m-0">
                    <label for="">Title <span>*</span></label>
                    <input type="text" class="form-control" formControlName="title">
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('required') && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('whitespace') && (!goalBucketForm.get('title').hasError('required')) && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched || submitted)">
                        Please enter valid data.
                    </div>
                    <div class="error-message"
                        *ngIf="goalBucketForm.get('title').hasError('maxlength') && (goalBucketForm.get('title').dirty || goalBucketForm.get('title').touched)">
                        Max 100 characters allowed.
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="!goalBucketUpdate">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addLearnMoreAbout(goalBucketForm.valid)">
            save
        </button>
    </div>
    <div class="modal-footer" *ngIf="goalBucketUpdate">
        <button class="btn btn-footer" (click)="delLearnMoreAbout()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateLearnMoreAbout(goalBucketForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #favouritesModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">favourites</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="favouritesForm" novalidate>
            <div class="modal-box">
                <div class="media mb-3">
                    <div class="imgSecPopup">
                        <img alt="image" class="preview-image"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                        <ul *ngIf="previewThumbnail" class="d-flex imgIcon pl-0 m-0 no-list">
                            <li>
                                <a href="javascript:void();" (click)="blankfavImageUpload(false, favFileUpload)">
                                    <img alt="icon" src="assets/images/newDelete.svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="media-body ml-3">
                        <div class="form-group">
                            <label for="">Add Cover Image</label>
                            <input type="file" placeholder="Type Here" class="fileUpload"
                                (change)="coverImageUpload($event)" #favFileUpload>
                            <span class="fileText cursor-pointer">Choose File</span>
                            <div *ngIf="coverImageUploadError" class="error-message">
                                {{coverImageUploadMessage}}
                            </div>
                            <div *ngIf="coverImageUploadSizeError" class="error-message">
                                {{coverImageUploadSizeMessage}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-group">
                        <label for="">Title <span>*</span></label>
                        <input type="text" class="form-control" formControlName="title">
                        <div class="error-message"
                            *ngIf="favouritesForm.get('title').hasError('required') && (favouritesForm.get('title').dirty || favouritesForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="favouritesForm.get('title').hasError('maxlength') && (favouritesForm.get('title').dirty || favouritesForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                <div class="form-group">
                    <label for="">Topic</label>
                    <input type="text" class="form-control" formControlName="topic">
                    <div class="error-message"
                        *ngIf="favouritesForm.get('topic').hasError('maxlength') && (favouritesForm.get('topic').dirty || favouritesForm.get('topic').touched)">
                        Max 75 characters allowed.
                    </div>
                    <p class="input-note">Ex Movie, Album, Artist, Colour, Subject, Book, Podcast</p>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addFavourite(favouritesForm.valid)">
            save
        </button>
    </div>
</ng-template>
<ng-template #editfavouritesModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">edit favourites</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="favouritesForm" novalidate>
            <div class="modal-box">
                <div class="media mb-3">
                    <div class="imgSecPopup">
                        <img alt="image" class="preview-image"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                        <ul *ngIf="previewThumbnail" class="d-flex imgIcon pl-0 m-0 no-list">
                            <li>
                                <a href="javascript:void();" (click)="blankfavImageUpload(true, editfavFileUpload)">
                                    <img alt="icon" src="assets/images/newDelete.svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="media-body ml-3">
                        <div class="form-group">
                            <label for="">Add Cover Image</label>
                            <input type="file" placeholder="Type Here" class="fileUpload"
                                (change)="coverImageUpload($event)" #editfavFileUpload>
                            <span class="fileText cursor-pointer">Choose File</span>
                            <div *ngIf="coverImageUploadError" class="error-message">
                                {{coverImageUploadMessage}}
                            </div>
                            <div *ngIf="coverImageUploadSizeError" class="error-message">
                                {{coverImageUploadSizeMessage}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form-group">
                        <label for="">Title <span>*</span></label>
                        <input type="text" class="form-control" formControlName="title">
                        <div class="error-message"
                            *ngIf="favouritesForm.get('title').hasError('required') && (favouritesForm.get('title').dirty || favouritesForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="favouritesForm.get('title').hasError('maxlength') && (favouritesForm.get('title').dirty || favouritesForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                <div class="form-group">
                    <label for="">Topic</label>
                    <input type="text" class="form-control" formControlName="topic">
                    <div class="error-message"
                        *ngIf="favouritesForm.get('topic').hasError('maxlength') && (favouritesForm.get('topic').dirty || favouritesForm.get('topic').touched)">
                        Max 75 characters allowed.
                    </div>
                    <p class="input-note">Ex Movie, Album, Artist, Colour, Subject, Book, Podcast</p>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delFavourite()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateFavourite(favouritesForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #mamModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">more about me</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body new-body">
        <div class="modal-box">
            <form [formGroup]="mamForm" novalidate>
               <div class="form-row">
                    <div class="media">
                            <div class="imgSecPopup">
                                <img alt="image" class="for-preview-image"
                                    src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                                <ul *ngIf="previewThumbnail" class="d-flex imgIcon pl-0 m-0 no-list">
                                    <li>
                                        <a href="javascript:void();" (click)="removeMamImage(mamImageUp)">
                                            <img alt="icon" src="assets/images/newDelete.svg">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="media-body ml-3">
                                <div class="form-group">
                                    <!-- <label for="">Add Cover Image</label> -->
                                    <input type="file" placeholder="Type Here" class="fileUpload"
                                        (change)="coverImageUpload($event)" #mamImageUp>
                                    <span class="fileText cursor-pointer">Choose File</span>
                                    <div *ngIf="coverImageUploadError" class="error-message">
                                        {{coverImageUploadMessage}}
                                    </div>
                                    <div *ngIf="coverImageUploadSizeError" class="error-message">
                                        {{coverImageUploadSizeMessage}}
                                    </div>
                                </div>
        
                            </div>
                        </div>
               </div>
                <div class="form-row">
                        <div class="form-group">
                                <input type="text" class="form-control" placeholder="Title*" formControlName="title">
                                <div class="error-message"
                                    *ngIf="mamForm.get('title').hasError('required') && (mamForm.get('title').dirty || mamForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="mamForm.get('title').hasError('maxlength') && (mamForm.get('title').dirty || mamForm.get('title').touched)">
                                    Max 75 characters allowed.
                                </div>
                            </div>
                </div>

            <div class="form-row">
                    <div class="form-group">
                            <input type="text" class="form-control" placeholder="Role*" formControlName="role">
                            <div class="error-message"
                                *ngIf="mamForm.get('role').hasError('required') && (mamForm.get('role').dirty || mamForm.get('role').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="mamForm.get('role').hasError('maxlength') && (mamForm.get('role').dirty || mamForm.get('role').touched)">
                                Max 75 characters allowed.
                            </div>
                        </div>
            </div>
            <div class="form-row">
                    <div class="form-group">
                            <input type="text" class="form-control" placeholder="Location*" formControlName="location">
                            <div class="error-message"
                                *ngIf="mamForm.get('location').hasError('required') && (mamForm.get('location').dirty || mamForm.get('location').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="mamForm.get('location').hasError('maxlength') && (mamForm.get('location').dirty || mamForm.get('location').touched)">
                                Max 75 characters allowed.
                            </div>
                        </div>
            </div>
            <div class="form-row">
                    <div class="form-group row customRow">
                            <div class="col-md-4">
                                <label for="">Start Date</label>
                                <div class="relative">
                                        <input type="text" class="form-control modal-control modal-field" placeholder="Start Date"
                                        formControlName="start_date" bsDatepicker [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        style="background-color: white;" readonly>
                                    <a href="javascript:void(0);" (click)="resetStartDate()"><img alt="image"
                                            src="assets/images/plusrotate.svg" class="img-cross"></a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="d-flex flex-column">
                                    <!-- <div class="custom-control custom-radio">
                                        <input type="radio" value="1" id="end" class="custom-control-input" name="dateset"
                                            (change)="endDate($event)" [checked]="isEndDate" />
                                        <label for="end" class="custom-control-label custom-label mb-0">End Date</label>
                                    </div> -->
                                    <div class="teal-radio-box canvas">
                                            <input type="radio" value="1" id="end" class="custom-control-input" name="dateset"
                                            (change)="endDate($event)" [checked]="isEndDate" />
                                            <label for="end">End Date</label>
                                    </div>
        
                                    <div class="fullWidth" *ngIf="isEndDate">
                                            <label for="">
                                                <div class="relative">
                                                        <input type="text" class="form-control modal-control modal-field" placeholder="End Date"
                                                        bsDatepicker [maxDate]="today" formControlName="end_date"
                                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                                        style="background-color: white;" readonly>
                                                    <a href="javascript:void(0);" (click)="resetEndDate()"><img alt="image"
                                                            src="assets/images/plusrotate.svg" class="img-cross"></a>
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="isEndDate && mamForm.get('end_date').hasError('required') && (mamForm.get('end_date').dirty || mamForm.get('end_date').touched || submitted)">
                                                    This field is required.
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="mamForm.errors?.invalidEndDate && (mamForm.touched || mamForm.dirty)">
                                                    Start date should not be greater than end date
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="mamForm.errors?.startDateNA && (mamForm.touched || mamForm.dirty)">
                                                    End date requires start date.
                                                </div>
                                            </label>
                                        </div>
                                </div>
                                </div>
                                <div class="col-md-4">
                                        <!-- <div class="custom-control custom-radio ml-3">
                                                <input type="radio" value="2" id="current" class="custom-control-input" name="dateset"
                                                    (change)="endDate($event)" [checked]="!isEndDate" />
                                                <label for="current" class="custom-control-label custom-label mb-0">Present Date</label>
                                            </div> -->
                                            <div class="teal-radio-box">
                                                    <input type="radio" value="2" id="current" class="custom-control-input" name="dateset"
                                                    (change)="endDate($event)" [checked]="!isEndDate" />
                                                    <label for="current">Present Date</label>
                                            </div>
                                </div>
        
                            </div>
            </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addMam(mamForm.valid)">
            save
        </button>
    </div>
</ng-template>
