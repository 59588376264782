<div *ngIf="submitError" class="error-message col-md-12 text-center">{{submitErrorMsg}}</div>
<form [hidden]="!currentForm" [formGroup]="searchForm" novalidate>
    <div class="mb-4">
        <div class="row">
            <div class="col-md-6">
                <select class="form-control customSelectBlue" formControlName="account_type">
                    <option value="" disabled selected>Page Type</option>
                    <option value='1'>Club</option>
                    <option value='2'>Team</option>
                </select>
                <div class="error-message col-12"
                    *ngIf="searchForm.get('account_type').hasError('required') && (searchForm.get('account_type').dirty || searchForm.get('account_type').touched || searchSubmitted)">
                    This field is required.
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12 ">
            <input type="text" placeholder="Title of Above" class="form-control signUpField"
                formControlName="title">
            <div class="error-message"
                *ngIf="searchForm.get('title').hasError('required') && (searchForm.get('title').dirty || searchForm.get('title').touched || searchSubmitted)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="searchForm.get('title').hasError('maxlength') && (searchForm.get('title').dirty || searchForm.get('title').touched)">
                Max 125 characters allowed.
            </div>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-10">
            <input type="text" class="form-control signUpField"
                placeholder="Teacher Email"
                formControlName="professional_email" (focusout)="validateEmail()">
            <div class="error-message"
                *ngIf="searchForm.get('professional_email').hasError('required') && (searchForm.get('professional_email').dirty || searchForm.get('professional_email').touched || searchSubmitted)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="searchForm.get('professional_email').hasError('maxlength') && (searchForm.get('professional_email').dirty || searchForm.get('professional_email').touched)">
                Max 100 characters allowed.
            </div>
            <div class="error-message"
                *ngIf="searchForm.get('professional_email').hasError('pattern') && (searchForm.get('professional_email').dirty || searchForm.get('professional_email').touched)">
                Please enter your email in a valid format.
            </div>
            <div class="error-message"
                *ngIf="searchForm.get('professional_email').hasError('invalid_format') && (searchForm.get('professional_email').dirty || searchForm.get('professional_email').touched)">
                This domain name cannot be used.
            </div>
        </div>
        <div class="col-2">
            <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                class="cursor-pointer">
            <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                <div class="info-div">
                    <p>You must enter and verify your official Teacher Email (ex.
                        ****@yrdsb.ca) to create a club or team page. This email is used to
                        verify the club or team, and is placed at the top of the page so
                        that students may contact you. You will have 30 days to verify the
                        email sent to your Teacher inbox</p>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12 ">
            <input type="text" class="form-control signUpField" placeholder="Country" formControlName="country" readonly disabled>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12 ">
            <input type="text" class="form-control signUpField" placeholder="State" formControlName="state" readonly disabled>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12">
            <input type="text" placeholder="Full School Name" class="form-control signUpField" formControlName="school_name" readonly disabled>
        </div>
    </div>
    <div class="form-group m-0 text-center pt-2">
        <button type="submit" class="btn btnSignUp"
            (click)="showNext(searchForm.valid)">
            <span *ngIf="!userId">Next</span>
            <span *ngIf="userId">Submit</span>
        </button>
    </div>
</form>
<form [hidden]="!secondStep" [formGroup]="secondStepForm" novalidate
    (ngSubmit)="save(secondStepForm.valid)">
    <div class="claimNoteWrap">
        <p class="claimNote m-0">Please enter all accurate information below. 
            You will not be allowed to change your Name or Year of Birth once your account is created</p>
        </div>
    <div class="form-group row inputRow">
        <div class="col-md-12">
            <label for="" class="claimLabel">Teacher Details</label>
        </div>
        <div class="col-md-12">
            <input type="text" class="form-control signUpField" placeholder="First"
                formControlName="firstname">
            <div class="error-message"
                *ngIf="secondStepForm.get('firstname').hasError('required') && (submitted || secondStepForm.get('firstname').dirty || secondStepForm.get('firstname').touched)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('firstname').hasError('maxlength') && (secondStepForm.get('firstname').dirty || secondStepForm.get('firstname').touched)">
                Max 50 characters allowed.
            </div>
        </div>
    </div>

    <div class="form-group row inputRow">
        <div class="col-md-12">
            <input type="text" class="form-control signUpField" placeholder="Last"
                formControlName="lastname">
            <div class="error-message"
                *ngIf="secondStepForm.get('lastname').hasError('required') && (submitted || secondStepForm.get('lastname').dirty || secondStepForm.get('lastname').touched)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('lastname').hasError('maxlength') && (secondStepForm.get('lastname').dirty || secondStepForm.get('lastname').touched)">
                Max 50 characters allowed.
            </div>
        </div>
    </div>

    <div class="form-group row inputRow">
        <div class="col-md-12">
            <label for="" class="claimLabel">Birth Year</label>
        </div>
        <div class="col-md-12">
            <input type="number" placeholder="YYYY" class="form-control signUpField"
                formControlName="year_of_birth" (focusout)="checkDate()">
            <ng-container *ngFor="let error of error_messages.year_of_birth">
                <div class="error-message"
                    *ngIf="secondStepForm.get('year_of_birth').hasError(error.type) && (submitted || secondStepForm.get('year_of_birth').dirty || secondStepForm.get('year_of_birth').touched)">
                    {{ error.message }}
                </div>
            </ng-container>
            <div class="error-message" *ngIf="invalidDate">
                {{invalidDateMsg}}
            </div>
        </div>
    </div>

    <div class="form-group row inputRow">
        <div class="col-md-12">
            <input type="text" class="form-control signUpField" placeholder="Email"
                formControlName="email">
            <div class="error-message"
                *ngIf="secondStepForm.get('email').hasError('required') && (submitted || secondStepForm.get('email').dirty || secondStepForm.get('email').touched)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('email').hasError('maxlength') && (secondStepForm.get('email').dirty || secondStepForm.get('email').touched)">
                Max 100 characters allowed.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('email').hasError('pattern') && (secondStepForm.get('email').dirty || secondStepForm.get('email').touched)">
                Please enter your email in a valid format.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('email').hasError('aftersubmit')">
                {{secondStepForm.controls.email.errors.aftersubmit}}
            </div>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12">
            <input type="password" class="form-control signUpField" placeholder="Password"
                formControlName="password">
            <div class="error-message"
                *ngIf="secondStepForm.get('password').hasError('required') && (submitted || secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('password').hasError('maxlength') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                Max 15 characters allowed.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('password').hasError('invalidPassword') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                Your password needs to be at least 8 characters, including a Capital Letter,
                Lowercase Letter and a Number.
            </div>
        </div>
    </div>
    <div class="form-group row inputRow">
        <div class="col-md-12">
            <input type="password" class="form-control signUpField" placeholder="Repeat Password"
                formControlName="confirm_password">
            <div class="error-message"
                *ngIf="secondStepForm.get('confirm_password').hasError('required') && (submitted || secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.get('confirm_password').hasError('minlength') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                Your password needs to be at least 8 characters, including a Capital Letter,
                Lowercase Letter and a Number.
            </div>
            <div class="error-message"
                *ngIf="secondStepForm.hasError('passwordNotMatch') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                Passwords do not match.
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck"
                name="privacy" (change)="onCheckboxChange($event)">
            <label class="custom-control-label" for="customCheck">I have read and agree to
                the
                <a [routerLink]="['/terms-of-use']" target="_blank" rel="noopener noreferrer">Terms of use</a> and
                <a [routerLink]="['/privacy-policy']" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
            </label>
        </div>
        <div class="error-message" *ngIf="!user.is_terms_accepted && submitted">
            Please agree to the Terms and Conditions and Privacy Policy.
        </div>
    </div>
    <div class="form-group m-0 text-center pt-2">
        <button type="submit" class="btn btnSignUp">Register</button>
    </div>
</form>