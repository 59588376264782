import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ProfileService } from '../services/profile.service';
import { IContactUs } from '../shared/contactUs'


@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnInit {

  contactUsForm: FormGroup;
  submitted: boolean = false;
  @Input() displayColClass: boolean;
  displayColClass4: boolean;
  modalRef: BsModalRef;
  disableSubmit: boolean = false;
  hideNameEmail: boolean = false;

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, 
    private profileService: ProfileService, private customValidator: CustomvalidationService,) {
    this.contactUsForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      message: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(100),]),
    });
  }

  ngOnInit(): void {
    this.displayColClass4 = this.displayColClass;
    this.contactFormInit();
  }

  submitContactUs(successModal: TemplateRef<any>){
    if (!this.contactUsForm.valid){
      this.submitted = true;
      return
    }
    this.disableSubmit = true;
    this.submitted = false;
    let contactObj: IContactUs = {
        'name': this.contactUsForm.controls['name'].value,
        'email': this.contactUsForm.controls['email'].value,
        'subject': this.contactUsForm.controls['subject'].value,
        'message': this.contactUsForm.controls['message'].value,
    }
    this.profileService.contactUsPost(contactObj)
      .subscribe(response => {
        this.handleContactSubmit(response, successModal);
      }, error => {
        this.handleContactError(error.error);
      });
  }

  contactFormInit() {
    this.contactUsForm.reset();
    this.submitted = false;
    if (localStorage.user){
      let user_ob = JSON.parse(localStorage.user);
      let email = user_ob.email;
      let full_name = user_ob.first_name + " " + user_ob.last_name;
      this.contactUsForm.controls['email'].setValue(email);
      this.contactUsForm.controls['name'].setValue(full_name);
      this.hideNameEmail = true;
      this.displayColClass4 = false;
    } else {
      this.hideNameEmail = false;
      this.displayColClass4 = true;
    }
  }

  protected handleContactSubmit(response: any, successModal: TemplateRef<any>) {
    if (response.status === 111) {
      this.handleContactError(response.message);
    } else if (response.status === 200) {
        this.disableSubmit = false;
        this.contactFormInit();
        this.modalRef = this.modalService.show(
          successModal, Object.assign({}, { 
            class: 'modal-dialog-centered custom-modal'}));
        
    }
  }

  protected handleContactError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.contactUsForm.contains(field)) {
        this.contactUsForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

}
