<li class="reply-li" *ngIf="replies.length > 0">
    <ul class="reply-list">
      <li *ngFor="let reply of replies">
        <div class="img-box">
            <img src="{{reply.creator_info.display_picture? reply.creator_info.display_picture : 'assets/images/p-img.png'}}" alt="profile" />
        </div>
        <div class="content-box">
            <div class="w-100 name-comments">
                <a href="profile/{{reply.creator_info.user_id}}/" class="person-name">{{reply.creator_info.name}}</a>
                <p innerHtml="{{reply.text}}"></p>
            </div>
          <div class="date-box">
            <span>{{reply.created_date | date: 'MMMM d, y'}}</span>
            <button *ngIf="user.id == reply.creator_info.user_id && !reply.is_deleted" type="button" class="reply-btn" (click)="openDeleteModal(reply.id, delTimelineTemplate)">
                Delete
            </button>
            <button *ngIf="user.id !== reply.creator_info.user_id && !reply.is_deleted" type="button" class="reply-btn" (click)="openReply(replyModal, reply.creator_info)">
                Reply
            </button>
          </div>
        </div>
      </li>
      <li *ngFor="let reply of newReplies">
        <div class="img-box">
            <img src="{{reply.creator_info.display_picture? reply.creator_info.display_picture : 'assets/images/p-img.png'}}" alt="profile" />
        </div>
        <div class="content-box">
            <div class="w-100 name-comments">
                <a href="profile/{{reply.creator_info.user_id}}/" class="person-name">{{reply.creator_info.name}}</a>
                <p innerHtml="{{reply.text}}"></p>
            </div>
          <div class="date-box">
            <span>{{reply.created_date | date: 'MMMM d, y'}}</span>
            <button *ngIf="user.id == reply.creator_info.user_id && !reply.is_deleted" type="button" class="reply-btn" (click)="openDeleteModal(reply.id, delTimelineTemplate)">
                Delete
            </button>
          </div>
        </div>
      </li>
    </ul>
    <button *ngIf="totalReplyCount > 10 && totalReplyCount > replies.length" 
        type="button" class="load-more" (click)="getReplies()">Load More</button>
  </li>

  <ng-template #replyModal>
    <div class="modal-body new-body">
      <div class="create-box timeline">
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
          <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg" alt="arrow" />
        </button>
      </div>
      <div class="comment-box reply-box">
        <form [formGroup]="replyForm" novalidate>
          <div class="input-box mt-5">
            <textarea type="text" placeholder="Write your reply" formControlName="text"
              [mentionConfig]="{items:teams, labelKey:'name', mentionSelect:chngFormat}"
              [mentionListTemplate]="mentionListTemplate"></textarea>
              <div class="error-message"
              *ngIf="replyForm.get('text').hasError('required') && (replyForm.get('text').dirty || replyForm.get('text').touched || replySubmitted)">
              This field is required.
            </div>
            <div class="error-message" *ngIf="replyForm.get('text').hasError('whitespace') && !replyForm.get('text').hasError('required') && replySubmitted">
              Please enter valid input
            </div>
            <button type="button" (click)="createReply(replyForm.valid)">Post</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #mentionListTemplate let-item="item">
    <img class="mr-2" src="{{item.profile_pic? item.profile_pic : 'assets/images/p-img.png'}}" alt="profile-image" width="25" height="25">
    <span>{{item.name}}</span>
  </ng-template>

  <ng-template #delTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to delete this reply ?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="deleteReply()">
            Confirm
        </button>
    </div>
</ng-template>
