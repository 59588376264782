<div *ngIf="myInitiatives.length > 0" class="content-profile-white-box">
<div class="titleDiv d-flex align-items-center">
    <h3>
        initiative
    </h3>
    <a href="javascript:void();" (click)="modalInitiativeTag(initiativeTagTemplate)"><img class="d-block"
            src="assets/images/plus-teal.svg" alt="icon"></a>
</div>
<div class="detailContent">
    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel" [config]="sliderInitiative1">
        <div class="slide" ngxSlickItem *ngFor="let initiative of myInitiatives">
            <div class="initiative-wrap pr-2">
                <div class="img-60-circle mx-auto" (click)="jumpToInitiative(initiative)">
                    <img class="icon"
                        src="{{initiative.display_pic ? initiative.display_pic : 'assets/images/default.svg'}}" />
                </div>

                <div class="dtl">
                    <h3 (click)="jumpToInitiative(initiative)">{{ initiative.page_info.title }}</h3>
                    <div class="expVerifyTeam" *ngIf="initiative.team_members > 0">
                        <p class="mb-2 mx-auto cursor-pointer  people-number" (click)="iniTeamModal(initiative, iniTeamMemberTemplate)">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                            <span>{{initiative.team_members}}</span>
                        </p>
                    </div>
                    <div class="initiativeAddWrap">
                        <a class="del-icon mr-2" href="javascript:void();"
                            (click)="deleteInitiativeConfirm(deleteInitiativeTemplate, initiative)">
                            <img alt="icon" src="assets/images/deleteIcon.svg" />
                        </a>
                        <div class="addRefRow m-0">
                            <div class="addRefRowInner">
                                <a href="javascript:void(0);" (click)="modalAddTeam(templateAddTeam, initiative)">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                    <span>Add</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div>
</div>
<ng-template #initiativeTagTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">add initiative</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="showTag" [formGroup]="pageTagForm" novalidate>
                <div class="tag-steps">
                    <div class="row">
                        <div class="col-md-4 left-wrap">
                            <h2 class="tag-name mb-3">
                                <strong>Step 1</strong>
                                tag initiative
                            </h2>

                            <div class="pl-3">
                                <div class="form-group">
                                    <label for="">Page ID</label>
                                    <input type="text" formControlName="page_id" class="form-control"
                                        (keydown)="reTagPage()" />
                                    <div class="error-message"
                                        *ngIf="(pageTagForm.errors?.invalidPageId || pageTagForm.get('page_id').hasError('whitespace')) && (pageTagForm.get('page_id').dirty || pageTagForm.get('page_id').touched)">
                                        Please enter valid data.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="pageTagForm.get('page_id').hasError('aftersubmit')">
                                        {{pageTagForm.controls.page_id.errors.aftersubmit}}
                                    </div>
                                    <div class="error-message" *ngIf="tagError && !(pageTagForm.errors?.invalidPageId)">
                                        Please tag an initiative.
                                    </div>
                                </div>
                                <button class="btn btn-colored mb-3" (click)="tagPage(pageTagForm.valid)">Tag</button>

                                <div class="tag-details mb-3" *ngIf="(selectedInit | json) !== '{}'">
                                    <div class="media align-items-center">
                                        <img alt="tag Icon"
                                            src="{{selectedInit.display_picture? selectedInit.display_picture : 'assets/images/default.svg'}}"
                                            class="thumb-img img-60">
                                        <div class="media-body ml-3">
                                            <h4 class="m-0">{{selectedInit.title}}</h4>
                                            <p class="m-0 font-14">{{selectedInit.state}}, {{selectedInit.city}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8 right-wrap">
                            <img alt="image" src="assets/images/tag-image.png">
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
                <div class="tag-steps">
                    <h2 class="tag-name mb-3">
                        <strong>Step 2</strong>
                        add postion details
                    </h2>

                    <div class="pl-3 pt-2">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="form-group">
                                    <label for="">Position <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Here"
                                        formControlName="position" />
                                    <div class="error-message"
                                        *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
                                        Max 50 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="searchForm.get('position').hasError('whitespace') && (!searchForm.get('position').hasError('required')) && (searchForm.get('position').dirty || searchForm.get('position').touched || submitted)">
                                        Please enter valid data.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">Cancel</button>
        <button class="btn btn-footer" *ngIf="showTag" (click)="aftrTag(pageTagForm.valid)">Next</button>
        <button class="btn btn-footer" *ngIf="!showTag" (click)="saveTag(searchForm.valid)">Save</button>
    </div>
</ng-template>

<ng-template #deleteInitiativeTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete initiative</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4 *ngIf="delInitiativeTagged">Are you sure you want to remove the tagged page?</h4>
            <h4 *ngIf="!delInitiativeTagged">Are you sure you want to delete this page? All of the information will be
                deleted and the page will no longer exist</h4>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" (click)="deleteInitiative()">Delete</button>
        <button class="btn btn-footer" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>

<ng-template #templateAddTeam>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add team member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
                <div class="initiativeTeamSlider mb-md-5 px-0">
                    <div class="teamSearchInputWrap mt-2">
                        <div class="teamSearchInputWrapInner">
                            <div class="leftWrap" *ngIf="iniMembers.length > 0">
                                <div class="form-group">
                                    <input type="text" placeholder="Search by name" class="form-control" (keyup)="searchStudent($event)">
                                </div>
                            </div>
                            <div class="rightWrap" [ngClass]="{'forMissingInvite' : iniMembers.length == 0}">
                                <p class="des">Is someone from this team missing? 
                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                        <img alt="icon" class="iconInvite ml-2 mr-1" src="./assets/images/verifyProfile.svg" />
                                        <span>Invite</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="iniMemberSliced && iniMemberSliced.length > 0">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let member of iniMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember"><img alt="icon" class="bannerImg"
                                        src="{{member.student_info.header_image? member.student_info.header_image : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="profileMember"><img alt="icon" class="profileImg"
                                        src="{{member.student_info.display_pic? member.student_info.display_pic : 'assets/images/p-img.png'}}">
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{member.student_info.student_name}}</h1>
                                        <h5 class="subName">{{member.student_info.position}}</h5>
                                        <div class="memberActions">
                                            <button class="btn common-btn btnMessage" *ngIf="user.approval_status && (user.age_group < 3 || user.age_group == member.student_info.age_group)" (click)="routeToProfile(member.student_info.id)">Profile</button>
                                            <button class="btn common-btn btnProfile" *ngIf="member.team_status == 5" (click)="addToTeam(member)">Add to team</button>
                                            <button class="btn common-btn btnProfile noCursor" *ngIf="member.team_status < 5"><span *ngIf="member.team_status == 1">Pending</span><span *ngIf="member.team_status == 2">Approved</span></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style="max-width: 100%;min-width: 100%;" id="bottomScr"></li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="loadMoreMembers('bottomScr')">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #iniTeamMemberTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Your Team <span *ngIf="teamMember.length > 0">({{ totalTeamMember }})</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div class="initiativeTeamSlider mb-md-2 px-2">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMember.length > 0">
                        <ul class="teamMemberList no-list">
                            <li *ngFor="let member of teamMember">
                                <div class="myTeamEach">
                                    <div class="leftTeam">
                                        <img alt="icon" class="profileImg" src="{{member.ini_team_detail.display_pic ? member.ini_team_detail.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="rightTeam rightTeamWithBtn">
                                        <div class="forDetails">
                                            <h1 class="name m-0">{{ member.ini_team_detail.name }}</h1>
                                            <p class="yearHistoryMember m-0">{{ member.ini_team_detail.position }}</p>
                                        </div>
                                        <div class="forBtns">
                                            <button class="btn btnInvite" (click)="delTeamMemberModal(delTeamTemplate, member)">Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="totalTeamMember > 6 && totalTeamMember > teamMember.length">
                        <a href="javascript:void(0);" (click)="loadMoreIniTeamMember()">Load More</a>
                    </div>
                    <div *ngIf="teamMember.length == 0">
                        No Team Member(s)
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Team Member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="delModalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to remove this person?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delModalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delTeamMember(); delModalRef.hide();">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="newModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                    <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                    <p class="inviteCopySbNote">Join my network at {{title}} on ConnectUs.today by signing up using the following link:  <strong>connectus.today/signin</strong></p>
                    <div class="inviteBorder">
                        <div class="custom-tooltip copy-tooltip">
                            <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                            <div class="tooltip-data" *ngIf="copyMessageBool">Message Copied</div>
                        </div>
                    </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="newModalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="newModalRef.hide()">Done</button>
    </div>
</ng-template>
