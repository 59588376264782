<div class="tab-content-box">
    <div class="fullBanner">
        <div class="forImage">
            <img alt="banner" class="bannerImg" [ngClass]="{'cursor-pointer': header_image}" (click)="showImg(headerImages);"
                src="{{header_image? header_image : 'assets/images/new-header-img.png'}}">
        </div>
        <div class="forDetail">
            <div class="container custom-container max-890 mb-40">
                <div class="row">
                        <div class="col-12 center-wrap">
                                <div class="withProfile">
                                    <div class="forEdit mr-auto">
                                        <img alt="profile Image" class="profileImg"
                                            src="{{display_image? display_image : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="btn-box">
                                            <div class="follow-group-box">
                                                    <button type="button" class="schoolfollow-btn" (click)="followInitiative()"
                                                        [ngClass]="{'unfollow': initiative.is_followed}">
                                                        <span *ngIf="!initiative.is_followed">Follow</span>
                                                        <span *ngIf="initiative.is_followed">Unfollow</span>
                                                    </button>
                                                    <p *ngIf="followBtnErr" class="error-message text-center">Something went wrong. Please try again later.</p>
                                                </div>
                                            <div class="btn-group" dropdown [insideClick]="true">
                                              <button id="button-basic" dropdownToggle type="button" class="three-dots-btn"
                                                aria-controls="dropdown-basic">
                                                <img src="/assets/images/three-dots-btn.svg" alt="dots" />
                                              </button>
                                              <ul id="dropdown-basic" *dropdownMenu
                                                class="dropdown-menu name-mobile-box school-bucket-dropdown ViewDropdown" role="menu"
                                                aria-labelledby="button-basic">
                                                <li role="menuitem">
                                                  <div class="flag-data-box cursor-pointer custom-tooltip" (click)="copyPageID()">
                                                    <img src="/assets/images/pageid.svg" alt="flag" class="edit-icon">
                                                    <span>Page ID: <span class="teal">INI{{ initiativeId }}</span></span>
                                                    <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
                                                  </div>
                                                </li>
                                                <li role="menuitem" *ngIf="showSettings && userID">
                                                        <div class="flag-data-box cursor-pointer" (click)="settingModal(settingTemplate)">
                                                          <img src="/assets/images/settings-teal.svg" alt="flag" class="edit-icon">
                                                          <span>Settings</span>
                                                        </div>
                                                      </li>
                                                <li role="menuitem">
                                                  <div class="flag-data-box cursor-pointer custom-tooltip" (click)="copyAndShare()">
                                                    <img src="/assets/images/share-icon-teal.svg" alt="flag" class="edit-icon">
                                                    <span>Share</span>
                                                    <div class="tooltip-data" *ngIf="copyBool">Link Copied</div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                </div>
                                <div class="profileList">
                                    <h3>{{ initiativeTitle }}</h3>
                                    <h4 *ngIf="city">{{ city }}, {{ state }}</h4>
                                    <h4 *ngIf="school_name">{{school_name}}</h4>
                                    <p class="startDate_init" *ngIf="start_date">{{ start_date | date:'MMM y' }} -
                                        <span *ngIf="end_date">{{ end_date | date:'MMM y' }}</span>
                                        <span *ngIf="!end_date">Current</span>
                                    </p>
                                </div>
                            </div>
                </div>
                <p class="initiativeSummary">{{ summary }}</p>

                <div *ngIf="tabActive == 1">
                    <div class="d-block d-md-none mt-3">
                        <ul class="socialBtns no-list justify-content-center">
                            <li class="custom-tooltip">
                                <span class="pageIDCopy cursor-pointer" (click)="copyPageID()">PAGE ID: INI{{ initiativeId }}</span>
                                <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
                            </li>

                            <li *ngIf="showSettings && userID">
                                <a href="javascript:void();" (click)="settingModal(settingTemplate)">
                                    <img alt="icon" class="hdg-icon" src="assets/images/settingIconTransparent.svg">
                                </a>
                            </li>

                            <li class="custom-tooltip">
                                <a href="javascript:void();" (click)="copyAndShare()">
                                    <img alt="icon" class="hdg-icon" src="assets/images/shareIconGreen.svg">
                                </a>
                                <div *ngIf="copyBool" class="tooltip-data">Link Copied</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tabWrap mb-0">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12">
                    <ul class="tabBtns no-list">
                            <li class="cursor-pointer" [ngClass]="{
                                'selected-tab': tabActive == 3
                              }" (click)="tabActive=3">
                                Timeline
                            </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                          }" (click)="tabActive=1">
                            About
                        </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                          }" (click)="tabActive=2;getTeamItems()">
                            Team
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="custom-tab-dark box-shadow-box">
            <div class="impactBlockOuter" *ngIf="tabActive == 3">
                    <div class="blog-listing school-bucket-tab-content">
                            <div class="isThisPost">
                                <div class="teal-radio-box">
                                        <input type="radio" [value]="4" id="timelineActive1" (click)="timelineNextPage=1;timelineActive=4;getTimelines()"
                                          [(ngModel)]="timelineActive" class="custom-control-input" name="post" checked />
                                        <label for="timelineActive1">All</label>
                                </div>

                                <div class="teal-radio-box">
                                        <input type="radio" [value]="5" id="timelineActive2" (click)="timelineNextPage=1;timelineActive=5;getTimelines()"
                                          [(ngModel)]="timelineActive" class="custom-control-input" name="post" />
                                        <label for="timelineActive2">volunteer</label>
                                    </div>

                                    <div class="teal-radio-box mr-0">
                                      <input type="radio" [value]="10" id="timelineActive3" (click)="timelineNextPage=1;timelineActive=10;getTimelines()"
                                        [(ngModel)]="timelineActive" class="custom-control-input" name="post" />
                                      <label for="timelineActive3">event</label>
                                  </div>
                            </div>

                            <div class="feeds-box-listing">
                                <div class="impactNoResult" *ngIf="timelines.length == 0">
                                    <h5 class="m-0">No data exists.</h5>
                                </div>
                                <div class="box TimeLineBox" *ngFor="let timeline of timelines"
                                    [ngClass]="{'no-image': timeline?.timeline_documents?.length == 0 }">
                                    <span class="event" *ngIf="timeline.post_type == 10">Event</span>
                                    <span class="event" *ngIf="timeline.post_type == 4">Update</span>
                                    <span class="event" *ngIf="timeline.post_type == 5">Position</span>
                                    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                                        [config]="communityConfig">
                                        <div class="communitySlide slide" ngxSlickItem *ngFor="let document of timeline.timeline_documents">
                                            <div class="communitySlideInner">
                                                <img src="{{document.document}}" alt="image" />
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                    <div class="content-box">
                                        <div class="img-box">
                                            <img src="{{initiative.display_picture_thumbnail? initiative.display_picture_thumbnail:initiative.display_picture? initiative.display_picture:'assets/images/default.svg'}}" alt="small-img" />
                                        </div>
                                        <div class="content">
                                            <h3 class="cursor-pointer" (click)="timelineDetailModal(timelineDetail, timeline)">{{timeline.title}}</h3>
                                            <p>{{initiative.title}}</p>
                                            <div class="date-box" *ngIf="timeline.date != null">
                                                <div class="month">
                                                    <span>{{timeline.date | date:'MMM'}}</span>
                                                </div>
                                                <div class="date">
                                                    <span>{{timeline.date | date:'d'}}</span>
                                                </div>
                                            </div>
                                            <p class="para cursor-pointer" (click)="timelineDetailModal(timelineDetail, timeline)" innerHtml="{{timeline.description | slice:0:100}}{{timeline.description.length > descriptionLimit ? '...' : ''}}"></p>
                                        </div>
                                        <div class="save-content">
                                            <div class="left-part">
                                                <button type="button" class="interest-btn" (click)="markInterested(timeline, timeline.post_type)"
                                                    [ngClass]="{'remove': timeline.interested_data?.interested}">
                                                    <img class="remove-img" src="/assets/images/interested-star-filled.svg"
                                                        alt="star-filled" />
                                                    <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                                    <span class="remove-text">remove</span>
                                                    <span>Interested</span>
                                                </button>
                                                <button type="button" class="other-btn" *ngIf="timeline.interested_data?.count > 0"
                                                  (click)="openModalOthers(templateOthers, timeline)">
                                                    {{timeline.interested_data?.count}} Others
                                                </button>
                                            </div>
                                            <div class="right-part">
                                                <div class="btn-group" dropdown>
                                                    <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                                                        aria-controls="dropdown-basic">
                                                        <img src="assets/images/dot-teal.svg" alt="more icon" />
                                                    </button>
                                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                                                        aria-labelledby="button-basic">
                                                        <li role="menuitem">
                                                            <div class="flag-data-box cursor-pointer" [routerLink]="['/profile', timeline.creator_info.user_id]">
                                                                <div class="name">
                                                                    <span class="dot">
                                                                        <img alt="profile-image"
                                                                            src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}" />
                                                                    </span>
                                                                    <span class="report-name">{{timeline.creator_info.name}}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeLineLoad mt-0" *ngIf="totalTimelines > 10 && totalTimelines > timelines.length">
                              <a href="javascript:void(0);" class="mb-4" (click)="getTimelines()">Load More</a>
                          </div>
                    </div>
                </div>
        <div class="impactBlockOuter" *ngIf="tabActive == 1">
            <div class="impactBlock backWhite pt-0">
                <div class="eachBlock" *ngIf="initiativeSdg.length > 0">
                  <div class="sdg-mob">
                    <div class="topHdg">
                      <h3 class="hdgName">
                        Sustainable Development Goals
                      </h3>
                    </div>
                    <ul class="sdg-list">
                      <li *ngFor="let sdgSelect of initiativeSdg">
                        <img alt="sdg-icon" class="sdg-icon" src="{{ sdgSelect.icon }}">
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="eachBlock" *ngIf="linkArray && linkArray.length > 0">
                    <div class="topHdg">
                        <h3 class="hdgName">
                            Links
                        </h3>
                    </div>
                    <div class="blockDes blockDesLinks">
                            <div class="image-with-del mr-0 d-flex" *ngFor="let link of linkArray">
                                    <a href="{{ link.link }}" target="_blank">
                                        <div class="img-box">
                                            <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}"
                                                class="icon">
                                        </div>
                                        <div class="dtl">
                                            {{ link.title }}
                                        </div>
                                    </a>
                                </div>
                    </div>
                </div>

                <div class="eachBlock" *ngIf="missionStatement">
                    <div class="topHdg">
                        <h3 class="hdgName">
                            Mission statement
                        </h3>
                    </div>
                    <div class="blockDes blockMission" [ngClass]="{ '': infoDiv1, showDetailContent: !infoDiv1}">
                        <p class="truncatedText" innerHtml="{{ missionStatement | slice:0:400 }}"></p>
                        <p class="fullText" innerHtml="{{ missionStatement  }}"></p>
                        <a *ngIf="(missionStatement + '').length > 400" href="javascript:void();" (click)="infoDiv1 = !infoDiv1" class="d-block text-center showMore">
                            <img src="assets/images/new-arrow.svg" alt="icon">
                        </a>
                    </div>
                </div>

                <div class="eachBlock" *ngIf="whoSupportUsArray && whoSupportUsArray.length > 0">
                    <div class="topHdg">
                        <h3 class="hdgName">
                                Supporters & Partners
                        </h3>
                    </div>
                    <div class="blockDes blockSupports">
                            <div class="image-with-del mr-0 d-flex" *ngFor="let item of whoSupportUsArray">
                                    <div class="img-box">
                                        <img src="{{item.thumbnail ? item.thumbnail :'assets/images/default.svg'}}" class="icon transform-image">
                                        {{ item.title }}
                                    </div>
                                    <div class="dtl no-center">

                                    </div>
                                </div>
                    </div>
                </div>

                <div class="eachBlock" *ngIf="whatWeImpact">
                    <div class="topHdg">
                        <h3 class="hdgName">
                            Who & What we Impact
                        </h3>
                    </div>
                    <div class="blockDes blockMission" [ngClass]="{ '': infoDiv2, showDetailContent: !infoDiv2}">
                        <p class="truncatedText" innerHtml="{{ whatWeImpact | slice:0:400 }}"></p>
                        <p class="fullText" innerHtml="{{ whatWeImpact }}"></p>
                        <a *ngIf="(whatWeImpact + '').length > 400" href="javascript:void();" (click)="infoDiv2 = !infoDiv2" class="d-block text-center showMore">
                            <img src="assets/images/new-arrow.svg" alt="icon">
                        </a>
                    </div>
                </div>

                <div class="eachBlock m-0" *ngIf="measurableArray && measurableArray.length > 0">
                    <div class="topHdg">
                        <h3 class="hdgName">
                            Measurables
                        </h3>
                    </div>
                    <div class="blockDes blockMeasurable">
                        <ul class="measurableList no-list" *ngIf="measurableArray && measurableArray.length > 0">
                            <li *ngFor="let measurableItem of measurableArray">
                                <h3>{{ measurableItem.description }}</h3>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="pt-4" *ngIf="goalsAndPlans">
                    <button (click)="goalsModal(goalsTemplate)" class="btn btn-plan">Goals and Plan</button>
                </div>
                <div class="impactNoResult" *ngIf="!hasData">
                    <h5 class="m-0">No data exists.</h5>
                </div>
            </div>
        </div>
        <div *ngIf="tabActive == 2">
            <div class="initiativeTeamBlock">
                <div class="initiativeTeamSlider mb-4 mb-md-5 pr-0 pl-0">
                <div class="d-flex align-items-center mb-3 withRadBlueName">
                    <h3 class="blueHdg">Team</h3>
                </div>
                <div class="search-box initiativeVolunteerAdd pt-0 pb-0">
                    <div class="left-part">
                        <div class="form-group">
                            <input type="text" #searchInp placeholder="Search Name" class="form-control" (keyup)="searchStudent($event)">
                        </div>
                    </div>
                    <div class="right-part">
                        <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                            <span>Invite members</span>
                        </a>
                    </div>
                  </div>
                <div class="initiativeTeamSlider mb-2 mb-md-12 initiativeTeamSliderMob35">
                  <div class="team-list-box">
                    <div class="impactNoResult" *ngIf="teamMembers.length == 0">
                        <h5 class="m-0">No data exists.</h5>
                      </div>
                      <div class="team-box" *ngFor="let member of teamMembers">
                          <div class="description-side">
                            <div class="img-box">
                                <img src="{{member.student_info.display_pic ? member.student_info.display_pic: 'assets/images/p-img.png'}}"
                                alt="profile" (click)="directToProfile(member)" [ngClass]="{'cursor-pointer': checkClick(member)}"/>
                            </div>
                            <div class="para-box">
                                <div class="relative">
                                    <h3>{{member.student_info.name}}</h3>
                                    <p *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                                        <span class="dot"></span>
                                        <span *ngIf="member.student_info.connection == 1">1st</span>
                                        <span *ngIf="member.student_info.connection == 2">2nd</span>
                                    </p>
                                </div>
                                <p *ngIf="member.position">{{member.position}}</p>
                                <p *ngIf="member.student_info.is_owner">Owner</p>
                            </div>
                          </div>
                          <div class="add-network" *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                            <button type="button" *ngIf="member.student_info.connection == 1" (click)="openModal(member, remNetworkModal)">
                                <img src="/assets/images/add-network-teal-icon.svg" alt="remove" />
                                Remove
                            </button>
                            <button type="button" *ngIf="member.student_info.connection == 2" (click)="openModal(member, networkModal)">
                                <img src="/assets/images/add-network-teal-icon.svg" alt="add-network" />
                                Add to Network
                            </button>
                        </div>
                      </div>
                  </div>
                  <div class="timeLineLoad mt-0" *ngIf="totalTeamMembers > 10 && totalTeamMembers > teamMembers.length">
                    <a href="javascript:void(0);" class="mb-4" (click)="getTeam()">Load More</a>
                  </div>
                </div>
                </div>
                <div class="initiativeVolunteerAdd pt-0 pb-0">
                    <div  class="topHdg">
                        <h3 class="hdgName">Volunteers </h3>
                    </div>
                </div>
                <div class="initiativeVolunteerAdd pt-0">
                  <div class="displayVolunteerList withDelIcon pt-0">
                    <div class="impactNoResult" *ngIf="volunteersList.length == 0">
                        <h5 class="m-0">No data exists.</h5>
                      </div>
                    <ul class="no-list">
                      <li *ngFor="let volunteer of volunteersList">{{ volunteer.first_name }} {{ volunteer.last_name | slice:0:1 }}.</li>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
    </div>

<ng-template #goalsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">goals and plan</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <p class="modalViewContent" innerHtml="{{ goalsAndPlans }}"></p>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
    </div>
</ng-template>


<ng-template #settingTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">SETTINGS</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="blockReportForm">
                <div class="d-flex align-items-center">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="report"
                            formControlName="report_initiative" (click)="reportReason();" />
                            <label class="custom-control-label" for="report">Report Initiative</label>
                        </div>
                    </div>
                </div>
                <div class="reportShow" *ngIf="reportUser">
                    <div class="form-group">
                        <label>Reason</label>
                        <select class="form-control custom-select" formControlName="report_reason">
                            <option value=1>Hate Speech</option>
                            <option value=2>Bullying and harrassment of yourself or others</option>
                            <option value=3>Direct threats</option>
                            <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)
                            </option>
                            <option value=5>Other</option>
                        </select>
                        <div class="error-message"
                            *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
                            This field is required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control"></textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">decline</button>
        <button class="btn btn-footer" (click)="blockUnblockUser(blockReportForm.valid)">save</button>
    </div>
</ng-template>
<ng-template #timelineDetail>
    <div class="modal-body new-body">
        <div class="create-box timeline">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
                <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg"
                    alt="arrow" />
            </button>
        </div>
        <div class="d-flex detail-comment-box scrollbar">
            <div class="timeLinesEach custom-design mt-0">
                <div class="feedDetailSlider" [ngClass]="{'no-image': timelineObj?.timeline_documents?.length == 0 }">
                    <span class="event" *ngIf="timelineObj.post_type == 10">Event</span>
                    <span class="event" *ngIf="timelineObj.post_type == 5">Position</span>
                    <span class="event" *ngIf="timelineObj.post_type == 4">Update</span>
                    <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
                        <div class="slide" ngxSlickItem *ngFor="let document of timelineObj.timeline_documents">
                            <div class="img-box">
                                <img src="{{document.document}}" alt="image">
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div class="feedDetailContent">
                    <div class="img-box">
                        <img src="{{initiative.display_picture_thumbnail? initiative.display_picture_thumbnail:initiative.display_picture? initiative.display_picture:'assets/images/default.svg'}}" alt="small-img" />
                    </div>
                    <h3>{{timelineObj.title}}</h3>
                    <p>{{initiative.title}}</p>
                    <div class="date-box" *ngIf="timelineObj.date != null">
                        <div class="month">
                            <span>{{timelineObj.date | date:'MMM'}}</span>
                        </div>
                        <div class="date">
                            <span>{{timelineObj.date | date:'d'}}</span>
                        </div>
                    </div>
                    <div class="save-content">
                        <div class="left-part">
                            <button type="button" class="interest-btn" (click)="markInterested(timelineObj, timelineObj.post_type)"
                                [ngClass]="{'remove': timelineObj.interested_data?.interested}">
                                <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                <span class="remove-text">remove</span>
                                <span>Interested</span>
                            </button>
                            <button type="button" class="other-btn" *ngIf="timelineObj.interested_data?.count > 0"
                                (click)="openModalOthers(templateOthers, timelineObj)">
                                {{timelineObj.interested_data?.count}} Others
                            </button>
                        </div>
                    </div>
                    <div class="details">
                        <h4>Details</h4>
                        <p innerHtml="{{timelineObj.description}}"></p>

                        <p class="metaLink" *ngIf="!checkExtraData(timelineObj)">
                            <a href="{{timelineObj.link}}" target="_blank">{{timelineObj.link}}</a>
                        </p>
                        <div class="singleTimelineDetail" *ngIf="checkExtraData(timelineObj)">
                            <div class="imageBox">
                                <img alt="timeline" class="timeLineImg"
                                src="{{timelineObj.metadata_image_link? timelineObj.metadata_image_link : 'assets/images/default.svg'}}" />
                            </div>
                            <div class="dtlOuter">
                                <h1>{{timelineObj.metadata_title}}</h1>
                                <a href="{{timelineObj.link}}" target="_blank">{{timelineObj.link}}</a>
                                <p>{{timelineObj.metadata_description}}</p>
                            </div>
                        </div>

                        <div class="posted-by">
                            <h4>Posted by</h4>
                            <div class="name-box cursor-pointer" routerLink="/profile/" (click)="modalRef.hide()">
                                <img src="{{timelineObj.creator_info.display_picture? timelineObj.creator_info.display_picture: 'assets/images/default.svg'}}"
                                    alt="image" />
                                <p>{{timelineObj.creator_info.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-comments [editFeedPostType]="timelineObj.post_type" [editFeedId]="timelineObj.id"></app-comments>
        </div>
    </div>
  </ng-template>
<ng-template #messageAlertTemplate>
    <div class="modal-body pt-5 pb-0">
        <p class="text-center m-0">Please sign in or sign up to view or message this person over ConnectUs.today</p>
    </div>
    <div class="modal-footer pb-5">
        <button class="btn btn-footer" (click)="modalRef.hide()">okay</button>
    </div>
</ng-template>

<ng-template #headerImages>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Header Image</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="headerOuter">
                    <img class="headerImg w-100" src="{{header_image}}">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateOthers>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interested</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
        </button>
      </div>
    <div class="modal-body">
        <ul class="follow-list scroll-activity scrollbar">
          <li *ngFor="let usr of interestedUsers">
            <div class="info-content">
              <img alt="profile-image" src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
              <div class="text">
                <p class="bold" [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}</p>
                <p>{{usr.education_info.school_name}}</p>
                <p class="small" *ngIf="usr.education_info.school">{{usr.education_info.start_date | date: 'yyyy'}} - {{usr.education_info.end_date | date: 'yyyy'}}</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
          <a href="javascript:void(0);" class="mb-4" (click)="getInterestedUsers()">Load More</a>
        </div>
    </div>
</ng-template>
<ng-template #networkModal>
    <div  class="modal-header">
      <h4  class="modal-title pull-left">Add to network</h4>
      <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-add-team [member]="selectedUser"></app-page-add-team>
    </div>
  </ng-template>
  <ng-template #remNetworkModal>
    <div  class="modal-header">
        <h4  class="modal-title pull-left">Remove from network</h4>
        <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-rem-team [member]="selectedUser"></app-page-rem-team>
    </div>
</ng-template>
<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm">
                <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                <p class="inviteCopySbNote">Join my network at {{initiativeTitle}} on ConnectUs.today by signing up using the following link: <strong>connectus.today/signin</strong></p>
                <div class="inviteBorder">
                    <div class="custom-tooltip copy-tooltip">
                        <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                        <div class="tooltip-data copied-tooltip" *ngIf="copyMessageBool">Message Copied</div>
                    </div>
                </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations"
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" *ngIf="!invitationSuccess" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="modalRef.hide()">Done</button>
    </div>
</ng-template>
