import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EventCalendarComponent } from './event-calendar/event-calendar.component'
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentComponent } from './student/student.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileStudentComponent } from './profile/profile-student/profile-student.component';
import { CarouselModule, ModalModule, BsDropdownModule, RatingModule } from 'ngx-bootstrap';
import { ProfileBusinessComponent } from './profile-business/profile-business.component';  
import { ProfileSchoolComponent } from './profile-school/profile-school.component'; 
import { StudentDashboardComponent } from './profile/student-dashboard/student-dashboard.component'; 
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SearchPageComponent } from './search/search-page/search-page.component';
import { NotificationsComponent } from './profile/notifications/notifications.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BaseService, httpServiceCreator } from './services/base.service';
import { MessageChatComponent } from './profile/message-chat/message-chat.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { AuthInterceptor } from './shared/_interceptors/auth.interceptor';
import { AppConfigService, appInitializer } from './services/app.config.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ParentVerificationComponent } from './parent-verification/parent-verification.component';
import { TabsModule } from 'ngx-bootstrap'; 
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AuthGuard } from './shared/_guards/auth.guard';
import { ProfileSettingComponent } from './profile/profile-setting/profile-setting.component';
import { NeedHelpComponent } from './need-help/need-help.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { EducationComponent } from './profile/education/education.component';
import { WorkExperienceComponent } from './profile/work-experience/work-experience.component';
import { ResourcesComponent } from './resources/resources.component';
import { VolunteerExperienceComponent } from './profile/volunteer-experience/volunteer-experience.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SchoolInvolvementComponent } from './profile/school-involvement/school-involvement.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { AzureMapsComponent } from './azure-maps/azure-maps.component';
import { TimeFormatPipe } from './shared/_pipes/timeConvert';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './shared/_interceptors/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { BusinessPageComponent } from './business-page/business-page.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InvitationSignupComponent } from './signup/invitation-signup/invitation-signup.component';
import { BusinessListingComponent } from './business-listing/business-listing.component';
import { ProfileHeaderComponent } from './profile/profile-header/profile-header.component';
import { PageSettingComponent } from './page/page-setting/page-setting.component';
import { PageHeaderComponent } from './page/page-header/page-header.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { CreateBusinessPageComponent } from './create-business-page/create-business-page.component';
import { CreateClubteamPageComponent } from './create-clubteam-page/create-clubteam-page.component';
import { ProfileVerificationComponent } from './profile-verification/profile-verification.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { PageExperiencesComponent } from './page/page-experiences/page-experiences.component';
import { BusinessProfileComponent } from './search/business-profile/business-profile.component';
import { StudentProfileComponent } from './search/student-profile/student-profile.component';
import { PageNotFoundComponent } from './error-pages/not-found/not-found.component';
import { StudentSearchComponent } from './search/student-search/student-search.component';
import { SchoolBucketComponent } from './school-bucket/school-bucket.component';
import { SearchProfilesComponent } from './search/search-profiles/search-profiles.component';
import { dayOfWeekAsString } from './services/daystostring';
import { NameSearchPipe } from './shared/_pipes/nameSearch';
import { FirstNameSearchPipe } from './services/firstNameSearch';
import { AboutUsComponent } from './about-us/about-us.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';
import { TermsPolicyComponent } from './terms-policy/terms-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PageEventCalendarComponent } from './page-event-calendar/page-event-calendar.component';
import { SearchHelpComponent } from './search/search-help/search-help.component';
import { LastNameSearchPipe } from './services/lastNameSearch';
import { VerifyExperienceComponent } from './verify-experience/verify-experience.component';
import { OthersComponent } from './why-signup/others/others.component';
import { StudentsComponent } from './why-signup/students/students.component';
import { SafePipe } from './shared/_pipes/safe.pipe';
import { ExtracurricularComponent } from './profile/extracurricular/extracurricular.component';
import { ExperiencesComponent } from './profile/experiences/experiences.component';
import { ClaimPageComponent } from './claim-page/claim-page.component';
import { InitiativesComponent } from './profile/initiatives/initiatives.component';
import { EditInitiativesComponent } from './profile/edit-initiatives/edit-initiatives.component';
import { ViewInitiativeComponent } from './search/view-initiative/view-initiative.component';
import { ClaimSchoolPageComponent } from './claim-page/claim-school-page/claim-school-page.component';
import { ClaimBusinessPageComponent } from './claim-page/claim-business-page/claim-business-page.component';
import { MoreAboutMeComponent } from './profile/more-about-me/more-about-me.component';
import { SearchInitiativeComponent } from './search/search-initiative/search-initiative.component';
import { EditMamComponent } from './profile/edit-mam/edit-mam.component';
import { ResumeComponent } from './profile/resume/resume.component';
import { PublicResumeComponent } from './public-resume/public-resume.component';
import { PublicExperienceComponent } from './public-experience/public-experience.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { ExperienceStatusComponent } from './profile/experience-status/experience-status.component';
import { ExperienceTeamComponent } from './profile/experience-team/experience-team.component';
import { AwardsCredentialsComponent } from './profile/awards-credentials/awards-credentials.component';
import { CanvasComponent } from './profile/canvas/canvas.component';
import { TagSearchComponent } from './profile/tag-search/tag-search.component';
import { TimelineComponent } from './profile-school/timeline/timeline.component';
import { StudentOnboardComponent } from './profile/student-onboard/student-onboard.component';
import { MyFeedsComponent } from './profile/my-feeds/my-feeds.component';
import { PageAddTeamComponent } from './page/page-add-team/page-add-team.component';
import { PageRemTeamComponent } from './page/page-rem-team/page-rem-team.component';
import { CommentsComponent } from './page/comments/comments.component';
import { MentionModule } from 'angular-mentions';
import { RepliesComponent } from './page/replies/replies.component';
import { PostDetailComponent } from './post-detail/post-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent, 
    HeaderComponent,
    StudentComponent,
    SidebarComponent,
    ProfileComponent,
    ProfileStudentComponent,
    ProfileBusinessComponent,  
    ProfileSchoolComponent,
    StudentDashboardComponent,
    SearchPageComponent,
    NotificationsComponent,
    MessageChatComponent,
    AccountVerificationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ParentVerificationComponent,
    EventCalendarComponent,
    ProfileSettingComponent,
    NeedHelpComponent,
    ContactUsComponent,
    EducationComponent,
    WorkExperienceComponent,
    ResourcesComponent,
    VolunteerExperienceComponent,
    SchoolInvolvementComponent,
    ContactUsFormComponent,
    AzureMapsComponent,
    TimeFormatPipe,
    LoaderComponent,
    BusinessPageComponent,
    UserProfileComponent,
    InvitationSignupComponent,
    BusinessListingComponent,
    ProfileHeaderComponent,
    PageSettingComponent,
    PageHeaderComponent,
    MentalHealthComponent,
    CreateBusinessPageComponent,
    CreateClubteamPageComponent,
    ProfileVerificationComponent,
    HelpPageComponent,
    PageExperiencesComponent,
    BusinessProfileComponent,
    StudentProfileComponent,
    PageNotFoundComponent,
    StudentSearchComponent,
    SchoolBucketComponent,
    SearchProfilesComponent,
    dayOfWeekAsString,
    NameSearchPipe,
    FirstNameSearchPipe,
    LastNameSearchPipe,
    AboutUsComponent,
    CommunityGuidelinesComponent,
    TermsPolicyComponent,
    PrivacyPolicyComponent,
    PageEventCalendarComponent,
    SearchHelpComponent,
    VerifyExperienceComponent,
    OthersComponent,
    StudentsComponent,
    SafePipe,
    ExtracurricularComponent,
    ExperiencesComponent,
    InitiativesComponent,
    EditInitiativesComponent,
    ViewInitiativeComponent,
    ClaimPageComponent,
    ClaimSchoolPageComponent,
    ClaimBusinessPageComponent,
    MoreAboutMeComponent,
    SearchInitiativeComponent,
    EditMamComponent,
    ResumeComponent,
    PublicResumeComponent,
    PublicExperienceComponent,
    LandingComponent,
    SigninComponent,
    ExperienceStatusComponent,
    ExperienceTeamComponent,
    AwardsCredentialsComponent,
    CanvasComponent,
    TagSearchComponent,
    TimelineComponent,
    StudentOnboardComponent,
    MyFeedsComponent,
    PageAddTeamComponent,
    PageRemTeamComponent,
    CommentsComponent,
    RepliesComponent,
    PostDetailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    RatingModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BsDropdownModule.forRoot(), 
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSlimScrollModule,
    NgbModule,
    TabsModule.forRoot(),
    SlickCarouselModule,
    CKEditorModule,
    InfiniteScrollModule,
    QRCodeModule,
    MentionModule,
  ],
  providers: [
    AppConfigService,
    AuthGuard,
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AppConfigService], multi: true },
    { provide: BaseService, useFactory: httpServiceCreator, deps: [HttpClient] },
    {      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: true,
        gridOpacity: '1', barOpacity: '0.5',
        gridBackground: '#fff',
        gridWidth: '6',
        gridMargin: '2px 2px',
        barBackground: '#335175',
        barWidth: '6',
        barMargin: '2px 2px'
      }
    },
  ],
  exports: [
    TimeFormatPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
