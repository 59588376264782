<app-student-onboard *ngIf="isFirstLogin" (firstLogin)="firstLogin($event)" [user]="user"></app-student-onboard>

<section *ngIf="!isFirstLogin" class="dashboardLanding home-page dashboardLandingTopSpace">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-xl-3 leftWrap d-md-block d-none">
        <div class="whiteWrapEach" *ngIf="user.user_role == '2' || totalPageCount > 0">
          <div class="forProfile">
            <div class="profileThumb">
              <img *ngIf="profileImageHeader_name" src="{{profileImageHeader}}" class="profileImg" alt=""
                (error)="getImageUrl()">
              <img *ngIf="!profileImageHeader_name" src="assets/images/p-img.png" class="profileImg" alt="">
            </div>

            <div class="profileDes">
              <h1>{{user.first_name}} {{user.last_name}}</h1>
              <a class="btn btnBlue" *ngIf="user.user_role == '2'" [routerLink]="['/profile']">Go to Resume</a>
            </div>
            <div class="btn-group" *ngIf="user.user_role == '2'">
              <button type="button">
                <img src="/assets/images/network-icon.svg" alt="network" />
                My Network
                <span>{{teamCount}}</span>
              </button>
              <button type="button" (click)="openInterestedFeedModal(intsdFeedsTemplate)">
                <img src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                Interested
                <span>{{totalIntFeedsCount}}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach" *ngIf="totalFollowedCount > 0">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">Following ({{totalFollowedCount}})</h3>
              <a href="javascript:void();" (click)="openModalFollow(followedModal)">View All</a>
            </div>
            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer" *ngFor="let pagee of followedPages | slice:0:2"
                (click)="directToPageIni(pagee)">
                <div class="leftImage">
                  <img class="pageThumb" alt="page-image"
                    src="{{pagee.page_info.display_picture? pagee.page_info.display_picture : 'assets/images/p-img.png'}}" />
                </div>
                <div class="rightText">
                  <h3>{{ pagee.page_info.title }}</h3>
                  <p *ngIf="pagee.page_info.school_name">{{ pagee.page_info.school_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach" *ngIf="totalPageCount > 0">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">my pages ({{totalPageCount}})</h3>
              <a href="javascript:void();" (click)="openBusiness(businessModal)">View All</a>
            </div>

            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer" *ngFor="let pagee of pageslist | slice:0:2"
                (click)="OpenPageDashboard(pagee)">
                <div class="leftImage">
                  <img class="pageThumb" src="{{pagee.display_pic? pagee.display_pic : 'assets/images/p-img.png'}}" />
                </div>
                <div class="rightText">
                  <h3>{{ pagee.page_info.title }}</h3>
                  <p *ngIf="[1,2].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.address">
                    {{ pagee.page_info.address.split(',')[0] }}</p>
                  <p *ngIf="[3,4].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.school_name">
                    {{ pagee.page_info.school_name }}</p>
                  <p *ngIf="pagee.page_info.page_type === 5">{{ pagee.page_info.state }}, {{ pagee.page_info.city }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="whiteWrapEach">
          <div class="pagesHdgWrap">
            <h3 class="">resources</h3>
          </div>

          <div class="createResourceEach">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Initiative</h3>
              <a href="javascript:void();" (click)="createInitiative(createInitiativeTemplate)">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>

          <div class="createResourceEach" *ngIf="user.age_group > 1">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Organization Page</h3>
              <a href="javascript:void();" [routerLink]="['/createbusinesspage']">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>

          <div class="resourceWhatIs">
            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/startIni.png">
              <a href="https://www.cuclub.today/" target="_blank">
                <p>CU 4 Community</p>
              </a>
            </div>

            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/whatIni.png">
              <a href="https://www.cuclub.today/cu-clubs" target="_blank">
                <p>What is an Initiative?</p>
              </a>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-8 col-xl-9 rightWrap">
        <div class="rightWrapInner d-md-block d-none">
          <div class="searchContent">
            <div class="eachHdg">
              <h3 class="">Search & Follow</h3>
            </div>

            <div class="searchBy">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                  <div class="eachSearchByInner" [routerLink]="['/search-pages']">
                    <img alt="searchBg" class="searchBg" src="assets/images/pagesSearch.jpg">
                    <div class="overlayName">
                      <p>Schools</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 eachSearchBy"
                  *ngIf="verifiers.length >= 3 || user.approval_status && user.user_role == '2'">
                  <div class="eachSearchByInner forObjectTop" [routerLink]="['/student-search']">
                    <img alt="searchBg" class="searchBg" src="assets/images/studentsSearch.jpg">
                    <div class="overlayName">
                      <p>Students</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                  <div class="eachSearchByInner" [routerLink]="['/search-initiative']">
                    <img alt="searchBg" class="searchBg" src="assets/images/initSearch.jpeg">
                    <div class="overlayName">
                      <p>Initiatives</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="rightWrapInner custom-padding">
          <p class="alert alert-success mb-4" *ngIf="showSuccessMsg">Post created successfully </p>

          <div class="tab-buttons">
            <button type="button" [ngClass]="{'active': tabActive == 1}" (click)="getFollowfeeds();tabActive = 1">
              Following
            </button>
            <button type="button" class="active" [ngClass]="{'active': tabActive == 2}" (click)="tabActive = 2">
              Your School
            </button>
          </div>

          <div class="button-heading">
            <div class="create-btn-group">
              <button type="button" (click)="openCreatePostModal(schPostTemplate)"
                [ngClass]="{'disabled': schInvPages.length === 0}"><img src="/assets/images/School Post.svg"
                  alt="edit" /></button>
              <div class="info-div create-tooltip initiative-tooltip">
                <p class="m-0">Hey there! You have to tag or create a club/team page to post here.</p>
              </div>
              <button type="button" (click)="openCreatePostModal(iniPostTemplate, true, true)"
                [ngClass]="{'disabled': initiatives.length === 0}"><img src="/assets/images/Initiative Post.svg"
                  alt="edit" /></button>
              <div class="info-div create-tooltip">
                <p class="m-0">Hey there! You have to create an initiative page to post here.</p>
              </div>
            </div>
            <div class="right-info">
              <!--Info Button-->
              <button type="button" class="info-btn" (click)="infoDiv = !infoDiv">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25"
                  height="25.342" viewBox="0 0 25 25.342">
                  <defs>
                    <ellipse id="ellipse-1" cx="12.5" cy="12.5" rx="12.5" ry="12.5" />
                    <mask id="mask-2" x="0" y="0" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                      <rect width="25" height="25.342" x="0" y="0" fill="black" />
                      <use fill="white" xlink:href="#ellipse-1" />
                    </mask>
                  </defs>
                  <g>
                    <text font-size="0" style="white-space:pre">
                      <tspan x="10" y="18.342" fill="rgb(51,81,117)" font-family="Arial" font-size="18"
                        font-weight="400" style="white-space:pre;text-transform:none" letter-spacing=".277">i</tspan>
                    </text>
                    <use fill-opacity="0" stroke="rgb(51,81,117)" stroke-linecap="butt" stroke-linejoin="miter"
                      stroke-width="2" mask="url(#mask-2)" xlink:href="#ellipse-1" />
                  </g>
                </svg>
              </button>
              <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                <div class="info-div">
                  <p class="m-0 d-md-block d-none"><b>Your School</b> feed shows all posts made by clubs, teams and
                    initiatives at your school. Follow the pages you like the best and see them under your
                    <b>Following</b> feed! You may save posts by clicking the <b>‘I’m interested’</b> button, and view
                    them under your name at the top left of this page.</p>
                  <p class="m-0 d-md-none d-block"><b>Your School</b> feed shows all posts made by clubs, teams and
                    initiatives at your school. Follow the pages you like the best and see them under your
                    <b>Following</b> feed! You may save posts by clicking the <b>‘I’m interested’</b> button, and view
                    them under your profile.</p>
                </div>
              </div>
              <!--Info Button-->
            </div>
          </div>


          <div class="blog-listing d-none" [ngClass]="{'d-block': tabActive == 1}">
            <p class="no-item-present" *ngIf="feeds.length == 0">
              Follow your current education to receive updates
            </p>
            <div class="scroll-activity scrollbar" infiniteScroll [infiniteScrollDistance]="2"
              (scrolled)="onScroll($event)">
              <div class="feeds-box-listing">
                <div class="box" *ngFor="let feed of feeds" [ngClass]="{'no-image': feed?.documents?.length == 0 }">
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 1 || feed.post_info.post_type === 8 || feed.post_info.post_type === 4">Update</span>
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10">Event</span>
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 2 || feed.post_info.post_type === 5">Position</span>
                  <span class="event interested" *ngIf="feed.post_info.post_type === 6">
                    <img src="assets/images/blub.svg" alt="blub" />Idea
                  </span>
                  <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                    [config]="communityConfig">
                    <div class="communitySlide slide" ngxSlickItem *ngFor="let document of feed.documents">
                      <div class="communitySlideInner">
                        <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                        <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg"
                          alt="pdf-image" />
                      </div>
                    </div>
                  </ngx-slick-carousel>
                  <div class="content-box">
                    <div class="img-box">
                      <img class="cursor-pointer" (click)="directToPage(feed)"
                        src="{{feed.display_picture? feed.display_picture: 'assets/images/default.svg'}}"
                        alt="small-img" />
                    </div>
                    <div class="content">
                      <h3 class="cursor-pointer" (click)="feedDetailModal(feedDetail, feed)">{{feed.title}}</h3>
                      <p>{{feed.page_title}} • Expires
                        <span *ngIf="feed.days_left > 0">in {{feed.days_left}} days</span>
                        <span *ngIf="feed.days_left === 0">Today</span>
                      </p>
                      <div class="date-box"
                        *ngIf="(feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10 || feed.post_info.post_type === 5) && (feed.post_info.event_date != null)">
                        <div class="month">
                          <span>{{feed.post_info.event_date | date:'MMM'}}</span>
                        </div>
                        <div class="date">
                          <span>{{feed.post_info.event_date | date:'d'}}</span>
                        </div>
                      </div>
                      <div class="date-box"
                      *ngIf="(feed.post_info.post_type !== 3 || feed.post_info.post_type !== 7 || feed.post_info.post_type !== 5 || feed.post_info.post_type !== 10) && (feed.post_info.date != null)">
                      <div class="month">
                        <span>{{feed.post_info.date | date:'MMM'}}</span>
                      </div>
                      <div class="date">
                        <span>{{feed.post_info.date | date:'d'}}</span>
                      </div>
                    </div>
                      <p class="para cursor-pointer" (click)="feedDetailModal(feedDetail, feed)"
                        innerHtml="{{feed.description | slice:0:100}}{{feed.description.length > descriptionLimit ? '...' : ''}}"></p>
                    </div>
                    <div class="save-content">
                      <div class="left-part">
                        <button type="button" class="interest-btn" (click)="markInterested(feed)"
                          [ngClass]="{'remove': feed.interested_data?.interested}">
                          <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                          <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                          <span class="remove-text">remove</span>
                          <span>Interested</span>
                        </button>
                        <button type="button" class="other-btn" *ngIf="feed.interested_data.count > 0"
                          (click)="openModalOthers(templateOthers, feed)">
                          {{feed.interested_data.count}} Others
                        </button>
                        <button type="button" class="interest-btn comments-btn"
                          (click)="feedDetailModal(feedDetail, feed)">
                          <img src="/assets/images/comment-icon.svg" alt="comment-icon" />
                          <span *ngIf="feed.post_info.comments_count > 0">{{feed.post_info.comments_count}}</span>
                        </button>
                        <button type="button" class="interest-btn comments-btn" (click)="copyUrl(feed)">
                          <img class="mr-0" src="/assets/images/copylink.svg" alt="copylink" />
                        </button>


                        <div class="custom-tooltip copy-tooltip">
                        <div class="tooltip-data copied-tooltip link-copied" *ngIf="(shareUrlIdCheck == feed.id) && copyMessageBool">Link Copied</div>
                        </div>
                      </div>
                      <div class="right-part">
                        <div class="btn-group" dropdown>
                          <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                            aria-controls="dropdown-basic">
                            <img src="assets/images/dot-teal.svg" alt="more icon" />
                          </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                            aria-labelledby="button-basic">
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer" (click)="reportModal(reportTemplate, feed)">
                                <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                                <span>Report Post</span>
                              </div>
                            </li>
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer" (click)="directToProfile(feed)">
                                <div class="name">
                                  <span class="dot">
                                    <img alt="profile-image"
                                      src="{{feed.post_info.creator_pic? feed.post_info.creator_pic : 'assets/images/default.svg'}}" />
                                  </span>
                                  <span class="report-name">{{feed.post_info.creator_name}}</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="totalFeedsCount > 10 && totalFeedsCount > feeds.length"
              class="next-listing text-center mt-4 pb-4 timeLineLoad d-lg-block d-none">
              <a href="javascript:void(0);" (click)="getFeeds()">Load More</a>
            </div>
          </div>

          <div class="blog-listing d-none" [ngClass]="{'d-block': tabActive == 2}">
            <p class="no-item-present" *ngIf="schoolFeeds.length == 0">
              Please add your current education to your resume to view all posts!
            </p>

            <div class="this-week-box" *ngIf="weekEvntsCount > 0">
                <div class="heading-box">
                  <h2>This Week</h2>
                  <a href="javascript:void(0);" (click)="openWeekModal(weekTemplate)">
                    View All
                  </a>
                </div>
                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel" [config]="weekConfig">
                  <div class="communitySlide slide" ngxSlickItem (click)="feedDetailModal(feedDetail, weekData)" *ngFor="let weekData of weekEvntData" >
                    <div class="date-box">
                      <div class="month">
                        <span>{{weekData.post_info.event_date | date:'MMMM d'}}</span>
                      </div>
                      <div class="date">
                        <span>
                          {{weekData.title}}
                        </span>
                      </div>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>

            <div class="scroll-activity scrollbar" infiniteScroll [infiniteScrollDistance]="2"
              (scrolled)="onScroll($event)">
              <div class="feeds-box-listing">
                <div class="box" *ngFor="let feed of schoolFeeds"
                  [ngClass]="{'no-image': feed?.documents?.length == 0 }">
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 1 || feed.post_info.post_type === 8 || feed.post_info.post_type === 4">Update</span>
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10">Event</span>
                  <span class="event"
                    *ngIf="feed.post_info.post_type === 2 || feed.post_info.post_type === 5">Position</span>
                  <span class="event interested" *ngIf="feed.post_info.post_type === 6">
                    <img src="assets/images/blub.svg" alt="blub" />Idea
                  </span>
                  <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                    [config]="communityConfig">
                    <div class="communitySlide slide" ngxSlickItem *ngFor="let document of feed.documents">
                      <div class="communitySlideInner">
                        <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                        <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg"
                          alt="pdf-image" />
                      </div>
                    </div>
                  </ngx-slick-carousel>
                  <div class="content-box">
                    <div class="img-box">
                      <img class="cursor-pointer" (click)="directToPage(feed)"
                        src="{{feed.display_picture? feed.display_picture: 'assets/images/default.svg'}}"
                        alt="small-img" />
                      <button *ngIf="showBtn(feed)" type="button" class="follow-plus-btn new-follow-btn"
                        (click)="followPage(feed)">
                        <img src="/assets/images/follow-plus-icon.svg" alt="plus-icon" />
                      </button>
                    </div>
                    <div class="content">
                      <h3 class="cursor-pointer" (click)="feedDetailModal(feedDetail, feed)">{{feed.title}}</h3>
                      <p>{{feed.page_title}} • Expires
                        <span *ngIf="feed.days_left > 0">in {{feed.days_left}} days</span>
                        <span *ngIf="feed.days_left === 0">Today</span>
                      </p>
                      <div class="date-box"
                        *ngIf="[3,5,6,7,8,10].includes(feed.post_info.post_type) && (feed.post_info.event_date != null)">
                        <div class="month">
                          <span>{{feed.post_info.event_date | date:'MMM'}}</span>
                        </div>
                        <div class="date">
                          <span>{{feed.post_info.event_date | date:'d'}}</span>
                        </div>
                      </div>
                      <div class="date-box"
                      *ngIf="[1,2,4].includes(feed.post_info.post_type) && (feed.post_info.date != null)">
                      <div class="month">
                        <span>{{feed.post_info.date | date:'MMM'}}</span>
                      </div>
                      <div class="date">
                        <span>{{feed.post_info.date | date:'d'}}</span>
                      </div>
                    </div>
                      <p class="para cursor-pointer" (click)="feedDetailModal(feedDetail, feed)"
                        innerHtml="{{feed.description | slice:0:100}}{{feed.description.length > descriptionLimit ? '...' : ''}}"></p>
                    </div>
                    <div class="save-content">
                      <div class="left-part">
                        <button type="button" class="interest-btn" (click)="markInterested(feed)"
                          [ngClass]="{'remove': feed.interested_data?.interested}">
                          <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                          <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                          <span class="remove-text">remove</span>
                          <span>Interested</span>
                        </button>
                        <button type="button" class="other-btn" *ngIf="feed.interested_data.count > 0"
                          (click)="openModalOthers(templateOthers, feed)">
                          {{feed.interested_data.count}} Others
                        </button>
                        <button type="button" class="interest-btn comments-btn"
                          (click)="feedDetailModal(feedDetail, feed)">
                          <img src="/assets/images/comment-icon.svg" alt="comment-icon" />
                          <span *ngIf="feed.post_info.comments_count > 0">{{feed.post_info.comments_count}}</span>
                        </button>
                        <button type="button" class="interest-btn comments-btn" (click)="copyUrl(feed)">
                          <img class="mr-0" src="/assets/images/copylink.svg" alt="copylink" />
                        </button>


                        <div class="custom-tooltip copy-tooltip">
                        <div class="tooltip-data copied-tooltip link-copied" *ngIf="(shareUrlIdCheck == feed.id) && copyMessageBool">Link Copied</div>
                        </div>

                      </div>
                      <div class="right-part">
                        <div class="btn-group" dropdown>
                          <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                            aria-controls="dropdown-basic">
                            <img src="assets/images/dot-teal.svg" alt="more icon" />
                          </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                            aria-labelledby="button-basic">
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer" (click)="reportModal(reportTemplate, feed)">
                                <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                                <span>Report Post</span>
                              </div>
                            </li>
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer" (click)="directToProfile(feed)">
                                <div class="name">
                                  <span class="dot">
                                    <img alt="profile-image"
                                      src="{{feed.post_info.creator_pic? feed.post_info.creator_pic : 'assets/images/default.svg'}}" />
                                  </span>
                                  <span class="report-name">{{feed.post_info.creator_name}}</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="totalSchFeedsCount > 10 && totalSchFeedsCount > schoolFeeds.length"
              class="next-listing text-center mt-4 pb-4 timeLineLoad d-lg-block d-none">
              <a href="javascript:void(0);" (click)="getSchFeeds()">Load More</a>
            </div>
          </div>
        </div>

        <div class="rightWrapInner new-page" *ngIf="tabActive == 2 && totalNewPagesCount > 0">
          <div class="eachHdg">
            <h3 class="mb-0">New Page(s)</h3>
          </div>
          <div class="blog-listing scroll-activity scrollbar">
            <div class="blog-box" *ngFor="let newPage of newPages">
              <div class="img-box">
                <img alt="profile-image" (click)="checkIfSenior(newPage, messageAlertTemplate)" class="cursor-pointer"
                  src="{{newPage.post_info.display_picture? newPage.post_info.display_picture : 'assets/images/default.svg'}}">
                <button *ngIf="showBtn(newPage)" type="button" class="follow-plus-btn" (click)="followPage(newPage)">
                  <img src="/assets/images/follow-plus-icon.svg" alt="plus-icon" />
                </button>
              </div>
              <div class="content-box d-flex align-items-center">
                <p class="bold cursor-pointer">
                  {{newPage.post_info.title}}
                  <span class="d-inline-flex pl-0">
                    <span *ngIf="newPage.post_info.page_type === 3">Club</span>
                    <span *ngIf="newPage.post_info.page_type === 4">Team</span>
                    <span *ngIf="newPage.post_info.page_type === 5">Initiative</span>
                    <span>• {{newPage.post_info.school_name}}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="totalNewPagesCount > 20 && totalNewPagesCount > newPages.length"
            class="next-listing text-center mt-4 pb-4 timeLineLoad">
            <a href="javascript:void(0);" (click)="getNewPageNotif()">Load More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #businessModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">All Pages</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body all-pages-body">
    <div class="modal-box">
      <div class="content-section pl-md-3 pr-md-3 pt-3 pb-3 dashBoardAllPgaes">
        <div class="profile-div scroll-activity scrollbar">
          <div class="profile-inner mb-3" *ngFor="let pagee of pageslist">
            <div class="box-div inner-exp"
              [ngClass]="{'business-border': pagee.page_type == 1,'volunteer-border': pagee.page_type == 2,'school-border': [3,4].indexOf(pagee.page_type) > -1}">
              <div class="experience-div media">
                <img src="{{pagee.display_pic? pagee.display_pic : 'assets/images/p-img.png'}}" />
                <div class="media-body ml-2 mt-1">
                  <h4>{{pagee.page_info.title}}
                    <span
                      *ngIf="[1,2].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.address">{{pagee.page_info.address.split(',')[0]}}</span>
                    <span
                      *ngIf="[3,4].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.school_name">{{pagee.page_info.school_name}}</span>
                    <span *ngIf="pagee.page_info.page_type === 5">{{ pagee.page_info.state }},
                      {{ pagee.page_info.city }}</span>
                  </h4>
                  <button class="common-btn ghost btnLightGrey" (click)="OpenPage(pagee);modalRef.hide()">Go to
                    Page</button>
                  <button *ngIf="pagee.page_info.page_type !== 5" class="common-btn ghost ml-2 btnBrightGrey"
                    (click)="OpenPageSettings(pagee);modalRef.hide()">Go to Settings</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="timeLineLoad pt-3 blue" *ngIf="totalPageCount > 10 && totalPageCount > pageslist.length">
          <a href="javascript:void(0);" (click)="getAllPages()">Load More</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="onBoard-overlay noticeOverlay" *ngIf="user.age_group_updated">
  <div class="onBoard-div">
    <div class="noticeDiv" *ngIf="stepOne">
      <h3>CONGRATS, CONNECTUS.TODAY GRAD!</h3>
      <p>
        Every year, on April 21, we take all of the students who will be turning 14 within the year and graduate
        them to a Full-Access User (a.k.a. users who have full access to all features within our Platform). We
        have noticed that you are turning 14 years old this year, so you, too, will be able to use the full
        site!

      </p>
      <p>
        Before using ConnectUs.today as a Full-Access User, we must ask that you Read and Accept the Terms of
        Use and Privacy Policy again with a Parent/Guardian, so that you understand how your Terms and Privacy
        as a user is changing.
      </p>
      <div class="text-center">
        <button class="common-btn" (click)="showTwo();">Next</button>
      </div>
    </div>
    <div class="noticeDiv" *ngIf="stepTwo">
      <h3>TERMS OF USE / PRIVACY POLICY CLICKWRAP</h3>
      <p>
        Please Read and Accept the following Terms of Use and Privacy Policy with a Parent/Guardian, so that you
        understand how your Terms and Privacy as a user is changing. If you do not Accept, you will only have
        access to Account Settings where you may either Delete your Account or Read through the Terms and
        Privacy Policy to Accept.
      </p>
      <form [formGroup]="termsAcceptForm">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
              (change)="onCheckboxChange($event)">
            <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
              Policy</label>
          </div>
          <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
            This field is required.
          </div>
        </div>
      </form>
      <div class="text-center">
        <button class="common-btn ghost mr-3" (click)="showThree();">Logout</button>
        <button class="common-btn" (click)="submitTermsAcceptForm()">Next</button>
      </div>
    </div>
    <div class="noticeDiv" *ngIf="stepThree">
      <h3>YOU ARE ABOUT TO LOG OUT!</h3>
      <h3 class="text-left">PLEASE NOTE</h3>
      <p>
        If you Log Out, you understand that you will only have Access to your Account Settings to either Review
        and Accept the Terms of Use and Privacy Policy, or to Delete your Account.</p>
      <h3 class="text-left">
        YOU WILL NOT HAVE ACCESS TO THE REST OF YOUR ACCOUNT UNTIL YOU ACCEPT THE TERMS OF USE AND PRIVACY
        POLICY.
      </h3>
      <p>
        Click Log Out to continue, or click the Back button to return to the previous screen.
      </p>
      <form [formGroup]="termsAcceptForm">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
              (change)="onCheckboxChange($event)">
            <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
              Policy</label>
          </div>
          <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
            This field is required.
          </div>
        </div>
      </form>
      <div class="text-center">
        <button class="common-btn ghost mr-3" (click)="goBack()">Go Back</button>
        <button class="common-btn" (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>

<ng-template #createInitiativeTemplate>
  <div class="modal-body new-body">
    <div class="modal-box">
      <div class="timeLineBlock">
        <div class="create-box">
          <h4 class="modal-title pull-left custom-h4">Create an Initiative</h4>
          <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
          </button>
        </div>
        <form [formGroup]="initiativeForm" novalidate>
          <div class="media">
            <div class="imgSec">
              <img alt="image" class="for-preview-image"
                src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
            </div>
            <div class="media-body ml-3">
              <div class="form-group">
                <input type="file" placeholder="Type Here" class="fileUpload" (change)="coverImageUpload($event)">
                <span class="fileText cursor-pointer">Select new photo</span>
                <div *ngIf="coverImageUploadError" class="error-message">
                  {{coverImageUploadMessage}}
                </div>
                <div *ngIf="coverImageUploadSizeError" class="error-message">
                  {{coverImageUploadSizeMessage}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Title of Initiative" formControlName="title">
                <div class="error-message"
                  *ngIf="initiativeForm.get('title').hasError('required') && (initiativeForm.get('title').dirty || initiativeForm.get('title').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="initiativeForm.get('title').hasError('maxlength') && (initiativeForm.get('title').dirty || initiativeForm.get('title').touched)">
                  Max 30 characters allowed.
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="schools.length > 0">
            <div class="col-12">
              <div class="form-group">
                <select class="custom-select" [(ngModel)]="school_id" [ngModelOptions]="{standalone: true}">
                  <option value="0" selected>Select School</option>
                  <option *ngFor="let school of schools" value="{{school.id}}">{{school.school_name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="City" formControlName="city">
                <div class="error-message"
                  *ngIf="initiativeForm.get('city').hasError('required') && (initiativeForm.get('city').dirty || initiativeForm.get('city').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="initiativeForm.get('city').hasError('maxlength') && (initiativeForm.get('city').dirty || initiativeForm.get('city').touched)">
                  Max 30 characters allowed.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Province/State" formControlName="state">
                <div class="error-message"
                  *ngIf="initiativeForm.get('state').hasError('required') && (initiativeForm.get('state').dirty || initiativeForm.get('state').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="initiativeForm.get('state').hasError('maxlength') && (initiativeForm.get('state').dirty || initiativeForm.get('state').touched)">
                  Max 30 characters allowed.
                </div>
              </div>
            </div>
          </div>
          <p class="m-0">
            <strong>Note:</strong> you will be able to edit all this information later on
          </p>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer new-btns">
    <button class="btn btn-footer" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-footer"
      (click)="addInitiative(initiativeForm.valid, initiativeSuccessTemplate)">Create</button>
  </div>
</ng-template>
<ng-template #initiativeSuccessTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create an Initiative</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-box">
      <p class="text-center m-0">
        Congrats! Your initiative page has been created. Access it through your <br />
        <strong>Profile > Experiences</strong>
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-footer" (click)="modalRef.hide()">Got it</button>
  </div>
</ng-template>
<ng-template #reportTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Report Post</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="reportModalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-box">
      <form [formGroup]="blockReportForm">
        <div class="reportShow">
          <div class="form-group">
            <label>Reason</label>
            <select class="form-control custom-select" formControlName="report_reason">
              <option value=1>Hate Speech</option>
              <option value=2>Bullying and harrassment of yourself or others</option>
              <option value=3>Direct threats</option>
              <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)</option>
              <option value=5>Other</option>
            </select>
          </div>
          <div class="error-message"
            *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
            This field is required.
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea class="form-control" formControlName="report_description"></textarea>
            <div class="error-message"
              *ngIf="blockReportForm.get('report_description').hasError('maxlength') && (blockReportForm.get('report_description').dirty || blockReportForm.get('report_description').touched)">
              Max 1000 characters allowed.
            </div>
          </div>
        </div>
        <h4 class="note-info"><strong>Note:</strong> Once submitted, this post will disappear from the feed. Our Support
          team will get notified and take a look. Thank you for keeping our community safe!</h4>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-footer cancel-btn" (click)="reportModalRef.hide()">
      Cancel
    </button>
    <button class="btn btn-footer" (click)="reportFeed(blockReportForm.valid)">
      Submit
    </button>
  </div>
</ng-template>
<ng-template #schPostTemplate>
  <div class="modal-body new-body">
    <div class="modal-box">
      <div class="timeLineBlock">
        <div class="create-box">
          <h4 class="modal-title pull-left">Create a school post</h4>
          <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
          </button>
        </div>
        <div class="allTimeLinesOuter">
          <div class="addTimeFrame">
            <form [formGroup]="timelineForm" novalidate>
              <div class="form-group">
                <select class="custom-select" [(ngModel)]="selectedSchClubTeam" [ngModelOptions]="{standalone: true}"
                  (change)="checkSchool()">
                  <option value="null" disabled selected>Select School/Club/Team</option>
                  <option *ngFor="let schInvPage of schInvPages" [ngValue]="schInvPage">
                    {{schInvPage.page_info.title}}
                  </option>
                </select>
                <div class="error-message" *ngIf="selectedSchClubTeam === null && submitted">
                  This field is required.
                </div>
              </div>

              <div class="isThisPost">
                <span class="">Is this post a(n):</span>
                <div class="radioPostBtnOuter" *ngIf="!isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="1" id="end" (click)="radioActive=1;changeType()" name="post"
                      [checked]="radioActive === 1" />
                    <label for="end">Update / Reminder</label>
                  </div>
                </div>
                <div class="radioPostBtnOuter" *ngIf="!isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="2" id="end2" (click)="radioActive=2;changeType()" name="post"
                      [checked]="radioActive === 2" />
                    <label for="end2">Position</label>
                  </div>
                </div>
                <div class="radioPostBtnOuter" *ngIf="!isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="3" id="end3" (click)="radioActive=3;changeType()" name="post"
                      [checked]="radioActive === 3" />
                    <label for="end3">Event</label>
                  </div>
                </div>
                <div class="radioPostBtnOuter" *ngIf="isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="8" id="end6" (click)="radioActive=8;changeType()" name="post"
                      [checked]="radioActive === 8" />
                    <label for="end6">Update / Reminder</label>
                  </div>
                </div>
                <div class="radioPostBtnOuter" *ngIf="isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="6" id="end4" (click)="radioActive=6;changeType()" name="post"
                      [checked]="radioActive === 6" />
                    <label for="end4">Idea</label>
                  </div>
                </div>
                <div class="radioPostBtnOuter" *ngIf="isSchool">
                  <div class="teal-radio-box">
                    <input type="radio" value="7" id="end5" (click)="radioActive=7;changeType()" name="post"
                      [checked]="radioActive === 7" />
                    <label for="end5">Event</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="filesTimeLine custom-file-upload mr-2 mb-2" *ngIf="timelineDocs.length == 0">
                  <div class="file-box">
                    <input type="file" class="uploadInput" multiple="" (change)="coverImageUpload($event, true)">
                    <div class="file-content">
                      <img src="/assets/images/teal-add-icon.svg" alt="plus-icon" />
                      <p>Select photos (up to 5)</p>
                    </div>
                  </div>
                </div>
                <div class="forUploadedImages scrollbarHorizontal">
                  <div class="forUploadedImagesInner mr-3 mb-2" *ngFor="let timelineDoc of timelineDocs; index as i">
                    <img alt="icon" class="uploadedPreview" src="{{timelineDoc.previewImg}}" />
                    <img alt="icon" class="previewDelete cursor-pointer" src="assets/images/cross-modal.svg"
                      (click)="remTimelineDoc(i)" />
                  </div>
                </div>
                <button type="button" class="select-photos-btn"
                  *ngIf="timelineDocs.length > 0 && timelineDocs.length < 5">
                  <input type="file" class="uploadInput" multiple="" (change)="coverImageUpload($event, true)">
                  Select photos
                </button>
                <div *ngIf="coverImageUploadError" class="error-message">
                  {{coverImageUploadMessage}}
                </div>
                <div *ngIf="coverImageUploadSizeError" class="error-message">
                  {{coverImageUploadSizeMessage}}
                </div>
                <div *ngIf="timelineDocs.length > 5" class="error-message">
                  Maximum 5 images allowed.
                </div>
              </div>

              <div class="form-group">
                <input type="text" class="form-control" placeholder="Title of post" formControlName="title">
                <div class="error-message"
                  *ngIf="timelineForm.get('title').hasError('required') && (timelineForm.get('title').dirty || timelineForm.get('title').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="timelineForm.get('title').hasError('maxlength') && (timelineForm.get('title').dirty || timelineForm.get('title').touched)">
                  Max 75 characters allowed.
                </div>
              </div>
              <div class="form-group">
                <ckeditor [editor]="Editor" formControlName="description"
                  [config]="{ toolbar: ['bold', 'italic'], placeholder: 'Details', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                  *ngIf="timelineForm.get('description').hasError('required') && (timelineForm.get('description').dirty || timelineForm.get('description').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="timelineForm.get('description').hasError('maxlength') && (timelineForm.get('description').dirty || timelineForm.get('description').touched)">
                  Max 2250 characters allowed.
                </div>
                <div class="error-message" *ngIf="timelineForm.get('description').hasError('aftersubmit')">
                  {{timelineForm.controls.description.errors.aftersubmit}}
                </div>
              </div>

              <div class="form-group">
                <input readonly type="text" class="form-control modal-control modal-field" placeholder="dd mm yy"
                  bsDatepicker formControlName="date" [bsConfig]="{adaptivePosition: true}">
                <div class="error-message"
                  *ngIf="timelineForm.get('date').hasError('required') && (timelineForm.get('date').dirty || timelineForm.get('date').touched || submitted)  && (radioActive == 3 || radioActive == 7)">
                  This field is required.
                </div>
              </div>



              <div class="timeFrameBtns btn-box">
                <div class="leftBtns">
                  <div class="leftBtnsInner">
                    <div class="linkTimeLine mr-2 mb-2">
                      <a href="javascript:void();" (click)="openLinkModal(addTimelineLink)"><img alt="icon"
                          src="assets/images/linkIcon.svg" /></a>
                      <div class="linkAdded" *ngIf="linkAdded">Link Added.</div>
                    </div>
                  </div>
                </div>
                <div class="rightBtns">
                  <button class="btn btn-colored" (click)="createTimeline(timelineForm.valid)">Post</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #followedModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Following</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body new-body common-body">
    <div *ngIf="followBtnErr" class="error-message text-center">Something went wrong. Please try again later.</div>
    <p *ngIf="followedPages.length == 0">No followed Page(s)</p>
    <ul class="follow-list scroll-activity scrollbar" *ngIf="followedPages.length > 0">
      <li *ngFor="let pagee of followedPages">
        <div class="info-content cursor-pointer" (click)="directToPageIni(pagee);modalRef.hide()">
          <img alt="page-image"
            src="{{pagee.page_info.display_picture? pagee.page_info.display_picture : 'assets/images/p-img.png'}}" />
          <div class="text">
            <p class="bold">{{ pagee.page_info.title }}</p>
            <p *ngIf="pagee.page_info.school_name">{{ pagee.page_info.school_name }}</p>
          </div>
        </div>
        <button type="button" class="schoolfollow-btn unfollow" (click)="unFollowPageIni(pagee)">
          Unfollow
        </button>
      </li>
    </ul>
    <div class="timeLineLoad pt-3 blue" *ngIf="totalFollowedCount > 10 && totalFollowedCount > followedPages.length">
      <a href="javascript:void(0);" (click)="getFollowedPages()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #templateOthers>
  <div class="modal-body new-body common-body">
    <div class="create-box">
      <h4 class="modal-title pull-left">Interested</h4>
      <button type="button" class="close-btn" aria-label="Close" (click)="otherModalRef.hide()">
        <img alt="image" src="assets/images/cross-modal.svg">
      </button>
    </div>
    <ul class="follow-list scroll-activity scrollbar">
      <li *ngFor="let usr of interestedUsers">
        <div class="info-content">
          <img alt="profile-image" class="cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
            src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
          <div class="text">
            <p class="bold cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
              [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}</p>
            <p>{{usr.education_info.school_name}}</p>
            <p class="small" *ngIf="usr.education_info.school">{{usr.education_info.start_date | date: 'yyyy'}} -
              {{usr.education_info.end_date | date: 'yyyy'}}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
      <a href="javascript:void(0);" (click)="getInterestedUsers()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #intsdFeedsTemplate>
  <div class="modal-body bookmark-body new-body">
    <div class="create-box">
      <h4 class="modal-title pull-left">Interested Feeds</h4>
      <button type="button" class="close-btn" aria-label="Close" (click)="intModalRef.hide()">
        <img alt="image" src="assets/images/cross-modal.svg">
      </button>
    </div>
    <div class="blog-listing scroll-activity scrollbar">
      <div class="blog-box" *ngFor="let feed of interestedFeeds">
        <div class="img-box">
          <img class="cursor-pointer" (click)="directToPage(feed);intModalRef.hide()" alt="profile-image"
            src="{{feed.display_picture? feed.display_picture : 'assets/images/default.svg'}}" />
        </div>
        <div class="content-box">
          <div class="w-100">
            <p class="bold cursor-pointer" (click)="feedDetailModal(feedDetail, feed, true)">{{feed.title}}
              <span class="d-inline-flex pl-0">
                <span>{{feed.page_title}}</span>
                <span
                  *ngIf="feed.post_info.post_type === 1 || feed.post_info.post_type === 8 || feed.post_info.post_type === 4">•
                  Update</span>
                <span
                  *ngIf="feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10">•
                  Event</span>
                <span *ngIf="feed.post_info.post_type === 2 || feed.post_info.post_type === 5">• Position</span>
                <span *ngIf="feed.post_info.post_type === 6">• Idea</span>
              </span>
            </p>
            <p class="timelineDes timelineDesOuterDots cursor-pointer" (click)="feedDetailModal(feedDetail, feed, true)"
              innerHtml="{{feed.description}}"></p>
          </div>
          <div class="save-content">
            <div class="left-part">
              <button type="button" class="interest-btn" [ngClass]="{'remove': feed.interested_data.interested}"
                (click)="markInterested(feed)">
                <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                <span class="remove-text">remove</span>
                <span>Interested</span>
              </button>
              <button type="button" class="other-btn" *ngIf="feed.interested_data.count > 0"
                (click)="openModalOthers(templateOthers, feed)">
                {{feed.interested_data.count}} Others
              </button>
            </div>
            <div class="right-part">
              <div class="desktop-flag-data">
                <div class="name">
                  <span class="dot">
                    <img alt="profile-image"
                      src="{{feed.post_info.creator_pic? feed.post_info.creator_pic : 'assets/images/default.svg'}}" />
                  </span>
                  <span class="cursor-pointer"
                    (click)="directToProfile(feed);intModalRef.hide()">{{feed.post_info.creator_name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="totalIntFeedsCount > 21 && totalIntFeedsCount > interestedFeeds.length"
      class="next-listing text-center mt-4 pb-4 timeLineLoad">
      <a href="javascript:void(0);" (click)="getInterestedFeeds()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #addTimelineLink>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Link</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="intModalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row m-0">
      <div class="col-md-12">
        <form [formGroup]="timelinelinkForm" novalidate>
          <input type="text" placeholder="Type Here" class="form-control" formControlName="link">
          <div class="error-message"
            *ngIf="timelinelinkForm.get('link').hasError('maxlength') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
            Max 250 characters allowed.
          </div>
          <div class="error-message"
            *ngIf="timelinelinkForm.get('link').hasError('pattern') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
            Please enter a valid URL.
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-footer cancel-btn" (click)="removeLink();intModalRef.hide()">cancel</button>
    <button class="btn btn-footer" (click)="setLink(timelinelinkForm.valid)">save</button>
  </div>
</ng-template>
<ng-template #iniPostTemplate>
  <div class="modal-body new-body">
    <div class="modal-box">
      <div class="timeLineBlock">
        <div class="create-box">
          <h4 class="modal-title pull-left">Create an initiative post</h4>
          <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
          </button>
        </div>
        <div class="allTimeLinesOuter">
          <div class="addTimeFrame">
            <form [formGroup]="timelineForm" novalidate>
              <div class="form-group">
                <select class="custom-select" [(ngModel)]="page_id" [ngModelOptions]="{standalone: true}">
                  <option value="0" disabled selected>Select Initiative</option>
                  <option *ngFor="let initiative of initiatives" value="{{initiative.id}}">
                    {{initiative.page_info.title}}</option>
                </select>
                <div class="error-message" *ngIf="page_id === 0 && submitted">
                  This field is required.
                </div>
              </div>
              <div class="isThisPost">
                <span class="">Is this post a(n):</span>
                <div class="teal-radio-box mr-4">
                  <input type="radio" value="1" id="end" (click)="radioActive=1;changeType()" name="post"
                    [checked]="radioActive === 1" />
                  <label for="end">Update / Reminder</label>
                </div>
                <div class="teal-radio-box mr-4">
                  <input type="radio" value="2" id="end3" (click)="radioActive=5;changeType()" name="post"
                    [checked]="radioActive === 5" />
                  <label for="end3">Volunteer</label>
                </div>
                <div class="teal-radio-box">
                  <input type="radio" value="3" id="end35" (click)="radioActive=10;changeType()" name="post"
                    [checked]="radioActive === 10" />
                  <label for="end35">Event</label>
                </div>
              </div>
              <div class="form-group">
                <div class="filesTimeLine custom-file-upload mr-2 mb-2" *ngIf="timelineDocs.length == 0">
                  <div class="file-box">
                    <input type="file" class="uploadInput" multiple="" (change)="coverImageUpload($event, true)">
                    <div class="file-content">
                      <img src="/assets/images/teal-add-icon.svg" alt="plus-icon" />
                      <p>Select photos (up to 5)</p>
                    </div>
                  </div>
                </div>
                <div class="forUploadedImages scrollbarHorizontal">
                  <div class="forUploadedImagesInner mr-3 mb-2" *ngFor="let timelineDoc of timelineDocs; index as i">
                    <img alt="icon" class="uploadedPreview" src="{{timelineDoc.previewImg}}" />
                    <img alt="icon" class="previewDelete cursor-pointer" src="assets/images/cross-modal.svg"
                      (click)="remTimelineDoc(i)" />
                  </div>
                </div>
                <button type="button" class="select-photos-btn"
                  *ngIf="timelineDocs.length > 0 && timelineDocs.length < 5">
                  <input type="file" class="uploadInput" multiple="" (change)="coverImageUpload($event, true)">
                  Select photos
                </button>
                <div *ngIf="coverImageUploadError" class="error-message">
                  {{coverImageUploadMessage}}
                </div>
                <div *ngIf="coverImageUploadSizeError" class="error-message">
                  {{coverImageUploadSizeMessage}}
                </div>
                <div *ngIf="timelineDocs.length > 5" class="error-message">
                  Maximum 5 images allowed.
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Title of post" formControlName="title">
                <div class="error-message"
                  *ngIf="timelineForm.get('title').hasError('required') && (timelineForm.get('title').dirty || timelineForm.get('title').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="timelineForm.get('title').hasError('maxlength') && (timelineForm.get('title').dirty || timelineForm.get('title').touched)">
                  Max 50 characters allowed.
                </div>
              </div>
              <div class="form-group">
                <ckeditor [editor]="Editor" formControlName="description"
                  [config]="{ toolbar: ['bold', 'italic'], placeholder: 'Details', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                  *ngIf="timelineForm.get('description').hasError('required') && (timelineForm.get('description').dirty || timelineForm.get('description').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                  *ngIf="timelineForm.get('description').hasError('maxlength') && (timelineForm.get('description').dirty || timelineForm.get('description').touched)">
                  Max 1000 characters allowed.
                </div>
                <div class="error-message" *ngIf="timelineForm.get('description').hasError('aftersubmit')">
                  {{timelineForm.controls.description.errors.aftersubmit}}
                </div>
              </div>
              <div class="form-group">
                <input readonly type="text" class="form-control modal-control modal-field" placeholder="dd mm yy"
                  bsDatepicker formControlName="date" [bsConfig]="{adaptivePosition: true}">
                <div class="error-message"
                  *ngIf="timelineForm.get('date').hasError('required') && (timelineForm.get('date').dirty || timelineForm.get('date').touched || submitted) && (radioActive == 10)">
                  This field is required.
                </div>
              </div>
              <div class="timeFrameBtns btn-box">
                <div class="leftBtns">
                  <div class="leftBtnsInner">
                    <div class="linkTimeLine mr-2 mb-2">
                      <a href="javascript:void();" (click)="openLinkModal(addTimelineLink)"><img alt="icon"
                          src="assets/images/linkIcon.svg" /></a>
                      <div class="linkAdded" *ngIf="linkAdded">Link Added.</div>
                    </div>
                  </div>
                </div>
                <div class="rightBtns">
                  <button class="btn btn-colored" (click)="createIniTimeline(timelineForm.valid)">Post</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #feedDetail>
  <div class="modal-body new-body pb-0">
    <div class="create-box timeline">
      <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
        <img alt="image" src="assets/images/cross-modal.svg">
        <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg" alt="arrow" />
      </button>
      <div class="border-line">
      </div>
    </div>
    <div class="d-flex detail-comment-box scrollbar">
      <div class="timeLinesEach custom-design mt-0">
        <div class="feedDetailSlider" [ngClass]="{'no-image': editFeedObj?.documents?.length == 0 }">
          <span class="event"
            *ngIf="editFeedObj.post_info.post_type === 3 || editFeedObj.post_info.post_type === 7 || editFeedObj.post_info.post_type === 10">Event</span>
          <span class="event"
            *ngIf="editFeedObj.post_info.post_type === 2 || editFeedObj.post_info.post_type === 5">Position</span>
          <span class="event"
            *ngIf="editFeedObj.post_info.post_type === 1 || editFeedObj.post_info.post_type === 8 || editFeedObj.post_info.post_type === 4">Update</span>
          <span class="event interested" *ngIf="editFeedObj.post_info.post_type === 6">
            <img src="assets/images/blub.svg" alt="blub" />Idea
          </span>
          <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
            <div class="slide" ngxSlickItem *ngFor="let document of editFeedObj.documents">
              <div class="img-box">
                <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg" alt="pdf-image" />
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="feedDetailContent">
          <div class="img-box"
            [ngClass]="{'mt-4 mt-lg-0 mt-md-0': editFeedObj?.documents?.length == 0 && [2,3,5,6,7].indexOf(editFeedObj.post_info.post_type) < 0}">
            <img class="cursor-pointer" (click)="directToPage(editFeedObj);closeModal()"
              src="{{editFeedObj.display_picture? editFeedObj.display_picture : 'assets/images/default.svg'}}"
              alt="image" />
          </div>
          <h3>{{editFeedObj.title}}</h3>
          <p>{{editFeedObj.page_title}} <span *ngIf="!isInterested">• Expires
              <span *ngIf="editFeedObj.days_left > 0">in {{editFeedObj.days_left}} days</span>
              <span *ngIf="editFeedObj.days_left === 0">Today</span></span>
          </p>
          <div class="date-box"
            *ngIf="[3,5,6,7,8,10].includes(editFeedObj.post_info.post_type) && (editFeedObj.post_info.event_date != null)">
            <div class="month">
              <span>{{editFeedObj.post_info.event_date | date:'MMM'}}</span>
            </div>
            <div class="date">
              <span>{{editFeedObj.post_info.event_date | date:'d'}}</span>
            </div>
          </div>
          <div class="date-box"
                      *ngIf="[1,2,4].includes(editFeedObj.post_info.post_type) && (editFeedObj.post_info.date != null)">
                      <div class="month">
                        <span>{{editFeedObj.post_info.date | date:'MMM'}}</span>
                      </div>
                      <div class="date">
                        <span>{{editFeedObj.post_info.date | date:'d'}}</span>
                      </div>
                    </div>
          <div class="save-content">
            <div class="left-part">
              <button type="button" class="interest-btn" [ngClass]="{'remove': editFeedObj.interested_data.interested}"
                (click)="markInterested(editFeedObj)">
                <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                <span class="remove-text">remove</span>
                <span>Interested</span>
                {{editFeedObj[0]?.id}}
              </button>
              <button type="button" class="other-btn" *ngIf="editFeedObj.interested_data.count > 0"
                (click)="openModalOthers(templateOthers, editFeedObj)">
                {{editFeedObj.interested_data.count}} Others
              </button>
              <button type="button" class="interest-btn comments-btn" (click)="copyUrl(editFeedObj)">
                <img class="mr-0" src="/assets/images/copylink.svg" alt="copylink" />
              </button>


              <div class="custom-tooltip copy-tooltip tooltip-show">
              <div class="tooltip-data copied-tooltip link-copied" *ngIf="(shareUrlIdCheck == editFeedObj.id) && copyMessageBool">Link Copied</div>
              </div>
            </div>
            <div class="right-part">
              <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                  aria-controls="dropdown-basic">
                  <img src="assets/images/dot-teal.svg" alt="more icon" />
                </button>
                <ul id="dropdown-basic" *dropdownMenu
                  class="dropdown-menu name-mobile-box detail-custom ReportDropMenuposition" role="menu"
                  aria-labelledby="button-basic" [ngClass]="{'onlyProfile': isInterested}">
                  <li role="menuitem" *ngIf="!isInterested">
                    <div class="flag-data-box cursor-pointer" (click)="reportModal(reportTemplate, editFeedObj)">
                      <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                      <span>Report Post</span>
                    </div>
                  </li>
                  <li role="menuitem">
                    <div class="flag-data-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                      <div class="name ml-0">
                        <span class="dot">
                          <img alt="profile-image"
                            src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}">
                        </span>
                        <span class="cursor-pointer"
                          (click)="directToProfile(editFeedObj);closeModal()">{{editFeedObj.post_info.creator_name}}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="details mb-0">
            <h4>Details</h4>
            <p innerHtml="{{editFeedObj.description}}"></p>
            <p class="metaLink" *ngIf="!checkExtraData(editFeedObj)">
              <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
            </p>
            <div class="singleTimelineDetail" *ngIf="checkExtraData(editFeedObj)">
              <div class="imageBox">
                <img alt="timeline" class="timeLineImg"
                  src="{{editFeedObj.metadata_image_link? editFeedObj.metadata_image_link : 'assets/images/default.svg'}}" />
              </div>
              <div class="dtlOuter">
                <h1>{{editFeedObj.metadata_title}}</h1>
                <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
                <p>{{editFeedObj.metadata_description}}</p>
              </div>
            </div>
            <div class="posted-by mb-0">
              <h4>Posted by</h4>
              <div class="name-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                <img
                  src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}"
                  alt="image" />
                <p>{{editFeedObj.post_info.creator_name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-comments (commentDeleted)="commentDeleted($event)" (commentAdded)="commentAdded($event)" [editFeedPostType]="editFeedObj.post_info.post_type" [editFeedId]="editFeedObj.id"></app-comments>
    </div>
  </div>
</ng-template>
<ng-template #messageAlertTemplate>
  <div class="modal-body pt-5 pb-0">
    <p class="text-center m-0">Since you are greater than 20 years old, you cannot access any High School pages.
      Thank you for understanding!</p>
  </div>
  <div class="modal-footer pb-5">
    <button class="btn btn-footer" (click)="modalRef.hide()">okay</button>
  </div>
</ng-template>

<ng-template #weekTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">This Week</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>

  <div class="modal-body scrollbar weekEventsScroll">
    <div class="this-week-box modal-week-box">
      <div class="date-box" (click)="feedDetailModal(feedDetail, weekData)" *ngFor="let weekData of weekEvntData">
        <div class="month">
        <span>{{weekData.post_info.event_date | date:'MMMM d'}}</span>
        </div>
        <div class="date">
         <span>{{weekData.title}}</span>
        </div>
      </div>

    </div>
    <div class="timeLineLoad pt-3 blue" *ngIf="weekEvntsCount > 10 && weekEvntsCount > weekEvntData.length">
        <a href="javascript:void(0);" (click)="getWeeklyDetails()">Load More</a>
      </div>
  </div>

</ng-template>


