import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseAccountEndPoint, BaseProfileEndPoint } from '../app.config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicResumeService {
  private baseResumeUrl: string = BaseProfileEndPoint.apiEndpoint + 'resume/';
  
  constructor(private http: BaseService) { }

  getUserProfile(id: string) {
    return this.http.get(this.baseResumeUrl + 'user-profile/' + id + '/');
  }

  getVerifiers(id: string) {
    return this.http.get(this.baseResumeUrl + 'verifiers/user/' + id + '/');
  }

  getUserSkills(id: string) {
    return this.http.get(this.baseResumeUrl + 'user-skills/' + id + '/');
  }

  getUserInterests(id: string) {
    return this.http.get(this.baseResumeUrl + 'user-interests/' + id + '/');
  }

  getUserBooks(id: string) {
    return this.http.get(this.baseResumeUrl + 'user-books/' + id + '/');
  }

  getUserBulletin(id: string) {
    return this.http.get(this.baseResumeUrl + 'user-bulletin/' + id + '/');
  }

  getUserActivities(id: string) {
    return this.http.get(this.baseResumeUrl + 'activities/' + id + '/');
  }

  getUserFavourite(id: string) {
    return this.http.get(this.baseResumeUrl + 'favourite/' + id + '/');
  }
  
  getUserExp(id: string) {
    return this.http.get(this.baseResumeUrl + 'experience/' + id + '/');
  }

  getUserInitiative(id: string) {
    return this.http.get(this.baseResumeUrl + 'initiatives/' + id + '/');
  }

  getUserMam(id: string) {
    return this.http.get(this.baseResumeUrl + 'mam/' + id + '/');
  }

  getUserSchoolInv(id: string) {
    return this.http.get(this.baseResumeUrl + 'school-involvement/' + id + '/');
  }

  getUserEducation(id: string) {
    return this.http.get(this.baseResumeUrl + 'student-education/' + id + '/');
  }

  getUserAwards(id: string) {
    return this.http.get(this.baseResumeUrl + 'get-awards-credentials/' + id + '/');
  }

  getSocialLinks(id: string) {
    return this.http.get(this.baseResumeUrl + 'get-social-link/' + id + '/');
  }

}
