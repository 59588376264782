<div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3 pt-0">
    <div class="topLinks">
        <div class="content-section">
<div class="content-profile-white-box" *ngIf="eduList && eduList.length > 0">
        <div class="titleDiv d-flex align-items-center">
                <h3 class="with-list-icon">
                    Education
                </h3>
            </div>

            <div class="detailContent" *ngIf="eduList && eduList.length > 0">
                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                    [config]="slideConfig2">
                    <div class=" slide" ngxSlickItem *ngFor="let eduObj of eduList">
                        <div class="media align-items-center"
                            (click)="updateEducationPopup(staticEduModal, eduObj)">
                            <a href="javascript:void();">
                                <img alt="image"
                                    src="{{eduObj.school.display_picture? eduObj.school.display_picture : 'assets/images/default.svg'}}"
                                    alt="Education Cover" class="thumb-img">
                            </a>
                            <div class="media-body ml-2">
                                <h4>{{eduObj.school.school_name}}</h4>
                                <p *ngIf="eduObj.majors">{{ eduObj.majors.join(', ') }}</p>
                                <p class="font14">{{eduObj.end_date | date:'yyyy' }}</p>
                                <p *ngIf="eduObj.interests">{{eduObj.interests.join(', ')}}</p>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="myInitiatives && myInitiatives.length > 0">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">
                    Initiative
                </h3>
            </div>

            <div class="detailContent" *ngIf="myInitiatives && myInitiatives.length > 0">
                <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel"
                    [config]="sliderInitiative">
                    <div class="slide" ngxSlickItem *ngFor="let initiative of myInitiatives">
                        <div class="initiative-wrap">
                                <div class="img-60-circle mx-auto" (click)="jumpToInitiative(initiative)">
                                <img class="icon" src="{{initiative.display_pic ? initiative.display_pic : 'assets/images/default.svg'}}" />
                            </div>
                            <div class="dtl">
                                <h3 (click)="jumpToInitiative(initiative)">{{ initiative.page_info.title }}</h3>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="usrWorkExperience && usrWorkExperience.length > 0 && user.age_group > 1">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">
                    Work
                </h3>
            </div>

            <div class="detailContent" *ngIf="usrWorkExperience && usrWorkExperience.length > 0 && user.age_group > 1">
                <div class="single-image-wrap">
                    <div class="media border-row cursor-pointer"
                        *ngFor="let experience of usrWorkExperience | slice:0:3"
                        (click)="updateExpModal(editExpTemplate, experience)">
                        <div class="exp-verified">
                            <div class="exp-verified-Inner">
                                <img alt="image" class="thumb-img"
                                src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                                
                            </div>
                        </div>
                        <div class="media-body ml-3">
                            <h3 class="top-name">{{experience.position}}</h3>
                            <h4>{{experience.organization_title}}</h4>
                            <h5 *ngIf="experience.start_date != experience.end_date" class="start-end">
                                <span>{{experience.start_date | date: 'MMM y'}}</span>
                                <span> - </span>
                                <ng-template
                                    [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                    [ngIfElse]="currentTemp">
                                    <span>{{experience.end_date | date: 'MMM y'}}</span>
                                </ng-template>
                            </h5>
                            <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                <strong>Date</strong>
                                <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                            </h5>
                            <p class="m-0"><strong>
                                    <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                    <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                    <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                    <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">,</span>
                                    <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                </strong></p>
                            <p class="m-0 text-ellipse">{{ experience.main_role }}</p>
                            <div class="addRefRow">
                            <div class="expVerifyTeam" >
                                    <p class="my-0 people-number" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        <span>{{ experience.related_data.exp_count }}</span>
                                    </p>
                                
                            </div>
                            <p class="verifiedTextImg"
                            *ngIf="experience.approver_name && experience.verified_date">
                            <img alt="image" src="assets/images/verifyProfile.svg">
                        </p>
                        </div>
                        </div>
                    </div>

                </div>

                <div class="viewMoreWrapper" *ngIf="usrWorkExperience?.length > 3">
                    <span class="viewMore" (click)="workExpListing(workExpListModal)">
                       View All ({{usrWorkExperience?.length}})
                    </span>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="usrVolunteerWorkExperience && usrVolunteerWorkExperience.length > 0 && user.age_group > 1">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">
                    Volunteer
                </h3>
            </div>

            <div class="detailContent" *ngIf="usrVolunteerWorkExperience && usrVolunteerWorkExperience.length > 0 && user.age_group > 1">
                <div class="single-image-wrap">
                    <div class="media border-row cursor-pointer"
                        *ngFor="let experience of usrVolunteerWorkExperience | slice:0:3"
                        (click)="updateExpModal(editExpTemplate, experience)">
                        <div class="exp-verified">
                            <div class="exp-verified-Inner">
                                <img alt="image"
                                src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                                alt="Education Cover" class="thumb-img">
                                <!-- <p class="verifiedTextImg"
                                    *ngIf="experience.approver_name && experience.verified_date">
                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                </p> -->
                            </div>
                            <!-- <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                <p class="m-0">
                                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                  <span>{{ experience.related_data.exp_count }}</span>
                                </p>
                              </div> -->
                              
                        </div>
                        <div class="media-body ml-3">
                            <h3 class="top-name">{{experience.position}}</h3>
                            <h4>{{experience.organization_title}}</h4>
                            <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                                <span>{{experience.start_date | date: 'MMM y'}}</span>
                                <span> - </span>
                                <ng-template
                                    [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                    [ngIfElse]="currentTemp">
                                    <span>{{experience.end_date | date: 'MMM y'}}</span>
                                </ng-template>
                            </h5>
                            <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                <strong>Date</strong>
                                <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                            </h5>

                            <p class="m-0">
                                <strong>
                                    <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                    <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                    <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                    <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">,</span>
                                    <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                </strong>
                            </p>
                            <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                            <div class="addRefRow">
                            <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                <p class="my-0 people-number">
                                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                  <span>{{ experience.related_data.exp_count }}</span>
                                </p>
                              </div>
                              <p class="verifiedTextImg"
                                    *ngIf="experience.approver_name && experience.verified_date">
                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div class="viewMoreWrapper" *ngIf="usrVolunteerWorkExperience?.length > 3">
                    <span class="viewMore" (click)="volunteerListing(volunteerListModal)">
                       View All ({{usrVolunteerWorkExperience?.length}})
                    </span>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="activities && activities.length > 0 && user.age_group > 1">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">
                    Extracurricular
                </h3>
            </div>

            <div class="detailContent" *ngIf="activities && activities.length > 0 && user.age_group > 1">
                <div class="single-image-wrap">
                    <div class="media border-row cursor-pointer"
                        *ngFor="let activity of activities | slice:0:3"
                        (click)="updateActivityModal(editActivityTemplate, activity)">
                        <div class="exp-verified">
                            <div class="exp-verified-Inner">
                                <img alt="image"
                                src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}"
                                class="thumb-img">
                                <!-- <p class="verifiedTextImg"
                                    *ngIf="activity.approver_name && activity.verified_date">
                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                </p> -->
                            </div>
                            <!-- <div class="expVerifyTeam" *ngIf="activity.page && activity.related_data.exp_count > 0">
                                <p class="m-0">
                                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                  <span>{{ activity.related_data.exp_count }}</span>
                                </p>
                              </div> -->

                              

                        </div>
                        <div class="media-body ml-3">
                            <h3 class="top-name">{{activity.position}}</h3>
                            <h4>{{activity.organization_title}}</h4>
                            <h5 class="start-end" *ngIf="activity.start_date != activity.end_date">
                                <span>{{activity.start_date | date: 'MMM y'}}</span> 
                                <span> - </span>
                                <ng-template
                                    [ngIf]="activity.end_date && !(activity.verification_status == 1 && activity.approver_name)"
                                    [ngIfElse]="currentTemp">
                                    <span>{{activity.end_date | date: 'MMM y'}}</span>
                                </ng-template>
                            </h5>
                            <h5 class="start-end" *ngIf="activity.start_date == activity.end_date">
                                <strong>Date</strong>
                                <span class="ml-1">{{activity.start_date | date: 'MMM y'}}</span>
                            </h5>
                            <p class="m-0">
                                <strong>
                                    <span *ngIf="activity.skill_1">{{activity.skill_1}}</span>
                                    <span *ngIf="activity.skill_2 && activity.skill_1">,</span>
                                    <span *ngIf="activity.skill_2">{{activity.skill_2}}</span>
                                    <span *ngIf="activity.skill_3  && (activity.skill_2 || activity.skill_1)">,</span>
                                    <span *ngIf="activity.skill_3">{{activity.skill_3}}</span>
                                </strong>
                            </p>
                            <p class="m-0 text-ellipse">{{activity.main_role}}</p>
                            <div class="addRefRow">
                            <div class="expVerifyTeam" *ngIf="activity.page && activity.related_data.exp_count > 0">
                                <p class="my-0 people-number">
                                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                  <span>{{ activity.related_data.exp_count }}</span>
                                </p>
                              </div>

                              <p class="verifiedTextImg"
                              *ngIf="activity.approver_name && activity.verified_date">
                              <img alt="image" src="assets/images/verifyProfile.svg">
                          </p>
                        </div>

                        </div>
                    </div>
                </div>

                <div class="viewMoreWrapper" *ngIf="activities?.length > 3">
                    <span class="viewMore" (click)="extraCurricularListing(extraCurricularListModal)">
                       View All ({{activities?.length}})
                    </span>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="schoolInvolvements && schoolInvolvements.length > 0">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">
                    School Involvement
                </h3>
            </div>

            <div class="detailContent mb-0" *ngIf="schoolInvolvements && schoolInvolvements.length > 0">
                <div class="single-image-wrap">
                    <div class="media border-row cursor-pointer"
                        *ngFor="let experience of schoolInvolvements | slice:0:3"
                        (click)="updateSchoolInvolvementModal(schoolInvolvementTemplate, experience)">
                        <div class="exp-verified">
                            <div class="exp-verified-Inner">
                                <img alt="image"
                                src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                                alt="Education Cover" class="thumb-img">
                                <!-- <p class="verifiedTextImg"
                                    *ngIf="experience.approver_name && experience.verified_date">
                                    <img alt="image" src="assets/images/verifyProfile.svg">
                                </p> -->
                            </div>
                            <!-- <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                <p class="m-0">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                    <span>{{ experience.related_data.exp_count }}</span>
                                </p>
                            </div> -->
                            
                        </div>
                        <div class="media-body ml-3">
                            <h3 class="top-name">{{experience.position}}</h3>
                            <h4>{{experience.title}}</h4>
                            <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                                <span>{{experience.start_date | date: 'MMM y'}}</span>
                                <span> - </span>
                                <ng-template
                                    [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                    [ngIfElse]="currentTemp">
                                    <span>{{experience.end_date | date: 'MMM y'}}</span>
                                </ng-template>
                            </h5>
                            <h5 class="start-end" *ngIf="experience.start_date == experience.end_date">
                                <strong>Date</strong>
                                <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                            </h5>

                            <p class="m-0">
                                <strong>
                                    <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                    <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                    <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                    <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">,</span>
                                    <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                                </strong>
                            </p>
                            <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                            <div class="addRefRow">
                                <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                    <p class="my-0 people-number">
                                        <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                        <span>{{ experience.related_data.exp_count }}</span>
                                    </p>
                                </div>
                                <p class="verifiedTextImg"
                                        *ngIf="experience.approver_name && experience.verified_date">
                                        <img alt="image" src="assets/images/verifyProfile.svg">
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="viewMoreWrapper" *ngIf="schoolInvolvements?.length > 3">
                    <span class="viewMore" (click)="schoolInvolvementListing(schoolInvolvementListModal)">
                       View All ({{schoolInvolvements?.length}})
                    </span>
                </div>
            </div>
</div>

<div class="content-profile-white-box" *ngIf="awards && awards.length > 0">
        <div class="titleDiv d-flex align-items-center" >
                <h3 class="with-list-icon">Awards and Credentials
                </h3>
            </div>

            <div class="detailContent" *ngIf="awards && awards.length > 0">
                <ul class="no-list awardsList">
                    <li *ngFor="let award of awards">
                        <div class="awardsDataLeft">
                            <p class="name m-0 mr-2 cursor-pointer" (click)="viewModalAwards(viewAwardsTemplate, award)">{{award.title}}</p>
                        </div>
                        <div class="awardsDataRight">
                                <div class="forDotsImages">
                                        <span *ngIf="award.link">
                                            <img alt="image" class="" src="assets/images/awardsLinkIcon.svg">
                                        </span>
        
                                        <span *ngIf="award.document">
                                            <img alt="image" class="" src="assets/images/awardsFileIcon.svg">
                                        </span>
                                    </div>
                            <p class="ml-0 my-0">{{award.year | date:'yyyy'}}</p>
                        </div>
                    </li>
                </ul>
            </div>
</div>

        </div>
    </div>
</div>

<ng-template #verification>
    <div class="modal-header">
        <h4 class="modal-title pull-left">profile verifiers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="verifiers.length >= 3">
                <div class="verify-div" *ngFor="let verifier of verifiers">
                    <p>Verified by
                        <span>********@{{verifier.email.split('@')[1]}}</span> on
                        <span>{{verifier.verified_date | date: 'yyyy-MM-dd'}}</span>
                    </p>
                </div>
            </div>
            <div class="col-md-12" *ngIf="verifiers.length < 3 && user.approval_status">
                <div>
                    <p>Verified by the ConnectUs.today Team
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #staticEduModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">

            <form [formGroup]="educationForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h2 class="stand-by-hdg">{{insText}}</h2>
                    </div>
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input readonly type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Country</label>
                        <input readonly type="text" class="form-control" formControlName="country"
                            placeholder="Country">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-md-12">
                        <!-- <div class="custom-control custom-radio" [hidden]="isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" class="custom-control-input">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div> -->
                        <div class="teal-radio-box">
                                <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" />
                                <label for="customCheck2">School</label>
                            </div>
                    </div>
                    <div class="col-md-12">
                        <div class="custom-control custom-radio" [hidden]="!isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="isCollegeSelected" [formGroup]="collegeEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3 class="stand-by-hdg">Education Details</h3>
                    </div>
                    <div class="col-md-12"
                        *ngIf="collegeEdForm.controls.college_degree && collegeEdForm.controls.college_degree.value">
                        <label>Degree of College/University
                        </label>
                    </div>
                    <div class="col-md-12"
                        *ngIf="collegeEdForm.controls.college_degree && collegeEdForm.controls.college_degree.value">
                        <select formControlName="college_degree" class="custom-select" disabled>
                            <option *ngFor="let ins_type of collegeTypes" value={{ins_type.id}}>
                                {{ins_type.value}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row"
                    *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                    <div class="col-md-12">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="majors" placeholder="Majors" class="form-control">
                    </div>
                </div>
                <div class="form-group row" *ngIf="collegeEdForm.controls.minors.value">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="minors" placeholder="Minors" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>

                </div>
            </form>
            <form *ngIf="!isCollegeSelected" [formGroup]="schoolEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3 class="stand-by-hdg">Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <label>Interests and Favourite Classes

                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <input readonly type="text" formControlName="interests" placeholder="Interests"
                            class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{expModalTitle}} Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate>
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img
                                src="{{editExp.page_display_pic? editExp.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editExp.position }}</h3>
                                <h4 *ngIf="editExp.organization_title">{{editExp.organization_title}}</h4>
                                <h4 *ngIf="!editExp.organization_title">Self-employed</h4>
                                <h5 class="start-end" *ngIf="editExp.start_date != editExp.end_date">
                                    <span>{{editExp.start_date | date: 'MMM y' }}</span>
                                    <span> - </span>
                                    <span *ngIf="editExp.end_date" >{{ editExp.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editExp.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editExp.start_date == editExp.end_date">
                                    <strong>Date</strong>
                                    <span class="ml-1">{{editExp.start_date | date: 'MMM y'}}</span>
                                </h5>

                                <p *ngIf="editExp.approver_name && editExp.verified_date" class="verifiedText d-block">
                                    Verified by {{editExp.approver_name}} on
                                    {{editExp.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.skill_1 || editExp.skill_2 || editExp.skill_3">
                                <h2>Top Skills Built</h2>
                                <p>
                                    <span *ngIf="editExp.skill_1">
                                        {{editExp.skill_1}}
                                    </span>
                                    <span *ngIf="editExp.skill_2 && editExp.skill_1">,</span> 
                                    <span *ngIf="editExp.skill_2">
                                        {{editExp.skill_2}}
                                    </span>
                                    <span *ngIf="editExp.skill_3 && (editExp.skill_2 || editExp.skill_1)">,</span> 
                                    <span *ngIf="editExp.skill_3">
                                        {{editExp.skill_3}}
                                    </span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editExp.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editExp.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editExp.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editActivityTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate>
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img alt="image"
                                src="{{editActivity.page_display_pic? editActivity.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editActivity.position }}</h3>
                                <h4>{{ editActivity.organization_title }}</h4>
                                <h5 class="start-end" *ngIf="editActivity.start_date != editActivity.end_date">
                                    <span>{{ editActivity.start_date | date: 'MMM y' }}</span>
                                    <span> - </span>
                                    <span *ngIf="editActivity.end_date" >{{ editActivity.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editActivity.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editActivity.start_date == editActivity.end_date"><strong>Date</strong>
                                    <span class="ml-1">{{editActivity.start_date | date: 'MMMM d, y'}}</span>
                                </h5>
                                <p *ngIf="editActivity.approver_name && editActivity.verified_date"
                                    class="verifiedText d-block">
                                    Verified by {{editActivity.approver_name}} on
                                    {{editActivity.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts"
                                *ngIf="editActivity.skill_1 || editActivity.skill_2 || editActivity.skill_3">
                                <h2>Top Skills Built</h2>
                                <p>
                                    <span *ngIf="editActivity.skill_1">
                                        {{editActivity.skill_1}}
                                    </span>
                                    <span *ngIf="editActivity.skill_2 && editActivity.skill_1">,</span> 
                                    <span *ngIf="editActivity.skill_2">
                                        {{editActivity.skill_2}}
                                    </span>
                                    <span *ngIf="editActivity.skill_3 && (editActivity.skill_2 || editActivity.skill_1)">,</span> 
                                    <span *ngIf="editActivity.skill_3">
                                        {{editActivity.skill_3}}
                                    </span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editActivity.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editActivity.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editActivity.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editActivity.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #schoolInvolvementTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <form novalidate>
                <div class="single-image-wrap exp-view-wrap">
                    <div class="experience-div media w-100">
                        <div>
                            <img
                                src="{{editSchoolInv.page_display_pic? editSchoolInv.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="media-body ml-md-3">
                            <div class="mb-3">
                                <h3 class="top-name">{{ editSchoolInv.position }}</h3>
                                <h4>{{ editSchoolInv.title }}</h4>
                                <h5 class="start-end" *ngIf="editSchoolInv.start_date != editSchoolInv.end_date">
                                    <span>{{editSchoolInv.start_date | date: 'MMM y' }}</span>
                                    <span> - </span>
                                    <span *ngIf="editSchoolInv.end_date" >{{ editSchoolInv.end_date | date: 'MMM y'}}</span>
                                    <span *ngIf="!editSchoolInv.end_date">Present</span>
                                </h5>
                                <h5 class="start-end" *ngIf="editSchoolInv.start_date == editSchoolInv.end_date"><strong>Date</strong>
                                    <span class="ml-1">{{editExp.start_date | date: 'MMMM d, y'}}</span>
                                </h5>

                                <p *ngIf="editSchoolInv.approver_name && editSchoolInv.verified_date"
                                    class="verifiedText d-block">
                                    Verified by {{editSchoolInv.approver_name}} on
                                    {{editSchoolInv.verified_date | date: 'MMMM d, y'}}
                                </p>
                            </div>

                            <div class="view-parts"
                                *ngIf="editSchoolInv.skill_1 || editSchoolInv.skill_2 || editSchoolInv.skill_3">
                                <h2>Top Skills Built</h2>
                                <p>
                                    <span *ngIf="editSchoolInv.skill_1">
                                        {{editSchoolInv.skill_1}}
                                    </span>
                                    <span *ngIf="editSchoolInv.skill_2 && editSchoolInv.skill_1">,</span> 
                                    <span *ngIf="editSchoolInv.skill_2">
                                        {{editSchoolInv.skill_2}}
                                    </span>
                                    <span *ngIf="editSchoolInv.skill_3 && (editSchoolInv.skill_2 || editSchoolInv.skill_1)">,</span> 
                                    <span *ngIf="editSchoolInv.skill_3">
                                        {{editSchoolInv.skill_3}}
                                    </span>
                                </p>
                            </div>

                            <div class="view-parts" *ngIf="editSchoolInv.main_role">
                                <h2>Main Role</h2>
                                <p>{{ editSchoolInv.main_role }}</p>
                            </div>

                            <div class="view-parts" *ngIf="editSchoolInv.personal_details">
                                <h2>Personal Experience</h2>
                                <p>{{ editSchoolInv.personal_details }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #currentTemp>
    <span>Present</span>
</ng-template>

<ng-template #favtemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Favourites</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="media mb-3 modal-media">
            <img class="fav-image" src="assets/images/default-ui.png" alt="Skill Cover">
            <div class="media-body ml-3">
                <h4>Psychology</h4>
                <p class="m-0">Subject</p>
                <p class="m-0">I believe in the importance of learning through experiences...I believe in the importance
                    of learning through experiences...I believe in the importance of learning through experiences.</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #workExpListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of usrWorkExperience"
                    (click)="updateExpModal(editExpTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" class="thumb-img"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>

                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4>{{experience.organization_title}}</h4>
                        <h5 *ngIf="experience.start_date != experience.end_date" class="start-end">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                        </h5>
                        <p class="m-0"><strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3">,</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">{{experience.skill_3}}</span>
                            </strong></p>
                        <p class="m-0 text-ellipse">
                            {{ experience.main_role }}
                        </p>
                        <div class="addRefRowInner">
                                <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                        <p class="my-0 people-number">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>{{ experience.related_data.exp_count }}</span>
                                        </p>
                                    </div>
                                    <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                            <img alt="image" src="assets/images/verifyProfile.svg">
                                        </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #volunteerListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Volunteer Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of usrVolunteerWorkExperience"
                    (click)="updateExpModal(editExpTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                            alt="Education Cover" class="thumb-img">
                            <!-- <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p> -->
                        </div>
                        <!-- <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                            <p class="m-0">
                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                <span>{{ experience.related_data.exp_count }}</span>
                            </p>
                        </div> -->
                        
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4>{{experience.organization_title}}</h4>
                        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                        </h5>

                        <p class="m-0">
                            <strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">,</span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                        
                        <div class="addRefRow">
                            <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                <p class="my-0 people-number">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                    <span>{{ experience.related_data.exp_count }}</span>
                                </p>
                            </div>
                            <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #extraCurricularListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let activity of activities"
                    (click)="updateActivityModal(editActivityTemplate, activity)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image" 
                            src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}"
                            class="thumb-img">
                            <!-- <p class="verifiedTextImg" *ngIf="activity.approver_name && activity.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p> -->
                        </div>
                        <!-- <div class="expVerifyTeam" *ngIf="activity.page && activity.related_data.exp_count > 0">
                            <p class="m-0">
                              <img src="assets/images/verifiedUserActive.svg" alt="icon">
                              <span>{{ activity.related_data.exp_count }}</span>
                            </p>
                        </div> -->
                        
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{activity.position}}</h3>
                        <h4>{{activity.organization_title}}</h4>
                        <h5 class="start-end" *ngIf="activity.start_date != activity.end_date">
                            <span>{{activity.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="activity.end_date && !(activity.verification_status == 1 && activity.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{activity.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="activity.start_date == activity.end_date"><strong>Date</strong>
                            <span class="ml-1">{{activity.start_date | date: 'MMM y'}}</span>
                        </h5>
                        <p class="m-0">
                            <strong>
                                <span *ngIf="activity.skill_1">{{activity.skill_1}}</span>
                                <span *ngIf="activity.skill_2 && activity.skill_1">,</span>
                                <span *ngIf="activity.skill_2">{{activity.skill_2}}</span>
                                <span *ngIf="activity.skill_3 && (activity.skill_2 || activity.skill_1)">,</span>
                                <span *ngIf="activity.skill_3">{{activity.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{activity.main_role}}</p>
                        <div class="addRefRow">
                            <div class="expVerifyTeam" *ngIf="activity.page && activity.related_data.exp_count > 0">
                                <p class="my-0 people-number">
                                  <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                  <span>{{ activity.related_data.exp_count }}</span>
                                </p>
                            </div>
                            <p class="verifiedTextImg" *ngIf="activity.approver_name && activity.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #schoolInvolvementListModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">School Involvement</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body px-0">
        <div class="exp-modal-wrap scroll-activity scrollbar exp-body">
            <div class="single-image-wrap">
                <div class="media border-row cursor-pointer" *ngFor="let experience of schoolInvolvements"
                    (click)="updateSchoolInvolvementModal(schoolInvolvementTemplate, experience)">
                    <div class="exp-verified">
                        <div class="exp-verified-Inner">
                            <img alt="image"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}"
                            alt="Education Cover" class="thumb-img">
                            <!-- <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p> -->
                        </div>
                        <!-- <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                            <p class="m-0">
                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                <span>{{ experience.related_data.exp_count }}</span>
                            </p>
                        </div> -->

                        
                    </div>
                    <div class="media-body ml-3">
                        <h3 class="top-name">{{experience.position}}</h3>
                        <h4>{{experience.title}}</h4>
                        <h5 class="start-end" *ngIf="experience.start_date != experience.end_date">
                            <span>{{experience.start_date | date: 'MMM y'}}</span>
                            <span> - </span>
                            <ng-template
                                [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                                [ngIfElse]="currentTemp">
                                <span>{{experience.end_date | date: 'MMM y'}}</span>
                            </ng-template>
                        </h5>
                        <h5 class="start-end" *ngIf="experience.start_date == experience.end_date"><strong>Date</strong>
                            <span class="ml-1">{{experience.start_date | date: 'MMM y'}}</span>
                        </h5>

                        <p class="m-0">
                            <strong>
                                <span *ngIf="experience.skill_1">{{experience.skill_1}}</span>
                                <span *ngIf="experience.skill_2 && experience.skill_1">,</span>
                                <span *ngIf="experience.skill_2">{{experience.skill_2}}</span>
                                <span *ngIf="experience.skill_3 && (experience.skill_2 || experience.skill_1)">,</span>
                                <span *ngIf="experience.skill_3">{{experience.skill_3}}</span>
                            </strong>
                        </p>
                        <p class="m-0 text-ellipse">{{experience.main_role}}</p>
                        <div class="addRefRow">
                            <div class="expVerifyTeam" *ngIf="experience.page && experience.related_data.exp_count > 0">
                                <p class="my-0 people-number">
                                    <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                    <span>{{ experience.related_data.exp_count }}</span>
                                </p>
                            </div>
    
                            <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                                <img alt="image" src="assets/images/verifyProfile.svg">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #viewAwardsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Awards and Credentials</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box pb-2">
            <div class="media">
                <div class="imgSec" *ngIf="editAward.document_name">
                    <a *ngIf="!isImage(editAward.document_name)" href="{{editAward.document}}" target="_blank">
                        <img alt="image" class="for-preview-image" src="assets/images/pdf-icon.svg">
                    </a>
                    <a *ngIf="isImage(editAward.document_name)" href="{{editAward.document}}" target="_blank">
                        <img alt="image" class="for-preview-image" src="{{editAward.document}}">
                    </a>
                </div>
                <div class="imgSec" *ngIf="!editAward.document_name">
                    <img alt="image" class="for-preview-image" src="assets/images/default.svg">
                </div>
                <div class="media-body ml-3">
                    <div class="awardsData">
                        <h3 class="m-0">{{editAward.title}}</h3>
                        <p class="mb-2">{{editAward.year | date: 'yyyy'}}</p>
                        <a *ngIf="editAward.link" href="{{editAward.link}}" target="blank">{{editAward.link}}</a>
                        <div class="awardsBadge" *ngIf="editAward.skills_gained && editAward.skills_gained.length > 0">
                            <span class="btn-dark-badge mr-2" *ngFor="let skill_gained of editAward.skills_gained">{{skill_gained}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
