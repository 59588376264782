import { Component, OnInit,TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-student-onboard',
  templateUrl: './student-onboard.component.html',
  styleUrls: ['./student-onboard.component.scss']
})

export class StudentOnboardComponent implements OnInit {
  @Input() user: any = {};
  searchForm: FormGroup;
  modalRef: BsModalRef;
  schools = [];
  submitted: boolean = false;
  onStart: boolean = true;
  today = new Date();
  @Output() firstLogin = new EventEmitter<boolean>();

  constructor(private modalService: BsModalService,private vps: ViewportScroller, private router: Router,
    private authService: AuthenticationService, public formBuilder: FormBuilder, private pageService: PageService) {
      this.searchForm = this.formBuilder.group({
        school_name: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50), this.noWhitespaceValidator]),
      });
  }

  ngOnInit(): void {
    document.body.classList.add("clickbuttonview");
    if (!this.user.first_login) {
      this.doneEvent();
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  config = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered custom-modal new-modal-ui step-modal',
    animated: false
  };
  openStep2Modal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,this.config);
    document.body.classList.remove("clickbuttonview");
  }
  interestedViewEvent(){
    this.onStart = false;
    document.body.classList.add("interestedview");
    this.modalRef.hide();
  }
  nextViewEvent(id){
    document.body.classList.add("nextview");
    document.body.classList.remove("interestedview");
    this.vps.scrollToAnchor(id);
  }
  followingViewEvent(){
    document.body.classList.add("followingview");
    document.body.classList.remove("nextview");
  }
  doneEvent(){
    document.body.classList.remove("followingview");
    this.user.first_login = false;
    localStorage.setItem('user', JSON.stringify(this.user));
    this.firstLogin.emit(false);
  }
  searchSchool(isValid: boolean){
    this.submitted = true;
    if (isValid){
      this.schools = [];
      let key = this.searchForm.controls['school_name'].value;
      let params = key.toLowerCase();
      this.authService.searchSchool(params)
      .subscribe(response => {
        if (response.status == 200){
          this.schools = response.response;
        } else {
          this.schools = [];
        }
      }, error => {
        this.schools = [];
      });
    }
  }

  followSchool(id: any){
    this.pageService.followSchool(id, {})
    .subscribe(response => {
      this.interestedViewEvent();
    }, error => {
      this.interestedViewEvent();
    });
  }

  showBtn(school: any){
    if ((this.user.age_group == 3 && school.is_college) || this.user.age_group !== 3) {
      return true;
    }
    return false;
  }
  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };
}
