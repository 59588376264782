<section class="profile-head business-section newProfile">
<div class="tab-content-box">
    <app-page-header (tabActive)="changeTab($event)" [page]="pagesDetails" [profile]="profile"></app-page-header>
    <div class="tabWrap mb-0">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12">
                    <ul class="tabBtns no-list">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                          }" (click)="tabActive=1;">
                            Timeline
                        </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                          }" (click)="tabActive=2">
                            About 
                        </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 3
                          }" (click)="tabActive=3;getTeamItems()">
                            Team
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>
    <div class="custom-tab-dark box-shadow-box">
        <div *ngIf="tabActive == 1">
            <app-timeline [pagesDetails]="pagesDetails" [page_id]="page_id"></app-timeline>
        </div>

        <div *ngIf="tabActive == 2">
            <div class="impactBlockOuter">
                
                    <div class="impactBlock backWhite">
                            <div class="eachBlock">
                                <div class="topHdg">
                                    <h3 class="hdgName">
                                        <img *ngIf="linkArray && linkArray.length < 10" (click)="linkModal(linkTemplate)" class="hdg-icon cursor-pointer"
                                            src="assets/images/plus-teal.svg">
                                        Links
                                    </h3>
                                </div>
                                <div class="blockDes blockDesLinks">
                                        <div class="image-with-del mr-0 d-flex" *ngFor="let link of linkArray">
                                                <a href="{{ link.link }}" target="_blank">
                                                    <div class="mx-auto">
                                                        <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}" class="icon">
                                                    </div>
                                                    {{ link.title }}
                                                </a>
                                                <div class="dtl">
                                                    <a href="javascript:void()" class="del-icon" (click)="delLink(link.id)">
                                                        <img alt="icon" src="assets/images/new-dustbin-teal.svg" />
                                                    </a>
                                                </div>
                                            </div>
                                </div>
                            </div>
        
                            <div class="eachBlock">
                                <div class="topHdg">
                                    <h3 class="hdgName">
                                        <img alt="icon" (click)="aboutUsModal(aboutUsTemplate)" class="hdg-icon cursor-pointer"
                                            src="assets/images/editIcon.svg">
                                        About Us
                                    </h3>
                                </div>
                                <div class="blockDes blockMission" *ngIf="aboutUs" [ngClass]="{ '': infoDiv1, showDetailContent: !infoDiv1}">
                                    <p class="truncatedText" innerHtml="{{ aboutUs | slice:0:400 }}"></p>
                                    <p class="fullText" innerHtml="{{ aboutUs }}"></p>
                                    <a *ngIf="(aboutUs + '').length > 400" href="javascript:void();" (click)="infoDiv1 = !infoDiv1"
                                        class="d-block text-center showMore">
                                        <img src="assets/images/new-arrow.svg" alt="icon">
                                    </a>
                                </div>
                            </div>
        
                            <!-- <div class="eachBlock mb-0">
                                <div class="topHdg topHdgWithLink">
                                    <h3 class="hdgName">
                                        this week
                                    </h3>
        
                                    <a class="hdgLink cursor-pointer" (click)="tabActive=2">View calendar</a>
                                </div>
        
                                <div class="blockDes">
                                    <div class="calenderCards">
                                        <ul class="no-list calenderCardsList">
                                            <li *ngFor="let eachEvent of weekEvents">
                                                <div class="cardsInner">
                                                    <div class="topName">{{ eachEvent.start_date | date: 'MMMM d'  }}</div>
                                                    <div class="btmdes">{{ eachEvent.title }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <div class="calenderWrapOuter">
                                    <app-page-event-calendar [pageId]="page_id">
                                    </app-page-event-calendar>
                                </div>
                        </div>
            </div>

        </div>

        <div *ngIf="tabActive == 3">
            <div class="initiativeTeamBlock">
                <div class="initiativeTeamSlider mb-4 mb-md-5 pr-0 pl-0">
                        <div class="d-flex align-items-center mb-3 withRadBlueName">
                                <h3 class="blueHdg">Team</h3>
                            </div>
                    <div class="search-box initiativeVolunteerAdd pt-0 pb-0">
                            <div class="left-part">
                                    <div class="form-group">
                                            <input type="text" #searchInp placeholder="Search Name" class="form-control" (keyup)="searchStudent($event)">
                                        </div>
                            </div>
                            <div class="right-part">

                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                            <span>Invite members</span>
                                        </a>
                            </div>
                          </div>
                    <div class="initiativeTeamSlider mb-2 mb-md-12 initiativeTeamSliderMob35">
                        <div class="team-list-box">
                          <div class="impactNoResult" *ngIf="teamMembers.length == 0">
                            <h5 class="m-0">No data exists.</h5>
                          </div>
                            <div class="team-box" *ngFor="let member of teamMembers">
                                <div class="description-side">
                                    <div class="img-box">
                                        <img src="{{member.student_info.display_pic ? member.student_info.display_pic: 'assets/images/p-img.png'}}"
                                          alt="profile" (click)="directToProfile(member)" [ngClass]="{'cursor-pointer': checkClick(member)}"/>
                                    </div>
                                    <div class="para-box">
                                        <div class="relative">
                                            <h3>{{member.student_info.student_name}}</h3>
                                            <p *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                                                <span class="dot"></span>
                                                <span *ngIf="member.student_info.connection == 1">1st</span>
                                                <span *ngIf="member.student_info.connection == 2">2nd</span>
                                            </p>
                                        </div>
                                        <p *ngIf="member.position">{{member.position}}</p>
                                    </div>
                                </div>
                                <div class="add-network" *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                                    <button type="button" *ngIf="member.student_info.connection == 1" (click)="openModal(member, remNetworkModal)">
                                        <img src="/assets/images/add-network-teal-icon.svg" alt="remove" />
                                        Remove
                                    </button>
                                    <button type="button" *ngIf="member.student_info.connection == 2" (click)="openModal(member, networkModal)">
                                        <img src="/assets/images/add-network-teal-icon.svg" alt="add-network" />
                                        Add to Network
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad mt-0" *ngIf="totalTeamMembers > 10 && totalTeamMembers > teamMembers.length">
                          <a href="javascript:void(0);" class="mb-4" (click)="getMembers()">Load More</a>
                        </div>
                      </div>
                </div>

                <div class="initiativeVolunteerAdd pt-0 pb-0">
                        <div  class="topHdg">
                                <h3  class="hdgName">
                                  <img (click)="leadersModal(leaderTemplate)" alt="icon" src="assets/images/plus-teal.svg" class="hdg-icon cursor-pointer">
                                 Leaders </h3>
                              </div>
                </div>

                <div class="initiativeVolunteerAdd pt-0">
                    <div class="managementWithRole">
                            <div class="displayVolunteerList withDelIcon pt-0">

                                <div class="team-list-box leaders-list">
                                        <div class="team-box" *ngFor="let volunteer of managementList">
                                                <div class="description-side">
                                                    <div class="para-box">
                                                            <div class="relative">
                                                                    <h3>{{ volunteer.first_name }} {{ volunteer.last_name | slice:0:1 }}</h3>
                                                            </div>
                                                            <p>{{ volunteer.position }}</p>
                                                    </div>
                                                </div>
                                                <span class="delIcon cursor-pointer">
                                                        <img alt="icon" src="/assets/images/new-dustbin-teal.svg" (click)="deleteVol(volunteer.id)">
                                                </span>
                                            </div>
                                </div>
                                  </div>

                        <div class="timeLineLoad pt-4" *ngIf="totalManagements > 10 && totalManagements > managementList.length">
                            <a href="javascript:void(0);" (click)="laodMoreManagements()">Load More</a>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div *ngIf="tabActive == 4">
            <app-page-experiences></app-page-experiences>
        </div>

        <div *ngIf="tabActive == 5">
            <app-page-setting></app-page-setting>
        </div>

    </div>
</section>
<ng-template #linkTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add links</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="linkForm" novalidate>
                <div class="media">
                    <div class="imgSecPopup">
                        <img alt="image" class="preview-image"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                        <ul *ngIf="previewThumbnail" class="d-flex imgIcon pl-0 m-0 no-list">
                            <li>
                                <a href="javascript:void()" (click)="blankLinkImageUpload(linkModal)">
                                    <img alt="icon" src="assets/images/newDelete.svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="media-body ml-3">
                        <div class="form-group">
                            <!-- <label for="">Add Cover Image</label> -->
                            <input type="file" placeholder="Type Here" class="fileUpload"
                                (change)="coverImageUpload($event)" #linkModal>
                            <span class="fileText cursor-pointer">Choose File</span>
                            <div *ngIf="coverImageUploadError" class="error-message">
                                {{coverImageUploadMessage}}
                            </div>
                            <div *ngIf="coverImageUploadSizeError" class="error-message">
                                {{coverImageUploadSizeMessage}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Title <span>*</span></label>
                                <input type="text" class="form-control" placeholder="Type Here"
                                    formControlName="title" />
                                <div class="error-message"
                                    *ngIf="linkForm.get('title').hasError('required') && (linkForm.get('title').dirty || linkForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="linkForm.get('title').hasError('maxlength') && (linkForm.get('title').dirty || linkForm.get('title').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Link <span>*</span></label>
                                <input type="text" class="form-control" placeholder="Type Here"
                                    formControlName="link" />
                                <div class="error-message"
                                    *ngIf="linkForm.get('link').hasError('required') && (linkForm.get('link').dirty || linkForm.get('link').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="linkForm.get('link').hasError('maxlength') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                    Max 250 characters allowed.
                                </div>
                                <div class="error-message"
                                    *ngIf="linkForm.get('link').hasError('pattern') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                    Please enter a valid URL.
                                </div>
                                <div class="error-message" *ngIf="linkForm.get('link').hasError('aftersubmit')">
                                    Please enter a valid URL.
                                </div>
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="addLink(linkForm.valid)">save</button>
    </div>
</ng-template>
<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                <p class="inviteCopySbNote">Join my network at {{pagesDetails?.page_info?.title}} on ConnectUs.today by signing up using the following link: <strong>connectus.today/signin</strong></p>
                <div class="inviteBorder">
                    <div class="custom-tooltip copy-tooltip">
                        <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                        <div class="tooltip-data" *ngIf="copyMessageBool">Message Copied</div>
                    </div>
                </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" *ngIf="!invitationSuccess" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="modalRef.hide()">Done</button>
    </div>
</ng-template>
<ng-template #leaderTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Leaders</h4>
          <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-box">
                <form [formGroup]="managementForm" novalidate>
                            <div class="form-group">
                                    <label class="m-0">First Name</label>
                                <input type="text"  class="form-control text-capitalize" formControlName='firstName'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('firstName').hasError('required') && (managementForm.get('firstName').dirty || managementForm.get('firstName').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('firstName').hasError('maxlength') && (managementForm.get('firstName').dirty || managementForm.get('firstName').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
    
                            <div class="form-group">
                                    <label class="m-0">Last Name</label>
                                <input type="text"  class="form-control text-capitalize" formControlName='lastName'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('lastName').hasError('required') && (managementForm.get('lastName').dirty || managementForm.get('lastName').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('lastName').hasError('maxlength') && (managementForm.get('lastName').dirty || managementForm.get('lastName').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
    
                            <div class="form-group">
                                    <label class="m-0">Position/Role</label>
                                <input type="text"  class="form-control" formControlName='position'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('position').hasError('required') && (managementForm.get('position').dirty || managementForm.get('position').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('position').hasError('maxlength') && (managementForm.get('position').dirty || managementForm.get('position').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="modal-footer">
                                    <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">cancel</button>
                                    <button class="btn btn-footer" (click)="addManagement(managementForm.valid)">Add</button>
                                  </div>
                    </form>
          </div>
        </div>
      
      </ng-template>
<ng-template #aboutUsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="descriptionForm" novalidate>
            <ckeditor [editor]="Editor" formControlName="about_us"
                [config]="{ link: {addTargetToExternalLinks: true}, toolbar: [ 'link', 'bold', 'italic', 'bulletedList', 'numberedList' ], placeholder: 'Description', baseFloatZIndex:100001}">
            </ckeditor>
            <div class="error-message"
                *ngIf="descriptionForm.get('about_us').hasError('required') && (descriptionForm.get('about_us').dirty || descriptionForm.get('about_us').touched || submitted)">
                This field is required.
            </div>
            <div class="error-message"
                *ngIf="descriptionForm.get('about_us').hasError('maxlength') && (descriptionForm.get('about_us').dirty || descriptionForm.get('about_us').touched)">
                Max 1000 characters allowed.
            </div>
            <div class="error-message" *ngIf="descriptionForm.hasError('aftersubmit')">
                Something went wrong.
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="!aboutUs">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">save</button>
    </div>
    <div class="modal-footer" *ngIf="aboutUs">
        <button class="btn btn-footer" (click)="deleteDescription()">Delete</button>
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">Update</button>
    </div>
</ng-template>

<ng-template #networkModal>
    <div  class="modal-header">
        <h4  class="modal-title pull-left">Add to network</h4>
        <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-add-team [member]="selectedUser"></app-page-add-team>
    </div>
</ng-template>
<ng-template #remNetworkModal>
    <div  class="modal-header">
        <h4  class="modal-title pull-left">Remove from network</h4>
        <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-rem-team [member]="selectedUser"></app-page-rem-team>
    </div>
</ng-template>
