<ng-template [ngIf]="isDashboard" [ngIfElse]="isProfile">
    <div class="col-md-12 text-center">
        <a href="javascript:void();" class="mr-3" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"
            (click)="getEvents()">
            <img src="assets/images/arrow-left-new.svg" class="align-top">
        </a>
        <h3 class="month-title d-inline-block">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        <a href="javascript:void();" class="float-right" (click)="addEventPopup(eventPopup)" title="Add Event">
            <img src="assets/images/add-icon.svg" class="align-top">
        </a>
        <a href="javascript:void();" class="ml-3" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"
            (click)="getEvents()"> 
            <img src="assets/images/arrow-right-new.svg" class="align-top">
        </a>
    </div>
    <div class="col-md-12">
        <div [ngSwitch]="view" class="mt-4">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)" (eventClicked)="updateEventPopup(eventPopup, $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
                [refresh]="refresh">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="updateEventPopup(eventPopup, $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="updateEventPopup(eventPopup, $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh">
            </mwl-calendar-day-view>
        </div>
    </div>
</ng-template>

<ng-template #isProfile>
    <h3>Calendar
        <a *ngIf="!isEditable && pageVerified != '3' " href="javascript:void();" class="float-right" (click)="addEventPopup(eventPopup)">
            <img src="assets/images/plusnew.svg">
        </a>
    </h3>
    <div class="box-div">
        <div [ngSwitch]="view" class="mt-4">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)" (eventClicked)="updateEventPopup(eventPopup, $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
                [refresh]="refresh">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="updateEventPopup(eventPopup, $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="updateEventPopup(eventPopup, $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh">
            </mwl-calendar-day-view>
        </div>
    </div>
</ng-template>

<ng-template #eventPopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="addNew; else eventEditBlock">Add Event</h4>
        <ng-template #eventEditBlock><h4 class="modal-title pull-left">Edit Event</h4></ng-template>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="eventForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here" formControlName="title" maxlength=50>
                        <div class="error-message" *ngIf="eventForm.get('title').hasError('required') && (eventForm.get('title').dirty || eventForm.get('title').touched || eventSubmitted)">
                            Title is required.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="eventForm.get('title').hasError('maxlength') && (eventForm.get('title').dirty || eventForm.get('title').touched)">
                        Max 50 characters allowed.
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <div class="col-md-2">
                        <label>Dates</label>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Start
                                    <input readonly type="text" class="form-control modal-control modal-field" placeholder="dd mm yy" bsDatepicker formControlName="start_date" [bsConfig]="{adaptivePosition: true}">
                                    <a href="javascript:void(0);" (click)="resetStartDate()"><img src="assets/images/plusrotate.svg" class="img-cross"></a>
                                </label>
                            </div>
                            <div class="col-md-6">
                                <label for="">End
                                    <input readonly type="text" class="form-control modal-control modal-field" placeholder="dd mm yy" bsDatepicker formControlName="end_date" [bsConfig]="{adaptivePosition: true}">
                                    <a href="javascript:void(0);" (click)="resetEndDate()"><img src="assets/images/plusrotate.svg" class="img-cross"></a>
                                </label>
                            </div>
                        </div>
                        <div class="error-message" *ngIf="eventForm.get('start_date').hasError('required') && (eventForm.get('start_date').dirty || eventForm.get('start_date').touched || eventSubmitted)">
                            Enter a valid start date
                        </div>
                        <div class="error-message" *ngIf="eventForm.errors?.startEndValid && (eventForm.get('start_date').touched || eventForm.get('start_date').dirty || eventForm.get('end_date').touched || eventForm.get('end_date').dirty)">
                            Start date should not be greater than end date
                        </div>
                        <div class="error-message" *ngIf="eventForm.get('start_date').hasError('aftersubmit')">
                            {{eventForm.controls.start_date.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="addNew; else eventUpdateBlock">
        <button class="btn btn-footer" (click)="addEventSubmit(eventForm.valid)" [disabled]="disableSubmit">
            Save
        </button>
    </div>
    <ng-template #eventUpdateBlock>
        <div class="modal-footer">
            <button class="btn btn-footer" (click)="updateEventSubmit(eventForm.valid, false)" [disabled]="disableSubmit">
                Update
            </button>
            <button class="btn btn-footer" (click)="updateEventSubmit(eventForm.valid, true)" [disabled]="disableSubmit">
                Delete
            </button>
        </div>
    </ng-template>
</ng-template>