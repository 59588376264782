import { Component, OnInit, EventEmitter } from '@angular/core';  
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { SearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-profiles',
  templateUrl: './search-profiles.component.html',
  styleUrls: ['./search-profiles.component.scss']
})
export class SearchProfilesComponent implements OnInit { 
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  searchForm: FormGroup;
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  public showAddressError: boolean = false;
  submitted: boolean = false;
  searchPlaces: any[] = [];
  businessProfiles: any[] = [];
  schoolProfiles: any[] = [];
  updateAddress: string = '';
  searchKeys: any[] = [];
  businessNextPage: any;
  schoolNextPage: any;
  totalbusinessProfiles: any;
  totalschoolProfiles: any;
  loading: boolean = false;
  placeHolder: string = "My Location";
  activeTab: string = "school";
  showLocation: boolean = true;
  keywordsErr: boolean = false;
  tabActive = 3;
  currentUser: any;

  constructor(private searchService: SearchService, private router: Router, public formBuilder: FormBuilder, private authService: AuthenticationService) {
    this.searchForm = this.formBuilder.group({
      search_key: new FormControl('', [Validators.maxLength(50)]),
      location: new FormControl('', Validators.maxLength(200))
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.profilePrivacy();
    this.initiateSearch();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  profilePrivacy(){
    this.authService.ProfilePrivacySettingGet()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.showLocation = response.response[0].location_display;
        if (!this.showLocation) {
          this.placeHolder = "Full Address";
        }
      }
    })
  }

  initiateSearch() {
    let params = this.setParams();
    this.businessProfileSearch(params);
    this.schoolProfileSearch(params);
  }

  businessProfileSearch(params: any) {
    this.searchService.businessProfileSearch(params)
    .subscribe(data => {
      this.businessProfiles = data.response.results;
      this.businessNextPage = data.response.next;
      this.totalbusinessProfiles = data.response.total_count;
      this.onTabChange(this.activeTab);
    })
  }

  schoolProfileSearch(params: any) {
    this.searchService.schoolProfileSearch(params)
    .subscribe(data => {
      this.schoolProfiles = data.response.results;
      this.schoolNextPage = data.response.next;
      this.totalschoolProfiles = data.response.total_count;
      this.onTabChange(this.activeTab);
    })
  }

  setParams() {
    let params = "?search=" + this.searchKeys.join("+");
    if (this.latitude && this.longitude) {
      params += "&lat=" + this.latitude + "&lng=" + this.longitude;
    }
    return params;
  }

  search(isValid: boolean) {
    this.submitted = true;
    if (this.searchForm.get('search_key').value || this.updateAddress.length > 0) {
      let search_key = this.searchForm.get('search_key').value;
      if (search_key !== "" && search_key !== null && search_key.trim().length > 0 && !(this.showLocation == false && this.updateAddress.length == 0)){
        if (this.searchKeys.length < 3) {
          this.keywordsErr = false;
          this.searchKeys.push(this.searchForm.get('search_key').value);
        } else {
          this.keywordsErr = true;
          this.focusOutErrMsg();
        }
        this.searchForm.reset();
      }
      if (!this.showLocation && !this.updateAddress) {
        return;
      }
      this.submitted = false;
      this.initiateSearch();
    }
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.showAddressError = true;
      this.latitude = null;
      this.longitude= null;
      this.updateAddress = "";
    } else {
      this.showAddressError = false;
      this.latitude = null;
      this.longitude= null;
    }
  }

  delKey(key: string) {
    if (key) {
      let index = this.searchKeys.findIndex(obj => obj == key);
      this.searchKeys.splice(index, 1);
      if (this.searchKeys.length == 0) {
        this.updateAddress = "";
        this.latitude = null;
        this.longitude= null;
      }
    } else {
      this.searchKeys = [];
      this.updateAddress = "";
      this.latitude = null;
      this.longitude= null;
    }
    this.initiateSearch();
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    this.updateAddress = place.address.freeformAddress;
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

  onBusinessScrollDown() {
    if (this.businessNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.businessNextPage;
      this.searchService.businessProfileSearch(params)
        .subscribe(data => {
          this.businessProfiles = this.businessProfiles.concat(data.response.results);
          this.businessNextPage = data.response.next;
          this.loading = false;
          this.onTabChange(this.activeTab);
        })
    }
  }

  onSchoolScrollDown() {
    if (this.schoolNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.schoolNextPage;
      this.searchService.schoolProfileSearch(params)
        .subscribe(data => {
          this.schoolProfiles = this.schoolProfiles.concat(data.response.results);
          this.schoolNextPage = data.response.next;
          this.loading = false;
          this.onTabChange(this.activeTab);
        })
    }
  }
  onTabChange(tab: any){
    this.activeTab = tab;
    if (tab === "school") {
      this.tabActive = 3;
    } else if (tab === "business") {
      this.tabActive = 1;
    }
  }

  focusOutFunction() {
    setTimeout(() => {
      this.hidePlaceResults = true;
      this.searchPlaces = [];
    }, 1000);
  }

  focusOutErrMsg() {
    setTimeout(() => {
      this.keywordsErr = false;
    }, 6000);
  }

  redirectBusiness(businessProfile: any){
    if (businessProfile.owner === this.currentUser.id){
      this.router.navigate(['page/' + businessProfile.id + '/profile-business'])
    } else {
      this.router.navigate(['page/' + businessProfile.id + '/'])
    }
  }

}
