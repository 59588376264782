<div class="content-section">
    <div class="profile-div">
        <div class="profile-inner">
            <h3>Biography <a href="javascript:void()" class="float-right" (click)="openModal(descriptionTemplate)">
                <img  alt="image" src="assets/images/plusnew.svg" alt="">
            </a></h3>
            <div class="box-div">
                <ul class="pl-0 d-flex">
                    <li>
                        <p>{{userProfile.profile_keyword_1}}</p>
                    </li>
                    <li>
                        <p>{{userProfile.profile_keyword_2}}</p>
                    </li>
                    <li>
                        <p>{{userProfile.profile_keyword_3}}</p>
                    </li>
                </ul>
                <p class="px-1">{{userProfile.description}}</p>
            </div>
        </div>
        <div class="profile-inner slider-div">
            <h3>Biography</h3>
            <carousel>
                <slide>
                    <div class="box-div inner-exp">
                        <div class="experience-div media">
                            <img  alt="image" src="assets/images/exp-logo.png">
                            <div class="media-body ml-2">
                                <h4>McMaster University
                                    <span>Hamilton, ON</span>
                                </h4>
                                <p>Bachelor of Commerce, Marketing</p>
                                <h5>Start
                                    <span>2017</span> End
                                    <span>2020</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide>
                    <div class="box-div inner-exp">
                        <div class="experience-div media">
                            <img  alt="image" src="assets/images/exp-logo.png">
                            <div class="media-body ml-2">
                                <h4>McMaster University2
                                    <span>Hamilton, ON</span>
                                </h4>
                                <p>Bachelor of Commerce, Marketing</p>
                                <h5>Start
                                    <span>2017</span> End
                                    <span>2020</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide>
                    <div class="box-div inner-exp">
                        <div class="experience-div media">
                            <img  alt="image" src="assets/images/exp-logo.png">
                            <div class="media-body ml-2">
                                <h4>McMaster University3
                                    <span>Hamilton, ON</span>
                                </h4>
                                <p>Bachelor of Commerce, Marketing</p>
                                <h5>Start
                                    <span>2017</span> End
                                    <span>2020</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
        <div class="profile-inner full-inner">
            <h3>WORK EXPERIENCE
                <a href="javascript:void()" class="float-right" (click)="openModal(template)">
                    <img  alt="image" src="assets/images/plusnew.svg" alt="">
                </a>
            </h3>
            <div class="box-div inner-exp">
                <div class="experience-div media">
                    <img  alt="image" src="assets/images/exp-logo.png">
                    <div class="media-body ml-2">
                        <h4>McDonald's</h4>
                        <p>Cashier</p>
                        <h5>Start
                            <span>2017</span> End
                            <span>2020</span>
                        </h5>
                    </div>
                </div>
                <div class="side-col">
                    <ul class="d-flex pl-0">
                        <li>
                            <p>Teamwork</p>
                        </li>
                        <li>
                            <p>Organization</p>
                        </li>
                        <li>
                            <p>Attention to Detail</p>
                        </li>
                    </ul>
                    <p>Engaged with clients while taking their orders.</p>
                </div>
            </div>
        </div>
        <div class="profile-inner full-inner colorOdd">
            <h3>Volunteer Experience</h3>
            <div class="box-div inner-exp">
                <div class="experience-div media">
                    <img  alt="image" src="assets/images/exp1-logo.png">
                    <div class="media-body ml-2">
                        <h4>UNICEF</h4>
                        <p>Events Coordinator</p>
                        <h5>Start
                            <span>2017</span> End
                            <span>2020</span>
                        </h5>
                    </div>
                </div>
                <div class="side-col">
                    <ul class="d-flex pl-0">
                        <li>
                            <p>Teamwork</p>
                        </li>
                        <li>
                            <p>Organization</p>
                        </li>
                        <li>
                            <p>Attention to Detail</p>
                        </li>
                    </ul>
                    <p>Organized activities and led a group of volunteers to ensure the events went smoothly.
                    </p>
                </div>
            </div>
        </div>
        <div class="profile-inner full-inner colorOdd">
            <h3>Work Experience
                <a href="javascript:void()" class="float-right" (click)="openModal1(template1)">
                    <img  alt="image" src="assets/images/plusnew.svg" alt="">
                </a>
            </h3>
            <div class="box-div inner-exp">
                <div class="experience-div media">
                    <img  alt="image" src="assets/images/exp1-logo.png">
                    <div class="media-body ml-2">
                        <h4>UNICEF</h4>
                        <p>Events Coordinator</p>
                        <h5>Start
                            <span>2017</span> End
                            <span>2020</span>
                        </h5>
                    </div>
                </div>
                <div class="side-col">
                    <ul class="d-flex pl-0">
                        <li>
                            <p>Teamwork</p>
                        </li>
                        <li>
                            <p>Organization</p>
                        </li>
                        <li>
                            <p>Attention to Detail</p>
                        </li>
                    </ul>
                    <p>Organized activities and led a group of volunteers to ensure the events went smoothly.
                    </p>
                </div>
            </div>
        </div>
        <div class="profile-inner">
            <h3>Skills
                <a href="javascript:void()" class="float-right" (click)="skillModal(skillpopup)">
                    <img  alt="image" src="assets/images/plusnew.svg" alt="">
                </a>
            </h3>
            <div class="box-div">
                <ul class="pl-0 d-flex skills-listing">
                    <li>
                        <p>Technology</p>
                    </li>
                    <li>
                        <p>Speaking</p>
                    </li>
                    <li>
                        <p>Leadership</p>
                    </li>
                    <li>
                        <p>Powerpoint</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="profile-inner">
            <h3>Interest</h3>
            <div class="box-div">
                <ul class="pl-0 d-flex skills-listing">
                    <li>
                        <p>Technology</p>
                    </li>
                    <li>
                        <p>Speaking</p>
                    </li>
                    <li>
                        <p>Leadership</p>
                    </li>
                    <li>
                        <p>Powerpoint</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="profile-inner full-inner">
            <h3>Books</h3>
            <carousel>
                <slide>
                    <div class="box-div d-flex">
                        <div class="books-listing">
                            <h3>The Happiness Advantage</h3>
                            <h4>Shawn Achor</h4>
                            <ul class="star-rating d-flex pl-0 m-0 align-items-center">
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/unchecked-star.png">
                                </li>
                            </ul>
                            <div class="inner-listing">
                                These are my thoughts on The Happiness Advantage - It was very interesting and insightful, here is exactly why: ...
                            </div>
                        </div>
                        <div class="books-listing">
                            <h3>The Happiness Advantage</h3>
                            <h4>Shawn Achor</h4>
                            <ul class="star-rating d-flex pl-0 m-0 align-items-center">
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/unchecked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/unchecked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/unchecked-star.png">
                                </li>
                            </ul>
                            <div class="inner-listing">
                                These are my thoughts on The Happiness Advantage - It was very interesting and insightful, here is exactly why: ...
                            </div>
                        </div>
                    </div>
                </slide>
                <slide>
                    <div class="box-div d-flex">
                        <div class="books-listing">
                            <h3>The Happiness Advantage2</h3>
                            <h4>Shawn Achor</h4>
                            <ul class="star-rating d-flex pl-0 m-0 align-items-center">
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                            </ul>
                            <div class="inner-listing">
                                These are my thoughts on The Happiness Advantage - It was very interesting and insightful, here is exactly why: ...
                            </div>
                        </div>
                        <div class="books-listing">
                            <h3>The Happiness Advantage</h3>
                            <h4>Shawn Achor</h4>
                            <ul class="star-rating d-flex pl-0 m-0 align-items-center">
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                                <li>
                                    <img  alt="image" src="assets/images/checked-star.png">
                                </li>
                            </ul>
                            <div class="inner-listing">
                                These are my thoughts on The Happiness Advantage - It was very interesting and insightful, here is exactly why: ...
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</div>

<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Description</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>top 3</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="First" formControlName="profile_keyword_1">
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                            Max 30 characters allowed.
                        </div>
                        <input type="text" class="form-control modal-control" placeholder="First" formControlName="profile_keyword_2">
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                            Max 30 characters allowed.
                        </div>
                        <input type="text" class="form-control modal-control" placeholder="First" formControlName="profile_keyword_3">
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="description"> </textarea>
                        <div class="error-message" *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1000 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>                
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">
            Save
        </button>
        <button class="btn btn-footer">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
    </div>
    <div class="modal-body">
        <div class="modal-box scroll-activity scrollbar">
            <div class="experience-div media w-100">
                <img  alt="image" src="assets/images/exp-logo.png">
                <div class="media-body ml-2">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Business</label>
                        </div>
                        <div class="col-md-9">
                            <label>Mc Donalds</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label>Position</label>
                        </div>
                        <div class="col-md-9">
                            <h4>Cashier</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label>Dates</label>
                        </div>
                        <div class="col-md-9">
                            <p>
                                <span>Start</span> August 12, 2017
                                <span>Ends</span> August 12, 2018</p>
                        </div>
                    </div>
                </div>
            </div>
            <form>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>top 3</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control">
                        <input type="text" class="form-control modal-control">
                        <input type="text" class="form-control modal-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Main Role</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Personal Experience</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control"> </textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer">
            Delete
        </button>
        <button class="btn btn-footer">
            Save
        </button>
    </div>
</ng-template>
<ng-template #template1>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Business</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Position</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Dates</label>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-4">
                                <label for="">Start
                                    <input type="text" class="form-control" placeholder="dd mm yy" bsDatepicker>
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label for="">End
                                    <input type="text" class="form-control" placeholder="dd mm yy" bsDatepicker>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="formShow">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>top 3</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control">
                        <input type="text" class="form-control modal-control">
                        <input type="text" class="form-control modal-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Main Role</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Personal Experience</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control"> </textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="showForm()">
            Next
        </button>
    </div>
</ng-template>
<ng-template #skillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer">
            Add
        </button>
    </div>
</ng-template>