import { Component, OnInit,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {
  loggedIn: boolean = true;
  isSenior: boolean = true;
  user: any = {};
  modalRef: BsModalRef;
  pageslist : any =[]
  pagetitle : string;
  pagecountry : string;
  pagestate : string;
  pagepic: string;
  page_id: string;
  pagetype: string;
  pagetypebusiness :any;
  params :string = "";
  instance_id :string;
  profileImageHeader: string ;
  profileImageHeader_name: string ;
  day: string = "";
  pagepic_name: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.day = new Date().toLocaleString('en-us', {  weekday: 'long' })
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.profileImageHeader = this.user.display_picture;
      this.profileImageHeader_name = this.user.display_picture;
      if (this.user.age_group == 3) {
        this.isSenior = true;
      } else {
        this.isSenior = false;
      }
    } else {
      this.router.navigate(['/']);
    } 
  }

}
