<div *ngIf="awards.length > 0" class="content-profile-white-box">
<div class="titleDiv d-flex align-items-center">
    <h3 class="with-list-icon">Awards and Credentials
    </h3>
    <a href="javascript:void()" class="float-right" (click)="openModalAwards(awardsTemplate)">
        <img class="d-block" src="assets/images/plus-teal.svg" alt="icon">
    </a>
</div>
<div class="detailContent px-0">
    <ul class="no-list awardsList">
        <li *ngFor="let award of awards">
            <div class="awardsDataLeft">
                <p class="name m-0 mr-2 cursor-pointer" (click)="editModalAwards(editAwardsTemplate, award)">{{award.title}}</p>
            </div>
            <div class="awardsDataRight">
                    <div class="forDotsImages">
                            <span *ngIf="award.link">
                                <img alt="image" class="" src="assets/images/awardsLinkIcon.svg">
                            </span>
                            
                            <span *ngIf="award.document">
                                <img alt="image" class="" src="assets/images/awardsFileIcon.svg">
                            </span>
                        </div>
                <p class="ml-2 my-0">{{award.year | date:'yyyy'}}</p>
            </div>
        </li>
    </ul>
</div>
</div>
<ng-template #awardsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Awards and Credentials</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="awardsForm" novalidate>
                <div class="experience-div media w-100">
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Title<span>*</span></label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="title">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('required') && (awardsForm.get('title').dirty || awardsForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('whitespace') && (!awardsForm.get('title').hasError('required')) && (awardsForm.get('title').dirty || awardsForm.get('title').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('maxlength') && (awardsForm.get('title').dirty || awardsForm.get('title').touched)">
                                    Max 75 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Year<span>*</span></label>
                            </div>
                            <div class="col-md-12">
                                <input type="number" placeholder="YYYY" class="form-control" formControlName="year">
                                <ng-container *ngFor="let error of error_messages.year">
                                    <div class="error-message"
                                        *ngIf="awardsForm.get('year').hasError(error.type) && (awardsForm.get('year').dirty || awardsForm.get('year').touched || submitted) ">
                                        {{ error.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12 mb-2">
                                <label for="">Document (ex. PDF, .png, .jpg, Word)</label>
                            </div>
                            <div class="col-md-12">
                                <input type="file" class="fileUpload" (change)="onFileSelect($event)" #awardsDocInp>
                                <span class="fileText cursor-pointer">Choose File</span>
                                <p class="mt-2 mb-0" *ngIf="uploadForm.get('document_name').value">
                                    {{this.uploadForm.get('document_name').value}}
                                </p>
                                <div *ngIf="fileUploadError" class="error-message">
                                    {{fileUploadMessage}}
                                </div>
                                <div *ngIf="fileUploadSizeError" class="error-message">
                                    {{fileUploadSizeMessage}}
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Link</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="link">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('link').hasError('pattern') && (awardsForm.get('link').dirty || awardsForm.get('link').touched)">
                                    Please enter a valid URL.
                                </div>
                                <div class="error-message"
                                    *ngIf="!(awardsForm.get('link').hasError('pattern')) && awardsForm.get('link').hasError('required') && (awardsForm.get('link').dirty || awardsForm.get('link').touched || submitted)">
                                    Please enter a valid URL before submit.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('link').hasError('maxlength') && (awardsForm.get('link').dirty || awardsForm.get('link').touched)">
                                    Max 250 characters allowed.
                                </div>
                                <div class="error-message" *ngIf="awardsForm.get('link').hasError('aftersubmit')">
                                    Please enter a valid URL.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Skills Gained (separate with a comma)</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" formControlName="skills_gained" placeholder="Skills Gained" class="form-control">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('skills_gained').hasError('arrElemValid') && (awardsForm.get('skills_gained').dirty || awardsForm.get('skills_gained').touched || submitted)">
                                    Please enter comma-separated skills containing less than 100 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addAwards(awardsForm.valid)">
            Add
        </button>
    </div>
</ng-template>

<ng-template #editAwardsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Awards and Credentials</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="awardsForm" novalidate>
                <div class="experience-div media w-100">
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Title<span>*</span></label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="title">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('required') && (awardsForm.get('title').dirty || awardsForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('whitespace') && (!awardsForm.get('title').hasError('required')) && (awardsForm.get('title').dirty || awardsForm.get('title').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('title').hasError('maxlength') && (awardsForm.get('title').dirty || awardsForm.get('title').touched)">
                                    Max 75 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Year<span>*</span></label>
                            </div>
                            <div class="col-md-12">
                                <input type="number" placeholder="YYYY" class="form-control" formControlName="year">
                                <ng-container *ngFor="let error of error_messages.year">
                                    <div class="error-message"
                                        *ngIf="awardsForm.get('year').hasError(error.type) && (awardsForm.get('year').dirty || awardsForm.get('year').touched || submitted) ">
                                        {{ error.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                       
                        <div class="row form-group">
                            <div class="col-md-12 mb-2">
                                <label for="">Document</label>
                            </div>
                            <div class="col-md-12" *ngIf="!editAwards.document">
                                <input type="file" class="fileUpload" (change)="onFileSelect($event)" #awardsDocInp>
                                <span class="fileText cursor-pointer">Choose File</span>
                                <p class="mt-2 mb-0" *ngIf="uploadForm.get('document_name').value">
                                    {{this.uploadForm.get('document_name').value}}
                                </p>
                                <div *ngIf="fileUploadError" class="error-message">
                                    {{fileUploadMessage}}
                                </div>
                                <div *ngIf="fileUploadSizeError" class="error-message">
                                    {{fileUploadSizeMessage}}
                                </div>
                            </div>

                            <div *ngIf="editAwards.document" class="col-md-10">
                                <div class="popupAwardsDelete">
                                    <a *ngIf="!isImage(editAwards.document_name)" href="{{editAwards.document}}" target="_blank"
                                    class="link-style">{{editAwards.document_name}}</a>
                                    <a *ngIf="isImage(editAwards.document_name)" href="{{editAwards.document}}" target="_blank">
                                        <img alt="image" src="{{editAwards.document}}" class="uploaded-img">
                                    </a>
                                    <a class="ml-2" href="javascript:void()" (click)="removeAwardDoc(editAwards.id)">
                                        <img alt="image" class="awardsDelete" src="assets/images/delete_icon.svg">
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Link</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" formControlName="link">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('link').hasError('pattern') && (awardsForm.get('link').dirty || awardsForm.get('link').touched)">
                                    Please enter a valid URL.
                                </div>
                                <div class="error-message"
                                    *ngIf="!(awardsForm.get('link').hasError('pattern')) && awardsForm.get('link').hasError('required') && (awardsForm.get('link').dirty || awardsForm.get('link').touched || submitted)">
                                    Please enter a valid URL before submit.
                                </div>
                                <div class="error-message"
                                    *ngIf="awardsForm.get('link').hasError('maxlength') && (awardsForm.get('link').dirty || awardsForm.get('link').touched)">
                                    Max 250 characters allowed.
                                </div>
                                <div class="error-message" *ngIf="awardsForm.get('link').hasError('aftersubmit')">
                                    Please enter a valid URL.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label for="">Skills Gained (separate with a comma)</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" formControlName="skills_gained" placeholder="Skills Gained" class="form-control">
                                <div class="error-message"
                                    *ngIf="awardsForm.get('skills_gained').hasError('arrElemValid') && (awardsForm.get('skills_gained').dirty || awardsForm.get('skills_gained').touched || submitted)">
                                    Please enter comma-separated skills containing less than 100 characters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer cancel-btn" (click)="delAward()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateAward(awardsForm.valid)">
            Update
        </button>
    </div>
</ng-template>
