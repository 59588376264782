import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from '../../services/page.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../services/authentication.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  modalRef: BsModalRef;
  public settingShow = true;
  public showSubmitMsg: boolean = false;
  submitMsg: string;
  invites = [];
  inviteObj: any = {};
  user: any = {};
  notifications = [];
  notifCount: any = 0;
  inviteCount: any = 0;
  notificationsNextPage: any = 1;
  totalNotifications: any = 0;
  tabActive = 1;

  constructor(private pageService: PageService, private modalService: BsModalService, private router: Router,
     private authservice: AuthenticationService, private profileService: ProfileService) {
      this.getNotifCount();
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.getAdminInvites();
    this.getNotifications();
    this.markNotifications();
  }

  getNotifCount(){
    let params = "?count=" + true;
    this.profileService.getUserNotifCount(params)
    .subscribe(response => {
      if (response.status === 200) {
        this.notifCount = response.response.notifs;
        this.inviteCount = response.response.invite_count;
      } else {
        this.notifCount = 0;
        this.inviteCount = 0;
      }
    });
  }

  getAdminInvites(){
    this.pageService.getUserInvites()
    .subscribe(response => {
      if(response.response.length > 0){
        this.invites = response.response;
      } else {
        this.invites = [];
      }
    })
  }

  getNotifications(){
    let params = '?page=' + this.notificationsNextPage;
    this.authservice.notifications(params)
    .subscribe(response => {
      if(response.response.results.length > 0) {
        if (this.notificationsNextPage == 1){
          this.notifications = response.response.results;
        } else {
          this.notifications = this.notifications.concat(response.response.results);
        }
        this.notificationsNextPage = response.response.next;
        this.totalNotifications = response.response.total_count;
      } else {
        this.notifications = [];
      }
    })
  }

  markNotifications(){
    this.profileService.markNotifications()
    .subscribe(response => {})
  }



  actionModal(actionModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(actionModal,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered new-modal-ui' })
    );
  }

  inviteAction(actionModal: TemplateRef<any>, adminInvite: any, accept:boolean=false) {
    this.submitMsg = "";
    this.showSubmitMsg = false;
    this.inviteObj = adminInvite;
    if (this.inviteObj.id) {
      this.pageService.inviteResponse(this.inviteObj.id, accept, this.inviteObj)
      .subscribe(response => {
        this.handleResponse(response);
        if (accept) {
          this.actionModal(actionModal);
        }
      }, error => {
        this.submitMsg = "Something went wrong. Please try again later.";
        this.showSubmitMsg = true;
        this.actionModal(actionModal);
      });
    }
  }

  handleResponse(response: any){
    if (response.status === 200) {
      let index = this.invites.indexOf(this.inviteObj);
      this.invites.splice(index, 1);
      let resp = response.response;
      this.notifications.unshift(resp.notif);
    } else {
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
   }

   redirectToPage(notification: any){
    if (notification.page) {
      if ([1,2].indexOf(notification.page_info.page_type) !== -1 && notification.page_info.is_related) {
        this.router.navigate(['/page/' + notification.page + '/profile-business/']);
      } else if ([3,4].indexOf(notification.page_info.page_type) !== -1 && notification.page_info.is_related) {
        this.router.navigate(['/page/' + notification.page + '/profile-school/']);
      } else {
        this.router.navigate(['/page/' + notification.page + '/']);
      }
    } else if (notification.school) {
      this.router.navigate(['/school/' + notification.school + '/']);
    } else if (notification.initiative) {
        if (notification.page_info.is_related) {
          this.router.navigate(['/edit-initiatives/' + notification.initiative + '/']);
        } else {
          this.router.navigate(['/initiatives/' + notification.initiatives + '/']);
        }
    }
    else if (notification.link){
      this.router.navigate([notification.link + '/']);
    }
   }

}
