<section class="dashboardLanding profile-mobile">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5 col-xl-3 leftWrap">
        <div class="whiteWrapEach" *ngIf="user.user_role == '2'">
          <div class="forProfile" *ngIf="user.user_role == '2'">
            <div class="profileThumb">
              <img src="{{displayImage? displayImage : 'assets/images/p-img.png'}}" class="profileImg" (error)="getImageUrl()">
            </div>
            <div class="profileDes">
              <h1>{{user.first_name}} {{user.last_name}}</h1>
              <a class="btn btnBlue" *ngIf="user.user_role == '2'" [routerLink]="['/profile']">Go to Resume</a>
            </div>
            <div class="btn-group">
              <button type="button">
                <img src="/assets/images/network-icon.svg" alt="network" />
                My Network
                <span>{{teamCount}}</span>
              </button>
              <button type="button" (click)="openInterestedFeedModal(intsdFeedsTemplate)">
                <img src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                Interested
                <span>{{totalIntFeedsCount}}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach step3-box" *ngIf="totalFollowedCount > 0">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">Following ({{totalFollowedCount}})</h3>
              <a href="javascript:void();" (click)="openModalFollow(followedModal)">View All</a>
            </div>
            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer" *ngFor="let pagee of followedPages | slice:0:2"
                (click)="directToPageIni(pagee)">
                <div class="leftImage">
                  <img class="pageThumb" alt="page-image"
                    src="{{pagee.page_info.display_picture? pagee.page_info.display_picture : 'assets/images/p-img.png'}}" />
                </div>
                <div class="rightText">
                  <h3>{{ pagee.page_info.title }}</h3>
                  <p *ngIf="pagee.page_info.school_name">{{ pagee.page_info.school_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach" *ngIf="totalPageCount > 0">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">my pages ({{totalPageCount}})</h3>
              <a href="javascript:void();" (click)="openBusiness(businessModal)">View All</a>
            </div>
            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer" *ngFor="let pagee of pageslist | slice:0:2"
                (click)="OpenPageDashboard(pagee)">
                <div class="leftImage">
                  <img class="pageThumb" src="{{pagee.display_pic? pagee.display_pic : 'assets/images/p-img.png'}}" />
                </div>
                <div class="rightText">
                  <h3>{{ pagee.page_info.title }}</h3>
                  <p *ngIf="[1,2].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.address">
                    {{ pagee.page_info.address.split(',')[0] }}</p>
                  <p *ngIf="[3,4].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.school_name">
                    {{ pagee.page_info.school_name }}</p>
                  <p *ngIf="pagee.page_info.page_type === 5">{{ pagee.page_info.state }}, {{ pagee.page_info.city }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach">
          <div class="pagesHdgWrap">
            <h3 class="">resources</h3>
          </div>

          <div class="createResourceEach">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Initiative</h3>
              <a href="javascript:void();" (click)="createInitiative(createInitiativeTemplate)">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>

          <div class="createResourceEach" *ngIf="user.age_group > 1">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Organization Page</h3>
              <a href="javascript:void();" [routerLink]="['/createbusinesspage']">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>

          <div class="resourceWhatIs">
            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/startIni.png">
              <a href="https://www.cuclub.today/" target="_blank">
                <p>CU 4 Community</p>
              </a>
            </div>

            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/whatIni.png">
              <a href="https://www.cuclub.today/cu-clubs" target="_blank">
                <p>What is an Initiative?</p>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #intsdFeedsTemplate>
  <div class="modal-body bookmark-body new-body">
    <div class="create-box">
        <h4 class="modal-title pull-left">Interested Feeds</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="intModalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="blog-listing scroll-activity scrollbar">
      <div class="blog-box" *ngFor="let feed of interestedFeeds">
        <div class="img-box">
          <img class="cursor-pointer" (click)="directToPage(feed);intModalRef.hide()" alt="profile-image"
            src="{{feed.display_picture? feed.display_picture : 'assets/images/default.svg'}}" />
        </div>
        <div class="content-box">
          <div class="w-100">
            <p class="bold cursor-pointer" (click)="feedDetailModal(feedDetail, feed)">{{feed.title}}
              <span class="d-inline-flex pl-0">
                <span>{{feed.page_title}}</span>
                <span *ngIf="feed.post_info.post_type === 1 || feed.post_info.post_type === 8 || feed.post_info.post_type === 4">• Update</span>
                <span *ngIf="feed.post_info.post_type === 3 || feed.post_info.post_type === 7 || feed.post_info.post_type === 10">• Event</span>
                <span *ngIf="feed.post_info.post_type === 2 || feed.post_info.post_type === 5">• Position</span>
                <span *ngIf="feed.post_info.post_type === 6">• Idea</span>
              </span>
            </p>
            <p class="timelineDes timelineDesOuterDots cursor-pointer" (click)="feedDetailModal(feedDetail, feed)"
              innerHtml="{{feed.description}}"></p>
          </div>
          <div class="save-content">
            <div class="left-part">
              <button type="button" class="interest-btn" [ngClass]="{'remove': feed.interested_data.interested}"
                (click)="markInterested(feed)">
                <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                <span class="remove-text">remove</span>
                <span>Interested</span>
              </button>
              <button type="button" class="other-btn" *ngIf="feed.interested_data.count > 0"
                (click)="openModalOthers(templateOthers, feed)">
                {{feed.interested_data.count}} Others
              </button>
            </div>
            <div class="right-part">
              <div class="desktop-flag-data">
                <div class="name">
                  <span class="dot">
                    <img alt="profile-image"
                      src="{{feed.post_info.creator_pic? feed.post_info.creator_pic : 'assets/images/default.svg'}}" />
                  </span>
                  <span class="cursor-pointer"
                    (click)="directToProfile(feed);intModalRef.hide()">{{feed.post_info.creator_name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="totalIntFeedsCount > 21 && totalIntFeedsCount > interestedFeeds.length"
      class="next-listing text-center mt-4 pb-4 timeLineLoad">
      <a href="javascript:void(0);" (click)="getInterestedFeeds()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #templateOthers>
  <div class="modal-body new-body common-body">
    <div class="create-box">
        <h4 class="modal-title pull-left">Interested</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="otherModalRef.hide()">
          <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <ul class="follow-list scroll-activity scrollbar">
      <li *ngFor="let usr of interestedUsers">
        <div class="info-content">
          <img alt="profile-image" class="cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
            src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
          <div class="text">
            <p class="bold cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
              [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}</p>
            <p>{{usr.education_info.school_name}}</p>
            <p class="small" *ngIf="usr.education_info.school">{{usr.education_info.start_date | date: 'yyyy'}} -
              {{usr.education_info.end_date | date: 'yyyy'}}</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
      <a href="javascript:void(0);" (click)="getInterestedUsers()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #followedModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Following</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="followBtnErr" class="error-message text-center">Something went wrong. Please try again later.</div>
    <p *ngIf="followedPages.length == 0">No followed Page(s)</p>
    <ul class="follow-list scroll-activity scrollbar" *ngIf="followedPages.length > 0">
      <li *ngFor="let pagee of followedPages">
        <div class="info-content cursor-pointer" (click)="directToPageIni(pagee);modalRef.hide()">
          <img alt="page-image"
            src="{{pagee.page_info.display_picture? pagee.page_info.display_picture : 'assets/images/p-img.png'}}" />
          <div class="text">
            <p class="bold">{{ pagee.page_info.title }}</p>
            <p *ngIf="pagee.page_info.school_name">{{ pagee.page_info.school_name }}</p>
          </div>
        </div>
        <button type="button" class="schoolfollow-btn unfollow" (click)="unFollowPageIni(pagee)">
          Unfollow
        </button>
      </li>
    </ul>
    <div class="timeLineLoad pt-3 blue" *ngIf="totalFollowedCount > 10 && totalFollowedCount > followedPages.length">
      <a href="javascript:void(0);" (click)="getFollowedPages()">Load More</a>
    </div>
  </div>
</ng-template>
<ng-template #businessModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">All Pages</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body all-pages-content">
    <div class="modal-box">
      <div class="content-section pl-md-3 pr-md-3 pt-3 pb-3 dashBoardAllPgaes">
        <div class="profile-div scroll-activity scrollbar">
          <div class="profile-inner mb-3" *ngFor="let pagee of pageslist">
            <div class="box-div inner-exp"
              [ngClass]="{'business-border': pagee.page_type == 1,'volunteer-border': pagee.page_type == 2,'school-border': [3,4].indexOf(pagee.page_type) > -1}">
              <div class="experience-div media">
                <img src="{{pagee.display_pic? pagee.display_pic : 'assets/images/p-img.png'}}" />
                <div class="media-body ml-2 mt-1">
                  <h4>{{pagee.page_info.title}}
                    <span
                      *ngIf="[1,2].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.address">{{pagee.page_info.address.split(',')[0]}}</span>
                    <span
                      *ngIf="[3,4].indexOf(pagee.page_info.page_type) > -1 && pagee.page_info.school_name">{{pagee.page_info.school_name}}</span>
                    <span *ngIf="pagee.page_info.page_type === 5">{{ pagee.page_info.state }},
                      {{ pagee.page_info.city }}</span>
                  </h4>
                  <button class="common-btn ghost btnLightGrey" (click)="OpenPage(pagee);modalRef.hide()">Go to
                    Page</button>
                  <button *ngIf="pagee.page_info.page_type !== 5" class="common-btn ghost ml-2 btnBrightGrey"
                    (click)="OpenPageSettings(pagee);modalRef.hide()">Go to Settings</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="timeLineLoad pt-3 blue" *ngIf="totalPageCount > 10 && totalPageCount > pageslist.length">
          <a href="javascript:void(0);" (click)="getAllPages()">Load More</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #createInitiativeTemplate>
  <div class="modal-body new-body">
    <div class="modal-box">
      <div class="timeLineBlock">
          <div class="create-box">
              <h4 class="modal-title pull-left custom-h4">Create an Initiative</h4>
              <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
              </button>
          </div>
          <form [formGroup]="initiativeForm" novalidate>
            <div class="media">
              <div class="imgSec">
                <img alt="image" class="for-preview-image"
                  src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
              </div>
              <div class="media-body ml-3">
                <div class="form-group">
                  <input type="file" placeholder="Type Here" class="fileUpload" (change)="coverImageUpload($event)">
                  <span class="fileText cursor-pointer">Select new photo</span>
                  <div *ngIf="coverImageUploadError" class="error-message">
                    {{coverImageUploadMessage}}
                  </div>
                  <div *ngIf="coverImageUploadSizeError" class="error-message">
                    {{coverImageUploadSizeMessage}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <div class="form-group">
                      <input type="text" class="form-control" placeholder="Title of Initiative" formControlName="title">
                      <div class="error-message"
                        *ngIf="initiativeForm.get('title').hasError('required') && (initiativeForm.get('title').dirty || initiativeForm.get('title').touched || submitted)">
                        This field is required.
                      </div>
                      <div class="error-message"
                        *ngIf="initiativeForm.get('title').hasError('maxlength') && (initiativeForm.get('title').dirty || initiativeForm.get('title').touched)">
                        Max 30 characters allowed.
                      </div>
                    </div>
              </div>
            </div>
            <div class="row" *ngIf="schools.length > 0">
               <div class="col-12">
                  <div class="form-group">
                      <select class="custom-select" [(ngModel)]="school_id" [ngModelOptions]="{standalone: true}">
                        <option value="0" selected>Select School</option>
                        <option *ngFor="let school of schools" value="{{school.id}}">{{school.school_name}}</option>
                    </select>
                    </div>
               </div>
              </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="City" formControlName="city">
                  <div class="error-message"
                    *ngIf="initiativeForm.get('city').hasError('required') && (initiativeForm.get('city').dirty || initiativeForm.get('city').touched || submitted)">
                    This field is required.
                  </div>
                  <div class="error-message"
                    *ngIf="initiativeForm.get('city').hasError('maxlength') && (initiativeForm.get('city').dirty || initiativeForm.get('city').touched)">
                    Max 30 characters allowed.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Province/State" formControlName="state">
                  <div class="error-message"
                    *ngIf="initiativeForm.get('state').hasError('required') && (initiativeForm.get('state').dirty || initiativeForm.get('state').touched || submitted)">
                    This field is required.
                  </div>
                  <div class="error-message"
                    *ngIf="initiativeForm.get('state').hasError('maxlength') && (initiativeForm.get('state').dirty || initiativeForm.get('state').touched)">
                    Max 30 characters allowed.
                  </div>
                </div>
              </div>
            </div>
            <p class="m-0">
              <strong>Note:</strong> you will be able to edit all this information later on
            </p>
          </form>
      </div>
    </div>
  </div>

  <div class="modal-footer new-btns">
    <button class="btn btn-footer" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-footer"
      (click)="addInitiative(initiativeForm.valid, initiativeSuccessTemplate)">Create</button>
  </div>
</ng-template>
<ng-template #initiativeSuccessTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Create an Initiative</h4>
    <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
      <img alt="image" src="assets/images/cross-modal.svg">
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-box">
      <p class="text-center m-0">
        Congrats! Your initiative page has been created. Access it through your <br />
        <strong>Profile > Experiences</strong>
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-footer" (click)="modalRef.hide()">Got it</button>
  </div>
</ng-template>
<ng-template #feedDetail>
  <div class="modal-body new-body">
    <div class="create-box timeline">
      <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
        <img alt="image" src="assets/images/cross-modal.svg">
        <img alt="image" class="d-md-none d-inline-flex" src="/assets/images/teal-slick-arrow.svg" alt="arrow" />
      </button>
    </div>
    <div class="timeLinesEach custom-design scrollbar">
      <div class="feedDetailSlider" [ngClass]="{'no-image': editFeedObj?.documents?.length == 0 }">
        <span class="event"
          *ngIf="editFeedObj.post_info.post_type === 3 || editFeedObj.post_info.post_type === 7 || editFeedObj.post_info.post_type === 10">Event</span>
        <span class="event"
          *ngIf="editFeedObj.post_info.post_type === 2 || editFeedObj.post_info.post_type === 5">Position</span>
        <span class="event"
          *ngIf="editFeedObj.post_info.post_type === 1 || editFeedObj.post_info.post_type === 8 || editFeedObj.post_info.post_type === 4">Update</span>
        <span class="event interested" *ngIf="editFeedObj.post_info.post_type === 6">
          <img src="assets/images/blub.svg" alt="blub" />Idea
        </span>
        <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
          <div class="slide" ngxSlickItem *ngFor="let document of editFeedObj.documents">
            <div class="img-box">
              <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
              <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg" alt="pdf-image" />
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="feedDetailContent">
        <div class="img-box" [ngClass]="{'mt-4 mt-lg-0 mt-md-0': editFeedObj?.documents?.length == 0 && [2,3,5,6,7].indexOf(editFeedObj.post_info.post_type) < 0}">
          <img class="cursor-pointer" (click)="directToPage(editFeedObj);closeModal()"
            src="{{editFeedObj.display_picture? editFeedObj.display_picture : 'assets/images/default.svg'}}"
            alt="image" />
        </div>
        <h3>{{editFeedObj.title}}</h3>
        <p>{{editFeedObj.page_title}}</p>
        <div class="date-box" *ngIf="editFeedObj.post_info.post_type === 3 || editFeedObj.post_info.post_type === 7 || editFeedObj.post_info.post_type === 10">
          <div class="month">
            <span>{{editFeedObj.post_info.event_date | date:'MMM'}}</span>
          </div>
          <div class="date">
            <span>{{editFeedObj.post_info.event_date | date:'d'}}</span>
          </div>
        </div>
        <div class="save-content">
          <div class="left-part">
            <button type="button" class="interest-btn" [ngClass]="{'remove': editFeedObj.interested_data.interested}"
              (click)="markInterested(editFeedObj)">
              <img class="remove-img" src="/assets/images/interested-star-filled.svg" alt="star-filled" />
              <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
              <span class="remove-text">remove</span>
              <span>Interested</span>
            </button>
            <button type="button" class="other-btn" *ngIf="editFeedObj.interested_data.count > 0"
              (click)="openModalOthers(templateOthers, editFeedObj)">
              {{editFeedObj.interested_data.count}} Others
            </button>
          </div>
          <div class="right-part">
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                aria-controls="dropdown-basic">
                <img src="assets/images/dot-teal.svg" alt="more icon" />
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box detail-custom ReportDropMenuposition onlyProfile1" role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem">
                  <div class="flag-data-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                    <div class="name ml-0">
                      <span class="dot">
                        <img alt="profile-image"
                          src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}">
                      </span>
                      <span class="cursor-pointer"
                        (click)="directToProfile(editFeedObj);closeModal()">{{editFeedObj.post_info.creator_name}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="details">
          <h4>Details</h4>
          <p innerHtml="{{editFeedObj.description}}"></p>
          <p class="metaLink" *ngIf="!checkExtraData(editFeedObj)">
            <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
          </p>
          <div class="singleTimelineDetail" *ngIf="checkExtraData(editFeedObj)">
            <div class="imageBox">
              <img alt="timeline" class="timeLineImg"
                src="{{editFeedObj.metadata_image_link? editFeedObj.metadata_image_link : 'assets/images/default.svg'}}" />
            </div>
            <div class="dtlOuter">
              <h1>{{editFeedObj.metadata_title}}</h1>
              <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
              <p>{{editFeedObj.metadata_description}}</p>
            </div>
          </div>
          <div class="posted-by">
            <h4>Posted by</h4>
            <div class="name-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
              <img
                src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}"
                alt="image" />
              <p>{{editFeedObj.post_info.creator_name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>