<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="notAccount" *ngIf="!currentUser">
                        <a [routerLink]="['/signin']">Have an Account? Sign In 
                            <img alt="icon" src="assets/images/arrowRight.svg" class="iconArrow">
                        </a>
                    </div>
                    <div class="SignInInnerWrap WrapWhiteShadow m-0">
                        <div class="d-flex align-items-center claimLoadWrap" *ngIf="!isVerified">
                            <p class="subHdg mb-4" *ngIf="!isResponse">Please wait.....</p>
                            <p class="subHdg mb-4" *ngIf="isResponse && !isValid">Uh Oh! This link has expired.</p>
                            <p class="subHdg mb-4" *ngIf="isResponse && isValid && hasAccount && !isLoggedIn">Please login with your account.</p>
                            <p class="subHdg mb-4" *ngIf="isResponse && isValid && !hasAccount && isLoggedIn">It seems that you are logged
                                    in from different account.Please login with your account.</p>
                        </div>
                        <div *ngIf="isVerified">
                            <h1 class="hdg">Claim Page</h1>
                            <p class="subHdg mb-4">Fill in the following information to claim the page</p>
                            <div *ngIf="[1,2].indexOf(page?.page_type) > -1">
                                <app-claim-business-page [page]="page" [verifier]="verifier"
                                    (successOverlay)="setSuccessOverlay($event)"></app-claim-business-page>
                            </div>
                            <div *ngIf="[3,4].indexOf(page?.page_type) > -1">
                                <app-claim-school-page [page]="page" [verifier]="verifier"
                                    (successOverlay)="setSuccessOverlay($event)"></app-claim-school-page>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="success-overlay" *ngIf="successOverlay">
    <div class="success-div">
        <div class="inner-div text-center">
            <h1>Congrats! You have successfully claimed this page.</h1>
            <button class="btn btn-common mr-3" (click)="disableOverlay()">Okay!</button>
        </div>
    </div>
</div>
