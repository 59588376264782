import { Component, OnInit,TemplateRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { InitiativeService } from '../../services/initiative.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl, FormArray } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { TeamService } from 'src/app/services/team.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';

@Component({
  selector: 'app-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.scss']
})

export class InitiativesComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  modalRef: BsModalRef;
  delModalRef: BsModalRef;
  @Input() user: any;
  myInitiatives: any = [];
  pageTagForm: FormGroup;
  selectedInit: any = {};
  initiativeId: any = null;
  businessImage: any;
  showTag: boolean = true;
  formShow: boolean = false;
  searchForm: FormGroup;
  submitted: boolean = false;
  myTaggedInit: any = [];
  delInitiative: any;
  delInitiativeTagged: boolean;
  tagError: boolean = false;
  teamError: boolean = false;
  slicedItem: any = 6;
  iniMembers: any[] = [];
  iniMemberSliced: any[] = [];
  searchQuery: string = "";
  teamMemberNext: any = 1;
  totalTeamMember: any = 0;
  teamMember: any = [];
  iniObj: any = {};
  teamLoadMoreBool: boolean = false;
  editTeamMember: any;
  invitationForm: FormGroup;
  newModalRef: BsModalRef;
  invitationSuccess: boolean = false;
  copyMessageBool: boolean = false;
  title: string = null;
  @Output() hasData = new EventEmitter<any>();
  @ViewChild('initiativeTagTemplate') initiativeTagTemplate: TemplateRef<any>;

  constructor(private modalService: BsModalService, private initiativeService: InitiativeService, private router: Router, public formBuilder: FormBuilder,
    private profileService: ProfileService, private teamService: TeamService, private customValidator: CustomvalidationService) {
    this.pageTagForm = this.formBuilder.group({
      page_id: new FormControl('')
    },{ validators: this.idValidator });
    this.searchForm = this.formBuilder.group({
      position: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
    });
    this.invitationForm = this.formBuilder.group({
      invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
    });
   }

  ngOnInit(): void {
    this.getInitiatives();
  }

  getInitiatives(){
    this.initiativeService.getUserInitiatives()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.myInitiatives = response.response;
        this.hasData.emit(true);
      } else {
        this.myInitiatives = [];
        this.hasData.emit(false);
      }
    })
  }

  addSection(){
    this.modalInitiativeTag(this.initiativeTagTemplate);
  }

  modalInitiativeTag(initiativeTagTemplate: TemplateRef<any>) {
    this.showTag = true;
    this.tagError = false;
    this.selectedInit = {};
    this.initiativeId = null;
    this.formShow = true;
    this.pageTagForm.reset();
    this.searchForm.reset();
    this.modalRef = this.modalService.show(initiativeTagTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  deleteInitiativeConfirm(deleteInitiativeTemplate: TemplateRef<any>, initiative: any) {
    this.delInitiative = initiative;
    if (this.delInitiative.page_info.is_owner){
      this.delInitiativeTagged = false;
    } else{
      this.delInitiativeTagged = true;
    }
    this.modalRef = this.modalService.show(deleteInitiativeTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  modalAddTeam(templateAddTeam: TemplateRef<any>, obj: any) {
    this.iniMembers = [];
    this.iniMemberSliced = [];
    this.selectedInit = obj;
    this.teamLoadMoreBool = false;
    this.initiativeId = obj.page_info.instance_id;
    this.title = obj.page_info.title;
    if (obj.page_info.is_owner) {
      this.getIniMembers(this.initiativeId);
    } else {
      this.getUserIniMembers(obj.id);
    }
    this.modalRef = this.modalService.show(templateAddTeam , 
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  iniTeamModal(initiative: any, teamModal: TemplateRef<any>) {
    this.iniObj = initiative;
    this.teamMemberNext = 1;
    this.totalTeamMember = 0;
    this.teamMember = [];
    if (initiative.page_info.is_owner) {
      this.getIniTeamMembers('');
    } else {
      this.getUserIniTeamMembers('');
    }
    this.modalRef = this.modalService.show(teamModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  idValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let inpVal = control.get('page_id').value;
    if (inpVal) {
      let page_type = inpVal.slice(0,3);
      let page_id = parseInt(inpVal.slice(3));
      if (['INI'].indexOf(page_type) < 0 || isNaN(page_id) || page_id < 0) {
        return {'invalidPageId': true };
      }
      return null;
    }
    return null;
  }

  reTagPage(){
    this.tagError = false;
    this.selectedInit = {};
    this.initiativeId = null;
  }

  tagPage(isValid: boolean){
    if (isValid) {
      this.getOrgById();
    }
  }

  getOrgById(){
    let inpVal = this.pageTagForm.get('page_id').value;
    if (inpVal){
      let page_id = inpVal.slice(3);
      if (page_id !== null && page_id > 0) {
        this.initiativeService.searchInitiative(page_id)
        .subscribe(response => {
          if (response){
            if (response.status === 111){
              this.pageTagForm.get('page_id').setErrors({ aftersubmit: "You can't tag your created initiative." });
              this.tagError = false;
            }
            else if(response.status === 112){
              this.pageTagForm.get('page_id').setErrors({ aftersubmit: "You have already tagged this." });
              this.tagError = false;
            }
            else{
              this.selectOrg(response.response);
              this.tagError = false;
            }
          } else {
            this.tagError = false;
            this.pageTagForm.get('page_id').setErrors({ aftersubmit: "Page against the above entered ID does not exists." });
          }
        });
      }
    }
  }

  aftrTag(isValid: boolean){
    if (this.initiativeId == null){
      this.tagError = true;
    }
    if (isValid && this.initiativeId != null) {
      this.showTag = !this.showTag;
      this.formShow = !this.formShow;
    }
  }

  saveTag(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {
        'position': this.searchForm.get('position').value,
        'user': this.user.id,
        'initiative': this.initiativeId
      }
    this.initiativeService.tagInitiative(model)
    .subscribe(response => {
      this.handleTagInitiative(response)
    }, error => {
      this.handleTagInitiativeErr(error.error)
    })
    }
  }

  protected handleTagInitiative(response: any){
    if (response.status === 111) {
      this.handleTagInitiativeErr(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.searchForm.reset();
        this.getInitiatives();
        this.submitted = false;
        this.selectedInit = {};
    }
  } 
  
  protected handleTagInitiativeErr(data:any){
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.searchForm.contains(field)) {
        this.searchForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  selectOrg(org: any) {
    this.selectedInit = org;
    this.initiativeId = org.id;
    this.businessImage = org.display_picture;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  jumpToInitiative(currentInitiative: any){
    let instance = currentInitiative.page_info;
    let isDeleted = instance.is_deleted
    let initiativeId = instance.instance_id;
    if (!isDeleted){
      if (instance.is_owner){
        this.router.navigate(['edit-initiatives/' + initiativeId + '/'])
      }
      else{
        this.router.navigate(['initiatives/' + initiativeId + '/'])
      }
    }
    
  }

  deleteInitiative(){
    let instance = this.delInitiative.page_info;
    let initiativeId = instance.instance_id;
    let index: any;
    if (instance.is_owner){
      this.initiativeService.deleteInitiative(initiativeId)
      .subscribe(response => {
        index = this.myInitiatives.findIndex(obj => obj.page_info.instance_id === initiativeId)
        this.getInitiatives();
        this.modalRef.hide();
      })
    }
    else{
      this.initiativeService.removeTagInitiative(this.delInitiative.id)
      .subscribe(response => {
        index = this.myInitiatives.findIndex(obj => obj.id === this.delInitiative.id)
        this.getInitiatives();
        this.modalRef.hide();
      })
    }
    this.delInitiative = {};
  }

  sliderInitiative1 = {
    "slidesToShow": 4,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "dots":true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  getIniMembers(iniId: any){
    this.profileService.getInitiativeMembers(iniId)
    .subscribe(response => {
      if(response.status == 200){
          this.iniMembers = response.response.initiatives;
          this.iniMemberSliced = this.iniMembers.slice(0, this.slicedItem);
          if (this.iniMembers.length > this.iniMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  getUserIniMembers(userIniId: any){
    this.profileService.getUserInitiativeMembers(userIniId)
    .subscribe(response => {
      if(response.status == 200){
          this.iniMembers = response.response.initiatives;
          this.iniMemberSliced = this.iniMembers.slice(0, this.slicedItem);
          if (this.iniMembers.length > this.iniMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  loadMoreMembers(id){
    let totalMember = this.iniMembers.length;
    let slicedMember = this.iniMemberSliced.length;
    if (slicedMember < totalMember){
      this.iniMemberSliced = this.iniMembers.slice(0, slicedMember+this.slicedItem)
    }
    if (totalMember > this.iniMemberSliced.length){
      this.teamLoadMoreBool = true;
    } else {
      this.teamLoadMoreBool = false;
    }
    setTimeout(() => {
      let el = document.getElementById(id);
      el.scrollIntoView({behavior: 'smooth'});
    }, 500);
  }

  routeToProfile(member_id: any) {
    if (member_id){
      this.modalRef.hide();
      this.router.navigate(["/profile/" + member_id + "/"]);
    }
  }

  addToTeam(member: any){
    this.teamError = false;
    let model = {};
    if (this.selectedInit.page_info.is_owner) {
      model = {'tagged_to_ini': member.id, 'tagged_owner': this.selectedInit.page_info.instance_id}
    } else if (member.is_initiative) {
      model = {'tagged_by_ini': this.selectedInit.id, 'tagged_owner': member.id}
    } else {
      model = {'tagged_by_ini': this.selectedInit.id, 'tagged_to_ini': member.id}
    }
    model['to_user'] = member.student_info.id;
    this.teamService.addIniTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.iniMemberSliced.findIndex(obj => obj.id === member.id);
        if (member.is_initiative) {
          index = this.iniMemberSliced.findIndex(obj => (obj.is_initiative === true && obj.id === member.id));
        }
        this.iniMemberSliced[index]['team_status'] = 1;
      } else {
        this.teamError = true;
      }
    }, error => {
      this.teamError = true;
    })
  }

  searchStudent(e: any){
    if (e.target.value && e.target.value.length > 0){
      this.searchQuery = e.target.value;
      this.iniMemberSliced = this.iniMembers.filter(item => item.student_info.student_name.search(new RegExp(this.searchQuery, 'i')) > -1);
      this.teamLoadMoreBool = false;
    } else if (this.searchQuery.length > 0) {
      this.iniMemberSliced = this.iniMembers;
    }
  }

  getIniTeamMembers(params: any){
    let iniId = this.iniObj.page_info.instance_id;
    this.profileService.getIniTeamMembers(iniId, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  getUserIniTeamMembers(params: any){
    this.profileService.getUserIniTeamMembers(this.iniObj.id, params)
      .subscribe(response => {
        if (response.status == 200 && response.response.results.length > 0){
          if (this.teamMemberNext == 1){
            this.teamMember = response.response.results;
          } else {
            this.teamMember = this.teamMember.concat(response.response.results);
          }
          this.teamMemberNext = response.response.next;
          this.totalTeamMember = response.response.total_count;
        }
      })
  }

  loadMoreIniTeamMember() {
    if (this.teamMemberNext != null) {
      let params = '?page=' + this.teamMemberNext;
      if (this.iniObj.page_info.is_owner) {
        this.getIniTeamMembers(params);
      } else {
        this.getUserIniTeamMembers(params);
      }
    }
  }

  delTeamMemberModal(delTeamTemplate: TemplateRef<any>, member: any) {
    this.editTeamMember = member;
    this.delModalRef = this.modalService.show(delTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTeamMember(){
    if (this.editTeamMember && this.editTeamMember.id) {
      this.teamService.deleteIniTeamMember(this.editTeamMember.id)
      .subscribe(response => {
        let index = this.teamMember.findIndex(obj => obj.id === this.editTeamMember.id);
        this.teamMember.splice(index, 1);
        this.totalTeamMember = this.totalTeamMember - 1;
        let iniIndex = this.myInitiatives.findIndex(obj => obj.id === this.iniObj.id);
        if (this.iniObj.page_info.is_owner) {
          iniIndex = this.myInitiatives.findIndex(obj => obj.page_info.instance_id === this.iniObj.id);
        }
        this.myInitiatives[iniIndex].team_members = this.totalTeamMember;
      });
    }
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.newModalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'initiative': this.initiativeId
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  copyMessage(){
    let msg = "Join my network at " + this.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.setToCopy(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
