<section class="experiencesPage">
    
    <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3 pt-0">
        <div class="btn-group" dropdown *ngIf="showAdd()">
            <button id="button-basic" dropdownToggle type="button" class="add-section-btn" aria-controls="dropdown-basic">
                <img src="/assets/images/add-section-btn.svg" alt="btn" />
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu add-section-dropdown" role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem" *ngIf="!hasIni">
                    <button type="button" class="add-btn" (click)="addSection(1)">
                        Initiatives
                    </button>
                </li>
                <li role="menuitem" *ngIf="!hasWork && user.age_group > 1">
                    <button type="button" class="add-btn" (click)="addSection(2)">
                        Work
                    </button>
                </li>
                <li role="menuitem" *ngIf="!hasVolunteer && user.age_group > 1">
                    <button type="button" class="add-btn" (click)="addSection(3)">
                        Volunteer
                    </button>
                </li>
                <li role="menuitem" *ngIf="!hasExtra && user.age_group > 1">
                    <button type="button" class="add-btn" (click)="addSection(4)">
                        Extracurricular
                    </button>
                </li>
                <li role="menuitem" *ngIf="!hasAwards">
                    <button type="button" class="add-btn" (click)="addSection(5)">
                        Awards and Credentials
                    </button>
                </li>
            </ul>
        </div>
        <div class="content-section fullPage">
            <app-education>
            </app-education>
            <app-initiatives [user]="user" (hasData)="checkData($event, 1)">
            </app-initiatives>
            <app-work-experience (hasData)="checkData($event, 2)" (exp)="getExp($event, memberTeamTemplate, teamMemberTemplate, 1)" [user]="user" *ngIf="user.age_group > 1">
            </app-work-experience>
            <app-volunteer-experience (hasData)="checkData($event, 3)" (exp)="getExp($event, memberTeamTemplate, teamMemberTemplate, 2)" [user]="user" *ngIf="user.age_group > 1">
            </app-volunteer-experience>
            <app-extracurricular (hasData)="checkData($event, 4)" (exp)="getExp($event, memberTeamTemplate, teamMemberTemplate, 3)" [user]="user" *ngIf="user.age_group > 1">
            </app-extracurricular>
            <app-school-involvement (exp)="getExp($event, memberTeamTemplate, teamMemberTemplate, 4)" [user]="user">
            </app-school-involvement>
            <app-awards-credentials (hasData)="checkData($event, 5)"></app-awards-credentials>
        </div>
    </div>
</section>

<ng-template #memberTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team Member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
                <div class="initiativeTeamSlider mb-md-5">
                    <div class="teamSearchInputWrap mt-2">
                        <div class="teamSearchInputWrapInner">
                            <div class="leftWrap" *ngIf="teamMember.length > 0">
                                <div class="form-group">
                                    <input type="text" placeholder="Search by name" class="form-control" (keyup)="searchStudent($event)">
                                </div>
                            </div>
                            <div class="rightWrap" [ngClass]="{'forMissingInvite' : teamMember.length == 0}">
                                <p class="des">Is someone from this team missing? 
                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                        <img alt="icon" class="iconInvite ml-2 mr-1" src="./assets/images/verifyProfile.svg" />
                                        <span>Invite</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMemberSliced && teamMemberSliced.length > 0">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let member of teamMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember">
                                        <img alt="icon" class="bannerImg" src="{{member.student_info.header_image ? member.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                                    </div>
                                    <div class="profileMember">
                                        <img alt="icon" class="profileImg" src="{{member.student_info.display_pic ? member.student_info.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{ member.student_info.student_name }}</h1>
                                        <h5 class="subName mb-1">{{member.position}}</h5>
                                        <p class="yearHistoryMember">
                                            {{ member.start_date | date: 'MMM y' }}<span *ngIf="member.end_date"> - {{member.end_date | date: 'MMM y'}}</span>
                                            <span *ngIf="!member.end_date"> - Present</span>
                                        </p>
                                        <div class="memberActions">
                                            <button class="btn common-btn btnMessage" *ngIf="user.approval_status && (user.age_group < 3 || user.age_group == member.student_info.age_group)" (click)="routeToProfile(member.student_info.id)">Profile</button>
                                            <button class="btn common-btn btnProfile" *ngIf="member.team_status == 5" (click)="addToTeam(member)">Add to team</button>
                                            <button class="btn common-btn btnProfile noCursor" *ngIf="member.team_status < 5"><span *ngIf="member.team_status == 1">Pending</span><span *ngIf="member.team_status == 2">Approved</span></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style="max-width: 100%;min-width: 100%;" id="bottomScr"></li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="teamLoadMore('bottomScr')">Load More</a>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</ng-template>

<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="newModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                    <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                    <p class="inviteCopySbNote">Join my network at {{title}} on ConnectUs.today by signing up using the following link:  <strong>connectus.today/signin</strong></p>
                    <div class="inviteBorder">
                        <div class="custom-tooltip copy-tooltip">
                            <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                            <div class="tooltip-data" *ngIf="copyMessageBool">Message Copied</div>
                        </div>
                    </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="newModalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="newModalRef.hide()">Done</button>
    </div>
</ng-template>

<ng-template #teamMemberTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Your Team <span *ngIf="teamMember.length > 0">({{ totalTeamMember }})</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div class="initiativeTeamSlider mb-md-2 px-2">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMember.length > 0">
                        <ul class="teamMemberList no-list">
                            <li *ngFor="let member of teamMember">
                                <div class="myTeamEach">
                                    <div class="leftTeam">
                                        <img alt="icon" class="profileImg" src="{{member.exp_team_detail.display_pic ? member.exp_team_detail.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="rightTeam rightTeamWithBtn">
                                        <div class="forDetails">
                                            <h1 class="name m-0">{{ member.exp_team_detail.name }}</h1>
                                            <h5 class="subName mb-0">{{member.exp_team_detail.position}}</h5>
                                            <p class="yearHistoryMember m-0">
                                                {{ member.exp_team_detail.start_date | date: 'MMM y'}}<span *ngIf="member.end_date"> - {{member.exp_team_detail.end_date | date: 'MMM y'}}</span>
                                                <span *ngIf="!member.end_date"> - Present</span>
                                            </p>
                                        </div>
                                        <div class="forBtns">
                                            <button class="btn btnInvite" (click)="delTeamMemberModal(delTeamTemplate, member)">Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="totalTeamMember > 6 && totalTeamMember > teamMember.length">
                        <a href="javascript:void(0);" (click)="loadMoreTeamMemberProfile()">Load More</a>
                    </div>
                    <div *ngIf="teamMember.length == 0">
                        No Team Member(s)
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Team Member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="delModalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to remove this person?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delModalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delTeamMember(); delModalRef.hide();">
            Yes
        </button>
    </div>
</ng-template>

