import { Component, OnInit, NgZone, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { BusinessUser } from '../../shared/businessUser';
import { User } from '../../shared/user';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'app-claim-business-page',
  templateUrl: './claim-business-page.component.html',
  styleUrls: ['./claim-business-page.component.scss']
})
export class ClaimBusinessPageComponent implements OnInit {
  @Input() page: any = {};
  @Input() verifier: any = {};
  @Output() successOverlay: EventEmitter<boolean> = new EventEmitter();
  firstStep: boolean = true;
  secondStep: boolean = false;
  firstStepForm: FormGroup;
  secondStepForm: FormGroup;
  public submitted: boolean = false;
  public isReadOnly: boolean = false;
  public showAddressError: boolean = false;
  public partialSubmitted: boolean = false;
  public invalidDate: boolean = false;
  public infoDiv = true;
  public infoDiv1 = true;
  plchldrState = "Province";
  plchldrPostal = "Postal";
  enteredEmail: string;
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  updateAddress: string = '';
  countrySet: string = 'CA';
  infoSlide: boolean = false;
  maxNum: number = new Date().getFullYear();
  public dob: string;
  public age: number;
  public invalidDateMsg: string;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  submitErrorMsg: string = null;
  public submitError: boolean = false;
  userId: string = null;
  currentUser: any = {};

  user: User = {
    first_name: '',
    last_name: '',
    email: '',
    user_role : 5,
    password : '',
    is_terms_accepted : false,
    parent_email : undefined, 
    date_of_birth: undefined,
    student_referral: undefined
  };

  businessUser: BusinessUser = {
    organization_title: '',
    address: '',
    unit_number: undefined,
    country: '',
    organization_type: 2,
    state: '',
    city: '',
    zip_code: '',
    business_number: undefined,
    user: this.user,
    professional_email: undefined,
    latitude: undefined,
    longitude: undefined
  };

  error_messages = {
    'year_of_birth': [
      { type: 'required', message: 'This field is required.' },
      { type: 'min', message: 'Please enter valid Year.' },
      { type: 'max', message: 'Max ' + this.maxNum + ' is allowed.' }
    ]
  }

  constructor(public zone: NgZone, private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService) {
    this.firstStepForm = this.formBuilder.group({
      organization_title: new FormControl('', [Validators.required, Validators.maxLength(125), this.noWhitespaceValidator]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200), this.noWhitespaceValidator]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      zip_code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.noWhitespaceValidator]),
      business_number: new FormControl('', [Validators.minLength(10), Validators.maxLength(15)]),
      unit_number: new FormControl('', Validators.maxLength(50)),
      professional_email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
    });    
    this.secondStepForm = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl({value: null, disabled: true}, [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required),
      student_referral: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      year_of_birth: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)])
    }, {
      validators: [this.checkPassword.bind(this)]
    });

  }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.currentUser = currentUser;
      this.userId = this.currentUser.id;
    }
    let business = this.page.business;
    this.firstStepForm.controls['organization_title'].setValue(business.organization_title);
    this.firstStepForm.controls['state'].setValue(business.state);
    this.firstStepForm.controls['country'].setValue(business.country);
    this.firstStepForm.controls['address'].setValue(business.address);
    this.firstStepForm.controls['city'].setValue(business.city);
    this.firstStepForm.controls['zip_code'].setValue(business.zip_code);
    this.firstStepForm.controls['business_number'].setValue(business.business_number);
    this.firstStepForm.controls['unit_number'].setValue(business.unit_number);
    this.firstStepForm.controls['professional_email'].setValue(this.page.professional_email);
    this.secondStepForm.controls['email'].setValue(this.verifier.email);
    this.secondStepForm.controls['firstname'].setValue(this.verifier.first_name);
    this.secondStepForm.controls['lastname'].setValue(this.verifier.last_name);
    this.updateAddress = business.address;
    this.businessUser.latitude = business.latitude;
    this.businessUser.longitude = business.longitude;
  }

  showNext(isValid: boolean) {
    if (isValid){
      if (this.userId) {
        this.user.is_terms_accepted = true;
        this.save(isValid);
      } else {
        this.firstStep = false;
        this.secondStep = true;
      }
    } else {
      this.partialSubmitted = true;
    }
  }

  checkDate() {
    if (this.secondStepForm.get('year_of_birth').value !== "" && this.secondStepForm.get('year_of_birth').value !== null) {
      this.dob = (this.secondStepForm.get('year_of_birth').value).toString() + '-1-1';
      let timeDiff = Math.abs(Date.now() - new Date(this.dob.replace(/-/g, "/")).getTime());
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
      if(new Date(this.dob.replace(/-/g, "/")) > new Date()){
        this.invalidDate = true;
        this.invalidDateMsg = "The year of birth is invalid.";
      } else if(this.age < 20) {
        this.invalidDate = true;
        this.invalidDateMsg = "Sorry, the minimum age to set up a Business is 20 years old.";
      } else {
        this.invalidDate = false;
        this.invalidDateMsg = null;
      }
    }
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.user.is_terms_accepted = true;
    } else {
      this.user.is_terms_accepted = false;
    }
  }

  changeCountry(e){
    if (e.target.value == "USA"){
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
      this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5), this.noWhitespaceValidator])

    } else {
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
      this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6), this.noWhitespaceValidator])
    }
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.firstStepForm.controls['address'].setValue(null);
      this.showAddressError = true;
      this.isReadOnly = false;
    } else {
      this.firstStepForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (isValid && !this.invalidDate && this.user.is_terms_accepted) {
      if (this.userId == null) {
        this.user.first_name = this.secondStepForm.get('firstname').value;
        this.user.last_name = this.secondStepForm.get('lastname').value;
        this.user.email = this.secondStepForm.get('email').value;
        this.user.password = this.secondStepForm.get('password').value;
        this.user.date_of_birth = this.dob;
        this.user.is_terms_accepted = true;
      }
      this.businessUser.organization_title = this.firstStepForm.get('organization_title').value;
      this.businessUser.address = this.firstStepForm.get('address').value;
      this.businessUser.unit_number = this.firstStepForm.get('unit_number').value;
      this.businessUser.country = this.firstStepForm.get('country').value;
      this.businessUser.state = this.firstStepForm.get('state').value;
      this.businessUser.city = this.firstStepForm.get('city').value;
      this.businessUser.zip_code = this.firstStepForm.get('zip_code').value;
      this.businessUser.business_number = this.firstStepForm.get('business_number').value;
      this.businessUser.professional_email = this.firstStepForm.get('professional_email').value;
      this.authService.claimPage(this.businessUser, this.page.id, 2)
      .subscribe(response => {
        this.handleResponse(response);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }

  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.successOverlay.emit(true);
        this.enteredEmail = this.secondStepForm.controls['email'].value;
        this.firstStep = true;
        this.secondStep = false;
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.submitted = false;
        this.partialSubmitted = false;
    } else {
      this.firstStep = true;
      this.secondStep = false;
      this.firstStepForm.reset();
      this.secondStepForm.reset();
      this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.submitted = false;
      this.partialSubmitted = false;
      if (response.status === 112) {
        this.submitErrorMsg = response.message;
      } else {
        this.submitErrorMsg = "Something went wrong. Please try again later";
      }
      this.submitError = true;
      this.hideMessage();
    }
  }

  hideMessage() {
    setTimeout(() => {
      this.submitError = false;
    }, 6000);
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    const fkFields = Object.keys(data['user'] || {});
    let firstFormErr = false;
    fields.forEach(field => {
      if (this.firstStepForm.contains(field)) {
        this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
        firstFormErr = true;
      } else if (this.secondStepForm.contains(field)) {
        this.secondStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
    fkFields.forEach(fkField => {
      if (this.secondStepForm.contains(fkField)) {
        this.secondStepForm.get(fkField).setErrors({ aftersubmit: data['user'][fkField][0] });
      }
    });
    if (firstFormErr) {
      this.firstStep = true;
      this.secondStep = false;
    } else {
      this.firstStep = false;
      this.secondStep = true;
    }
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){ 
    this.hidePlaceResults = e;
  }

  formClick(){
    this.hidePlaceResults = true;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    this.firstStepForm.controls['address'].setValue(address);
    this.firstStepForm.controls['city'].setValue(place.address.municipality);
    if (lat && lon) {
      this.businessUser.latitude = lat;
      this.businessUser.longitude = lon;
    }
    this.firstStepForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
    this.isReadOnly = true;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  validateEmail(){
    let email = this.firstStepForm.get('professional_email').value;
    if (email && email.length > 0 && this.firstStepForm.controls['professional_email'].valid) {
      this.authService.validateEmail({"professional_email": email})
      .subscribe(response => {
        if (response.status === 111) {
          this.firstStepForm.controls['professional_email'].setErrors({'invalid_format': true});
        }
      })
    }
  }

}
