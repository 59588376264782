import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { InitiativeService } from '../../services/initiative.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ProfileService } from '../../services/profile.service';
import { PageService } from 'src/app/services/page.service';
import { TeamService } from '../../services/team.service';

@Component({
  selector: 'app-edit-initiatives',
  templateUrl: './edit-initiatives.component.html',
  styleUrls: ['./edit-initiatives.component.scss']
})

export class EditInitiativesComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("timelineInp") timelineInp: ElementRef;
  @ViewChild("searchInp") searchInp: ElementRef;
  modalRef: BsModalRef;
  linkModalRef: BsModalRef;
  public Editor = ClassicEditor;
  sdgs: any;
  initiativeId: string;
  header_image: any;
  tabActive = 3;
  missionStatementForm: FormGroup;
  submitted: boolean;
  disableSubmit: boolean;
  whatWeImpactForm: FormGroup;
  impactSubmitted: boolean;
  uploadForm: FormGroup;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  imageChangedEvent: any = '';
  croppedImage: any;
  profileImgSubmit : boolean
  initiativeSdg: any;
  goalsAndPlanForm: FormGroup;
  whoSupportUsForm: FormGroup;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage: string;
  coverImageUploadSizeMessage: string;
  previewThumbnail: any;
  previewThumbnailName: any;
  measurableForm: FormGroup;
  whoSupportUsArray: any;
  measurableArray: any;
  today = new Date();
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  timelineUpdForm: FormGroup;
  keywordArray: any = [];
  linkArray: any = [];
  timelines: any = [];
  timelineDocs: any = [];
  maxKeywordLimit: boolean;
  editInitiativeForm: FormGroup;
  isEndDate: boolean = false;
  supportUs: any;
  measurable: any;
  measurableUpdateBool: boolean = false;
  linkForm: FormGroup;
  allKeywords: any;
  markedSDG: any = [];
  radioActive = 4;
  public infoDiv1 = true;
  public infoDiv2 = true;
  thisSDG: any;
  sdKMaxLimit: boolean = false;
  delError: boolean;
  isHeaderToDel: boolean;
  user: any;
  display_image_delete: boolean;
  copyBool: boolean = false;
  editTimelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;
  teamMembers: any = [];
  volunteerForm: FormGroup;
  volunteersList: any = [];
  copyPageIDBool: boolean = false;
  schools = [];
  taggedSchool: any;
  taggedSchoolName: any;
  followBtnErr: boolean = false;
  initiative: any = {};
  intUsersNextPage: any = 1;
  interestedUsers:any = [];
  totalUsersCount: any = 0;
  linkAdded: boolean = false;
  timelineActive = 4;
  teamMemberNextPage: any = 1;
  totalTeamMembers: any = 0;
  selectedUser: any;
  invitationSuccess: boolean = false;
  invitationForm: FormGroup;
  copyMessageBool: boolean = false;
  searchQuery: string = "";
  InitiativeNextPage: any = 1;
  descriptionLimit: number = 97;

  constructor(private datePipe: DatePipe, private router: Router, private modalService: BsModalService, private customValidator: CustomvalidationService,
      private initiativeService: InitiativeService, public formBuilder: FormBuilder, private profileService: ProfileService, private pageService: PageService,  private teamService: TeamService) {
    var url = document.URL.split('/');
    this.initiativeId = url[url.length - 1];
    this.editInitiativeForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      summary: new FormControl('', [Validators.maxLength(200)]),
      start_date: new FormControl(''),
      end_date: new FormControl(''),
      keywords: new FormControl(''),
      searchBool: new FormControl(true)
    }, { validators: this.dateValidator });
    this.missionStatementForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    });
    this.linkForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      link: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.whatWeImpactForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    });
    this.measurableForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(75)]),
    });
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required]),
      thumbnail: [''],
      thumbnail_name: ['']
    });
    this.goalsAndPlanForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    });
    this.whoSupportUsForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)])
    });
    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      date: new FormControl(''),
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelineUpdForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)])
    });
    this.volunteerForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastName : new FormControl('', [Validators.required, Validators.maxLength(50)]),
    });
    this.invitationForm = this.formBuilder.group({
      invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
    });
  }


  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.initiativeService.getInitiatives(this.initiativeId)
    .subscribe(response => {
      let res = response.response;
      if (res.user !== this.user.id){
        this.router.navigate(['/dashboard']);
      }
      if (res.title == ""){
        this.router.navigate(['**']);
      }
      this.initiative = res;
      this.taggedSchool = res.school;
      this.taggedSchoolName = res.school_name;
      this.header_image = res.header_image;
      this.thisSDG = res.sdg;
      this.markedSDG = res.sdg;
      this.whoSupportUsArray = res.who_support_us;
      this.measurableArray = res.measurable;
      this.linkArray = res.link;
      this.keywordArray = res.keywords;
      this.allKeywords = res.keywords;
      this.initiativeService.getSdks()
        .subscribe(response => {
            this.sdgs = response.response
            this.sdgMapping(this.thisSDG);
        });
    }, error => {
      this.router.navigate(['**']);
    });
    this.getAllTimelines();
    this.getUserSchools();
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  supportsConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ]
  };

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  checkTagged(){
    if (this.taggedSchool && this.taggedSchoolName) {
      let index = this.schools.findIndex(obj => obj.id === parseInt(this.taggedSchool));
      if (index === -1){
        return true;
      }
    }
    return false;
  }

  sdgCheckEvent(id: string){
    if (this.markedSDG.indexOf(id) === -1){
      this.markedSDG.push(id);
      if (this.markedSDG.length > 5){
        this.sdKMaxLimit = true;
      }
    }
    else{
       this.markedSDG.splice(this.markedSDG.indexOf(id), 1)
      if (this.markedSDG.length <= 5){
        this.sdKMaxLimit = false;
      }
    }
  }

  getUserSchools(){
    this.schools = [];
      this.profileService.getUserSchools()
      .subscribe(response => {
        if (response.response.length > 0) {
          this.schools = response.response;
        } else {
          this.schools = [];
        }
      }, error => {
        this.schools = [];
      });
  }

  getTeamItems(){
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getTeam();
    if (this.volunteersList.length == 0) {
      this.getVolunteers();
    }
  }

  getVolunteers(){
    this.initiativeService.getVolunteers(this.initiativeId)
    .subscribe(response => {
      this.volunteersList = response.response;
    });
  }

  getTeam(){
    let params = '?';
    if (this.teamMemberNextPage > 1){
      params = params + '&page=' + this.teamMemberNextPage;
    }
    if (this.searchQuery && this.searchQuery.length > 2) {
      params = params + '&search=' + this.searchQuery;
    }
    this.initiativeService.getTeam(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.teamMemberNextPage == 1 || this.teamMemberNextPage === null) {
          this.teamMembers = response.response.results;
        } else {
          this.teamMembers = this.teamMembers.concat(response.response.results);
        }
        this.teamMemberNextPage = response.response.next;
        this.totalTeamMembers = response.response.total_count;
      } else {
        this.teamMembers = [];
        this.teamMemberNextPage = 1;
        this.totalTeamMembers = 0;
      }
    });
  }

  removeHeader(){
    let model = {"header_img": "true"}
    this.initiativeService.removeHeaderImage(model, this.initiativeId)
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.modalRef.hide();
        if (this.isHeaderToDel) {
          this.header_image = null;
        } else {
          this.header_image = null;
        }
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
   }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0,0,0,0);
      end_date = new Date(end_date).setHours(0,0,0,0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }else if (!start_date && end_date){
      return { 'startDateNA' : true}
    }
    return null;
  }

  addSDG(){
    if (!this.sdKMaxLimit){
      this.markedSDG = this.markedSDG.slice(0, 5)
      let model = {
        "sdg": this.markedSDG
      }
      this.initiativeService.addSDG(model, this.initiativeId)
      .subscribe(response => {
        this.handleAddSDGResponse(response)
      }, error => {
        this.handleAddSDGError(error.error)
      })
    }
  }

  protected handleAddSDGResponse(response: any){
    if (response.status === 111){
      this.handleAddSDGError(response.message)
    } else if (response.status === 200){
      this.modalRef.hide();
      this.thisSDG = response.response
      this.sdgMapping(this.thisSDG);
    }
  }

  protected handleAddSDGError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
  }

  addSupporter(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      let model = {
        'initiative': this.initiativeId,
        'title': this.whoSupportUsForm.get('title').value,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addSupporter(model)
        .subscribe(response => {
          this.handleWhoSupportedUsResponse(response);
        }, error => {
          this.handleSupporterSubmitError(error.error)
        })
    }
  }

  protected handleWhoSupportedUsResponse(response: any) {
    if (response.status === 111) {
      this.handleSupporterSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.whoSupportUsArray.push(response.response);
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleSupporterSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.whoSupportUsForm.contains(field)) {
        this.whoSupportUsForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  updateSupporter(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      const prev_thumbnail_name = this.uploadForm.get('thumbnail_name').value;
      let thumbnail_name = this.supportUs.thumbnail_name;
      if (prev_thumbnail_name && prev_thumbnail_name.length > 0) {
        thumbnail_name = prev_thumbnail_name;
      }
      let model = {
        'initiative': this.initiativeId,
        'title': this.whoSupportUsForm.get('title').value,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': thumbnail_name
      }
      this.initiativeService.updateSupporter(model, this.supportUs.id)
        .subscribe(response => {
          this.handleWhoSupportedUpdateResponse(response);
        }, error => {
          this.handleSupporterSubmitError(error.error)
        })
    }
  }

  protected handleWhoSupportedUpdateResponse(response: any) {
    if (response.status === 111) {
      this.handleSupporterSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.getWhoSupportUs()
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  updateMeasurable(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      let model = {
        'initiative': this.initiativeId,
        'description': this.measurableForm.get('description').value
      }
      this.initiativeService.updateMeasurable(model, this.measurable.id)
        .subscribe(response => {
          this.handleMeasurableUpdateResponse(response);
        }, error =>{
          this.handleMeasurableSubmitError(error.error)
        })
    }
  }

  protected handleMeasurableUpdateResponse(response: any) {
    if (response.status === 111) {
      this.handleMeasurableSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.getOurMeasurable();
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  getWhoSupportUs(){
    this.initiativeService.getSupporter(this.initiativeId)
    .subscribe(response => {
      if (response.response){
        this.whoSupportUsArray = response.response
      }
    });
  }

  getOurMeasurable(){
    this.initiativeService.getMeasurable(this.initiativeId)
    .subscribe(response => {
      if (response.response){
        this.measurableArray = response.response
      }
    });
  }

  getLink(){
    this.initiativeService.getLinks(this.initiativeId)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  addLink(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    let link = this.linkForm.get('link').value;
    if (link) {
      link = this.updateLink(link);
    }
    if (isValid){
      let model = {
        'initiative': this.initiativeId,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addLink(model)
        .subscribe(response => {
          this.handleAddLinkResponse(response);
        }, error =>{
          this.handleAddLinkSubmitError(error.error)
        })
    }
  }

  protected handleAddLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleAddLinkSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.linkArray.push(response.response);
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleAddLinkSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.linkForm.contains(field)) {
        this.linkForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delLink(id:any){
    var id = id;
    this.initiativeService.deleteLink(id)
    .subscribe(response => {
      let index = this.linkArray.findIndex(obj => obj.id === id)
      this.linkArray.splice(index, 1)
    })
  }

  delSupporter(id:any){
    var id = id;
    this.initiativeService.deleteSupporter(id)
    .subscribe(response => {
      let index = this.whoSupportUsArray.findIndex(obj => obj.id === id)
      this.whoSupportUsArray.splice(index, 1)
    })
  }

  delMeasurable(id:any){
    var id = id;
    this.initiativeService.deleteMeasurable(id)
    .subscribe(response => {
      let index = this.measurableArray.findIndex(obj => obj.id === id)
      this.measurableArray.splice(index, 1)
    })
  }

  blankSupportImageUpload(update: boolean = true, element){
    if (update){
      this.supportUs.thumbnail_name = '';
    }
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  blankProfileImage(element){
    element.value = '';
    this.uploadForm.reset();
    this.display_image_delete = true;
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  editInitiativeDetails(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid && !this.maxKeywordLimit){
      const prev_thumbnail_name = this.uploadForm.get('thumbnail_name').value;
      let thumbnail_name = this.initiative.display_picture;
      if (prev_thumbnail_name && prev_thumbnail_name.length > 0) {
        thumbnail_name = prev_thumbnail_name;
      }
      if (this.display_image_delete){
        thumbnail_name = "";
      }
      let start_date = this.datePipe.transform(this.editInitiativeForm.get('start_date').value,"yyyy-MM-dd");
      let end_Date = this.datePipe.transform(this.editInitiativeForm.get('end_date').value,"yyyy-MM-dd");
      this.keywordArray = this.allKeywords;
      let model = {
        'title': this.editInitiativeForm.get('title').value,
        'city': this.editInitiativeForm.get('city').value,
        'state': this.editInitiativeForm.get('state').value,
        'start_date': start_date,
        'end_date': end_Date,
        'display_picture': this.uploadForm.get('thumbnail').value,
        'display_name': thumbnail_name,
        'summary': this.editInitiativeForm.get('summary').value,
        'keywords': this.keywordArray,
        'searchable': this.editInitiativeForm.get('searchBool').value,
      }
      this.initiativeService.editInitiativedetails(model, this.initiativeId)
        .subscribe(response => {
          this.handleEditDetailResponse(response);
        }, error => {
          this.handleEditDetailSubmitError(error.error)
        })
    }
  }

  protected handleEditDetailResponse(response: any) {
    if (response.status === 111) {
      this.handleEditDetailSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.initiative = response.response;
      this.keywordArray = response.response.keywords;
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
      this.maxKeywordLimit = false;
    }
  }

  protected handleEditDetailSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.editInitiativeForm.contains(field)) {
        this.editInitiativeForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  checkCommaVal(event){
    this.maxKeywordLimit = false;
    var keywordInput = this.editInitiativeForm.get('keywords').value;
    var allKeywordsLocal = keywordInput.split(",");
    if (event.key === ","){
       allKeywordsLocal.pop();
      if (allKeywordsLocal.length >= 10){
        this.maxKeywordLimit = true;
        allKeywordsLocal = allKeywordsLocal.slice(0,10);
      }
    }
    else if (allKeywordsLocal.length > 10){
      this.maxKeywordLimit = true;
      allKeywordsLocal = allKeywordsLocal.slice(0,10);
    }
    this.allKeywords = allKeywordsLocal;
  }

  coverImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 2000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 2MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnail = reader.result;
          this.previewThumbnailName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
          this.display_image_delete = false;
        };
      }
    }
  }

  config = {
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered custom-modal new-modal-ui',
  };


  editImagetModal(editImageTemplate: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.profileImgSubmit = false;
    this.modalRef = this.modalService.show(editImageTemplate, this.config);
  }

  deleteBannerModal(deleteBannerTemplate: TemplateRef<any>) {
    this.delError = false;
    if (this.header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.modalRef = this.modalService.show(deleteBannerTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  endDate(e){
    const key = e.target.value;
    if(key == "1"){
      this.isEndDate = true;
      this.editInitiativeForm.get('end_date').setValidators([Validators.required]);
      this.editInitiativeForm.get('end_date').updateValueAndValidity();
      let endDate = this.initiative.end_date ? this.initiative.end_date: null;
      this.editInitiativeForm.controls['end_date'].setValue(endDate);
    }else{
      this.isEndDate = false;
      this.editInitiativeForm.get('end_date').clearValidators();
      this.editInitiativeForm.get('end_date').updateValueAndValidity();
      this.editInitiativeForm.controls['end_date'].setValue(null);
    }
  }

  updateInitiative(updateInitiativeTemplate: TemplateRef<any>) {
    this.editInitiativeForm.reset();
    this.uploadForm.reset();
    this.display_image_delete = false;
    this.editInitiativeForm.controls['title'].setValue(this.initiative.title);
    this.editInitiativeForm.controls['city'].setValue(this.initiative.city);
    this.editInitiativeForm.controls['state'].setValue(this.initiative.state);
    this.editInitiativeForm.controls['start_date'].setValue(this.initiative.start_date);
    this.editInitiativeForm.controls['summary'].setValue(this.initiative.summary);
    this.editInitiativeForm.controls['searchBool'].setValue(this.initiative.searchable);
    if (this.initiative.start_date && this.initiative.end_date == null){
      this.isEndDate = false;
    } else {
      this.isEndDate = true;
      this.editInitiativeForm.controls['end_date'].setValue(this.initiative.end_date);
    }
    this.allKeywords = this.keywordArray;
    if (this.keywordArray && this.keywordArray.length > 0){
      let keywordString: string = this.keywordArray.join(",");
      this.editInitiativeForm.controls['keywords'].setValue(keywordString);
    }
    this.maxKeywordLimit = false;
    this.previewThumbnail = this.initiative.display_picture;
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(updateInitiativeTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editTimelineModal(editTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.timelineUpdForm.reset();
    this.editTimelineObj = timeline;
    this.timelineUpdForm.controls['description'].setValue(timeline.description);
    this.modalRef = this.modalService.show(editTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTimelineModal(delTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(delTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  resetEndDate(){
    this.editInitiativeForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.editInitiativeForm.controls['start_date'].setValue(null);
  }

  addImage(isValid: boolean) {
    this.profileImgSubmit = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value,
        'display_pic': false,
        'header_img': true,
      }
      this.initiativeService.updateInitiative(model, this.initiativeId)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.profileImgSubmit = false;
      this.disableSubmit = false;
      this.header_image = response.response.header_image;
      if (this.header_image){
        this.header_image = this.header_image;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  sdgMapping(sdgList: any){
    this.initiativeSdg = sdgList.map(item => {
      const container = {};
      for (let sdg of this.sdgs){
        if (item === sdg.id){
          container['id'] = item;
          container['icon'] = sdg.icon;
          break;
        }
      }
      return container
    })
  }

  saveMissionStatement(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      let model = {
        'id': this.initiativeId,
        'mission_statement': this.missionStatementForm.get('description').value
      }
      this.initiativeService.saveMissionStatement(model, this.initiativeId)
        .subscribe(response => {
          this.handleMissionSaveResponse(response);
        }, error =>{
          this.handleMissionSubmitError(error.error)
        })
    }
  }

  deleteMS(){
    let model = {
      'id': this.initiativeId,
      'mission_statement': ""
    }
    this.initiativeService.saveMissionStatement(model, this.initiativeId)
    .subscribe(response => {
      this.handleMissionSaveResponse(response);
    }, error =>{
      this.handleMissionSubmitError(error.error)
    })
  }

  protected handleMissionSaveResponse(response: any) {
    if (response.status === 111) {
      this.handleMissionSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.initiative.mission_statement = response.response.mission_statement;
      this.submitted = false;
    }
  }

  protected handleMissionSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.missionStatementForm.contains(field)) {
        this.missionStatementForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  copyData(data: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyAndShare(){
    var siteUrl: any = document.URL.split('/')
    siteUrl = siteUrl.splice(0, 3).join("/") + "/initiatives/" + this.initiativeId + "/";
    this.copyData(siteUrl);
    this.copyBool = true;
    setTimeout(() => {this.copyBool = false  ;}, 3000);
  }

  addMeasurable(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid){
      let model = {
        'initiative': this.initiativeId,
        'description': this.measurableForm.get('description').value
      }
      this.initiativeService.addMeasurables(model)
        .subscribe(response => {
          this.handleMeasurableResponse(response);
        }, error =>{
          this.handleMeasurableSubmitError(error.error)
        })
    }
  }

  protected handleMeasurableResponse(response: any) {
    if (response.status === 111) {
      this.handleMeasurableSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.measurableArray.push(response.response);
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleMeasurableSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.measurableForm.contains(field)) {
        this.measurableForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveWhatWeImpact(isValid: boolean){
    this.impactSubmitted = true;
    if (isValid){
      let model = {
        'id': this.initiativeId,
        'what_we_impact': this.whatWeImpactForm.get('description').value
      }
      this.initiativeService.saveWhatWeImpact(model, this.initiativeId)
        .subscribe(response => {
          this.handleImpactSaveResponse(response);
        }, error =>{
          this.handleImpactSubmitError(error.error)
        })
    }
  }

  deleteWhatWeImpact(){
    this.impactSubmitted = true;
    let model = {
      'id': this.initiativeId,
      'what_we_impact': ""
    }
    this.initiativeService.saveWhatWeImpact(model, this.initiativeId)
      .subscribe(response => {
        this.handleImpactSaveResponse(response);
      }, error =>{
        this.handleImpactSubmitError(error.error)
      })
  }

  protected handleImpactSaveResponse(response: any) {
    if (response.status === 111) {
      this.handleImpactSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.initiative.what_we_impact = response.response.what_we_impact;
      this.impactSubmitted = false;
    }
  }

  protected handleImpactSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.whatWeImpactForm.contains(field)) {
        this.whatWeImpactForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveGoalsAndPlans(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'id': this.initiativeId,
        'goals_and_plans': this.goalsAndPlanForm.get('description').value
      }
      this.initiativeService.saveGoalAndPlan(model, this.initiativeId)
        .subscribe(response => {
          this.handleGoalSaveResponse(response);
        }, error =>{
          this.handleGoalSubmitError(error.error)
        })
    }
  }

  deleteGoalsAndPlans(){
    this.submitted = true;
    let model = {
      'id': this.initiativeId,
      'goals_and_plans': ""
    }
    this.initiativeService.saveGoalAndPlan(model, this.initiativeId)
      .subscribe(response => {
        this.handleGoalSaveResponse(response);
      }, error =>{
        this.handleGoalSubmitError(error.error)
      })
  }

  protected handleGoalSaveResponse(response: any) {
    if (response.status === 111) {
      this.handleGoalSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.initiative.goals_and_plans = response.response.goals_and_plans;
      this.submitted = false;
    }
  }

  protected handleGoalSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.goalsAndPlanForm.contains(field)) {
        this.goalsAndPlanForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addTimelineModal(template: TemplateRef<any>) {
    this.linkAdded = false;
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.radioActive = 4;
    this.timelineDocs = [];
    this.timelineForm.reset();
    this.timelinelinkForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  editSdgModal(editSdgTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(editSdgTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  missionModal(missionTemplate: TemplateRef<any>) {
    this.missionStatementForm.reset();
    this.missionStatementForm.controls['description'].setValue(this.initiative.mission_statement);
    this.modalRef = this.modalService.show(missionTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  impactModal(impactTemplate: TemplateRef<any>) {
    this.whatWeImpactForm.reset();
    this.whatWeImpactForm.controls['description'].setValue(this.initiative.what_we_impact);
    this.modalRef = this.modalService.show(impactTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  goalsModal(goalsTemplate: TemplateRef<any>) {
    this.goalsAndPlanForm.reset();
    this.goalsAndPlanForm.controls['description'].setValue(this.initiative.goals_and_plans);
    this.modalRef = this.modalService.show(goalsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  measurableModal(measurableTemplate: TemplateRef<any>) {
    this.measurableForm.reset();
    this.measurableUpdateBool = false;
    this.submitted = false;
    this.disableSubmit = false;
    this.modalRef = this.modalService.show(measurableTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  supportModal(suportTemplate: TemplateRef<any>) {
    this.whoSupportUsForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(suportTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editSupportModal(editSuportTemplate: TemplateRef<any>, supportUs: any) {
    this.supportUs = supportUs
    this.whoSupportUsForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = supportUs.thumbnail;
    this.whoSupportUsForm.controls['title'].setValue(supportUs.title);
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(editSuportTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editMeasurableModal(measurableTemplate: TemplateRef<any>, measurable: any) {
    this.measurableUpdateBool = true;
    this.measurable = measurable;
    this.measurableForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.measurableForm.controls['description'].setValue(measurable.description);
    this.modalRef = this.modalService.show(measurableTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  linkModal(linkTemplate: TemplateRef<any>) {
    this.linkForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(linkTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addTimelineLinkModal(addTimelineLink: TemplateRef<any>) {
    this.linkAdded = false;
    this.linkModalRef = this.modalService.show(addTimelineLink,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'custom-modal mobile-position modal-dialog modal-dialog-centered new-modal-ui newfeeddetail' })
    );
  }
  leadersModal(template: TemplateRef<any>) {
    this.submitted = false;
    this.volunteerForm.reset();
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  sdgInSDGList(sdg: number){
    if (this.thisSDG.indexOf(sdg) === -1){
      return false;
    } else {
      return true;
    }
  }

  changeType(){
    if (this.radioActive === 10) {
      this.timelineForm.get('date').setValidators([Validators.required]);
      this.timelineForm.controls["date"].updateValueAndValidity();
    } else {
      this.timelineForm.controls["date"].setErrors(null);
      this.timelineForm.get('date').clearValidators();
      this.timelineForm.controls["date"].updateValueAndValidity();
    }
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'initiative': this.initiativeId,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'date': this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd")
      }
      if (this.radioActive == 4) {
        this.addPageTimeline(model);
      } else if (this.radioActive == 10) {
        this.addVolunteerPost(model);
      } else {
        this.addVolunteerPost(model);
      }
    }
  }

  addPageTimeline(model: any){
    this.initiativeService.addTimeline(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    })
  }

  addVolunteerPost(model: any){
    model['post_type'] = this.radioActive;
    this.initiativeService.addIniVolunteer(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    });
  }

  handlePostResponse(response: any){
    if (response.status === 200) {
      this.timelineDocs = [];
      this.modalRef.hide();
      this.timelineForm.reset();
      this.timelinelinkForm.reset();
      this.submitted = false;
      if (this.timelineActive == this.radioActive) {
        this.timelineActive = this.radioActive;
      } else {
        this.timelineActive = 4;
      }
      this.timelineNextPage=1;
      this.getTimelines();
    } else {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.linkModalRef.hide();
      this.linkAdded = true;
      setTimeout(() => {this.linkAdded = false;}, 4000);
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  updateTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {
        'description': this.timelineUpdForm.get('description').value
      }
      if (this.editTimelineObj.post_type == 4) {
        this.updatePageTimeline(model);
      } else if (this.editTimelineObj.post_type > 4) {
        this.updateVolunteerJob(model);
      }
    }
  }

  updatePageTimeline(model: any) {
    this.initiativeService.updateTimeline(model, this.editTimelineObj.id)
        .subscribe(response => {
          this.handleupdResponse(response);
        }, error => {
          this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
        })
  }

  updateVolunteerJob(model: any) {
    this.initiativeService.updateIniVolunteer(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  protected handleupdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      let index = this.timelines.findIndex(obj => (obj.id === this.editTimelineObj.id && obj.post_type === this.editTimelineObj.post_type));
      this.timelines[index].description = response.response.description;
      this.timelineUpdForm.reset();
      this.editTimelineObj = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  delTimelineElem(){
    if (this.editTimelineObj.post_type == 4) {
      this.deleteTimeline();
    } else {
      this.deleteVolunteer();
    }
  }

  deleteTimeline(){
    this.initiativeService.deleteTimeline(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.timelines.findIndex(obj => (obj.id === this.editTimelineObj.id && obj.post_type === this.editTimelineObj.post_type));
      this.timelines.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  deleteVolunteer(){
    this.initiativeService.deleteIniVolunteer(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.timelines.findIndex(obj => (obj.id === this.editTimelineObj.id && obj.post_type === this.editTimelineObj.post_type));
      this.timelines.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  getTimelines(){
    if (this.timelineActive == 4) {
      this.getAllTimelines();
    } else {
      this.getEventVolunteers();
    }
  }

  getAllTimelines(){
    let params = '?';
    if (this.timelineNextPage > 1){
      params = params + '&page=' + this.timelineNextPage;
    }
    this.initiativeService.getAllIniTimeline(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  getEventVolunteers(){
    let params = '?';
    if (this.timelineActive && this.timelineActive > 4) {
      params = params + 'post_type=' + this.timelineActive;
    }
    if (this.timelineNextPage > 1){
      params = params + '&page=' + this.timelineNextPage;
    }
    this.initiativeService.getIniVolunteer(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  uploadTimelineDoc(event) {
    let fileSizeVal = 5000;
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0) {
      let fileCount = this.timelineDocs.length + event.target.files.length;
      if (fileCount > 5) {
        this.coverImageUploadError = true;
        this.coverImageUploadMessage = "Maximum 5 photos allowed.";
        event.target.value = null;
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        let file_name = event.target.files[i].name;
        const fileExtension = file_name.split('.').pop();
        if (file_name.length > 30) {
          file_name = "cu-media." + fileExtension;
        }
        const fileSize = Math.round(file.size / 1024);
        if (!allowedExtensions.includes(fileExtension)) {
          this.coverImageUploadError = true
          this.coverImageUploadMessage = "Only image files allowed.";
        } else {
          this.coverImageUploadError = false;
          this.coverImageUploadMessage = "";
        }
        if (fileSize > fileSizeVal) {
          this.coverImageUploadSizeError = true
          this.coverImageUploadSizeMessage = "Image size should be less than 5MB.";
        } else {
          this.coverImageUploadSizeError = false;
          this.coverImageUploadSizeMessage = "";
        }
        if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e): void => {
            const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
            )[1];
            if (this.timelineDocs.length < 5){
              let timelineDoc = { 'previewImg': reader.result, 'document': base64String, 'document_name': file_name };
              this.timelineDocs.unshift(timelineDoc);
            }
            event.target.value = null;
          };
        } else {
          event.target.value = null;
          setTimeout(() => { this.coverImageUploadError = false; this.coverImageUploadSizeError = false; }, 5000);
          break;
        }
      }
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  addVolunteer(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'initiative': this.initiativeId,
        'first_name': this.volunteerForm.get('firstName').value,
        'last_name': this.volunteerForm.get('lastName').value
      }
      this.initiativeService.addVolunteers(model)
        .subscribe(response => {
          this.handleAddVolunteerResponse(response);
        }, error =>{
          this.handleAddVolunteerSubmitError(error.error)
        })
    }
  }

  protected handleAddVolunteerResponse(response: any) {
    if (response.status === 111) {
      this.handleAddVolunteerSubmitError(response.message);
    } else if (response.status === 200) {
      this.volunteersList.push(response.response);
      this.volunteerForm.reset();
      this.submitted = false;
      this.modalRef.hide();
    }
  }

  protected handleAddVolunteerSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.volunteerForm.contains(field)) {
        this.volunteerForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  deleteVol(id: any){
    this.initiativeService.deleteVolunteer(id)
    .subscribe(response => {
      let index = this.volunteersList.findIndex(obj => obj.id === id)
      this.volunteersList.splice(index, 1)
    })
  }

  copyPageID(){
    let data = "INI" + this.initiativeId;
    this.copyData(data);
    this.copyPageIDBool = true;
    setTimeout(() => {this.copyPageIDBool = false;}, 3000);
  }

  showImg(headerImages: TemplateRef<any>) {
    if (this.header_image) {
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
      );
    }
  }

  tagSchool(e){
    let schoolId = e.target.value;
    if (schoolId < 1){
      schoolId = null;
    }
    let model = {'school': schoolId};
    this.initiativeService.tagInitiativeSchool(this.initiativeId, model)
    .subscribe(response => {
      this.taggedSchool = schoolId;
    });
  }

  followInitiative(){
    this.followBtnErr = false;
    if (this.initiative.is_followed) {
      this.pageService.unFollowInitiative(this.initiative.id, {})
      .subscribe(response => {
        this.initiative.is_followed = false;
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    } else {
      this.pageService.followInitiative(this.initiative.id, {})
      .subscribe(response => {
        if (response.status === 200) {
          this.initiative.is_followed = true;
        } else {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        }
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    }
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 6000);
  }

  openModalOthers(template: TemplateRef<any>, timeline: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editTimelineObj = timeline;
    this.getInterestedUsers();
    this.modalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others custom-profile-modal' }));
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editTimelineObj.id, this.editTimelineObj.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  markInterested(timeline: any, post_type: any){
    if (timeline.interested_data.interested) {
      this.pageService.unMarkInterested(timeline.id, post_type)
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => (obj.id === timeline.id && obj.post_type === timeline.post_type));
      this.timelines[index].interested_data.interested = false;
    } else {
      this.pageService.markInterested(timeline.id, post_type, {})
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => (obj.id === timeline.id && obj.post_type === timeline.post_type));
      this.timelines[index].interested_data.interested = true;
    }
  }

  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };

  directToProfile(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      this.router.navigate(['profile/' + member.user + '/']);
    }
  }

  checkClick(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      return true;
    }
    return false;
  }

  openModal(member: any, template: TemplateRef<any>) {
    this.selectedUser = member;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.modalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal new-modal-ui' })
    );
  }

  copyMessage(){
    let msg = "Join my network at " + this.initiative.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.copyData(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'initiative': this.initiativeId
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  searchStudent(event: any){
    let name = event.target.value;
    if (name.length === 0) {
      this.resetSearch();
    } else if (name.length > 2) {
      this.searchQuery = name;
      this.teamMemberNextPage = 1;
      this.getTeam();
    } else {
      this.searchQuery = null;
    }
  }

  resetSearch(){
    if (this.searchInp){
      this.searchInp.nativeElement.value = "";
    }
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getTeam();
  }

}
