<section class="dashboardLanding">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5 col-xl-3 leftWrap">
        <div class="whiteWrapEach" id="target">
          <div class="forProfile">
            <div class="profileThumb">
              <img src="assets/images/p-img.png" class="profileImg" alt="">
            </div>
            <div class="profileDes">
              <h1>{{user.first_name}} {{user.last_name}}</h1>
              <a class="btn btnBlue">Go to Resume</a>
            </div>
            <div class="btn-group">
              <button type="button">
                <img src="/assets/images/network-icon.svg" alt="network" />
                My Network
                <span>67</span>
              </button>
              <div class="step2-box">
                <button type="button">
                  <img src="/assets/images/interested-star-filled.svg" alt="star-filled" />
                  Interested
                  <span>12</span>
                </button>
                <div class="dialoginterested-box">
                  <div class="white-dialog-content">
                    <p>
                      When you click "I'm interested" on a post, it saves here! Click it to view all.
                    </p>
                  </div>
                  <button type="button" (click)="followingViewEvent()">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach step3-box">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">Following (34)</h3>
              <a href="javascript:void();">View All</a>
            </div>
            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer">
                <div class="leftImage">
                  <img class="pageThumb" alt="page-image" src="/assets/images/following-1.svg" />
                </div>
                <div class="rightText">
                  <h3>DECA</h3>
                  <p>Bill Crothers S.S.</p>
                </div>
              </div>
              <div class="pagesListEach cursor-pointer">
                  <div class="leftImage">
                    <img class="pageThumb" alt="page-image" src="/assets/images/following-2.svg" />
                  </div>
                  <div class="rightText">
                    <h3>Science Club</h3>
                    <p>Bill Crothers S.S.</p>
                  </div>
                </div>
            </div>
            <div class="dialogfollowing-box">
              <div class="white-dialog-content">
                <p>
                  All of the pages you follow can be accessed here
                </p>
              </div>
              <button type="button" (click)="doneEvent()">Done</button>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach">
          <div class="forPages mt-0">
            <div class="pagesHdgWrap">
              <h3 class="">My Pages</h3>
              <a href="javascript:void();">View All</a>
            </div>
            <div class="pagesListWrap scroll-activity scrollbar">
              <div class="pagesListEach cursor-pointer">
                <div class="leftImage">
                  <img class="pageThumb" src="/assets/images/my-pages-1.svg" />
                </div>
                <div class="rightText">
                  <h3>Talk 4 Disabilities</h3>
                  <p>Markham, ON</p>
                </div>
              </div>
              <div class="pagesListEach cursor-pointer">
                  <div class="leftImage">
                    <img class="pageThumb" src="/assets/images/my-pages-1.svg" />
                  </div>
                  <div class="rightText">
                    <h3>McDonald's</h3>
                    <p>Highway 7, Markham, ON</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="whiteWrapEach">
          <div class="pagesHdgWrap">
            <h3 class="">resources</h3>
          </div>
          <div class="createResourceEach">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Initiative</h3>
              <a href="javascript:void();">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>
          <div class="createResourceEach">
            <div class="leftImage">
              <img alt="resourceThumb" class="resourceThumb" src="assets/images/plusGrey.svg">
            </div>
            <div class="rightText">
              <h3>Create an Organization Page</h3>
              <a href="javascript:void();">Start
                <img alt="arrowRight" class="arrowRight" src="assets/images/arrowRight.svg">
              </a>
            </div>
          </div>
          <div class="resourceWhatIs">
            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/startIni.png">
              <a href="https://www.cuclub.today/" target="_blank">
                <p>CU 4 Community</p>
              </a>
            </div>
            <div class="eachWhatIs">
              <img alt="initiative" class="initiativeThumb" src="assets/images/whatIni.png">
              <a href="https://www.cuclub.today/cu-clubs" target="_blank">
                <p>What is an Initiative?</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-9 rightWrap">
        <div class="rightWrapInner">
          <div class="searchContent">
            <div class="eachHdg">
              <h3 class="">searches</h3>
            </div>
            <div class="searchBy">
              <div class="row">
                  <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                      <div class="eachSearchByInner">
                        <img alt="searchBg" class="searchBg" src="assets/images/pagesSearch.jpg">
                        <div class="overlayName">
                          <p>Schools</p>
                        </div>
                      </div>
                    </div>
                <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                  <div class="eachSearchByInner forObjectTop">
                    <img alt="searchBg" class="searchBg" src="assets/images/studentsSearch.jpg">
                    <div class="overlayName">
                      <p>Students</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                  <div class="eachSearchByInner">
                    <img alt="searchBg" class="searchBg" src="assets/images/initSearch.jpeg">
                    <div class="overlayName">
                      <p>Initiatives</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightWrapInner">
          <div class="tab-buttons">
            <button type="button" class="active">
              Following
            </button>
            <button type="button">
              Your School
            </button>
          </div>
          <div class="button-heading">
            <div class="create-btn-group">
              <button type="button" class="disabled">
                  <img src="/assets/images/School Post.svg" alt="edit">
              </button>
              <button type="button" class="disabled">
                  <img src="/assets/images/Initiative Post.svg" alt="edit">
              </button>
            </div>
            <div class="right-info">
              <!--Info Button-->
              <button type="button" class="info-btn">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25"
                  height="25.342" viewBox="0 0 25 25.342">
                  <defs>
                    <ellipse id="ellipse-1" cx="12.5" cy="12.5" rx="12.5" ry="12.5" />
                    <mask id="mask-2" x="0" y="0" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                      <rect width="25" height="25.342" x="0" y="0" fill="black" />
                      <use fill="white" xlink:href="#ellipse-1" />
                    </mask>
                  </defs>
                  <g>
                    <text font-size="0" style="white-space:pre">
                      <tspan x="10" y="18.342" fill="rgb(51,81,117)" font-family="Arial" font-size="18"
                        font-weight="400" style="white-space:pre;text-transform:none" letter-spacing=".277">i</tspan>
                    </text>
                    <use fill-opacity="0" stroke="rgb(51,81,117)" stroke-linecap="butt" stroke-linejoin="miter"
                      stroke-width="2" mask="url(#mask-2)" xlink:href="#ellipse-1" />
                  </g>
                </svg>
              </button>
              <!--Info Button-->
            </div>

          </div>
          <div class="blog-listing" *ngIf="!onStart">
            <div class="feeds-box-listing">
                <div class="box">
                  <span class="event">Event</span>
                  <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel" [config]="communityConfig">
                    <div class="communitySlide slide" ngxSlickItem>
                      <div class="communitySlideInner">
                        <img  src="../../../assets/images/dashboard-box-image.jpg" alt="image" />
                      </div>
                    </div>
                  </ngx-slick-carousel>
                  <div class="content-box">
                    <div class="img-box">
                      <img class="cursor-pointer" src="../../../assets/images/profile.svg" alt="small-img" />
                    </div>
                    <div class="content">
                      <h3 class="cursor-pointer">Test Feed</h3>
                      <p>abc • Expires
                          <span>Today</span>
                      </p>
                      <div class="date-box">
                        <div class="month">
                          <span>Feb</span>
                        </div>
                        <div class="date">
                          <span>24</span>
                        </div>
                      </div>
                      <p class="para">
                        dsfsdfsdfsdfsfd
                      </p>
                    </div>
                    <div class="save-content">
                      <div class="left-part">
                        <div class="interestedview-box">
                            <button type="button" class="interest-btn">
                                <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                <span>Interested</span>
                              </button>
                              <div class="dialog-box">
                                  <div class="white-dialog-content">
                                    <p>
                                      Click "I'm interested" on a post to save it. The person posting can view your resume.
                                    </p>
                                  </div>
                                  <button type="button" (click)="nextViewEvent('target')">Next</button>
                                </div>
                        </div>
                        <button type="button" class="other-btn">
                          4 Others
                        </button>
                      </div>
                      <div class="right-part">
                        <div class="btn-group" dropdown>
                          <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                            aria-controls="dropdown-basic">
                            <img src="assets/images/dot-teal.svg" alt="more icon" />
                          </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                          aria-labelledby="button-basic">
                          <li role="menuitem">
                            <div class="flag-data-box cursor-pointer">
                              <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                              <span>Report Post</span>
                            </div>
                          </li>
                          <li role="menuitem">
                            <div class="flag-data-box cursor-pointer">
                              <div class="name">
                                <span class="dot">
                                  <img alt="profile-image"
                                    src="../../../assets/images/profile.svg" />
                                </span>
                                <span class="report-name">Mehul</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                    <span class="event">Event</span>
                    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel" [config]="communityConfig">
                      <div class="communitySlide slide" ngxSlickItem>
                        <div class="communitySlideInner">
                          <img  src="../../../assets/images/dashboard-box-image.jpg" alt="image" />
                        </div>
                      </div>
                    </ngx-slick-carousel>
                    <div class="content-box">
                      <div class="img-box">
                        <img class="cursor-pointer" src="../../../assets/images/profile.svg" alt="small-img" />
                      </div>
                      <div class="content">
                        <h3 class="cursor-pointer">Test Feed</h3>
                        <p>abc • Expires
                            <span>Today</span>
                        </p>
                        <div class="date-box">
                          <div class="month">
                            <span>Feb</span>
                          </div>
                          <div class="date">
                            <span>24</span>
                          </div>
                        </div>
                        <p class="para">
                          dsfsdfsdfsdfsfd
                        </p>
                      </div>
                      <div class="save-content">
                        <div class="left-part">
                              <button type="button" class="interest-btn">
                                  <img src="/assets/images/interested-star-empty.svg" alt="star-empty" />
                                  <span>Interested</span>
                                </button>
                          <button type="button" class="other-btn">
                            4 Others
                          </button>
                        </div>
                        <div class="right-part">
                          <div class="btn-group" dropdown>
                            <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                              aria-controls="dropdown-basic">
                              <img src="assets/images/dot-teal.svg" alt="more icon" />
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu name-mobile-box" role="menu"
                            aria-labelledby="button-basic">
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer">
                                <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                                <span>Report Post</span>
                              </div>
                            </li>
                            <li role="menuitem">
                              <div class="flag-data-box cursor-pointer">
                                <div class="name">
                                  <span class="dot">
                                    <img alt="profile-image"
                                      src="../../../assets/images/profile.svg" />
                                  </span>
                                  <span class="report-name">Mehul</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <!--Step 1-->
          <div class="clickbutton-box" *ngIf="onStart">
            <button class="step-button" (click)="openStep2Modal(template)">
              Click Here to Get Started
            </button>
          </div>
          <!--Step 1-->
        </div>
      </div>
    </div>
  </div>
</section>
<!--Step 2 Modal-->
<ng-template #template data-keyboard="false" data-backdrop="static" role="dialog">
  <div class="modal-body">
    <div class="form-group row m-0">
      <div class="col-md-12">
        <form [formGroup]="searchForm" novalidate>
          <h2>Search and Follow Your Current School</h2>
          <div class="form-row">
            <div class="form-group">
              <div class="left-part">
                <input type="text" class="form-control" formControlName="school_name"
                  placeholder="Full School/College/University Name" >
                <div class="error-message"
                  *ngIf="searchForm.get('school_name').hasError('required') && (searchForm.get('school_name').dirty || searchForm.get('school_name').touched || submitted)">
                  This field is required.
                </div>
                <div class="error-message"
                    *ngIf="searchForm.get('school_name').hasError('minlength') && (searchForm.get('school_name').dirty || searchForm.get('school_name').touched)">
                    Minimum 4 characters required.
                </div>
                <div class="error-message"
                    *ngIf="searchForm.get('school_name').hasError('maxlength') && (searchForm.get('school_name').dirty || searchForm.get('school_name').touched)">
                    Maximum 50 characters allowed.
                </div>
                <div class="error-message"
                    *ngIf="searchForm.get('school_name').hasError('whitespace') && (!searchForm.get('school_name').hasError('required')) && (searchForm.get('school_name').dirty || searchForm.get('school_name').touched || submitted)">
                    Please enter valid data.
                </div>
                <button class="btn btn-footer" (click)="searchSchool(searchForm.valid)">Search</button>
              </div>
              <button type="button" class="skip-btn" (click)="interestedViewEvent()">Skip</button>
            </div>
          </div>
          <div class="blog-listing scroll-activity scrollbar">
            <div class="blog-box" *ngFor="let school of schools">
              <div class="img-box">
                <img alt="school-image" src="{{school.display_picture_thumbnail? school.display_picture_thumbnail : 'assets/images/default.svg'}}" />
              </div>
              <div class="content-box">
                <div class="w-100">
                  <p class="bold">{{school.school_name}}</p>
                  <p class="timelineDes timelineDesOuterDots">
                    {{school.address}}
                  </p>
                </div>
                <button *ngIf="showBtn(school)" type="button" class="follow-btn" (click)="followSchool(school.id)">
                  Follow
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
