import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseProfileEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  businessProfileSearch(params: string) {
    return this.http.get(this.baseProfileUrl + 'search-profiles/' + params);
  }

  schoolProfileSearch(params: string) {
    return this.http.get(this.baseProfileUrl + 'search-school-profiles/' + params);
  }

  studentSearch(params: string) {
    return this.http.get(this.baseProfileUrl + 'search-students/' + params);
  }

  searchInitiatives(params: string){
    return this.http.get(this.baseProfileUrl + 'initiative-search/' + params);
  }

}
