import { Component, OnInit, TemplateRef, ViewChild, ElementRef  } from '@angular/core';
import { InitiativeService } from '../../services/initiative.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from 'src/app/services/page.service';
import { TeamService } from '../../services/team.service';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-view-initiative',
  templateUrl: './view-initiative.component.html',
  styleUrls: ['./view-initiative.component.scss']
})
export class ViewInitiativeComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("searchInp") searchInp: ElementRef;
  modalRef: BsModalRef;
  tabActive = 3;
  initiativeId: string;
  city: string;
  state: string;
  initiativeTitle: string;
  start_date: any;
  end_date: any;
  header_image: any;
  searchable: any;
  searchableForm: any;
  display_image: any;
  missionStatement: string;
  whatWeImpact: string;
  goalsAndPlans: string;
  whoSupportUsArray: any = [];
  measurableArray: any = [];
  linkArray: any = [];
  sdgs: any = [];
  initiativeSdg: any;
  public infoDiv1 = true;
  public infoDiv2 = true;
  blockReportForm: FormGroup;
  reportUser: boolean = false;
  reportUserChecked: boolean = false;
  submitted: boolean;
  user: any;
  userID: any = false;
  showSettings: boolean = true;
  copyBool: boolean = false;
  summary: any;
  teamMembers: any = [];
  volunteersList: any = [];
  timelines: any = [];
  timelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;
  copyPageIDBool: boolean = false;
  hasData: boolean = false;
  radioActive = 1;
  school_name: any;
  followBtnErr: boolean = false;
  initiative: any = {};
  intUsersNextPage: any = 1;
  interestedUsers:any = [];
  totalUsersCount: any = 0;
  timelineActive = 4;
  teamMemberNextPage: any = 1;
  totalTeamMembers: any = 0;
  selectedUser: any;
  invitationSuccess: boolean = false;
  invitationForm: FormGroup;
  copyMessageBool: boolean = false;
  searchQuery: string = "";
  InitiativeNextPage: any = 1;
  descriptionLimit:number = 97;

  constructor(private router: Router,private route: ActivatedRoute, private initiativeService: InitiativeService, private modalService: BsModalService,
    public formBuilder: FormBuilder, private pageService: PageService, private teamService: TeamService, private customValidator: CustomvalidationService, private authService: AuthenticationService, private profileService: ProfileService) {
        var url = document.URL.split('/')
        this.initiativeId = url[url.length - 1]
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser){
          this.userID = currentUser
        }
        this.blockReportForm = this.formBuilder.group({
          report_initiative: new FormControl(),
          report_reason: new FormControl('', [Validators.required]),
          report_description: new FormControl('', [Validators.maxLength(1000)])
        });
        this.invitationForm = this.formBuilder.group({
          invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
        });
  }
  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }
  ngOnInit(): void {

    this.initiativeService.getInitiatives(this.initiativeId)
    .subscribe(response => {
      let res = response.response;
      this.user = res.user;
      if (this.user === this.userID.id){
        this.showSettings = false;
      }
      this.initiative = res;
      this.initiativeTitle = res.title;
      if (res.title == ""){
        this.router.navigate(['**']);
      }
      this.school_name = res.school_name;
      this.city = res.city;
      this.state = res.state;
      this.start_date = res.start_date;
      this.end_date = res.end_date;
      this.header_image = res.header_image;
      this.display_image = res.display_picture;
      this.missionStatement = res.mission_statement;
      this.whatWeImpact = res.what_we_impact;
      this.goalsAndPlans = res.goals_and_plans;
      let thisSDG = res.sdg;
      this.whoSupportUsArray = res.who_support_us;
      this.measurableArray = res.measurable;
      this.linkArray = res.link;
      this.summary = res.summary;
      if (this.missionStatement || this.linkArray.length > 0 || this.whoSupportUsArray.length > 0 || this.whatWeImpact || this.measurableArray.length > 0 || this.goalsAndPlans || thisSDG.length > 0){
        this.hasData = true;
      }
      this.initiativeService.getSdks()
        .subscribe(response => {
          if (response.response.length > 0){
            this.sdgs = response.response
            this.sdgMapping(thisSDG);
          }
        });
    }, error => {
      this.router.navigate(['**']);
    });
    this.getAllTimelines();
  }

  sdgMapping(sdgList: any){
    this.initiativeSdg = sdgList.map(item => {
      const container = {};
      for (let sdg of this.sdgs){
        if (item === sdg.id){
          container['id'] = item;
          container['icon'] = sdg.icon;
          break;
        }
      }
      return container
    })
  }

  reportReason() {
    this.reportUser = !this.reportUser;
    this.reportUserChecked = !this.reportUserChecked;
  }

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  supportsConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ]
  };

  teamsConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "infinite": true,
    "arrow": true,
    "autoplay": false,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  goalsModal(goalsTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(goalsTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.timelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'custom-modal mobile-position modal-dialog modal-dialog-centered new-modal-ui newfeeddetail' })
    );
  }

  settingModal(settingTemplate: TemplateRef<any>) {
    this.submitted = false;
    this.reportUser = false;
    this.reportUserChecked = false;
    this.blockReportForm.reset();
    this.modalRef = this.modalService.show(settingTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  blockUnblockUser(isValid: boolean) {
    if (this.reportUserChecked) {
      var description;
      if (!this.blockReportForm.valid) {
        this.submitted = true;
        return
      }
      if (this.blockReportForm.value['report_description']) {
        description = this.blockReportForm.value['report_description']
      } else {
        description = '';
      }
      var report_data = {
        'reported_initiative': this.initiativeId, 'reason': this.blockReportForm.value['report_reason'],
        'description': description
      }
      this.initiativeService.reportInitiative(report_data).subscribe(respones => {});
    }
    this.modalRef.hide();
  }

  copyData(data: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyAndShare(){
    var siteUrl: any = document.URL.split('/')
    siteUrl = siteUrl.splice(0, 3).join("/") + "/initiatives/" + this.initiativeId + "/";
    this.copyData(siteUrl);
    this.copyBool = true;
    setTimeout(() => {this.copyBool = false;}, 3000);
  }

  messageAlertModal(messageAlertTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(messageAlertTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  getTeamItems(){
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getTeam();
    if (this.volunteersList.length == 0) {
      this.getVolunteers();
    }
  }

  getVolunteers(){
    this.initiativeService.getVolunteers(this.initiativeId)
    .subscribe(response => {
      this.volunteersList = response.response;
    });
  }

  getTeam(){
    let params = '?';
    if (this.teamMemberNextPage > 1){
      params = params + '&page=' + this.teamMemberNextPage;
    }
    if (this.searchQuery && this.searchQuery.length > 2) {
      params = params + '&search=' + this.searchQuery;
    }
    this.initiativeService.getTeam(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.teamMemberNextPage == 1 || this.teamMemberNextPage === null) {
          this.teamMembers = response.response.results;
        } else {
          this.teamMembers = this.teamMembers.concat(response.response.results);
        }
        this.teamMemberNextPage = response.response.next;
        this.totalTeamMembers = response.response.total_count;
      } else {
        this.teamMembers = [];
        this.teamMemberNextPage = 1;
        this.totalTeamMembers = 0;
      }
    });
  }

  getTimelines(){
    if (this.timelineActive == 4) {
      this.getAllTimelines();
    } else {
      this.getEventVolunteers();
    }
  }

  getAllTimelines(){
    let params = '?';
    if (this.timelineNextPage > 1){
      params = params + '&page=' + this.timelineNextPage;
    }
    this.initiativeService.getAllIniTimeline(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }

  getEventVolunteers(){
    let params = '?';
    if (this.timelineActive && this.timelineActive > 4) {
      params = params + 'post_type=' + this.timelineActive;
    }
    if (this.timelineNextPage > 1){
      params = params + '&page=' + this.timelineNextPage;
    }
    this.initiativeService.getIniVolunteer(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }



  loadMore(){
    let params = '?page=' + this.timelineNextPage;
    this.initiativeService.getAllIniTimeline(this.initiativeId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      }
    });
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  copyPageID(){
    let data = "INI" + this.initiativeId;
    this.copyData(data);
    this.copyPageIDBool = true;
    setTimeout(() => {this.copyPageIDBool = false;}, 3000);
  }

  showImg(headerImages: TemplateRef<any>) {
    if (this.header_image) {
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  followInitiative(){
    this.followBtnErr = false;
    if (this.initiative.is_followed) {
      this.pageService.unFollowInitiative(this.initiative.id, {})
      .subscribe(response => {
        this.initiative.is_followed = false;
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    } else {
      this.pageService.followInitiative(this.initiative.id, {})
      .subscribe(response => {
        if (response.status === 200) {
          this.initiative.is_followed = true;
        } else {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        }
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    }
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 6000);
  }

  openModalOthers(template: TemplateRef<any>, timeline: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.timelineObj = timeline;
    this.getInterestedUsers();
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    let page_type = 4;
    if (this.timelineObj.obj_type === 2) {
      page_type = 5;
    } else {
      page_type = 4;
    }
    this.pageService.interestedUsers(this.timelineObj.id, page_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  markInterested(timeline: any, post_type: any){
    if (timeline.interested_data.interested) {
      this.pageService.unMarkInterested(timeline.id, post_type)
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => (obj.id === timeline.id && obj.post_type === timeline.post_type));
      this.timelines[index].interested_data.interested = false;
    } else {
      this.pageService.markInterested(timeline.id, post_type, {})
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => (obj.id === timeline.id && obj.post_type === timeline.post_type));
      this.timelines[index].interested_data.interested = true;
    }
  }

  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };

  directToProfile(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      this.router.navigate(['profile/' + member.user + '/']);
    }
  }

  checkClick(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      return true;
    }
    return false;
  }

  openModal(member: any, template: TemplateRef<any>) {
    this.selectedUser = member;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.modalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal new-modal-ui' })
    );
  }

  copyMessage(){
    let msg = "Join my network at " + this.initiativeTitle + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.copyData(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false;}, 3000);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'initiative': this.initiativeId
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  searchStudent(event: any){
    let name = event.target.value;
    if (name.length === 0) {
      this.resetSearch();
    } else if (name.length > 2) {
      this.searchQuery = name;
      this.teamMemberNextPage = 1;
      this.getTeam();
    } else {
      this.searchQuery = null;
    }
  }

  resetSearch(){
    if (this.searchInp){
      this.searchInp.nativeElement.value = "";
    }
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getTeam();
  }
}
