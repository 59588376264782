<div class="profileSetting" [ngClass]="{'custom-tab-dark': user.user_role != '2'}">
    <div class="profileWhiteWrap pb-3 pt-0">
    <p class="settingText">Profile Settings</p>
    <div class="content-section pt-3 pb-2 fullPage showPadding">
        <div class="profile-div">
            <div class="profile-inner full-inner ">
                <h3>General</h3>
                <div class="box-div customBox d-flex justify-content-between">
                    <div class="inner-div">
                        <h2>Profile owner details</h2>
                        <div class="inner-box mr-4">
                            <h4>Name: {{user.first_name}} {{user.last_name}}</h4>
                            <p *ngIf="user.user_role == '2'" class="d-flex align-items-center mb-2">Birthday: <img class="ml-2" src="assets/images/birthday_icon.svg"> {{user.date_of_birth | date: 'yyyy-MM-dd'}} </p>
                            <p>Email: {{user.email}}</p>
                        </div>
                        <button class="common-button" (click)="emailModal(changeEmail)">Change
                            Email</button>
                    </div>
                    <div class="inner-div">
                        <p *ngIf="showMsg" class="alert alert-success">Password has been changed
                            successfully.</p>
                        <h2>change password</h2>
                        <button class="common-button" (click)="passwordModal(changePassword)">Change
                            Password</button>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner" *ngIf="!isDisabled">
                <h3>Create Resume</h3>
                <div class="box-div">
                    <div class="inner-div">
                        <h2>Create Resume</h2>
                        <button class="common-button" (click)="UniqueProfileModal(uniqueProfile)">
                            Click here</button>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner " *ngIf="user.user_role == '2' && !isMinor">
                <h3>Profile Verifiers <span class="float-right" *ngIf="approvedVerifier >= 3">You
                        have 3/3</span><span class="float-right" *ngIf="approvedVerifier < 3">You
                        have {{approvedVerifier}}/3</span></h3>
                <div class="box-div">
                    <h4 class="infoText">You must verify your identity by sending an email that
                        includes your Full Name, Age and Relationship (i.e. “I am their _____”), to
                        3 professional email accounts. <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv1 = !infoDiv1"
                        class="cursor-pointer">
                    </h4>
                    <div class="info-overlay2" [ngClass]="{ '': infoDiv1, show: !infoDiv1}">
                        <div class="info-div">
                            <p>The emails added in this section will be checked against a list of free domains – this means the emails must be sent to people who have professional domains. Examples of people you might trust and who have professional emails include Teachers (first.last@yrdsb.ca), Parents/Guardians (first.last@organization.ca), Relatives, Doctors, Neighbours… We suggest you explain to them why they might receive this email beforehand, so that they better understand what you are doing and can quickly verify you! Once this process is complete, you will have access to all of the features and will receive a blue “Verified” checkmark beside your name. Click on the checkmark while viewing any student profile to see the domains that others have used!”</p>
                        </div>
                    </div>
                    <div class="inner-div">
                        <button class="common-button"
                            (click)="passwordModal(addVerifier)">Add</button>
                    </div>
                    <div class="inner-div" *ngIf="verifiers?.length > 0">
                        <h2>Manage</h2>
                        <p *ngIf="showDelMsg" class="alert"
                            [ngClass]="{'alert-danger': !isVerifSuccess, 'alert-success': isVerifSuccess }">
                            {{delSubmitMsg}}</p>
                        <div class="d-flex flex-wrap">
                            <div class="ownerDiv" *ngFor="let verifier of verifiers">
                                <div class="inner-box mr-md-3">
                                    <h4 class="nameFont">{{verifier.first_name}}
                                        {{verifier.last_name}}</h4>
                                    <p>{{verifier.email}}</p>
                                    <h4>I am their <span>{{verifier.relation}}</span></h4>
                                    <p *ngIf="verifier.status == 1" class="text-uppercase">Pending
                                    </p>
                                    <p *ngIf="verifier.status == 2" class="text-uppercase">Approved
                                    </p>
                                </div>
                                <button class="common-button ghost mr-2"
                                    (click)="deleteModal(deleteVerifier, verifier)"
                                    *ngIf="canDelete(verifier)">Delete</button>
                                <button class="common-button mr-3" (click)="resendEmail(verifier)"
                                    *ngIf="verifier.status == 1">Resend</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-inner full-inner" *ngIf="BlockUserListLength > 0">
                <h3>Blocked List <span class="float-right">{{BlockUserListLength}} Total</span></h3>
                <div class="box-div">
                    <div class="inner-div">
                        <div class="d-flex flex-wrap">
                            <div class="ownerDiv" *ngFor="let blocked_user of BlockUserList">
                                <div class="inner-box">
                                    <h4>{{blocked_user.first_name}} {{blocked_user.last_name}}</h4>
                                </div>
                                <button class="common-button ghost mr-2"
                                    [routerLink]="['/profile', blocked_user.id]">Go to
                                    Profile</button>
                                <button class="common-button "
                                    (click)="UnblockUser(blocked_user.id)">Unblock</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="user.user_role == '2' && user.age_group != '1'"
                class="profile-inner full-inner">
                <h3>Privacy </h3>
                <form [formGroup]="privaceSettingForm">
                    <div class="d-flex justify-content-between flex-wrap privacyDiv">
                        <div class="box-div">
                            <h4>Name Display</h4>
                            <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="fullname"
                                        formControlName="name_display" value="1">
                                    <label class="custom-control-label " for="fullname">Full Name
                                        (ex. Jane Doe)</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="abrname"
                                        formControlName="name_display" value="2">
                                    <label class="custom-control-label" for="abrname">Abbreviated
                                        Name (ex. Jane D.)</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="private"
                                        formControlName="name_display" value="3">
                                    <label class="custom-control-label" for="private">Private (ex.
                                        CU123456)</label>
                                </div>
                            </div>
                        </div>
                        <div class="box-div">
                            <h4>Image Display</h4>
                            <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="image"
                                        formControlName="image_display" value="true">
                                    <label class="custom-control-label " for="image">Display Picture
                                        & Header</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" id="image1"
                                        formControlName="image_display" value="false">
                                    <label class="custom-control-label" for="image1">None</label>
                                </div>
                            </div>
                        </div>
                        <div class="box-div">
                            <h4>Your Location</h4>
                            <label class="switch mb-0"><input type="checkbox"
                                    [attr.disabled]="isMinor === true ? 'disabled' : null"
                                    class="switch-input" formControlName="location_display"><span
                                    data-on="On" data-off="Off" class="switch-label"></span><span
                                    class="switch-handle"></span></label>
                        </div>
                    </div>
                    <div class="box-div">
                        <div class="form-group mb-0">
                            <button class="common-button" (click)="SavePrivacy()">Save
                                Changes</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="profile-inner full-inner mb-4"
                *ngIf="pageslist_owners?.length > 0 || pageslist_admins?.length > 0">
                <h3>Manage Pages</h3>
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="box-div">
                        <div class="inner-div" *ngIf="pageslist_owners?.length > 0">
                            <h2>Owner</h2>
                            <div class="profilediv" *ngFor="let pagee of pageslist_owners">
                                <div class="profileinner d-flex align-items-center">
                                    <img *ngIf="pagee.display_pic"
                                        src="{{pagee.display_pic}}">
                                    <img *ngIf="!pagee.display_pic" src="assets/images/p-img.png">
                                    <div class="contentDiv ml-3">
                                        <h4>{{pagee.page_info.title}}</h4>
                                        <p *ngIf="pagee.business">{{pagee.page_info.state}}, {{pagee.page_info.country}}</p>
                                        <p *ngIf="pagee.club_team && pagee.page_info.school_name">{{pagee.page_info.school_name}}</p>
                                    </div>
                                </div>
                                <div class="btn-div ">
                                    <button *ngIf="pagee.business"
                                        class="common-button mb-md-2 mr-2 mt-2"
                                        (click)="OpenPage(pagee.page_info.instance_id, 'business')">Go
                                        to Page</button>
                                    <button *ngIf="pagee.club_team"
                                        class="common-button mb-md-2 mr-2 mt-2"
                                        (click)="OpenPage(pagee.page_info.instance_id, 'club_team')">Go
                                        to Page</button>
                                    <button class="common-button ghost mt-2"
                                        (click)="OpenPageSettings(pagee)">Go
                                        to Settings</button>
                                </div>
                            </div>
                        </div>
                        <div class="inner-div" *ngIf="pageslist_admins?.length > 0">
                            <h2>Admin</h2>
                            <div class="profilediv" *ngFor="let page_adm of pageslist_admins">
                                <div class="profileinner d-flex align-items-center">
                                    <img *ngIf="page_adm.display_pic"
                                        src="{{page_adm.display_pic}}">
                                    <img *ngIf="!page_adm.display_pic"
                                        src="assets/images/p-img.png">
                                    <div class="contentDiv ml-3">
                                        <h4>{{page_adm.page_info.title}}</h4>
                                        <p *ngIf="page_adm.business">{{page_adm.page_info.state}}, {{page_adm.page_info.country}}</p>
                                        <p *ngIf="page_adm.club_team && page_adm.page_info.school_name">{{page_adm.page_info.school_name}}</p>
                                    </div>
                                </div>
                                <div class="btn-div">
                                    <button *ngIf="page_adm.business"
                                        class="common-button  mt-3 mr-2"
                                        (click)="OpenPage(page_adm.page_info.instance_id, 'business')">Go
                                        to Page</button>
                                    <button *ngIf="page_adm.club_team"
                                        class="common-button mt-3 mr-2"
                                        (click)="OpenPage(page_adm.page_info.instance_id, 'club_team')">Go
                                        to Page</button>
                                    <button class="common-button ghost"
                                        (click)="OpenPageSettings(page_adm)">Go
                                        to Settings</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-div" *ngIf="isSenior">
                        <div class="inner-div text-center">
                            <h2>Add Pages</h2>
                            <button class="common-button" [routerLink]="['/create-page']"
                                autoscroll="true">Click Here</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<ng-template #changeEmail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Change Email</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">

            <form *ngIf="showPassForm" [formGroup]="passwordForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Password
                            <input type="password" class="form-control" placeholder="Type Here"
                                formControlName="password">
                            <div class="error-message"
                                *ngIf="passwordForm.get('password').hasError('required') && (passwordForm.get('password').dirty || passwordForm.get('password').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="passwordForm.get('password').hasError('invalidPassword') && (passwordForm.get('password').dirty || passwordForm.get('password').touched)">
                                Your password needs to be at least 8 characters, including a Capital Letter, Lowercase
                                Letter and a Number.
                            </div>
                            <div class="error-message" *ngIf="passwordForm.get('password').hasError('aftersubmit')">
                                {{passwordForm.controls.password.errors.aftersubmit}}
                            </div>
                        </label>
                    </div>
                </div>
            </form>

            <form *ngIf="showEmailForm" [formGroup]="newEmailForm" novalidate>
                <div class="form-group">
                    <label>Enter New Email</label>
                    <input type="email" class="form-control" placeholder="New Email" formControlName="email">
                    <div class="error-message"
                        *ngIf="newEmailForm.get('email').hasError('required') && (newEmailForm.get('email').dirty || newEmailForm.get('email').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="newEmailForm.get('email').hasError('maxlength') && (newEmailForm.get('email').dirty || newEmailForm.get('email').touched)">
                        Max 100 characters allowed.
                    </div>
                    <div class="error-message"
                        *ngIf="newEmailForm.get('email').hasError('pattern') && (newEmailForm.get('email').dirty || newEmailForm.get('email').touched)">
                        Please enter your email in a valid format.
                    </div>
                    <div class="error-message" *ngIf="newEmailForm.get('email').hasError('aftersubmit')">
                        {{newEmailForm.controls.email.errors.aftersubmit}}
                    </div>
                </div>
                <div class="form-group">
                    <label>Repeat New Email</label>
                    <input type="email" class="form-control" placeholder="Repeat New Email"
                        formControlName="confirm_email">
                    <div class="error-message"
                        *ngIf="newEmailForm.get('confirm_email').hasError('required') && (newEmailForm.get('confirm_email').dirty || newEmailForm.get('confirm_email').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="error-message"
                        *ngIf="newEmailForm.get('confirm_email').hasError('maxlength') && (newEmailForm.get('confirm_email').dirty || newEmailForm.get('confirm_email').touched)">
                        Max 100 characters allowed.
                    </div>
                    <div class="error-message"
                        *ngIf="newEmailForm.get('confirm_email').hasError('pattern') && (newEmailForm.get('confirm_email').dirty || newEmailForm.get('confirm_email').touched)">
                        Please enter your email in a valid format.
                    </div>
                    <div class="error-message"
                        *ngIf="newEmailForm.hasError('emailNotMatch') && (newEmailForm.get('confirm_email').dirty || newEmailForm.get('confirm_email').touched)">
                        Emails do not match.
                    </div>
                </div>
            </form>

            <form *ngIf="showOTPForm" [formGroup]="otpForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <p>An OTP is sent on your email {{changeReqData.new_email}}.<br>
                            Please enter the OTP below to verify your Email Address. On the successful submission of the
                            OTP, your new email address will be verified and you will be logged out automatically.
                            <br> Please use the combination for new email address and your password to login again.</p>
                    </div>
                    <div class="col-md-6">
                        <label>OTP
                            <input type="text" class="form-control" placeholder="Type Here" formControlName="otp">
                            <div class="error-message"
                                *ngIf="otpForm.get('otp').hasError('required') && (otpForm.get('otp').dirty || otpForm.get('otp').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="otpForm.get('otp').hasError('maxlength') && (otpForm.get('otp').dirty || otpForm.get('otp').touched)">
                                Max 8 characters allowed.
                            </div>
                            <div class="error-message" *ngIf="otpForm.get('otp').hasError('aftersubmit')">
                                {{otpForm.controls.otp.errors.aftersubmit}}
                            </div>
                        </label>
                    </div>
                </div>
            </form>

            <p *ngIf="isSuccess">Email changed successfully. You will be logged out from app now. Please use your new
                email to login again.</p>

        </div>
    </div>
    <div class="modal-footer">

        <button class="btn btn-footer" *ngIf="showPassForm" (click)="verifyPassword(passwordForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="showEmailForm" (click)="addNewEmail(newEmailForm.valid)">
            Submit
        </button>
        <button class="btn btn-footer" *ngIf="showOTPForm" (click)="verifyOTP(otpForm.valid)">
            Done
        </button>
    </div>
</ng-template>

<ng-template #changePassword>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Change Password</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="passwordChangeForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Current Password</label>
                    </div>
                    <div class="col-md-6">
                        <input type="password" class="form-control mb-2" placeholder="Enter Current Password"
                            formControlName="password">
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('password').hasError('required') && (submitted || passwordChangeForm.get('password').dirty || passwordChangeForm.get('password').touched)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('password').hasError('invalidPassword') && (passwordChangeForm.get('password').dirty || passwordChangeForm.get('password').touched)">
                            Your password needs to be at least 8 characters, including a Capital Letter, Lowercase
                            Letter and a Number.
                        </div>
                        <div class="error-message" *ngIf="passwordChangeForm.get('password').hasError('aftersubmit')">
                            {{passwordChangeForm.controls.password.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>New Password</label>
                    </div>
                    <div class="col-md-6">
                        <input type="password" class="form-control mb-2" placeholder="New Password"
                            formControlName="new_password">
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('new_password').hasError('required') && (submitted || passwordChangeForm.get('new_password').dirty || passwordChangeForm.get('new_password').touched)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('new_password').hasError('maxlength') && (passwordChangeForm.get('new_password').dirty || passwordChangeForm.get('new_password').touched)">
                            Max 15 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('new_password').hasError('invalidPassword') && (passwordChangeForm.get('new_password').dirty || passwordChangeForm.get('new_password').touched)">
                            Your password needs to be at least 8 characters, including a Capital Letter, Lowercase
                            Letter and a Number.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('new_password').hasError('aftersubmit')">
                            {{passwordChangeForm.controls.new_password.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Repeat New Password</label>
                    </div>
                    <div class="col-md-6">
                        <input type="password" class="form-control mb-2" placeholder="Repeat New Password"
                            formControlName="confirm_new_password">
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('confirm_new_password').hasError('required') && (submitted || passwordChangeForm.get('confirm_new_password').dirty || passwordChangeForm.get('confirm_new_password').touched)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('confirm_new_password').hasError('minlength') && (passwordChangeForm.get('confirm_new_password').dirty || passwordChangeForm.get('confirm_new_password').touched)">
                            Your password needs to be at least 8 characters, including a Capital Letter, Lowercase
                            Letter and a Number.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.hasError('passwordNotMatch') && (passwordChangeForm.get('confirm_new_password').dirty || passwordChangeForm.get('confirm_new_password').touched)">
                            Passwords do not match.
                        </div>
                        <div class="error-message"
                            *ngIf="passwordChangeForm.get('confirm_new_password').hasError('aftersubmit')">
                            {{passwordChangeForm.controls.confirm_new_password.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updatePassword(passwordChangeForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #addVerifier>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Verifier</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="verifierForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>First Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter First Name"
                            formControlName="first_name">
                        <div class="error-message"
                            *ngIf="verifierForm.get('first_name').hasError('required') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('first_name').hasError('maxlength') && (verifierForm.get('first_name').dirty || verifierForm.get('first_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="verifierForm.get('first_name').hasError('aftersubmit')">
                            {{verifierForm.controls.first_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Last Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" placeholder="Enter Last Name"
                            formControlName="last_name">
                        <div class="error-message"
                            *ngIf="verifierForm.get('last_name').hasError('required') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('last_name').hasError('maxlength') && (verifierForm.get('last_name').dirty || verifierForm.get('last_name').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="verifierForm.get('last_name').hasError('aftersubmit')">
                            {{verifierForm.controls.last_name.errors.aftersubmit}}
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Professional Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('required') && (verifierForm.get('email').dirty || verifierForm.get('email').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('maxlength') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
                            Max 100 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('email').hasError('pattern') && (verifierForm.get('email').dirty || verifierForm.get('email').touched)">
                            Please enter your email in a valid format.
                        </div>
                        <div class="error-message" *ngIf="verifierForm.get('email').hasError('aftersubmit')">
                            {{verifierForm.controls.email.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>I am their: </label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Daughter, Neighbour, Student"
                            formControlName="relation">
                        <div class="error-message"
                            *ngIf="verifierForm.get('relation').hasError('required') && (verifierForm.get('relation').dirty || verifierForm.get('relation').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="verifierForm.get('relation').hasError('maxlength') && (verifierForm.get('relation').dirty || verifierForm.get('relation').touched)">
                            Max 50 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="verifierForm.get('relation').hasError('aftersubmit')">
                            {{verifierForm.controls.relation.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="createVerifier(verifierForm.valid)">
            Submit
        </button>
    </div>
</ng-template>

<ng-template #deleteVerifier>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Verifier</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>Are you sure you want to delete verifier ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delVerifier()">
            Yes
        </button>
        <button class="btn btn-footer" (click)="modalRef.hide()">
            No
        </button>
    </div>
</ng-template>

<ng-template #uniqueProfile>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Resume</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="disclaimer-box mb-3">
                <p><strong>Important: </strong>Once you create a Resume, you will not be able to delete it without
                    deleting your whole account. They are linked. You will automatically have your own searchable
                    identity.
                </p>
            </div>
            <h4 class="">Personal Details</h4>
            <form [formGroup]="profileForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>First Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" formControlName="first_name" placeholder="First Name">
                        <div class="error-message"
                            *ngIf="profileForm.get('first_name').hasError('required') && (profileForm.get('first_name').dirty || searchSubmitted)">
                            This field is required.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Last Name</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-capitalize" formControlName="last_name" placeholder="Last Name">
                        <div class="error-message"
                            *ngIf="profileForm.get('last_name').hasError('required') && (profileForm.get('last_name').dirty || searchSubmitted)">
                            This field is required.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Date Of Birth</label>
                    </div>
                    <div class="col-md-6 col-8">
                        <input type="text" class="form-control" bsDatepicker [minDate]="minDate" [maxDate]="maxDate"
                            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                            formControlName="date_of_birth" style="background-color: white;" readonly>
                        <div class="error-message"
                            *ngIf="profileForm.get('date_of_birth').hasError('required') && (profileForm.get('date_of_birth').dirty || searchSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="profileForm.get('date_of_birth').hasError('aftersubmit')">
                            {{profileForm.controls.date_of_birth.errors.aftersubmit}}
                        </div>
                    </div>
                    <div class="col-2">
                        <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv" class="cursor-pointer">
                        <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                            <div class="info-div">
                                <p>**You may change your date and month of the year if you feel like updating it.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label>Email</label>
                    </div>
                    <div class="col-md-6">
                        <input type="email" class="form-control" formControlName="email" placeholder="Email">
                        <div class="error-message"
                            *ngIf="profileForm.get('email').hasError('required') && (profileForm.get('email').dirty || searchSubmitted)">
                            This field is required.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="save(profileForm.valid)">
            Submit
        </button>
    </div>
</ng-template>