import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PageService } from '../services/page.service';

@Component({
  selector: 'app-claim-page',
  templateUrl: './claim-page.component.html',
  styleUrls: ['./claim-page.component.scss']
})
export class ClaimPageComponent implements OnInit {
  modalRef: BsModalRef;
  public isValid: boolean = false;
  public isResponse: boolean = false;
  public isVerified: boolean = false;
  public hasAccount: boolean = true;
  public isLoggedIn: boolean = false;
  public successOverlay: boolean = false;
  public isMinor: boolean = false;
  message = "Please wait.....";
  page: any = {};
  verifier: any = {};
  currentUser: any = {};

  constructor(private router: Router, private modalService: BsModalService, private route: ActivatedRoute,
    private pageService: PageService) {}

  ngOnInit(): void {
    let url = this.router.url;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    if (this.currentUser && this.currentUser.age_group === 1) {
      this.isMinor = true;
    } else {
      this.isMinor = false;
    }
    if (this.route.snapshot.queryParams['id'] && this.route.snapshot.queryParams['verifier'] && !this.isMinor) {
      this.pageService.verifyClaim(url)
      .subscribe(response => {
        this.isResponse = true;
        if (response.status === 111) {
            this.isValid = false;
        } else if (response.status === 200) {
          if (response.response['validity'] == "valid_token") {
              this.isValid = true;
              this.hasAccount = response.response['has_account'];
              this.isLoggedIn = response.response['is_loggedIn'];
              this.page = response.response['page'];
              this.verifier = response.response['verifier'];
              if ((this.hasAccount && this.isLoggedIn) || (!this.hasAccount && !this.isLoggedIn)) {
                this.isVerified = true;
              } else {
                this.isVerified = false;
              }
          } else {
             this.isValid = false;
          }
        } else {
          this.isResponse = true;
          this.isValid = false;
          this.isVerified = false;
        }
      }, error => {
          this.isResponse = true;
          this.isValid = false;
          this.isVerified = false;
      });
    } else {
      this.isResponse = true;
      this.isValid = false;
      this.isVerified = false;
    }
  }

  setSuccessOverlay(e){ 
    this.successOverlay = e;
  }
  disableOverlay(){
    this.successOverlay = false;
    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
