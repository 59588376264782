import { Component, OnInit, TemplateRef, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../../services/profile.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { Router } from '@angular/router';
import { BaseProfileEndPoint } from '../../app.config';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  modalRef: BsModalRef;
  summaryForm: FormGroup;
  contactForm: FormGroup;
  referenceForm: FormGroup;
  user: any = {};
  resumeSummary: any = {};
  expObj: any = {};
  eduList: any[] = [];
  usrWorkExperience: any[] = [];
  usrVolunteerExperience = [];
  activities = [];
  skills = [];
  interests = [];
  schoolInvolvements = [];
  selectedExperiences = [];
  resumeExperiences = [];
  resumeSkillinterests = [];
  resumeReferences = [];
  selectedSkillinterests = [];
  references = [];
  selectedReferences = [];
  public submitted: boolean = false;
  public submitError: boolean = false;
  qrCodeData: string = "ConnectUs.today";
  copyMessageBool: boolean = false;

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private customValidator: CustomvalidationService, 
    private profileService: ProfileService, private http: HttpClient) {
    this.referenceForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      phone_number : new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(17), Validators.pattern(this.customValidator.numberRegex)]),
      email : new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)])
    });
    this.contactForm = this.formBuilder.group({
      phone_number : new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(17), Validators.pattern(this.customValidator.numberRegex)]),
      email : new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)])
    });
    this.summaryForm = this.formBuilder.group({
      summary_statement: new FormControl('', [Validators.required, Validators.maxLength(250)])
    });
    var url: any = document.URL.split('/profile');
    url.splice(-1, 1);
    this.qrCodeData = url.join("/") + "/public-resume/";
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.getSummary();
    this.getResumeExp();
    this.getResumeSkillInterest();
    this.getAllExp();
    this.getAllSkillInterests();
    this.getAllResumeReference();
  }

  getSummary(){
    this.profileService.getResumeSummary()
    .subscribe(response => {
      if(response.response.summary) {
        this.resumeSummary = response.response.summary;
        this.qrCodeData = this.qrCodeData + response.response.encrypted_id;
      }
      if(response.response.education.length > 0) {
        this.eduList = response.response.education;
      } else {
        this.eduList = [];
      }
    });
  }

  getResumeExp(){
    this.profileService.getResumeExperiences()
    .subscribe(response => {
      if(response.response.experiences.length > 0){
          this.resumeExperiences = response.response.experiences;
        } else {
          this.resumeExperiences = [];
        }
      });
  }

  getAllResumeReference(){
    this.profileService.getAllResumeReference()
    .subscribe(response => {
      if(response.response.length > 0){
          this.references = response.response;
          this.resumeReferences = this.references.filter(obj => obj.resume_selected === true);
        } else {
          this.references = [];
          this.resumeReferences = [];
        }
      });
  }

  getResumeSkillInterest(){
    this.profileService.getResumeSkillInterests()
    .subscribe(response => {
      if(response.response.skill_interest.length > 0){
          this.resumeSkillinterests = response.response.skill_interest;
        } else {
          this.resumeSkillinterests = [];
        }
      });
  }

  getAllExp(){
    this.profileService.getAllExperiences()
    .subscribe(response => {
      this.usrWorkExperience = response.response.work_experiences;
      this.usrVolunteerExperience = response.response.volunteer_experiences;
      this.activities = response.response.activity_experiences;
      this.schoolInvolvements = response.response.school_experiences;
    });
  }

  getAllSkillInterests(){
    this.profileService.getAllSkillinterests()
    .subscribe(response => {
      this.skills = response.response.skills;
      this.interests = response.response.interests;
    });
  }

  summaryList(summaryModal: TemplateRef<any>) {
    this.submitted = false;
    this.summaryForm.reset();
    this.summaryForm.get("summary_statement").setValue(this.resumeSummary.summary_statement);
    this.modalRef = this.modalService.show(summaryModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  contactInfoList(contactInfoModal: TemplateRef<any>) {
    this.submitted = false;
    this.contactForm.reset();
    this.contactForm.get('email').setValue(this.resumeSummary.email);
    if (this.resumeSummary.phone_number) {
      this.contactForm.get('phone_number').setValue(this.resumeSummary.phone_number);
    }
    this.modalRef = this.modalService.show(contactInfoModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  expList(expModal: TemplateRef<any>) {
    this.submitError = false;
    this.selectedExperiences = [];
    this.setSelectedExp();
    this.modalRef = this.modalService.show(expModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  refList(refModal: TemplateRef<any>) {
    this.submitError = false;
    this.submitted = false;
    this.referenceForm.reset();
    this.selectedReferences = [];
    this.setSelectedReferences();
    this.modalRef = this.modalService.show(refModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  skillList(skillModal: TemplateRef<any>) {
    this.submitError = false;
    this.selectedSkillinterests = [];
    this.setSelectedSkillInt();
    this.modalRef = this.modalService.show(skillModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  setSelectedExp(){
    if (this.resumeExperiences.length > 0) {
      let work_exp_list = this.usrWorkExperience.filter(obj => obj.resume_selected === true);
      let volun_exp_list = this.usrVolunteerExperience.filter(obj => obj.resume_selected === true);
      let act_exp_list = this.activities.filter(obj => obj.resume_selected === true);
      let school_exp_list = this.schoolInvolvements.filter(obj => obj.resume_selected === true);
      work_exp_list = work_exp_list.concat(volun_exp_list);
      for (let work_exp of work_exp_list) {
        this.selectExperience("work", work_exp.id);
      }
      for (let act_exp of act_exp_list) {
        this.selectExperience("activity", act_exp.id);
      }
      for (let school_exp of school_exp_list) {
        this.selectExperience("school", school_exp.id);
      }
    }
  }

  setSelectedSkillInt(){
    if (this.resumeSkillinterests.length > 0) {
      let skill_list = this.skills.filter(obj => obj.resume_selected === true);
      let interest_list = this.interests.filter(obj => obj.resume_selected === true);
      for (let skill of skill_list) {
        this.selectSkillInt("skill", skill.id);
      }
      for (let interest of interest_list) {
        this.selectSkillInt("interest", interest.id);
      }
    }
  }

  setSelectedReferences(){
    if (this.resumeReferences.length > 0) {
      let ref_list = this.references.filter(obj => obj.resume_selected === true);
      for (let ref of ref_list) {
        this.selectRef(ref);
      }
    }
  }

  updateContactDetails(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let model = {"phone_number": this.contactForm.get("phone_number").value,
                  "email": this.contactForm.get("email").value};
      this.profileService.updateResumeSummary(model, this.resumeSummary.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          const fields = Object.keys(response.message || {});
        } else if (response.status === 200) {
          this.resumeSummary = response.response;
          this.submitted = false;
          this.contactForm.reset();
          this.modalRef.hide();
        }
      });
    }
  }

  updateSummary(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let model = {"summary_statement": this.summaryForm.get("summary_statement").value};
      this.profileService.updateResumeSummary(model, this.resumeSummary.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          const fields = Object.keys(response.message || {});
        } else if (response.status === 200) {
          this.resumeSummary = response.response;
          this.submitted = false;
          this.contactForm.reset();
          this.summaryForm.reset();
          this.modalRef.hide();
        }
      });
    }
  }

  updateEdu(obj: any) {
    let selected_education = this.resumeSummary.selected_education;
    if (selected_education.indexOf(obj.id) > -1) {
      let index = selected_education.indexOf(obj.id);
      selected_education.splice(index, 1);
    } else {
      selected_education.push(obj.id);
    }
    if (selected_education.length < 3) {
      let model = {"selected_education": selected_education};
      this.profileService.updateResumeSummary(model, this.resumeSummary.id)
      .subscribe((response:any) => {
        if (response.status === 200) {
          this.resumeSummary = response.response;
        }
      });
    }
  }

  selectExperience(exp_type: any, id: string){
    this.submitError = false;
    let obj = {"type": exp_type, "id": id};
    let index = this.selectedExperiences.findIndex(obj => (obj.type === exp_type) && (obj.id === id));
    if (index === -1){
      this.selectedExperiences.push(obj);
    } else {
      this.selectedExperiences.splice(index, 1);
    }
  }

  selectSkillInt(exp_type: any, id: string){
    this.submitError = false;
    let obj = {"type": exp_type, "id": id};
    let index = this.selectedSkillinterests.findIndex(obj => (obj.type === exp_type) && (obj.id === id));
    if (index === -1){
      this.selectedSkillinterests.push(obj);
    } else {
      this.selectedSkillinterests.splice(index, 1);
    }
  }

  selectRef(ref: any){
    let index = this.selectedReferences.findIndex(obj => obj === ref.id);
    if (index === -1){
      this.selectedReferences.push(ref.id);
    } else {
      this.selectedReferences.splice(index, 1);
    }
  }

  addExpResume(){
    this.submitError = false;
    if (this.selectedExperiences.length <= 5){
      let model = {
        "experiences": this.selectedExperiences
      }
      this.profileService.addResumeExp(model)
      .subscribe(response => {
        if (response.status === 200){
          this.modalRef.hide();
          this.resumeExperiences = response.response.experiences;
          this.getAllExp();
          this.selectedExperiences = [];
        } else {
          this.submitError = true;
        }
      }, error => {
        this.submitError = true;
      })
    }
  }

  addSkillIntResume(){
    this.submitError = false;
    if (this.selectedSkillinterests.length <= 10){
      let model = {
        "skill_interest": this.selectedSkillinterests
      }
      this.profileService.addResumeSkillInterest(model)
      .subscribe(response => {
        if (response.status === 200){
          this.modalRef.hide();
          this.resumeSkillinterests = response.response.skill_interest;
          this.getAllSkillInterests();
          this.selectedSkillinterests = [];
        } else {
          this.submitError = true;
        }
      }, error => {
        this.submitError = true;
      })
    }
  }

  addReference(isValid: boolean) {
    this.submitted = true;
    if (isValid && this.references.length <= 10){
      let model = {"first_name": this.referenceForm.get("firstName").value,
                  "last_name": this.referenceForm.get("lastName").value,
                  "position": this.referenceForm.get("position").value,
                  "email": this.referenceForm.get("email").value,
                  "phone_number": this.referenceForm.get("phone_number").value};
      this.profileService.addResumeReference(model)
      .subscribe((response:any) => {
        if (response.status === 111) {
          if (typeof response.message === 'string') {
            this.referenceForm.get('email').setErrors({ aftersubmit: response.message });
          } else {
            const fields = Object.keys(response.message || {});
            fields.forEach(field => {
              if (this.referenceForm.contains(field)) {
                this.referenceForm.get(field).setErrors({ aftersubmit: response.message[field][0] });
              }
            });
          }
        } else if (response.status === 200) {
          this.references.push(response.response);
          this.submitted = false;
          this.referenceForm.reset();
        }
      });
    }
  }

  addResumeReference(){
    this.submitError = false;
    if (this.selectedReferences.length <= 3){
      let model = {
        "references": this.selectedReferences
      }
      this.profileService.selectResumeReference(model)
      .subscribe(response => {
        if (response.status === 200){
          this.modalRef.hide();
          this.getAllResumeReference();
          this.selectedReferences = [];
        } else {
          this.submitError = true;
        }
      }, error => {
        this.submitError = true;
      })
    }
  }

  delResumeRef(reference: any){
    this.profileService.deleteResumeReference(reference.id)
    .subscribe(response => {
      let index = this.references.findIndex(obj => obj.id == reference.id);
      this.references.splice(index, 1);
    })
  }

  downloadPDF(){
    let url = BaseProfileEndPoint.apiEndpoint + 'pdf-resume-download/';
    this.http.get(url, {responseType: 'blob'})
    .subscribe(response => {
      let blob = new Blob([response], {type: 'application/pdf'});
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      let name = this.user.first_name + "_Resume.pdf";
      link.download = name;
      link.click();
    })
  }

  copyLink(inputElement: any){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  saveAsImage(parent) {
    const parentElement = parent.qrcElement.nativeElement.children[0].toDataURL()
    let blobData = this.convertBase64ToBlob(parentElement);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    } else {
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

}
