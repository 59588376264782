import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InitiativeService } from '../../services/initiative.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-more-about-me',
  templateUrl: './more-about-me.component.html',
  styleUrls: ['./more-about-me.component.scss']
})
export class MoreAboutMeComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  public mamDescription = true;
  modalRef: BsModalRef;
  public Editor = ClassicEditor;
  mamId: string;
  user: any;
  location: any;
  title: any;
  start_date: any;
  end_date: any;
  display_image: any;
  description: any;
  submitted: boolean;
  disableSubmit: boolean;
  previewThumbnail: any;
  previewThumbnailName: any;
  role: any;
  header_image: any;
  linkArray = [];
  delError: boolean;
  isHeaderToDel: boolean;
  imageChangedEvent: any;
  croppedImage: any;
  fileUploadError: any;
  fileUploadSizeError: any;
  profileImgSubmit: any;
  fileUploadSizeMessage: string;
  fileUploadMessage: string;
  display_image_delete: boolean;
  summary: any;
  timelines: any = [];
  editTimelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;

  constructor(private datePipe: DatePipe, private modalService: BsModalService, private initiativeService: InitiativeService, 
    private profileeService: ProfileService, private router: Router, private windowLocation: Location) { 
    var url = document.URL.split('/')
    this.mamId = url[url.length - 1]
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }
    this.profileeService.getMoreAboutMeDetail(this.mamId)
    .subscribe(response => {
      let res = response.response;
      this.location = res.location;
      this.title = res.title;
      this.role = res.role;
      this.start_date = res.start_date;
      this.end_date = res.end_date;
      this.display_image = res.thumbnail;
      this.header_image = res.header_image;
      this.description = res.description;
      this.summary = res.summary;
      this.getLink();
    });
    this.initiativeService.recentMamTimeline(this.mamId)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        this.timelines = response.response.results;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];  
      }
    });
  }

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  getLink(){
    this.initiativeService.getLinksMam(this.mamId)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  addTimelineLinkModal(addTimelineLink: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addTimelineLink,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  returnToProfile(){
    this.windowLocation.back();
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  loadMore(){
    let params = '?page=' + this.timelineNextPage;
    this.initiativeService.mamTimeline(this.mamId, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      }
    });
  }

}
