import { Component, OnInit, TemplateRef, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { EventService } from '../services/event.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { 
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { formatDate } from '@angular/common';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/shared/event';
import { initialDatepickerState } from 'ngx-bootstrap/datepicker/reducer/bs-datepicker.state';

const colors: any = {
    calColor: {
      primary: '#339999 ',
      secondary: '#339999',
    }
};

@Component({
    selector:'event-cal',
    templateUrl: './event-calendar.component.html',
})
export class EventCalendarComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  public startWeek: number = 1;
  viewDate: Date = new Date();
  eventForm: FormGroup;
  eventSubmitted: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isDashboard: boolean = true;
  @Input() pageVerified: string;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  responseEvent: IEvent[] = [];
  addNew: boolean = true;

  editEvent: string = null;
  disableSubmit: boolean = false;

  activeDayIsOpen: boolean = false; 
  loggedIn: boolean = true;
  user: any = {};
  constructor(
    private modal: NgbModal, 
    private router: Router,
    private modalService: BsModalService,
    private eventService: EventService, public formBuilder: FormBuilder) {
      this.eventFormInit();
    }
  
  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    } else {
      this.router.navigate(['/']);
    }
    this.getEvents();
  }

  eventFormInit() {
    this.eventForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl(),
    }, { validators: this.startEndValidator });
    this.disableSubmit = false;
  }

  resetEndDate(){
    this.eventForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.eventForm.controls['start_date'].setValue(null);
  }

  startEndValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    return (start_date && end_date) && start_date > end_date ? {
       'startEndValid': true } : null;
  };

  getEvents(){
    let events: CalendarEvent[] = [];
    let month = (this.viewDate.getMonth() + 1).toString();
    let year = this.viewDate.getFullYear().toString();
    this.eventService.listEvents(month, year)
    .subscribe(response => {
      this.responseEvent = response.response;
      let today = new Date();
      this.responseEvent.forEach(day_event => {
        let start_date = day_event.start_date.split("-");
        let start_date_day = Number(start_date[2]);
        let start_date_month = Number(start_date[1]) - 1;
        let start_date_year = Number(start_date[0]);
        let end_date = day_event.start_date.split("-");
        let end_date_day = Number(end_date[2]);
        let end_date_month = Number(end_date[1]) - 1;
        let end_date_year = Number(end_date[0]);
        let start = new Date(start_date_year, start_date_month, start_date_day);
        let end = new Date(end_date_year, end_date_month, end_date_day);
        if (today.toDateString() == start.toDateString() || today.toDateString() == end.toDateString()){
          this.activeDayIsOpen = true;
        }
        events.push({
          'start': start, 
          'end': end,
          'title': day_event.title,
          'color': colors.calColor,
          'actions': this.actions,
          'meta': {
            'id': day_event.id
          }
        })
      })
      this.events = events;
      this.refresh.next();
    })
  }

  addEventSubmit(isValid: boolean) {
    this.eventSubmitted = true;
    if(isValid) {
      this.disableSubmit = true;
        let model = {
          'title': this.eventForm.get('title').value,
          'start_date': this.getDateString(this.eventForm.get('start_date').value),
          'end_date': this.getDateString(this.eventForm.get('end_date').value)
        }
        this.eventService.addEvent(model)
          .subscribe(response => {
            this.handleEventResponse(response);
        }, error => {
            this.handleEventError(error.error);
        })
    }
  }

  updateEventSubmit(isValid:boolean, isDelete:boolean) {
    this.eventSubmitted = true;
    if(isValid) {
      this.disableSubmit = true;
      let model = {
        'title': this.eventForm.get('title').value,
        'start_date': this.getDateString(this.eventForm.get('start_date').value),
        'end_date': this.getDateString(this.eventForm.get('end_date').value),
        'is_deleted': isDelete
      }
      this.eventService.updateEvent(model, this.editEvent)
        .subscribe(response => {
          this.handleEventResponse(response);
      }, error => {
          this.handleEventError(error.error);
      })
      this.closeOpenMonthViewDay()
    }
  }

  getDateString(_date: Date) {
    if(_date == null){
      return null;
    }
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

  protected handleEventResponse(response: any) {
    if (response.status === 111) {
      this.handleEventError(response.message);
    } else if (response.status === 200) {
        this.getEvents();
        this.modalRef.hide();
        this.eventForm.reset();
        this.eventSubmitted = false;
        this.disableSubmit = false;
    }
  }

  protected handleEventError(data: any) {
    this.eventSubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.eventForm.contains(field)) {
        this.eventForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }
  
  addEventPopup(eventPopup: TemplateRef<any>) {
    this.addNew = true;
    this.eventSubmitted = false;
    this.eventFormInit();
    this.modalRef = this.modalService.show(
      eventPopup, Object.assign({}, { 
        class: 'modal-dialog-centered custom-modal'}));
  }

  updateEventPopup(eventPopup: TemplateRef<any>, event: CalendarEvent) {
    this.eventService.RetreiveEvent(event.meta.id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            let eventEd = response.response;
            this.editEvent = eventEd.id;
            this.addNew = false;
            this.eventForm.controls['title'].setValue(eventEd.title);
            this.eventForm.controls['start_date'].setValue(new Date(eventEd.start_date));
            if(eventEd.end_date){
              this.eventForm.controls['end_date'].setValue(new Date(eventEd.end_date));
            }
            this.modalRef = this.modalService.show(
              eventPopup, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal'}));
          } else {
            this.getEvents();
          }
        });
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log('Event clicked');
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
