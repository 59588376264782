import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProfileService } from 'src/app/services/profile.service';
import { Router } from '@angular/router';
import { PageService } from 'src/app/services/page.service';
import { InitiativeService } from 'src/app/services/initiative.service';

@Component({
  selector: 'app-my-feeds',
  templateUrl: './my-feeds.component.html',
  styleUrls: ['./my-feeds.component.scss']
})
export class MyFeedsComponent implements OnInit {
  timelineUpdForm: FormGroup;
  feeds: any = [];
  interestedUsers:any = [];
  feedsNextPage: any = 1;
  intUsersNextPage: any = 1;
  totalFeedsCount: number = 0;
  totalUsersCount: number = 0;
  editFeedObj: any = {};
  modalRef: BsModalRef;
  otherModalRef: BsModalRef;
  reportModalRef: BsModalRef;
  submitted: Boolean = false;
  public Editor = ClassicEditor;
  descriptionLimit: number = 97;

  constructor(private profileService: ProfileService, private router: Router, private modalService: BsModalService,
    private pageService: PageService, public formBuilder: FormBuilder, private initiativeService: InitiativeService) {
      this.timelineUpdForm = this.formBuilder.group({
        description: new FormControl('', [Validators.required, Validators.maxLength(1000)])
      });
    }

  ngOnInit(): void {
    this.getFeeds();
  }

  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };

  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  getFeeds(){
    let params = '?page=' + this.feedsNextPage;
    this.profileService.getMyFeeds(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.feedsNextPage === 1) {
            this.feeds = response.response.results;
          } else {
            this.feeds = this.feeds.concat(response.response.results);
          }
          this.totalFeedsCount = response.response.total_count;
          this.feedsNextPage = response.response.next;
        } else {
          this.feeds = [];
          this.totalFeedsCount = 0;
          this.feedsNextPage = 1;
        }
      }, error => {
          this.feeds = [];
          this.totalFeedsCount = 0;
          this.feedsNextPage = 1;
      });
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true;
    } else {
      return false;
    }
  }

  directToPage(feed: any) {
    if (feed.post_info.ini_id) {
      this.router.navigate(['initiatives/' + feed.post_info.ini_id + '/']);
    } else if (feed.post_info.page_id) {
      this.router.navigate(['page/' + feed.post_info.page_id + '/']);
    } else if (feed.post_info.school_id) {
      this.router.navigate(['school/' + feed.post_info.school_id + '/']);
    }
  }

  feedDetailModal(feedDetail: TemplateRef<any>, feed: any, isInterested: boolean = false) {
    this.editFeedObj = feed;
    this.modalRef = this.modalService.show(feedDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position newfeeddetail' })
    );
  }

  markInterested(feed: any){
    if (feed.interested_data.interested) {
      this.pageService.unMarkInterested(feed.id, feed.post_info.post_type)
      .subscribe(response => {});
      let index = this.feeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].interested_data.interested = false;
      }
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = false;
      }
    } else {
      this.pageService.markInterested(feed.id, feed.post_info.post_type, {})
      .subscribe(response => {});
      let index = this.feeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].interested_data.interested = true;
      }
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = true;
      }
    }
  }

  openModalOthers(template: TemplateRef<any>, feed: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editFeedObj = feed;
    this.getInterestedUsers();
    this.otherModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editFeedObj.id, this.editFeedObj.post_info.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  directToProfile(feed: any){
    this.router.navigate(['profile/' + feed.post_info.creator_id + '/']);
  }

  checkExtraData(feed: any){
    if (feed.link && feed.metadata_title && (feed.metadata_description || feed.metadata_image_link)){
      return true;
    } else if (feed.link && !feed.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  closeModal(){
    if (this.otherModalRef) {
      this.otherModalRef.hide();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  editFeedModal(editFeedTemplate: TemplateRef<any>, feed: any) {
    this.timelineUpdForm.reset();
    this.editFeedObj = feed;
    this.timelineUpdForm.controls['description'].setValue(feed.description);
    this.modalRef = this.modalService.show(editFeedTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  delFeedModal(delFeedTemplate: TemplateRef<any>, timeline: any) {
    this.editFeedObj = timeline;
    this.modalRef = this.modalService.show(delFeedTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  updatePost(isValid: boolean){
    if (isValid) {
      let model = {'description': this.timelineUpdForm.get('description').value}
      if (this.editFeedObj.post_info.post_type === 1) {
        this.updatePageTimeline(model);
      } else if (this.editFeedObj.post_info.post_type === 2) {
        this.updatePosition(model);
      } else if (this.editFeedObj.post_info.post_type === 3) {
        this.updateEvent(model);
      } else if (this.editFeedObj.post_info.post_type === 4) {
        this.updateIniTimeline(model);
      } else if ([5,10].includes(this.editFeedObj.post_info.post_type)) {
        this.updateVolunteerPost(model);
      } else if ([6,7,8].includes(this.editFeedObj.post_info.post_type)) {
        this.updateSchoolPost(model);
      }
    } else {
      this.submitted = true;
    }
  }

  updateSchoolPost(model: any){
    this.pageService.updateSchoolTimeline(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    });
  }

  updatePageTimeline(model: any) {
    this.profileService.updatePageTimeline(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updatePosition(model: any) {
    this.profileService.updatePagePosition(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updateEvent(model: any) {
    this.profileService.updateClubTeamEvent(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updateIniTimeline(model: any) {
    this.initiativeService.updateTimeline(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    });
  }

  updateVolunteerPost(model: any) {
    this.initiativeService.updateIniVolunteer(model, this.editFeedObj.id)
    .subscribe(response => {
      this.handleUpdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    });
  }

  deletePost(){
    if (this.editFeedObj.post_info.post_type === 1) {
      this.profileService.deletePageTimeline(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    } else if (this.editFeedObj.post_info.post_type === 2) {
      this.profileService.deletePagePosition(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    } else if (this.editFeedObj.post_info.post_type === 3) {
      this.profileService.deleteClubTeamEvent(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    } else if (this.editFeedObj.post_info.post_type === 4) {
      this.initiativeService.deleteTimeline(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    } else if (this.editFeedObj.post_info.post_type === 5) {
      this.initiativeService.deleteIniVolunteer(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    } else if (this.editFeedObj.post_info.post_type > 5){
      this.pageService.deleteSchoolTimeline(this.editFeedObj.id)
      .subscribe(response => {this.handleDelResponse();});
    }
  }

  protected handleUpdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      let index = this.feeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      this.feeds[index].description = response.response.description;
      this.timelineUpdForm.reset();
      this.editFeedObj = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  protected handleDelResponse(){
    let index = this.feeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
    this.feeds.splice(index, 1);
    this.totalFeedsCount = this.totalFeedsCount - 1;
    this.editFeedObj = {};
  }
}
