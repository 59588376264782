import { Component, OnInit, TemplateRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ProfileService } from '../services/profile.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { PageService } from '../services/page.service';
import { CarouselConfig } from 'ngx-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { InitiativeService } from '../services/initiative.service';
import { TeamService } from '../services/team.service';

@Component({
  selector: 'app-profile-business',
  templateUrl: './profile-business.component.html',
  styleUrls: ['./profile-business.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class ProfileBusinessComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("timelineInp") timelineInp: ElementRef;
  @ViewChild("searchInp") searchInp: ElementRef;
  modalRef: BsModalRef;
  user: any = {};
  public Editor = ClassicEditor;
  tabActive = 1;
  radioActive = 1;
  profile: any = {};
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  public isUnclaimed: boolean = false;
  uploadForm: FormGroup;
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  timelineUpdForm: FormGroup;
  page_id: any;
  pagesDetails: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  pageVerified: string;
  pageVerificationDaysLeft: string;
  pageProfessionalEmail: string;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage: string = "";
  coverImageUploadSizeMessage: string = "";
  linkForm: FormGroup;
  previewThumbnail: any;
  previewThumbnailName: any;
  linkArray = [];
  jobs: any = [];
  volunteerJobs: any = [];
  timelines: any = [];
  timelineDocs: any = [];
  editTimelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;
  totalJobs: any = 0;
  jobNextPage: any = 1;
  volunteerNextPage: any = 1;
  totalVolunteerJob: any = 0;
  teamMember: any = [];
  teamMemberSliced: any = [];
  slicedItem = 6;
  teamLoadMoreBool: boolean = false;
  messageError: any;
  managementForm: FormGroup;
  managementList: any = [];
  totalManagements: any;
  nextManagement: any;
  blockReportForm: FormGroup;
  copyPageIDBool: boolean = false;
  copyMessageBool: boolean = false;
  isOwner: boolean = false;
  encrypt_email: any;
  encrypt_page_id: any;
  searchQuery: string = "";
  invitationForm: FormGroup;
  invitationSuccess: boolean = false;

  constructor(private router: Router, private modalService: BsModalService, public formBuilder: FormBuilder, private route: ActivatedRoute,
    private customValidator: CustomvalidationService, private profileService: ProfileService, private authService: AuthenticationService,
    private pageService: PageService, private initiativeService: InitiativeService, private teamService: TeamService) {
    this.uploadForm = this.formBuilder.group({
      document: [''],
      document_name: [''],
      thumbnail: [''],
      thumbnail_name: ['']
    });
    this.linkForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      link: new FormControl('', [Validators.required, Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.timelineUpdForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)])
    });
    this.managementForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    });
    this.blockReportForm = this.formBuilder.group({
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
    this.invitationForm = this.formBuilder.group({
      invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
    });
  }

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    if (this.route.snapshot.queryParams['tab'] === 'setting') {
      this.tabActive = 4;
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
          if (this.pagesDetails.owner){
            this.isUnclaimed = false;
          } else {
            this.isUnclaimed = true;
          }
          if ([3, 4].indexOf(this.pagesDetails?.page_type) > -1) {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    this.profileService.BusinessVerificationStatus(this.page_id)
      .subscribe(response => {
        this.pageVerified = response.response.business_verified;
        this.pageVerificationDaysLeft = response.response.days_left;
        this.pageProfessionalEmail = response.response.page_info.email;
      });
    this.pageService.getPageDescription(this.page_id)
      .subscribe(response => {
        if (response.response['id'] !== undefined) {
          this.profile = response.response;
        }
      });
      this.profileService.recentPageTimeline(this.page_id)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.timelines = response.response.results;
          this.totalTimelines = response.response.total_count;
        } else {
          this.timelines = [];
        }
      });
    this.getLink();
    this.getTeamMembers();
    this.getManagement();
    this.checkPageUser();
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  teamsConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "infinite": true,
    "arrow": true,
    "autoplay": false,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  getRecentJobs(){
    if (this.jobs.length == 0) {
      this.profileService.getRecentJob(this.page_id)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.jobs = response.response.results;
          this.totalJobs = response.response.total_count;
        } else {
          this.jobs = [];
        }
      });
    }
  }

  getRecentVolunteerJobs(){
    if (this.volunteerJobs.length == 0) {
      this.profileService.getRecentVolunteer(this.page_id)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.volunteerJobs = response.response.results;
          this.totalVolunteerJob = response.response.total_count;
        } else {
          this.volunteerJobs = [];
        }
      });
    }
  }

  checkPageUser(){
    this.pageService.checkPageUser(this.page_id)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.is_related === false) {
          this.router.navigate(['/page/' + this.page_id]);
        }
        this.isOwner = response.response.is_owner;
        this.encrypt_email = response.response.encrypt_email;
        this.encrypt_page_id = response.response.encrypt_page_id;
      } else {
        this.isOwner = false;
      }
    })
  }

  addTimelineLinkModal(addTimelineLink: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addTimelineLink,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  linkModal(linkTemplate: TemplateRef<any>) {
    this.linkForm.reset();
    this.uploadForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
    this.previewThumbnailName = "";
    this.previewThumbnail = false;
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
    this.modalRef = this.modalService.show(linkTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  editTimelineModal(editTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.timelineUpdForm.reset();
    this.editTimelineObj = timeline;
    this.timelineUpdForm.controls['description'].setValue(timeline.description);
    this.modalRef = this.modalService.show(editTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  delTimelineModal(delTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(delTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  coverImageUpload(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true
        this.coverImageUploadMessage = "Only image files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 3000) {
        this.coverImageUploadSizeError = true
        this.coverImageUploadSizeMessage = "Image size should be less than 3MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.previewThumbnail = reader.result;
          this.previewThumbnailName = file_name;
          this.uploadForm.get('thumbnail').setValue(base64String);
          this.uploadForm.get('thumbnail_name').setValue(file_name);
        };
      }
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  blankSupportImageUpload(element){
    element.value = '';
    this.uploadForm.reset();
    this.previewThumbnail = false
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadMessage = "";
  }

  addLink(isValid: boolean){
    this.submitted = true;
    this.disableSubmit = true;
    let link = this.linkForm.get('link').value;
    if (link) {
      link = this.updateLink(link);
    }
    if (isValid){
      let model = {
        'page': this.page_id,
        'title': this.linkForm.get('title').value,
        'link': link,
        'thumbnail': this.uploadForm.get('thumbnail').value,
        'thumbnail_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addLink(model)
        .subscribe(response => {
          this.handleAddLinkResponse(response);
        }, error =>{
          this.handleAddLinkSubmitError(error.error)
        })
    }
  }

  protected handleAddLinkResponse(response: any) {
    if (response.status === 111) {
      this.handleAddLinkSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.linkArray.push(response.response);
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleAddLinkSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.linkForm.contains(field)) {
        this.linkForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  getLink(){
    this.pageService.getLinks(this.page_id)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  delLink(id:any){
    var id = id;
    this.initiativeService.deleteLink(id)
    .subscribe(response => {
      let index = this.linkArray.findIndex(obj => obj.id === id)
      this.linkArray.splice(index, 1)
    })
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'page': this.page_id,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs
      }
      if (this.radioActive == 1) {
        this.addPageTimeline(model);
      } else if (this.radioActive == 2) {
        this.addJob(model);
      } else if (this.radioActive == 3) {
        this.addVolunteerPost(model);
      }
    }
  }

  addPageTimeline(model: any){
    model['date'] = null;
    this.profileService.addPageTimeline(model)
    .subscribe(response => {
      this.timelineDocs = [];
      if (response.status === 112) {
        this.timelineUpdForm.get('description').setErrors({aftersubmit: response.message});
      } else if (response.status === 200) {
        this.timelines.unshift(response.response);
        this.timelineForm.reset();
        this.timelinelinkForm.reset();
        this.submitted = false;
      } else {
        this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      }
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    })
  }

  addJob(model: any){
    this.profileService.addJob(model)
    .subscribe(response => {
      this.timelineDocs = [];
      if (response.status === 112) {
        this.timelineUpdForm.get('description').setErrors({aftersubmit: response.message});
      } else if (response.status === 200) {
        this.jobs.unshift(response.response);
        this.timelineForm.reset();
        this.timelinelinkForm.reset();
        this.submitted = false;
      } else {
        this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      }
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    })
  }

  addVolunteerPost(model: any){
    this.profileService.addVolunteerJob(model)
    .subscribe(response => {
      this.timelineDocs = [];
      if (response.status === 112) {
        this.timelineUpdForm.get('description').setErrors({aftersubmit: response.message});
      } else if (response.status === 200) {
        this.volunteerJobs.unshift(response.response);
        this.timelineForm.reset();
        this.timelinelinkForm.reset();
        this.submitted = false;
      } else {
        this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      }
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    })
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.modalRef.hide();
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  updateTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {'description': this.timelineUpdForm.get('description').value}
      if (this.radioActive == 1) {
        this.updatePageTimeline(model);
      } else if (this.radioActive == 2) {
        this.updateJob(model);
      }  else if (this.radioActive == 3) {
        this.updateVolunteerJob(model);
      }
    }
  }

  updatePageTimeline(model: any) {
    this.profileService.updatePageTimeline(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updateJob(model: any) {
    this.profileService.updateJob(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  updateVolunteerJob(model: any) {
    this.profileService.updateVolunteerJob(model, this.editTimelineObj.id)
    .subscribe(response => {
      this.handleupdResponse(response);
    }, error => {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    })
  }

  protected handleupdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      if (this.radioActive == 1) {
        let index = this.timelines.findIndex(obj => obj.id === response.response.id);
        this.timelines[index].description = response.response.description;
      } else if (this.radioActive == 2) {
        let index = this.jobs.findIndex(obj => obj.id === response.response.id);
        this.jobs[index].description = response.response.description;
      } else if (this.radioActive == 3) {
        let index = this.volunteerJobs.findIndex(obj => obj.id === response.response.id);
        this.volunteerJobs[index].description = response.response.description;
      }
      this.timelineUpdForm.reset();
      this.editTimelineObj = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  delTimelineElem(){
    if (this.radioActive == 1) {
      this.deleteTimeline();
    } else if (this.radioActive == 2) {
      this.deleteJob();
    } else if (this.radioActive == 3) {
      this.deleteVolunteer();
    }
  }

  deleteTimeline(){
    this.profileService.deletePageTimeline(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.timelines.findIndex(obj => obj.id === this.editTimelineObj.id);
      this.timelines.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  deleteJob(){
    this.profileService.deleteJob(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.jobs.findIndex(obj => obj.id === this.editTimelineObj.id);
      this.jobs.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  deleteVolunteer(){
    this.profileService.deleteVolunteerJob(this.editTimelineObj.id)
    .subscribe(response => {
      let index = this.volunteerJobs.findIndex(obj => obj.id === this.editTimelineObj.id);
      this.volunteerJobs.splice(index, 1);
      this.editTimelineObj = {};
    })
  }

  loadMoreTimelines(){
    let params = '?page=' + this.timelineNextPage;
    this.profileService.pageTimeline(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.timelineNextPage == 1) {
          this.timelines = response.response.results;
        } else {
          this.timelines = this.timelines.concat(response.response.results);
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      }
    });
  }

  loadMoreJobs(){
    let params = '?page=' + this.jobNextPage;
    this.profileService.listJobs(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.jobNextPage == 1) {
          this.jobs = response.response.results;
        } else {
          this.jobs = this.jobs.concat(response.response.results);
        }
        this.jobNextPage = response.response.next;
        this.totalJobs = response.response.total_count;
      }
    });
  }

  loadMoreVolunteerJobs(){
    let params = '?page=' + this.volunteerNextPage;
    this.profileService.listVolunteerJobs(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.volunteerNextPage == 1) {
          this.volunteerJobs = response.response.results;
        } else {
          this.volunteerJobs = this.volunteerJobs.concat(response.response.results);
        }
        this.volunteerNextPage = response.response.next;
        this.totalVolunteerJob = response.response.total_count;
      }
    });
  }

  uploadTimelineDoc(event){
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    if (event.target.files.length > 0){
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.coverImageUploadError = true;
        this.coverImageUploadMessage = "Only jpg, png and pdf files allowed.";
      } else {
        this.coverImageUploadError = false;
        this.coverImageUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.coverImageUploadSizeError = true;
        this.coverImageUploadSizeMessage = "Image/Document size should be less than 5MB.";
      } else {
        this.coverImageUploadSizeError = false;
        this.coverImageUploadSizeMessage = "";
      }
      if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          let timelineDoc = {'previewImg': reader.result, 'document': base64String, 'document_name': file_name};
          this.timelineDocs.push(timelineDoc);
          if (this.timelineDocs.length < 5) {
            this.timelineInp.nativeElement.value = "";
          }
        };
      }
    }
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  getTeamMembers(){
    this.pageService.teamMembers(this.page_id)
    .subscribe(response => {
      if(response.status == 200){
          this.teamMember = response.response.experiences;
          this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
          if (this.teamMember.length > this.teamMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  teamLoadMore(){
    let totalMember = this.teamMember.length;
    let slicedMember = this.teamMemberSliced.length;
    if (slicedMember < totalMember){
      this.teamMemberSliced = this.teamMember.slice(0, slicedMember+this.slicedItem)
    }
    if (totalMember > this.teamMemberSliced.length){
      this.teamLoadMoreBool = true;
    } else {
      this.teamLoadMoreBool = false;
    }
  }

  sendMessage(memberID){
    this.profileService.createConversation({"user2": memberID})
    .subscribe(response => {
      if(response.status == 200) {
        this.router.navigate(['message-chat']);
      } else if (response.status < 200) {
        this.messageError = response.message;
      } else {
        this.messageError = "Something went wrong. Please try again later.";
      }
    });
  }

  addManagement(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'page': this.page_id,
        'first_name': this.managementForm.get('firstName').value,
        'last_name': this.managementForm.get('lastName').value,
        'position': this.managementForm.get('position').value
      }
      this.pageService.addManagementVolunteer(model)
        .subscribe(response => {
          this.handleAddManagementResponse(response);
        }, error =>{
          this.handleAddManagementSubmitError(error.error)
        })
    }
  }

  protected handleAddManagementResponse(response: any) {
    if (response.status === 111) {
      this.handleAddManagementSubmitError(response.message);
    } else if (response.status === 200) {
      this.managementList.push(response.response);
      this.managementForm.reset();
      this.submitted = false;
    }
  }

  protected handleAddManagementSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.managementForm.contains(field)) {
        this.managementForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  getManagement(){
    let param = '';
    if (this.nextManagement && this.nextManagement != null){
      param = '?page=' + this.nextManagement;
    }
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = response.response.results;
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }

  deleteVol(id: any){
    this.pageService.deleteManagement(id)
    .subscribe(response => {
      let index = this.managementList.findIndex(obj => obj.id === id)
      this.managementList.splice(index, 1);
      this.totalManagements = this.totalManagements - 1;
    })
  }

  laodMoreManagements(){
    let  param = '?page=' + this.nextManagement;
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = this.managementList.concat(response.response.results);
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }
  openDetail(editModal: TemplateRef<any>) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(editModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  reportUser(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reported_page': this.page_id, 'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description']
      }
      this.authService.ReportUser(report_data).subscribe(respones => {});
      this.modalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyPageID(){
    let msg = "ORG" + this.page_id;
    this.setToCopy(msg);
    this.copyPageIDBool = true;
    setTimeout(() => {this.copyPageIDBool = false  ;}, 3000);
  }

  copyMessage(){
    let msg = "Join my network at " + this.pagesDetails.page_info.title + " on ConnectUs.today by signing up using the link below: connectus.today/signin";
    this.setToCopy(msg);
    this.copyMessageBool = true;
    setTimeout(() => {this.copyMessageBool = false  ;}, 3000);
  }

  routeToClaim(){
    this.router.navigate(['/verify-claim'], {queryParams: {'id': this.encrypt_page_id, 'verifier': this.encrypt_email}});
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.modalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  resetMembers(){
    if (this.teamMember.length > 0) {
      this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
      if (this.searchInp){
        this.searchInp.nativeElement.value = "";
      }
      if (this.teamMember.length > this.teamMemberSliced.length){
        this.teamLoadMoreBool = true;
      }
    }
  }

  searchStudent(e: any){
    if (e.target.value && e.target.value.length > 0){
      this.searchQuery = e.target.value;
      this.teamMemberSliced = this.teamMember.filter(item => item.student_info.student_name.search(new RegExp(this.searchQuery, 'i')) > -1);
      this.teamLoadMoreBool = false;
    } else if (this.searchQuery.length > 0) {
      this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
      if (this.teamMember.length > this.teamMemberSliced.length){
        this.teamLoadMoreBool = true;
      }
    }
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'page': this.page_id
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

}
