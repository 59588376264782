<section class="profile-head newPageShow newProfile">
<div class="tab-content-box">
    <app-profile-header></app-profile-header>
    <div class="tabWrap">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12">
                    <ul class="tabBtns no-list">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                        }" (click)="tabActive=1" > requests
                        </li>
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                        }" (click)="tabActive=2; approveTab()">added
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div class="custom-tab-dark custom-tab-teal">
        <div class="profileWhiteWrap profileWrapSpace blueHdgRad pb-3">
            <p *ngIf="actionError" class="alert alert-danger">Something went wrong. Please try again later.</p>
            <div *ngIf="tabActive == 1">
                <div class="teamRequests">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Experience</h3>
                    </div>
                    <ul class="teamListWrap no-list">
                        <li *ngFor="let pendingReq of peningRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ pendingReq.exp_team_detail.header_image ? pendingReq.exp_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ pendingReq.exp_team_detail.display_pic ? pendingReq.exp_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ pendingReq.exp_team_detail.name }}</h1>
                                    <h3 class="subName">{{ pendingReq.exp_team_detail.organization_title }}</h3>
                                    <h3 class="subName">{{ pendingReq.exp_team_detail.position }}</h3>
                                    <p class="yearHistory">{{ pendingReq.exp_team_detail.start_date | date: 'MMM y' }}- <span
                                            *ngIf="pendingReq.exp_team_detail.end_date">{{ pendingReq.exp_team_detail.end_date | date: 'MMM y'
                                            }}</span><span *ngIf="!pendingReq.exp_team_detail.end_date">Present</span></p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="requestAction(pendingReq, 'decline')">Decline</button>
                                        <button class="btn common-btn btnProfile"
                                            (click)="requestAction(pendingReq, 'accept')">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="timeLineLoad pt-3"
                        *ngIf="totalpendingReq > 6 && totalpendingReq > peningRequests.length && pendingReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMorePending()">Load More</a>
                    </div>
                </div>

                <div class="teamRequests">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Education</h3>
                    </div>
                    <ul class="teamListWrap no-list">
                        <li *ngFor="let pendingReq of pendingEduRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ pendingReq.edu_team_detail.header_image ? pendingReq.edu_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ pendingReq.edu_team_detail.display_pic ? pendingReq.edu_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ pendingReq.edu_team_detail.name }}</h1>
                                    <h3 class="subName">{{ pendingReq.edu_team_detail.organization_title }}</h3>
                                    <p class="yearHistory">{{ pendingReq.edu_team_detail.start_date | date:'yyyy' }}- {{ pendingReq.edu_team_detail.end_date | date:'yyyy'}}</p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="requestActionEdu(pendingReq, 'decline')">Decline</button>
                                        <button class="btn common-btn btnProfile"
                                            (click)="requestActionEdu(pendingReq, 'accept')">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="timeLineLoad pt-3"
                        *ngIf="totalpendingEduReq > 6 && totalpendingEduReq > pendingEduRequests.length && pendingEduReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMorePendingEdu()">Load More</a>
                    </div>
                </div>

                <div class="teamRequests">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Initiative</h3>
                    </div>
                    <ul class="teamListWrap no-list">
                        <li *ngFor="let pendingReq of pendingIniRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ pendingReq.ini_team_detail.header_image ? pendingReq.ini_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ pendingReq.ini_team_detail.display_pic ? pendingReq.ini_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ pendingReq.ini_team_detail.name }}</h1>
                                    <p class="yearHistory">{{ pendingReq.ini_team_detail.position }}</p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="requestActionIni(pendingReq, 'decline')">Decline</button>
                                        <button class="btn common-btn btnProfile"
                                            (click)="requestActionIni(pendingReq, 'accept')">Accept</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="timeLineLoad pt-3"
                        *ngIf="totalpendingIniReq > 6 && totalpendingIniReq > pendingIniRequests.length && pendingIniReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMorePendingIni()">Load More</a>
                    </div>
                </div>
            </div>
            <div *ngIf="tabActive == 2">
                <div class="teamVerications">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Experience</h3>
                    </div>

                    <ul class="teamListWrap no-list">
                        <li *ngFor="let approvedReq of approvedRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ approvedReq.exp_team_detail.header_image ? approvedReq.exp_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ approvedReq.exp_team_detail.display_pic ? approvedReq.exp_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ approvedReq.exp_team_detail.name }}</h1>
                                    <h3 class="subName">{{ approvedReq.exp_team_detail.organization_title }}</h3>
                                    <h3 class="subName">{{ approvedReq.exp_team_detail.position }}</h3>
                                    <p class="yearHistory">{{ approvedReq.exp_team_detail.start_date | date: 'MMM y'}} - <span
                                            *ngIf="approvedReq.exp_team_detail.end_date">{{ approvedReq.exp_team_detail.end_date | date: 'MMM y'
                                            }}</span><span *ngIf="!approvedReq.exp_team_detail.end_date">Present</span></p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="cancelTeam(approvedReq.id)">remove</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="timeLineLoad pt-3" *ngIf="totalapprovedReq > 6 && approvedReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMoreApproved()">Load More</a>
                    </div>
                </div>

                <div class="teamVerications">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Education</h3>
                    </div>

                    <ul class="teamListWrap no-list">
                        <li *ngFor="let approvedReq of approvedEduRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ approvedReq.edu_team_detail.header_image ? approvedReq.edu_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ approvedReq.edu_team_detail.display_pic ? approvedReq.edu_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ approvedReq.edu_team_detail.name }}</h1>
                                    <h3 class="subName">{{ approvedReq.edu_team_detail.position }}</h3>
                                    <p class="yearHistory">{{ approvedReq.edu_team_detail.start_date | date:'yyyy' }} - {{ approvedReq.edu_team_detail.end_date | date:'yyyy' }}</p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="cancelEduTeam(approvedReq.id)">remove</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="timeLineLoad pt-3" *ngIf="totalapprovedEduReq > 6 && approvedEduReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMoreApprovedEdu()">Load More</a>
                    </div>
                </div>

                <div class="teamVerications">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Initiative</h3>
                    </div>

                    <ul class="teamListWrap no-list">
                        <li *ngFor="let approvedReq of approvedIniRequests">
                            <div class="teamMemberEach">
                                <div class="topMember">
                                    <img alt="icon" class="bannerImg"
                                        src="{{ approvedReq.ini_team_detail.header_image ? approvedReq.ini_team_detail.header_image :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="profileMember">
                                    <img alt="icon" class="profileImg"
                                        src="{{ approvedReq.ini_team_detail.display_pic ? approvedReq.ini_team_detail.display_pic :'assets/images/new-header-img.png'}}" />
                                </div>
                                <div class="btmMember">
                                    <h1 class="name">{{ approvedReq.ini_team_detail.name }}</h1>
                                    <p class="yearHistory">{{ approvedReq.ini_team_detail.position }}</p>
                                    <div class="memberActions">
                                        <button class="btn common-btn btnMessage"
                                            (click)="cancelIniTeam(approvedReq.id)">remove</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="timeLineLoad pt-3" *ngIf="totalapprovedIniReq > 6 && approvedIniReqNextPage">
                        <a href="javascript:void(0);" (click)="loadMoreApprovedIni()">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
