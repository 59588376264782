import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private router: Router) { 
    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    }
  } 

  ngOnInit(): void { 
  }

  communityConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrows": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: false,
          dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

}
