import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageService } from '../services/page.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { Location, DatePipe } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-school-bucket',
  templateUrl: './school-bucket.component.html',
  styleUrls: ['./school-bucket.component.scss']
})
export class SchoolBucketComponent implements OnInit {
  modalRef: BsModalRef;
  linkModalRef: BsModalRef;
  school_id: any;
  schoolDetails: any = {};
  editTimeline: any = {};
  clubs = [];
  teams = [];
  approvedExperiences = [];
  tabActive = 1;
  public isSenior: boolean = false;
  firstVisit: boolean = false;
  user: any = {};
  uploadForm: FormGroup;
  addVerifierForm: FormGroup;
  searchForm: FormGroup;
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  blockReportForm: FormGroup;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  public delError: boolean = false;
  public isHeaderToDel: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  imageChangedEvent: any = '';
  croppedImage: any = '';
  delErrorMessage = "Unable to delete the image. Please try again later.";
  verifiers: any[] = [];
  public showVerifierMsg: boolean = false;
  verifierMsg: string;
  delVerifierObj: any = {};
  public isSuccess: boolean = false;
  recentClubsTeams: any = [];
  totalResults: any = 0;
  initiativeCount: any = 0;
  userCount: any = 0;
  allCount: any = 0;
  radioActive: number = 1;
  clubTeamNextPage: any = 1;
  copyPageIDBool: boolean = false;
  initiatives = [];
  initiativeNextPage: any = 1;
  followBtnErr: boolean = false;
  searchKey: any = null;
  postSelected = 8;
  timelineDocs: any = [];
  public Editor = ClassicEditor;
  timelines = [];
  totalTimelines: any = 0;
  timelineNextPage: any = 1;
  timelineActive = 9;
  intUsersNextPage: any = 1;
  interestedUsers:any = [];
  totalUsersCount: number = 0;
  otherModalRef: BsModalRef;
  reportModalRef: BsModalRef;
  timelineUpdForm: FormGroup;
  linkAdded: boolean = false;
  descriptionLimit: number= 97;

  constructor(private pageService: PageService, private route: ActivatedRoute, private modalService: BsModalService,
    public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private location: Location,
    private router: Router, private datePipe: DatePipe, private profileService: ProfileService) {
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required])
    });
    this.addVerifierForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
    this.searchForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator])
    });
    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      date: new FormControl(''),
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.blockReportForm = this.formBuilder.group({
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
    this.timelineUpdForm = this.formBuilder.group({
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)])
    });
  }

  ngOnInit(): void {
    this.school_id = this.route.snapshot.paramMap.get('id');
    this.getSchoolDetails();
    this.getVerifiers();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.age_group == 3) {
      this.isSenior = true;
    } else {
      this.isSenior = false;
    }
    this.getAllClubsTeams();
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  getSchoolDetails(){
    this.pageService.getSchoolDetails(this.school_id)
    .subscribe(response => {
        if(response.status === 200){
          this.firstVisit = true;
          this.schoolDetails = response.response.result;
          this.initiativeCount = response.response.initiative_count;
          this.userCount = response.response.user_count;
          if (this.schoolDetails.content.is_related) {
            this.getSchoolExperiences();
          }
        } else {
          this.schoolDetails = {};
        }
      });
  }

  editTimelineModal(editTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.timelineUpdForm.reset();
    this.editTimeline = timeline;
    this.timelineUpdForm.controls['description'].setValue(timeline.description);
    this.modalRef = this.modalService.show(editTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  getSchoolExperiences(){
    this.pageService.getSchoolExperiences(this.school_id)
    .subscribe(response => {
      if(response.response.length > 0){
        this.approvedExperiences = response.response;
      } else {
        this.approvedExperiences = [];
      }
    });
  }

  getVerifiers(){
    let params = '?type=1';
    this.pageService.getVerifiers(this.school_id, params)
    .subscribe(response => {
      if(response.response.length > 0){
        this.verifiers = response.response;
      } else {
        this.verifiers = [];
      }
    })
  }

  openModal(template: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  addTimeline(template: TemplateRef<any>) {
    this.linkAdded = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.postSelected = 8;
    this.timelineDocs = [];
    this.timelineForm.reset();
    this.timelinelinkForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  openLinkModal(template: TemplateRef<any>) {
    this.linkAdded = false;
    this.linkModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall' }));
  }

  delVerifierModal(delVerifier: TemplateRef<any>, verifier: any) {
    this.delVerifierObj = verifier;
    this.modalRef = this.modalService.show(delVerifier,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered new-modal-ui' })
    );
  }

  addVerifiers(addVerifier: TemplateRef<any>) {
    this.addVerifierForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(addVerifier,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered new-modal-ui' })
    );
  }

  openDetailModal(postDetail: TemplateRef<any>, timeline: any) {
    this.editTimeline = timeline;
    this.modalRef = this.modalService.show(postDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position newfeeddetail' })
    );
  }

  coverImageUpload(event) {
    let fileSizeVal = 5000;
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      let fileCount = this.timelineDocs.length + event.target.files.length;
      if (fileCount > 5) {
        this.fileUploadError = true;
        this.fileUploadMessage = "Maximum 5 photos allowed.";
        event.target.value = null;
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        let file_name = event.target.files[i].name;
        const fileExtension = file_name.split('.').pop();
        if (file_name.length > 30) {
          file_name = "cu-media." + fileExtension;
        }
        const fileSize = Math.round(file.size / 1024);
        if (!allowedExtensions.includes(fileExtension)) {
          this.fileUploadError = true
          this.fileUploadMessage = "Only image files allowed.";
        } else {
          this.fileUploadError = false;
          this.fileUploadMessage = "";
        }
        if (fileSize > fileSizeVal) {
          this.fileUploadSizeError = true
          this.fileUploadSizeMessage = "Image size should be less than 5MB.";
        } else {
          this.fileUploadSizeError = false;
          this.fileUploadSizeMessage = "";
        }
        if (!this.fileUploadError && !this.fileUploadSizeError) {
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e): void => {
            const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
            )[1];
            if (this.timelineDocs.length < 5){
              let timelineDoc = { 'previewImg': reader.result, 'document': base64String, 'document_name': file_name };
              this.timelineDocs.unshift(timelineDoc);
            }
            event.target.value = null;
          };
        } else {
          event.target.value = null;
          setTimeout(() => { this.fileUploadError = false; this.fileUploadSizeError = false; }, 5000);
          break;
        }
      }

    }
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  addImage(isValid: boolean, type:string) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      if (type === 'header'){
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': false,
          'header_img': true,
        }
      } else {
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': true,
          'header_img': false,
        }
      }
      this.pageService.updateSchoolImage(model, this.school_id)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.schoolDetails.display_picture = response.response.display_picture;
      this.schoolDetails.header_image = response.response.header_image;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delImageModal(delImage: TemplateRef<any>, header_image: boolean = false) {
    this.delError = false;
    if (header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.modalRef = this.modalService.show(delImage,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  deleteImage(){
    this.pageService.deleteSchoolImage({'header_img': this.isHeaderToDel}, this.school_id)
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.modalRef.hide();
        if (this.isHeaderToDel) {
          this.schoolDetails.header_image = null;
        } else {
          this.schoolDetails.display_picture = null;
        }
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
  }

  createVerifier(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'school': this.school_id,
                  'email': this.addVerifierForm.get('email').value.toLowerCase(),
                  'first_name': this.addVerifierForm.get('first_name').value,
                  'last_name': this.addVerifierForm.get('last_name').value}
      this.pageService.addVerifiers(model)
      .subscribe(response => {
        if (response.status === 111) {
          let data = response.message;
          const fields = Object.keys(data || {});
          fields.forEach(field => {
            if (this.addVerifierForm.contains(field)) {
              this.addVerifierForm.get(field).setErrors({ aftersubmit: data[field][0] });
            }
          });
        } else if (response.status === 200) {
          this.verifiers.push(response.response);
          this.modalRef.hide();
          this.submitted = false;
          this.addVerifierForm.reset();
        } else {
          this.addVerifierForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.addVerifierForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
  }

  fadeOutVerifierMsg() {
    setTimeout( () => {
      this.showVerifierMsg = false;
      this.verifierMsg = "";
    }, 6000);
  }

  deleteVerifier() {
    this.modalRef.hide();
    if (this.delVerifierObj.id) {
      this.pageService.deleteVerifier(this.delVerifierObj.id, this.delVerifierObj)
      .subscribe(response => {
        this.isSuccess = true;
        this.verifierMsg = "Verifier has been deleted successfully.";
        this.handleVerifierResponse(response, this.delVerifierObj);
      }, error => {
        this.isSuccess = false;
        this.verifierMsg = "Something went wrong. Please try again later.";
        this.showVerifierMsg = true;
        this.fadeOutVerifierMsg();
      });
    }
  }

  handleVerifierResponse(response: any, delVerifierObj: any){
    if (response.status === 111) {
      this.isSuccess = false;
      this.verifierMsg = "Unable to process the request. Please refresh and try again later";
      this.showVerifierMsg = true;
    } else if (response.status === 200) {
      this.showVerifierMsg = true;
      let index = this.verifiers.indexOf(delVerifierObj);
      this.verifiers.splice(index, 1);
    } else {
      this.isSuccess = false;
      this.verifierMsg = response.message;
      this.showVerifierMsg = true;
    }
    this.fadeOutVerifierMsg();
  }

  backClicked(){
    this.location.back();
  }

  toGetTimeline(){
    if (this.timelines.length == 0) {
      this.timelineActive = 9;
      this.getTimeline();
    }
  }


  getTimeline(){
    let params = '?';
    if (this.timelineActive && this.timelineActive < 9) {
      params = params + 'post_type=' + this.timelineActive;
    }
    if (this.timelineNextPage > 1){
      params = params + '&page=' + this.timelineNextPage;
    }
    this.pageService.listSchoolTimeline(this.school_id, params)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.results.length > 0) {
          if (this.timelineNextPage == 1 || this.timelineNextPage === null) {
            this.timelines = response.response.results;
          } else {
            this.timelines = this.timelines.concat(response.response.results);
          }
        } else {
          this.timelines = [];
        }
        this.timelineNextPage = response.response.next;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];
        this.timelineNextPage = 1;
        this.totalTimelines = 0;
      }
    });
  }




  getAllClubsTeams(){
    let params = '?';
    if (this.searchKey && this.searchKey.length > 2) {
      params = params + 'search=' + this.searchKey;
    }
    if (this.clubTeamNextPage > 1){
      params = params + '&page=' + this.clubTeamNextPage;
    }
    this.pageService.listAllClubTeam(this.school_id, params)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.results.length > 0) {
          if (this.clubTeamNextPage == 1 || this.clubTeamNextPage === null) {
            this.recentClubsTeams = response.response.results;
          } else {
            this.recentClubsTeams = this.recentClubsTeams.concat(response.response.results);
          }
        } else {
          this.recentClubsTeams = [];
        }
        this.clubTeamNextPage = response.response.next;
        this.totalResults = response.response.total_count;
        this.allCount = response.response.total_count;
      } else {
        this.recentClubsTeams = [];
        this.clubTeamNextPage = 1;
        this.totalResults = 0;
        this.allCount = 0;
      }
    });
  }

  getAllClubs(){
    let params = '?';
    if (this.searchKey && this.searchKey.length > 2) {
      params = params + 'search=' + this.searchKey;
    }
    if (this.clubTeamNextPage > 1){
      params = params + '&page=' + this.clubTeamNextPage;
    }
    this.pageService.listAllClub(this.school_id, params)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.results.length > 0) {
          if (this.clubTeamNextPage == 1 || this.clubTeamNextPage === null) {
            this.recentClubsTeams = response.response.results;
          } else {
            this.recentClubsTeams = this.recentClubsTeams.concat(response.response.results);
          }
        } else {
          this.recentClubsTeams = [];
        }
        this.clubTeamNextPage = response.response.next;
        this.totalResults = response.response.total_count;
      } else {
        this.recentClubsTeams = [];
        this.clubTeamNextPage = 1;
        this.totalResults = 0;
      }
    });
  }

  getAllTeams(){
    let params = '?';
    if (this.searchKey && this.searchKey.length > 2) {
      params = params + 'search=' + this.searchKey;
    }
    if (this.clubTeamNextPage > 1){
      params = params + '&page=' + this.clubTeamNextPage;
    }
    this.pageService.listAllTeam(this.school_id, params)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.results.length > 0) {
          if (this.clubTeamNextPage == 1 || this.clubTeamNextPage === null) {
            this.recentClubsTeams = response.response.results;
          } else {
            this.recentClubsTeams = this.recentClubsTeams.concat(response.response.results);
          }
        } else {
          this.recentClubsTeams = [];
        }
        this.clubTeamNextPage = response.response.next;
        this.totalResults = response.response.total_count;
      } else {
        this.recentClubsTeams = [];
        this.clubTeamNextPage = 1;
        this.totalResults = 0;
      }
    });
  }

  getAllInitiatives(){
    let params = '?';
    if (this.searchKey && this.searchKey.length > 2) {
      params = params + 'search=' + this.searchKey;
    }
    if (this.initiativeNextPage > 1){
      params = params + '&page=' + this.initiativeNextPage;
    }
    this.pageService.listAllInitiatives(this.school_id, params)
    .subscribe(response => {
      if (response.status === 200) {
        if (response.response.results.length > 0) {
          if (this.initiativeNextPage == 1 || this.initiativeNextPage === null) {
            this.initiatives = response.response.results;
          } else {
            this.initiatives = this.initiatives.concat(response.response.results);
          }
        } else {
          this.initiatives = [];
        }
        this.initiativeNextPage = response.response.next;
        this.totalResults = response.response.total_count;
      } else {
        this.initiatives = [];
        this.initiativeNextPage = 1;
        this.totalResults = 0;
      }
    });
  }

  loadMoreClubTeam(){
    if (this.radioActive === 1){
      this.getAllClubsTeams();
    } else if (this.radioActive === 2){
      this.getAllClubs();
    } else if (this.radioActive === 4){
      this.getAllInitiatives();
    } else {
      this.getAllTeams();
    }
  }

  copyPageID(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "SCH" + this.school_id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copyPageIDBool = true;
    setTimeout(() => {this.copyPageIDBool = false  ;}, 3000);
  }

  showImg(headerImages: TemplateRef<any>) {
    if (this.schoolDetails.header_image) {
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  routeToClubTeam(clubTeam: any, messageAlertTemplate: TemplateRef<any>){
    if (!this.isSenior || this.schoolDetails.is_college){
      if (clubTeam.page_info.owner === this.user.id){
        this.router.navigate(['/page/' + clubTeam.id + '/profile-school']);
      } else {
        this.router.navigate(['/page/' + clubTeam.id]);
      }
    } else {
      this.modalRef = this.modalService.show(messageAlertTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  routeToInitiative(initiative: any){
    if (initiative.page_info.owner === this.user.id) {
      this.router.navigate(['/edit-initiatives/' + initiative.id]);
    } else {
      this.router.navigate(['/initiatives/' + initiative.id]);
    }
  }

  followPageIni(instance: any){
    if (instance.page_type === 5) {
      this.allFollowInitiative(instance);
    } else {
      this.followPage(instance);
    }
  }

  allFollowInitiative(instance: any){
    this.followBtnErr = false;
    if (instance.is_followed) {
      this.pageService.unFollowInitiative(instance.id, {})
      .subscribe(response => {
        let index = this.recentClubsTeams.findIndex(obj => (obj.id === instance.id && obj.page_type === 5));
        this.recentClubsTeams[index].is_followed = false;
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    } else {
      this.pageService.followInitiative(instance.id, {})
      .subscribe(response => {
        if (response.status === 200) {
          let index = this.recentClubsTeams.findIndex(obj => (obj.id === instance.id && obj.page_type === 5));
          this.recentClubsTeams[index].is_followed = true;
        } else {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        }
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    }
  }

  followInitiative(instance: any){
    this.followBtnErr = false;
    if (instance.is_followed) {
      this.pageService.unFollowInitiative(instance.id, {})
      .subscribe(response => {
        let index = this.initiatives.findIndex(obj => obj.id === instance.id);
        this.initiatives[index].is_followed = false;
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    } else {
      this.pageService.followInitiative(instance.id, {})
      .subscribe(response => {
        if (response.status === 200) {
          let index = this.initiatives.findIndex(obj => obj.id === instance.id);
          this.initiatives[index].is_followed = true;
        } else {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        }
      }, error => {
        this.followBtnErr = true;
        this.fadeOutFollowErrMsg();
      });
    }
  }

  followPage(instance: any){
    if (!this.isSenior || this.schoolDetails.is_college) {
      this.followBtnErr = false;
      if (instance.is_followed) {
        this.pageService.unFollowPage(instance.id, {})
        .subscribe(response => {
          let index = this.recentClubsTeams.findIndex(obj => (obj.id === instance.id && obj.page_type !== 5));
          this.recentClubsTeams[index].is_followed = false;
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      } else {
        this.pageService.followPage(instance.id, {})
        .subscribe(response => {
          if (response.status === 200) {
            let index = this.recentClubsTeams.findIndex(obj => (obj.id === instance.id && obj.page_type !== 5));
            this.recentClubsTeams[index].is_followed = true;
          } else {
            this.followBtnErr = true;
            this.fadeOutFollowErrMsg();
          }
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      }
    }
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 6000);
  }

  search(event: any){
    let name = this.searchForm.get('name').value;
    if (name.length === 0) {
      this.resetSearch();
    } else if (this.searchForm.valid && name.length > 2) {
      this.searchKey = name;
      this.clubTeamNextPage = 1;
      this.initiativeNextPage = 1;
      this.loadMoreClubTeam();
    } else {
      this.searchKey = null;
    }
  }

  resetSearch(){
    this.searchForm.reset();
    this.searchKey = null;
    this.radioActive = 1;
    this.clubTeamNextPage = 1;
    this.initiativeNextPage = 1;
    this.getAllClubsTeams();
  }
  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };
  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };
  openNewFeedDetailModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui message-modal' })
    );
  }

  delTimelineModal(delTimelineTemplate: TemplateRef<any>, timeline: any) {
    this.editTimeline = timeline;
    this.modalRef = this.modalService.show(delTimelineTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  openModalOthers(template: TemplateRef<any>, post: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editTimeline = post;
    this.getInterestedUsers();
    this.otherModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  reportModal(reportTemplate: TemplateRef<any>, post: any) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.editTimeline = post;
    this.reportModalRef = this.modalService.show(reportTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' }
    ));
  }

  changeType(){
    if (this.postSelected === 7) {
      this.timelineForm.get('date').setValidators([Validators.required]);
      this.timelineForm.controls["date"].updateValueAndValidity();
    } else {
      this.timelineForm.controls["date"].setErrors(null);
      this.timelineForm.get('date').clearValidators();
      this.timelineForm.controls["date"].updateValueAndValidity();
    }
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.linkModalRef.hide();
      this.linkAdded = true;
      setTimeout(() => {this.linkAdded = false;}, 4000);
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.fileUploadError = false;
      this.fileUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'school': this.school_id,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'post_type': this.postSelected,
        'date': this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd")
      }
      this.pageService.addSchoolTimeline(model)
      .subscribe(response => {
        this.handlePostResponse(response);
      }, error => {
        this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  handlePostResponse(response: any){
    if (response.status === 200) {
      this.timelineDocs = [];
      this.modalRef.hide();
      this.timelineForm.reset();
      this.timelinelinkForm.reset();
      this.submitted = false;
      if (this.timelineActive == this.postSelected) {
        this.timelineActive = this.postSelected;
      } else {
        this.timelineActive = 9;
      }
      this.timelineNextPage=1;
      this.getTimeline();
    } else {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  markInterested(post: any){
    if (post.interested_data.interested) {
      this.pageService.unMarkInterested(post.id, 6)
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => obj.id === post.id);
      if (index !== -1) {
        this.timelines[index].interested_data.interested = false;
      }
      if (this.editTimeline && this.editTimeline.id === post.id) {
        this.editTimeline.interested_data.interested = false;
      }
    } else {
      this.pageService.markInterested(post.id, 6, {})
      .subscribe(response => {});
      let index = this.timelines.findIndex(obj => obj.id === post.id);
      if (index !== -1) {
        this.timelines[index].interested_data.interested = true;
      }
      if (this.editTimeline && this.editTimeline.id === post.id) {
        this.editTimeline.interested_data.interested = true;
      }
    }
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editTimeline.id, 6, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  closeModal(){
    if (this.otherModalRef) {
      this.otherModalRef.hide();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  reportFeed(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description'],
        'school_timeline': this.editTimeline.id
      }
      this.profileService.reportFeed(report_data).subscribe(respones => {});
      let index = this.timelines.findIndex(obj => obj.id === this.editTimeline.id);
      this.timelines.splice(index, 1);
      this.totalTimelines = this.totalTimelines - 1;
      this.editTimeline = {};
      this.reportModalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  updateTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let model = {'description': this.timelineUpdForm.get('description').value}
      this.pageService.updateSchoolTimeline(model, this.editTimeline.id)
      .subscribe(response => {
        this.handleupdResponse(response);
      }, error => {
        this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  protected handleupdResponse(response: any) {
    if (response.status === 200) {
      this.modalRef.hide();
      let index = this.timelines.findIndex(obj => obj.id === response.response.id);
      this.timelines[index].description = response.response.description;
      this.timelineUpdForm.reset();
      this.editTimeline = {};
      this.submitted = false;
    } else {
      this.timelineUpdForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  deleteTimeline(){
    this.pageService.deleteSchoolTimeline(this.editTimeline.id)
    .subscribe(response => {
      let index = this.timelines.findIndex(obj => obj.id === this.editTimeline.id);
      this.timelines.splice(index, 1);
      this.totalTimelines = this.totalTimelines - 1;
      this.editTimeline = {};
    })
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  directToProfile(post: any){
    if (post.creator_info.user_id === this.user.id) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['profile/' + post.creator_info.user_id + '/']);
    }
  }

}
