import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewChecked  } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-message-chat',
  templateUrl: './message-chat.component.html',
  styleUrls: ['./message-chat.component.scss']
})
export class MessageChatComponent implements OnInit, AfterViewChecked  {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  modalRef: BsModalRef;
  currentConversation : any = {};
  searchQuery: string;
  messages = [];
  conversations = [];
  public settingShow = true;
  public msgSetting = true;
  public checkSetting = true;
  public showMobileChatBox = false;
  messageForm: FormGroup;
  public showMsg = false;
  submitMsg: string;
  selectedMessages = [];
  tabActive = 1;


  constructor(private profileService: ProfileService, private router: Router, private route: ActivatedRoute,
    private modalService: BsModalService, public formBuilder: FormBuilder) {
    this.messageForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
    });
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser.user_role !== 2 || currentUser.age_group == 1) {
      this.router.navigate(['/not-found']);
    }
  }

  ngOnInit(): void {
    this.getConversations();
    this.getRecentConversation();
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  getConversations(){
    this.profileService.getConversations()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.conversations = response.response;
        this.currentConversation = this.conversations[0];
      }
    })
  }

  getRecentConversation(){
    this.profileService.getRecentConversation()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.messages = response.response;
      }
    })
  }

  getConversation(conversation: any){
    this.profileService.getConversation(conversation.id)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.messages = response.response;
        this.resetRecentConv(this.messages[this.messages.length - 1]);
      }
    })
  }

  openChatBox(conversation: any) {
    this.showMobileChatBox = true;
    this.messages = [];
    this.currentConversation = conversation;
    this.getConversation(conversation);
  }
  removeChat(){
    this.showMobileChatBox = false;
  }

  resetRecentConv(message: any){
    let index = this.conversations.findIndex(obj => obj.id === this.currentConversation.id);
    this.conversations[index].modified_date = message.created_date;
    this.conversations[index].recent_message = message.text;
  }

  public resetChatBox(){
    let index = this.conversations.findIndex(obj => obj.id === this.currentConversation.id);
    this.conversations.splice(index, 1);
    if (this.conversations.length > 0){
      this.currentConversation = this.conversations[0];
      this.getConversation(this.currentConversation);
    }
  }

  sendText(isValid: boolean){
    if (isValid && this.currentConversation.id) {
      let model = {
        'conversation': this.currentConversation.id,
        'text': this.messageForm.get('text').value
      }
      this.profileService.sendMessage(model)
      .subscribe(response => {
        if (response.status === 200) {
          this.messageForm.reset();
          let message = response.response
          message.is_sender = true;
          let index = this.conversations.findIndex(obj => obj.id === this.currentConversation.id);
          this.conversations[index].modified_date = message.created_date;
          this.conversations[index].recent_message = message.text;
          this.messages.push(message);
        } else if (response.status === 112) {
          this.fadeOutMsg();
          this.showMsg = true;
          this.submitMsg = response.message;
          this.messageForm.reset();
        } else if (response.status === 111 && response.message && response.message['text']) {
          this.fadeOutMsg();
          this.showMsg = true;
          this.submitMsg = "Enter valid input";
          this.messageForm.reset();
        } else {
          this.fadeOutMsg();
          this.showMsg = true;
          this.submitMsg = "Something went wrong. Please try again later.";
        }
      })
    }
  }

  fadeOutMsg() {
    setTimeout( () => {
      this.showMsg = false;
      this.submitMsg = "";
    }, 6000);
   }

  deleteModal(deleteTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(deleteTemplate,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  toSelectMessages(isCancel: boolean = false){
    this.selectedMessages = [];
    this.checkSetting = !this.checkSetting;
    if (isCancel){
      this.msgSetting = !this.msgSetting;
    }
  }

  delConversation(){
    this.msgSetting = !this.msgSetting;
     this.profileService.deleteConversation(this.currentConversation.id)
     .subscribe(response => {
       this.modalRef.hide();
       this.handleResponse(response);
     }, error => {
        this.modalRef.hide();
        this.fadeOutMsg();
        this.showMsg = true;
        this.submitMsg = "Something went wrong. Please try again later.";
     })
  }

  handleResponse(response: any){
    if (response.status === 200){
      this.messages = [];
      this.resetChatBox();
    } else {
      this.fadeOutMsg();
      this.showMsg = true;
      this.submitMsg = "Something went wrong. Please try again later.";
    }
  }

  selectMessages(e: any, message: any){
    if (e.target.checked){
      this.selectedMessages.push(message.id);
    } else {
      let index = this.selectedMessages.findIndex(item => item === message.id);
      this.selectedMessages.splice(index, 1);
    }
  }

  delMessages(){
    this.msgSetting = !this.msgSetting;
    let model = {
      'messages': this.selectedMessages
    }
    this.profileService.deleteMessages(this.currentConversation.id, model)
    .subscribe(response => {
      this.modalRef.hide();
      this.handleMessageResponse(response);
    }, error => {
       this.modalRef.hide();
       this.fadeOutMsg();
       this.showMsg = true;
       this.submitMsg = "Something went wrong. Please try again later.";
    })
  }

  handleMessageResponse(response: any){
    this.selectedMessages = [];
    this.toSelectMessages();
    if (response.status === 200){
      if (response.response.length > 0){
        this.messages = response.response;
      } else {
        this.messages = [];
      }
    } else {
      this.fadeOutMsg();
      this.showMsg = true;
      this.submitMsg = "Something went wrong. Please try again later.";
    }
  }

}
