<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="studentSignUpInner">
                    <div class="SignUpInnerWrap WrapWhiteShadow">
                        <h1 class="hdg mb-4">Parent Verification</h1>
                        
                        <form [formGroup]="changeEmailForm" novalidate >
                            <div class="form-group inputRow row">
                                <div class="col-md-12">
                                    <input type="email" class="form-control signUpField" placeholder="Enter Your Email ID" formControlName="email">
                                    <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('required') && (changeEmailForm.get('email').dirty || changeEmailForm.get('email').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('aftersubmit')">
                                        {{changeEmailForm.controls.email.errors.aftersubmit}}
                                    </div>
                                    <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('pattern') && (changeEmailForm.get('email').dirty || changeEmailForm.get('email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                </div>
                            </div> 
                            <div class="form-group inputRow row">
                                <div class="col-md-12">
                                    <input type="password" class="form-control signUpField" placeholder="Enter Password" formControlName="password">
                                    <div class="error-message" *ngIf="changeEmailForm.get('password').hasError('required') && (changeEmailForm.get('password').dirty || changeEmailForm.get('password').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message" *ngIf="changeEmailForm.get('password').hasError('aftersubmit')">
                                        {{changeEmailForm.controls.password.errors.aftersubmit}}
                                    </div>
                                </div>
                            </div> 
                            <div class="form-group inputRow row">
                                <div class="col-md-12">
                                    <input type="email" class="form-control signUpField" placeholder="Enter Parent's Email ID" formControlName="parent_email">
                                    <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('required') && (changeEmailForm.get('parent_email').dirty || changeEmailForm.get('parent_email').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('aftersubmit')">
                                        {{changeEmailForm.controls.parent_email.errors.aftersubmit}}
                                    </div>
                                    <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('pattern') && (changeEmailForm.get('parent_email').dirty || changeEmailForm.get('parent_email').touched)">
                                        Please enter the email in a valid format.
                                    </div>
                                </div>
                            </div> 
                            <div class="form-group m-0 text-center">
                                    <button (click)="changeParentMail(successModal)" type="submit" class="btn btnSignUp">Confirm</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #successModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Parent Email Changed</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div text-left">
                    <h3>Your parent email ID has been changed successfully.</h3>
                    <p>A confirmation email has been sent to your Parent/Guardian at {{parentEmail}}. Please get your Parent’s/Guardian’s approval to access your account.</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
