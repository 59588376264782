import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BaseAccountEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
    public appConfig;
    constructor(private injector: Injector) { }

    loadAppConfig() {
        const http = this.injector.get(HttpClient);
        return http.get<any>(BaseAccountEndPoint.apiEndpoint + 'config')
        .toPromise()
        .then(data => {
          this.appConfig = data.response;
        });
    }
    get config() {
      return this.appConfig;
    }
}

export const appInitializer = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

