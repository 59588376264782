<section class="profile-head business-section newProfile greenTabs">
    <app-page-header [page]="pagesDetails" [profile]="profile"></app-page-header>
    <div class="d-block d-md-none pb-4">
        <ul class="socialBtns no-list justify-content-center">
            <li class="custom-tooltip">
                <span class="pageIDCopy cursor-pointer" (click)="copyPageID()">Page ID: ORG{{page_id}}</span>
                <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
            </li>

            <li *ngIf="isUnclaimed">
                <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                    <span class="cursor-pointer" id="button-basic2" dropdownToggle aria-controls="dropdown-basic2">
                        <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                    </span>
                    <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu"
                        role="menu" aria-labelledby="button-basic2">
                        <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                        <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                    </ul>
                  </div>
            </li>
            
            <li *ngIf="pagesDetails?.owner">
                <a href="javascript:void()" class="cursor-pointer" [ngClass]="{
                    'selected-tab': tabActive == 3
                  }" (click)="tabActive=3">
                  <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/verifyIcon.svg">
                  <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/verifyIconActive.svg">
                </a>
            </li>

            <li *ngIf="!isUnclaimed">
                <a href="javascript:void()" routerLink="/page/{{ page_id }}/" target="_blank">
                    <img alt="icon" class="hdg-icon" src="assets/images/eyeIconGreen.svg">
                </a>
            </li>

            <li *ngIf="pagesDetails?.owner">
                <a href="javascript:void()" [ngClass]="{
                    'selected-tab': tabActive == 4
                  }" (click)="tabActive=4">
                  <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                  <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/settingIconActive.svg">
                </a>
            </li>

            <li>
                <a href="javascript:void()" [ngClass]="{
                    'selected-tab': tabActive == 5
                  }" (click)="tabActive=5">
                  <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                  <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                </a>
            </li>
        </ul>
    </div>

    <div class="tabWrap">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12 col-md-5">
                    <ul class="tabBtns no-list">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                          }" (click)="tabActive=1">
                            details
                        </li>

                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                          }" (click)="tabActive=2;resetMembers()">
                            team
                        </li>
                    </ul>
                </div>
                <div class="d-none d-md-block col-md-7">
                    <ul class="socialBtns no-list">
                        <li class="custom-tooltip">
                            <span class="pageIDCopy cursor-pointer" (click)="copyPageID()">Page ID: ORG{{page_id}}</span>
                            <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
                        </li>

                        <li *ngIf="isUnclaimed">
                            <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                                <span class="cursor-pointer" id="button-basic" dropdownToggle aria-controls="dropdown-basic">
                                    <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                                </span>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                                    role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                                    <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                                </ul>
                              </div>
                        </li>
                        
                        <li *ngIf="pagesDetails?.owner">
                            <a href="javascript:void()" class="cursor-pointer" [ngClass]="{
                                'selected-tab': tabActive == 3
                              }" (click)="tabActive=3">
                              <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/verifyIcon.svg">
                              <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/verifyIconActive.svg">
                            </a>
                        </li>

                        <li *ngIf="!isUnclaimed">
                            <a href="javascript:void()" routerLink="/page/{{ page_id }}/" target="_blank">
                                <img alt="icon" class="hdg-icon" src="assets/images/eyeIconGreen.svg">
                            </a>
                        </li>

                        <li *ngIf="pagesDetails?.owner">
                            <a href="javascript:void()" [ngClass]="{
                                'selected-tab': tabActive == 4
                              }" (click)="tabActive=4">
                              <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                              <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/settingIconActive.svg">
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void()" [ngClass]="{
                                'selected-tab': tabActive == 5
                              }" (click)="tabActive=5">
                              <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                              <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-tab-dark">
        <div *ngIf="tabActive == 1">
            <div class="impactBlockOuter">
                <div class="impactBlock backWhite">
                    <div class="eachBlock mb-0">
                        <div class="topHdg">
                            <h3 class="hdgName">
                                <img *ngIf="linkArray && linkArray.length < 10" (click)="linkModal(linkTemplate)" class="hdg-icon cursor-pointer"
                                    src="assets/images/plus-teal.svg">
                                Links
                            </h3>
                        </div>
                        <div class="blockDes blockDesLinks">
                            <ngx-slick-carousel class="sliderContent custom-slick-arrow carousel mt-4"
                                #slickModal="slick-carousel" [config]="linksConfig">
                                <div class="image-with-del slide" *ngFor="let link of linkArray" ngxSlickItem>
                                    <a href="{{ link.link }}" target="_blank">
                                        <div class="mx-auto">
                                            <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}" class="icon img-70 mx-auto">
                                        </div>
                                    </a>
                                    <div class="dtl">
                                        <h3>{{ link.title }}</h3>
                                        <a href="javascript:void()" class="del-icon" (click)="delLink(link.id)">
                                            <img alt="icon" src="assets/images/newDelete.svg" />
                                        </a>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                </div>
            </div>

            <div class="timeLineBlock">
                <div class="allTimeLinesOuter">
                    <div class="addTimeFrame">
                        <form [formGroup]="timelineForm" novalidate>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Title" formControlName="title">
                                <div class="error-message"
                                    *ngIf="timelineForm.get('title').hasError('required') && (timelineForm.get('title').dirty || timelineForm.get('title').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('title').hasError('maxlength') && (timelineForm.get('title').dirty || timelineForm.get('title').touched)">
                                    Max 75 characters allowed.
                                </div>
                            </div>

                            <div class="isThisPost">
                                <span class="">Is this post a(n):</span>
                                <!-- <div class="radioPostBtnOuter">
                                    <p>Update / Reminder</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="1" id="end" (click)="radioActive=1" class="custom-control-input" name="post" checked />
                                        <label for="end" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box">
                                        <input type="radio" value="1" id="end" (click)="radioActive=1" class="custom-control-input" name="post" checked />
                                        <label for="end">Update / Reminder</label>
                                </div>
                                <!-- <div class="radioPostBtnOuter">
                                    <p>job</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="2" id="end2" (click)="getRecentJobs();radioActive=2" class="custom-control-input" name="post" />
                                        <label for="end2" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box">
                                        <input type="radio" value="2" id="end2" (click)="getRecentJobs();radioActive=2" class="custom-control-input" name="post" />
                                        <label for="end2">Job</label>
                                </div>
                                <!-- <div class="radioPostBtnOuter">
                                    <p>volunteer</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="3" id="end3" (click)="getRecentVolunteerJobs();radioActive=3" class="custom-control-input" name="post" />
                                        <label for="end3" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box mr-0">
                                        <input type="radio" value="3" id="end3" (click)="getRecentVolunteerJobs();radioActive=3" class="custom-control-input" name="post" />
                                        <label for="end3">Volunteer</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <ckeditor [editor]="Editor" formControlName="description" 
                                    [config]="{ toolbar: ['bold', 'italic'], placeholder: 'Write An Update', baseFloatZIndex:100001}">
                                </ckeditor>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('description').hasError('required') && (timelineForm.get('description').dirty || timelineForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="timelineForm.get('description').hasError('maxlength') && (timelineForm.get('description').dirty || timelineForm.get('description').touched)">
                                    Max 2250 characters allowed.
                                </div>
                                <div class="error-message" *ngIf="timelineForm.get('description').hasError('aftersubmit')">
                                    {{timelineForm.controls.description.errors.aftersubmit}}
                                </div>
                            </div>
                            <div class="timeFrameBtns">
                                <div class="leftBtns">
                                    <div class="leftBtnsInner">
                                        <div class="linkTimeLine mr-2 mb-2">
                                            <a href="javascript:void();" (click)="addTimelineLinkModal(addTimelineLink)"><img class="linkLogoSize" alt="icon" src="/assets/images/linkIcon.svg" /></a>
                                        </div>
                                        <div class="filesTimeLine custom-file-upload mr-2 mb-2" *ngIf="timelineDocs.length < 5">
                                            <img alt="icon" src="assets/images/fileIcon.svg" />
                                            <input type="file" class="uploadInput" (change)="uploadTimelineDoc($event)" #timelineInp>
                                        </div>
                                        <div class="forUploadedImages">
                                            <div class="forUploadedImagesInner mr-3 mb-2" *ngFor="let timelineDoc of timelineDocs; index as i">
                                                <img alt="icon" class="uploadedPreview" *ngIf="isImage(timelineDoc.document_name)" src="{{timelineDoc.previewImg}}" />
                                                <img alt="icon" class="uploadedPreview" *ngIf="!isImage(timelineDoc.document_name)" src="assets/images/pdf-icon.svg" />
                                                <img alt="icon" class="previewDelete" src="assets/images/cross-modal.svg" (click)="remTimelineDoc(i)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="coverImageUploadError" class="error-message">
                                        {{coverImageUploadMessage}}
                                    </div>
                                    <div *ngIf="coverImageUploadSizeError" class="error-message">
                                        {{coverImageUploadSizeMessage}}
                                    </div>
                                </div>
                                <div class="rightBtns">
                                    <button class="btn btn-colored" (click)="createTimeline(timelineForm.valid)">Post</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="radioActive == 1"> 
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="timelines.length > 0">
                            <div *ngFor="let timeline of timelines" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                                <div class="timeLineActions mt-2">
                                    <a href="javascript:void(0);" (click)="delTimelineModal(delTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/newDelete.svg" />
                                        <span>Delete</span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="editTimelineModal(editTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/icEditNew.svg" />
                                        <span>edit</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="timelineNextPage == 1 && totalTimelines > 3 && totalTimelines > timelines.length">
                            <a href="javascript:void(0);" (click)="loadMoreTimelines()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="timelineNextPage > 1 && totalTimelines > 10 && totalTimelines > timelines.length">
                            <a href="javascript:void(0);" (click)="loadMoreTimelines()">Load More</a>
                        </div>
                    </div>

                    <div *ngIf="radioActive == 2">
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="jobs.length > 0">
                            <div *ngFor="let timeline of jobs" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                                <div class="timeLineActions mt-2">
                                    <a href="javascript:void(0);" (click)="delTimelineModal(delTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/newDelete.svg" />
                                        <span>Delete</span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="editTimelineModal(editTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/icEditNew.svg" />
                                        <span>edit</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="jobNextPage == 1 && totalJobs > 3 && totalJobs > jobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreJobs()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="jobNextPage > 1 && totalJobs > 10 && totalJobs > jobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreJobs()">Load More</a>
                        </div>
                    </div>

                    <div *ngIf="radioActive == 3">
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="volunteerJobs.length > 0">
                            <div *ngFor="let timeline of volunteerJobs" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                                <div class="timeLineActions mt-2">
                                    <a href="javascript:void(0);" (click)="delTimelineModal(delTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/newDelete.svg" />
                                        <span>Delete</span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="editTimelineModal(editTimelineTemplate, timeline)">
                                        <img alt="icon" class="actionImg" src="/assets/images/icEditNew.svg" />
                                        <span>edit</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="volunteerNextPage == 1 && totalVolunteerJob > 3 && totalVolunteerJob > volunteerJobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreVolunteerJobs()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="volunteerNextPage > 1 && totalVolunteerJob > 10 && totalVolunteerJob > volunteerJobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreVolunteerJobs()">Load More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="tabActive == 2">
            <div class="initiativeTeamBlock">
                <div class="initiativeTeamSlider mb-4 mb-md-5">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">members</h3>
                    </div>
                    <div class="teamSearchInputWrap">
                        <div class="teamSearchInputWrapInner">
                            <div class="leftWrap">
                                <div class="form-group" *ngIf="teamMember && teamMember.length > 0">
                                    <input type="text" #searchInp placeholder="Search by name" class="form-control" (keyup)="searchStudent($event)">
                                </div>
                            </div>
                            <div class="rightWrap">
                                <p class="des">Is someone from this team missing? 
                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                        <img alt="icon" class="iconInvite ml-2 mr-1" src="./assets/images/verifiedUser.svg" />
                                        <span>Invite</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="scroll-activity scrollbar teamListScroll">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let member of teamMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember">
                                        <img alt="icon" class="bannerImg" src="{{member.student_info.header_image ? member.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                                    </div>
                                    <div class="profileMember">
                                        <img alt="icon" class="profileImg" src="{{member.student_info.display_pic ? member.student_info.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{ member.student_info.student_name }}</h1>
                                        <h3 class="subName">{{member.position}}</h3>
                                        <h3 class="subName">
                                            {{ member.start_date }}<span *ngIf="member.end_date"> - {{member.end_date}}</span>
                                            <span *ngIf="!member.end_date"> - Present</span>
                                        </h3>
                                        <div class="memberActions" *ngIf="user.id != member.student_info.id && (user.age_group == 2 || user.age_group == member.student_info.age_group)">
                                            <button class="btn common-btn btnMessage" (click)="sendMessage(member.student_info.id)">Message</button>
                                            <button class="btn common-btn btnProfile" *ngIf="user.approval_status" routerLink="/profile/{{ member.student_info.id }}/">Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="teamLoadMore()">Load More</a>
                    </div>
                </div>

                <div class="initiativeVolunteerAdd pt-0 pb-0">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Leaders</h3>
                    </div>
                </div>

                <div class="initiativeVolunteerAdd pt-0">

                    <form [formGroup]="managementForm" novalidate>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input type="text" placeholder="First Name" class="form-control text-capitalize" formControlName='firstName'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('firstName').hasError('required') && (managementForm.get('firstName').dirty || managementForm.get('firstName').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('firstName').hasError('maxlength') && (managementForm.get('firstName').dirty || managementForm.get('firstName').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
    
                            <div class="col-md-6 form-group">
                                <input type="text" placeholder="Last Name" class="form-control text-capitalize" formControlName='lastName'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('lastName').hasError('required') && (managementForm.get('lastName').dirty || managementForm.get('lastName').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('lastName').hasError('maxlength') && (managementForm.get('lastName').dirty || managementForm.get('lastName').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
    
                            <div class="col-md-6 form-group">
                                <input type="text" placeholder="Position/Role" class="form-control" formControlName='position'/>
                                <div class="error-message"
                                    *ngIf="managementForm.get('position').hasError('required') && (managementForm.get('position').dirty || managementForm.get('position').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="managementForm.get('position').hasError('maxlength') && (managementForm.get('position').dirty || managementForm.get('position').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <button class="btn common-btn" (click)="addManagement(managementForm.valid)">Add</button>
                            </div>
                        </div>
                    </form>
                    
                    <div class="managementWithRole">
                        <ul class="no-list">
                            <li class="row" *ngFor="let volunteer of managementList">
                                <div class="col-md-6">
                                    <h3>{{ volunteer.first_name }} {{ volunteer.last_name | slice:0:1 }}.</h3>
                                    <p>{{ volunteer.position }}</p>
                                </div>
                                <div class="col-md-6">
                                    <span class="cursor-pointer" (click)="deleteVol(volunteer.id)">
                                        <img alt="icon" src="assets/images/newDelete.svg">
                                    </span>
                                </div>
                            </li>
                        </ul>

                        <div class="timeLineLoad pt-4" *ngIf="totalManagements > 10 && totalManagements > managementList.length">
                            <a href="javascript:void(0);" (click)="laodMoreManagements()">Load More</a>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div *ngIf="tabActive == 3">
            <app-page-experiences></app-page-experiences>
        </div>

        <div *ngIf="tabActive == 4">
            <app-page-setting></app-page-setting>
        </div>

        <div *ngIf="tabActive == 5">
            <app-need-help></app-need-help>
        </div>
    </div>

</section>

<ng-template #addTimelineLink>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Link</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row m-0">
            <div class="col-md-12">
                <form [formGroup]="timelinelinkForm" novalidate>
                    <input type="text" placeholder="Type Here" class="form-control" formControlName="link">
                    <div class="error-message"
                        *ngIf="timelinelinkForm.get('link').hasError('maxlength') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
                        Max 250 characters allowed.
                    </div>
                    <div class="error-message"
                        *ngIf="timelinelinkForm.get('link').hasError('pattern') && (timelinelinkForm.get('link').dirty || timelinelinkForm.get('link').touched)">
                        Please enter a valid URL.
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="removeLink(); modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="setLink(timelinelinkForm.valid)">save</button>
    </div>
</ng-template>
<ng-template #linkTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add links</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="linkForm" novalidate>
                <div class="media">
                    <div class="imgSecPopup">
                        <img alt="image" class="preview-image"
                            src="{{previewThumbnail? previewThumbnail :'assets/images/default.svg'}}">
                        <ul *ngIf="previewThumbnail" class="d-flex imgIcon pl-0 m-0 no-list">
                            <li>
                                <a href="javascript:void()" (click)="blankSupportImageUpload(linkModal)">
                                    <img alt="icon" src="assets/images/newDelete.svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="media-body ml-3">
                        <div class="form-group">
                            <label for="">Add Cover Image</label>
                            <input type="file" placeholder="Type Here" class="fileUpload"
                                (change)="coverImageUpload($event)" #linkModal>
                            <span class="fileText cursor-pointer">Choose File</span>
                            <div *ngIf="coverImageUploadError" class="error-message">
                                {{coverImageUploadMessage}}
                            </div>
                            <div *ngIf="coverImageUploadSizeError" class="error-message">
                                {{coverImageUploadSizeMessage}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Title <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Type Here"
                                        formControlName="title" />
                                    <div class="error-message"
                                        *ngIf="linkForm.get('title').hasError('required') && (linkForm.get('title').dirty || linkForm.get('title').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="linkForm.get('title').hasError('maxlength') && (linkForm.get('title').dirty || linkForm.get('title').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="">Link <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Type Here"
                                        formControlName="link" />
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('required') && (linkForm.get('link').dirty || linkForm.get('link').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('maxlength') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Max 250 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="linkForm.get('link').hasError('pattern') && (linkForm.get('link').dirty || linkForm.get('link').touched)">
                                        Please enter a valid URL.
                                    </div>
                                    <div class="error-message" *ngIf="linkForm.get('link').hasError('aftersubmit')">
                                        Please enter a valid URL.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="addLink(linkForm.valid)">save</button>
    </div>
</ng-template>
<ng-template #editTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">update 
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Job</span>
            <span *ngIf="radioActive == 3">Volunteer</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="timelineUpdForm" novalidate>
                <ckeditor [editor]="Editor" formControlName="description"
                    [config]="{ toolbar: [ 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                </ckeditor>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('required') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="timelineUpdForm.get('description').hasError('maxlength') && (timelineUpdForm.get('description').dirty || timelineUpdForm.get('description').touched)">
                    Max 2250 characters allowed.
                </div>
                <div class="error-message" *ngIf="timelineUpdForm.get('description').hasError('aftersubmit')">
                    {{timelineUpdForm.controls.description.errors.aftersubmit}}
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" (click)="updateTimeline(timelineUpdForm.valid)">update</button>
    </div>
</ng-template>
<ng-template #delTimelineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete 
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Job</span>
            <span *ngIf="radioActive == 3">Volunteer</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to delete this <span *ngIf="radioActive == 1">timeline</span>
                <span *ngIf="radioActive == 2">job</span>
                <span *ngIf="radioActive == 3">volunteer</span> ?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="delTimelineElem(); this.modalRef.hide();">
            Confirm
        </button>
    </div>
</ng-template>
<ng-template #timelineDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Job</span>
            <span *ngIf="radioActive == 3">Volunteer</span> detail</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="timeLinesEach">
            <div class="timelineTopHdr">
                <h3>{{editTimelineObj.title}}</h3>
                <p>Posted on {{editTimelineObj.created_date | date:'MMMM d, y'}}</p>
            </div>
            <p class="timelineDes" innerHtml="{{editTimelineObj.description}}"></p>
            <p class="metaLink" *ngIf="!checkExtraData(editTimelineObj)">
                <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
            </p>
            <div class="singleTimelineDetail" *ngIf="checkExtraData(editTimelineObj)">
                <div class="imageBox">
                    <img alt="timeline" class="timeLineImg" 
                    src="{{editTimelineObj.metadata_image_link? editTimelineObj.metadata_image_link : 'assets/images/default.svg'}}" />
                </div>
                <div class="dtlOuter">
                    <h1>{{editTimelineObj.metadata_title}}</h1>
                    <p>{{editTimelineObj.metadata_description}}</p>
                    <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
                </div>
            </div>
            <div class="allTimeImages" *ngIf="editTimelineObj.timeline_documents.length > 0">
                <div class="imageOuter" *ngFor="let document of editTimelineObj.timeline_documents">
                    <a href="{{document.document}}" target="_blank">
                        <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                        <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
    </div>
</ng-template>
<ng-template #settingModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Report Page</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="blockReportForm">
                <div class="reportShow">
                    <div class="form-group">
                        <label>Reason</label>
                        <select class="form-control custom-select" formControlName="report_reason">
                            <option value=1>Hate Speech</option>
                            <option value=2>Bullying and harrassment of yourself or others</option>
                            <option value=3>Direct threats</option>
                            <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)</option>
                            <option value=5>Other</option>
                        </select>
                    </div>
                    <div class="error-message"
                        *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="report_description"></textarea>
                        <div class="error-message"
                            *ngIf="blockReportForm.get('report_description').hasError('maxlength') && (blockReportForm.get('report_description').dirty || blockReportForm.get('report_description').touched)">
                            Max 1000 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="reportUser(blockReportForm.valid)">
            Save
        </button>
    </div>
</ng-template>

<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                <p class="inviteCopySbNote">Join my network at {{pagesDetails?.page_info?.title}} on ConnectUs.today by signing up using the following link: <strong>connectus.today/signin</strong></p>
                <div class="inviteBorder">
                    <div class="custom-tooltip copy-tooltip">
                        <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                        <div class="tooltip-data" *ngIf="copyMessageBool">Message Copied</div>
                    </div>
                </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="modalRef.hide()">Done</button>
    </div>
</ng-template>