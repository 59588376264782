<div class="customSearchWrap">
    <div class="searchFieldWrap">
        <div class="container">
            <form [formGroup]="searchForm" novalidate>
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                            <input type="text" placeholder="Search" class="form-control searchField" formControlName="search_key">
                            <div class="error-message"
                                *ngIf="searchForm.get('search_key').hasError('whitespace') && (!searchForm.get('search_key').hasError('required')) && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                Please enter valid data
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('search_key').hasError('maxlength') && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                Max 50 characters allowed.
                            </div>
                            <div class="error-message" *ngIf="keywordsErr">
                                Max 3 search keywords allowed.
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3">
                        <div class="form-group pl-0 pl-lg-4">
                            <input type="text" placeholder="Location" class="form-control locationField" formControlName="location">
                            <div class="error-message"
                                *ngIf="searchForm.get('location').hasError('maxlength') && (searchForm.get('location').dirty || searchForm.get('location').touched)">
                                Max 50 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="form-group pl-0 pl-lg-4">
                            <button type="submit" class="common-btn" (click)="search(searchForm.valid)">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="searchKeys.length > 0" class="d-flex align-items-center flex-wrap">
                <ul class="d-flex no-list chipsListing">
                    <li *ngFor="let searchKey of searchKeys">
                        <a href="javascript:void();">{{searchKey}} </a>
                        <span class="float-right cursor-pointer" (click)="delKey(searchKey)">x</span>
                    </li>
                </ul>
                <button class="common-btn ghost mt-1" (click)="delKey('')">Clear Filter</button>
            </div>
        </div>
    </div>


    <div class="searchTabsWrap">
        <div class="container">
            <ul class="borderTabs no-list">
                <li class="cursor-pointer" [ngClass]="{
                    'selected-tab': tabActive == 1
                }" (click)="tabActive=1">
                    <span>initiatives</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="searchResultsWrap">
        <div *ngIf="tabActive == 1">
            <div class="container">
                <div class="searchScroll">
                    <div *ngIf="initiatives.length > 0" class="searchResultOuter w-100">
                        <div class="eachResult cursor-pointer" *ngFor="let initiative of initiatives" (click)="redirectToInitiative(initiative)">
                            <div class="topImages">
                                <img alt="banner-image" class="bannerImage" src="{{initiative.header_image ? initiative.header_image : '/assets/images/new-header-img.png'}}" />
                                <img alt="profile-image" class="profileImage" src="{{initiative.display_picture ? initiative.display_picture : '/assets/images/new-header-img.png'}}" />
                            </div>
    
                            <div class="btmDetails">
                                <h1 class="searchName">{{ initiative.title }}</h1>
                                <div class="searchTags" *ngIf="initiative.keywords && initiative.keywords.length > 0">
                                    <span class="btn btn-dark-badge" *ngFor="let keyword of initiative.keywords | slice:0:3">{{ keyword }}</span>
                                </div>
                                <div class="searchLocation">
                                    <p class="m-0">
                                        <img alt="icon" class="locationIcon" src="/assets/images/location-icon.png" />
                                        <span>{{ initiative.city }}, {{ initiative.state }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="initiatives.length == 0" class="noResultFound">
                        <h5 class="m-0">No Result(s) Found</h5>
                    </div>
                    <div *ngIf="totalInitiatives > 21 && totalInitiatives > initiatives.length"
                        class="next-listing text-center mt-4 pb-4 timeLineLoad">
                        <a href="javascript:void(0);" (click)="onScrollDown()">Load More</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>







