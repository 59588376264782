<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="studentSignUpInner">
                    <div class="SignInInnerWrap WrapWhiteShadow mb-0">
                    <h1 class="hdg mb-4">Reset Password</h1>
                    <div *ngIf="!hideform">
                        <form [formGroup]="passwordForm" novalidate (ngSubmit)="submit(passwordForm.valid)">
                            <div class="form-group inputRow row">
                                <div class="col-md-12">
                                    <input type="password" class="form-control signUpField"
                                        placeholder="Enter New Password" formControlName="password">
                                    <div class="error-message"
                                        *ngIf="passwordForm.get('password').hasError('required') && (passwordForm.get('password').dirty || passwordForm.get('password').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="passwordForm.get('password').hasError('maxlength') && (passwordForm.get('password').dirty || passwordForm.get('password').touched)">
                                        Max 15 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="passwordForm.get('password').hasError('invalidPassword') && (passwordForm.get('password').dirty || passwordForm.get('password').touched)">
                                        Your password needs to be at least 8 characters, including a Capital
                                        Letter, Lowercase Letter and a Number.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group inputRow row">
                                <div class="col-md-12">
                                    <input type="password" class="form-control signUpField"
                                        placeholder="Confirm Password" formControlName="confirm_password">
                                    <div class="error-message"
                                        *ngIf="passwordForm.get('confirm_password').hasError('required') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="passwordForm.get('confirm_password').hasError('minlength') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched)">
                                        Your password needs to be at least 8 characters, including a Capital
                                        Letter, Lowercase Letter and a Number.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="passwordForm.hasError('passwordNotMatch') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched)">
                                        Passwords do not match.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-0 text-center pt-2">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btnSignUp">Confirm</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <p class="text-center success-msg" *ngIf="showMessage">Password has been changed
                        successfully.</p>
                    <span class="text-center success-msg expiredText my-3 d-block" *ngIf="showError">Uh Oh! This
                        link has expired. Go to Main Page.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
