import { Component, OnInit, TemplateRef, Input, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommentsService } from '../../services/comments.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-replies',
  templateUrl: './replies.component.html',
  styleUrls: ['./replies.component.scss']
})
export class RepliesComponent implements OnInit {
  @Input() editFeedId: any;
  @Input() editFeedPostType: any;
  @Input() commentId: any;
  @Input() notifier: Subject<any>;
  @Output() replyAdded = new EventEmitter<any>();
  modalRef: BsModalRef;
  user: any = {};
  replies = [];
  newReplies = [];
  replySubmitted: boolean = false;
  replyForm: FormGroup;
  replyNextPage: any = 1;
  totalReplyCount: number = 0;
  feedId: any;
  postType: any;
  cleanData: any;
  taggedUsers = [];
  teams: any[];
  editReplyId: any;

  constructor(private modalService: BsModalService, public commentService: CommentsService, public formBuilder: FormBuilder) {
    this.replyForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required, this.noWhitespaceValidator])
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.feedId = this.editFeedId;
    this.postType = this.editFeedPostType;
    setTimeout(() => {
      this.getReplies();
      this.getNetwork();
    });
    this.notifier.subscribe(data => {
      this.replyCreated(data);
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  openReply(replyModal: TemplateRef<any>, creator_info: any) {
    this.replySubmitted = false;
    this.replyForm.reset();
    let item = creator_info;
    item['id'] = creator_info.user_id;
    this.format(item);
    this.replyForm.controls['text'].setValue('@'+item.name+' ');
    this.modalRef = this.modalService.show(replyModal, Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal comment-modal' }));
  }

  createReply(isValid: boolean){
    this.replySubmitted = true;
    if (isValid) {
      this.formatData(this.replyForm.get('text').value);
      let model = {
        'text': this.cleanData,
        'post_type': this.postType,
        'timeline_id': this.feedId
      }
      this.commentService.addReply(this.commentId, model)
      .subscribe(response => {
        this.replyForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.replySubmitted = false;
        if (response.status == 200) {
          this.replyCreated(response.response);
          this.replyAdded.emit(true);
          this.modalRef.hide();
        } else {
          this.replyForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
        }
      }, error => {
        this.replyForm.reset();
        this.cleanData = null;
        this.taggedUsers = [];
        this.replySubmitted = false;
        this.replyForm.get('text').setErrors({aftersubmit:'Uh Oh! Something went wrong. Please try again later.'});
      });
    }
  }

  chngFormat = (item): any => {
    this.format(item);
    return '@'+item.name+' ';
  }

  format = (item) => {
    let dataLink = ' <a href="/profile/' + item.id + '/">' + item.name + '</a>&nbsp;'
    let data = {'name': item.name, 'id': item.id, 'data': dataLink};
    this.taggedUsers.push(data);
  }

  formatData(data: any){
    this.cleanData = data;
    for (let tag of this.taggedUsers) {
      let to_replace = '@'+tag['name'];
      this.cleanData = this.cleanData.replace(to_replace, tag['data']);
    }
  }

  getReplies(){
    this.newReplies = [];
    if (this.replyNextPage) {
      let params = '?page=' + this.replyNextPage;
      this.commentService.getReplies(this.commentId, this.postType, params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.replyNextPage == 1 || this.replyNextPage === null) {
            this.replies = response.response.results;
          } else {
            this.replies = this.replies.concat(response.response.results);
          }
          this.replyNextPage = response.response.next;
          this.totalReplyCount = response.response.total_count;
        } else {
          this.replies = [];
          this.replyNextPage = 1;
          this.totalReplyCount = 0;
        }
      });
    }
  }

  getNetwork(){
    this.commentService.getUserNetwork()
    .subscribe(response => {
      if (response.status == 200) {
        this.teams = response.response;
      } else {
        this.teams = [];
      }
    }, error => {
      this.teams = [];
    });
  }

  deleteReply(){
    this.commentService.deleteReply(this.editReplyId)
    .subscribe(response => {
      this.replyNextPage = 1;
      this.getReplies();
      this.modalRef.hide();
    });
  }

  openDeleteModal(id: any, template: TemplateRef<any>) {
    this.editReplyId = id;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal delete-modal-popup'})
    );
  }

  replyCreated(data){
    let index = this.replies.findIndex(obj => obj.id == data.id);
    if (index == -1 && this.replies.length > 0) {
      this.newReplies.push(data);
    }
  }

}
