import { Component, OnInit, TemplateRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll'
import { PageService } from '../../services/page.service';
import { EventService } from '../../services/event.service';
import { IEvent } from 'src/app/shared/event';
import { CarouselConfig } from 'ngx-bootstrap';
import { AuthenticationService } from '../../services/authentication.service';
import { Location } from '@angular/common';
import { TeamService } from '../../services/team.service';
import { CustomvalidationService } from '../../services/customvalidation.service';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { SlickCarouselComponent } from "ngx-slick-carousel";

const colors: any = {
  calColor: {
    primary: '#339999 ',
    secondary: '#339999',
  }
};

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class BusinessProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  @ViewChild("searchInp") searchInp: ElementRef;
  viewDate: Date = new Date();
  modalRef: BsModalRef;
  user: any = {};
  aboutUs: any;
  tabActive = 1;
  events: CalendarEvent[] = [];
  responseEvent: IEvent[] = [];
  refresh: Subject<any> = new Subject();
  profile: any = {};
  uploadForm: FormGroup;
  isClubTeam: boolean = false;
  public infoDiv1 = true;
  page_id: any;
  pagesDetails: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  page_Image_name : string = "";
  page_Image: string = "";
  pageHeaderImg: string = "";
  pageHeaderImgName: string = "";
  activeDayIsOpen: boolean = false; 
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  linkArray: any = [];
  teamMembers: any = [];
  teamMember: any = [];
  teamMemberSliced: any = [];
  slicedItem = 6;
  teamLoadMoreBool: boolean = false;
  messageError: any;
  radioActive = 1;
  jobs: any = [];
  volunteerJobs: any = [];
  timelines: any = [];
  timelineDocs: any = [];
  editTimelineObj: any = {};
  totalTimelines: any = 0;
  timelineNextPage: any = 1;
  totalJobs: any = 0;
  jobNextPage: any = 1;
  volunteerNextPage: any = 1;
  totalVolunteerJob: any = 0;
  volunteerJobNextPage: any = 1;
  public isUnclaimed: boolean = false;
  managementList: any = [];
  totalManagements: any;
  nextManagement: any;
  weekEvents: any = [];
  copyPageIDBool: boolean = false;
  isOwner: boolean = false;
  blockReportForm: FormGroup;
  public submitted: boolean = false;
  encrypt_email: any;
  encrypt_page_id: any;
  searchQuery: string = "";
  invitationForm: FormGroup;
  invitationSuccess: boolean = false;
  followBtnErr: boolean = false;
  teamMemberNextPage: any = 1;
  totalTeamMembers: any = 0;
  selectedUser: any;
  
  constructor(private eventService: EventService, private router: Router, private location: Location, private modalService: BsModalService, private route: ActivatedRoute, private profileService: ProfileService, private authService: AuthenticationService,
    private pageService: PageService, public formBuilder: FormBuilder, private teamService: TeamService, private customValidator: CustomvalidationService) {
      this.blockReportForm = this.formBuilder.group({
        report_reason: new FormControl('', [Validators.required]),
        report_description: new FormControl('', [Validators.maxLength(1000)])
      });
      this.invitationForm = this.formBuilder.group({
        invitations: this.formBuilder.array([], [Validators.required, this.maxLengthArray(10)])
      });
    }

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.pageService.getPageDetails(this.page_id)
        .subscribe(response => {
          if (response.status === 200) {
            this.pagesDetails = response.response;
            if (this.pagesDetails.owner){
              this.isUnclaimed = false;
            } else {
              this.isUnclaimed = true;
            }
            this.page_Image = this.pagesDetails.display_pic;
            this.page_Image_name = this.pagesDetails.display_pic;
            this.pageHeaderImg = this.pagesDetails.header_img;
            this.pageHeaderImgName = this.pagesDetails.header_img;
            if (this.pagesDetails.page_type == 2){
              this.getTeamMembers();
              this.getRecentTimeline();
            } else if (this.pagesDetails.page_type == 3 || this.pagesDetails.page_type == 4 ) {
              this.isClubTeam = true;
              this.getEvents();
            } else {
              this.getTeamMembers();
              this.getRecentTimeline();
            }
          } else {
            this.router.navigate(['/dashboard']);
          }
      });
    this.pageService.getPageDescription(this.page_id)
    .subscribe(response => {
      if(response.response['id'] !== undefined){
        this.profile = response.response;
        this.aboutUs = response.response.about_us;
      }
    });
    this.getLink();
    this.getManagement();
    this.checkPageUser();
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive":[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  linksConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,  
    "infinite": false,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  teamsConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,  
    "infinite": true,
    "arrow": true,
    "autoplay": false,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get invitations() {
    return this.invitationForm.get('invitations') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    });
  }

  addItem() {
    this.submitted = false;
    if (this.invitationForm.valid) {
      this.invitations.push(this.createItem());
    } else {
      this.submitted = true;
    }
  }

  removeItem(i:number) {
    this.invitations.removeAt(i);
  }

  maxLengthArray(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length <= max)
            return null;
        return { 'maxLengthArray': {valid: false }};
    }
  }

  getWeeklyEvents(){
    this.eventService.getWeekEvent(this.page_id)
    .subscribe(response => {
      this.weekEvents = response.response;
    });
  }

  getRecentTimeline(){
    this.profileService.pageAllRecentTimeline(this.page_id)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        this.timelines = response.response.results;
        this.totalTimelines = response.response.total_count;
      } else {
        this.timelines = [];  
      }
    });
  }

  getRecentJobs(){
    if (this.jobs.length == 0) {
      this.profileService.getRecentJob(this.page_id)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.jobs = response.response.results;
          this.totalJobs = response.response.total_count;
        } else {
          this.jobs = [];
        }
      });
    }
  }

  getRecentVolunteerJobs(){
    if (this.volunteerJobs.length == 0) {
      this.profileService.getRecentVolunteer(this.page_id)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          this.volunteerJobs = response.response.results;
          this.totalVolunteerJob = response.response.total_count;
        } else {
          this.volunteerJobs = [];
        }
      });
    }
  }

  checkPageUser(){
    this.pageService.checkPageUser(this.page_id)
    .subscribe(response => {
      if (response.status === 200) {
        this.isOwner = response.response.is_owner;
        this.encrypt_email = response.response.encrypt_email;
        this.encrypt_page_id = response.response.encrypt_page_id;
      } else {
        this.isOwner = false;
      }
    })
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true
    } else {
      return false
    }
  }

  openDetail(editModal: TemplateRef<any>) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(editModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  openModal(member: any, template: TemplateRef<any>) {
    this.selectedUser = member;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  timelineDetailModal(timelineDetail: TemplateRef<any>, timeline: any) {
    this.editTimelineObj = timeline;
    this.modalRef = this.modalService.show(timelineDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getEvents(){
    let events: CalendarEvent[] = [];
    let month = (this.viewDate.getMonth() + 1).toString();
    let year = this.viewDate.getFullYear().toString();
    this.eventService.GetOtherUserEventList(this.page_id, month, year)
    .subscribe(response => {
      this.responseEvent = response.response;
      let today = new Date();
      this.responseEvent.forEach(day_event => {
        let start_date = day_event.start_date.split("-");
        let start_date_day = Number(start_date[2]);
        let start_date_month = Number(start_date[1]) - 1;
        let start_date_year = Number(start_date[0]);
        let end_date = day_event.start_date.split("-");
        let end_date_day = Number(end_date[2]);
        let end_date_month = Number(end_date[1]) - 1;
        let end_date_year = Number(end_date[0]);
        let start = new Date(start_date_year, start_date_month, start_date_day);
        let end = new Date(end_date_year, end_date_month, end_date_day);
        if (today.toDateString() == start.toDateString() || today.toDateString() == end.toDateString()){
          this.activeDayIsOpen = true;
        }
        events.push({
          'start': start, 
          'end': end,
          'title': day_event.title,
          'color': colors.calColor,
          'meta': {
            'id': day_event.id
          }
        })
      })
      this.events = events;
      this.refresh.next();
    })
  }

  backClicked(){
    this.location.back();
  }

  getLink(){
    this.pageService.getLinks(this.page_id)
    .subscribe(response => {
      if (response.response){
        this.linkArray = response.response
      }
    });
  }

  getTeamMembers(){
    this.pageService.teamMembers(this.page_id)
    .subscribe(response => {
      if(response.status == 200){
          this.teamMember = response.response.experiences;
          this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
          if (this.teamMember.length > this.teamMemberSliced.length){
            this.teamLoadMoreBool = true;
          }
        }
      });
  }

  teamLoadMore(){
    let totalMember = this.teamMember.length;
    let slicedMember = this.teamMemberSliced.length;
    if (slicedMember < totalMember){
      this.teamMemberSliced = this.teamMember.slice(0, slicedMember+this.slicedItem)
    }
    if (totalMember > this.teamMemberSliced.length){
      this.teamLoadMoreBool = true;
    } else {
      this.teamLoadMoreBool = false;
    }
  }

  sendMessage(memberID){
    this.profileService.createConversation({"user2": memberID})
    .subscribe(response => {
      if(response.status == 200) {
        this.router.navigate(['message-chat']);
      } else if (response.status < 200) {
        this.messageError = response.message;
      } else {
        this.messageError = "Something went wrong. Please try again later.";
      }
    });
  }

  loadMoreTimelines(){
    let params = '?page=' + this.timelineNextPage;
    if (this.isClubTeam){
      this.profileService.pageAllSchoolTimeline(this.page_id, params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.timelineNextPage == 1) {
            this.timelines = response.response.results;
          } else {
            this.timelines = this.timelines.concat(response.response.results);
          }
          this.timelineNextPage = response.response.next;
          this.totalTimelines = response.response.total_count;
        }
      });
    } else {
      this.profileService.pageAllTimeline(this.page_id, params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.timelineNextPage == 1) {
            this.timelines = response.response.results;
          } else {
            this.timelines = this.timelines.concat(response.response.results);
          }
          this.timelineNextPage = response.response.next;
          this.totalTimelines = response.response.total_count;
        }
      });
    }
  }

  loadMoreJobs(){
    let params = '?page=' + this.jobNextPage;
    this.profileService.listJobs(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.jobNextPage == 1) {
          this.jobs = response.response.results;
        } else {
          this.jobs = this.jobs.concat(response.response.results);
        }
        this.jobNextPage = response.response.next;
        this.totalJobs = response.response.total_count;
      }
    });
  }

  loadMoreVolunteerJobs(){
    let params = '?page=' + this.volunteerNextPage;
    this.profileService.listVolunteerJobs(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.volunteerNextPage == 1) {
          this.volunteerJobs = response.response.results;
        } else {
          this.volunteerJobs = this.volunteerJobs.concat(response.response.results);
        }
        this.volunteerNextPage = response.response.next;
        this.totalVolunteerJob = response.response.total_count;
      }
    });
  }

  checkExtraData(timeline: any){
    if (timeline.link && timeline.metadata_title && (timeline.metadata_description || timeline.metadata_image_link)){
      return true;
    } else if (timeline.link && !timeline.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  getTeamItems(){
    this.resetSearch();
    if (this.totalManagements == 0) {
      this.getManagement();
    }
  }

  getMembers(){
    let params = '?';
    if (this.teamMemberNextPage > 1){
      params = params + '&page=' + this.teamMemberNextPage;
    }
    if (this.searchQuery && this.searchQuery.length > 2) {
      params = params + '&search=' + this.searchQuery;
    }
    this.profileService.UserSchoolInvolvementMembers(this.page_id, params)
    .subscribe(response => {
      if (response.response.results.length > 0) {
        if (this.teamMemberNextPage == 1 || this.teamMemberNextPage === null) {
          this.teamMembers = response.response.results;
        } else {
          this.teamMembers = this.teamMembers.concat(response.response.results);
        }
        this.teamMemberNextPage = response.response.next;
        this.totalTeamMembers = response.response.total_count;
      } else {
        this.teamMembers = [];
        this.teamMemberNextPage = 1;
        this.totalTeamMembers = 0;
      }
    });
  }

  getManagement(){
    let param = '';
    if (this.nextManagement && this.nextManagement != null){
      param = '?page=' + this.nextManagement;
    }
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = response.response.results;
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }

  laodMoreManagements(){
    let  param = '?page=' + this.nextManagement;
    this.pageService.getManagement(this.page_id, param)
    .subscribe(response => {
      this.managementList = this.managementList.concat(response.response.results);
      this.totalManagements = response.response.total_count;
      this.nextManagement = response.response.next;
    });
  }

  copyPageID(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = "ORG" + this.page_id;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copyPageIDBool = true;
    setTimeout(() => {this.copyPageIDBool = false  ;}, 3000);
  }

  showImg(headerImages: TemplateRef<any>) {
    if (this.pageHeaderImg) {
      this.modalRef = this.modalService.show(headerImages,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  reportUser(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reported_page': this.page_id, 'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description']
      }
      this.authService.ReportUser(report_data).subscribe(respones => {});
      this.modalRef.hide();
    } else {
      this.submitted = true;
    }
  }

  routeToClaim(){
    this.router.navigate(['/verify-claim'], {queryParams: {'id': this.encrypt_page_id, 'verifier': this.encrypt_email}});
  }

  inviteTeamModal(inviteTeamTemplate: TemplateRef<any>) {
    this.invitationSuccess = false;
    this.submitted = false;
    let frmArray = this.invitationForm.get('invitations') as FormArray;
    frmArray.clear();
    this.invitationForm.reset();
    this.invitations.push(this.createItem());
    this.modalRef = this.modalService.show(inviteTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  resetMembers(){
    if (this.teamMember.length > 0) {
      this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
      if (this.searchInp){
        this.searchInp.nativeElement.value = "";
      }
      if (this.teamMember.length > this.teamMemberSliced.length){
        this.teamLoadMoreBool = true;
      }
    }
  }

  searchStudent(e: any){
    if (e.target.value && e.target.value.length > 0){
      this.searchQuery = e.target.value;
      this.teamMemberSliced = this.teamMember.filter(item => item.student_info.student_name.search(new RegExp(this.searchQuery, 'i')) > -1);
      this.teamLoadMoreBool = false;
    } else if (this.searchQuery.length > 0) {
      this.teamMemberSliced = this.teamMember.slice(0, this.slicedItem);
      if (this.teamMember.length > this.teamMemberSliced.length){
        this.teamLoadMoreBool = true;
      }
    }
  }

  searchStudents(event: any){
    let name = event.target.value;
    if (name.length === 0) {
      this.resetSearch();
    } else if (name.length > 2) {
      this.searchQuery = name;
      this.teamMemberNextPage = 1;
      this.getMembers();
    } else {
      this.searchQuery = null;
    }
  }

  resetSearch(){
    if (this.searchInp){
      this.searchInp.nativeElement.value = "";
    }
    this.searchQuery = null;
    this.teamMemberNextPage = 1;
    this.getMembers();
  }

  inviteMember(isValid: boolean){
    this.submitted = true;
    if (isValid){
      let model = {
        'invitations': this.invitationForm.get('invitations').value,
        'page': this.page_id
      }
      this.teamService.sendInvite(model)
      .subscribe(response => {
        this.submitted = false;
        if (response.status === 200) {
          this.invitationSuccess = true;
          this.invitationForm.reset();
        } else {
          this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.invitationForm.get('invitations').setErrors({ aftersubmit: "Something went wrong please try again later."});
      });
    }
  }

  // isEvent(event: any){
  //   if (event) {
  //     this.getWeeklyEvents();
  //     this.getEvents();
  //   }
  // }

  followPage(){
    if (this.user.age_group < 3 || this.pagesDetails.club_team.school.is_college) {
      this.followBtnErr = false;
      if (this.pagesDetails.page_info.is_followed) {
        this.pageService.unFollowPage(this.pagesDetails.id, {})
        .subscribe(response => {
          this.pagesDetails.page_info.is_followed = false;
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      } else {
        this.pageService.followPage(this.pagesDetails.id, {})
        .subscribe(response => {
          if (response.status === 200) {
            this.pagesDetails.page_info.is_followed = true;
          } else {
            this.followBtnErr = true;
            this.fadeOutFollowErrMsg();
          }
        }, error => {
          this.followBtnErr = true;
          this.fadeOutFollowErrMsg();
        });
      }
    }
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
    }, 6000);
  }

  directToProfile(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      this.router.navigate(['profile/' + member.student_info.id + '/']);
    }
  }

  checkClick(member: any){
    if (this.user.user_role == '2' && this.user.approval_status && (this.user.age_group < 3 || this.user.age_group == member.student_info.age_group)) {
      return true;
    }
    return false;
  }
 
}
