<section class="studentSignUp">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="studentSignUpInner">
                    <div class="SignUpInnerWrap WrapWhiteShadow mb-0">
                        <h1 class="hdg">Resume</h1>
                        <p class="subHdg">Personal Details</p>
                        <form [formGroup]="profileForm" novalidate>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="text" class="form-control text-capitalize signUpField" formControlName="first_name"
                                        placeholder="First Name">
                                    <div class="error-message"
                                        *ngIf="profileForm.get('first_name').hasError('required') && (profileForm.get('first_name').dirty || searchSubmitted)">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="text" class="form-control text-capitalize signUpField" formControlName="last_name"
                                        placeholder="Last Name">
                                    <div class="error-message"
                                        *ngIf="profileForm.get('last_name').hasError('required') && (profileForm.get('last_name').dirty || searchSubmitted)">
                                        This field is required.
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-11">
                                    <input type="text" class="form-control signUpField" bsDatepicker [minDate]="minDate"
                                        [maxDate]="maxDate"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="date_of_birth" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="profileForm.get('date_of_birth').hasError('required') && (profileForm.get('date_of_birth').dirty || searchSubmitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="profileForm.get('date_of_birth').hasError('aftersubmit')">
                                        {{profileForm.controls.date_of_birth.errors.aftersubmit}}
                                    </div>
                                </div>
                                <div class="col-1 p-0">
                                    <img alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                                        class="cursor-pointer">
                                    <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                        <div class="info-div">
                                            <p>**You may change your date and month of the year if you feel like
                                                updating it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row inputRow">
                                <div class="col-md-12">
                                    <input type="email" class="form-control signUpField" formControlName="email" placeholder="Email"
                                        readonly>
                                    <div class="error-message"
                                        *ngIf="profileForm.get('email').hasError('required') && (profileForm.get('email').dirty || searchSubmitted)">
                                        This field is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-0 text-center">
                                    <input type="submit" class="btn btnSignUp" value="Confirm"
                                        (click)="save(profileForm.valid)">
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
