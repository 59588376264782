<div *ngIf="isClubTeam" class="newProfile blueTabs2">
<div class="tab-content-box">
        <div class="fullBanner">
                <div class="forImage">
                    <img alt="banner" class="bannerImg" [ngClass]="{'cursor-pointer': pageHeaderImgName}"
                        (click)="showImg(headerImages);" src="{{pageHeaderImg ? pageHeaderImg : 'assets/images/new-header-img.png'}}">
                        <div class="return-wrap">
                            <a href="javascript:void()" (click)="backClicked()">
                                <img alt="image" src="assets/images/return-btn-teal.svg" class="returnImg" />
                            </a>
                        </div>
                </div>
            
                <div class="forDetail">
                    <div class="container custom-container max-1050 mb-40">
                        <div class="row">
                            <div class="col-12 col-md-6 center-wrap">
                                <div class="withProfile">
                                    <div class="forEdit mr-auto">
                                        <form novalidate="" class="pos-rel">
                                            <img alt="profile Image" class="profileImg" *ngIf="page_Image_name" src="{{page_Image}}">
                                            <img alt="profile Image" class="profileImg" *ngIf="!page_Image_name" src="assets/images/p-img.png">
                                        </form>
                                    </div>
                                </div>
                                
                                <div class="profileList">
                                    <h3>{{ pagesDetails?.page_info?.title }}</h3>
                                    <div class="ownAddress">
                                        <h4 *ngIf="pagesDetails.club_team">
                                           <span class="cursor-pointer" *ngIf="pagesDetails?.club_team?.school"
                                            [routerLink]="['/school/' + pagesDetails?.club_team?.school?.id ]">{{pagesDetails?.club_team?.school?.school_name}}</span>
                                        </h4>
                                    </div>
                                    <div class="ownContact" *ngIf="pagesDetails.phone_number">
                                        <h4>
                                            <span *ngIf="pagesDetails.club_team && pagesDetails?.leader_name">
                                                {{pagesDetails?.leader_name}}<span class="pipe pipeSm" *ngIf="pagesDetails.professional_email"> | </span>
                                            </span>
                                            <span>
                                                {{pagesDetails?.professional_email}}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
            
                                <div class="d-block d-md-none mt-3 forActiveSocials">
                                    <!-- <div class="support-btns mb-3">
                                        <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_1">{{profile.profile_keyword_1}}</button>
                                        <button class="btn btn-dark-badge ml-2" *ngIf="profile.profile_keyword_2">{{profile.profile_keyword_2}}</button>
                                        <button class="btn btn-dark-badge ml-2" *ngIf="profile.profile_keyword_3">{{profile.profile_keyword_3}}</button>
                                    </div> -->
        
                                    <!-- <ul class="socialBtns no-list justify-content-center">
                                        <li *ngIf="isUnclaimed">
                                            <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                                                <span class="cursor-pointer" id="button-basic2" dropdownToggle aria-controls="dropdown-basic2">
                                                        <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                                                </span>
                                                <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu"
                                                    role="menu" aria-labelledby="button-basic2">
                                                    <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                                                    <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                                                </ul>
                                              </div>
                                        </li>
                                        <li>
                                            <a href="javascript:void()" [ngClass]="{'selected-tab': tabActive == 3}" (click)="tabActive=3">
                                              <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                                              <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                                            </a>
                                        </li>
                                     </ul> -->
                                </div>
                            </div>
            
                            <!-- <div class="d-none d-md-block col-md-12 support-wrap">
                                <div class="support-btns">
                                    <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_1">{{profile.profile_keyword_1}}</button>
                                    <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_2">{{profile.profile_keyword_2}}</button>
                                    <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_3">{{profile.profile_keyword_3}}</button>
                                </div>
                            </div> -->
                        </div>
        
                        <p class="initiativeSummary">
                            {{ pagesDetails?.description }}
                        </p>
        
                        <div class="follow-group-box">
                            <button *ngIf="user.age_group < 3 || pagesDetails.club_team?.school.is_college" type="button" class="schoolfollow-btn" (click)="followPage()"
                                [ngClass]="{'unfollow': pagesDetails.page_info.is_followed}">
                                <span *ngIf="!pagesDetails.page_info.is_followed">Follow</span>
                                <span *ngIf="pagesDetails.page_info.is_followed">Unfollow</span>
                            </button>
                            <p *ngIf="followBtnErr" class="error-message text-center">Something went wrong. Please try again later.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="tabWrap mb-0">
                <div class="container custom-container max-890">
                    <div class="row">
                        <div class="col-12">
                            <ul class="tabBtns no-list">
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 1
                                  }" (click)="tabActive=1">
                                    Timeline
                                </li>
        
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 4
                                  }" (click)="tabActive=4">
                                    About
                                </li>
            
                                <li class="cursor-pointer" [ngClass]="{
                                    'selected-tab': tabActive == 2
                                  }" (click)="tabActive=2;getTeamItems()">
                                    team
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="d-none d-md-block col-md-3 forActiveSocials">
                            <ul class="socialBtns no-list">
                                <li *ngIf="isUnclaimed">
                                    <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                                        <span class="cursor-pointer" id="button-basic2" dropdownToggle aria-controls="dropdown-basic2">
                                            <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                                        </span>
                                        <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu"
                                            role="menu" aria-labelledby="button-basic2">
                                            <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                                            <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                                        </ul>
                                      </div>
                                </li>
                                <li>
                                    <a href="javascript:void()" [ngClass]="{'selected-tab': tabActive == 3}" (click)="tabActive=3">
                                      <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                                      <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
</div>
    
    <div class="custom-tab-dark box-shadow-box">
        <div *ngIf="tabActive == 1">
            <app-timeline [pagesDetails]="pagesDetails" [page_id]="page_id"></app-timeline>
        </div>
        
        <div *ngIf="tabActive == 4">
                <div class="impactBlockOuter">
                        <div class="impactBlock backWhite pt-0 pb-0">
                            <div class="eachBlock" *ngIf="linkArray && linkArray.length > 0">
                                <div class="topHdg">
                                    <h3 class="hdgName pl-0">
                                        Links
                                    </h3>
                                </div>
                                <div class="blockDes blockDesLinks">
                                        <div class="image-with-del mr-0 d-flex" *ngFor="let link of linkArray">
                                                <a href="{{ link.link }}" target="_blank">
                                                    <div class="img-box">
                                                        <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}" class="icon">
                                                    </div>
                                                    {{ link.title }}
                                                </a>
                                            </div>
                                </div>
                            </div>
        
                            <div class="eachBlock" *ngIf="aboutUs">
                                <div class="topHdg">
                                    <h3 class="hdgName pl-0">About Us</h3>
                                </div>
                                <div class="blockDes blockMission" [ngClass]="{ '': infoDiv1, showDetailContent: !infoDiv1}">
                                    <p class="truncatedText" innerHtml="{{ aboutUs | slice:0:400 }}"></p>
                                    <p class="fullText" innerHtml="{{ aboutUs }}"></p>
                                    <a *ngIf="(aboutUs + '').length > 400" href="javascript:void();" (click)="infoDiv1 = !infoDiv1"
                                        class="d-block text-center showMore mt-0">
                                        <img src="assets/images/new-arrow.svg" alt="icon">
                                    </a>
                                </div>
                            </div>
        
                            <!-- <div class="eachBlock mb-0" *ngIf="weekEvents.length > 0">
                                <div class="topHdg topHdgWithLink">
                                    <h3 class="hdgName">
                                        this week
                                    </h3>
                                    <a class="hdgLink cursor-pointer" (click)="tabActive=4">View calendar</a>
                                </div>
        
                                <div class="blockDes">
                                    <div class="calenderCards">
                                        <ul class="no-list calenderCardsList">
                                            <li *ngFor="let eachEvent of weekEvents">
                                                <div class="cardsInner">
                                                    <div class="topName">{{ eachEvent.start_date | date: 'MMMM d'  }}</div>
                                                    <div class="btmdes">{{ eachEvent.title }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="impactNoResult" *ngIf="linkArray.length == 0 && weekEvents.length == 0 && !aboutUs">
                                <h5 class="m-0">No data exists.</h5>
                            </div> -->

                            <div class="calenderWrapOuter">
                                    <div class="grid-box">
                                        <div class="text-center">
                                            <h3 class="month-title calendar-title">
                                                {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                        </div>
                                        <div [ngSwitch]="view" class="mt-2 calendar-box">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    [activeDayIsOpen]="activeDayIsOpen"
                                                    (dayClicked)="dayClicked($event.day)" [refresh]="refresh">
                                                </mwl-calendar-month-view>
                                                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    [refresh]="refresh">
                                                </mwl-calendar-week-view>
                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    [refresh]="refresh">
                                                </mwl-calendar-day-view>
                                            </div>
                                    </div>
                                </div>
                        </div>
                    </div>

        </div>

        <div *ngIf="tabActive == 2">
            <div class="initiativeTeamBlock">
                <div class="initiativeTeamSlider mb-4 mb-md-5">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">Team</h3>
                    </div>
                          <div class="search-box initiativeVolunteerAdd pt-0 pb-0">
                            <div class="left-part">
                                    <div class="form-group">
                                            <input type="text" #searchInp placeholder="Search Name" class="form-control" (keyup)="searchStudents($event)">
                                    </div>
                            </div>
                            <div class="right-part">
                                <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                    Invite members
                                </a>
                            </div>
                          </div>
                </div>
                <div class="initiativeTeamSlider mb-2 mb-md-12 initiativeTeamSliderMob35">
                    <div class="team-list-box">
                      <div class="impactNoResult" *ngIf="teamMembers.length == 0">
                        <h5 class="m-0">No data exists.</h5>
                      </div>
                        <div class="team-box" *ngFor="let member of teamMembers">
                            <div class="description-side">
                                <div class="img-box">
                                    <img src="{{member.student_info.display_pic ? member.student_info.display_pic: 'assets/images/p-img.png'}}"
                                      alt="profile" (click)="directToProfile(member)" [ngClass]="{'cursor-pointer': checkClick(member)}"/>
                                </div>
                                <div class="para-box">
                                    <div class="relative">
                                        <h3>{{member.student_info.student_name}}</h3>
                                        <p *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                                            <span class="dot"></span>
                                            <span *ngIf="member.student_info.connection == 1">1st</span>
                                            <span *ngIf="member.student_info.connection == 2">2nd</span>
                                        </p>
                                    </div>
                                    <p *ngIf="member.position">{{member.position}}</p>
                                </div>
                            </div>
                            <div class="add-network" *ngIf="member.student_info.connection == 1 || member.student_info.connection == 2">
                                <button type="button" *ngIf="member.student_info.connection == 1" (click)="openModal(member, remNetworkModal)">
                                    <img src="/assets/images/add-network-teal-icon.svg" alt="remove" />
                                    Remove
                                </button>
                                <button type="button" *ngIf="member.student_info.connection == 2" (click)="openModal(member, networkModal)">
                                    <img src="/assets/images/add-network-teal-icon.svg" alt="add-network" />
                                    Add to Network
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="timeLineLoad mt-0" *ngIf="totalTeamMembers > 10 && totalTeamMembers > teamMembers.length">
                      <a href="javascript:void(0);" class="mb-4" (click)="getMembers()">Load More</a>
                    </div>
                  </div>

                <div class="initiativeVolunteerAdd pt-0 pb-0">
                    <div class="d-flex align-items-center withRadBlueName">
                        <h3 class="blueHdg">Leaders</h3>
                    </div>
                </div>
    
                <div class="initiativeVolunteerAdd pt-0" *ngIf="managementList.length > 0">
                    <div class="managementWithRole">
                        <div class="team-list-box leaders-list">
                                <div class="team-box" *ngFor="let volunteer of managementList">
                                        <div class="description-side">
                                            <div class="para-box">
                                                    <div class="relative">
                                                            <h3>{{ volunteer.first_name }} {{ volunteer.last_name | slice:0:1 }}.</h3>
                                                    </div>
                                                    <p>{{ volunteer.position }}</p>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                        <div class="timeLineLoad pt-4" *ngIf="totalManagements > 10 && totalManagements > managementList.length">
                            <a href="javascript:void(0);" (click)="laodMoreManagements()">Load More</a>
                        </div>
                    </div>
                </div>

                <div class="timelineNoResult" *ngIf="managementList.length == 0">
                    <h5 class="m-0">No data exists.</h5>
                </div>
            </div>
            
        </div>

        <div *ngIf="tabActive == 3">
            <app-need-help></app-need-help>
        </div>
    </div>
</div>

<div *ngIf="!isClubTeam" class="newProfile greenTabs">
    <div class="fullBanner">
        <div class="forImage">
            <img alt="banner" class="bannerImg" [ngClass]="{'cursor-pointer': pageHeaderImg}" 
                (click)="showImg(headerImages);" src="{{pageHeaderImg ? pageHeaderImg : 'assets/images/new-header-img.png'}}">
                <div class="return-wrap">
                        <a href="javascript:void()" (click)="backClicked()">
                            <img alt="image" src="assets/images/return-btn-teal.svg" class="returnImg" />
                        </a>
                    </div>
        </div>
    
        <div class="forDetail">
            <div class="container custom-container max-890 mb-40">
                <div class="row">
                    <div class="col-12 center-wrap">
                        <div class="withProfile">
                            <div class="forEdit mr-auto">
                                <form novalidate="" class="pos-rel">
                                    <img alt="profile Image" class="profileImg" *ngIf="page_Image_name" src="{{page_Image}}">
                                    <img alt="profile Image" class="profileImg" *ngIf="!page_Image_name" src="assets/images/p-img.png">
                                </form>
                            </div>
                        </div>
                        
                        <div class="profileList">
                            <h3>{{ pagesDetails?.page_info?.title }}</h3>
                            <div class="ownAddress">
                                <h4 *ngIf="pagesDetails.business">
                                    <span *ngIf="pagesDetails?.business?.address">{{pagesDetails?.business?.address}}, </span>{{pagesDetails?.business?.city}},
                                    {{pagesDetails?.business?.state}} <span *ngIf="pagesDetails?.business?.zip_code">, {{pagesDetails?.business?.zip_code}}</span>
                                </h4>
                            </div>
                            <div class="ownContact" *ngIf="pagesDetails.phone_number">
                                <h4>
                                    <a *ngIf="pagesDetails?.phone_number" href="tel:{{pagesDetails?.phone_number}}" (click)="$event.stopPropagation()">
                                        <span>({{pagesDetails?.phone_number | slice:0:3}}) {{pagesDetails?.phone_number | slice:3:6}}-{{pagesDetails?.phone_number | slice:6}}</span>
                                    </a>
                                    <span>
                                        {{pagesDetails?.professional_email}}
                                    </span>
                                </h4>
                            </div>
                        </div>
    
                        <div class="d-block d-md-none mt-3 forActiveSocials">
                            <div class="support-btns mb-3">
                                <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_1">{{profile.profile_keyword_1}}</button>
                                <button class="btn btn-dark-badge ml-2" *ngIf="profile.profile_keyword_2">{{profile.profile_keyword_2}}</button>
                                <button class="btn btn-dark-badge ml-2" *ngIf="profile.profile_keyword_3">{{profile.profile_keyword_3}}</button>
                            </div>

                            <ul class="socialBtns no-list justify-content-center">
                                <li class="custom-tooltip">
                                    <span class="pageIDCopy cursor-pointer" (click)="copyPageID()">Page ID: ORG{{page_id}}</span>
                                    <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
                                </li>

                                <li *ngIf="isUnclaimed">
                                    <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                                        <span class="cursor-pointer" id="button-basic2" dropdownToggle aria-controls="dropdown-basic2">
                                                <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                                        </span>
                                        <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu"
                                            role="menu" aria-labelledby="button-basic2">
                                            <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                                            <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                                        </ul>
                                      </div>
                                </li>
        
                                <li>
                                    <a href="javascript:void()" [ngClass]="{'selected-tab': tabActive == 3}" (click)="tabActive=3">
                                      <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                                      <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="d-none d-md-block col-md-12 support-wrap">
                        <div class="support-btns">
                            <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_1">{{profile.profile_keyword_1}}</button>
                            <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_2">{{profile.profile_keyword_2}}</button>
                            <button class="btn btn-dark-badge" *ngIf="profile.profile_keyword_3">{{profile.profile_keyword_3}}</button>
                        </div>
                    </div>
                </div>

                <p class="initiativeSummary">
                    {{ pagesDetails?.description }}
                </p>
            </div>
        </div>
    </div>
    
    <div class="tabWrap">
        <div class="container custom-container max-890">
            <div class="row">
                <div class="col-12 col-md-7">
                    <ul class="tabBtns no-list">
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 1
                          }" (click)="tabActive=1">
                            details
                        </li>
    
                        <li class="cursor-pointer" [ngClass]="{
                            'selected-tab': tabActive == 2
                          }" (click)="tabActive=2;resetMembers()">
                            team
                        </li>
                    </ul>
                </div>
                <div class="d-none d-md-block col-md-5 forActiveSocials">
                    <ul class="socialBtns no-list">
                        <li class="custom-tooltip">
                            <span class="pageIDCopy cursor-pointer" (click)="copyPageID()">Page ID: ORG{{page_id}}</span>
                                    <div class="tooltip-data" *ngIf="copyPageIDBool">Page ID Copied</div>
                        </li>

                        <li *ngIf="isUnclaimed">
                            <div class="customDropdown pos-rel customDropdownUnclaimed" dropdown>
                                <span class="cursor-pointer" id="button-basic2" dropdownToggle aria-controls="dropdown-basic2">
                                        <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/settingIconTransparent.svg">
                                </span>
                                <ul id="dropdown-basic2" *dropdownMenu class="dropdown-menu"
                                    role="menu" aria-labelledby="button-basic2">
                                    <li role="menuitem"> <a href="javascript:void();" (click)="openDetail(settingModal);$event.stopPropagation()">Report</a></li>
                                    <li role="menuitem" *ngIf="isOwner"><a class="dropdown-item" href="javascript:void();" (click)="routeToClaim()">Claim Page</a></li>
                                </ul>
                              </div>
                        </li>

                        <li>
                            <a href="javascript:void()" [ngClass]="{'selected-tab': tabActive == 3}" (click)="tabActive=3">
                              <img alt="icon" class="hdg-icon nonActiveTabIcon" src="assets/images/helpProfileIcon.svg">
                              <img alt="icon" class="hdg-icon activeTabIcon" src="assets/images/helpProfileIconActive.svg">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div class="custom-tab-dark">
        <div *ngIf="tabActive == 1">
            <div class="impactBlockOuter">
                <div class="impactBlock backWhite pt-0 pb-0">
                    <div class="eachBlock mb-0" *ngIf="linkArray && linkArray.length > 0">
                        <div class="topHdg">
                            <h3 class="hdgName">
                                Links
                            </h3>
                        </div>
                        <div class="blockDes blockDesLinks">
                            <ngx-slick-carousel class="sliderContent custom-slick-arrow carousel mt-4"
                                #slickModal="slick-carousel" [config]="linksConfig">
                                <div class="image-with-del slide" *ngFor="let link of linkArray" ngxSlickItem>
                                    <a href="{{ link.link }}" target="_blank">
                                        <div class="mx-auto">
                                            <img src="{{link.thumbnail ? link.thumbnail :'assets/images/default.svg'}}" class="icon img-70 mx-auto">
                                        </div>
                                    </a>
                                    <div class="dtl">
                                        <h3>{{ link.title }}</h3>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                    <div class="impactNoResult" *ngIf="linkArray.length == 0">
                        <h5 class="m-0">No data exists.</h5>
                    </div>
                </div>
            </div>
    
            <div class="timeLineBlock">
                <div class="allTimeLinesOuter" *ngIf="timelines.length > 0">
                    <div class="addTimeFrame border-0 pb-0 mb-1">
                        <form>
                            <div class="isThisPost">
                                <!-- <div class="radioPostBtnOuter">
                                    <p>all</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="1" id="end" (click)="radioActive=1" class="custom-control-input" name="post" checked />
                                        <label for="end" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box">
                                        <input type="radio" value="1" id="end" (click)="radioActive=1" class="custom-control-input" name="post" checked />
                                        <label for="end">All</label>
                                </div>
                                
                                <!-- <div class="radioPostBtnOuter">
                                    <p>job</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="2" id="end2" (click)="getRecentJobs();radioActive=2" class="custom-control-input" name="post" />
                                        <label for="end2" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box">
                                        <input type="radio" value="2" id="end2" (click)="getRecentJobs();radioActive=2" class="custom-control-input" name="post" />
                                        <label for="end2">job</label>
                                </div>
    
                                <!-- <div class="radioPostBtnOuter">
                                    <p>volunteer</p>
                                    <div class="custom-control custom-radio radioPostBtn">
                                        <input type="radio" value="3" id="end3" (click)="getRecentVolunteerJobs();radioActive=3" class="custom-control-input" name="post" />
                                        <label for="end3" class="custom-control-label custom-label mb-0"></label>
                                    </div>
                                </div> -->
                                <div class="teal-radio-box mr-0">
                                        <input type="radio" value="3" id="end3" (click)="getRecentVolunteerJobs();radioActive=3" class="custom-control-input" name="post" />
                                        <label for="end3">volunteer</label>
                                </div>
                            </div>
                        </form>
                    </div>
    
                    <div *ngIf="radioActive == 1"> 
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="timelines.length > 0">
                            <div *ngFor="let timeline of timelines" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="timelineNextPage == 1 && totalTimelines > 3 && totalTimelines > timelines.length">
                            <a href="javascript:void(0);" (click)="loadMoreTimelines()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="timelineNextPage > 1 && totalTimelines > 10 && totalTimelines > timelines.length">
                            <a href="javascript:void(0);" (click)="loadMoreTimelines()">Load More</a>
                        </div>
                    </div>

                    <div *ngIf="radioActive == 2">
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="jobs.length > 0">
                            <div *ngFor="let timeline of jobs" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="jobNextPage == 1 && totalJobs > 3 && totalJobs > jobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreJobs()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="jobNextPage > 1 && totalJobs > 10 && totalJobs > jobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreJobs()">Load More</a>
                        </div>
                    </div>

                    <div *ngIf="radioActive == 3">
                        <div class="allTimeLines scroll-activity scrollbar" *ngIf="volunteerJobs.length > 0">
                            <div *ngFor="let timeline of volunteerJobs" class="timeLinesEach">
                                <div class="timelineTopHdr" (click)="timelineDetailModal(timelineDetail, timeline)">
                                    <h3 class="cursor-pointer">{{timeline.title}}</h3>
                                    <p>Posted on {{timeline.created_date | date:'MMMM d, y'}}</p>
                                </div>
                                <p class="timelineDes timelineDesOuterDots" innerHtml="{{timeline.description}}"></p>
                                <p class="metaLink" *ngIf="!checkExtraData(timeline)">
                                    <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                </p>
                                <div class="singleTimelineDetail" *ngIf="checkExtraData(timeline)">
                                    <div class="imageBox">
                                        <img alt="timeline" class="timeLineImg" 
                                        src="{{timeline.metadata_image_link? timeline.metadata_image_link : 'assets/images/default.svg'}}" />
                                    </div>
                                    <div class="dtlOuter">
                                        <h1>{{timeline.metadata_title}}</h1>
                                        <p *ngIf="timeline.metadata_description">{{timeline.metadata_description}}</p>
                                        <a href="{{timeline.link}}" target="_blank">{{timeline.link}}</a>
                                    </div>
                                </div>
                                <div class="allTimeImages" *ngIf="timeline.timeline_documents.length > 0">
                                    <div class="imageOuter" *ngFor="let document of timeline.timeline_documents">
                                        <a href="{{document.document}}" target="_blank">
                                            <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                                            <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div class="timelinePostedBy" *ngIf="timeline.creator_info">
                                    <img class="postedUserImg" src="{{timeline.creator_info.display_picture? timeline.creator_info.display_picture: 'assets/images/default.svg'}}">
                                    <span class="postedUserName">{{timeline.creator_info.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="timeLineLoad" *ngIf="volunteerJobNextPage == 1 && totalVolunteerJob > 3 && totalVolunteerJob > volunteerJobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreVolunteerJobs()">Load More</a>
                        </div>
                        <div class="timeLineLoad" *ngIf="volunteerJobNextPage > 1 && totalVolunteerJob > 10 && totalVolunteerJob > volunteerJobs.length">
                            <a href="javascript:void(0);" (click)="loadMoreVolunteerJobs()">Load More</a>
                        </div>
                    </div>
                </div>
                <div class="timelineNoResult" *ngIf="timelines.length == 0">
                    <h5 class="m-0">No data exists.</h5>
                </div>
            </div>
        </div>
    
        <div *ngIf="tabActive == 2">
            <div class="initiativeTeamBlock">
                <div class="initiativeTeamSlider mb-4 mb-md-5" *ngIf="teamMember.length > 0">
                    <div class="d-flex align-items-center mb-3 withRadBlueName">
                        <h3 class="blueHdg">members</h3>
                    </div>
                    <div class="teamSearchInputWrap">
                        <div class="teamSearchInputWrapInner">
                            <div class="leftWrap">
                                <div class="form-group">
                                    <input type="text" #searchInp placeholder="Search by name" class="form-control" (keyup)="searchStudent($event)">
                                </div>
                            </div>
                            <div class="rightWrap">
                                <p class="des">Is someone from this team missing? 
                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                        <img alt="icon" class="iconInvite ml-2 mr-1" src="./assets/images/verifiedUser.svg" />
                                        <span>Invite</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
    
                <div class="initiativeTeamSlider mb-4 mb-md-5" *ngIf="teamMemberSliced && teamMemberSliced.length > 0">
                    <div class="teamListScroll scroll-activity scrollbar">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let member of teamMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember">
                                        <img alt="icon" class="bannerImg" src="{{member.student_info.header_image ? member.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                                    </div>
                                    <div class="profileMember">
                                        <img alt="icon" class="profileImg" src="{{member.student_info.display_pic ? member.student_info.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{ member.student_info.student_name }}</h1>
                                        <h3 class="subName">{{member.position}}</h3>
                                        <h3 class="subName">
                                            {{ member.start_date }}<span *ngIf="member.end_date"> - {{member.end_date}}</span>
                                            <span *ngIf="!member.end_date"> - Present</span>
                                        </h3>
                                        <div class="memberActions" *ngIf="user.id != member.student_info.id && (user.age_group < 3 || user.age_group == member.student_info.age_group)">
                                            <button class="btn common-btn btnMessage" *ngIf="user.age_group > 1" (click)="sendMessage(member.student_info.id)">Message</button>
                                        <button class="btn common-btn btnProfile" *ngIf="user.approval_status" routerLink="/profile/{{ member.student_info.id }}/">Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="teamLoadMore()">Load More</a>
                    </div>
                </div>
    
                <div class="initiativeVolunteerAdd pt-0 pb-0">
                    <div class="d-flex align-items-center withRadBlueName" *ngIf="managementList.length > 0">
                        <h3 class="blueHdg">Leaders</h3>
                    </div>
                </div>
    
                <div class="initiativeVolunteerAdd" *ngIf="managementList.length > 0">
                    <div class="managementWithRole">
                        <ul class="no-list">
                            <li class="row" *ngFor="let volunteer of managementList">
                                <div class="col-md-6">
                                    <h3>{{ volunteer.first_name }} {{ volunteer.last_name | slice:0:1 }}.</h3>
                                    <p>{{ volunteer.position }}</p>
                                </div>
                            </li>
                        </ul>

                        <div class="timeLineLoad pt-4" *ngIf="totalManagements > 10 && totalManagements > managementList.length">
                            <a href="javascript:void(0);" (click)="laodMoreManagements()">Load More</a>
                        </div>
                    </div>
                </div>

                <div class="timelineNoResult" *ngIf="managementList.length == 0 && teamMemberSliced.length == 0">
                    <h5 class="m-0">No data exists.</h5>
                </div>
            </div>
        </div>

        <div *ngIf="tabActive == 3">
            <app-need-help></app-need-help>
        </div>
    </div>
</div>

<ng-template #timelineDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            <span *ngIf="radioActive == 1">Timeline</span>
            <span *ngIf="radioActive == 2">Job</span>
            <span *ngIf="radioActive == 3">Volunteer</span> detail</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="timeLinesEach">
            <div class="timelineTopHdr">
                <h3>{{editTimelineObj.title}}</h3>
                <p>Posted on {{editTimelineObj.created_date | date:'MMMM d, y'}}</p>
            </div>
            <p class="timelineDes" innerHtml="{{editTimelineObj.description}}"></p>
            <p class="metaLink" *ngIf="!checkExtraData(editTimelineObj)">
                <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
            </p>
            <div class="singleTimelineDetail" *ngIf="checkExtraData(editTimelineObj)">
                <div class="imageBox">
                    <img alt="timeline" class="timeLineImg" 
                    src="{{editTimelineObj.metadata_image_link? editTimelineObj.metadata_image_link : 'assets/images/default.svg'}}" />
                </div>
                <div class="dtlOuter">
                    <h1>{{editTimelineObj.metadata_title}}</h1>
                    <p>{{editTimelineObj.metadata_description}}</p>
                    <a href="{{editTimelineObj.link}}" target="_blank">{{editTimelineObj.link}}</a>
                </div>
            </div>
            <div class="allTimeImages" *ngIf="editTimelineObj.timeline_documents.length > 0">
                <div class="imageOuter" *ngFor="let document of editTimelineObj.timeline_documents">
                    <a href="{{document.document}}" target="_blank">
                        <img alt="timeline" *ngIf="isImage(document.document_name)" class="timeLineImg" src="{{document.document}}" />
                        <img alt="timeline" *ngIf="!isImage(document.document_name)" class="timeLineImg" src="assets/images/pdf-icon.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">cancel</button>
    </div>
</ng-template>
<ng-template #headerImages>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Header Image</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="headerOuter">
                    <img class="headerImg w-100" src="{{pageHeaderImg? pageHeaderImg: 'assets/images/new-header-img.png'}}">
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #settingModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Report Page</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="blockReportForm">
                <div class="reportShow">
                    <div class="form-group">
                        <label>Reason</label>
                        <select class="form-control custom-select" formControlName="report_reason">
                            <option value=1>Hate Speech</option>
                            <option value=2>Bullying and harrassment of yourself or others</option>
                            <option value=3>Direct threats</option>
                            <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)</option>
                            <option value=5>Other</option>
                        </select>
                    </div>
                    <div class="error-message"
                        *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="report_description"></textarea>
                        <div class="error-message"
                            *ngIf="blockReportForm.get('report_description').hasError('maxlength') && (blockReportForm.get('report_description').dirty || blockReportForm.get('report_description').touched)">
                            Max 1000 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="reportUser(blockReportForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="modalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="modalRef.hide()">Done</button>
    </div>
</ng-template>
<ng-template #networkModal>
    <div  class="modal-header">
      <h4  class="modal-title pull-left">Add to network</h4>
      <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-add-team [member]="selectedUser"></app-page-add-team>
    </div>
</ng-template>
<ng-template #remNetworkModal>
    <div  class="modal-header">
        <h4  class="modal-title pull-left">Remove from network</h4>
        <button  type="button" aria-label="Close" class="close-btn" (click)="modalRef.hide()">
        <img  alt="image"  src="assets/images/cross-modal.svg"></button>
    </div>
    <div class="modal-body connectionModal">
        <app-page-rem-team [member]="selectedUser"></app-page-rem-team>
    </div>
</ng-template>
