import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-experience-team',
  templateUrl: './experience-team.component.html',
  styleUrls: ['./experience-team.component.scss']
})
export class ExperienceTeamComponent implements OnInit {
  peningRequests: any = [];
  pendingReqNextPage: any = 1;
  totalpendingReq: any;
  pendingEduRequests: any = [];
  pendingEduReqNextPage: any = 1;
  totalpendingEduReq: any;
  pendingIniRequests: any = [];
  pendingIniReqNextPage: any = 1;
  totalpendingIniReq: any;
  approvedRequests: any = [];
  approvedReqNextPage: any = 1;
  totalapprovedReq: any;
  approvedEduRequests: any = [];
  approvedEduReqNextPage: any = 1;
  totalapprovedEduReq: any;
  approvedIniRequests: any = [];
  approvedIniReqNextPage: any = 1;
  totalapprovedIniReq: any;
  actionError: boolean = false;
  user: any;
  tabActive = 1;

  constructor(private teamService: TeamService, private router: Router) { }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }
    this.getPendingReq('');
    this.getPendingEduReq('');
    this.getPendingIniReq('');
  }

  getPendingReq(params: any) {
    this.teamService.getPendingRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0){
          if (this.pendingReqNextPage == 1) {
            this.peningRequests = response.response.results;
          } else {
            this.peningRequests = this.peningRequests.concat(response.response.results);
          }
          this.pendingReqNextPage = response.response.next;
          this.totalpendingReq = response.response.total_count;
        } else {
          this.peningRequests = [];
        }
      })
  }

  getPendingEduReq(params: any) {
    this.teamService.getPendingEduRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0){
          if (this.pendingEduReqNextPage == 1) {
            this.pendingEduRequests = response.response.results;
          } else {
            this.pendingEduRequests = this.pendingEduRequests.concat(response.response.results);
          }
          this.pendingEduReqNextPage = response.response.next;
          this.totalpendingEduReq = response.response.total_count;
        } else {
          this.pendingEduRequests = [];
        }
      })
  }

  getPendingIniReq(params: any) {
    this.teamService.getPendingIniRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0){
          if (this.pendingIniReqNextPage == 1) {
            this.pendingIniRequests = response.response.results;
          } else {
            this.pendingIniRequests = this.pendingIniRequests.concat(response.response.results);
          }
          this.pendingIniReqNextPage = response.response.next;
          this.totalpendingIniReq = response.response.total_count;
        } else {
          this.pendingIniRequests = [];
        }
      })
  }

  approveTab(){
    this.approvedReqNextPage = 1;
    this.approvedEduReqNextPage = 1;
    this.approvedIniReqNextPage = 1;
    this.getApprovedReq('');
    this.getApprovedEduReq('');
    this.getApprovedIniReq('');
  }

  getApprovedReq(params: any) {
    this.teamService.getApprovedRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.approvedReqNextPage == 1) {
            this.approvedRequests = response.response.results;
          } else {
            this.approvedRequests = this.approvedRequests.concat(response.response.results);
          }
          this.approvedReqNextPage = response.response.next;
          this.totalapprovedReq = response.response.total_count;
        } else {
          this.approvedRequests = [];
        }
      })
  }

  getApprovedEduReq(params: any) {
    this.teamService.getApprovedEduRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.approvedEduReqNextPage == 1) {
            this.approvedEduRequests = response.response.results;
          } else {
            this.approvedEduRequests = this.approvedEduRequests.concat(response.response.results);
          }
          this.approvedEduReqNextPage = response.response.next;
          this.totalapprovedEduReq = response.response.total_count;
        } else {
          this.approvedEduRequests = [];
        }
      })
  }

  getApprovedIniReq(params: any) {
    this.teamService.getApprovedIniRequest(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.approvedIniReqNextPage == 1) {
            this.approvedIniRequests = response.response.results;
          } else {
            this.approvedIniRequests = this.approvedIniRequests.concat(response.response.results);
          }
          this.approvedIniReqNextPage = response.response.next;
          this.totalapprovedIniReq = response.response.total_count;
        } else {
          this.approvedIniRequests = [];
        }
      })
  }

  loadMorePending() {
    if (this.pendingReqNextPage != null) {
      let params = '?page=' + this.pendingReqNextPage;
      this.getPendingReq(params);
    }
  }

  loadMorePendingEdu() {
    if (this.pendingEduReqNextPage != null) {
      let params = '?page=' + this.pendingEduReqNextPage;
      this.getPendingEduReq(params);
    }
  }

  loadMorePendingIni() {
    if (this.pendingIniReqNextPage != null) {
      let params = '?page=' + this.pendingIniReqNextPage;
      this.getPendingIniReq(params);
    }
  }

  loadMoreApproved() {
    if (this.approvedReqNextPage != null) {
      let params = '?page=' + this.approvedReqNextPage;
      this.getApprovedReq(params);
    }
  }

  loadMoreApprovedEdu() {
    if (this.approvedEduReqNextPage != null) {
      let params = '?page=' + this.approvedEduReqNextPage;
      this.getApprovedEduReq(params);
    }
  }

  loadMoreApprovedIni() {
    if (this.approvedIniReqNextPage != null) {
      let params = '?page=' + this.approvedIniReqNextPage;
      this.getApprovedIniReq(params);
    }
  }

  requestAction(pendingReq: any, actionType: string) {
    let params = '';
    if (actionType === 'accept') {
      params = '?approve=true'
    } else {
      params = '?approve=false'
    }
    let model = {'to_user': pendingReq.exp_team_detail.user};
    this.teamService.acceptDeclineRequest(pendingReq.id, params, model)
      .subscribe((response: any) => {
        if (response.status === 111){
          this.actionError = true;
          this.fadeOutErrorMsg();
        } else if (response.status === 200){
          this.pendingReqNextPage = 1;
          this.getPendingReq('');
        }
      }, error => {
        this.actionError = true;
        this.fadeOutErrorMsg();
      })
  }

  requestActionEdu(pendingReq: any, actionType: string) {
    let params = '';
    if (actionType === 'accept') {
      params = '?approve=true'
    } else {
      params = '?approve=false'
    }
    let model = {'to_user': pendingReq.edu_team_detail.user};
    this.teamService.acceptDeclineEduRequest(pendingReq.id, params, model)
      .subscribe((response: any) => {
        if (response.status === 111){
          this.actionError = true;
          this.fadeOutErrorMsg();
        } else if (response.status === 200){
          this.pendingEduReqNextPage = 1;
          this.getPendingEduReq('');
        }
      }, error => {
        this.actionError = true;
        this.fadeOutErrorMsg();
      })
  }

  requestActionIni(pendingReq: any, actionType: string) {
    let params = '';
    if (actionType === 'accept') {
      params = '?approve=true'
    } else {
      params = '?approve=false'
    }
    let model = {'to_user': pendingReq.ini_team_detail.user};
    this.teamService.acceptDeclineIniRequest(pendingReq.id, params, model)
      .subscribe((response: any) => {
        if (response.status === 111){
          this.actionError = true;
          this.fadeOutErrorMsg();
        } else if (response.status === 200){
          this.pendingIniReqNextPage = 1;
          this.getPendingIniReq('');
        }
      }, error => {
        this.actionError = true;
        this.fadeOutErrorMsg();
      })
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.actionError = false;
    }, 4000);
   }

  cancelTeam(id: any){
    this.teamService.deleteTeamMember(id)
    .subscribe(response => {
      this.approvedReqNextPage = 1;
      this.getApprovedReq('');
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

  cancelEduTeam(id: any){
    this.teamService.deleteEduTeamMember(id)
    .subscribe(response => {
      this.approvedEduReqNextPage = 1;
      this.getApprovedEduReq('');
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

  cancelIniTeam(id: any){
    this.teamService.deleteIniTeamMember(id)
    .subscribe(response => {
      this.approvedIniReqNextPage = 1;
      this.getApprovedIniReq('');
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

}
