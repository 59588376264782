import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})

export class OthersComponent implements OnInit {
  whySignUpOther: any = "for_others";
  whySignUpOtherComponent: any = "";
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.staticContent(this.whySignUpOther)
    .subscribe(response => {
      this.whySignUpOtherComponent = response
    });
  }
}
