import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { LoginService } from '../services/login.service';
import { first } from 'rxjs/operators';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { setExternalUserId } from '../../assets/js/oneSignal';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000000 } }
  ]
})
export class ProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  termsAcceptForm: FormGroup;
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  tabActive = 2;
  user: any = {};
  today = new Date();
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  is_terms_accepted: boolean = false;


  constructor(private loginService: LoginService, private router: Router, public formBuilder: FormBuilder, private profileService: ProfileService) {
      this.termsAcceptForm = this.formBuilder.group({
        terms: new FormControl('', [Validators.required]),
      });
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }
    if (this.router.url === '/profile/setting') {
      this.tabActive = 4;
    }
    if (this.router.url === '/profile/experiences') {
      this.tabActive = 8;
    }
    if (this.user.first_education) {
      document.body.classList.add("profile-step1-onboard");
      if (this.user.unique_id) {
        setExternalUserId(this.user.unique_id, this.user.email, true);
      }
    }
  }

  showTwo(){
    this.stepOne = false;
    this.stepTwo = true;
  }
  showThree(){
    this.stepTwo = false;
    this.stepThree = true;
  }
  goBack() {
    this.stepTwo = true;
    this.stepThree = false;
  }

  logout() {
    this.loginService.userLogout()
        .subscribe(
            data => {
                if (this.user.unique_id) {
                  setExternalUserId(this.user.unique_id, this.user.email, false);
                }
                localStorage.removeItem('user');
                window.location.href='/';
                this.loadPublicToken();
            },
            error => {}
        )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
        .pipe(first())
        .subscribe(
            data => {
            },
            error => {

            });
}

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.is_terms_accepted = true;
    } else {
      this.is_terms_accepted = false;
    }
  }

  submitTermsAcceptForm() {
    if (this.is_terms_accepted) {
      var data = { 'is_terms_accepted': this.is_terms_accepted }
      this.profileService.AcceptTerms(data, this.user.id).subscribe(response => {
        this.handleTermsResponse(response);
      })
    }
  }

  handleTermsResponse(response: any) {
    if (response.status === 200) {
        this.user.age_group_updated = response.response.age_group_updated;
        this.user.age_group = response.response.age_group;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
    }
  }
  step2Event(){
    document.body.classList.add("step2-onboard");
  }
  step3Event(){
    document.body.classList.add("step3-onboard");
  }
  step4Event(){
    document.body.classList.remove("profile-step1-onboard");
    document.body.classList.remove("step2-onboard");
    document.body.classList.remove("step3-onboard");
    document.body.classList.add("onboardeducation");
  }
}
