import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  user: any = {};
  verifiers = []; 

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    }
    this.profileService.listVerifiers()
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      });
  }
}
