<div class="row">
    <div class="col-md-12">
        <div class="connectionNetworkList scroll-activity scrollbar">
            <ul class="no-list pr-2">
                <li *ngFor="let experience of addExpTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{experience.position}}</h2>
                            <h4>{{experience.organization_title}}</h4>
                            <p>{{experience.start_date}}
                                <ng-template [ngIf]="experience.end_date" [ngIfElse]="currentTemp">
                                    <span>- {{experience.end_date}}</span>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btnAdd" *ngIf="experience.add_to_team == 5"
                        (click)="teamExpBusiness(experience, addProfileTeamModal)">Add</button>
                        <p *ngIf="experience.add_to_team == 1" class="pending-text">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                        </p>
                        <p *ngIf="experience.add_to_team == 2">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        </p>
                    </div>
                </li>
                <li *ngFor="let activity of addToActTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                            src="{{activity.page_display_pic? activity.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{activity.position}}</h2>
                            <h4>{{activity.organization_title}}</h4>
                            <p>{{activity.start_date}}
                                <ng-template [ngIf]="activity.end_date" [ngIfElse]="currentTemp">
                                    <span>- {{activity.end_date}}</span>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btnAdd" *ngIf="activity.add_to_team == 5"
                        (click)="teamExpBusiness(activity, addProfileTeamModal)">Add</button>
                        <p *ngIf="activity.add_to_team == 1" class="pending-text">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                        </p>
                        <p *ngIf="activity.add_to_team == 2">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        </p>
                    </div>
                </li>
                <li *ngFor="let experience of addToSchInvTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                            src="{{experience.page_display_pic? experience.page_display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{experience.position}}</h2>
                            <h4>{{experience.title}}</h4>
                            <p>{{experience.start_date}}
                                <ng-template [ngIf]="experience.end_date" [ngIfElse]="currentTemp">
                                    <span>- {{experience.end_date}}</span>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btnAdd" *ngIf="experience.add_to_team == 5"
                        (click)="teamExpClubTeam(experience, addProfileTeamModal)">Add</button>
                        <p *ngIf="experience.add_to_team == 1" class="pending-text">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                        </p>
                        <p *ngIf="experience.add_to_team == 2">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        </p>
                    </div>
                </li>
                <li *ngFor="let eduObj of addToEduTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                            src="{{eduObj.school.display_picture? eduObj.school.display_picture : 'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{eduObj.school.school_name}}</h2>
                            <h4 *ngIf="eduObj.majors">{{ eduObj.majors.join(', ') }}</h4>
                            <h4 *ngIf="eduObj.interests">{{ eduObj.interests.join(', ') }}</h4>
                            <p>{{eduObj.end_date | date:'yyyy' }}</p>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btnAdd" *ngIf="eduObj.add_to_team == 5"
                        (click)="teamEdu(eduObj, addEduTeamModal)">Add</button>
                        <p *ngIf="eduObj.add_to_team == 1" class="pending-text">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                        </p>
                        <p *ngIf="eduObj.add_to_team == 2">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        </p>
                    </div>
                </li>
                <li *ngFor="let initiative of addToInitTeam">
                    <div class="leftWrap">
                        <div class="forImage">
                            <img class="connImg"
                            src="{{initiative.display_pic ? initiative.display_pic : 'assets/images/default.svg'}}">
                        </div>
                        <div class="forDes">
                            <h2>{{ initiative.page_info.title }}</h2>
                        </div>
                    </div>
                    <div class="rightWrap">
                        <button class="btnAdd" *ngIf="initiative.add_to_team == 5"
                        (click)="addToIniTeam(initiative)">Add</button>
                        <p *ngIf="initiative.add_to_team == 1" class="pending-text">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">Pending
                        </p>
                        <p *ngIf="initiative.add_to_team == 2">
                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<ng-template #addProfileTeamModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team Members</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="othersExpOuterPopup px-md-5">
            <p class="othersExp2">You held multiple roles. When were you teammates?</p>
            <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
            <div class="scroll-activity scrollbar overflowXHide">
                <ul class="teamListWrap no-list">
                    <li *ngFor="let eachExp of matchedExp">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{eachExp.student_info.header_image ? eachExp.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{eachExp.student_info.display_pic ? eachExp.student_info.display_pic : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <h3 class="subName">{{ eachExp.position }}</h3>
                                <p class="yearHistory">
                                    {{ eachExp.start_date }}<span *ngIf="eachExp.end_date"> {{ eachExp.end_date }}</span><span *ngIf="!eachExp.end_date"> Present</span>
                                </p>
                                <div class="memberActions">
                                    <button class="btn common-btn btnMessage" *ngIf="!eachExp.team_id" (click)="addToTeamFnc(eachExp)">Add to team</button>
                                    <button class="btn common-btn btnMessage" *ngIf="eachExp.team_id">Pending</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updateModalRef.hide()">
            Done
        </button>
    </div>
</ng-template>
<ng-template #addEduTeamModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team Members</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="updateModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="othersExpOuterPopup px-md-5">
            <p class="othersExp2">When were you teammates?</p>
            <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
            <div class="scroll-activity scrollbar overflowXHide">
                <ul class="teamListWrap no-list">
                    <li *ngFor="let eachExp of matchedExp">
                        <div class="teamMemberEach">
                            <div class="topMember">
                                <img alt="icon" class="bannerImg" src="{{eachExp.student_info.header_image ? eachExp.student_info.header_image : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="profileMember">
                                <img alt="icon" class="profileImg" src="{{eachExp.student_info.display_pic ? eachExp.student_info.display_pic : 'assets/images/new-header-img.png'}}" />
                            </div>
                            <div class="btmMember">
                                <p class="yearHistory">
                                    {{ eachExp.start_date | date:'yyyy'}} - {{ eachExp.end_date | date:'yyyy' }}
                                </p>
                                <div class="memberActions">
                                    <button class="btn common-btn btnMessage" *ngIf="!eachExp.team_id" (click)="addToTeamEdu(eachExp)">Add to team</button>
                                    <button class="btn common-btn btnMessage" *ngIf="eachExp.team_id">Pending</button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="updateModalRef.hide()">
            Done
        </button>
    </div>
</ng-template>
<ng-template #currentTemp>
    <span>Present</span>
</ng-template>