import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-page-rem-team',
  templateUrl: './page-rem-team.component.html',
  styleUrls: ['./page-rem-team.component.scss']
})
export class PageRemTeamComponent implements OnInit {
  @Input() member: any;
  updateModalRef: BsModalRef;
  actionError: boolean = false;
  expTeam: any[];
  eduTeam: any[];
  iniTeam: any[];
  currentTeamExp: any;
  currentTeamEdu: any;
  UserID: any = {};

  constructor(private modalService: BsModalService, private teamService: TeamService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getTeams();
    });
  }

  getTeams(){
    this.UserID = null;
    const user_id = this.member.student_info.id;
    if (user_id) {
      this.UserID = this.member.student_info.id;
      this.teamService.getUserTeams(user_id)
      .subscribe(response => {
        this.expTeam = response.response.exp_team;
        this.eduTeam = response.response.edu_team;
        this.iniTeam = response.response.ini_team;
      });
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.actionError = false;
    }, 4000);
   }

  cancelTeam(id: any){
    this.teamService.deleteTeamMember(id)
    .subscribe(response => {
      let index = this.expTeam.findIndex(obj => obj.id == id);
      this.expTeam.splice(index, 1);
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

  cancelEduTeam(id: any){
    this.teamService.deleteEduTeamMember(id)
    .subscribe(response => {
      let index = this.eduTeam.findIndex(obj => obj.id == id);
      this.eduTeam.splice(index, 1);
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

  cancelIniTeam(id: any){
    this.teamService.deleteIniTeamMember(id)
    .subscribe(response => {
      let index = this.iniTeam.findIndex(obj => obj.id == id);
      this.iniTeam.splice(index, 1);
    }, error => {
      this.actionError = true;
      this.fadeOutErrorMsg();
    })
  }

}
