<section class="dashboardLanding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 rightWrap">
              <div class="rightWrapInner">
                <div class="searchContent">
                  <div class="eachHdg">
                    <h3 class="">searches</h3>
                  </div>
                  <div class="searchBy">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                            <div class="eachSearchByInner" [routerLink]="['/search-pages']">
                              <img alt="searchBg" class="searchBg" src="assets/images/pagesSearch.jpg">
                              <div class="overlayName">
                                <p>Schools</p>
                              </div>
                            </div>
                          </div>
                      <div class="col-xl-4 col-lg-4 col-12 eachSearchBy"
                        *ngIf="verifiers.length >= 3 || user.approval_status && user.user_role == '2'">
                        <div class="eachSearchByInner forObjectTop" [routerLink]="['/student-search']">
                          <img alt="searchBg" class="searchBg" src="assets/images/studentsSearch.jpg">
                          <div class="overlayName">
                            <p>Students</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-12 eachSearchBy">
                        <div class="eachSearchByInner" [routerLink]="['/search-initiative']">
                          <img alt="searchBg" class="searchBg" src="assets/images/initSearch.jpeg">
                          <div class="overlayName">
                            <p>Initiatives</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
