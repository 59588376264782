import { Component, OnInit, TemplateRef, Input, EventEmitter, Output} from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { PageService } from 'src/app/services/page.service';
import { TeamService } from 'src/app/services/team.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-school-involvement',
  templateUrl: './school-involvement.component.html',
  styleUrls: ['./school-involvement.component.scss']
})
export class SchoolInvolvementComponent implements OnInit {
  @Output() exp = new EventEmitter<any>();
  modalRef: BsModalRef;
  updateModalRef: BsModalRef;
  schoolInvForm: FormGroup;
  searchForm: FormGroup;
  schoolInvUpdForm: FormGroup;
  verifierForm: FormGroup;
  pageForm: FormGroup;
  schoolInvolvements = [];
  clubsAndTeams = [];
  searchResults = [];
  verifiers = [];
  schools = [];
  searchSchools = [];
  editSchoolInv: any = {};
  selectedSchool: any = {};
  hideResults: boolean = false;
  previewShow: boolean = false;
  formShow: boolean = false;
  pageformShow: boolean = false;
  verifierFormShow: boolean = false;
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  isEndDate: boolean =false;
  today = new Date();
  schoolId: string = null;
  verifierId: string = null;
  @Input() user: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  pageImage: string = null;
  canSrchFirstname: boolean = false;
  canSrchLastname: boolean = false;
  public exp_end_one_day: boolean = false;
  canCreatePage: boolean = false;
  selectedExp: any = {};
  schoolInvolvementsTotal: any = 0;
  teamMember: any = [];
  teamMemberSliced: any = [];
  page_size = 6;
  teamError: boolean = false;
  otherSchool: boolean = false;

  constructor(private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder,
    private profileService: ProfileService, private customValidator: CustomvalidationService, private pageService: PageService, private teamService: TeamService,
    private authService: AuthenticationService) {

    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school: new FormControl('', Validators.required),
      school_name: new FormControl(''),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('')
    }, { validators: this.dateValidator });
    this.schoolInvForm = this.formBuilder.group({
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(1125)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(2250)]),
    });
    this.schoolInvUpdForm = this.formBuilder.group({
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      skill_1: new FormControl('', Validators.maxLength(30)),
      skill_2: new FormControl('', Validators.maxLength(30)),
      skill_3: new FormControl('', Validators.maxLength(30)),
      main_role: new FormControl('', Validators.maxLength(1125)),
      personal_details: new FormControl('', Validators.maxLength(2250)),
    }, { validators: this.dateValidator });
    this.verifierForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)])
    });
    this.pageForm = this.formBuilder.group({
      organization_type: new FormControl('', Validators.required),
      organization_title: new FormControl('', [Validators.required, Validators.maxLength(125),this.noWhitespaceValidator]),
    });

  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit(): void {
    this.getSchoolInvRecent();
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0,0,0,0);
      end_date = new Date(end_date).setHours(0,0,0,0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }
    return null;
  }

  canEdit(){
    if (this.editSchoolInv.page) {
      return true;
    } else if (this.editSchoolInv.page == null) {
      return true;
    } else {
      return false;
    }
  }

  canEditEndDate(){
    if (!this.editSchoolInv.end_date) {
      return true;
    } else {
    return false;
    }
  }

  resetEndDate(){
    this.schoolInvUpdForm.controls['end_date'].setValue(null);
    this.searchForm.controls['end_date'].setValue(null);
  }

  endDate(e){
    const key = e.target.value;
    if(key == "1"){
      this.isEndDate = true;
      this.searchForm.get('end_date').setValidators([Validators.required]);
      this.searchForm.get('end_date').updateValueAndValidity();
    }else{
      this.isEndDate = false;
      this.searchForm.get('end_date').clearValidators();
      this.searchForm.get('end_date').updateValueAndValidity();
      this.searchForm.controls['end_date'].setValue(null);
    }
  }

  resetStartDate(){
    this.schoolInvUpdForm.controls['start_date'].setValue(null);
    this.searchForm.controls['start_date'].setValue(null);
  }

  getSchoolInv(){
    this.profileService.getSchoolInvolvement()
    .subscribe(response => {
      if(response.response.length > 0){
          this.schoolInvolvements = response.response;
          this.schoolInvolvementsTotal = response.response.length;
        } else {
          this.schoolInvolvements = [];
        }
      });
  }

  getSchoolInvRecent(){
    this.profileService.getSchoolInvolvementRecent()
    .subscribe(response => {
      if(response.response.results.length > 0){
          this.schoolInvolvements = response.response.results;
          this.schoolInvolvementsTotal = response.response.total_count;
        } else {
          this.schoolInvolvements = [];
        }
      });
  }

  expModal(expTemplate: TemplateRef<any>) {
    this.teamMember = [];
    this.teamMemberSliced = [];
    this.otherSchool = false;
    this.schoolId = null;
    this.verifierId = null;
    this.pageImage = null;
    this.previewShow = false;
    this.formShow = false;
    this.pageformShow = false;
    this.verifierFormShow = false;
    this.submitted = false;
    this.partialSubmitted = false;
    this.isEndDate = false;
    this.searchForm.reset();
    this.schoolInvForm.reset();
    this.verifierForm.reset();
    this.hideResults = true;
    this.clubsAndTeams = [];
    this.getUserSchools();
    this.selectedSchool = {};
    this.selectedExp = {};
    this.teamError = false;
    this.searchForm.get('end_date').clearValidators();
    this.searchForm.get('end_date').updateValueAndValidity();
    this.searchForm.controls['end_date'].setValue(null);
    this.pageForm.controls['organization_type'].setValue(1, {onlySelf: true});
    this.modalRef = this.modalService.show(expTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.schoolId = null;
    this.pageImage = null;
    this.editSchoolInv = exp;
    this.schoolInvUpdForm.controls['start_date'].setValue(exp.start_date);
    this.schoolInvUpdForm.controls['end_date'].setValue(exp.end_date);
    this.schoolInvUpdForm.controls['skill_1'].setValue(exp.skill_1);
    this.schoolInvUpdForm.controls['skill_2'].setValue(exp.skill_2);
    this.schoolInvUpdForm.controls['skill_3'].setValue(exp.skill_3);
    this.schoolInvUpdForm.controls['main_role'].setValue(exp.main_role);
    this.schoolInvUpdForm.controls['personal_details'].setValue(exp.personal_details);
    this.hideResults = true;
    this.clubsAndTeams = [];
    this.updateModalRef = this.modalService.show(editExpTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal'})
    );
  }

  showForm(isValid: boolean) {
    this.partialSubmitted = false;
    if (this.otherSchool && Object.keys(this.selectedSchool).length === 0){
      this.searchForm.get('school_name').setErrors({ aftersubmit: 'You must tag a school in order to add School Involvement' });
      return;
    }
    if (isValid){
      this.formShow = !this.formShow;
      let exp_start_date: any = this.searchForm.controls['start_date'].value;
      let exp_end_date: any = this.searchForm.controls['end_date'].value;
      if (this.user.age_group == 1) {
        this.canCreatePage = false;
      } else {
        this.canCreatePage = true;
      }
      if (this.schoolId || !this.canCreatePage) {
        this.verifierFormShow = !this.verifierFormShow;
      } else {
        this.pageformShow = !this.pageformShow;
        this.pageForm.controls['organization_title'].setValue(this.searchForm.get('title').value);
      }
      if (exp_end_date && exp_start_date.getTime() === exp_end_date.getTime()){
        this.exp_end_one_day = true;
      } else {
        this.exp_end_one_day = false;
      }
    } else {
      this.partialSubmitted = true;
    }
  }

  showPageForm(isValid: boolean, isReturn: boolean=false) {
    this.partialSubmitted = false;
    if (isValid){
      this.searchForm.controls['title'].setValue(this.pageForm.get('organization_title').value);
      if (this.schoolId) {
        this.formShow = !this.formShow;
        this.verifierFormShow = !this.verifierFormShow;
      } else {
        this.pageformShow = !this.pageformShow;
        this.verifierFormShow = !this.verifierFormShow;
      }
    } else {
      this.partialSubmitted = true;
    }
  }

  showPreview(isValid: boolean){
    if (isValid){
      this.addSchoolInv();
    } else {
      this.submitted = true;
    }
  }

  getUserSchools(){
    this.schools = [];
      this.profileService.getUserSchools()
      .subscribe(response => {
        if (response.response.length > 0) {
          this.schools = response.response;
        } else {
          this.schools = [];
        }
      }, error => {
        this.schools = [];
      })
  }

  getVerifiers(){
    if (this.schoolId) {
      this.profileService.getPageVerifiers(this.schoolId)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.verifiers = response.response;
        } else {
          this.verifiers = [];
        }
      }, error => {
        this.verifiers = [];
      })
    }
  }

  searchSchool(e){
    let key = e.target.value;
    this.selectedSchool = {};
    this.schoolId = null;
    this.searchForm.controls['title'].setValue(null);
    if (key !== null && key.length > 0) {
      this.searchSchools = [];
      let params = key.toLowerCase();
      this.authService.searchSchool(params)
      .subscribe(response => {
        if (response.status == 200){
          this.searchSchools = response.response;
          if (this.searchSchools.length > 0) {
            this.hideResults = false;
          } else {
            this.hideResults = true;
          }
        }
      });
    } else {
      this.hideResults = true;
      this.searchSchools = [];
    }
  }

  selectSchool(school) {
    this.hideResults = true;
    this.searchForm.controls['school_name'].setValue(school.school_name);
    let schoolId = school.id.toString();
    this.selectedSchool = school;
    this.searchSchools = [];
    if (schoolId !== null && schoolId.length > 0) {
      this.clubsAndTeams = [];
      let params = '&school_id=' + schoolId;
      this.profileService.searchClubTeamPage(params)
      .subscribe(response => {
        if (response.status == 200){
          this.clubsAndTeams = response.response;
        }
      });
    } else {
      this.clubsAndTeams = [];
    }
  }

  searchfocusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.searchSchools = [];
    }, 1000);
  }

  getPageBySchool(e){
    this.selectedSchool = {};
    this.schoolId = null;
    this.searchForm.controls['title'].setValue(null);
    this.searchForm.controls['school_name'].setValue(null);
    if (this.user.age_group == 1) {
      return;
    }
    if (e.target.value == 0) {
      this.otherSchool = true;
      return;
    } else {
      this.otherSchool = false;
    }
    let schoolId = e.target.value;
    let index = this.schools.findIndex(obj => obj.id === parseInt(schoolId));
    if (index > -1){
      this.selectedSchool = this.schools[index];
    }
    if (schoolId !== null && schoolId.length > 0) {
      this.clubsAndTeams = [];
      let params = '&school_id=' + schoolId;
      this.profileService.searchClubTeamPage(params)
      .subscribe(response => {
        if (response.status == 200){
          this.clubsAndTeams = response.response;
        }
      });
    } else {
      this.clubsAndTeams = [];
    }
  }

  searchOrg(e, update:boolean=false){
    if (this.user.age_group == 1) {
      return;
    }
    let key = e.target.value;
    let school = this.searchForm.get('school').value;
    this.schoolId = null;
    this.verifierId = null;
    this.pageImage = null;
    if (key !== null && key.length > 0 && school !== null && school.length > 0) {
      this.searchResults = this.clubsAndTeams.filter(item => item.page_title.search(new RegExp(key, 'i')) > -1);
      if (this.searchResults.length > 0) {
        this.hideResults = false;
      } else {
        this.hideResults = true;
      }
    } else {
      this.hideResults = true;
      this.searchResults = [];
    }
  }

  selectOrg(org: any, update:boolean=false) {
    this.hideResults = true;
    this.searchForm.controls['title'].setValue(org.page_title);
    this.schoolId = org.id;
    this.pageImage = org.display_pic;
    this.searchResults = [];
  }

  selectVerifier(verifier: any){
    if (verifier.id) {
      this.verifierId = verifier.id;
      this.canSrchFirstname = false;
      this.canSrchLastname = false;
      this.verifierForm.controls['first_name'].setValue(verifier.first_name);
      this.verifierForm.controls['last_name'].setValue(verifier.last_name);
      this.verifierForm.controls['email'].setValue(verifier.email);
    }
  }

  firstnameSearch() {
    this.canSrchFirstname = true;
    this.verifierId = null;
    this.verifierForm.controls['email'].setValue(null);
  }

  lastnameSearch() {
    this.canSrchLastname = true;
    this.verifierId = null;
    this.verifierForm.controls['email'].setValue(null);
  }

  focusOutEmail(){
    let email = this.verifierForm.get('email').value;
    let first_name = this.verifierForm.get('first_name').value;
    let last_name = this.verifierForm.get('last_name').value;
    if (email && first_name && last_name) {
      let index = this.verifiers.findIndex(obj => obj.email.toLowerCase() === email.toLowerCase());
      if (index > -1 && this.verifierId == null) {
        let verif = this.verifiers[index];
        if (verif.first_name.toLowerCase() == first_name.toLowerCase() && verif.last_name.toLowerCase() == last_name.toLowerCase()) {
          this.selectVerifier(this.verifiers[index]);
        } else {
          this.verifierForm.get('email').setErrors({ aftersubmit: 'Professional Reference with this email already exists.' });
        }
      }
    }
  }

  addSchoolInv() {
    let page_data = null;
    if (!this.schoolId && this.canCreatePage) {
      page_data = {'account_type': this.pageForm.get('organization_type').value,
                    'title': this.pageForm.get('organization_title').value,
                    'school': this.selectedSchool.id}
    }
    let start_date = this.datePipe.transform(this.searchForm.get('start_date').value,"yyyy-MM-dd");
    let end_Date =  this.datePipe.transform(this.searchForm.get('end_date').value,"yyyy-MM-dd");
    let model = {'page': this.schoolId,
                'title': this.searchForm.get('title').value,
                'user': this.user.id,
                'school': this.selectedSchool.id,
                'position': this.searchForm.get('position').value,
                'start_date': start_date,
                'end_date': end_Date,
                'page_data': page_data}
    this.profileService.addSchoolInvolvement(model)
    .subscribe(response => {
      this.handleInvResponse(response);
    }, error => {
        this.handleInvSubmitError(error.error);
    })
  }

  updateSchoolInv(isValid: boolean){
    this.submitted = true;
    let clubTeamId = this.editSchoolInv.page;
    if (this.schoolId !== null) {
      clubTeamId = this.schoolId;
    }
    if (isValid) {
      let start_date = this.datePipe.transform(this.schoolInvUpdForm.get('start_date').value, "yyyy-MM-dd");
      let end_Date =  this.datePipe.transform(this.schoolInvUpdForm.get('end_date').value, "yyyy-MM-dd");
      let model = {'user': this.user.id,
                  'position': this.editSchoolInv.position,
                  'start_date': start_date,
                  'end_date': end_Date,
                  'skill_1': this.schoolInvUpdForm.get('skill_1').value,
                  'skill_2': this.schoolInvUpdForm.get('skill_2').value,
                  'skill_3': this.schoolInvUpdForm.get('skill_3').value,
                  'main_role': this.schoolInvUpdForm.get('main_role').value,
                  'personal_details': this.schoolInvUpdForm.get('personal_details').value}
      this.profileService.updateSchoolInvolvement(model, this.editSchoolInv.id)
      .subscribe(response => {
        this.handleInvUpdResponse(response);
      }, error => {
          this.handleInvSubmitError(error.error);
      })
    }
  }

  delSchoolInv(){
    this.profileService.deleteSchoolInvolvement(this.editSchoolInv.id)
    .subscribe(response => {
      if (this.schoolInvolvementsTotal === this.schoolInvolvements.length){
        let index = this.schoolInvolvements.findIndex(obj => obj.id === this.editSchoolInv.id)
        this.schoolInvolvements.splice(index, 1);
        this.schoolInvolvementsTotal = this.schoolInvolvements.length;
      }
      else {
        this.getSchoolInvRecent();
      }
      this.updateModalRef.hide();
      this.editSchoolInv = {};
      this.schoolInvUpdForm.reset();
    })
  }

  protected handleInvResponse(response: any) {
    if (response.status === 111) {
      this.handleInvSubmitError(response.message);
    } else if (response.status === 200) {
        this.verifierFormShow = !this.verifierFormShow;
        this.previewShow = !this.previewShow;
        if (response.response.page) {
          this.getTeamMembers(response.response);
        }
        this.schoolInvolvements.push(response.response);
        this.schoolInvolvements.sort(this.custom_sort);
        this.schoolInvolvementsTotal = this.schoolInvolvementsTotal + 1;
        this.searchForm.reset();
        this.partialSubmitted = false;
        this.submitted = false;
    }
  }

  protected handleInvUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleInvSubmitError(response.message);
    } else if (response.status === 200) {
      let index = this.schoolInvolvements.findIndex(obj => obj.id === this.editSchoolInv.id)
      this.schoolInvolvements[index] = response.response;
        this.updateModalRef.hide();
        this.schoolInvUpdForm.reset();
        this.submitted = false;
    }
  }

  protected handleInvSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.schoolInvUpdForm.contains(field)) {
        this.schoolInvUpdForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.searchResults = [];
      this.canSrchFirstname = false;
      this.canSrchLastname = false;
    }, 1000);
  }

  redirectPage(experience: any){
    if (experience.page && !experience.related_data.is_deleted) {
      let url = '/page/' + experience.page;
      if (experience.related_data.is_related || experience.related_data.is_unclaimed) {
        url = '/page/' + experience.page + '/profile-school';
      }
      this.router.navigate([url]);
    }
  }

  custom_sort(a: any, b: any) {
    return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
  }

  extraCurricularListing(extraCurricularListModal: TemplateRef<any>) {
    if (this.schoolInvolvements.length !== this.schoolInvolvementsTotal) {
      this.getSchoolInv();
    }
    this.modalRef = this.modalService.show(extraCurricularListModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  addReferenceListing(addReferenceModal: TemplateRef<any>, experience: any) {
    this.previewShow = false;
    this.selectedExp = experience;
    this.getReferences();
    this.verifierForm.reset();
    this.updateModalRef = this.modalService.show(addReferenceModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' })
    );
  }

  getReferences(){
    this.verifiers = [];
    if (this.selectedExp.page) {
      this.profileService.getPageVerifiers(this.selectedExp.page)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.verifiers = response.response;
        } else {
          this.verifiers = [];
        }
      }, error => {
        this.verifiers = [];
      })
    }
  }

  addReference(isValid: boolean){
    if (isValid){
      let model = {'first_name': this.verifierForm.get('first_name').value,
                    'last_name': this.verifierForm.get('last_name').value,
                    'email': this.verifierForm.get('email').value,
                    'exp_id': this.selectedExp.id}
      this.profileService.addSchoolInvReference(model)
      .subscribe(response => {
        if (response.status === 200) {
            this.previewShow = !this.previewShow;
            this.verifierForm.reset();
            let index = this.schoolInvolvements.findIndex(obj => obj.id === this.selectedExp.id);
            this.schoolInvolvements[index] = response.response;
            this.submitted = false;
            this.selectedExp = {};
        } else {
          this.verifierForm.get('email').setErrors({aftersubmit: "Something went wrong please try again later."});
        }
      }, error => {
        this.verifierForm.get('email').setErrors({aftersubmit: "Something went wrong please try again later."});
      })
    } else {
      this.submitted = true;
    }
  }

  getTeamMembers(experience: any){
    if (this.selectedSchool) {
      this.selectedExp = experience;
      this.pageService.schInvteamMembers(experience.id)
      .subscribe(response => {
        if(response.status == 200){
            this.teamMember = response.response.experiences;
            this.teamMemberSliced = this.teamMember.slice(0, this.page_size);
          }
      });
    }
  }

  teamLoadMore(){
    let totalMember = this.teamMember.length;
    let slicedMember = this.teamMemberSliced.length;
    if (slicedMember < totalMember){
      this.teamMemberSliced = this.teamMember.slice(0, slicedMember + this.page_size)
    }
  }

  addToTeam(member: any){
    this.teamError = false;
    let model = {'tagged_by_sch': this.selectedExp.id, 'tagged_to_sch': member.id, 'to_user': member.student_info.id};
    this.teamService.addTeam(model)
    .subscribe(response => {
      if(response.status === 200) {
        let index = this.teamMemberSliced.findIndex(obj => obj.id === member.id);
        this.teamMemberSliced[index]['team_id'] = response.response.id;
      } else {
        this.teamError = true;
      }
    }, error => {
      this.teamError = true;
    })
  }

  cancelTeam(member: any) {
    this.teamService.deleteTeamMember(member.team_id)
    .subscribe(response => {
        let index = this.teamMemberSliced.findIndex(obj => obj.id === member.id);
        this.teamMemberSliced[index]['team_id'] = null;
    }, error => {
      this.teamError = true;
    })
  }

  addTeamMember(experience: any){
    if (experience.page && experience.id) {
      let data = {"expId": experience.id, "expType": "2", "pageId": experience.page, "title": experience.title}
      this.exp.emit(data);
    }
  }

  teamMemberView(experience: any){
    if (experience.id && experience.page) {
      let data = {"expId": experience.id, "expType": "school", "title": experience.title}
      this.exp.emit(data);
    }
  }

  resetCount(expId: any, count: any){
    let index = this.schoolInvolvements.findIndex(obj => obj.id === expId);
    this.schoolInvolvements[index].related_data.exp_count = count;
  }

}
