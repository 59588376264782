import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { LoginEndPoint, LogoutEndPoint } from '../app.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app.config.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public static readonly PUBLIC_TOKEN = 'public_user';
  public static readonly USER_TOKEN = 'user';
  private loginUrl: string = LoginEndPoint.apiEndpoint;
  private logoutUrl: string = LogoutEndPoint.apiEndpoint;
  private publicTokenUrl: string = environment.apiURL + 'api/o/token/';
  public publicData: any;
  public publicApiToken: string;

  constructor(private http: BaseService, private configProvider: AppConfigService, private httpClient: HttpClient) {
    this.loadData();
  }

  userLogin(email: string, password: string) {
    return this.http.post(this.loginUrl, { email: email, password: password });
  }

  userLogout() {
    return this.http.put(this.logoutUrl, {});
  }

  private loadData() {
    const publicdata = JSON.parse(localStorage.getItem(LoginService.PUBLIC_TOKEN));
    if (publicdata) {
      this.updatePublicTokenDetail(publicdata);
    }
  }

  get publicToken() {
    return this.publicApiToken;
  }

  getPublicToken(): Observable<any> {
    const localVar = localStorage.getItem(LoginService.PUBLIC_TOKEN);
    if (localVar) {
      return new Observable(observer => {
        return observer.next(JSON.parse(localVar).access_token);
      });
    }
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('username', this.configProvider.config.username);
    urlSearchParams.set('password', this.configProvider.config.password);
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('client_id', this.configProvider.config.client_id);
    urlSearchParams.set('client_secret', this.configProvider.config.client_secret);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    const body = urlSearchParams.toString();
    return this.httpClient.post<any>(this.publicTokenUrl, body, httpOptions).pipe(
      map(response => {
        if (response) {
          localStorage.setItem(LoginService.PUBLIC_TOKEN, JSON.stringify(response));
          this.updatePublicTokenDetail(response);
        }
        return response.access_token;
      })
    );
  }

  updatePublicTokenDetail(publicTokenDetail: any) {
    this.publicData = publicTokenDetail;
    this.publicApiToken = publicTokenDetail.access_token;
    return this;
  }

  refreshAccessToken() {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const data = { refresh_token: currentUser.refresh_token, access_token: currentUser.access_token };
    return this.http.post(`${environment.apiURL}api/accounts/token/`, data);
  }

}
