<header class="no-login" *ngIf="!loggedIn && !hideHeader">
    <nav class="navbar navbar-expand-md navbar-light container-fluid pl-md-5 d-none">
        <a class="navbar-brand" href="/">
            <img src="assets/images/new-logo.png" class="new-logo" alt="logo" /> 
        </a>
        <div class="collapse navbar-collapse">
        </div>
        <div class="d-md-none d-flex">
            <div>
            </div>
        </div>
    </nav>

    <div class="headerSignIn">
        <a href="/">
            <img class="logoSignIn" alt="logo" src="assets/images/logo.svg" />
        </a>
   </div>
</header>
<div *ngIf="loggedIn && !hideHeader" class="d-block d-md-none mobile-header">
    <ul class="d-flex">
        <li>
            <a [routerLink]="['/dashboard']" routerLinkActive="active">
                <img src="/assets/images/Home1.png" alt="home"  class="normal-icon">
                <img src="/assets/images/Home2.png" alt="home"  class="active-icon">
            </a>
        </li>
        <li>
            <a [routerLink]="['/search-page']" routerLinkActive="active">
                <img src="/assets/images/Search1-icon.png" alt="search"  class="normal-icon">
                <img src="/assets/images/Search2.png" alt="search"  class="active-icon">
            </a>
        </li>
        <li class="position-relative">
            <a [routerLink]="['/profile/teams']" routerLinkActive="active">
                <img src="/assets/images/network1.svg" alt="notif1"  class="normal-icon">
                <img src="/assets/images/network2.svg" alt="notif1"  class="active-icon">
                <span class="count" *ngIf="unreadReqCount > 0 && !isExpReqUrl">{{unreadReqCount}}</span>
            </a>
        </li>
        <li *ngIf="user.user_role == '2' && user.age_group > 1" class="position-relative">
            <a [routerLink]="['/message-chat']" routerLinkActive="active">
                <img src="/assets/images/Messen1.png" alt="message"  class="normal-icon">
                <img src="/assets/images/Messeng2.png" alt="message"  class="active-icon">
                <span class="count" *ngIf="messageCount > 0 && !isMsgUrl">{{messageCount}}</span>
            </a>
        </li>
        <li>
            <a [routerLink]="user.age_group > 1 || user.user_role != '2' ? ['/page-listing'] : ['/profile']" routerLinkActive="active">
                <img src="/assets/images/User1.png" alt="user1"  class="normal-icon">
                <img src="/assets/images/User2.png" alt="user1"  class="active-icon">
            </a>
        </li>
    </ul>
</div>

<header *ngIf="loggedIn && !hideHeader">
    <nav class="navbar navbar-expand-md navbar-light container-fluid pl-md-5">
        <div class="col-4">
        </div>
        <div class="col-4 text-center">
            <a class="navbar-brand" href="/dashboard">
                <img src="assets/images/logo.svg" class="logo" alt="logo" />
            </a>
        </div>
        <div class="col-4">
            <div class="collapse navbar-collapse login-header">
                <div class="ml-auto d-none d-md-block">
                    <ul class="d-flex align-items-center mb-0 forActiveHeader">
                        <li>
                            <a class="homeIconHeader" [routerLink]="['/dashboard']" routerLinkActive="activeHeaderMenu" [routerLinkActiveOptions]="{exact:
                                true}">
                                <img alt="icon" class="forNonActiveIcon" src="assets/images/home.svg" />
                                <img alt="icon" class="forActiveIcon" src="assets/images/homeDark.svg" />
                            </a>
                        </li>
                        <li class="ml-3" *ngIf="user.user_role == '2'">
                            <a class="homeIconHeader" [routerLink]="['/profile/teams']" routerLinkActive="activeHeaderMenu" [routerLinkActiveOptions]="{exact:
                                true}">
                                <img alt="icon" class="forNonActiveIcon" src="assets/images/teamReq.png" />
                                <img alt="icon" class="forActiveIcon" src="assets/images/teamReqDark.png" />
                                <span class="count" *ngIf="unreadReqCount > 0 && !isExpReqUrl">{{ unreadReqCount }}</span>
                            </a>
                        </li>
                        <li *ngIf="user.user_role == '2' && user.age_group > 1" class="ml-3">
                            <div class="btn-group" dropdown>
                                <button id="button-basic2" dropdownToggle type="button"
                                    class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic" [ngClass]="{'activeHeaderMenu': url.includes('message')}">
                                    <img alt="icon" class="forNonActiveIcon" src="assets/images/message.svg" />
                                    <img alt="icon" class="forActiveIcon" src="assets/images/messageDark.svg" />
                                    <span class="count" *ngIf="messageCount > 0 && !isMsgUrl">{{messageCount}}</span>
                                </button>
                                <div id="dropdown-basic2" *dropdownMenu
                                    class="dropdown-menu dropdown-menu-right notificationDiv" role="menu"
                                    aria-labelledby="button-basic2">
                                    <h4>Messages</h4>
                                    <ul class="pl-0 mb-0 msgList" *ngIf="messages.length > 0">
                                        <li class="cursor-pointer" *ngFor="let message of messages" [routerLink]="['/message-chat']">
                                            <div class="media">
                                                <img src="{{message.sender_data.display_pic? message.sender_data.display_pic : 'assets/images/p-img.png'}}"
                                                    alt="image">
                                                <div class="media-body ml-2">
                                                    <h5><span style="font-weight: 600;">{{message.sender_data.name}}
                                                        </span><a href="javascript:void();">{{message.text}}</a></h5>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <p *ngIf="messages.length == 0" class="errorText">No messages</p>
                                    <div class="text-center">
                                        <a [routerLink]="['/message-chat']" class="common-button ghost">View All</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="mx-3">
                            <div class="btn-group" dropdown>
                                <button id="button-basic2" dropdownToggle type="button" [ngClass]="{'activeHeaderMenu': url.includes('notifications')}"
                                    class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic">
                                    <img  alt="icon" class="forNonActiveIcon" src="assets/images/notif.svg" />
                                    <img alt="icon" class="forActiveIcon" src="assets/images/notifDark.svg" />
                                    <span class="count" *ngIf="notifCount > 0 && !isNotifUrl">{{notifCount}}</span>
                                </button>
                                <div id="dropdown-basic2" *dropdownMenu
                                    class="dropdown-menu dropdown-menu-right notificationDiv" role="menu"
                                    aria-labelledby="button-basic2">
                                    <h4>Notifications</h4>
                                    <ul class="pl-0 mb-0 msgList" *ngIf="notifications.length > 0">
                                        <li class="cursor-pointer" *ngFor="let notification of notifications" [routerLink]="['/notifications']">
                                            <div class="media">
                                                <img alt="icon" *ngIf="notification.notify_type !== 6"
                                                    src="{{notification.page_info.display_pic? notification.page_info.display_pic : 'assets/images/default.svg'}}">
                                                <img alt="icon" *ngIf="notification.notify_type == 6" src="assets/images/logo.svg">
                                                <div class="media-body ml-2">
                                                    <h5><a href="javascript:void();">{{notification.message}}</a></h5>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <p *ngIf="notifications.length == 0" class="errorText">No notifications</p>
                                    <div class="text-center">
                                        <a href="javascript:void(0)" [routerLink]="['/notifications']" class="common-button ghost">View All</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="btn-group" dropdown>
                                <button id="button-basic" dropdownToggle type="button" [ngClass]="{'activeHeaderMenu': url == 'profile' || url.includes('setting')}"
                                    class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic">
                                    <img alt="icon" class="forNonActiveIcon" src="assets/images/profile.svg" />
                                    <img alt="icon" class="forActiveIcon" src="assets/images/profileDark.svg" />
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                                    aria-labelledby="button-basic">
                                    <li role="menuitem">
                                        <a class="dropdown-item" [routerLink]="['/profile']"
                                            *ngIf="user.user_role == '2'">Profile</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" [routerLink]="['/profile/setting']" *ngIf="user.user_role == '2'">Settings</a>
                                        <a class="dropdown-item" [routerLink]="['/setting']" *ngIf="user.user_role != '2'">Settings</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-md-none d-flex justify-content-end">
                <div>
                    <a href="https://www.aboutcu.today/faq" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <rect width="24" height="24" fill="#577795" rx="12" transform="matrix(-1 0 0 1 24 0)" />
                                <g fill="#FFF" fill-rule="nonzero">
                                    <path
                                        d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                        transform="translate(7.2 6)" />
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="dropdown-toggle custom-dropdown p-0 ml-2"
                        aria-controls="dropdown-basic">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <g>
                                <ellipse cx="10" cy="10" fill="rgb(51,81,117)" rx="10" ry="10" />
                                <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                    d="M5 5h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                                <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                    d="M5 9h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                                <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                    d="M5 13h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                            </g>
                        </svg>
                        <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item" [routerLink]="['/profile']" *ngIf="user.user_role == '2'">Profile</a>
                        </li>
                        <li role="menuitem" class="d-md-none d-block">
                            <a class="dropdown-item" [routerLink]="['/notifications']">Notifications</a>
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item" [routerLink]="['/profile/setting']" *ngIf="user.user_role == '2'">Settings</a>
                            <a class="dropdown-item" [routerLink]="['/setting']" *ngIf="user.user_role != '2'">Settings</a>
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item" href="javascript:void(0)"
                                (click)="settingShow = !settingShow">Logout</a>
                        </li>
                    </ul>
                    <div class="setting-div mt-2 logout-overlay" [ngClass]="{ '': settingShow, show: !settingShow}">
                        <div class="setting-inner">
                            <h3>Logout</h3>
                            <div class="logout-div">
                                <p>Are you sure you want to logout?</p>
                                <button class="btn comman-button" (click)="logout()">Yes</button>
                                <button class="btn cancel-button" (click)="settingShow = !settingShow">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </nav>
</header>
<header class="no-padding" *ngIf="!loggedIn && hideHeader"></header>
