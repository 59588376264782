import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mental-health',
  templateUrl: './mental-health.component.html',
  styleUrls: ['./mental-health.component.scss']
})
export class MentalHealthComponent implements OnInit {

  mentalHealth: any = "mental_health_resources";
  mentalHealthContent: any = "";
  constructor(private authService: AuthenticationService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.authService.staticContent(this.mentalHealth)
    .subscribe(response => {
      this.mentalHealthContent = response;
    });
  }

}
