import { Component, OnInit, TemplateRef , EventEmitter, HostListener} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { LoginService } from '../../services/login.service';
import { first } from 'rxjs/operators';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { ProfileService } from '../../services/profile.service';
import { InitiativeService } from '../../services/initiative.service';
import { TeamService } from 'src/app/services/team.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PageService } from 'src/app/services/page.service';
import { CustomvalidationService } from 'src/app/services/customvalidation.service';
import { DatePipe } from '@angular/common';
import { setExternalUserId } from '../../../assets/js/oneSignal';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss'],
    providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})

export class StudentDashboardComponent implements OnInit {
  loggedIn: boolean = true;
  termsAcceptForm: FormGroup;
  timelineForm: FormGroup;
  timelinelinkForm: FormGroup;
  is_terms_accepted: boolean = false;
  user: any = {};
  modalRef: BsModalRef;
  intModalRef: BsModalRef;
  otherModalRef: BsModalRef;
  reportModalRef: BsModalRef;
  gearsMobile = [];
  public Editor = ClassicEditor;
  pageslist: any = [];
  followedPages: any = [];
  pagetypebusiness: any;
  params: string = "";
  instance_id: string;
  profileImageHeader: string;
  profileImageHeader_name: string;
  pagepic_name: string;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  isFirstLogin: boolean = false;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  slideConfig: any = [];
  initiativeForm: FormGroup;
  submitted: boolean;
  disableSubmit: boolean;
  previewThumbnailName: any = "";
  previewThumbnail: any = false;
  uploadForm: FormGroup;
  blockReportForm: FormGroup;
  coverImageUploadError: boolean;
  coverImageUploadSizeError: boolean;
  coverImageUploadMessage: string;
  coverImageUploadSizeMessage: string;
  totalPageCount: number = 0;
  totalFeedsCount: number = 0;
  totalSchFeedsCount: number = 0;
  totalUsersCount: number = 0;
  totalIntFeedsCount: number = 0;
  totalFollowedCount: number = 0;
  totalNewPagesCount: number = 0;
  getImgCount = 0;
  verifiers = [];
  teamCount: number = 0;
  public infoDiv = true;
  feedsNextPage: any = 1;
  schFeedsNextPage: any = 1;
  intUsersNextPage: any = 1;
  intFeedsNextPage: any = 1;
  followedNextPage: any = 1;
  myPageNextPage: any = 1;
  newPageNextPage: any = 1;
  feeds: any = [];
  schoolFeeds: any = [];
  newPages: any = [];
  editFeedObj: any = {};
  followBtnErr: boolean = false;
  interestedUsers:any = [];
  interestedFeeds:any = [];
  schInvPages: any = [];
  initiatives: any = [];
  radioActive = 1;
  timelineDocs: any = [];
  page_id = 0;
  school_id = 0;
  showSuccessMsg: boolean = false;
  newFollowed: boolean = false;
  isInterested: boolean = false;
  tabActive = 2;
  schools = [];
  scrWidth:any;
  isScrolled: boolean = false;
  isSchool: boolean = false;
  selectedSchClubTeam: any = null;
  linkAdded: boolean = false;
  weeklyData: any = {};
  weekEvntNextPage:any = 1;
  weekEvntData: any = [];
  weekEvntsCount:number = 0;
  copyMessageBool: boolean = false;
  shareUrlIdCheck: any;
  copyMessageCheck:boolean = false;
  descriptionLimit: number= 97;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  constructor(private profileService: ProfileService, private initiativeService: InitiativeService, private loginService: LoginService, public formBuilder: FormBuilder, private router: Router, private modalService: BsModalService,
    private teamService: TeamService, private pageService: PageService, private customValidator: CustomvalidationService, private datePipe: DatePipe) {
      this.getScreenSize();
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user.unique_id) {
        setExternalUserId(this.user.unique_id, this.user.email, true);
      }
      this.termsAcceptForm = this.formBuilder.group({
      terms: new FormControl('', [Validators.required]),
    });
    this.initiativeForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
    this.uploadForm = this.formBuilder.group({
      thumbnail: [''],
      thumbnail_name: ['']
    });
    this.blockReportForm = this.formBuilder.group({
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
    this.timelineForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)]),
      date: new FormControl(''),
    });
    this.timelinelinkForm = this.formBuilder.group({
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
  }



  newInitiativesConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "infinite": true,
    "arrow": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.getImgCount = 0;
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.profileImageHeader = this.user.display_picture;
      this.profileImageHeader_name = this.user.display_picture;
      this.getAllPages();
      this.getSchFeeds();
      this.getFollowedPages();
      this.getInterestedFeeds();
      this.getSchoolInvPages();
      this.getUserInitiatives();
      this.getNewPageNotif();
      this.getWeeklyDetails();
      if (this.user.user_role == '2') {
        this.getTeamCount();
        this.profileService.listVerifiers()
          .subscribe(response => {
            if (response.response.length > 0) {
              let verifiers = response.response;
              this.verifiers = verifiers.filter(obj => obj.status === 2);
            } else {
              this.verifiers = [];
            }
        });
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  createInitiative(createInitiativeTemplate: TemplateRef<any>) {
    this.school_id = 0;
    this.getUserSchools();
    this.initiativeForm.reset();
    this.uploadForm.reset();
    this.previewThumbnail = false;
    this.previewThumbnailName = "";
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(createInitiativeTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
    );
  }

  addInitiative(isValid: boolean, initiativeSuccessTemplate: TemplateRef<any>){
    this.submitted = true;
    this.disableSubmit = true;
    let school = this.school_id;
    if (this.school_id === 0){
      school = null;
    }
    if (isValid){
      let model = {
        'school': school,
        'user': this.user.id,
        'title': this.initiativeForm.get('title').value,
        'city': this.initiativeForm.get('city').value,
        'state': this.initiativeForm.get('state').value,
        'display_picture': this.uploadForm.get('thumbnail').value,
        'display_name': this.uploadForm.get('thumbnail_name').value
      }
      this.initiativeService.addInitiative(model)
        .subscribe(response => {
          this.handleInitiativeResponse(response, initiativeSuccessTemplate);
        }, error => {
          this.handleSubmitError(error.error)
        })
    }
  }

  coverImageUpload(event, isPost: boolean = false){
    let fileSizeVal = 2000;
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (isPost){
      fileSizeVal = 5000;
    }
    this.coverImageUploadError = false;
    this.coverImageUploadSizeError = false;
    this.coverImageUploadMessage = "";
    this.coverImageUploadSizeMessage = "";
    if (event.target.files.length > 0) {
      let fileCount = this.timelineDocs.length + event.target.files.length;
      if (fileCount > 5) {
        this.coverImageUploadError = true;
        this.coverImageUploadMessage = "Maximum 5 photos allowed.";
        event.target.value = null;
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        let file_name = event.target.files[i].name;
        const fileExtension = file_name.split('.').pop();
        if (file_name.length > 30) {
          file_name = "cu-media." + fileExtension;
        }
        const fileSize = Math.round(file.size / 1024);
        if (!allowedExtensions.includes(fileExtension)) {
          this.coverImageUploadError = true
          this.coverImageUploadMessage = "Only image files allowed.";
        } else {
          this.coverImageUploadError = false;
          this.coverImageUploadMessage = "";
        }
        if (fileSize > fileSizeVal) {
          this.coverImageUploadSizeError = true
          this.coverImageUploadSizeMessage = "Image size should be less than 2MB.";
          if (isPost) {
            this.coverImageUploadSizeMessage = "Image size should be less than 5MB.";
          }
        } else {
          this.coverImageUploadSizeError = false;
          this.coverImageUploadSizeMessage = "";
        }
        if (!this.coverImageUploadError && !this.coverImageUploadSizeError) {
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e): void => {
            const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
            )[1];
            if (isPost && this.timelineDocs.length < 5) {
              let timelineDoc = { 'previewImg': reader.result, 'document': base64String, 'document_name': file_name };
              this.timelineDocs.unshift(timelineDoc);
            } else {
              this.previewThumbnail = reader.result;
              this.previewThumbnailName = file_name;
              this.uploadForm.get('thumbnail').setValue(base64String);
              this.uploadForm.get('thumbnail_name').setValue(file_name);
            }
            event.target.value = null;
          };
        } else {
          event.target.value = null;
          setTimeout(() => {this.coverImageUploadError = false;this.coverImageUploadSizeError = false;}, 5000);
          break;
        }
      }
    }
  }

  protected handleInitiativeResponse(response: any, initiativeSuccessTemplate: TemplateRef<any>) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.modalRef = this.modalService.show(initiativeSuccessTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
      this.myPageNextPage = 1;
      this.getAllPages();
      this.totalPageCount = this.totalPageCount + 1;
      this.initiativeForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.previewThumbnailName = "";
      this.previewThumbnail = false;
      this.coverImageUploadError = false;
      this.coverImageUploadMessage = "";
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.initiativeForm.contains(field)) {
        this.initiativeForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  getImageUrl() {
    this.profileImageHeader = "assets/images/p-img.png";
    if (this.getImgCount === 0){
      this.profileService.getUserImage(this.user.id)
      .subscribe(response => {
        if (response.status === 200) {
          if (response.response.display_picture){
            this.profileImageHeader = response.response.display_picture;
            this.user.display_picture = response.response.display_picture;
          }
          if (response.response.header_image){
            this.user.header_image = response.response.header_image;
          }
          localStorage.setItem('user', JSON.stringify(this.user));
        }
      })
    }
    this.getImgCount = this.getImgCount + 1;
  }

  OpenPage(page: any) {
    if ([1,2].indexOf(page.page_info.page_type) > -1) {
      this.router.navigate(['page/' + page.id + '/profile-business']);
    } else if (page.page_info.page_type === 5) {
      this.router.navigate(['edit-initiatives/' + page.id + '/'])
    } else {
      this.router.navigate(['page/' + page.id + '/profile-school']);
    }
  }

  OpenPageDashboard(page: any) {
    if ([1,2].indexOf(page.page_info.page_type) > -1) {
      this.router.navigate(['page/' + page.id + '/profile-business']);
    } else if (page.page_info.page_type === 5) {
      this.router.navigate(['edit-initiatives/' + page.id + '/']);
    } else {
      this.router.navigate(['page/' + page.id + '/profile-school']);
    }
  }

  OpenPageSettings(pagee: any) {
    let url = 'page/' + pagee.id;
    if ([1,2].indexOf(pagee.page_info.page_type) > -1) {
      url = url + '/profile-business?tab=setting';
      this.router.navigateByUrl(url);
    } else {
      url = url + '/profile-school?tab=setting';
      this.router.navigateByUrl(url);
    }
  }

  redirectToProfile() {
    if (this.user.user_role == "2") {
      this.router.navigate(['/profile']);
    } else if (this.user.user_role == "3") {
      this.router.navigate(['/profile-school']);
    } else {
      this.router.navigate(['/profile-business']);
    }
  }

  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal' }));
  }
  showTwo() {
    this.stepOne = false;
    this.stepTwo = true;
  }
  showThree() {
    this.stepTwo = false;
    this.stepThree = true;
  }
  goBack() {
    this.stepTwo = true;
    this.stepThree = false;
  }
  logout() {
    this.loginService.userLogout()
      .subscribe(
        data => {
          if (this.user.unique_id) {
            setExternalUserId(this.user.unique_id, this.user.email, false);
          }
          localStorage.removeItem('user');
          window.location.href = '/';
          this.loadPublicToken();
        },
        error => { }
      )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
      .pipe(first())
      .subscribe(
        data => {
        },
        error => {

        });
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.is_terms_accepted = true;
    } else {
      this.is_terms_accepted = false;
    }
  }

  submitTermsAcceptForm() {
    if (this.is_terms_accepted) {
      var data = { 'is_terms_accepted': this.is_terms_accepted }
      this.profileService.AcceptTerms(data, this.user.id).subscribe(response => {
        this.handleResponse(response);
      })
    }
  }

  handleResponse(response: any) {
    if (response.status === 200) {
        this.user.age_group_updated = response.response.age_group_updated;
        this.user.age_group = response.response.age_group;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
    }
  }

  getAllPages(){
    if (this.myPageNextPage) {
      let params = '?page=' + this.myPageNextPage;
      this.profileService.getMyPages(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.myPageNextPage == 1) {
            this.pageslist = response.response.results;
          } else {
            this.pageslist = this.pageslist.concat(response.response.results);
          }
          this.totalPageCount = response.response.total_count;
          this.myPageNextPage = response.response.next;
        } else {
          this.pageslist = [];
          this.totalPageCount = 0;
          this.myPageNextPage = 1;
        }
      });
    }
  }

  getFollowedPages(){
    if (this.followedNextPage) {
      let params = '?page=' + this.followedNextPage;
      this.profileService.getFollowedPages(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.followedNextPage == 1) {
            this.followedPages = response.response.results;
          } else {
            this.followedPages = this.followedPages.concat(response.response.results);
          }
          this.totalFollowedCount = response.response.total_count;
          this.followedNextPage = response.response.next;
        } else {
          this.followedPages = [];
          this.totalFollowedCount = 0;
          this.followedNextPage = 1;
        }
      });
    }
  }

  getFeeds(){
    if (this.feedsNextPage) {
      let params = '?page=' + this.feedsNextPage;
      this.profileService.getFeeds(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.feedsNextPage == 1) {
            this.feeds = response.response.results;
          } else {
            this.feeds = this.feeds.concat(response.response.results);
          }
          this.feedsNextPage = response.response.next;
          this.totalFeedsCount = response.response.total_count;
        }
      });
    }
  }

  getSchFeeds(){
    if (this.schFeedsNextPage) {
      let params = '?page=' + this.schFeedsNextPage;
      this.profileService.getSchFeeds(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.schFeedsNextPage == 1) {
            this.schoolFeeds = response.response.results;
          } else {
            this.schoolFeeds = this.schoolFeeds.concat(response.response.results);
          }
          this.schFeedsNextPage = response.response.next;
          this.totalSchFeedsCount = response.response.total_count;
        }
      });
    }
  }

  getNewPageNotif(){
    if (this.newPageNextPage) {
      let params = '?page=' + this.newPageNextPage;
      this.profileService.getNewPagesNotif(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.newPageNextPage == 1) {
            this.newPages = response.response.results;
          } else {
            this.newPages = this.newPages.concat(response.response.results);
          }
          this.newPageNextPage = response.response.next;
          this.totalNewPagesCount = response.response.total_count;
        }
      });
    }
  }

  getTeamCount(){
    this.teamService.getTeamCount()
    .subscribe(response => {
      if (response.status === 200){
        this.teamCount = response.response.team_count;
      }
    });
  }

  getSchoolInvPages(){
    this.pageService.schoolInvPages()
      .subscribe(response => {
        if (response.status === 200) {
          this.schInvPages = response.response;
        } else {
          this.schInvPages = [];
        }
      }, error => {
          this.schInvPages = [];
      });
  }

  getUserInitiatives(){
    this.pageService.userInitiatives()
      .subscribe(response => {
        if (response.status === 200) {
          this.initiatives = response.response;
        } else {
          this.initiatives = [];
        }
      }, error => {
          this.initiatives = [];
      });
  }

  reportModal(reportTemplate: TemplateRef<any>, feed: any) {
    this.blockReportForm.reset();
    this.submitted = false;
    this.editFeedObj = feed;
    this.reportModalRef = this.modalService.show(reportTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui custom-profile-modal' }
    ));
  }

  feedDetailModal(feedDetail: TemplateRef<any>, feed: any, isInterested: boolean = false) {
    this.editFeedObj = feed;
    this.isInterested = isInterested;
    this.modalRef = this.modalService.show(feedDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui mobile-position newfeeddetail' })
    );
  }

  getWeeklyDetails(){
    if (this.weekEvntNextPage) {
      let params = '?page=' + this.weekEvntNextPage;
      this.profileService.getWeeklyDetails(params)
      .subscribe(response => {
        if (response.response.results.length > 0) {
          if (this.weekEvntNextPage == 1) {
            this.weekEvntData = response.response.results;
          } else {
            this.weekEvntData = this.weekEvntData.concat(response.response.results);
          }
          this.weekEvntNextPage = response.response.next;
          this.weekEvntsCount = response.response.total_count;
        }
      });
    }
  }

  checkExtraData(feed: any){
    if (feed.link && feed.metadata_title && (feed.metadata_description || feed.metadata_image_link)){
      return true;
    } else if (feed.link && !feed.metadata_title) {
      return false;
    } else {
      return false;
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true;
    } else {
      return false;
    }
  }

  reportFeed(isValid: boolean) {
    if (isValid) {
      var report_data = {
        'reason': this.blockReportForm.value['report_reason'],
        'description': this.blockReportForm.value['report_description']
      }
      if (this.editFeedObj.post_info.post_type === 1) {
        report_data['page_general'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 2) {
        report_data['reported_position'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 3) {
        report_data['reported_event'] = this.editFeedObj.id;
      } else if (this.editFeedObj.post_info.post_type === 4) {
        report_data['initiative_general'] = this.editFeedObj.id;
      }  else if ([5, 10].includes(this.editFeedObj.post_info.post_type)){
        report_data['reported_volunteer'] = this.editFeedObj.id;
      } else if ([6, 7, 8].includes(this.editFeedObj.post_info.post_type)){
        report_data['school_timeline'] = this.editFeedObj.id;
      }
      this.profileService.reportFeed(report_data).subscribe(respones => {});
      let index = this.feeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (index !== -1) {
        this.feeds.splice(index, 1);
        this.totalFeedsCount = this.totalFeedsCount - 1;
      }
      let schIndex = this.schoolFeeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (schIndex !== -1) {
        this.schoolFeeds.splice(schIndex, 1);
        this.totalSchFeedsCount = this.totalSchFeedsCount - 1;
      }
      this.reportModalRef.hide();
      this.closeModal();
    } else {
      this.submitted = true;
    }
  }

  directToProfile(feed: any){
    if (feed.post_info.creator_id === this.user.id) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['profile/' + feed.post_info.creator_id + '/']);
    }
  }

  directToPage(feed: any) {
    if (feed.post_info.ini_id) {
      this.router.navigate(['initiatives/' + feed.post_info.ini_id + '/']);
    } else if (feed.post_info.page_id) {
      this.router.navigate(['page/' + feed.post_info.page_id + '/']);
    } else if (feed.post_info.school_id) {
      this.router.navigate(['school/' + feed.post_info.school_id + '/']);
    }
  }

  openCreatePostModal(template: TemplateRef<any>, initiative: boolean = false, isValidatorChange: boolean = false) {
    this.linkAdded = false;
    let count = this.schInvPages.length;
    if (initiative){
      count = this.initiatives.length;
    }
    if (count > 0) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      this.showSuccessMsg = false;
      this.page_id = 0;
      this.radioActive = 1;
      this.selectedSchClubTeam = null;
      this.isSchool = false;
      this.timelineDocs = [];
      this.timelineForm.reset();
      this.timelinelinkForm.reset();
      this.submitted = false;
      this.changeValidation(isValidatorChange);
      this.modalRef = this.modalService.show(template,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui' })
      );
    }
  }

  changeValidation(changeVal){
    if(changeVal == true){
      this.timelineForm.get('title').setValidators([Validators.required, Validators.maxLength(50)]);
      this.timelineForm.get('description').setValidators([Validators.required, Validators.maxLength(1000)]);
    }  else {
      this.timelineForm.get('title').setValidators([Validators.required, Validators.maxLength(75)]);
      this.timelineForm.get('description').setValidators([Validators.required, Validators.maxLength(2250)]);
    }
  }

  openInterestedFeedModal(template: TemplateRef<any>) {
    if (this.totalIntFeedsCount > 0) {
      this.intModalRef = this.modalService.show(
        template,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui interested-popup' })
      );
    }
  }

  openModalFollow(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall custom-profile-modal' }));
  }

  openLinkModal(template: TemplateRef<any>) {
    this.linkAdded = false;
    this.intModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall custom-profile-modal' }));
  }

  openModalOthers(template: TemplateRef<any>, feed: any) {
    this.intUsersNextPage = 1;
    this.interestedUsers = [];
    this.editFeedObj = feed;
    this.getInterestedUsers();
    this.otherModalRef = this.modalService.show(
      template, Object.assign({}, { class: 'modal-dialog-centered custom-modal new-modal-ui followall others' })
    );
  }

  unFollowPageIni(instance: any){
    if (instance.page_info.page_ini_id) {
      if (instance.page_info.page_type === 5) {
        this.unFollowInitiative(instance);
      } else {
        this.unFollowPage(instance);
      }
    }
  }

  unFollowInitiative(instance: any){
    this.followBtnErr = false;
    this.pageService.unFollowInitiative(instance.page_info.page_ini_id, {})
    .subscribe(response => {
      let index = this.followedPages.findIndex(obj => obj.id === instance.id);
      this.followedPages.splice(index, 1);
      this.totalFollowedCount = this.totalFollowedCount - 1;
    }, error => {
      this.followBtnErr = true;
      this.fadeOutFollowErrMsg();
    });
  }

  unFollowPage(instance: any){
    this.followBtnErr = false;
    this.pageService.unFollowPage(instance.page_info.page_ini_id, {})
    .subscribe(response => {
      let index = this.followedPages.findIndex(obj => obj.id === instance.id);
      this.followedPages.splice(index, 1);
      this.totalFollowedCount = this.totalFollowedCount - 1;
    }, error => {
      this.followBtnErr = true;
      this.fadeOutFollowErrMsg();
    });
  }

  fadeOutFollowErrMsg() {
    setTimeout(() => {
      this.followBtnErr = false;
      this.showSuccessMsg = false;
    }, 3000);
  }

  directToPageIni(instance: any){
    if (instance.page_info.page_type === 5) {
      if (instance.page_info.owner_id === this.user.id) {
        this.router.navigate(['edit-initiatives/' + instance.page_info.page_ini_id + '/']);
      } else {
        this.router.navigate(['initiatives/' + instance.page_info.page_ini_id + '/']);
      }
    } else {
      if (instance.page_info.owner_id === this.user.id) {
        this.router.navigate(['page/' + instance.page_info.page_ini_id + '/profile-school']);
      } else {
        this.router.navigate(['page/' + instance.page_info.page_ini_id + '/']);
      }
    }
  }

  markInterested(feed: any){
    if (feed.interested_data.interested) {
      this.pageService.unMarkInterested(feed.id, feed.post_info.post_type)
      .subscribe(response => {
        this.intFeedsNextPage = 1;
        this.getInterestedFeeds();
      });
      let schIndex = this.schoolFeeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (schIndex !== -1) {
        this.schoolFeeds[schIndex].interested_data.interested = false;
      }
      let index = this.feeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].interested_data.interested = false;
      }
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = false;
      }
    } else {
      this.pageService.markInterested(feed.id, feed.post_info.post_type, {})
      .subscribe(response => {
        this.intFeedsNextPage = 1;
        this.getInterestedFeeds();
      });
      let schIndex = this.schoolFeeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (schIndex !== -1) {
        this.schoolFeeds[schIndex].interested_data.interested = true;
      }
      let index = this.feeds.findIndex(obj => obj.id === feed.id && obj.post_info.post_type === feed.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].interested_data.interested = true;
      }
      if (this.editFeedObj && this.editFeedObj.id == feed.id && this.editFeedObj.post_info.post_type === feed.post_info.post_type) {
        this.editFeedObj.interested_data.interested = true;
      }
    }
  }

  getInterestedUsers(){
    let params = '?page=' + this.intUsersNextPage;
    this.pageService.interestedUsers(this.editFeedObj.id, this.editFeedObj.post_info.post_type, params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intUsersNextPage === 1) {
            this.interestedUsers = response.response.results;
          } else {
            this.interestedUsers = this.interestedUsers.concat(response.response.results);
          }
          this.totalUsersCount = response.response.total_count;
          this.intUsersNextPage = response.response.next;
        } else {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
        }
      }, error => {
          this.interestedUsers = [];
          this.totalUsersCount = 0;
          this.intUsersNextPage = 1;
      });
  }

  getInterestedFeeds(){
    let params = '?page=' + this.intFeedsNextPage;
    this.pageService.interestedFeeds(params)
      .subscribe(response => {
        if (response.status === 200) {
          if (this.intFeedsNextPage === 1) {
            this.interestedFeeds = response.response.results;
          } else {
            this.interestedFeeds = this.interestedFeeds.concat(response.response.results);
          }
          this.totalIntFeedsCount = response.response.total_count;
          this.intFeedsNextPage = response.response.next;
          if (this.interestedFeeds.length == 0 && this.intModalRef) {
            this.intModalRef.hide();
          }
        } else {
          this.interestedFeeds = [];
          this.totalIntFeedsCount = 0;
          this.intFeedsNextPage = 1;
        }
      }, error => {
          this.interestedFeeds = [];
          this.totalIntFeedsCount = 0;
          this.intFeedsNextPage = 1;
      });
  }

  changeType(){
    if (this.radioActive === 3 || this.radioActive === 7 || this.radioActive === 10) {
      this.timelineForm.get('date').setValidators([Validators.required]);
      this.timelineForm.controls["date"].updateValueAndValidity();
    } else {
      this.timelineForm.controls["date"].setErrors(null);
      this.timelineForm.get('date').clearValidators();
      this.timelineForm.controls["date"].updateValueAndValidity();
    }
  }

  setLink(isValid: boolean){
    if (isValid) {
      this.intModalRef.hide();
      this.linkAdded = true;
      setTimeout(() => {this.linkAdded = false;}, 4000);
      this.timelineForm.controls['link'].setValue(this.timelinelinkForm.get('link').value);
    }
  }

  removeLink(){
    this.timelinelinkForm.reset();
    this.timelineForm.controls['link'].setValue(null);
  }

  remTimelineDoc(index){
    this.timelineDocs.splice(index, 1);
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  createTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid && this.page_id > 0) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'page': this.page_id,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'date': this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd")
      }
      if (this.radioActive == 1) {
        this.addPageTimeline(model);
      } else if (this.radioActive == 2) {
        this.addTimelinePosition(model);
      } else if (this.radioActive == 3) {
        this.addTimelineEvent(model);
      } else if ([6,7,8].indexOf(this.radioActive) > -1) {
        model['school'] = this.selectedSchClubTeam.id;
        model['post_type'] = this.radioActive;
        this.addSchoolTimeline(model);
      }
    }
  }

  addSchoolTimeline(model: any){
    this.pageService.addSchoolTimeline(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  addPageTimeline(model: any){
    this.profileService.addPageTimeline(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  addTimelinePosition(model: any){
    this.profileService.addPagePosition(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  addTimelineEvent(model: any){
    model['date'] = this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd");
    this.profileService.addClubTeamEvents(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  handlePostResponse(response: any){
    this.timelineDocs = [];
    if (response.status === 200) {
      this.modalRef.hide();
      this.timelineForm.reset();
      this.timelinelinkForm.reset();
      this.submitted = false;
      this.selectedSchClubTeam = null;
      this.isSchool = false;
      this.feedsNextPage = 1;
      this.schFeedsNextPage = 1;
      this.getFeeds();
      this.getSchFeeds();
      this.showSuccessMsg = true;
      this.fadeOutFollowErrMsg();
      this.weekEvntNextPage = 1;
      this.getWeeklyDetails();
    } else {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
    }
  }

  createIniTimeline(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      this.coverImageUploadError = false;
      this.coverImageUploadSizeError = false;
      let link = this.timelineForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'initiative': this.page_id,
        'title': this.timelineForm.get('title').value,
        'link': link,
        'description': this.timelineForm.get('description').value,
        'documents': this.timelineDocs,
        'date': this.datePipe.transform(this.timelineForm.get('date').value,"yyyy-MM-dd")
      }
      if (this.radioActive == 1) {
        this.addIniTimeline(model);
      } else if (this.radioActive == 10) {
        this.addVolunteerPost(model);
      } else {
        this.addVolunteerPost(model);
      }
    }
  }

  addIniTimeline(model: any){
    this.initiativeService.addTimeline(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  addVolunteerPost(model: any){
    model['post_type'] = this.radioActive;
    this.initiativeService.addIniVolunteer(model)
    .subscribe(response => {
      this.handlePostResponse(response);
    }, error => {
      this.timelineForm.get('description').setErrors({aftersubmit: "Something went wrong please try again later."});
      this.timelineDocs = [];
    });
  }

  closeModal(){
    if (this.otherModalRef) {
      this.otherModalRef.hide();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (this.intModalRef) {
      this.intModalRef.hide();
    }
  }

  onScroll(e) {
    if (this.scrWidth < 992 && !this.isScrolled) {
      this.isScrolled = true;
      if (this.tabActive === 1) {
        if (this.totalFeedsCount !== this.feeds.length) {
          this.getFeeds();
        }
      } else if (this.tabActive === 2) {
        if (this.totalSchFeedsCount !== this.schoolFeeds.length) {
          this.getSchFeeds();
        }
      }
      setTimeout(() => {this.isScrolled = false;}, 500);
    }
  }

  firstLogin(data: any){
    this.user.first_login = data;
    this.isFirstLogin = data;
  }

  getFollowfeeds(){
    this.isFirstLogin = this.user.first_login;
    if (this.feeds.length == 0 || this.newFollowed) {
      this.feedsNextPage = 1;
      this.getFeeds();
      this.newFollowed = false;
    }
  }

  followPage(instance: any){
    if (instance.post_info.ini_id || instance.post_info.page_id) {
      if (instance.post_info.ini_id) {
        this.pageService.followInitiative(instance.post_info.ini_id, {})
          .subscribe(response => {
            this.handleFollowReq(instance);
          });
      } else {
        this.pageService.followPage(instance.post_info.page_id, {})
          .subscribe(response => {
            this.handleFollowReq(instance);
          });
      }
    }
  }

  handleFollowReq(instance: any){
    this.schFeedsNextPage = 1;
    this.getSchFeeds();
    this.followedNextPage = 1;
    this.getFollowedPages();
    this.newPageNextPage = 1;
    this.getNewPageNotif();
    this.newFollowed = true;
  }

  showBtn(info: any){
    if (!info.follow_info.is_followed) {
      if ((this.user.age_group == 3 && info.follow_info.is_college) || this.user.age_group !== 3 || info.post_info.ini_id) {
        return true;
      }
    }
    return false;
  }

  getUserSchools(){
    this.schools = [];
      this.profileService.getUserSchools()
      .subscribe(response => {
        if (response.response.length > 0) {
          this.schools = response.response;
        } else {
          this.schools = [];
        }
      }, error => {
        this.schools = [];
      })
  }

  communityConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
  };
  weekConfig = {
    "variableWidth": true,
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":false,
    "responsive": [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            },
          },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };
  feedDetailConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "autoplay": false,
    "dots":true,
    "responsive": [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            },
          },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        },
      },
    ]
  };

  checkSchool(){
    if (this.selectedSchClubTeam){
      this.page_id = this.selectedSchClubTeam.id;
      this.isSchool = this.selectedSchClubTeam.page_info.is_school;
      if (this.isSchool) {
        this.radioActive = 8;
      } else {
        this.radioActive = 1;
      }
    } else {
      this.page_id = 0;
      this.isSchool = false;
      this.radioActive = 1;
    }
  }

  checkIfSenior(newPage, messageAlertTemplate){
    if (this.user.age_group == 3 && this.user.id !== newPage.post_info.created_by && newPage.post_info.page_id) {
        this.modalRef = this.modalService.show(messageAlertTemplate, Object.assign({},
        { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal' }));
    } else {
      this.directToPage(newPage);
    }
  }
  openWeekModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal new-modal-ui custom-profile-modal' })
    );
  }

  commentAdded(event: any){
    if (event !== null && event >= 0) {
      let schIndex = this.schoolFeeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (schIndex !== -1) {
        this.schoolFeeds[schIndex].post_info.comments_count = event;
      }
      let index = this.feeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].post_info.comments_count = event;
      }
    }
  }

  commentDeleted(event: any){
    if (event) {
      let schIndex = this.schoolFeeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (schIndex !== -1) {
        this.schoolFeeds[schIndex].post_info.comments_count = this.schoolFeeds[schIndex].post_info.comments_count - 1;
      }
      let index = this.feeds.findIndex(obj => obj.id === this.editFeedObj.id && obj.post_info.post_type === this.editFeedObj.post_info.post_type);
      if (index !== -1) {
        this.feeds[index].post_info.comments_count = this.feeds[index].post_info.comments_count - 1;
      }
    }
  }



  copyUrl(data:any){
    this.profileService.getShareUrl(data.id, data.post_info.post_type).subscribe(response => {
      const msg = response.response.share_post_url;
      this.setToCopy(msg);
      this.shareUrlIdCheck = data.id;
      this.copyMessageBool = true;
      setTimeout(() => {this.copyMessageBool = false  ;      }, 3000);
    });

  }

  setToCopy(msg: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }



}
