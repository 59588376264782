<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <div class="detail-post-page" *ngIf="!hideContent">
        <div class="d-flex detail-comment-box" *ngIf="(editFeedObj | json) != '{}'">
          <div class="timeLinesEach custom-design mt-0">
            <div class="feedDetailSlider" [ngClass]="{'no-image': editFeedObj?.documents?.length == 0 }">
                <span class="event"
                *ngIf="editFeedObj.post_info?.post_type === 3 || editFeedObj.post_info?.post_type === 7 || editFeedObj.post_info?.post_type === 10">Event</span>
              <span class="event"
                *ngIf="editFeedObj.post_info?.post_type === 2 || editFeedObj.post_info?.post_type === 5">Position</span>
              <span class="event"
                *ngIf="editFeedObj.post_info?.post_type === 1 || editFeedObj.post_info?.post_type === 8 || editFeedObj.post_info?.post_type === 4">Update</span>
              <span class="event interested" *ngIf="editFeedObj.post_info?.post_type === 6">
                <img src="assets/images/blub.svg" alt="blub" />Idea
              </span>
              <ngx-slick-carousel class="new-slider" #slickModal="slick-carousel" [config]="feedDetailConfig">
                <div class="slide" ngxSlickItem *ngFor="let document of editFeedObj.documents">
                  <div class="img-box">
                    <img *ngIf="isImage(document.document_name)" src="{{document.document}}" alt="image" />
                    <img *ngIf="!isImage(document.document_name)" src="assets/images/pdf-icon.svg" alt="pdf-image" />
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
            <div class="feedDetailContent">
                <div class="img-box"
                [ngClass]="{'mt-4 mt-lg-0 mt-md-0': editFeedObj?.documents?.length == 0 && [2,3,5,6,7].indexOf(editFeedObj.post_info?.post_type) < 0}">
                <img class="cursor-pointer" (click)="directToPage(editFeedObj);closeModal()"
                  src="{{editFeedObj.display_picture? editFeedObj.display_picture : 'assets/images/default.svg'}}"
                  alt="image" />
              </div>
              <h3>{{editFeedObj.title}}</h3>
              <p>{{editFeedObj.page_title}}</p>
            <div class="date-box"
            *ngIf="(editFeedObj.post_info?.post_type === 3 || editFeedObj.post_info?.post_type === 7 || editFeedObj.post_info?.post_type === 10 || editFeedObj.post_info.post_type === 5) && (editFeedObj.post_info.event_date != null)">
            <div class="month">
              <span>{{editFeedObj.post_info.event_date | date:'MMM'}}</span>
            </div>
            <div class="date">
              <span>{{editFeedObj.post_info.event_date | date:'d'}}</span>
            </div>
          </div>
          <div class="date-box"
          *ngIf="(editFeedObj.post_info.post_type !== 3 || editFeedObj.post_info.post_type !== 7 || editFeedObj.post_info.post_type !== 5 || editFeedObj.post_info.post_type !== 10) && (editFeedObj.post_info.date != null)">
          <div class="month">
            <span>{{editFeedObj.post_info.date | date:'MMM'}}</span>
          </div>
          <div class="date">
            <span>{{editFeedObj.post_info.date | date:'d'}}</span>
          </div>
        </div>

              <div class="save-content">
                <div class="left-part">
                  <button type="button" class="interest-btn" [ngClass]="{'remove': editFeedObj.interested_data?.interested}" (click)="markInterested(editFeedObj)">
                    <img src="/assets/images/interested-star-filled.svg" alt="star-filled" class="remove-img">
                    <img src="/assets/images/interested-star-empty.svg" alt="star-empty">
                    <span class="remove-text">remove</span>
                    <span>Interested</span> {{editFeedObj[0]?.id}}</button>
                    <button type="button" class="other-btn" *ngIf="editFeedObj.interested_data?.count > 0"
                        (click)="openModalOthers(templateOthers, editFeedObj)">
                        {{editFeedObj.interested_data.count}} Others
                      </button>
                  <button type="button" class="interest-btn comments-btn" (click)="copyUrl(editFeedObj)">
                    <img class="mr-0" src="/assets/images/copylink.svg" alt="copylink"></button>
                    <div class="custom-tooltip copy-tooltip tooltip-show">
                      <div class="tooltip-data copied-tooltip link-copied" *ngIf="(shareUrlIdCheck == editFeedObj.id) && copyMessageBool">Link Copied</div>
                      </div>
                </div>
                <div class="right-part">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="mobile-btn d-flex"
                          aria-controls="dropdown-basic">
                          <img src="assets/images/dot-teal.svg" alt="more icon" />
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu
                          class="dropdown-menu name-mobile-box detail-custom ReportDropMenuposition" role="menu"
                          aria-labelledby="button-basic" [ngClass]="{'onlyProfile': isInterested}">
                          <li role="menuitem" *ngIf="!isInterested">
                            <div class="flag-data-box cursor-pointer" (click)="reportModal(reportTemplate, editFeedObj)">
                              <img class="edit-icon" src="/assets/images/empty-flag.svg" alt="flag" />
                              <span>Report Post</span>
                            </div>
                          </li>
                          <li role="menuitem">
                            <div class="flag-data-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                              <div class="name ml-0">
                                <span class="dot">
                                  <img alt="profile-image"
                                    src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}">
                                </span>
                                <span class="cursor-pointer"
                                  (click)="directToProfile(editFeedObj);closeModal()">{{editFeedObj.post_info.creator_name}}</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                </div>
              </div>
              <div class="details mb-0">
                <h4>Details</h4>
                <p innerHtml="{{editFeedObj.description}}"></p>
                <p class="metaLink" *ngIf="!checkExtraData(editFeedObj)">
                    <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
                  </p>
                  <div class="singleTimelineDetail" *ngIf="checkExtraData(editFeedObj)">
                      <div class="imageBox">
                        <img alt="timeline" class="timeLineImg"
                          src="{{editFeedObj.metadata_image_link? editFeedObj.metadata_image_link : 'assets/images/default.svg'}}" />
                      </div>
                      <div class="dtlOuter">
                        <h1>{{editFeedObj.metadata_title}}</h1>
                        <a href="{{editFeedObj.link}}" target="_blank">{{editFeedObj.link}}</a>
                        <p>{{editFeedObj.metadata_description}}</p>
                      </div>
                    </div>
                <div class="posted-by mb-0">
                  <h4>Posted by</h4>
                  <div class="name-box cursor-pointer" (click)="directToProfile(editFeedObj);closeModal()">
                      <img
                        src="{{editFeedObj.post_info.creator_pic? editFeedObj.post_info.creator_pic : 'assets/images/default.svg'}}"
                        alt="image" />
                      <p>{{editFeedObj.post_info.creator_name}}</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <app-comments [editFeedPostType]="editFeedObj.post_info?.post_type" [editFeedId]="editFeedObj.id"></app-comments>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #reportTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Report Post</h4>
      <button type="button" class="close-btn" aria-label="Close" (click)="reportModalRef.hide()">
        <img alt="image" src="assets/images/cross-modal.svg">
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-box">
        <form [formGroup]="blockReportForm">
          <div class="reportShow">
            <div class="form-group">
              <label>Reason</label>
              <select class="form-control custom-select" formControlName="report_reason">
                <option value=1>Hate Speech</option>
                <option value=2>Bullying and harrassment of yourself or others</option>
                <option value=3>Direct threats</option>
                <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)</option>
                <option value=5>Other</option>
              </select>
            </div>
            <div class="error-message"
              *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
              This field is required.
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control" formControlName="report_description"></textarea>
              <div class="error-message"
                *ngIf="blockReportForm.get('report_description').hasError('maxlength') && (blockReportForm.get('report_description').dirty || blockReportForm.get('report_description').touched)">
                Max 1000 characters allowed.
              </div>
            </div>
          </div>
          <h4 class="note-info"><strong>Note:</strong> Once submitted, this post will disappear from the feed. Our Support
            team will get notified and take a look. Thank you for keeping our community safe!</h4>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-footer cancel-btn" (click)="reportModalRef.hide()">
        Cancel
      </button>
      <button class="btn btn-footer" (click)="reportFeed(blockReportForm.valid)">
        Submit
      </button>
    </div>
  </ng-template>
  <ng-template #templateOthers>
      <div class="modal-body new-body common-body">
        <div class="create-box">
          <h4 class="modal-title pull-left">Interested</h4>
          <button type="button" class="close-btn" aria-label="Close" (click)="otherModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
          </button>
        </div>
        <ul class="follow-list scroll-activity scrollbar">
          <li *ngFor="let usr of interestedUsers">
            <div class="info-content">
              <img alt="profile-image" class="cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
                src="{{usr.display_pic? usr.display_pic : 'assets/images/default.svg'}}" />
              <div class="text">
                <p class="bold cursor-pointer" (click)="closeModal()" [routerLink]="['/profile', usr.id]"
                  [ngClass]="{'align-verti': !usr.education_info.school}">{{usr.first_name}} {{usr.last_name}}</p>
                <p>{{usr.education_info.school_name}}</p>
                <p class="small" *ngIf="usr.education_info.school">{{usr.education_info.start_date | date: 'yyyy'}} -
                  {{usr.education_info.end_date | date: 'yyyy'}}</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="timeLineLoad " *ngIf="totalUsersCount > 21 && totalUsersCount > interestedUsers.length">
          <a href="javascript:void(0);" (click)="getInterestedUsers()">Load More</a>
        </div>
      </div>
    </ng-template>
    <div class="message-box" *ngIf="hideContent">

    <div class="eachResult mobile-result show">
      <div class="profile-sidebar">
          <div class="sidebarForm">

              <div class="message-content">
                  <p class="bold">Hey there!</p>
                  <p class="mb-md-5 mb-4">
                      Since you're now above 20 years old, you will no longer be able to view the posts or access the clubs
                      and teams here.
                  </p>
                  <p>You will still be able to follow and see the initiatives!</p>
              </div>
              <a href="/dashboard" class="btn btn-footer">Go to homepage</a>
          </div>
      </div>
  </div>

  </div>

  <div class="message-box" *ngIf="showPostDetailError">
    <div class="eachResult mobile-result show">
      <div class="profile-sidebar">
          <div class="sidebarForm">

              <div class="message-content">
                  <p class="mb-md-5 mb-4">
                     Something went wrong, please try again later.
                  </p>
              </div>
              <a href="/dashboard" class="btn btn-footer">Go to homepage</a>
          </div>
      </div>
  </div>
  </div>


  <div class="message-box" *ngIf="showDeletePostMessage">
    <div class="eachResult mobile-result show">
      <div class="profile-sidebar">
          <div class="sidebarForm">

              <div class="message-content">
                  <p class="mb-md-5 mb-4">
                    This post has been deleted or no longer exists.
                  </p>
              </div>
              <a href="/dashboard" class="btn btn-footer">Go to homepage</a>
          </div>
      </div>
  </div>
  </div>
