import { Component, OnInit, TemplateRef  } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { UserProfile } from '../../shared/userProfile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-student',
  templateUrl: './profile-student.component.html',
  styleUrls: ['./profile-student.component.scss']
})
export class ProfileStudentComponent implements OnInit {
  modalRef: BsModalRef;
  descriptionForm: FormGroup
  formShow: boolean = false;
  public submitted: boolean = false;
  user: any = {};
  profile: any = {};
  skills: any = {};
  interests: any = {};

  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, private router: Router) {

    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      profile_keyword_3: new FormControl('', [Validators.required, Validators.maxLength(30)])
    });

  }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    } else {
      this.router.navigate(['/']);
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  openModal1(template1: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template1,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  showForm(){
    this.formShow = true;
  }
  
  skillModal(skillpopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(skillpopup,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  saveDescription(isValid: boolean){
    this.submitted = true;
    if(isValid) {
        this.userProfile.description = this.descriptionForm.get('description').value;
        this.userProfile.profile_keyword_1 = this.descriptionForm.get('profile_keyword_1').value;
        this.userProfile.profile_keyword_2 = this.descriptionForm.get('profile_keyword_2').value;
        this.userProfile.profile_keyword_3 = this.descriptionForm.get('profile_keyword_3').value;
        this.userProfile.user = this.user.id;
        this.profileService.addDescription(this.userProfile)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
            this.handleSubmitError(error.error);
        })
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.descriptionForm.reset();
        this.submitted = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

}
