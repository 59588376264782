<div class="titleDiv d-flex align-items-center">
  <div class="step3-box d-flex align-items-center">
    <h3>
      Education
    </h3>
    <a href="javascript:void();" (click)="eduModal(educationModal)"><img class="d-block"
        src="assets/images/plus-teal.svg" alt="icon"></a>

    <div class="dialogeducation-box education-box">
      <div class="white-dialog-content">
        <p>
                Click the plus icon to get started!
        </p>
      </div>
    </div>
  </div>
</div>
<div class="detailContent">
    <ngx-slick-carousel class="sliderContent carousel" #slickModal="slick-carousel" [config]="sliderEdu">
        <div class=" slide" ngxSlickItem *ngFor="let eduObj of eduList">
            <div class="media">
                <div>
                    <a routerLink="/school/{{ eduObj.school.id }}">
                        <img alt="image" alt="Cover" class="thumb-img"
                            src="{{eduObj.school.display_picture? eduObj.school.display_picture : 'assets/images/default.svg'}}">
                    </a>
                </div>
                <div class="media-body ml-2">
                    <h4 (click)="updateEducationPopup(educationUpdateModal, eduObj.id)">{{eduObj.school.school_name}}</h4>
                    <p *ngIf="eduObj.majors">{{ eduObj.majors.join(', ') }}</p>
                    <p *ngIf="eduObj.interests">{{eduObj.interests.join(', ')}}</p>
                    <p class="font14">{{eduObj.end_date | date:'yyyy' }}</p>
                    <div class="addRefRow">
                        <div class="addRefRowInner flex-column">
                                <div class="expVerifyTeam" *ngIf="eduObj.team_members > 0">
                                        <p class="m-0 people-number cursor-pointer custom-margin" (click)="teamModal(eduObj, teamMemberTemplate)">
                                            <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                            <span>{{eduObj.team_members}}</span>
                                        </p>
                                    </div>
                            <a href="javascript:void(0);" (click)="modalAddTeam(templateAddTeam, eduObj)">
                                <img src="assets/images/verifiedUserActive.svg" alt="icon">
                                <span>Add team members</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div>

<ng-template #educationModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body mb-0">
        <div class="modal-box education-box">
            <div *ngIf="!showTeam">
                <form [hidden]="!getCurrentEdForm(1)" [formGroup]="educationForm" class="edu-form" novalidate>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <h2 class="stand-by-hdg">Select {{insText}}</h2>
                        </div>
                        <div class="col-md-12">
                            <label>Institute type</label>
                        </div>
                        <div class="col-4">
                            <!-- <div class="custom-control custom-radio">
                                <input formControlName="is_college" type="radio" value="false" checked="" id="customCheck2"
                                    name="is_college" class="custom-control-input" (change)="changeSchoolCollege($event)">
                                <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                            </div> -->
                            <div class="teal-radio-box">
                                    <input formControlName="is_college" type="radio" value="false" checked="" id="customCheck2"
                                    name="is_college" (change)="changeSchoolCollege($event)" />
                                    <label for="customCheck2">School</label>
                                </div>
                        </div>
                        <div class="col-8">
                            <!-- <div class="custom-control custom-radio">
                                <input formControlName="is_college" type="radio" value="true" id="customCheck3"
                                    name="is_college" class="custom-control-input" (change)="changeSchoolCollege($event)">
                                <label for="customCheck3"
                                    class="custom-control-label custom-label mb-0">College/University</label>
                            </div> -->
                            <div class="teal-radio-box">
                                    <input formControlName="is_college" type="radio" value="true" id="customCheck3"
                                    name="is_college" class="custom-control-input" (change)="changeSchoolCollege($event)" />
                                    <label for="customCheck3">College/University</label>
                                </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                    <label>Country</label>
                                    <select formControlName="country" class="form-control custom-select educ-select"
                                        (change)="changeCountry($event)" placeholder="Country">
                                        <option selected>Canada</option>
                                        <option>USA</option>
                                    </select>
                                    <div class="error-message"
                                        *ngIf="educationForm.get('country').hasError('required') && (educationForm.get('country').dirty || educationForm.get('country').touched || nextClicked)">
                                        This field is required.
                                    </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                    <label>{{plchldrState}}</label>
                                    <select class="form-control custom-select educ-select" formControlName="state"
                                        (change)="changeState($event)">
                                        <option [hidden]="!showCaStates" [disabled]="!showCaStates" *ngFor="let state of caStates"
                                            value="{{state.name}}">{{state.name}}</option>
                                        <option [hidden]="showCaStates" [disabled]="showCaStates" *ngFor="let state of usStates"
                                            value="{{state.name}}">{{state.name}}</option>
                                    </select>
                                    <div class="error-message"
                                        *ngIf="educationForm.get('state').hasError('required') && (educationForm.get('state').dirty || educationForm.get('state').touched || nextClicked)">
                                        This field is required.
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <label>{{insText}} Name</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" formControlName="school_name"
                                placeholder="Full {{insText}} Name" (keydown)="searchSchool($event)"
                                (focusout)="focusOutFunction()">
                            <div class="error-message"
                                *ngIf="educationForm.get('school_name').hasError('required') && (educationForm.get('school_name').dirty || educationForm.get('school_name').touched || nextClicked)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="educationForm.get('school_name').hasError('maxlength') && (educationForm.get('school_name').dirty || educationForm.get('school_name').touched)">
                                Max 30 characters allowed.
                            </div>
                            <div *ngIf="!hideResults" class="col-md-9">
                                <div class="option-div">
                                    <div class="listing" *ngFor="let school of schools" (click)="selectSchool(school)">
                                        <img
                                            src="{{school.display_picture? school.display_picture : 'assets/images/default.svg'}}">
                                        <div class="detail">
                                            <h6>{{school.school_name}}</h6>
                                            <span> {{school.address}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                    <label for="">Start Date
                                            <span>*</span>
                                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" [maxDate]="today" 
                                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                                    formControlName="start_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                                            <div class="error-message"
                                                *ngIf="educationForm.get('start_date').hasError('required') && (educationForm.get('start_date').dirty || educationForm.get('start_date').touched || nextClicked)">
                                                This field is requied.
                                            </div>
                                    </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-0">
                                    <label for="">End Date (or Projected End Date)
                                            <span>*</span>
                                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" 
                                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                                    formControlName="end_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                                            <div class="error-message"
                                                *ngIf="educationForm.get('end_date').hasError('required') && (educationForm.get('end_date').dirty || educationForm.get('end_date').touched || nextClicked)">
                                                This field is requied.
                                            </div>
                                        </label>
                                        <div class="error-message"
                                            *ngIf="educationForm.errors?.startEndValid && (educationForm.get('start_date').touched || educationForm.get('start_date').dirty || educationForm.get('end_date').touched || educationForm.get('end_date').dirty)">
                                            Start date should not be greater than end date.
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div class="error-message text-center"
                        *ngIf="educationForm.hasError('aftersubmit') && (educationForm.dirty || educationForm.touched || nextClicked)">
                        Something went wrong. Please try again later.
                    </div>
                </form>
                <form [hidden]="!getCurrentEdForm(2)" [formGroup]="newSchoolForm" novalidate>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <h3>Enter {{insText}} Details</h3>
                        </div>
                        <div class="col-md-12" *ngIf="!isCollegeSelected">
                            <label for="">Type of {{insText}}</label>
                        </div>
                        <div class="col-md-12" *ngIf="!isCollegeSelected">
                            <select formControlName="school_type" class="custom-select">
                                <option [hidden]="isCollegeSelected" *ngFor="let ins_type of schoolTypes"
                                    value="{{ins_type.id}}">{{ins_type.value}}</option>
                                <option [hidden]="!isCollegeSelected" *ngFor="let ins_type of collegeTypes"
                                    value="{{ins_type.id}}">{{ins_type.value}}</option>
                            </select>
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('school_type').hasError('required') && (newSchoolForm.get('school_type').dirty || newSchoolForm.get('school_type').touched || nextClicked)">
                                This field is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label>Full Address</label>
                        </div>
                        <div class="col-md-9">
                            <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet"
                                (change)="changeAddress($event)" (setAddress)="getAddress($event)"
                                (setHideResult)="setResultHide($event)"></app-azure-maps>
                            <div *ngIf="!hidePlaceResults" class="col-md-12">
                                <div class="option-div">
                                    <div class="listing" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                                        <svg class="location-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                            viewBox="0 0 15 20">
                                            <path fill="none" stroke="#505759" stroke-width="1.4"
                                                d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                        </svg>
                                        <div class="detail">
                                            <h6>{{place.address.freeformAddress}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input formControlName="address" [hidden]="true" type="text" class="form-control"
                                placeholder="Full Address">
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('address').hasError('required') && (newSchoolForm.get('address').dirty || newSchoolForm.get('address').touched || nextClicked || showAddressError)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('address').hasError('maxlength') && (newSchoolForm.get('address').dirty || newSchoolForm.get('address').touched || nextClicked)">
                                Max 200 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label>City</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" formControlName="city" class="form-control" placeholder="City">
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('city').hasError('required') && (newSchoolForm.get('city').dirty || newSchoolForm.get('city').touched || nextClicked)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('city').hasError('maxlength') && (newSchoolForm.get('city').dirty || newSchoolForm.get('city').touched || nextClicked)">
                                Max 20 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" [hidden]="!showDistrict">
                        <div class="col-md-3">
                            <label>District</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" formControlName="district" class="form-control" placeholder="District">
                            <div class="error-message"
                                *ngIf="showDistrict && newSchoolForm.get('district').hasError('required') && (newSchoolForm.get('district').dirty || newSchoolForm.get('district').touched || nextClicked)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('district').hasError('maxlength') && (newSchoolForm.get('district').dirty || newSchoolForm.get('district').touched || nextClicked)">
                                Max 50 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label>{{plchldrPostal}}</label>
                        </div>
                        <div class="col-md-9">
                            <input type="text" formControlName="zip_code" class="form-control"
                                placeholder="{{plchldrPostal}}">
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('zip_code').hasError('required') && (newSchoolForm.get('zip_code').dirty || newSchoolForm.get('zip_code').touched || nextClicked)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="newSchoolForm.get('zip_code').hasError('maxlength') && (newSchoolForm.get('zip_code').dirty || newSchoolForm.get('zip_code').touched || nextClicked)">
                                Max 20 characters allowed.
                            </div>
                        </div>
                    </div>
                    <div class="error-message text-center"
                        *ngIf="newSchoolForm.hasError('aftersubmit') && (newSchoolForm.dirty || newSchoolForm.touched || nextClicked)">
                        Something went wrong. Please try again later.
                    </div>
                </form>
            </div>
            <div class="initiativeTeamBlock p-0 mt-3" *ngIf="showTeam">
                <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
                <div class="initiativeTeamSlider mb-md-0 px-0" *ngIf="schoolMembers.length > 0">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let schoolMember of schoolMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember"><img alt="icon" class="bannerImg"
                                        src="{{schoolMember.student_info.header_image? schoolMember.student_info.header_image : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="profileMember"><img alt="icon" class="profileImg"
                                        src="{{schoolMember.student_info.display_pic? schoolMember.student_info.display_pic : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{schoolMember.student_info.student_name}}</h1>
                                        <p class="yearHistoryMember"> {{schoolMember.start_date | date:'yyyy' }}
                                            <span> - {{schoolMember.end_date | date:'yyyy' }}</span>
                                        </p>
                                        <div class="memberActions">
                                            <button class="btn common-btn btnMessage" *ngIf="user.approval_status && (user.age_group < 3 || user.age_group == schoolMember.student_info.age_group)" (click)="routeToProfile(schoolMember.student_info.id)">Profile</button>
                                            <button class="btn common-btn btnProfile" *ngIf="schoolMember.team_status == 5" (click)="addToTeam(schoolMember)">Add to team</button>
                                            <button class="btn common-btn btnProfile noCursor" *ngIf="schoolMember.team_status < 5"><span *ngIf="schoolMember.team_status == 1">Pending</span><span *ngIf="schoolMember.team_status == 2">Approved</span></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style="max-width: 100%;min-width: 100%;" id="bottomScr"></li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="loadMoreMembers('bottomScr')">Load More</a>
                    </div>
                </div>
                <p class="text-center py-5 m-0" *ngIf="schoolMembers.length == 0">No members</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="showPrev" (click)="getPrevEd()" class="btn btn-footer">
            Previous
        </button>
        <button *ngIf="showNext" (click)="getNextEd()" class="btn btn-footer">
            <span *ngIf="!schoolId">Next</span><span *ngIf="schoolId">Done</span>
        </button>
        <button *ngIf="showDone && addNewEdu" (click)="getDoneClicked()" [disabled]="disableSubmit"
            class="btn btn-footer">
            Done
        </button>
        <button *ngIf="showTeam && addNewEdu" (click)="resetAddModal()" class="btn btn-footer">
            skip
        </button>
        <button *ngIf="showTeam && addNewEdu" (click)="resetAddModal()" class="btn btn-footer">
            Done
        </button>
    </div>
</ng-template>
<ng-template #templateAddTeam>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Team member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div *ngIf="teamError" class="error-message err-font mb-2 mt-0 text-center">Something went wrong. Please try again later.</div>
                <div class="initiativeTeamSlider mb-md-5 px-0">
                    <div class="teamSearchInputWrap mt-2">
                        <div class="teamSearchInputWrapInner">
                            <div class="leftWrap" *ngIf="schoolMembers.length > 0">
                                <div class="form-group">
                                    <input type="text" placeholder="Search by name" class="form-control" (keyup)="searchStudent($event)">
                                </div>
                            </div>
                            <div class="rightWrap" [ngClass]="{'forMissingInvite' : schoolMembers.length == 0}">
                                <p class="des">Is someone from this team missing? 
                                    <a href="javascript:void();" (click)="inviteTeamModal(inviteTeamTemplate)">
                                        <img alt="icon" class="iconInvite ml-2 mr-1" src="./assets/images/verifyProfile.svg" />
                                        <span>Invite</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="schoolMemberSliced && schoolMemberSliced.length > 0">
                        <ul class="teamListWrap no-list">
                            <li *ngFor="let schoolMember of schoolMemberSliced">
                                <div class="teamMemberEach">
                                    <div class="topMember"><img alt="icon" class="bannerImg"
                                        src="{{schoolMember.student_info.header_image? schoolMember.student_info.header_image : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="profileMember"><img alt="icon" class="profileImg"
                                        src="{{schoolMember.student_info.display_pic? schoolMember.student_info.display_pic : 'assets/images/new-header-img.png'}}">
                                    </div>
                                    <div class="btmMember">
                                        <h1 class="name">{{schoolMember.student_info.student_name}}</h1>
                                        <p class="yearHistoryMember"> {{schoolMember.start_date | date:'yyyy' }}
                                            <span> - {{schoolMember.end_date | date:'yyyy' }}</span>
                                        </p>
                                        <div class="memberActions">
                                            <button class="btn common-btn btnMessage" *ngIf="user.approval_status && (user.age_group < 3 || user.age_group == schoolMember.student_info.age_group)" (click)="routeToProfile(schoolMember.student_info.id)">Profile</button>
                                            <button class="btn common-btn btnProfile" *ngIf="schoolMember.team_status == 5" (click)="addToTeam(schoolMember)">Add to team</button>
                                            <button class="btn common-btn btnProfile noCursor" *ngIf="schoolMember.team_status < 5"><span *ngIf="schoolMember.team_status == 1">Pending</span><span *ngIf="schoolMember.team_status == 2">Approved</span></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li style="max-width: 100%;min-width: 100%;" id="bottomScr"></li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="teamLoadMoreBool">
                        <a href="javascript:void(0);" (click)="loadMoreMembers('bottomScr')">Load More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #teamMemberTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Your Team <span *ngIf="teamMember.length > 0">({{ totalTeamMember }})</span></h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="initiativeTeamBlock p-0">
                <div class="initiativeTeamSlider mb-md-2 px-0">
                    <div class="scroll-activity scrollbar teamListScroll scrollVH" *ngIf="teamMember.length > 0">
                        <ul class="teamMemberList no-list">
                            <li *ngFor="let member of teamMember">
                                <div class="myTeamEach">
                                    <div class="leftTeam">
                                        <img alt="icon" class="profileImg" src="{{member.edu_team_detail.display_pic ? member.edu_team_detail.display_pic : 'assets/images/p-img.png'}}" />
                                    </div>
                                    <div class="rightTeam rightTeamWithBtn">
                                        <div class="forDetails">
                                            <h1 class="name m-0">{{ member.edu_team_detail.name }}</h1>
                                            <p class="yearHistoryMember m-0">
                                                {{ member.edu_team_detail.start_date | date:'yyyy' }} - {{member.edu_team_detail.end_date | date:'yyyy' }}
                                            </p>
                                        </div>
                                        <div class="forBtns">
                                            <button class="btn btnInvite" (click)="delTeamMemberModal(delTeamTemplate, member)">Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="timeLineLoad pt-3" *ngIf="totalTeamMember > 6 && totalTeamMember > teamMember.length">
                        <a href="javascript:void(0);" (click)="loadMoreTeamMemberProfile()">Load More</a>
                    </div>
                    <div *ngIf="teamMember.length == 0">
                        No Team Member(s)
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Team Member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="delModalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <h4>Are you sure you want to remove this person?</h4>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delModalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delTeamMember(); delModalRef.hide();">
            Yes
        </button>
    </div>
</ng-template>
<ng-template #educationUpdateModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body mb-0">
        <div class="modal-box">
            <form [formGroup]="educationForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h2 class="stand-by-hdg">{{insText}}</h2>
                    </div>
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input readonly type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Country</label>
                        <input readonly type="text" class="form-control" formControlName="country"
                            placeholder="Country">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-md-4">
                        <!-- <div class="custom-control custom-radio" [hidden]="isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" class="custom-control-input">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div> -->
                        <div class="teal-radio-box">
                                <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" />
                                <label for="customCheck2">School</label>
                            </div>

                    </div>
                    <div class="col-md-8">
                        <!-- <div class="custom-control custom-radio" [hidden]="!isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div> -->
                        <div class="teal-radio-box" [hidden]="!isCollegeSelected">
                                <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" />
                                <label for="customCheck3">College/University</label>
                            </div>
                    </div>
                </div>
            </form>
            <form [hidden]="!isCollegeSelected" [formGroup]="collegeEdForm" novalidate>
                <div class="error-message"
                    *ngIf="collegeEdForm.hasError('aftersubmit') && (collegeEdForm.dirty || collegeEdForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3 class="stand-by-hdg">Education Details</h3>
                    </div>
                    <div class="col-md-12">
                        <label for="">Degree of {{insText}}
                            <span>*</span>
                        </label>
                    </div>
                    <div class="col-md-12">
                        <select formControlName="college_degree" class="custom-select full-width">
                            <option *ngFor="let ins_type of collegeTypes" value={{ins_type.id}}>{{ins_type.value}}
                            </option>
                        </select>
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('college_degree').hasError('required') && (collegeEdForm.get('college_degree').dirty || collegeEdForm.get('college_degree').touched || doneClicked)">
                            This field is required.
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label>Program
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="program" placeholder="Program" class="form-control">
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('program').hasError('maxlength') && (collegeEdForm.get('program').dirty || collegeEdForm.get('program').touched || doneClicked)">
                            Max 150 characters allowed.
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="majors" placeholder="Majors" class="form-control">
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('majors').hasError('arrElemValid') && (collegeEdForm.get('majors').dirty || collegeEdForm.get('majors').touched || doneClicked)">
                            Please enter comma-separated majors containing less than 50 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="minors" placeholder="Minors" class="form-control">
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('minors').hasError('arrElemValid') && (collegeEdForm.get('minors').dirty || collegeEdForm.get('minors').touched || doneClicked)">
                            Please enter comma-separated minors containing less than 50 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" 
                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                    formControlName="start_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                            <div class="error-message"
                                *ngIf="collegeEdForm.get('start_date').hasError('required') && (collegeEdForm.get('start_date').dirty || collegeEdForm.get('start_date').touched || doneClicked)">
                                This field is requied.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" 
                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                    formControlName="end_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                            <div class="error-message"
                                *ngIf="collegeEdForm.get('end_date').hasError('required') && (collegeEdForm.get('end_date').dirty || collegeEdForm.get('end_date').touched || doneClicked)">
                                This field is requied.
                            </div>
                        </label>
                        <div class="error-message"
                            *ngIf="collegeEdForm.errors?.startEndValid && (collegeEdForm.get('start_date').touched || collegeEdForm.get('start_date').dirty || collegeEdForm.get('end_date').touched || collegeEdForm.get('end_date').dirty)">
                            Start date should not be greater than end date.
                        </div>
                    </div>
                </div>
            </form>
            <form [hidden]="isCollegeSelected" [formGroup]="schoolEdForm" novalidate>
                <div class="error-message"
                    *ngIf="schoolEdForm.hasError('aftersubmit') && (schoolEdForm.dirty || schoolEdForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3 class="stand-by-hdg">Education Details</h3>
                    </div>
                    <div class="col-md-12">
                        <label>Interests and Favourite Classes

                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="interests" placeholder="Interests" class="form-control">
                        <div class="error-message"
                            *ngIf="schoolEdForm.get('interests').hasError('arrElemValid') && (schoolEdForm.get('interests').dirty || schoolEdForm.get('interests').touched || doneClicked)">
                            Please enter comma-separated interests containing less than 100 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" 
                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                    formControlName="start_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                            <div class="error-message"
                                *ngIf="schoolEdForm.get('start_date').hasError('required') && (schoolEdForm.get('start_date').dirty || schoolEdForm.get('start_date').touched || doneClicked)">
                                This field is requied.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" bsDatepicker (onShown)="onOpenCalendar($event)" 
                                    [bsConfig]="{dateInputFormat: 'YYYY-MM', adaptivePosition: true }" autocomplete="off" 
                                    formControlName="end_date" style="background-color: white;" placeholder="YYYY MM" readonly>
                            <div class="error-message"
                                *ngIf="schoolEdForm.get('end_date').hasError('required') && (schoolEdForm.get('end_date').dirty || schoolEdForm.get('end_date').touched || doneClicked)">
                                This field is requied.
                            </div>
                        </label>
                        <div class="error-message"
                            *ngIf="schoolEdForm.errors?.startEndValid && (schoolEdForm.get('start_date').touched || schoolEdForm.get('start_date').dirty || schoolEdForm.get('end_date').touched || schoolEdForm.get('end_date').dirty)">
                            Start date should not be greater than end date.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="getUpdateClicked(true)" [disabled]="disableSubmit"
            class="btn btn-footer cancel-btn">
            Delete
        </button>
        <button (click)="getUpdateClicked(false)" [disabled]="disableSubmit"
            class="btn btn-footer">
            Update
        </button>
    </div>
</ng-template>
<ng-template #inviteTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Invite a member</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="newModalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!invitationSuccess" [formGroup]="invitationForm" novalidate>
                    <h3 class="inviteCopyNote">Copy and share the following message with your network via text or social media:</h3>
                    <p class="inviteCopySbNote">Join my network at {{title}} on ConnectUs.today by signing up using the following link:  <strong>connectus.today/signin</strong></p>
                    <div class="inviteBorder">
                        <div class="custom-tooltip copy-tooltip">
                            <button class="btn btnInvite" (click)="copyMessage()">Copy</button>
                            <div class="tooltip-data" *ngIf="copyMessageBool">Message Copied</div>
                        </div>
                    </div>

                <p class="inviteNote">Not sure of your teammate's email? Look their name up through your school email (it should be there!)</p>
                <div formArrayName="invitations" 
                    *ngFor="let item of invitationForm.get('invitations')['controls']; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('required') && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('whitespace') && (!item.get('name').hasError('required')) && (item.get('name').dirty || item.get('name').touched || submitted)">
                                    Please enter valid data.
                                </div>
                                <div class="error-message"
                                    *ngIf="item.get('name').hasError('maxlength') && (item.get('name').dirty || item.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Email" formControlName="email">
                                <div class="error-message" *ngIf="item.get('email').hasError('required') && (item.get('email').dirty || item.get('email').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message" *ngIf="item.get('email').hasError('pattern') && (item.get('email').dirty || item.get('email').touched)">
                                    Please enter your email in a valid format.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 pl-0" *ngIf="i > 0">
                            <span class="cursor-pointer" (click)="removeItem(i)">
                                <img alt="icon" class="" src="assets/images/delete_icon.svg">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="error-message"
                    *ngIf="invitations.hasError('maxLengthArray')">
                    You can only add upto 10 invites.
                </div>
                <div class="error-message" *ngIf="invitations.hasError('aftersubmit')">
                    {{invitations.errors.aftersubmit}}
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btnInvite" (click)="addItem()">Add Row</button>
                    </div>
                </div>
            </form>
            <div *ngIf="invitationSuccess" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <p class="contentText">Your invitation has been sent successfully!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="newModalRef.hide()">cancel</button>
        <button class="btn btn-footer" *ngIf="!invitationSuccess" (click)="inviteMember(invitationForm.valid)">invite</button>
        <button class="btn btn-footer" *ngIf="invitationSuccess" (click)="newModalRef.hide()">Done</button>
    </div>
</ng-template>
