import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { LoginService } from '../../services/login.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private route: Router,
    private loginService: LoginService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let token = null;
    if (this.isTokenExpired && request.url.indexOf('login') === -1
    && request.url.indexOf('api/accounts/token') === -1){
      if (!this.refreshTokenInProgress){
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.loginService.refreshAccessToken().pipe(switchMap((data:any) => {
          this.updateToken(data.response);
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(data.response.access_token);
          return this.handleHttpRequest(request, next);
        }))
      }
      else {
        return this.refreshTokenSubject.pipe(
          filter(d => d != null),
          take(1),
          switchMap(token => {
              return this.handleHttpRequest(request, next);
          }));
        }
      }
    else{
      return this.handleHttpRequest(request, next);
    }
  }

  private handleHttpRequest(httpRequest: HttpRequest<any>, next: HttpHandler) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let token = null;
    if (currentUser) {
      token = currentUser.access_token ? currentUser.access_token : this.loginService.publicToken;
    } else {
      token = this.loginService.publicToken;
    }

    if (token) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    if (!httpRequest.headers.has('Content-Type')) {
      httpRequest = httpRequest.clone({ headers: httpRequest.headers.set('Content-Type', 'application/json') });
    }

    return next.handle(httpRequest).pipe(tap((evt) => {
        if (evt instanceof HttpResponse) {
            if (evt.body && evt.body.status === 401) {
              localStorage.removeItem('user');
              localStorage.removeItem('public_user');
              window.location.href='/';
            }
            if (evt?.body?.status === 302) {
                this.route.navigate(['/404']);
            }
            
        }
      }, (err: any) => { })
    );
  }

  private updateToken(data) {
    if (data.access_token && data.refresh_token){
      const currentUser = JSON.parse(localStorage.getItem('user'));
      currentUser.access_token = data.access_token;
      currentUser.refresh_token = data.refresh_token;
      currentUser.expires_on = data.expires_on;
      localStorage.setItem('user', JSON.stringify(currentUser));
    }
  }

  private get isTokenExpired(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (!currentUser || !currentUser.expires_on) { return false; }
    return moment.utc(currentUser.expires_on).diff(moment.utc(), 'minutes') < 2;
  }
}
