<div>
    <div *ngIf="submitError" class="error-message col-md-12 text-center">{{submitErrorMsg}}</div>
    <form [hidden]="!firstStep" [formGroup]="firstStepForm" novalidate>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <label for="first" class="claimLabel">Organization Details <span>*</span></label>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" placeholder="Title of Organization" class="form-control signUpField"
                    formControlName="organization_title">
                <div class="error-message"
                    *ngIf="firstStepForm.get('organization_title').hasError('required') && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('organization_title').hasError('whitespace') && (!firstStepForm.get('organization_title').hasError('required')) && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched || partialSubmitted)">
                    Please enter valid data
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('organization_title').hasError('maxlength') && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched)">
                    Max 125 characters allowed.
                </div>
            </div>
        </div>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <select class="form-control custom-select signUpField" placeholder="Country"
                    formControlName="country" (change)="changeCountry($event)">
                    <option selected>Canada</option>
                    <option>USA</option>
                </select>
                <div class="error-message"
                    *ngIf="firstStepForm.get('country').hasError('required') && (firstStepForm.get('country').dirty || firstStepForm.get('country').touched || partialSubmitted)">
                    This field is required.
                </div>
            </div>
        </div>
        <div class="form-group row inputRow forAzure">
            <div class="col-md-12">
                <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet"
                    (change)="changeAddress($event)" (setAddress)="getAddress($event)"
                    (setHideResult)="setResultHide($event)"></app-azure-maps>
                <div *ngIf="!hidePlaceResults" class="col-md-12">
                    <div class="option-div" slimScroll [options]="opts"
                        [scrollEvents]="scrollEvents">
                        <div class="listing" *ngFor="let place of searchPlaces"
                            (click)="selectPlace(place)">
                            <svg _ngcontent-dsr-c176="" width="130px" height="130px"
                                viewBox="0 0 130 130" version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title _ngcontent-dsr-c176="">Artboard</title>
                                <desc _ngcontent-dsr-c176="">Created with Sketch.</desc>
                                <g _ngcontent-dsr-c176="" id="Artboard" stroke="none"
                                    stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect _ngcontent-dsr-c176="" id="Rectangle" fill="#C0CBD7"
                                        x="0" y="0" width="130" height="130" rx="12"></rect>
                                    <g _ngcontent-dsr-c176="" id="image1"
                                        transform="translate(21.000000, 21.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <path _ngcontent-dsr-c176=""
                                            d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                            id="Shape"></path>
                                    </g>
                                </g>
                            </svg>
                            <div class="detail">
                                <h6>{{place.address.freeformAddress}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="text" [hidden]="true" placeholder="Full Address"
                    class="form-control signUpField" formControlName="address">
                <div class="error-message"
                    *ngIf="firstStepForm.get('address').hasError('required') && (showAddressError || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('address').hasError('whitespace') && (!firstStepForm.get('address').hasError('required')) && (firstStepForm.get('address').dirty || firstStepForm.get('address').touched || partialSubmitted)">
                    Please enter valid data
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('address').hasError('maxlength') && (firstStepForm.get('address').dirty || firstStepForm.get('address').touched)">
                    Max 200 characters allowed.
                </div>
            </div>
        </div>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" placeholder="Unit #" class="form-control signUpField"
                    formControlName="unit_number">
                <div class="error-message"
                    *ngIf="firstStepForm.get('unit_number').hasError('maxlength') && (firstStepForm.get('unit_number').dirty || firstStepForm.get('unit_number').touched)">
                    Max 50 characters allowed.
                </div>
            </div>
        </div>
        <div class="form-group row inputRow">
            <div class="col-md-6">
                <input type="text" placeholder="City" class="form-control signUpField"
                    formControlName="city">
                <div class="error-message"
                    *ngIf="firstStepForm.get('city').hasError('required') && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('city').hasError('whitespace') && (!firstStepForm.get('city').hasError('required')) && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched || partialSubmitted)">
                    Please enter valid data
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('city').hasError('maxlength') && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched)">
                    Max 20 characters allowed.
                </div>
            </div>
            <div class="col-md-6 ">
                <input type="text" placeholder="{{plchldrPostal}}" class="form-control signUpField"
                    formControlName="zip_code" autocomplete="off">
                <div class="error-message"
                    *ngIf="firstStepForm.get('zip_code').hasError('required') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('zip_code').hasError('whitespace') && (!firstStepForm.get('zip_code').hasError('required')) && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched || partialSubmitted)">
                    Please enter valid data
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('zip_code').hasError('maxlength') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched)">
                    <span *ngIf="plchldrPostal==='Postal'">Please enter your postal code in a valid format (Ex. A1A1A1).</span>
                    <span *ngIf="plchldrPostal==='ZIP Code'">Please enter your zip code in a valid format (Ex. A1AA1).</span>
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('zip_code').hasError('minlength') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched)">
                    <span *ngIf="plchldrPostal==='Postal'">Please enter your postal code in a valid format (Ex. A1A1A1).</span>
                    <span *ngIf="plchldrPostal==='ZIP Code'">Please enter your zip code in a valid format (Ex. A1AA1).</span>
                </div>
            </div>
        </div>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" placeholder="{{plchldrState}}" class="form-control signUpField"
                    formControlName="state">
                <div class="error-message"
                    *ngIf="firstStepForm.get('state').hasError('required') && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('state').hasError('whitespace') && (!firstStepForm.get('state').hasError('required')) && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched || partialSubmitted)">
                    Please enter valid data
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('state').hasError('maxlength') && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched)">
                    Max 50 characters allowed.
                </div>
            </div>
        </div>
        <div class="form-group row mb-1">
            <div class="col-md-12">
                <label class="claimLabel">Contact & Verification</label>
            </div>
        </div>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" class="form-control signUpField" placeholder="Contact Email"
                    formControlName="professional_email" (focusout)="validateEmail()">
                <div class="error-message"
                    *ngIf="firstStepForm.get('professional_email').hasError('required') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched || partialSubmitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('professional_email').hasError('maxlength') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                    Max 100 characters allowed.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('professional_email').hasError('pattern') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                    Please enter your email in a valid format.
                </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('professional_email').hasError('invalid_format') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                    This domain name cannot be used.
                </div>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-10">
                <input type="text" placeholder="Business/NPO Number" class="form-control signUpField"
                    formControlName="business_number">
                    <div class="error-message" *ngIf="firstStepForm.get('business_number').hasError('minlength') && (firstStepForm.get('business_number').dirty || firstStepForm.get('business_number').touched)">
                        Business/NPO number needs to be at least 10 digits.
                    </div>
                <div class="error-message"
                    *ngIf="firstStepForm.get('business_number').hasError('maxlength') && (firstStepForm.get('business_number').dirty || firstStepForm.get('business_number').touched)">
                    Max 15 characters allowed.
                </div>
            </div>
            <div class="col-2">
                <img src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                    class="cursor-pointer" alt="image">
                <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                    <div class="info-div">
                        <p>Enter the Business/NPO number associated with your
                            organization here. Your Business/NPO number will only be
                            used by ConnectUs.today admin to verify your organization, and will not be
                            made public. You have 30 days to add this number through Settings.
                            You must perform this task before verifying a student’s experience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group m-0 text-center pt-2">
            <button type="submit" class="btn btnSignUp"
                (click)="showNext(firstStepForm.valid)">
                <span *ngIf="!userId">Next</span>
                <span *ngIf="userId">Submit</span>
            </button>
        </div>
    </form>
    <form [hidden]="!secondStep" [formGroup]="secondStepForm" novalidate
        (ngSubmit)="save(secondStepForm.valid)">
        <div class="claimNoteWrap">
            <p class="claimNote m-0">Please enter all accurate information below. 
                You will not be allowed to change your Name or Year of Birth once your account is created</p>
            </div>
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <label for="" class="claimLabel">Business Representative Details</label>
            </div>
            <div class="col-md-12">
                <input type="text" class="form-control signUpField" placeholder="First"
                    formControlName="firstname">
                <div class="error-message"
                    *ngIf="secondStepForm.get('firstname').hasError('required') && (secondStepForm.get('firstname').dirty || secondStepForm.get('firstname').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('firstname').hasError('maxlength') && (secondStepForm.get('firstname').dirty || secondStepForm.get('firstname').touched)">
                    Max 50 characters allowed.
                </div>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" class="form-control signUpField" placeholder="Last"
                    formControlName="lastname">
                <div class="error-message"
                    *ngIf="secondStepForm.get('lastname').hasError('required') && (secondStepForm.get('lastname').dirty || secondStepForm.get('lastname').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('lastname').hasError('maxlength') && (secondStepForm.get('lastname').dirty || secondStepForm.get('lastname').touched)">
                    Max 50 characters allowed.
                </div>
            </div>
        </div>
        
        <div class="form-group row inputRow">
            <div class="col-md-12">
                <label for="" class="claimLabel">Birth Year</label>
            </div>
            <div class="col-md-12">
                <input type="number" placeholder="YYYY" class="form-control signUpField"
                    formControlName="year_of_birth" (focusout)="checkDate()">
                <ng-container *ngFor="let error of error_messages.year_of_birth">
                    <div class="error-message"
                        *ngIf="secondStepForm.get('year_of_birth').hasError(error.type) && (submitted || secondStepForm.get('year_of_birth').dirty || secondStepForm.get('year_of_birth').touched)">
                        {{ error.message }}
                    </div>
                </ng-container>
                <div class="error-message" *ngIf="invalidDate">
                    {{invalidDateMsg}}
                </div>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="text" class="form-control signUpField" placeholder="Email"
                    formControlName="email">
                <div class="error-message"
                    *ngIf="secondStepForm.get('email').hasError('required') && (secondStepForm.get('email').dirty || secondStepForm.get('email').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('email').hasError('maxlength') && (secondStepForm.get('email').dirty || secondStepForm.get('email').touched)">
                    Max 100 characters allowed.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('email').hasError('pattern') && (secondStepForm.get('email').dirty || secondStepForm.get('email').touched)">
                    Please enter your email in a valid format.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('email').hasError('aftersubmit')">
                    {{secondStepForm.controls.email.errors.aftersubmit}}
                </div>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="password" class="form-control signUpField" placeholder="Password"
                    formControlName="password">
                <div class="error-message"
                    *ngIf="secondStepForm.get('password').hasError('required') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('password').hasError('maxlength') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                    Max 15 characters allowed.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('password').hasError('invalidPassword') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                    Your password needs to be at least 8 characters, including a Capital Letter,
                    Lowercase Letter and a Number.
                </div>
            </div>
        </div>

        <div class="form-group row inputRow">
            <div class="col-md-12">
                <input type="password" class="form-control signUpField" placeholder="Repeat Password"
                    formControlName="confirm_password">
                <div class="error-message"
                    *ngIf="secondStepForm.get('confirm_password').hasError('required') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.get('confirm_password').hasError('minlength') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                    Your password needs to be at least 8 characters, including a Capital Letter,
                    Lowercase Letter and a Number.
                </div>
                <div class="error-message"
                    *ngIf="secondStepForm.hasError('passwordNotMatch') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                    Passwords do not match.
                </div>
            </div>
        </div>
        
        <div class="form-group">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck"
                    name="privacy" (change)="onCheckboxChange($event)">
                <label class="custom-control-label" for="customCheck">I have read and agree to
                    the
                    <a [routerLink]="['/terms-of-use']" target="_blank" rel="noopener noreferrer">Terms of use</a> and
                    <a [routerLink]="['/privacy-policy']" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                </label>
            </div>
            <div class="error-message" *ngIf="!user.is_terms_accepted && submitted">
                Please agree to the Terms and Conditions and Privacy Policy.
            </div>
        </div>
        <div class="form-group m-0 text-center pt-2">
            <div class="col-md-12">
                <button type="submit" class="btn btnSignUp">Register</button>
            </div>
        </div>
    </form>
</div>