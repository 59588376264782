import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { ProfileService } from '../services/profile.service';
import { TeamService } from '../services/team.service';
import { setExternalUserId } from '../../assets/js/oneSignal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedIn: boolean = false;
  public settingShow = true;
  user: any = {};
  notifCount: any = 0;
  messageCount: any = 0;
  isNotifUrl: boolean = false;
  isMsgUrl: boolean = false;
  messages = [];
  notifications = [];
  hideHeader: boolean = false;
  url: any;
  unreadReqCount: any = 0;
  isExpReqUrl: boolean = false;

  constructor(private loginService: LoginService, private router: Router, private profileService: ProfileService, private teamService: TeamService) {
    this.updateHeader();
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    }
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        const uri = this.router.url.split("/");
        this.url = uri.slice(-1)[0];
        this.updateHeader();
        this.getNotifCount();
        this.getRequestCount();
        if (location.pathname.indexOf('notifications') > 0){
          this.isNotifUrl = true;
          this.notifCount = 0;
        } else {
          this.isNotifUrl = false;
        }
        if (location.pathname.indexOf('message-chat') > 0){
          this.isMsgUrl = true;
        } else {
          this.isMsgUrl = false;
        }
        if (location.pathname.indexOf('teams') > 0 || location.pathname.indexOf('profile/teams') > 0) {
          this.unreadReqCount = 0;
          this.isExpReqUrl = true;
          this.markRequestsRead();
        } else {
          this.isExpReqUrl = false;
        }
      }
    });
  }

  ngOnInit(): void {}

  updateHeader(){
    if (this.router.url === '/') {
      this.hideHeader = true;
    }else {
      this.hideHeader = false;
    }
  }

  logout() {
    this.loginService.userLogout()
        .subscribe(
            data => {
                if (this.user.unique_id) {
                  setExternalUserId(this.user.unique_id, this.user.email, false);
                }
                localStorage.removeItem('user');
                window.location.href='/';
                this.loadPublicToken();
            },
            error => {}
        )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
        .pipe(first())
        .subscribe(
            data => {
            },
            error => {

            });
  }

  getNotifCount(){
    if (this.loggedIn){
      this.profileService.getUserNotifCount()
      .subscribe(response => {
        if (response.status === 200) {
          this.notifCount = response.response.notif_count;
          this.messageCount = response.response.message_count;
          this.messages = response.response.messages;
          this.notifications = response.response.notifications;
          let approval_status = response.response.approval_status;
          if (this.user.approval_status !== approval_status) {
            this.user.approval_status = approval_status;
            localStorage.setItem('user', JSON.stringify(this.user));
          }
        } else {
          this.notifCount = 0;
          this.messageCount = 0;
          this.messages = [];
          this.notifications = [];
        }
      });
    } else {
      this.notifCount = 0;
      this.messageCount = 0;
    }
  }

  getRequestCount(){
    this.unreadReqCount = 0;
    if (this.loggedIn) {
      this.teamService.getUnreadReq()
        .subscribe(response => {
          if (response.status === 200){
            this.unreadReqCount = response.response.total_count;
          } else {
            this.unreadReqCount = 0;
          }
        })
    } else {
      this.unreadReqCount = 0;
    }
  }

 markRequestsRead(){
  this.teamService.markRequests()
    .subscribe(response => {})
}

}
